import { Table } from '@themesberg/react-bootstrap'
import React from 'react'
import PaginationComponent from '../Pagination'
import { statusType, tableHeaders } from '../../pages/Super-Admin/CasinoTransactions/constants'
import { getDateTime } from '../../utils/dateFormatter'
import Trigger from '../OverlayTrigger'
import FormatNumber from '../../utils/formatNumber'

const CasinoTransactionsList = ({
  setPage,
  page,
  totalPages,
  limit,
  setLimit,
  transactions,
  isTenant,
  loading = false
}) => {
  const tenantHeader = [
    'ID',
    'E-mail do usuário',
    'Nome do jogo',
    'Tipo de ação',
    'Quantia',
    'Dinheiro de bônus',
    'Status',
    'Criado'
  ]

  return (
    <>
      <div className='TableBg mt-4 pt-1'>
        {/* Table with Casino Transactions Info */}

        <Table striped responsive hover size='sm' className='text-center mt-3'>
          <thead className='thead-light'>
            <tr>
              {isTenant
                ? tenantHeader.map((c) => (
                  <th key={c}>{c}</th>
                ))
                : tableHeaders.map((c) => (
                  <th key={c} className={`${['Amount', 'Bonus Money'].includes(c) && transactions?.count > 0 ? 'text-right' : ''}`}>{c}</th>
                ))}
            </tr>
          </thead>

          <tbody id={loading ? 'cover-spin' : ''}>
            {transactions && !loading &&
              transactions?.rows?.map(
                ({
                  casinoTransactionId,
                  User,
                  createdAt,
                  gameIdentifier,
                  actionType,
                  amount,
                  status,
                  nonCashAmount,
                  CasinoGame
                }) => {
                  return (
                    <tr key={`casino-transaction-list ${casinoTransactionId}`}>
                      <td>{casinoTransactionId}</td>

                      <td>
                        <Trigger message={User?.email}>
                          <span
                            style={{
                              width: '130px',
                              cursor: 'pointer'
                            }}
                            className='d-inline-block text-truncate'
                          >
                            {User?.email}
                          </span>
                        </Trigger>
                      </td>

                      <td>
                        <Trigger message={CasinoGame?.name || gameIdentifier}>
                          <span
                            style={{
                              width: '120px',
                              cursor: 'pointer'
                            }}
                            className='d-inline-block text-truncate'
                          >
                            {CasinoGame?.name || gameIdentifier}
                          </span>
                        </Trigger>
                      </td>

                      <td>{actionType}</td>
                      <td className={`text-right ${amount > 0 ? 'text-success' : amount < 0 && 'text-danger'}`}>
                        {`${FormatNumber.currency(amount, User?.currencyCode)}`}
                      </td>
                      <td className={`text-right ${nonCashAmount > 0 ? 'text-success' : nonCashAmount < 0 && 'text-danger'}`}>
                        {`${FormatNumber.currency(nonCashAmount, User?.currencyCode)}`}
                      </td>
                      <td>{statusType?.[parseInt(status) + 1].label}</td>
                      <td>{getDateTime(createdAt)}</td>

                    </tr>
                  )
                })}
            {transactions?.count === 0 && !loading && (
              <tr>
                <td colSpan={9} className='text-danger text-center'>
                 Sem dados
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {transactions?.count > 0 && !loading && (
          <PaginationComponent
            page={transactions?.count < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
          />
        )}
      </div>
    </>
  )
}

export default CasinoTransactionsList
