import * as Yup from 'yup'

export const createSuperAdminUserSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .max(200)
    .required('Email Required'),
  password: Yup.string()
    .matches(
      // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      /^(?!.*(\d)\1\1)[A-Za-z0-9~!@#$%^&*()]{8,}$/gm,
      'Password must be at least One Uppercase, One Lowercase, One Special Character and Number.'
    )
    .max(50)
    .required('Password Required'),
  firstName: Yup.string().min(3, 'First Name must be atleast 3 characters')
    .max(200)
    .matches(/^[a-zA-Z\u00C0-\u00FF ]+$/i, 'Only Alphabets and Space Allowed and Must Start with Alphabet')
    .required('First Name Required'),
  lastName: Yup.string().min(3, 'Last Name must be atleast 3 characters')
    .max(200)
    .matches(/^[a-zA-Z\u00C0-\u00FF ]+$/i, 'Only Alphabets and Space Allowed and Must Start with Alphabet')
    .required('Last Name Required'),
  role: Yup.string().required('Role Required').nullable(),
  adminId: Yup.string().when('role', {
    is: (role) => role === 'Support',
    then: Yup.string().required('Parent Admin is required').nullable(),
    otherwise: Yup.string().nullable()
  }),
  adminUsername: Yup.string()
    .matches(/^[A-Za-z]+$/, 'Only Alphabets Allowed')
    .min(8)
    .max(100)
    .required('User Name Required'),
  // group: Yup.string().min(3, 'Group Name must be atleast 3 characters')
  //   .max(200)
  //   .matches(/^[A-Za-z0-9 ]+$/, 'Only Alphabets, Numbers and Space Allowed')
  //   .required('Group Name Required')
})

export const updateSuperAdminUserSchema = ({ maxCommissionPercentage }) => Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .max(200)
    .required('Email Required'),
  firstName: Yup.string().min(3, 'First Name must be atleast 3 characters')
    .max(200)
    .matches(/^[a-zA-Z\u00C0-\u00FF ]+$/i, 'Only Alphabets and Space Allowed and Must Start with Alphabet')
    .required('First Name Required'),
  lastName: Yup.string().min(3, 'Last Name must be atleast 3 characters')
    .max(200)
    .matches(/^[a-zA-Z\u00C0-\u00FF ]+$/i, 'Only Alphabets and Space Allowed and Must Start with Alphabet')
    .required('Last Name Required'),
  password: Yup.string()
    .matches(
      // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      /^(?!.*(\d)\1\1)[A-Za-z0-9~!@#$%^&*()]{8,}$/gm,
      'Invalid Password'
    )
    .max(50),
  role: Yup.string().required('Role Required').nullable(),
  adminId: Yup.string().when('role', {
    is: (role) => role === 'Support',
    then: Yup.string().required('Parent Admin is required').nullable(),
    otherwise: Yup.string().nullable()
  }),
  adminUsername: Yup.string()
    .matches(/^[a-zA-Z][a-zA-Z0-9_]+$/, 'Must Start with Alphabet.Only Alphabets, Numbers and UnderScore(_) allowed')
    .min(8, 'Minimum 8 characters required')
    .max(100)
    .required('User Name Required'),
  // commission:Yup.number().min(0,'Must be at least zero').max(maxCommissionPercentage,`Must be at most ${maxCommissionPercentage}`)
  // group: Yup.string().min(3, 'Group Name must be atleast 3 characters')
  //   .max(200)
  //   .matches(/^[A-Za-z0-9 ]+$/, 'Only Alphabets, Numbers and Space Allowed')
  //   .required('Group Name Required').nullable()
})
