import { Col, Row, Table } from '@themesberg/react-bootstrap'
import React, { useEffect, useState } from 'react'

import FormatNumber from '../../../../utils/formatNumber'
import FormatDate from '../../../../utils/formatDate'
import { getSubAffiliatePerformance } from '../../../../utils/apiCalls'
import { Loading } from '../../../../components/Preloader'
import { toast } from '../../../../components/Toast'

import { DashboardHeadTitle } from '../style'
import useDebounce from '../../../../hooks/useDebounce'
import DateRangePicker from '../../../../components/DateRangePicker'
import { formatDateYMD, getDateDaysAgo } from '../../../../utils/dateFormatter'
import UpdateButtonIcon from '../../../../components/UpdateButtonIcon'
import Trigger from '../../../../components/OverlayTrigger'
import PaginationComponent from '../../../../components/Pagination'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

export const tableHeaders = [
  { label: 'ID', value: 'affiliateId' },
  { label: 'Usuário', value: 'username' },
  { label: 'E-mail', value: 'email' },
  { label: 'Primeiro Depósito', value: 'valueFirstDeposit', message: "Valor do primeiro depósito" },
  { label: 'Total em Depósitos', value: 'valueTotalDeposit', message: "Valor total de depósitos feito pelo usuário" },
  { label: 'Total em Saques', value: 'valueWithdrawRequested', message: "Valor total de saques" },
  { label: 'Quantidade de Saques', value: 'withdrawRequestCount' },
  { label: 'CPA', value: 'cpaCommission' },
  { label: 'NGR', value: 'ngr' },
  { label: 'PROFIT', value: 'profit' },
  { label: 'NET DEPOSIT', value: 'netDeposit' },
  { label: 'TURNOVER', value: 'turnover' },
  { label: 'REVENUE SHARE', value: 'revenueShare' },
  { label: 'Data de Cadastro', value: 'createdAt' },
]

const textColor = (v = 0) => v == 0 ? "" : v > 0 ? "text-success" : "text-danger";

const SubAffiliatesPerformanceTable = ({ affiliate, ...props }) => {
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);

  const [dateRange, setDateRange] = useState([{
    startDate: getDateDaysAgo(30),
    endDate: new Date(),
    key: 'selection'
  }])

  const loadAffiliateUsersPerformance = useDebounce(async ({ loading, initialLoading, page }) => {
    if (loading) setLoading(true);
    if (initialLoading) setInitialLoading(true);
    try {
      const users = await getSubAffiliatePerformance({
        affiliateId: affiliate?.affiliateId,
        // startDate: formatDateYMD(dateRange.map(a => a.startDate)),
        // endDate: formatDateYMD(dateRange.map(a => a.endDate)),
        pageNo: page || 1,
        limit: 15,
      })
      setUsers(users.data.data);
    } catch (err) {
      toast("Error ao obter usuários do afiliado", "error")
    } finally {
      setLoading(false);
      setInitialLoading(false);
    }
  }, 400)

  useEffect(() => {
    if (affiliate?.affiliateId) loadAffiliateUsersPerformance({ initialLoading: true })
  }, [affiliate])

  if (!affiliate) return;

  return (<>
    <div className='TableBg mt-4 p-2' {...props}>
      <DashboardHeadTitle as={Row} className="mt-2 px-2 d-flex">
        <Col xs={6}>
          <h3 className="dashboard-spec-text">Desempenho de Sub-afiliados</h3>
        </Col>
        <Col xs={6}>
          <Row className='mt-0'>
            <Col xs={12} md={12} className='d-flex align-items-center justify-content-end'>
              <UpdateButtonIcon
                onClick={() => loadAffiliateUsersPerformance({ loading: true })}
                {...{ loading }}
              />
            </Col>
            {/* <Col xs={6} md={6} >
              <DateRangePicker
                state={dateRange} setState={setDateRange}
                size='sm'
                placement={"right"}
              />
            </Col> */}
          </Row>
        </Col>

      </DashboardHeadTitle>
      <div className='position-relative h-100'>
        <Table striped responsive hover size='sm' className='text-center m-0'>
          <thead className='thead-light'>
            <tr>
              {tableHeaders.map((h, idx) => (
                <Trigger message={h.message} placement='top'>
                  <th
                    key={`T-table_heading ${idx}`}
                    className={`${['Amount'].includes(h.label) && users?.count > 0 ? 'text-right' : ''}`}
                  >
                    {h.label}
                  </th>
                </Trigger>
              ))}
            </tr>
          </thead>

          <tbody>
            {users && !initialLoading &&
              users?.result?.map(
                ({
                  affiliateId,
                  username,
                  email,
                  valueFirstDeposit,
                  valueTotalDeposit,
                  valueWithdrawRequested,
                  withdrawRequestCount,
                  cpaCommission,
                  ngr,
                  profit,
                  netDeposit,
                  turnover,
                  revenueShare,
                  createdAt,
                }) => {
                  return (
                    <tr key={`users-list:${affiliateId}`}>
                      <td>{affiliateId || "N/A"}</td>
                      {username ? <Trigger message={username}>
                        <td className='text-left'>
                          <Link to={`/admin/affiliate-details/${affiliateId}`} target='_blank'>{username}</Link>
                        </td>
                      </Trigger> : <td>N/A</td>}
                      <td className='text-left'>{email || "N/A"}</td>
                      <td className={classNames("text-right", textColor(valueFirstDeposit))}>{FormatNumber.currency(valueFirstDeposit || 0)}</td>
                      <td className={classNames("text-right", textColor(valueTotalDeposit))}>{FormatNumber.currency(valueTotalDeposit || 0)}</td>
                      <td className={classNames("text-right", textColor(-valueWithdrawRequested))}>{FormatNumber.currency(-valueWithdrawRequested || 0)}</td>
                      <td>{withdrawRequestCount || 0}</td>
                      <td className={classNames("text-right", textColor(cpaCommission))}>{FormatNumber.currency(cpaCommission || 0)}</td>
                      <td className={classNames("text-right", textColor(ngr))}>{FormatNumber.currency(ngr || 0)}</td>
                      <td className={classNames("text-right", textColor(profit))}>{FormatNumber.currency(profit || 0)}</td>
                      <td className={classNames("text-right", textColor(netDeposit))}>{FormatNumber.currency(netDeposit || 0)}</td>
                      <td className={classNames("text-right", textColor(turnover))}>{FormatNumber.currency(turnover || 0)}</td>
                      <td className={classNames("text-right", textColor(revenueShare))}>{FormatNumber.currency(revenueShare || 0)}</td>
                      <td className=''>{FormatDate.toDateOnly(createdAt)}</td>
                    </tr>
                  )
                })}

            {users?.result?.length === 0 && !loading && (
              <tr>
                <td colSpan={10} className='text-danger text-center'>
                  Nenhum dado encontrado!
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        {initialLoading ? <Loading className={"position-absolute bg-white top-0 w-100 h-100"} /> : null}
      </div>
      {users && users?.result?.length > 0 && !initialLoading && (
        <PaginationComponent
          page={page}
          totalPages={users?.totalPages}
          setPage={(page) => {
            setPage(page);
            loadAffiliateUsersPerformance({ loading: true, page });
          }}
        // limit={limit}
        // setLimit={setLimit}
        />
      )}
    </div>
  </>)
}

export default SubAffiliatesPerformanceTable;


