import * as Yup from 'yup'

export const adminProfileSchema = Yup.object().shape({
  firstName: Yup.string().min(3, 'First Name must be atleast 3 characters')
    .max(200)
    .matches(/^[a-zA-Z\u00C0-\u00FF ]+$/i, 'Only Alphabets and Space Allowed and Must Start with Alphabet')
    .required('First Name Required'),
  lastName: Yup.string().min(3, 'Last Name must be atleast 3 characters')
    .max(200)
    .matches(/^[a-zA-Z\u00C0-\u00FF ]+$/i, 'Only Alphabets and Space Allowed and Must Start with Alphabet')
    .required('Last Name Required'),
  phone: Yup.string()
    .min(10, 'Phone must be at least 10 digits')
    .max(20, 'Phone must be at most 20 digits')
    .matches(
      /^((\\+[1-9]{1,10}[ \\-]*)|(\\([0-9]{1,10}\\)[ \\-]*)|([0-9]{1,10})[ \\-]*)*?[0-9]{1,10}?[ \\-]*[0-9]{1,10}?$/,
      'Enter a valid Phone Number'
    )
})

export const profilePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Old Password Required!'),
  newPassword: Yup.string()
    .matches(
      // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      /^(?!.*(\d)\1\1)[A-Za-z0-9~!@#$%^&*()]{8,}$/gm,
      'Invalid Password'
    )
    .test('match', 'Old and New Password Must be Different!', function (newPassword) {
      return newPassword !== this.options.parent.password
    })
    .max(50)
    .required('New Password Required!'),
  confirmPassword:
    Yup.string()
      .max(50)
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match!')
      .required('Confirm Password Required!')
})
