import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { setEmailCredsStart, updateProfileStart,updateSiteConfigurationStart } from '../../store/redux-slices/login'
import { getSuperAdminDetails } from '../../utils/apiCalls'
import useCheckPermission from '../../utils/checkPermission';

const useProfilePage = (isTenant) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selectedTab, setSelectedTab] = useState('overview')
  const [editable, setEditable] = useState(false)
  const [editableCreds, setEditableCreds] = useState(false)
  const [editableSiteConfig, setEditableSiteConfig] = useState(false)

  const [isGetLoading, setIsGetLoading] = useState(false)
  const [details, setDetails] = useState()
  const { loading, resetPassLoading } = useSelector(state => state.login)
  const { adminDetails } = useSelector(state => state.admins);

  const myParam = useLocation().search;
  const tabParam = new URLSearchParams(myParam).get("tab");
  useEffect(() => {
    if(tabParam === "password") {
        setSelectedTab(tabParam);
    }
  }, [tabParam])
  const updateData = (data) => {
    dispatch(updateProfileStart({
      data,
      isTenant
    }))
    setEditable(false)
  }

  const updateSiteConfiguration = (data) => {
    dispatch(updateSiteConfigurationStart({
      data,
      isTenant
    }))
    setEditableSiteConfig(false)
  }

  
  const updateCredentials = ({ data }) => {
    dispatch(setEmailCredsStart({
      data: {
        sendgridKey: Buffer.from(data.sendgridKey).toString('base64'),
        sendgridEmail: Buffer.from(data.sendgridEmail).toString('base64')
      }
    }))
    setEditableCreds(false)
  }

  useEffect(() => {

    setIsGetLoading(true)
    async function fetchData () {
      await getSuperAdminDetails().then((res) => {
        setDetails(res?.data?.data?.adminDetails);
        setIsGetLoading(false)
      })
    }
    fetchData()
  }, [])

  const {isHidden} = useCheckPermission()

  return {
    details,
    selectedTab,
    setSelectedTab,
    navigate,
    editable,
    setEditable,
    updateData,
    loading,
    editableCreds,
    updateCredentials,
    adminDetails,
    setEditableCreds,
    resetPassLoading,
    updateSiteConfiguration,
    isGetLoading,
    editableSiteConfig,
    setEditableSiteConfig,
    isHidden
  }
}

export default useProfilePage
