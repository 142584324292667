import React, { useEffect } from 'react'
import { Table } from '@themesberg/react-bootstrap'
import { getPercentageColor, getTextColor } from '../../utils/dashboardEffects'
import { defaultCurrency } from '../../utils/constant'
import FormatNumber from '../../utils/formatNumber'
const UserReport = ({ tableData, isPlayer, setPage }) => {
  useEffect(() => {
    setPage(1)
  }, [])
  return (
    <div style={{ overflow: 'auto', maxHeight: '385px' }}>
      <Table striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-light'>
          <tr>
            {isPlayer
              ? ['ID',
                'Name',
                'Number of Rounds',
                'Total Bets',
                'Total Wins',
                'Game Revenue',
                'Payout'
              ].map((h) => (
                <th key={h}>{h}</th>
              ))
              : [
                'ID',
                'Valores dos Apostas',
                'Quantidade dos Apostas',
                'Valores dos Prêmios',
                'Quantidade dos Prêmios',
                'GGR',
              ].map((h) => (
                <th key={h}>{h}</th>
              ))}
          </tr>
        </thead>

        <tbody>
          {tableData && tableData.length > 0
            ? tableData.map(({ userId, userCount, gameCount, betAmount, betCount, winAmount, winCount, ggr }, idx) => {
              return (
                <tr key={`top-loosers ${userId}`}>
                  <td>{userId}</td>
                  <td>{FormatNumber.currency(betAmount || 0)}</td>
                  <td>{betCount || '0'}</td>
                  <td>{FormatNumber.currency(winAmount || 0)}</td>
                  <td>{winCount || '0'}</td>
                  <td className={`${getTextColor(ggr)}`}>{FormatNumber.currency(ggr || 0)}</td>
                  {/* {!isPlayer && <td>{playerCount || '0'}</td>}
                  <td className={getPercentageColor(payout)}>{payout || '0'}</td> */}
                </tr>
              )
            })
            : (
              <tr>
                <td colSpan={7} className='text-danger text-center'>
                  Registros não encontrados
                </td>
              </tr>
            )}
        </tbody>
      </Table>
    </div>
  )
}

export default UserReport
