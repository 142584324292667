import React from 'react'
import {
    Button,
    //   Form,
    Row,
    Col,
    //   Table
} from '@themesberg/react-bootstrap'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import { motion } from 'framer-motion/dist/framer-motion'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faArrowCircleDown, faArrowCircleUp, faRedoAlt } from '@fortawesome/free-solid-svg-icons'
// import PaginationComponent from '../../../components/Pagination'
import BetTable from './BetTable'
import BetSettingsForm from './BetSettingsForm'
import useBetSettings from './useBetSettings'

export default () => {
    const {
        toggleAddModal,
        showAddModal,
        editData,
        setEditData,
        updateBetSettingsStart,
        isUpdateBetSettingLoading,
        addBetSettingsStart,
        sportsListInfo
    } = useBetSettings();
    return (
        <>
            <div className='section-header-wrap mt-n3 mx-n3'>
                <Row>
                    <Col lg={8} xs={7}>
                        <h2 className='title-text'>Bet Settings</h2>
                    </Col>
                    <Col lg={4} xs={5}>
                        {/* Button to create new admin */}
                        <div className='d-flex justify-content-end'>
                            <motion.div
                                initial={{ opacity: 0, scale: 0.5 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 0.5 }}
                            >
                                <Button
                                    variant='btn btn-primary w-100'
                                    onClick={toggleAddModal}
                                    // hidden={isHidden({ module: { key: 'Currencies', value: 'C' } })}
                                    style={{ maxWidth: '180px' }}
                                >
                                    <AddCircleOutlineRoundedIcon className='me-2' />
                                    <span>Bet Settings</span>
                                </Button>
                            </motion.div>
                        </div>
                    </Col>

                </Row>
            </div>
            <BetTable
                toggleAddModal={toggleAddModal}
                setEditData={setEditData}
            />
            {
                showAddModal &&
                <BetSettingsForm
                    toggleAddModal={toggleAddModal}
                    editData={editData}
                    show={showAddModal}
                    loading={isUpdateBetSettingLoading}
                    updateBetSettingsStart={updateBetSettingsStart}
                    addBetSettingsStart={addBetSettingsStart}
                    sportsListInfo={sportsListInfo}
                />
            }

        </>
    )
}
