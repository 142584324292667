import { takeLatest, put } from 'redux-saga/effects'
import {
  getSportsList,
  getCountriesList,
  getMatchesList,
  getMarketsList,
  getTournamentsList,
  updateStatus,
  addFeaturedApi,
  uploadImageApi,
  getSportsMatchesDetailApi,
  updateOddsVariationApi,
  detachOddsVariationApi,
  updateCompanyOddApi
} from '../../utils/apiCalls'
import { toast } from '../../components/Toast'

import {
  getSportsListStart,
  getSportsListSuccess,
  getSportsListFailure,
  getCountriesListStart,
  getCountriesListSuccess,
  getCountriesListFailure,
  getMatchesListStart,
  getMatchesListSuccess,
  getMatchesListFailure,
  getMarketsListStart,
  getMarketsListSuccess,
  getMarketsListFailure,
  getTournamentsListStart,
  getTournamentsListSuccess,
  getTournamentsListFailure,
  updateStatusStart,
  updateStatusSuccess,
  updateStatusFailure,
  addFeaturedStart,
  addFeaturedSuccess,
  addFeaturedFailure,
  uploadImageStart,
  uploadImagefailure,
  uploadImageSuccess,
  getSportsMatchesDetailStart,
  getSportsMatchesDetailSuccess,
  getSportsMatchesDetailfailure,
  updateOddsVariationStart,
  updateOddsVariationSuccess,
  updateOddsVariationFailure,
  detachOddsVariationStart,
  updateCompanyOddStart,
  updateCompanyOddSuccess
} from '../redux-slices/sportsBook'

export default function* sportsBookWatcher() {
  yield takeLatest(getSportsListStart.type, getSportsListWorker)
  yield takeLatest(getCountriesListStart.type, getCountriesListWorker)
  yield takeLatest(getMatchesListStart.type, getMatchesListWorker)
  yield takeLatest(getMarketsListStart.type, getMarketsListWorker)
  yield takeLatest(getTournamentsListStart.type, getTournamentsListWorker)
  yield takeLatest(updateStatusStart.type, updateStatusWorker)
  yield takeLatest(addFeaturedStart.type, addFeaturedWorker)
  yield takeLatest(uploadImageStart.type, uploadImageWorker)
  yield takeLatest(getSportsMatchesDetailStart.type, getSportsMatchesDetailWorker)
  yield takeLatest(updateOddsVariationStart.type, updateOddsVariationWorker)
  yield takeLatest(detachOddsVariationStart.type, detachOddsVariationWorker)
  yield takeLatest(updateCompanyOddStart.type, updateCompanyOddWorker)
}



function* getSportsListWorker(action) {
  try {
    const { data } = yield getSportsList(action.payload)
    yield put(getSportsListSuccess(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getSportsListFailure(e?.response?.data?.errors[0]?.description))
  }
}

function* getCountriesListWorker(action) {
  try {
    const { data } = yield getCountriesList(action.payload)
    yield put(getCountriesListSuccess(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getCountriesListFailure(e?.response?.data?.errors[0]?.description))
  }
}

function* getMatchesListWorker(action) {
  try {
    const { data } = yield getMatchesList(action.payload)
    yield put(getMatchesListSuccess(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getMatchesListFailure(e?.response?.data?.errors[0]?.description))
  }
}

function* getMarketsListWorker(action) {
  try {
    const { data } = yield getMarketsList(action.payload)
    yield put(getMarketsListSuccess(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getMarketsListFailure(e?.response?.data?.errors[0]?.description))
  }
}
function* getTournamentsListWorker(action) {
  try {
    const { data } = yield getTournamentsList(action.payload)
    yield put(getTournamentsListSuccess(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getTournamentsListFailure(e?.response?.data?.errors[0]?.description))
  }
}
function* updateStatusWorker(action) {
  try {
    const { data } = yield updateStatus(action.payload)
    yield put(updateStatusSuccess(action.payload))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateStatusFailure(e?.response?.data?.errors[0]?.description))
  }
}

function* addFeaturedWorker(action) {
  const {
    providerMatchId,
    isFeatured,
  } = action.payload;
  try {
    yield addFeaturedApi({
      providerMatchId: providerMatchId,
      isFeatured: isFeatured.toString()
    })
    yield put(addFeaturedSuccess(action.payload))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(addFeaturedFailure(e?.response?.data?.errors[0]?.description))
  }
}

function* uploadImageWorker(action) {
  try {
    const { data } = yield uploadImageApi(action.payload)
    yield toast(data?.data?.message, 'success')
    yield put(uploadImageSuccess(action.payload))
  } catch (e) {
    yield toast('error')
    yield put(uploadImagefailure())
  }
}

function* getSportsMatchesDetailWorker(action) {
  try {
    const { data } = yield getSportsMatchesDetailApi(action.payload)
    yield put(getSportsMatchesDetailSuccess(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getSportsMatchesDetailfailure(e?.response?.data?.errors[0]?.description))
  }
}

function* updateOddsVariationWorker(action) {
  try {
    const { data } = yield updateOddsVariationApi(action.payload)
    if (data.errors.length === 0) {
      action.payload.toggleModal && action.payload.toggleModal()
      action.payload.setVaryPercentage && action.payload.setVaryPercentage()
      yield put(updateOddsVariationSuccess(data?.data))
      yield put(getSportsMatchesDetailStart({ matchId: action.payload.matchId }))
      yield toast(data?.data?.message, 'success')
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateOddsVariationFailure(e?.response?.data?.errors[0]?.description))
  }
}

function* detachOddsVariationWorker(action) {
  try {
    const { data } = yield detachOddsVariationApi(action.payload)
    if (data.errors.length === 0) {
      action.payload.toggleDetachMarketModal && action.payload.toggleDetachMarketModal()
      yield put(updateOddsVariationSuccess(data?.data))
      yield toast(data?.data?.message, 'success')
      yield put(getSportsMatchesDetailStart({ matchId: action.payload.marketId }))
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateOddsVariationFailure(e?.response?.data?.errors[0]?.description))
  }
}

function* updateCompanyOddWorker(action) {
  try {
    const { data } = yield updateCompanyOddApi({ ...action.payload })
    if (data.errors.length === 0) {
      yield toast(data?.data?.message, 'success')
      yield put(updateCompanyOddSuccess(action.payload))
      // yield put(getSportsMatchesDetailStart(action.payload))
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateOddsVariationFailure(e?.response?.data?.errors[0]?.description))
  }
}