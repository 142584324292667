import { Col, Row, Table } from '@themesberg/react-bootstrap'
import React, { useEffect, useState } from 'react'

import { walletType, transactionType } from '../../TransactionsBanking/constants'
import { findTypeStatus } from '../../SportsTransactions/constants'
import FormatNumber from '../../../../utils/formatNumber'
import FormatDate from '../../../../utils/formatDate'
import { getAffiliateTransactions } from '../../../../utils/apiCalls'
import { Loading } from '../../../../components/Preloader'
import { toast } from '../../../../components/Toast'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { DashboardHeadTitle } from '../style'
import { clipboard } from '../../../../utils/clipboard'
import Trigger from '../../../../components/OverlayTrigger'
import useDebounce from '../../../../hooks/useDebounce'
import { AMOUNT_TYPE_LABEL, STATUS_LABEL, STATUS_VALUE } from '../../../../utils/constant'
import PaginationComponent from '../../../../components/Pagination'
import { formatDateYMD, getDateDaysAgo } from '../../../../utils/dateFormatter'
import { resolveErrors } from '../../../../utils/errorTypes'
import UpdateButtonIcon from '../../../../components/UpdateButtonIcon'
import DateRangePicker from '../../../../components/DateRangePicker'

export const tableHeaders = [
  { label: 'Usuário', value: 'transactionAffiliate.username' },
  { label: 'UUID da Transação', value: 'transactionUUID' },
  { label: 'Tipo de ação', value: 'actioneeType' },
  { label: 'Valor', value: 'amount' },
  { label: 'Tipo de transação', value: 'transactionType' },
  { label: 'Tipo de valor', value: 'amountType' },
  { label: 'Status', value: 'status' },
  { label: 'Saldo antes da transação', value: 'sourceBeforeBalance' },
  { label: 'Saldo depois da transação', value: 'sourceAfterBalance' },
  { label: 'Criado em:', value: 'createdAt' },
  { label: 'Atualizado em:', value: 'updated' },
]


const AffiliateTransactionsTable = ({ affiliate, ...props }) => {
  const type = transactionType;
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState({ rows: [], count: 0 });
  const [page, setPage] = useState(1);

  const [dateRange, setDateRange] = useState([{
    startDate: getDateDaysAgo(7),
    endDate: new Date(),
    key: 'selection'
  }])

  const loadAffiliateTransactions = useDebounce(async ({ loading, initialLoading, page }) => {
    if (loading) setLoading(true);
    if (initialLoading) setInitialLoading(true);
    try {
      const transactions = await getAffiliateTransactions({
        affiliateId: affiliate?.affiliateId,
        startDate: formatDateYMD(dateRange.map(a => a.startDate)),
        endDate: formatDateYMD(dateRange.map(a => a.endDate)),
        pageNo: page || 1,
        limit: 15
      })
      setTransactions(transactions.data.data);
    } catch (err) {
      toast(resolveErrors(err?.response?.data?.errors, "Error ao obter transações do afiliado"), "error");
    } finally {
      setLoading(false)
      setInitialLoading(false)
    }
  }, 400)

  const handleWalletType = ({ transactionType, amountType }) => {
    if (['addMoney', 'removeMoney', 'addMoneyInternal', 'removeMoneyInternal']?.includes(transactionType)) {
      return <>({walletType[amountType]})</>
    }
  }

  useEffect(() => {
    if (affiliate?.affiliateId) loadAffiliateTransactions({ initialLoading: true })
  }, [affiliate, dateRange])

  if (!affiliate) return;

  return (
    <div className='TableBg mt-4 p-2' {...props}>
      <DashboardHeadTitle className="mt-2 px-2 d-flex justify-content-between">
        <Col md={6} xs={12}>
          <h3 className="dashboard-spec-text">Transações</h3>
        </Col>
        <Col md={6} xs={12}>
          <Row className='mt-0 justify-content-end'>
            <Col xs={1} md={1} className='d-flex align-items-center justify-content-end'>
              <UpdateButtonIcon
                onClick={() => loadAffiliateTransactions({ loading: true })}
                {...{ loading }}
              />
            </Col>
            <Col xs={6} md={6} >
              <DateRangePicker
                state={dateRange} setState={setDateRange}
                size='sm'
                placement={"right"}
              />
            </Col>
          </Row>
        </Col>
      </DashboardHeadTitle>
      <div className='position-relative h-100'>
        <Table striped responsive hover size='sm' className='text-center'>
          <thead className='thead-light'>
            <tr>
              {tableHeaders.map((h, idx) => (
                <th
                  key={`T-table_heading ${idx}`}
                  className={`${['Amount'].includes(h.label) && transactions?.count > 0 ? 'text-right' : ''}`}
                >
                  {h.label}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {transactions && !initialLoading &&
              transactions?.rows?.map(
                ({
                  affiliateTransactionId,
                  transactionUUID,
                  createdAt,
                  updatedAt,
                  actioneeEmail,
                  amount,
                  currencyCode,
                  transactionType,
                  amountType,
                  actioneeType,
                  status,
                  transactionAffiliate,
                  affiliateWalletAfterAmount,
                  affiliateWalletBeforeAmount,
                }) => {
                  return (
                    <tr key={`transaction-list ${affiliateTransactionId}`}>
                      <td>{transactionAffiliate?.username}</td>
                      <Trigger delay={{ show: 300 }} message={"Copiar"}>
                        <td className='cursor-pointer' onClick={() => clipboard(transactionUUID)}>{transactionUUID || 'NA'}</td>
                      </Trigger>
                      <td>{actioneeType || 'N/A'}</td>
                      <td className={`text-right ${["removeMoney", "withdraw"].includes(transactionType) ? 'text-danger' : transactionType == "addMoney" ? 'text-success' : ""}`}>
                        {["removeMoney", "withdraw"].includes(transactionType) ? "-" : ""} {FormatNumber.currency(amount || 0, currencyCode)}
                      </td>
                      <td>{type?.find((type) => type.value === transactionType)?.label} {handleWalletType({ transactionType, amountType })} </td>
                      <td><span className='w-100' data-badge={"info"}>{AMOUNT_TYPE_LABEL[amountType]}</span></td>
                      <td><span className='w-100' data-badge={String(STATUS_VALUE[status]).toLowerCase()}>{STATUS_LABEL[STATUS_VALUE[status]]}</span></td>
                      <td className='text-right'>
                        {FormatNumber.currency(affiliateWalletBeforeAmount)}
                      </td>
                      <td className='text-right'>
                        {FormatNumber.currency(affiliateWalletAfterAmount)}
                      </td>
                      <td>{FormatDate.default(createdAt)}</td>
                      <td>{FormatDate.default(updatedAt)}</td>
                    </tr>
                  )
                })}

            {transactions?.count === 0 && !loading && (
              <tr>
                <td colSpan={10} className='text-danger text-center'>
                  Nenhuma transação encontrada
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        {initialLoading ? <Loading className={"position-absolute bg-white top-0 w-100 h-100"} /> : null}
      </div>
      {transactions?.count > 0 && !initialLoading && (
        <PaginationComponent
          page={transactions?.count < page ? setPage(1) : page}
          totalPages={transactions.totalPages}
          setPage={(page) => {
            setPage(page);
            loadAffiliateTransactions({ loading: true, page });
          }}
        />
      )}
    </div>
  )
}

export default AffiliateTransactionsTable
