import styled from 'styled-components';

export const BigSize = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        width: 120px !important;
        height: 120px;
    }
`;