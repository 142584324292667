import React from 'react'
import { Form } from '@themesberg/react-bootstrap'
import useClientFilter from './useClientFilter'

const ClientFilter = ({ cms = false, cmsData = false, setSelectedClient, setSelectedPortal, selectedClient, hasAllOptions = true }) => {
  const { clientsFilterData } = useClientFilter()

  return (
    <>
      {/* <Form.Label style={{ marginBottom: '0', marginRight: '15px' }}>
        Owner
      </Form.Label> */}
    
      <Form.Select
        onChange={(e) => {
          setSelectedClient(e.target.value)
          setSelectedPortal('')
        }}
        
        value={selectedClient}
        size={cmsData ? '' : 'xs'}
        disabled={cms}
      >
        <option value='' disabled={!hasAllOptions}>
          {hasAllOptions ? 'All' : 'Choose Tenant Owner'}
        </option>

        {clientsFilterData?.count === 0 && (
          <option value='' disabled>
            No Tenant Owners Available
          </option>
        )}

        {clientsFilterData?.rows?.map(
          ({ adminUserId, firstName, lastName }) => (
            <option key={adminUserId} value={adminUserId}>
              {`${firstName} ${lastName}`}
            </option>
          )
        )}
      </Form.Select>
    </>
  )
}

export default ClientFilter
