import { takeLatest, put, select } from 'redux-saga/effects'
import {
  addInternalTag,
  getAdmin,
  getAdminChildren,
  getAllAdmins,
  getAllBonus,
  getSuperAdminDetails,
  getTenantOwnerDetails,
  superAdminViewToggleStatus,
  tenantViewToggleStatus,
  reorderBonus,
  getAdminInformationSettingsService
} from '../../utils/apiCalls'

import {
  getAdminPanelSettingsStart,
  getAdminPanelSettingsSuccess,
  getAdminPanelSettingsFailure,
  getAllAdminsStart,
  getAllAdminsSuccess,
  getAllAdminsFailure,
  getAdminDetailsStart,
  getAdminDetailsSuccess,
  getAdminDetailsFailure,
  getSuperAdminDetailsStart,
  getSuperAdminDetailsSuccess,
  getSuperAdminDetailsFailure,
  updateTenantAdminStatusStart,
  updateTenantAdminStatusComplete,
  getAllBonusStart,
  getAllBonusComplete,
  getAllBonusFailure,
  getAdminChildrenStart,
  getAdminChildrenSuccess,
  getAdminChildrenFailure,
  getAdminDataStart,
  getAdminDataSuccess,
  getAdminDataFailure,
  addUserInternalTagtStart,
  addUserInternalTagtSuccess,
  addUserInternalTagtFailure,
  updateReorderBonusStart,
  updateReorderBonusComplete,
  getLoggedSADetailsSuccess
} from '../redux-slices/admins'

import { toast } from '../../components/Toast'
import { getAllClientsStart } from '../redux-slices/tenants'
import { getAllTenantAdminsStart } from '../redux-slices/tenantsFetchData'
import { cloneDeep } from 'lodash'
import { getAllTABonusStart } from '../redux-slices/bonus'
import { superAdminLoginSuccess } from '../redux-slices/login'

export default function* adminssWatcher() {
  yield takeLatest(getAdminPanelSettingsStart.type, getAdminPanelSettingsWorker)
  yield takeLatest(getAllAdminsStart.type, getAllAdminsWorker)
  yield takeLatest(getAdminDetailsStart.type, getAdminDetailsWorker)
  yield takeLatest(getSuperAdminDetailsStart.type, getSuperAdminDetailsWorker)
  yield takeLatest(updateTenantAdminStatusStart.type, updateTenantAdminStatusWorker)
  yield takeLatest(getAllBonusStart.type, getBonusListingWorker)
  yield takeLatest(getAdminChildrenStart.type, getAdminChildrenWorker)
  yield takeLatest(getAdminDataStart.type, getAdminDataWorker)
  yield takeLatest(addUserInternalTagtStart.type, addInternalUserTagWorker)
  yield takeLatest(updateReorderBonusStart.type, updateReorderBonusWorker)
}
const getAdminState = (state) => state.admins

function* getAdminPanelSettingsWorker(action) {
  try {
    const { data } = yield getAdminInformationSettingsService()
    yield put(getAdminPanelSettingsSuccess(data.data || {}))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAdminPanelSettingsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function* getAllAdminsWorker(action) {
  try {
    const { limit, pageNo, orderBy, search, sort, adminRoleId, shouldGetAffiliateUsers = true, status = '' } =
      action && action.payload

    const { data } = yield getAllAdmins({
      limit,
      pageNo,
      orderBy,
      search,
      sort,
      // superAdminId,
      adminRoleId: adminRoleId,
      status,
      shouldGetAffiliateUsers

    })

    yield put(getAllAdminsSuccess(data?.data?.adminDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllAdminsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function* getAdminDetailsWorker(action) {
  try {
    const { adminId } = action && action.payload

    const { data } = yield getAdmin({ adminId })

    yield put(getAdminDetailsSuccess(data?.data?.adminDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAdminDetailsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function* getSuperAdminDetailsWorker(action) {
  try {
    const { isTenant = false, onStart = false } = action && action.payload
    const { data } = yield getSuperAdminDetails()
    yield put(superAdminLoginSuccess(data?.data?.adminDetails?.AdminRole?.name || "Admin"))
    yield put(getSuperAdminDetailsSuccess(data?.data?.adminDetails))
    if (onStart) yield put(getLoggedSADetailsSuccess(data?.data?.adminDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getSuperAdminDetailsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function* getAdminDataWorker(action) {
  try {
    const { adminId } = action && action.payload

    const { data } = yield getAdmin({ adminId })

    yield put(getAdminDataSuccess(data?.data?.adminDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAdminDataFailure(e?.response?.data?.errors[0]?.description))
  }
}

function* updateTenantAdminStatusWorker(action) {
  try {
    const {
      adminId,
      status,
      limit,
      pageNo,
      orderBy,
      sort,
      search,
      isTenant = false,
      active = ''
    } = action && action.payload

    isTenant
      ? yield tenantViewToggleStatus({ adminId, status, code: 'ADMIN' })
      : yield superAdminViewToggleStatus({ adminId, status, code: 'ADMIN' })

    yield put(updateTenantAdminStatusComplete())

    isTenant
      ? yield put(getAllTenantAdminsStart({ limit, pageNo, sort, orderBy, search, status: active }))
      : yield put(
        getAllClientsStart({ limit, pageNo, sort, orderBy, search, status: active })
      )

    yield toast('Status Updated Successfully', 'success')
  } catch (e) {
    yield put(updateTenantAdminStatusComplete())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function* getBonusListingWorker(action) {
  try {
    const { adminId = '', tenantId = '', limit = '', pageNo = '', bonusType = '', isActive = '', search = '', userId = '', reorder = false } = action && action.payload

    const { data } = yield getAllBonus({ adminId, tenantId, limit, pageNo, bonusType, isActive, search, userId, reorder })

    yield put(getAllBonusComplete(data?.data?.bonus))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllBonusFailure(e?.response?.data?.errors[0]?.description))
  }
}

function* getAdminChildrenWorker(action) {
  try {
    const { superAdminId, superRoleId } =
      action && action.payload
    const { adminChildren } = yield select(getAdminState)

    const addChildrenToAdmin = (newAdminChildren, id, children) => {
      if (newAdminChildren?.id === id) {
        return (newAdminChildren.children = [...children])
      }

      if (newAdminChildren?.children?.length) {
        for (const admin of newAdminChildren.children) {
          addChildrenToAdmin(admin, id, children)
        }
      }
    }

    const { data } = yield getAdminChildren({ superAdminId, superRoleId })

    const newAdminChildren = cloneDeep(adminChildren)
    const children = data?.data?.adminDetails?.map((item) => {
      return {
        id: item.adminUserId,
        name: `${item.firstName || ''} (${item.childCount})`,
        children: [],
        data: item
      }
    })
    yield addChildrenToAdmin(newAdminChildren, superAdminId, children)

    yield put(getAdminChildrenSuccess(newAdminChildren))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(getAdminChildrenFailure())
  }
}

function* addInternalUserTagWorker(action) {
  try {
    const { data, isTenant } = action && action.payload

    const res = yield addInternalTag({ data, isTenant })
    yield toast(res.data.message, 'success')
    yield put(addUserInternalTagtSuccess())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(addUserInternalTagtFailure(e?.response?.data?.errors[0]?.description))
  }
}

function* updateReorderBonusWorker(action) {
  try {
    const { data, isTenant } = action && action.payload

    const res = yield reorderBonus({ data, isTenant })
    yield toast(res?.data?.message, 'success')
    if (data?.adminId) {
      yield put(getAllBonusStart({ adminId: data?.adminId, tenantId: data?.tenantId, reorder: true }))
    } else {
      yield put(getAllTABonusStart({
        limit: '',
        pageNo: '',
        search: '',
        bonusType: '',
        isActive: '',
        reorder: true
      }))
    }
    yield put(updateReorderBonusComplete())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateReorderBonusComplete(e?.response?.data?.errors[0]?.description))
  }
}
