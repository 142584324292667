import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getLanguageSupportKeysStart, updateLanguageSupportStart } from '../../../store/redux-slices/languages'
import { getItem } from '../../../utils/storageUtils'
import { keysNotToBeShown } from './constant'

const useAddLanguage = (isTenant) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const tenantId = getItem('tenant-id')
  const { lang } = useParams()
  const { languageKeys, languageTabs } = useSelector(state => state.languages)
  const [myKeys, setMyKeys] = useState({})
  const [isError, setIsError] = useState({})
  const [selectedKeyTab, setSelectedKeyTab] = useState('')

  useEffect(() => {
    dispatch(getLanguageSupportKeysStart({ isTenant, tenantId: isTenant ? tenantId : '', language: '' }))
  }, [])

  useEffect(() => {
    if (languageKeys && languageKeys?.length) {
      const data = {}
      for (const key in languageKeys[0]) {
        if (!keysNotToBeShown.includes(key)) data[key] = ''
      }
      setMyKeys(data)
    }
  }, [languageKeys])

  useEffect(() => {
    if (languageTabs && Object.keys(languageTabs)?.length) {
      setSelectedKeyTab(Object.keys(languageTabs)?.[0])
    }
  }, [languageTabs])

  const updateData = (data) =>
    dispatch(updateLanguageSupportStart({ data, isTenant, tenantId: isTenant ? tenantId : '', navigate }))

  return {
    selectedKeyTab,
    navigate,
    languageTabs,
    setSelectedKeyTab,
    myKeys,
    languageKeyObj: languageKeys?.find(obj => obj.language === 'EN'),
    lang,
    updateData,
    isError,
    setIsError
  }
}

export default useAddLanguage
