import { createSlice } from '@reduxjs/toolkit';
export const referralManagementSlice = createSlice({
    name:'referralManagement',
    initialState:{
        loading: false,
        error: null,
        success: false,
        referralManagement: null,
    },
    reducers:{
        getReferralManagementStart:(state)=>({
            ...state,
            loading: true,
        }),
        getReferralManagementSuccess:(state, {payload})=>({
            ...state,
            loading: false,
            error: null,
            success: true,
            referralManagement: payload,
        }),
        getReferralManagementFailure:(state, {payload})=>({
            ...state,
            loading: false,
            error: payload,
            success: false,
        }),
        updateReferralManagementStart:(state,{payload})=>({
            ...state,
            loading: true,
        }),
        updateReferralManagementSuccess:(state, {payload})=>({
            ...state,
            loading: false,
            error: null,
            success: true,
        }),
        updateReferralManagementFailure:(state,{payload})=>({
            ...state,
            loading: false,
            error: payload,
            success: false,
        })
    }
})

export default referralManagementSlice.reducer
export const {
    getReferralManagementStart,
    getReferralManagementSuccess,
    getReferralManagementFailure,
    updateReferralManagementStart,
    updateReferralManagementSuccess,
    updateReferralManagementFailure,
} =referralManagementSlice.actions
