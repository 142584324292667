import {
  faCheck,
  faCopy,
  faEdit,
  faTimes
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Tabs,
  Tab,
  Row,
  Col,
  Button,
  ButtonGroup,
  Badge,
  Form
} from '@themesberg/react-bootstrap'
import React, { useEffect } from 'react'
import Trigger from '../../../../components/OverlayTrigger'
import Preloader from '../../../../components/Preloader'
import WageringTemplateDetails from '../../../../components/WageringTemplateDetails'
import { BONUS_TYPE_CONSTANTS, convertAmountOptions } from '../components/CreateBonus/constants'
import useBonusDetail from '../components/hooks/useBonusDetail'
import GeneralDetails from './generalDetail'
import useCheckPermission from '../../../../utils/checkPermission'
import Games from '../components/CreateBonus/Games'
import LoyaltyPoint from '../../../../components/LoyaltyPoints/LoyaltyPoint'
import LanguageDetails from './LanguageDetails'
import BonusCountry from '../components/CreateBonus/BonusCountry'
import { getAllBonusStart } from '../../../../store/redux-slices/admins'
import { useDispatch, useSelector } from 'react-redux'

const BonusDetail = () => {
  const {
    selectedTab,
    setSelectedTab,
    bonusDetail,
    loading,
    bonusId,
    navigate,
    paymentProviders,
    wageringTemplateDetail,
    pageNo,
    setPageNo,
    limit,
    setLimit,
    totalPages,
    gameIds,
    setGameIds,
    casinoGamesData,
    search,
    setSearch,
    selectedProvider,
    setSelectedProvider,
    gamesLimit,
    gamesPageNo,
    setGamesLimit,
    setGamesPageNo,
    wagerSearch,
    setWagerSearch,
    selectedLang,
    setSelectedLang,
    languages,
    countryData,
    setCountryData
  } = useBonusDetail()
  const { isHidden } = useCheckPermission()
  const { bonusList } = useSelector((state) => state.admins)
  const dispatch = useDispatch()

  const isAlreadyExistsJoiningBonus = bonusList?.rows.find((bonus) => bonus.bonusType === "joining");
  useEffect(() => {
    if (!bonusList) {
      dispatch(getAllBonusStart({
        adminId: '',
        tenantId: '',
        limit: "15",
        pageNo: 1,
        search: "",
        bonusType: '',
        isActive: ''
      }))
    }

  }, [bonusList])
  return (
    <>
      {loading && !bonusDetail && bonusDetail?.bonusId !== bonusId
        ? (
          <Preloader />
        )
        : (
          <>
            
              <div className='section-header-wrap mt-n3 mx-n3'>
                <Row>
                  <Col>
                    {' '}
                    <h3>Bonus Details</h3>
                  </Col>
                  <Col className='d-flex justify-content-end '>
                    <ButtonGroup>
                      <Trigger message='Edit'>
                        <Button
                          className='m-1'
                          size='sm'
                          onClick={() =>
                            navigate(`/admin/bonus/edit/${bonusId}`)}
                          hidden={isHidden({ module: { key: 'Bonus', value: 'U' } })}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      </Trigger>
                    </ButtonGroup>
                    {/* {!(isAlreadyExistsJoiningBonus?.bonusId == bonusId )&& <ButtonGroup>
                    <Trigger message='Clone'>
                      <Button
                        className='m-1'
                        size='sm'
                        onClick={() =>
                          navigate(`/admin/bonus/clone/${bonusId}`)}
                        hidden={isHidden({ module: { key: 'Bonus', value: 'C' } })}
                      >
                        <FontAwesomeIcon icon={faCopy} />
                      </Button>
                    </Trigger>
                  </ButtonGroup>} */}
                  </Col>
                </Row>
              </div>


            
            <Tabs
              key={selectedTab}
              activeKey={selectedTab}
              className='nav-light'
              onSelect={(k) => setSelectedTab(k)}
            >
              <Tab eventKey='general' title='General'>
                <div className='mt-5'>
                  <GeneralDetails bonusDetail={bonusDetail} />
                </div>
              </Tab>
              {bonusDetail?.promotionTitle && Object.keys(bonusDetail?.promotionTitle)?.length > 1 &&
                <Tab eventKey='languages' title='Languages'>
                  <div className='mt-5'>
                    <LanguageDetails
                      languages={languages}
                      selectedLang={selectedLang}
                      setSelectedLang={setSelectedLang}
                      bonusDetail={bonusDetail}
                    />
                  </div>
                </Tab>
              }
               
                <Tab eventKey='currency' title='Currency'>
                  <div className='mt-3'>
                    <Row>
                      <h3>Currencies</h3>
                      {bonusDetail?.currency &&
                        Object.keys(bonusDetail.currency).length > 0 &&
                        Object.keys(bonusDetail.currency).map((key, index) => (
                          <div className='FormsBg'>
                            <Row key={index} className='g-2 mb-2'>
                              <Col sm={bonusDetail?.bonusType === 'freespins' && 5} className='d-flex w-100'>
                                <Col className='px-1 text-center'>
                                  {index < 1 && (
                                    <label htmlFor='currency' style={{ fontSize: '14px' }}>Currency</label>
                                  )}
                                  <p>{key}</p>
                                </Col>
                                {Object.keys(bonusDetail.currency[key]).map(
                                  (currKey, currIndex) => {
                                    let hide = false;
                                    let values = bonusDetail
                                    if (values?.bonusType === 'joining') {
                                      if (currKey !== 'joiningAmount' && currKey !== 'minDeposit') {
                                        hide = true;
                                      }
                                    } else {
                                      if (currKey == 'joiningAmount' || currKey === 'minBalance') {
                                        hide = true;
                                      }
                                    }
                                    return (
                                      currKey !== 'minBonusThreshold' &&
                                      <Col
                                        className='px-1 text-center'
                                        key={currIndex}
                                        hidden={hide}
                                      >
                                        {index < 1 && (
                                          <label htmlFor={currKey} style={{ fontSize: '14px' }}>
                                            {
                                              ['depositCashback', 'wagering'].includes(bonusDetail?.bonusType)
                                                ? convertAmountOptions?.find(
                                                  (val) => currKey === 'minBalance' ? val.value === 'minBalanceCash' : val.value === currKey
                                                )?.label
                                                : convertAmountOptions?.find(
                                                  (val) => val.value === currKey
                                                )?.label
                                            }
                                          </label>
                                        )}

                                        <Form.Control
                                          value={bonusDetail.currency[key][currKey]}
                                          hidden={hide}
                                          disabled
                                        />
                                      </Col>
                                    )
                                  }
                                )}
                              </Col>
                            </Row>
                          </div>

                        ))}

                      <Col />
                    </Row>
                  </div>
                </Tab>
              

              {/* {bonusDetail?.bonusType === 'deposit' && (
                <Tab eventKey='payment' title='Applies to Payment method'>
                  <div className='mt-5'>
                    {paymentProviders &&
                      paymentProviders?.map(
                        ({ depositProviders, tenant, tenantId }) => (
                          <Row key={tenantId} className='mb-2'>
                            <h5>Portal:&nbsp;{tenant?.name}</h5>
                            {depositProviders?.map(({ name }, index) => (
                              <Col sm={2} key={index} className='d-flex'>
                                <Badge
                                  className='mb-3 mt-1 rounded-circle'
                                  style={{ height: 'fit-content' }}
                                  bg={
                                    bonusDetail?.paymentMethods?.find(obj => obj.name === name)
                                      ? 'success'
                                      : 'dark'
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={
                                      bonusDetail?.paymentMethods?.find(obj => obj.name === name)
                                        ? faCheck
                                        : faTimes
                                    }
                                  />
                                </Badge>

                                <span className='mx-2'> {name}</span>
                              </Col>
                            ))}
                          </Row>
                        )
                      )}
                  </div>
                </Tab>
              )} */}





              {bonusDetail?.bonusType === 'freespins' && (
                <Tab
                  eventKey='games'
                  title='Games'
                >
                  <div className='mt-5'>
                    <Games
                      gameIds={gameIds}
                      setGameIds={setGameIds}
                      setLimit={setGamesLimit}
                      setPageNo={setGamesPageNo}
                      setSearch={setSearch}
                      search={search}
                      totalPages={totalPages}
                      limit={gamesLimit}
                      pageNo={gamesPageNo}
                      loading={loading}
                      casinoGamesData={casinoGamesData}
                      selectedProvider={selectedProvider}
                      setSelectedProvider={setSelectedProvider}
                      setSelectedTab={setSelectedTab}
                      details
                    />
                  </div>
                </Tab>
              )}
              {[BONUS_TYPE_CONSTANTS.CASINO_DEPOSIT,BONUS_TYPE_CONSTANTS.SPORTS_DEPOSIT].includes(bonusDetail?.bonusType)&& <Tab eventKey='countries' title='Countries'>
                <div className='mt-5'>
                  <BonusCountry
                    data={countryData}
                    setData={setCountryData}
                    details
                    languages={languages}
                  />
                </div>
              </Tab>}
            </Tabs>
          </>
        )}
    </>
  )
}
export default BonusDetail
