import React from 'react'
import PaginationComponent from '../../../../components/Pagination'
import useCasino from '../hooks/useCasino'
import { Col, Form, Row, Table } from '@themesberg/react-bootstrap'
import Trigger from '../../../../components/OverlayTrigger'
import ProvidersFilter from '../../../../components/ProviderFilter'

const Casino = () => {
  const {
    limit,
    setLimit,
    page,
    setPage,
    search,
    setSearch,
    active,
    setActive,
    categoryId,
    setCategoryId,
    gameSubCategory,
    gameCategory,
    totalPages,
    subCategoryId,
    setSubCategoryId,
    casinoGamesData,
    selectedProvider,
    setSelectedProvider
  } = useCasino()

  return (
    <>
      <Row>
        <Col xs='auto' className='d-flex align-items-center mb-2'>
          <Form.Label style={{ marginBottom: '0', marginRight: '15px' }}>
            Category
          </Form.Label>

          <Form.Select
            onChange={(e) => setCategoryId(e.target.value)}
            size='sm'
            style={{ maxWidth: '230px', width: 'max-content' }}
            value={categoryId}
          >
            <option key='' value='' disabled>Choose Category</option>
            {gameCategory?.count !== 0
              ? gameCategory?.rows.map(({ tenantGameCategoryId, name }) => {
                return <option key={tenantGameCategoryId} value={tenantGameCategoryId}>{name?.EN}</option>
              })
              : (
                <option key='not founds' value='not found' disabled className='text-danger'>
                  No Categories found
                </option>)}
          </Form.Select>
        </Col>
        <Col xs='auto' className='d-flex align-items-center mb-2'>
          {categoryId !== '' &&
            <>
              <Form.Label style={{ marginBottom: '0', width: '-webkit-fill-available' }}>
                Sub Category
              </Form.Label>

              <Form.Select
                onChange={(e) => setSubCategoryId(e.target.value)}
                size='sm'
                style={{ maxWidth: '230px' }}
                value={subCategoryId}
              >
                <option key='' value=''>Choose Sub Category</option>
                {gameSubCategory?.count !== 0
                  ? gameSubCategory?.rows.map(({ tenantGameSubCategoryId, name }) => {
                    return <option key={tenantGameSubCategoryId} value={tenantGameSubCategoryId}>{name?.EN}</option>
                  })
                  : (
                    <option key='not founds' value='not found' disabled className='text-danger'>
                      No Sub Categories found
                    </option>)}
              </Form.Select>
            </>}
        </Col>

        <Col xs='auto' className='d-flex align-items-center mb-2'>
          {subCategoryId !== '' &&
            <ProvidersFilter
              selectedProvider={selectedProvider}
              setSelectedProvider={setSelectedProvider}
            />}
        </Col>

        <Col xs='auto' className='d-flex align-items-center mb-2'>
          {subCategoryId !== '' &&
            <>
              <Form.Label style={{ marginBottom: '0', marginRight: '15px' }}>
                Search
              </Form.Label>
              <Form.Control
                type='search'
                size='sm'
                style={{ maxWidth: '230px' }}
                value={search}
                placeholder='Search Name'
                onChange={(event) => setSearch(event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, ''))}
              />
            </>}
        </Col>
        <Col xs='auto' className='d-flex align-items-center'>
          {subCategoryId !== '' &&
            <>
              <Form.Label style={{ marginBottom: '0', marginRight: '15px' }}>
                Status
              </Form.Label>

              <Form.Select
                onChange={(e) => { setActive(e.target.value) }}
                size='sm'
                style={{ maxWidth: '230px' }}
                value={active}
              >
                <option value=''>All</option>
                <option value='true'>Active</option>
                <option value='false'>In-Active</option>
              </Form.Select>
            </>}
        </Col>
      </Row>

      {
        subCategoryId !== '' &&
          <>
            <Table  striped responsive hover size='sm' className='text-center mt-4'>
              <thead className='thead-light'>
                <tr>
                  {[
                    'Game Id',
                    'Name',
                    'Thumbnail',
                    'Status'
                  ].map((header, idx) => (
                    <th key={idx}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Boolean(casinoGamesData) &&
                casinoGamesData?.rows?.map(
                  (
                    {
                      name,
                      casinoGameId,
                      thumbnailUrl,
                      casinoProviderId,
                      isActive,
                      operatorStatus
                    },
                    indx
                  ) => {
                    return (
                      <tr key={casinoGameId}>
                        <td>{casinoGameId}</td>

                        <td>
                          <Trigger message={name}>
                            <span
                              style={{
                                width: '100px',
                                cursor: 'pointer'
                              }}
                              className='d-inline-block text-truncate'
                            >
                              {name}
                            </span>
                          </Trigger>
                        </td>

                        <td>
                          <span
                            onClick={() => window.open(thumbnailUrl)}
                            className='text-link'
                            style={{ cursor: 'pointer' }}
                          >
                            View Here
                          </span>
                        </td>

                        <td>
                          {isActive
                            ? (
                              <span className='text-success'>Active</span>
                              )
                            : (
                              <span className='text-danger'>In Active</span>
                              )}
                        </td>

                      </tr>
                    )
                  }
                )}

                {casinoGamesData?.count === 0 &&
                (
                  <tr>
                    <td
                      colSpan={7}
                      className='text-danger text-center'
                    >
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>

            {casinoGamesData?.count !== 0 &&
            (
              <PaginationComponent
                page={casinoGamesData?.count < page ? setPage(1) : page}
                totalPages={totalPages}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
              />
            )}

          </>
      }
    </>
  )
}

export default Casino
