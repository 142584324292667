import React from "react";
import {
    //   Button,
    //   Form,
    //   Row,
    //   Col,
    Table
} from '@themesberg/react-bootstrap'
import { tableHeaders } from './constant'
import useBetSettings from './useBetSettings'
import ActionMenu from "./ActionMenu";
import { getDateTime } from "../../../utils/dateFormatter";

const BetTable = (props) => {
    const { toggleAddModal, setEditData } = props;
    const {
        isGetBetSettingLoading,
        betSettingsList,
        sportsListInfo
    } = useBetSettings();

    const getSportsName = (sportId) => {
        const sportsName = sportsListInfo?.sportsList?.rows?.filter(x => x.sportId === sportId);
        if (sportsName?.length > 0) {
            return sportsName[0].sportName[0].name || '-'
        }
        return 'Default'
    }

    return (
        <div className='TableBg mt-4 pt-1'>

            <Table striped responsive hover size='sm' className='text-center mt-3'>
                <thead className='thead-light'>
                    <tr>
                        {tableHeaders.map((h, idx) => (
                            <th
                                key={idx}
                                style={{
                                    cursor: 'pointer'
                                }}
                                className=""
                            >
                                {h.label}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody id={isGetBetSettingLoading ? 'cover-spin' : ''}>
                    {!isGetBetSettingLoading && Boolean(betSettingsList) &&
                        betSettingsList?.dbBetObj?.map((item, index) => {

                            return (
                                <tr key={index}>
                                    <td>{item.betSettingId}</td>
                                    <td>{getSportsName(item?.sportId?.toString())}</td>
                                    <td>{item.maxBetAmount}</td>
                                    <td>{item.minBetAmount}</td>
                                    <td>{item.maxBetCount}</td>
                                    <td>{item.maxWinAmount}</td>
                                    <td>{item.cashoutPercentage}</td>
                                    <td>{item.minOddLimit}</td>
                                    <td>{item.maxOddLimit}</td>
                                    <td>{item.maxEventCount}</td>
                                    <td>{item.maxMarketOutcomeCount}</td>
                                    <td>{getDateTime(item.updatedAt)}</td>

                                    <td>
                                        <ActionMenu
                                            item={item}
                                            setEditData={setEditData}
                                            toggleAddModal={toggleAddModal}
                                        // userId={userId}
                                        // navigate={navigate}
                                        />
                                    </td>
                                </tr>
                            )
                        }
                        )}

                    {betSettingsList?.count === 0 && !isGetBetSettingLoading && (
                        <tr>
                            <td colSpan={13} className='text-danger text-center'>
                                No data found
                            </td>
                        </tr>
                    )}
                </tbody>


            </Table>
            {/* {userData?.count !== 0 && !loading && (
                <PaginationComponent
                page={userData?.count < page ? setPage(1) : page}
                totalPages={totalPages}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                />
                )} */}
        </div>
    )
};

export default BetTable;