import * as Yup from 'yup'

export const casinoSchema = Yup.object().shape({
  name: Yup.string().required('Game Name Required'),
  gameSubCategoryId: Yup.string().required('Sub-Category  Required').nullable(),
  casinoProviderId: Yup.string()
    .required('Casino Provider Id Required')
    .nullable(),
  thumbnail: Yup.mixed().required('A file is required').test('File Size',
    'File Size Should be Less Than 1MB', (value) => !value || (value && value.size <= 1024 * 1024))
    .test('FILE_FORMAT', 'Uploaded file has unsupported format.',
      (value) => !value || (value && ['image/png', 'image/jpeg', 'image/jpg'].includes(value.type))).nullable()
})
export const editCasinoSchema = Yup.object().shape({
  name: Yup.string().required('Game Name Required'),
  gameSubCategoryId: Yup.string().required('Sub-Category  Required').nullable(),
  casinoProviderId: Yup.string()
    .required('Casino Provider Id Required')
    .nullable()
})
