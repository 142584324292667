import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, InputGroup, Row, Form as BForm, Spinner } from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import { sendEmailSchema } from '../schema'
import BgsImage from '../../../../assets/img/login-image.png'
import LogoImage from '../../../../assets/img/logo.png'
import leafImage from '../../../../assets/img/leaf.png'
import React from 'react'

const TakeUserMail = ({ sendMailLoading, handleSendMail }) => {
  return (
    <>
      <main>
        <section>
          <div className='logoImage'>
            <img src={LogoImage} width={200}></img>
            </div>
            <Row className='justify-content-center vh-100'>
            <Col
              xs={12} md={8}
              className='d-flex align-items-center BGImage admin-left justify-content-center'
            >
               <img src={BgsImage} width={500}></img>
            </Col>
              <Col
                xs={12} md={4}
                className='d-flex align-items-center admin-right justify-content-center bg-white'
              >
                <div className='bg-white p-2 p-lg-5 w-100'>
                  <div className='text-center text-md-center mb-4 mt-md-0'>
                    <h2 className='mb-0 login-heading'>Forgot Password</h2>
                    <p>No worries, we’ll send you reset instructions!</p>
                  </div>

                  <Formik
                    initialValues={{ email: '' }}
                    validationSchema={sendEmailSchema}
                    onSubmit={({ email }) =>
                      handleSendMail({ email })}
                  >
                    {({
                      touched,
                      errors,
                      values,
                      handleChange,
                      handleSubmit,
                      handleBlur
                    }) => (
                      <div>
                        <Form>
                          <div className='form-group'>
                            <label
                              htmlFor='email'
                              className={
                            touched.email && errors.email ? 'text-danger' : ''
                          }
                            >
                              Email
                            </label>

                            <InputGroup
                              className={
                            touched.email && errors.email
                              ? 'border border-danger'
                              : ''
                          }
                            >
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faEnvelope} />
                              </InputGroup.Text>

                              <BForm.Control
                                name='email'
                                autoFocus
                                required
                                type='email'
                                placeholder='example@company.com'
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </InputGroup>

                            <ErrorMessage
                              component='div'
                              name='email'
                              className='text-danger'
                            />
                          </div>

                          <button
                            type='submit'
                            className='btn btn-primary btn-block mt-4'
                            onClick={handleSubmit}
                          >
                            {sendMailLoading && (
                              <Spinner
                                as='span'
                                animation='border'
                                size='sm'
                                role='status'
                                aria-hidden='true'
                              />
                            )}
                            Reset Password
                          </button>
                        </Form>
                      </div>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
        </section>
      </main>
    </>
  )
}

export default TakeUserMail
