import React, { useState, useEffect, useRef } from "react";

import { Button, Form, Row, Col, Table } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleDown,
  faArrowCircleUp,
  faRedoAlt,
} from "@fortawesome/free-solid-svg-icons";

import Trigger from "../../../components/OverlayTrigger";
import DateRangePicker from "../../../components/DateRangePicker";
import useActivePlayers from "./hooks/useActivePlayers";
import { tableHeaders } from "./playerConstant";
import { AFFILIATE_PLAYERS_TYPE } from "../constants/index";
import ActionMenu from "./components/ActionMenu";
import { getAdminAffiliateUsersStart } from "../../../store/redux-slices/dashboard";
import { useDispatch } from 'react-redux';
import { ACTIVE_LABEL, ACTIVE_VALUE } from "../../../utils/constant";
import { Link } from "react-router-dom";

//NOTE : HARDCODED STATIC DATA


const ActivePlayers = ({ playerType = AFFILIATE_PLAYERS_TYPE.ACTIVE, isInitialRender, search = '' }) => {
  const [filterState, setFilterState] = useState({
    emailNameUserName: '',
    userId: '',
    phoneNumber: '',
  })
  const {
    state,
    setState,
    orderBy,
    setOrderBy,
    selected,
    sort,
    setSort,
    over,
    setOver,
    loading,
    userData, //GETTING STATIC DATA
    navigate,
  } = useActivePlayers({ playerType, filterState });
  const dispatch = useDispatch();
  const isFirstRender = useRef(isInitialRender);
  useEffect(() => {
    setFilterState((state) => ({ ...state, emailNameUserName: search }))
    dispatch(getAdminAffiliateUsersStart({
      orderBy,
      sort,
      playerType,
      search: filterState.emailNameUserName
    }))
  }, [playerType, search])
  useEffect(() => {
    let delyaDebounceFn

    delyaDebounceFn = setTimeout(() => {
      dispatch(getAdminAffiliateUsersStart({
        orderBy,
        sort,
        playerType,
        search: filterState.emailNameUserName
      }))
    }, 100)



    return () => {
      clearTimeout(delyaDebounceFn)
    }
  }, [filterState])

  return (
    <>
      <div className="section-header-wrap mt-n3 mx-n3">
        <Row>
          <Col lg={8} xs={7}>
            <h2 className="title-text">
              {
                playerType === AFFILIATE_PLAYERS_TYPE.ACTIVE
                  ? "Jogadores ativos"
                  : "Jogadores bloqueados"
              }
            </h2>
          </Col>
        </Row>
      </div>
      <div className="FormsBg">
        <Row>
          <Col xs={3}>
            <Form.Control
              type="search"
              placeholder="Procurar Email, Name, Username, User Id, Phone."
              size="sm"
              // style={{ maxWidth: '230px', marginRight: '10px', maxHeight: '15px' }}
              value={filterState.emailNameUserName}
              onChange={(event) =>
                setFilterState((state) => {
                  return {
                    ...state,
                    emailNameUserName: event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, "")
                  }
                }
                )
              }
            />
          </Col>
          <Col xs={1}>
            <div className="d-flex justify-content-end align-item-center">
              <Trigger message="Reset Filters">
                <Button size="sm">
                  <FontAwesomeIcon icon={faRedoAlt} />
                </Button>
              </Trigger>
            </div>
          </Col>
        </Row>
        {/* <Row>
          <Col xs={3} className="mt-2">
            <DateRangePicker
              state={state}
              setState={setState}
              size="sm"
              player
              transaction
              bonus
            />
          </Col>
        </Row> */}
      </div>

      <div className="TableBg mt-4 pt-1">
        <Table striped responsive hover size="sm" className="text-center mt-3">
          <thead className="thead-light">
            <tr>
              {tableHeaders.map((h, idx) => (
                <th
                  key={idx}
                  onClick={() =>
                    (h.value === "email" ||
                      h.value === "userId" ||
                      h.value === "username") &&
                    setOrderBy(h.value)
                  }
                  style={{
                    cursor: "pointer",
                  }}
                  className={selected(h) ? "border-3 border border-blue" : ""}
                >
                  {h.label}{" "}
                  {selected(h) &&
                    (sort === "ASC" ? (
                      <FontAwesomeIcon
                        style={over ? { color: "red" } : {}}
                        icon={faArrowCircleUp}
                        onClick={() => setSort("DESC")}
                        onMouseOver={() => setOver(true)}
                        onMouseLeave={() => setOver(false)}
                      />
                    ) : (
                      <FontAwesomeIcon
                        style={over ? { color: "red" } : {}}
                        icon={faArrowCircleDown}
                        onClick={() => setSort("ASC")}
                        onMouseOver={() => setOver(true)}
                        onMouseLeave={() => setOver(false)}
                      />
                    ))}
                </th>
              ))}
            </tr>
          </thead>

          <tbody id={loading ? "cover-spin" : ""}>
            {!loading &&
              Boolean(userData) &&
              userData?.rows?.map(
                ({
                  email,
                  isActive,
                  userId,
                  firstName,
                  kycStatus,
                  isInternalUser,
                  affiliate,
                  affiliateStatus,
                  sumsubKycStatus,
                  kycMethod,
                  username,
                  phone,
                  dateOfBirth,
                  fullName
                }) => {
                  return (
                    <tr key={userId}>
                      <td>{userId}</td>

                      <td className="text-left">
                        {username ? (
                          <Trigger message={username}>
                            <Link to={`/affiliate/player/details/${userId}`}>
                              <span className="text-link d-inline-block text-truncate cursor-pointer">
                                {username}
                              </span>
                            </Link>
                          </Trigger>
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td className="text-left">{fullName}</td>
                      <td className="text-left">{email}</td>

                      <td><span className="w-100" data-badge={ACTIVE_VALUE[isActive]}>{ACTIVE_LABEL[isActive]}</span></td>
                      <td>
                        <ActionMenu userId={userId} navigate={navigate} />
                      </td>
                    </tr>
                  );
                }
              )}

            {userData?.count === 0 && !loading && (
              <tr>
                <td colSpan={13} className="text-danger text-center">
                  Nenhum dado encontrado
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {/* {userData?.count !== 0 && !loading && (
          <PaginationComponent
            page={userData?.count < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
          />
        )} */}
      </div>
    </>
  );
};

export default ActivePlayers;
