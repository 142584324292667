import { ButtonGroup, Table } from '@themesberg/react-bootstrap'
import React from 'react'
import { getDateTime } from '../../../../../utils/dateFormatter'
import Trigger from '../../../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowCircleUp,
  faCheckSquare,
  faWindowClose
} from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import useCheckPermission from '../../../../../utils/checkPermission'
import Button from '../../../../../components/Button'
import { STATUS_LABEL, STATUS_VALUE } from '../../../../../utils/constant'
import FormatDate from '../../../../../utils/formatDate'

export const tableHeaders = [
  'ID',
  'Nome',
  'Pré Visualização',
  'Motivo',
  'Actionee',
  'Status',
  'Atualizado em',
  'Ação realizada em',
  'Ação'
];

const UserKycDocumentsList = ({
  userDocuments,
  documentLabels,
  updateDocument,
  handleVerify,
  isTenant = false,
  docLabels,
  handleReRequest,
  handleImagePreview,
  setShowSendUserDocumentModal
}) => {
  const { userData } = useSelector((state) => state.fetch);
  const { isHidden } = useCheckPermission();

  console.log({ docLabels })
  return (
    <>
      <div className='TableBg mt-4 pb-0'>
        <div className='p-3 d-flex justify-content-between aling-items-center'>
          <h3 className='m-0'>Documentos enviados</h3>
          {documentLabels.length ? <Button onClick={() => setShowSendUserDocumentModal(true)}>Enviar documentos</Button> : null}
        </div>
        <Table
          striped
          responsive
          hover
          size='sm'
          className='text-center'
        >
          <thead className='thead-light'>
            <tr>
              {tableHeaders.map((c) => {
                if (isTenant && c === 'Action') {
                  return null
                } else {
                  return <th key={c}>{c}</th>
                }
              }
              )}
            </tr>
          </thead>

          <tbody>
            {userDocuments &&
              userDocuments?.map(
                ({
                  userDocumentId,
                  documentUrl,
                  documentName,
                  status,
                  reason,
                  updatedAt,
                  actionPerformedAt,
                  actionee
                }) => {
                  return (
                    <tr key={`user-docs-list ${userDocumentId}`}>
                      <td>{userDocumentId}</td>

                      <td>
                        <Trigger message={documentName}>
                          <span className='d-inline-block text-truncate'>
                            {documentName}
                          </span>
                        </Trigger>
                      </td>

                      <td>
                        <span
                          onClick={() => handleImagePreview(documentUrl, documentName)}
                          className='text-link'
                          style={{ cursor: 'pointer' }}
                        >
                          Visualizar
                        </span>
                      </td>

                      <td>{reason || 'N/A'}</td>
                      <td>
                        <Trigger message={actionee || 'N/A'}>
                          <span className='d-inline-block text-truncate'>
                            {actionee || 'NA'}
                          </span>
                        </Trigger>
                      </td>

                      <td><span className='w-100' data-badge={String(STATUS_VALUE[status]).toLowerCase()}>{STATUS_LABEL[STATUS_VALUE[status]]}</span></td>

                      <td>{FormatDate.default(updatedAt)}</td>

                      <td>{actionPerformedAt ? FormatDate.default(actionPerformedAt) : 'N/A'}</td>

                      <td>
                        {!status && !isHidden({ module: { key: 'Users', value: 'U' } }) &&
                          <>
                            {isHidden({ module: { key: 'Users', value: 'U' } }) && 'Pending'}
                            <ButtonGroup>
                              <Trigger message='Aprovar'>
                                <Button
                                  variant='success'
                                  className='m-1'
                                  size='sm'
                                  onClick={() =>
                                    handleVerify('approved', userDocumentId, `${documentName} for ${userData?.firstName} ${userData?.lastName}`)}
                                >
                                  Aprovar
                                </Button>
                              </Trigger>

                              <Trigger message='Rejeitar'>
                                <Button
                                  variant='danger'
                                  className='m-1'
                                  size='sm'
                                  onClick={() =>
                                    handleVerify('rejected', userDocumentId, `${documentName} for ${userData?.firstName} ${userData?.lastName}`)}
                                >
                                  Rejeitar
                                </Button>
                              </Trigger>
                            </ButtonGroup>
                          </>
                        }

                        {[1, 2].includes(status) && !isHidden({ module: { key: 'Users', value: 'U' } }) &&
                          <Trigger message='Solicitar novamente'>
                            <Button
                              variant='success'
                              className='m-1'
                              size='sm'
                              onClick={() => handleReRequest(userDocumentId, 'request', `${documentName} for ${userData?.firstName} ${userData?.lastName}`, documentName)}
                            >
                              Solicitar novamente
                            </Button>
                          </Trigger>
                        }

                        {[4].includes(status) && !isHidden({ module: { key: 'Users', value: 'U' } }) &&
                          <Trigger message='Cancelar a resolicitação'>
                            <Button
                              variant='danger'
                              className='m-1'
                              size='sm'
                              onClick={() => handleReRequest(userDocumentId, 'cancel', `${documentName} for ${userData?.firstName} ${userData?.lastName}`, documentName)}
                            >
                              Cancelar resolicitação
                            </Button>
                          </Trigger>
                        }
                      </td>
                    </tr>
                  )
                }
              )}

            {userDocuments?.length < 1 && (
              <tr>
                <td colSpan={8} className='text-danger text-center'>
                  Nenhum dado encontrado
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {(!isTenant && !isHidden({ module: { key: 'Users', value: 'U' } })
        && (Boolean(documentLabels.length)
          || (Boolean(docLabels) && Boolean(userData?.requestedDocuments)))
      ) && (
          <div className='TableBg mt-4 pb-0'>
            <h3 className='p-3 m-0'>Solicitação de documentos</h3>
            <Table
              striped
              responsive
              hover
              size='sm'
              className='m'
            >
              <thead className='thead-light'>
                <tr>
                  <th className='w-100'>Nome</th>
                  <th>Prioridade</th>
                  <th>Ação</th>
                </tr>
              </thead>

              <tbody>
                {documentLabels &&
                  documentLabels.map(({ name, documentLabelId, isRequired }) => {
                    return (
                      (
                        <tr key={documentLabelId}>
                          <td>{name?.PT || name?.EN}</td>
                          <td><span className='w-100' data-badge={isRequired ? "warning" : "disabled"}>{isRequired ? "Obrigatório" : "Opcional"}</span></td>
                          <td>
                            {!userData?.documentLabels?.includes(documentLabelId)
                              ? (
                                <ButtonGroup>
                                  <Trigger message='Solicitar'>
                                    <Button
                                      variant='success'
                                      className='m-1'
                                      size='sm'
                                      onClick={() =>
                                        updateDocument({
                                          documentLabelId,
                                          isRequested: true,
                                          labelName: name?.EN
                                        })}
                                    >
                                      Solicitar
                                    </Button>
                                  </Trigger>
                                </ButtonGroup>
                              )
                              : (
                                <ButtonGroup>
                                  <Trigger message='Marcar como não solicitado'>
                                    <Button
                                      variant='danger'
                                      className='m-1'
                                      size='sm'
                                      onClick={() =>
                                        updateDocument({
                                          documentLabelId,
                                          isRequested: false
                                        })}
                                    >
                                      Marcar como não solicitado
                                    </Button>
                                  </Trigger>
                                </ButtonGroup>
                              )}
                          </td>
                        </tr>
                      )
                    )
                  })}
                {userData?.requestedDocuments && docLabels &&
                  docLabels.map(({ name, documentLabelId, isRequired }) => {
                    return userData.requestedDocuments?.includes(documentLabelId) &&
                      (
                        <tr key={`reqDocs${name?.EN}`}>
                          <td>{name?.PT || name?.EN}</td>
                          <td><span className='w-100' data-badge={isRequired ? "warning" : "disabled"}>{isRequired ? "Obrigatório" : "Opcional"}</span></td>
                          <td>
                            <ButtonGroup>
                              <Trigger message='Marcar como não solicitado'>
                                <Button
                                  variant='danger'
                                  className='m-1'
                                  size='sm'
                                  onClick={() =>
                                    updateDocument({
                                      documentLabelId,
                                      isRequested: false
                                    })}
                                >
                                  Marcar como não solicitado
                                </Button>
                              </Trigger>
                            </ButtonGroup>
                          </td>
                        </tr>
                      )
                  })}
                {(userData?.requestedDocuments === null || userData?.requestedDocuments?.length < 1) && documentLabels?.length < 1 && (
                  <tr>
                    <td colSpan={2} className='text-danger text-center'>
                      Nenhum dado encontrado
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        )}

    </>
  )
}

export default UserKycDocumentsList
