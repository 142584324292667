import React, { useEffect, useState } from 'react'
import {
    Button,
    Form as BForm,
    Row,
    Col,
    Modal,
    Spinner,
} from '@themesberg/react-bootstrap'
import { useDispatch } from 'react-redux'
import { Formik, Form, ErrorMessage } from 'formik'
import Preloader, { SpinnerLoader, InlineLoader } from '../../../components/Preloader'
import { InputGroup } from 'react-bootstrap'
import { betSettingValidation } from './betSettingsValidation'
import DivSpinnerLoader from '../../../components/Loader/spinnerLoader'
import { dataInfoHelper } from './supportFunction'

const BetSettingsForm = ({
    toggleAddModal,
    show,
    loading,
    editData,
    updateBetSettingsStart,
    addBetSettingsStart,
    sportsListInfo
}) => {
    const dispatch = useDispatch()
    return (
        <>
            <Formik
                enableReinitialize
                initialValues={dataInfoHelper(editData)}
                validationSchema={() => betSettingValidation(editData)}
                onSubmit={(formValues) => {
                    const data = dataInfoHelper(formValues);
                    if (editData?.betSettingId) {
                        data.betSettingId = editData.betSettingId;
                        dispatch(updateBetSettingsStart({ data, toggleAddModal }))
                    } else {
                        dispatch(addBetSettingsStart({ data, toggleAddModal }))
                    }
                }}

            >
                {({ values, handleChange, handleSubmit, handleBlur }) => (
                    <Form>
                        <Modal
                            size="lg"
                            show={show}
                            onHide={toggleAddModal}
                            backdrop='static'
                            keyboard={false}
                        >
                            {/* {
                    !loading && <Loader />
                } */}
                            {loading && <DivSpinnerLoader />}


                            <Modal.Header closeButton>
                                <Modal.Title>Bet Settings</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row className=''>
                                    <Col xs={6} className='mt-3'>
                                        <BForm.Label>
                                            Max Bet Amount<span className='text-danger'> *</span>
                                        </BForm.Label>
                                        <InputGroup>
                                            <BForm.Control
                                                type='text'
                                                name='maxBetAmount'
                                                value={values?.maxBetAmount}
                                                // disabled={!edit || !editable}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </InputGroup>

                                        <ErrorMessage
                                            component='div'
                                            name='maxBetAmount'
                                            className='text-danger'
                                        />

                                    </Col>
                                    <Col xs={6} className='mt-3'>
                                        <BForm.Label>
                                            Min Bet Amount<span className='text-danger'> *</span>
                                        </BForm.Label>
                                        <InputGroup>
                                            <BForm.Control
                                                type='text'
                                                name='minBetAmount'
                                                value={values?.minBetAmount}
                                                // disabled={!edit || !editable}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </InputGroup>
                                        <ErrorMessage
                                            component='div'
                                            name='minBetAmount'
                                            className='text-danger'
                                        />
                                    </Col>
                                    <Col xs={6} className='mt-3'>
                                        <BForm.Label>
                                            Max Bet Count <span className='text-danger'> *</span>
                                        </BForm.Label>
                                        <InputGroup>
                                            <BForm.Control
                                                type='text'
                                                name='maxBetCount'
                                                value={values?.maxBetCount}
                                                // disabled={!edit || !editable}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </InputGroup>
                                        <ErrorMessage
                                            component='div'
                                            name='maxBetCount'
                                            className='text-danger'
                                        />

                                    </Col>
                                    <Col xs={6} className='mt-3'>
                                        <BForm.Label>
                                            Cashout Percentage <span className='text-danger'> *</span>
                                        </BForm.Label>
                                        <InputGroup>
                                            <BForm.Control
                                                type='text'
                                                name='cashoutPercentage'
                                                value={values?.cashoutPercentage}
                                                // disabled={!edit || !editable}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </InputGroup>
                                        <ErrorMessage
                                            component='div'
                                            name='cashoutPercentage'
                                            className='text-danger'
                                        />
                                    </Col>
                                    <Col xs={6} className='mt-3'>
                                        <BForm.Label>
                                            Min Odd Limit <span className='text-danger'> *</span>
                                        </BForm.Label>
                                        <InputGroup>
                                            <BForm.Control
                                                type='text'
                                                name='minOddLimit'
                                                value={values?.minOddLimit}
                                                // disabled={!edit || !editable}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </InputGroup>
                                        <ErrorMessage
                                            component='div'
                                            name='minOddLimit'
                                            className='text-danger'
                                        />

                                    </Col>
                                    <Col xs={6} className='mt-3'>
                                        <BForm.Label>
                                            Max odd limit <span className='text-danger'> *</span>
                                        </BForm.Label>
                                        <InputGroup>
                                            <BForm.Control
                                                type='text'
                                                name='maxOddLimit'
                                                value={values?.maxOddLimit}
                                                // disabled={!edit || !editable}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </InputGroup>
                                        <ErrorMessage
                                            component='div'
                                            name='maxOddLimit'
                                            className='text-danger'
                                        />

                                    </Col>
                                    <Col xs={6} className='mt-3'>
                                        <BForm.Label>
                                            Max Event Count <span className='text-danger'> *</span>
                                        </BForm.Label>
                                        <InputGroup>
                                            <BForm.Control
                                                type='text'
                                                name='maxEventCount'
                                                value={values?.maxEventCount}
                                                // disabled={!edit || !editable}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </InputGroup>
                                        <ErrorMessage
                                            component='div'
                                            name='maxEventCount'
                                            className='text-danger'
                                        />
                                    </Col>
                                    <Col xs={6} className='mt-3'>
                                        <BForm.Label>
                                            Max Market Outcome Count <span className='text-danger'> *</span>
                                        </BForm.Label>
                                        <InputGroup>
                                            <BForm.Control
                                                type='text'
                                                name='maxMarketOutcomeCount'
                                                value={values?.maxMarketOutcomeCount}
                                                // disabled={!edit || !editable}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </InputGroup>
                                        <ErrorMessage
                                            component='div'
                                            name='maxMarketOutcomeCount'
                                            className='text-danger'
                                        />
                                    </Col>
                                    <Col xs={6} className='mt-3'>
                                        <BForm.Label>
                                            Max Win Amount <span className='text-danger'> *</span>
                                        </BForm.Label>
                                        <InputGroup>
                                            <BForm.Control
                                                type='text'
                                                name='maxWinAmount'
                                                value={values?.maxWinAmount}
                                                // disabled={!edit || !editable}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </InputGroup>
                                        <ErrorMessage
                                            component='div'
                                            name='maxWinAmount'
                                            className='text-danger'
                                        />
                                    </Col>
                                    {editData.betSettingId !== '1' && (
                                        <Col xs={6} className='mt-3'>
                                            <BForm.Label>
                                                Select Sports <span className='text-danger'> *</span>
                                            </BForm.Label>
                                            <InputGroup>
                                                <BForm.Select
                                                    type='select'
                                                    name='sportId'
                                                    value={values?.sportId}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                >
                                                    <option value={null} key={''}>
                                                        Select Sports
                                                    </option>
                                                    {sportsListInfo?.sportsList?.rows?.map(({ sportName, sportId }) => (
                                                        <option value={sportId} key={sportName[0].name}>
                                                            {sportName[0].name || 'NA'}
                                                        </option>
                                                    ))}
                                                </BForm.Select>
                                            </InputGroup>
                                            <ErrorMessage
                                                component='div'
                                                name='sportId'
                                                className='text-danger'
                                            />
                                        </Col>
                                    )}
                                </Row>
                            </Modal.Body>
                            <div className='mt-4 '>
                                <Modal.Footer className='d-flex justify-content-between align-items-center'>
                                    <Button className='btn-danger' onClick={() => toggleAddModal()}>
                                        Cancel
                                    </Button>
                                    <Button
                                        className='btn-primary'
                                        onClick={handleSubmit}
                                    >
                                        {
                                            editData.betSettingId ? 'Update Bet Settings' : 'Add Settings'
                                        }

                                        {loading && (
                                            <Spinner
                                                as='span'
                                                animation='border'
                                                size='sm'
                                                role='status'
                                                aria-hidden='true'
                                            />
                                        )}
                                    </Button>
                                </Modal.Footer>
                            </div>
                        </Modal>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default BetSettingsForm
