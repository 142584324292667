export const kpiConstant = {
    "Banking": [
        {
            "rowName": "Pending Withdrawals (Globally)",
            "type": "amount",
            "today": "500",
            "yesterday": "5,530",
            "monthToDate": "39,387",
            "prevMonthToDate": "0",
            "customDate": "500",
            "delta": "100 %"
        },
        {
            "rowName": "Pending Withdrawals (Kyc Requested)",
            "type": "amount",
            "today": "0",
            "yesterday": "0",
            "monthToDate": "0",
            "prevMonthToDate": "0",
            "customDate": "0",
            "delta": "0 %"
        },
        {
            "rowName": "Pending Withdrawals (Kyc Approved)",
            "type": "amount",
            "today": "0",
            "yesterday": "0",
            "monthToDate": "0",
            "prevMonthToDate": "0",
            "customDate": "0",
            "delta": "0 %"
        },
        {
            "rowName": "Pending Withdrawals (Kyc Pending)",
            "type": "amount",
            "today": "500",
            "yesterday": "5,530",
            "monthToDate": "39,387",
            "prevMonthToDate": "0",
            "customDate": "500",
            "delta": "100 %"
        },
        {
            "rowName": "Withdrawals",
            "type": "amount",
            "today": "1,520",
            "yesterday": "3,763",
            "monthToDate": "35,018.8",
            "prevMonthToDate": "8,482",
            "customDate": "1,520",
            "delta": "312.86 %"
        },
        {
            "rowName": "GGR",
            "type": "amount",
            "today": "2,446.12",
            "yesterday": "-373.62",
            "monthToDate": "20,731.35",
            "prevMonthToDate": "42,600.07",
            "customDate": "2,446.12",
            "delta": "-51.33 %"
        },
        {
            "rowName": "Bonus Count",
            "type": "count",
            "today": "44",
            "yesterday": "45",
            "monthToDate": "1,196",
            "prevMonthToDate": "611",
            "customDate": "44",
            "delta": "95.74 %"
        },
        {
            "rowName": "Bonus Amount",
            "type": "amount",
            "today": "722.84",
            "yesterday": "1,236.75",
            "monthToDate": "28,332.9",
            "prevMonthToDate": "32,622.26",
            "customDate": "722.84",
            "delta": "-13.15 %"
        },
        {
            "rowName": "Bonus Win",
            "type": "amount",
            "today": "2,753.84",
            "yesterday": "5,429.8",
            "monthToDate": "179,789.37",
            "prevMonthToDate": "101,898.94",
            "customDate": "2,753.84",
            "delta": "76.44 %"
        },
        {
            "rowName": "Bonus Bet",
            "type": "amount",
            "today": "3,284.87",
            "yesterday": "6,625.2",
            "monthToDate": "186,690.87",
            "prevMonthToDate": "118,499.99",
            "customDate": "3,284.87",
            "delta": "57.55 %"
        },
        {
            "rowName": "Bonus GGR",
            "type": "amount",
            "today": "531.03",
            "yesterday": "1,195.4",
            "monthToDate": "6,901.5",
            "prevMonthToDate": "16,601.05",
            "customDate": "531.03",
            "delta": "-58.43 %"
        },
        {
            "rowName": "Deposits",
            "type": "amount",
            "today": "904",
            "yesterday": "3,189",
            "monthToDate": "68,665.8",
            "prevMonthToDate": "59,129.5",
            "customDate": "904",
            "delta": "16.13 %"
        },
        {
            "rowName": "Depositors",
            "type": "count",
            "today": "20",
            "yesterday": "48",
            "monthToDate": "544",
            "prevMonthToDate": "465",
            "customDate": "20",
            "delta": "16.99 %"
        },
        {
            "rowName": "Active Users",
            "type": "count",
            "today": "49",
            "yesterday": "75",
            "monthToDate": "906",
            "prevMonthToDate": "808",
            "customDate": "49",
            "delta": "12.13 %"
        },
        {
            "rowName": "New Players",
            "type": "count",
            "today": "36",
            "yesterday": "63",
            "monthToDate": "2,014",
            "prevMonthToDate": "1,852",
            "customDate": "36",
            "delta": "8.75 %"
        },
        {
            "rowName": "New Depositors",
            "type": "count",
            "today": "12",
            "yesterday": "21",
            "monthToDate": "469",
            "prevMonthToDate": "267",
            "customDate": "12",
            "delta": "75.66 %"
        },
        {
            "rowName": "Deposit Conv Rate",
            "type": "rate",
            "today": "33.33 %",
            "yesterday": "33.33 %",
            "monthToDate": "23.29 %",
            "prevMonthToDate": "14.42 %",
            "customDate": "33.33 %",
            "delta": "61.51 %"
        },
        {
            "rowName": "Add Money (Internal)",
            "type": "amount",
            "today": "0",
            "yesterday": "1",
            "monthToDate": "75.7",
            "prevMonthToDate": "11.99",
            "customDate": "0",
            "delta": "-99.87 %"
        },
        {
            "rowName": "Remove Money (Internal)",
            "type": "amount",
            "today": "0",
            "yesterday": "0",
            "monthToDate": "124",
            "prevMonthToDate": "39,030.23",
            "customDate": "0",
            "delta": "-99.68 %"
        },
        {
            "rowName": "Deposit (Internal)",
            "type": "amount",
            "today": "0",
            "yesterday": "0",
            "monthToDate": "20",
            "prevMonthToDate": "111",
            "customDate": "0",
            "delta": "-99.97 %"
        },
        {
            "rowName": "Withdrawals (Internal)",
            "type": "amount",
            "today": "0",
            "yesterday": "0",
            "monthToDate": "70",
            "prevMonthToDate": "680",
            "customDate": "0",
            "delta": "-89.71 %"
        },
        {
            "rowName": "GGR (Internal)",
            "type": "amount",
            "today": "0",
            "yesterday": "1.2",
            "monthToDate": "87.96",
            "prevMonthToDate": "-33,027.72",
            "customDate": "0",
            "delta": "100.27 %"
        },
        {
            "rowName": "Cash Hold %",
            "type": "rate",
            "today": "0%",
            "yesterday": "0%",
            "monthToDate": "0%",
            "prevMonthToDate": "0%",
            "customDate": "0%",
            "delta": "0%"
        }
    ],
    "SPORTS": [
        {
            "rowName": "Bet Amount",
            "type": "amount",
            "today": "18,603.78",
            "yesterday": "36,647.18",
            "monthToDate": "822,114.35",
            "prevMonthToDate": "605,436.37",
            "customDate": "18,603.78",
            "delta": "35.79 %"
        },
        {
            "rowName": "Win Amount",
            "type": "amount",
            "today": "3,825",
            "yesterday": "7,637",
            "monthToDate": "148,552",
            "prevMonthToDate": "114,742",
            "customDate": "3,825",
            "delta": "29.47 %"
        },
        {
            "rowName": "Total Risk",
            "type": "amount",
            "today": "16,157.66",
            "yesterday": "37,020.8",
            "monthToDate": "801,383",
            "prevMonthToDate": "562,836.3",
            "customDate": "16,157.66",
            "delta": "42.38 %"
        },
        {
            "rowName": "Total Stake",
            "type": "amount",
            "today": "2,446.12",
            "yesterday": "373.62",
            "monthToDate": "20,731.35",
            "prevMonthToDate": "42,600.07",
            "customDate": "2,446.12",
            "delta": "51.33 %"
        }
    ],
    "CASINO": [
        {
            "rowName": "Bet Count",
            "type": "count",
            "today": "10,403",
            "yesterday": "23,790",
            "monthToDate": "523,709",
            "prevMonthToDate": "419,590",
            "customDate": "10,403",
            "delta": "24.81 %"
        },
        {
            "rowName": "Bet Amount",
            "type": "amount",
            "today": "18,603.78",
            "yesterday": "36,647.18",
            "monthToDate": "822,114.35",
            "prevMonthToDate": "605,436.37",
            "customDate": "18,603.78",
            "delta": "35.79 %"
        },
        {
            "rowName": "Win Count",
            "type": "count",
            "today": "3,825",
            "yesterday": "7,637",
            "monthToDate": "148,552",
            "prevMonthToDate": "114,742",
            "customDate": "3,825",
            "delta": "29.47 %"
        },
        {
            "rowName": "Win Amount",
            "type": "amount",
            "today": "16,157.66",
            "yesterday": "37,020.8",
            "monthToDate": "801,383",
            "prevMonthToDate": "562,836.3",
            "customDate": "16,157.66",
            "delta": "42.38 %"
        },
        {
            "rowName": "Bet Count (Internal)",
            "type": "count",
            "today": "0",
            "yesterday": "3",
            "monthToDate": "88",
            "prevMonthToDate": "2,993",
            "customDate": "0",
            "delta": "-97.06 %"
        },
        {
            "rowName": "Win Count (Internal)",
            "type": "count",
            "today": "0",
            "yesterday": "0",
            "monthToDate": "19",
            "prevMonthToDate": "966",
            "customDate": "0",
            "delta": "-98.03 %"
        },
        {
            "rowName": "Bet Amount (Internal)",
            "type": "amount",
            "today": "0",
            "yesterday": "1.2",
            "monthToDate": "102.29",
            "prevMonthToDate": "74,780.85",
            "customDate": "0",
            "delta": "-99.86 %"
        },
        {
            "rowName": "Win Amount (Internal)",
            "type": "amount",
            "today": "0",
            "yesterday": "0",
            "monthToDate": "14.33",
            "prevMonthToDate": "107,808.57",
            "customDate": "0",
            "delta": "-99.99 %"
        }
    ]
}