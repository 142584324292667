import React from 'react'
import { Row, Col } from '@themesberg/react-bootstrap'
import { Formik } from 'formik'
import { createSuperAdminUserSchema } from '../schemas'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { createSuperAdminUserStart } from '../../../../store/redux-slices/adminUser'
import SuperAdminUserForm from './SuperAdminUserForm'
import { toast } from '../../../../components/Toast'
import useAdminUserForm from '../hooks/useAdminUserForm'

const CreateSuperAdminUser = () => {
  const {
    adminDetails
  } = useAdminUserForm({ group: null })
  const navigate = useNavigate()
  const dispatch = useDispatch()
  return (
    <>
      <div className='section-header-wrap mt-n3 mx-n3'>
        <Row>
          <Col lg={8} xs={7}>
            <h2 className='title-text'>Criar Staff</h2>
          </Col>
        </Row>
      </div>
      <div className='FormsBg mt-4'>
        <Formik
          initialValues={{
            email: '',
            password: '',
            adminUsername: '',
            firstName: '',
            lastName: '',
            role: null,
            adminId: null,
            permission: {},
            group: ''
          }}
          validationSchema={createSuperAdminUserSchema}
          onSubmit={(formValues) => {
            ([undefined, null].includes(formValues.permission) || Object.keys(formValues.permission).length < 1)
              ? toast('Please Select At Least One Permission', 'error')
              : dispatch(
                createSuperAdminUserStart({
                  data: {
                    ...formValues,
                    password: Buffer.from(formValues.password).toString('base64'),
                    adminId: formValues.adminId ? Number(formValues.adminId) : adminDetails.adminUserId
                  },
                  navigate
                })
              )
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue
          }) => (
            <SuperAdminUserForm
              values={values}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
            />
          )}
        </Formik>
      </div>
    </>
  )
}

export default CreateSuperAdminUser
