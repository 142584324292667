import { takeLatest, put } from 'redux-saga/effects'
import {
  getAllTenantUsersStart,
  getAllTenantUsersSuccess,
  getAllTenantUsersFailure,
  updateTenantUserStatusStart,
  updateTenantUserStatusSuccess,
  updateTenantUserStatusFailure,
  getTenantUserDetailsStart,
  getTenantUserDetailsSuccess,
  getTenantUserDetailsFailure,
  getUserFieldsStart,
  getUserFieldsSuccess,
  getUserFieldsFailure,
  updateUserFieldsStart,
  updateUserFieldsSuccess,
  updateUserFieldsFailure,
  createTenantUserStart,
  createTenantUserSuccess,
  createTenantUserFailure,
  updateTenantUserStart,
  updateTenantUserSuccess,
  updateTenantUserFailure,
  getAllWithdrawRequestStart,
  getAllWithdrawRequestSuccess,
  getAllWithdrawRequestFailure,
  updateTagsStart,
  updateTagsComplete,
  getDuplicateUsersStart,
  getDuplicateUsersSuccess,
  getDuplicateUsersFailure,
  updateLimitsStart,
  updateLimitsComplete,
  disableUserStart,
  disableUserComplete,
  updateUserAffiliateStart,
  updateUserAffiliateComplete,
  addAffiliateUserStart,
  addAffiliateUserComplete,
  verifyUserEmailStart,
  verifyUserEmailComplete,
  resetPasswordEmailStart,
  resetPasswordEmailComplete,
  resetUserPasswordStart,
  resetUserPasswordComplete,
  updateUserComplete,
  updateUserStart,
  getCashbackDataStart,
  getCashbackDataComplete,
  getCashbackDataFailure,
  approveWithdrawRequestSuccess,
  approveWithdrawRequestFailure,
  approveWithdrawRequestStart,
  cancelWithdrawRequestStart,
  cancelWithdrawRequestSuccess,
  cancelWithdrawRequestFailure,
  addLoyaltyPointsToUserSuccess,
  addLoyaltyPointsToUserFailure,
  addLoyaltyPointsToUserStart
} from '../redux-slices/tenantUsers'
import {
  // createTenantUser,
  // getAllTenantUsers,
  // getTenantUserDetails,
  // getUserFields,
  // updateTenantUser,
  tenantViewToggleStatus,
  // updateUserFields,
  getAllWithdrawRequestAdmin,
  getAllWithdrawRequestSuperAdmin,
  updateTags,
  getDuplicateUsers,
  setDailyLimit,
  setDepositLimit,
  setLossLimit,
  disableUser,
  setSessionLimit,
  updateUserAffiliate,
  addUserAffiliate,
  verifyPlayerEmail,
  resetUserPassword,
  resetPasswordEmail,
  updateUser,
  getCashbackData,
  approveWithdrawRequestAdmin,
  cancelWithdrawRequestAdmin,
  addSomeLoyaltyPoinstToUser,
  updateAffiliatePassword
} from '../../utils/apiCalls'
import { toast } from '../../components/Toast'
// import { TenantRoutes } from '../../routes'
import { formatDateYMD, formatDate } from '../../utils/dateFormatter'
import { getUserDocumentStart } from '../redux-slices/tenantsFetchData'
import { getUserStart, getSAUserDocumentStart } from '../redux-slices/fetchData'

export default function* tenantUsersWatcher() {
  // yield takeLatest(getAllTenantUsersStart.type, getAllTenantUsersWorker)
  // yield takeLatest(updateTenantUserStatusStart.type, updateTenantUserStatusWorker)
  // yield takeLatest(getTenantUserDetailsStart.type, getTenantUserDetailsWorker)
  // yield takeLatest(getUserFieldsStart.type, getUserFieldsWorker)
  // yield takeLatest(updateUserFieldsStart.type, updateUserFieldsWorker)
  // yield takeLatest(createTenantUserStart.type, createTenantUserWorker)
  // yield takeLatest(updateTenantUserStart.type, updateTenantUserWorker)
  yield takeLatest(getAllWithdrawRequestStart.type, getAllWithdrawRequestWorker)
  yield takeLatest(approveWithdrawRequestStart.type, approveWithdrawRequestWorker)
  yield takeLatest(cancelWithdrawRequestStart.type, cancelWithdrawRequestWorker)
  yield takeLatest(updateTagsStart.type, updateTagsWorker)
  yield takeLatest(getDuplicateUsersStart.type, getDuplicateUsersWorker)
  yield takeLatest(updateLimitsStart.type, updateLimitsWorker)
  yield takeLatest(disableUserStart.type, disableUserWorker)
  yield takeLatest(updateUserAffiliateStart.type, updateUserAffiliateWorker)
  yield takeLatest(addAffiliateUserStart.type, addAffiliateUserWorker)
  yield takeLatest(verifyUserEmailStart.type, verifyUserEmailWorker)
  yield takeLatest(resetPasswordEmailStart.type, resetPasswordEmailWorker)
  yield takeLatest(resetUserPasswordStart.type, resetUserPasswordWorker)
  yield takeLatest(updateUserStart.type, updateUserWorker)
  yield takeLatest(getCashbackDataStart.type, getCashbackDataWorker)
  yield takeLatest(addLoyaltyPointsToUserStart.type, addLoyaltyPointsToUserWorker)
}

// function * getAllTenantUsersWorker (action) {
//   try {
//     const {
//       limit, search, pageNo, isActive,
//       affiliateId, kycStatus, affiliateSearch,
//       dobStart, dobEnd, myUserId, phoneNumber,
//       orderBy = '', sortBy = ''
//     } =
//       action && action.payload

//     const { data } = yield getAllTenantUsers({
//       limit,
//       search,
//       pageNo,
//       isActive,
//       affiliateId: affiliateId || '',
//       kycStatus,
//       affiliateSearch,
//       dobStart,
//       dobEnd,
//       userId: myUserId,
//       phoneNumber,
//       orderBy,
//       sortBy
//     })

//     yield put(getAllTenantUsersSuccess(data?.data?.users))
//   } catch (e) {
//     yield put(getAllTenantUsersFailure(e?.response?.data?.errors[0].description))

//     yield toast(e?.response?.data?.errors[0].description, 'error')
//   }
// }

// function * updateTenantUserStatusWorker (action) {
//   try {
//     const {
//       kycStatus, userId, status, limit, pageNo, search, isActive, code, reason = false,
//       isPlayersPage = false, isUserPage = false, affiliateSearch,
//       myUserId, phoneNumber, dobStart, dobEnd, orderBy, sortBy
//     } =
//       action && action.payload

//     reason && (yield tenantViewToggleStatus({ userId, status, code, reason }))
//     !reason && (yield tenantViewToggleStatus({ userId, status, code }))
//     yield put(updateTenantUserStatusSuccess())
//     yield toast('User Status Updated Successfully', 'success')
//     isPlayersPage && (yield put(getAllTenantUsersStart({ limit, pageNo, search, isActive, kycStatus, affiliateSearch, myUserId, phoneNumber, dobStart, dobEnd, orderBy, sortBy })))
//     isUserPage && (yield put(getTenantUserDetailsStart({ userId })))
//   } catch (e) {
//     yield toast(e?.response?.data?.errors[0]?.description, 'error')

//     yield put(updateTenantUserStatusFailure(e?.response?.data?.errors[0]?.description))
//   }
// }

// function * getTenantUserDetailsWorker (action) {
//   try {
//     const { userId } = action && action.payload

//     const { data } = yield getTenantUserDetails({
//       userId
//     })

//     data?.data?.getUser.dateOfBirth &&
//       (data.data.getUser.dateOfBirth = formatDateYMD(
//         data.data.getUser.dateOfBirth
//       ))

//     yield put(getTenantUserDetailsSuccess(data?.data?.getUser))
//   } catch (e) {
//     yield put(getTenantUserDetailsFailure(e?.response?.data?.errors[0].description))

//     yield toast(e?.response?.data?.errors[0].description, 'error')
//   }
// }

// function * getUserFieldsWorker () {
//   try {
//     const { data } = yield getUserFields()

//     yield put(getUserFieldsSuccess(data?.data?.tenantRegistration))
//   } catch (e) {
//     yield put(getUserFieldsFailure(e?.response?.data?.errors[0].description))

//     yield toast(e?.response?.data?.errors[0].description, 'error')
//   }
// }

// function * updateUserFieldsWorker (action) {
//   try {
//     const data = action && action.payload

//     yield updateUserFields(data)

//     yield put(updateUserFieldsSuccess())

//     yield toast('Fields updated', 'success')

//     yield put(getUserFieldsStart())
//   } catch (e) {
//     yield put(updateUserFieldsFailure(e?.response?.data?.errors[0].description))

//     yield toast(e?.response?.data?.errors[0].description, 'error')
//   }
// }

// function * createTenantUserWorker (action) {
//   try {
//     const { initialState, navigate } = action && action.payload
//     initialState.dateOfBirth &&
//       (initialState.dateOfBirth = formatDate(initialState.dateOfBirth))

//     const encryptedPass = Buffer.from(initialState.password).toString('base64')
//     initialState.password = encryptedPass
//     initialState.confirmPassword = encryptedPass

//     yield createTenantUser(initialState)

//     yield put(createTenantUserSuccess())

//     yield toast('User Created Successfully', 'success')
//     // navigate(TenantRoutes.Users)
//   } catch (e) {
//     yield toast(e?.response?.data?.errors[0]?.description, 'error')

//     yield put(createTenantUserFailure(e?.response?.data?.errors[0]?.description))
//   }
// }

// function * updateTenantUserWorker (action) {
//   try {
//     const { initialState, navigate } = action && action.payload
//     initialState.dateOfBirth &&
//       (initialState.dateOfBirth = formatDate(initialState.dateOfBirth))

//     if (
//       initialState.password !== '' &&
//       initialState.password !== null &&
//       initialState.password !== undefined
//     ) {
//       const encryptedPass = Buffer.from(initialState.password).toString(
//         'base64'
//       )
//       initialState.password = encryptedPass
//       initialState.confirmPassword = encryptedPass
//     } else {
//       initialState.password = null
//       initialState.confirmPassword = null
//     }

//     yield updateTenantUser(initialState)

//     yield put(updateTenantUserSuccess())

//     yield toast('User Updated Successfully', 'success')
//     // navigate(TenantRoutes.Users)
//   } catch (e) {
//     yield toast(e?.response?.data?.errors[0]?.description, 'error')

//     yield put(updateTenantUserFailure(e?.response?.data?.errors[0]?.description))
//   }
// }

function* getAllWithdrawRequestWorker(action) {
  try {
    const { type, name, status, page, limit, startDate, endDate, tenantId, adminId, paymentProvider } = action && action.payload
    if (type === 'tenant') {
      const { data } = yield getAllWithdrawRequestAdmin({ name, status, page, limit, startDate, endDate, tenantId, paymentProvider })
      yield put(getAllWithdrawRequestSuccess(data?.data?.withdrawRequest))
    } else {
      const { data } = yield getAllWithdrawRequestSuperAdmin({ name, status, page, limit, startDate, endDate, adminId, tenantId, paymentProvider })
      yield put(getAllWithdrawRequestSuccess(data?.data?.withdrawRequest))
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllWithdrawRequestFailure())
  }
}

function* approveWithdrawRequestWorker(action) {
  try {
    const { userId, withdrawRequestId } = action && action.payload
    const { data } = yield approveWithdrawRequestAdmin({ userId, withdrawRequestId })
    yield put(approveWithdrawRequestSuccess())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(approveWithdrawRequestFailure())
  }
}

function* cancelWithdrawRequestWorker(action) {
  try {
    const { userId, withdrawRequestId } = action && action.payload
    const { data } = yield cancelWithdrawRequestAdmin({ userId, withdrawRequestId })
    yield put(cancelWithdrawRequestSuccess())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(cancelWithdrawRequestFailure())
  }
}

function* updateTagsWorker(action) {
  try {
    const { data, isTenant } = action && action.payload

    yield updateTags({ data, isTenant })
    yield put(updateTagsComplete())

    if (isTenant) yield put(getTenantUserDetailsStart({ userId: data?.userId }))
    else yield put(getUserStart({ userId: data?.userId }))

    if (isTenant) yield put(getUserDocumentStart({ userId: data?.userId }))
    else yield put(getSAUserDocumentStart({ userId: data?.userId }))

    yield toast('Tags Updated Successfully', 'success')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateTagsComplete(e?.response?.data?.errors[0]?.description))
  }
}

function* getDuplicateUsersWorker(action) {
  try {
    const { limit, pageNo, userId, isTenant } = action && action.payload
    const { data } = yield getDuplicateUsers({ limit, pageNo, userId, isTenant })

    yield put(getDuplicateUsersSuccess(data?.data?.users))
  } catch (e) {
    yield put(getDuplicateUsersFailure(e?.response?.data?.errors[0].description))

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function* updateLimitsWorker(action) {
  try {
    const { isTenant, data } = action && action.payload

    data?.type === 'wager'
      ? yield setDailyLimit({ isTenant, data })
      : (
        data?.type === 'deposit'
          ? yield setDepositLimit({ isTenant, data })
          : yield setLossLimit({ isTenant, data })
      )
    yield put(updateLimitsComplete())

    data?.reset
      ? yield toast('Limits Reset Successfully', 'success')
      : yield toast('Limits Updated Successfully', 'success')

    isTenant
      ? yield put(getTenantUserDetailsStart({ userId: data.userId }))
      : yield put(getUserStart({ userId: data.userId }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateLimitsComplete(e?.response?.data?.errors[0]?.description))
  }
}

function* disableUserWorker(action) {
  try {
    const { data, isTenant } = action && action.payload

    data?.type
      ? yield disableUser({ isTenant, data })
      : yield setSessionLimit({ isTenant, data })

    yield put(disableUserComplete())

    yield toast(`Account ${data?.reset ? 'Enabled' : 'Disabled'} Successfully`, 'success')

    isTenant
      ? yield put(getTenantUserDetailsStart({ userId: data.userId }))
      : yield put(getUserStart({ userId: data.userId }))
  } catch (e) {
    yield put(disableUserComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function* updateUserAffiliateWorker(action) {
  try {
    const { data, isSuperAdmin, setShowRemoveAffiliate } = action && action.payload

    yield updateUserAffiliate({ isSuperAdmin, data })

    yield setShowRemoveAffiliate(prev => false)

    yield put(updateUserAffiliateComplete())

    if (isSuperAdmin) {
      yield put(getUserStart({ userId: data?.userId }))
    } else {
      yield put(getTenantUserDetailsStart({ userId: data?.userId }))
    }

    yield toast('Affiliate removed successfully', 'success')
  } catch (e) {
    const { setShowRemoveAffiliate } = action && action.payload

    yield setShowRemoveAffiliate(prev => false)

    yield put(updateUserAffiliateComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function* addAffiliateUserWorker(action) {
  try {
    const { data, isSuperAdmin, setShowAddAffiliate } = action && action.payload

    yield addUserAffiliate({ isSuperAdmin, data })

    yield setShowAddAffiliate(prev => false)

    yield put(addAffiliateUserComplete())

    if (isSuperAdmin) {
      yield put(getUserStart({ userId: data?.userId }))
    } else {
      yield put(getTenantUserDetailsStart({ userId: data?.userId }))
    }

    yield toast('Affiliate added successfully', 'success')
  } catch (e) {
    const { setShowAddAffiliate } = action && action.payload

    yield put(addAffiliateUserComplete())

    yield setShowAddAffiliate(prev => false)

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function* verifyUserEmailWorker(action) {
  try {
    const { data, isTenant } = action && action.payload

    yield verifyPlayerEmail({ isTenant, data })

    yield put(verifyUserEmailComplete())

    isTenant
      ? yield put(getTenantUserDetailsStart({ userId: data.userId }))
      : yield put(getUserStart({ userId: data.userId }))

    yield toast('Email Verified successfully', 'success')
  } catch (e) {
    yield put(verifyUserEmailComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function* resetPasswordEmailWorker(action) {
  try {
    const { data, isTenant } = action && action.payload

    yield resetPasswordEmail({ isTenant, data })

    yield put(resetPasswordEmailComplete())

    isTenant
      ? yield put(getTenantUserDetailsStart({ userId: data.userId }))
      : yield put(getUserStart({ userId: data.userId }))

    yield toast('Reset Link Sent Successfully', 'success')
  } catch (e) {
    yield put(resetPasswordEmailComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function* resetUserPasswordWorker(action) {
  try {
    console.log(arguments)
    const { data, isTenant,userType='Player' } = action && action.payload

    if(userType === 'Player') {
      yield resetUserPassword({ isTenant, data })
      
    }
    if(userType === 'Affiliate'){
      //api call affiliate
      console.log("8888888888")
     yield updateAffiliatePassword({isTenant,...data})
    }

    yield put(resetUserPasswordComplete())

    yield toast('Password Reset Successfully', 'success')
  } catch (e) {
    yield put(resetUserPasswordComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function* updateUserWorker(action) {
  try {
    const { isTenant, data, successToggle, resetForm } = action && action.payload

    yield updateUser({ data, isTenant })
    yield put(updateUserComplete())
    successToggle && successToggle(resetForm)
    yield toast('User Data Updated Successfully', 'success')

    yield put(getUserStart({ userId: data?.userId }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateUserComplete(e?.response?.data?.errors[0]?.description))
  }
}

function* getCashbackDataWorker(action) {
  try {
    const { isTenant, userId, level, tenantId, currencyCode } = action && action.payload

    const { data } = yield getCashbackData({ isTenant, userId, level, tenantId, currencyCode })

    yield put(getCashbackDataComplete(data?.data))
  } catch (e) {
    yield put(getCashbackDataFailure(e?.response?.data?.errors[0].description))

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}


function* addLoyaltyPointsToUserWorker(action) {
  try {
    const { setSendLoyaltyPointsToUserModal,...rest } = action && action.payload

    const { data } = yield addSomeLoyaltyPoinstToUser(rest)

    yield put(addLoyaltyPointsToUserSuccess())
    toast('Loyalty Points Added Successfully','success')
    setSendLoyaltyPointsToUserModal(false)
    yield put(getUserStart({userId:rest.userId}))
  } catch (e) {
    yield put(addLoyaltyPointsToUserFailure(e?.response?.data?.errors[0].description))

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}