

import React, { useState, useEffect } from 'react'
import { Button, Card, Col, Row } from '@themesberg/react-bootstrap'
import { toast } from '../../../../components/Toast'
import config from '../../../../configs/app.config'

const BASE_REFERRAL_URL = `${config.app.userBaseUrl.replace(/\/+$/, '')}/signup?referralCode`;
const BASE_REFERRAL_SUB_AFFILIATE_URL = `${config.app.affiliateBaseUrl.replace(/\/+$/, '')}/affiliate/signup?referralCode`;

const LinkCreater = ({ affiliate }) => {
  const handleCopyLinkClick = (str) => {
    window.navigator.clipboard.writeText(str);
    toast('Link copiado com sucesso!', 'success');
  }

  const [link, setLink] = useState('');
  const [subAffiliateLink, setSubAffiliateLink] = useState('');

  useEffect(() => {
    setLink(`${BASE_REFERRAL_URL}=${affiliate?.username}` || '')
    setSubAffiliateLink(`${BASE_REFERRAL_SUB_AFFILIATE_URL}=${affiliate?.username}` || '')
  }, [affiliate])

  return (
    <Row>
      <Col md={6} xs={12} className='mb-4 mb-md-0'>
        <Card className="card-overview">
          <div className="d-flex flex-row  p-3" style={{ borderBottom: '2px solid grey' }}>
            <div className="col-12">
              Seu link de referência
            </div>
          </div>
          <div className="row p-3">
            <div className="col-12">
              <input type="text"
                defaultValue={link}
                style={{
                  padding: '.5rem',
                  borderRadius: '1rem',
                  borderColor: '#8080803d',
                  width: '100%',
                }} />
            </div>
            <div className="col-12 mt-3 d-flex justify-content-end">
              <Button
                variant='primary'
                onClick={() => handleCopyLinkClick(link)}
              >Copiar link</Button>
            </div>
          </div>
        </Card>
      </Col>
      {affiliate?.enableSubAffiliatesSystem ? <Col md={6} xs={12}>
        <Card className="card-overview">
          <div className="d-flex flex-row  p-3" style={{ borderBottom: '2px solid grey' }}>
            <div className="col-12">
              Seu link para cadastro de sub afiliados
            </div>
          </div>
          <div className="row p-3">
            <div className="col-12">
              <input type="text"
                defaultValue={subAffiliateLink}
                style={{
                  padding: '.5rem',
                  borderRadius: '1rem',
                  borderColor: '#8080803d',
                  width: '100%',
                }} />
            </div>
            <div className="col-12 d-flex justify-content-end mt-3">
              <Button
                variant='primary'
                onClick={() => handleCopyLinkClick(subAffiliateLink)}
              >Copiar link</Button>
            </div>
          </div>
        </Card>
      </Col> : null}
    </Row>
  )
}

export default LinkCreater