import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { affiliateLoginStart } from '../../../store/redux-slices/login'
import { getLoginToken, removeLoginToken, getItem } from '../../../utils/storageUtils'
import { AdminsRoutes, AffiliateRoutes } from '../../../routes'
import { toast } from '../../../components/Toast'
import { registerSubAffiliate, subAffiliateCountLinkVisit } from '../../../utils/apiCalls'
import useRole from '../../../hooks/useRole'
import { resolveErrors } from '../../../utils/errorTypes'

const localStorage = window.localStorage;
const visitStorageName = (ref) => `visitLimit-${ref}`;

export const useAffiliateSignup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const { Admin, SuperAdmin, Affiliate } = useRole();

  const [searchParams] = useSearchParams();
  const referralCode = searchParams.get('referralCode');

  const storage = localStorage.getItem(visitStorageName(referralCode));

  const onSubmit = async (formData = {}, actions) => {
    setLoading(true);
    try {
      // const { confirmPassword } = formData;
      //console.log(formData);

      await registerSubAffiliate({ ...formData, referralCode, /* password: Buffer.from(formData.password).toString('base64') */ });
      toast("Cadastro enviado para aprovação, em breve entraremos em contato.", "success");
      actions.resetForm();

      //if (formData.email && formData.password) handleSignIn(formData.email, formData.password);
    } catch (err) {
      toast(resolveErrors(err?.response?.data?.errors), "error");
    } finally {
      setLoading(false);
    }
  }

  const setSubAffiliateCountLinkVisit = async () => {
    const data = await subAffiliateCountLinkVisit({ referralCode, storage });

    if (data?.data?.authorized && data?.data?.token) {
      localStorage.setItem(visitStorageName(referralCode), data.data.token);
    } else if ((data?.data?.expired || data?.expired) && localStorage.getItem(visitStorageName(referralCode))) {
      localStorage.removeItem(visitStorageName(referralCode));
    }
  }

  useEffect(() => {
    if (!referralCode) {
      if (window.location.pathname.includes('affiliate')) {
        navigate(AffiliateRoutes.AffiliateSignIn);
        return;
      }
    }
    setSubAffiliateCountLinkVisit();

    if (getLoginToken()) {
      if (Admin || SuperAdmin) navigate(AdminsRoutes.Dashboard);
      if (Affiliate) navigate(AffiliateRoutes.Dashboard);
    } if (window.location.pathname.includes('affiliate')) {
      return
    } else {
      removeLoginToken()
    }


  }, [referralCode]);

  return {
    loading,
    onSubmit
  }
}

