import { createSlice } from '@reduxjs/toolkit';
const socialLinksSlice = createSlice({
    name: 'socialLinks',
    initialState: {
        loading: false,
        error: null,
        success: false,
        socialLinks: null,
        socialLink: null
    },
    reducers: {
        getAllSocialLinksStart: (state) => ({
            ...state,
            loading: true
        }),
        getAllSocialLinksSuccess: (state, { payload }) => ({
            ...state,
            loading: false,
            error: null,
            success: true,
            socialLinks: payload
        }),
        getAllSocialLinksFailure: (state, { payload }) => ({
            ...state,
            loading: false,
            error: payload,
            success: false
        }),
        setSocialLink: (state, { payload }) => ({
            ...state,
            socialLink: payload
        }),
        editSocialLinkStart: (state, { payload }) => ({
            ...state,
            loading: true,
            error: null
        }),
        editSocialLinkSuccess: (state, { payload }) => ({
            ...state,
            loading: false,
            error: null,
            success: true
        }),
        editSocialLinkFailure: (state, { payload }) => ({
            ...state,
            loading: false,
            error: payload,
            success: false
        }),
        createSocialLinkStart: (state, { payload }) => ({
            ...state,
            loading: true,
            error: null
        }),
        createSocialLinkSuccess: (state, { payload }) => ({
            ...state,
            loading: false,
            error: null,
            success: true
        }),
        createSocialLinkFailure: (state, { payload }) => ({
            ...state,
            loading: false,
            error: payload,
            success: false
        }),
        deleteSocialLinkStart: (state, { payload }) => ({
            ...state,
            loading: true,
            error: null
        }),
        deleteSocialLinkSuccess: (state, { payload }) => ({
            ...state,
            loading: false,
            error: null,
            success: true
        }),
        deleteSocialLinkFailure: (state, { payload }) => ({
            ...state,
            loading: false,
            error: null,
            success: false
        })
    }
})

export default socialLinksSlice.reducer
export const {
    getAllSocialLinksStart,
    getAllSocialLinksFailure,
    getAllSocialLinksSuccess,
    setSocialLink,
    editSocialLinkSuccess,
    editSocialLinkStart,
    editSocialLinkFailure,
    createSocialLinkFailure,
    createSocialLinkStart,
    createSocialLinkSuccess,
    deleteSocialLinkStart,
    deleteSocialLinkSuccess,
    deleteSocialLinkFailure
} = socialLinksSlice.actions