import React, { useEffect, useState } from 'react'
import {
  Button,
  Form as BForm,
  Row,
  Col,
  Modal,
  Spinner
} from '@themesberg/react-bootstrap'
import { Formik, Form, ErrorMessage } from 'formik'
// import { editproviderSchema, providerSchema } from '../schema'
import Preloader from '../../../../components/Preloader'
import Trigger from '../../../../components/OverlayTrigger'
import { editSubCategorySchema } from '../../CasinoCategory/schema'
import { InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

const CreateCasinoCategory = ({
  handleClose,
  data,
  show,
  type,
  gameCategory,
  loading,
  createProvider,
  updateProvider,
  language,
  setLangauge,
  languages
}) => {
  const [createName, setCreateName] = useState({ EN: '' })
  useEffect(() => {
    if (type === 'Edit Sub' && data) {
      setCreateName(data?.jname)
    } else {
      setCreateName({ EN: '' })
    }
  }, [type, data])

  return (
    <>
      {loading
        ? <Preloader />
        : (
          <Formik
            enableReinitialize
            initialValues={
              {
                name: createName || '',
                isActive: data?.isActive || false,
                subcategoryImage: "",
                gameCategoryId: data?.gameCategoryId || '',
                // demo: data?.demo
              }

            }
            validationSchema={
              data?.gameSubCategoryId ? editSubCategorySchema(createName) : editSubCategorySchema(createName)
            }
            onSubmit={(formValues) => {
              data?.gameSubCategoryId
                ? updateProvider(formValues, { casinoSubCategoryId: data?.gameSubCategoryId })
                : createProvider(formValues)

              handleClose()
            }}
          >
            {({ values, handleChange, handleSubmit, handleBlur, setFieldValue }) => (

              <Form>
                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop='static'
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>{type} Category</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {/* {values.name} */}
                    <Row className='mt-3'>
                      <Col>
                        <BForm.Label>
                          Select Language<span className='text-danger'> *</span>
                        </BForm.Label>

                        <BForm.Select
                          name='language'
                          value={language}
                          onChange={(e) => {

                            setLangauge(e.target.value)
                            const label = Object.assign({}, values?.name)

                            if (label[e.target.value] === undefined) {
                              label[e.target.value] = ''
                            }
                            setCreateName(label)

                          }}
                        >
                          <option value=''>Select Language</option>
                          {languages?.count && languages?.rows?.map(({ languageName, code }) => {
                            return !Object.keys(createName)?.includes(code) && (
                              <option key={code} value={code}>{languageName}</option>
                            )
                          })}
                        </BForm.Select>
                      </Col>
                    </Row>

                    <Row className='mt-3'>
                      <Col>
                        <BForm.Label>
                          Label Name<span className='text-danger'> *</span>
                        </BForm.Label>

                        {Object.keys(values?.name).map((language) => {
                          return (
                            <div className='d-flex align-items-center mt-1' key={language}>
                              <Col>
                                <Col className='d-flex m-1'>
                                  <InputGroup>
                                    <InputGroup.Text>{language}</InputGroup.Text>
                                    <BForm.Control
                                      type='text'
                                      name={`name[${language}]`}
                                      placeholder='Enter  Name'
                                      value={values.name[language]}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </InputGroup>

                                  <Button
                                    variant='danger'
                                    disabled={language === 'EN' || data?.name?.[language] !== undefined}
                                    style={{ Height: '35px', lineHeight: '14px' }}
                                    onClick={() => {
                                      const label = Object.assign({}, values?.name)
                                      delete label[language]
                                      setCreateName(label)
                                      setFieldValue('name', label)
                                      setLangauge('EN')
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </Button>
                                </Col>

                                <ErrorMessage
                                  component='div'
                                  name={`name[${language}]`}
                                  className='text-danger'
                                />
                              </Col>
                            </div>
                          )
                        })}
                      </Col>
                    </Row>

                    {/* <Col>
                        <BForm.Label>
                        Category Name<span className='text-danger'> *</span>
                        </BForm.Label>

                        <BForm.Control
                          type='text'
                          name='subCategoryName'
                          placeholder='Enter Sub-Category Name'
                          value={values.subCategoryName.EN}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <ErrorMessage
                          component='div'
                          name='name'
                          className='text-danger'
                        />
                      </Col> */}

                    <Row className='mt-3'>
                      <Col>
                        <BForm.Label>
                          Game Category<span className='text-danger'> *</span>
                        </BForm.Label>

                        <BForm.Select
                          type='text'
                          name='gameCategoryId'
                          value={values.gameCategoryId}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={data?.gameCategoryId}
                        >
                          <option value='' disabled>---Select game category---</option>
                          {gameCategory &&
                            gameCategory?.rows.map(({ gameCategoryId, name }) => {
                              let casinoCategory
                              try {
                                casinoCategory = JSON.parse(name).EN
                              } catch (error) {
                                casinoCategory = name
                              }
                              return (
                                <option value={gameCategoryId} key={gameCategoryId}>
                                  {casinoCategory}
                                </option>
                              )
                            })}
                        </BForm.Select>

                        <ErrorMessage
                          component='div'
                          name='gameCategoryId'
                          className='text-danger'
                        />
                      </Col>
                    </Row>

                    <Row className='mt-3'>
                      <Col sm='2'>
                        <BForm.Label>
                          Status<span className='text-danger'> *</span>
                        </BForm.Label>
                      </Col>
                      <Col sm='3'>
                        <BForm.Check
                          className='mx-auto'
                          type='checkbox'
                          name='isActive'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.isActive}
                          defaultChecked={values.isActive}
                        />

                      </Col>
                    </Row>
                    {/*
                    <Row className='mt-3'>
                      <Col className='d-flex'>
                        <BForm.Label>
                          Demo Mode<span className='text-danger'> *</span>
                        </BForm.Label>

                        <BForm.Check
                          className='mx-auto'
                          type='checkbox'
                          name='demo'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.demo}
                          defaultChecked={values.demo}
                        />
                      </Col>
                    </Row> */}

                    <Row className='mt-3'>
                      <Col className='d-grid'>
                        <BForm.Label>Thumbnail</BForm.Label>

                        <BForm.Text className='FileWidth'>
                          <Trigger message='Only PNG, JPG, JPEG, SVG allowed with size < 1MB'>
                            <input
                              id='file'
                              title=' '
                              name='subcategoryImage'
                              type='file'
                              onChange={(event) => {
                                setFieldValue(
                                  'subcategoryImage',
                                  event.currentTarget.files[0]
                                )
                              }}
                            />
                          </Trigger>
                          {values?.subcategoryImage && (
                            <img
                              style={{ backgroundColor: '#999' }}
                              alt='not found'
                              width='80px'
                              src={URL.createObjectURL(values.subcategoryImage)}
                            />
                          )}
                          {!values?.subcategoryImage && data?.imageUrl && (
                            <>
                              <img
                                style={{ backgroundColor: '#999' }}
                                alt='not found'
                                width='60px'
                                src={data?.imageUrl}
                              />
                              {/* <span onClick={()=>setFieldValue(
                              'subcategoryImage',
                              null
                            )}>
                            <FontAwesomeIcon icon={faCross}/>
                            </span> */}
                            </>
                          )}
                        </BForm.Text>

                        <ErrorMessage
                          component='div'
                          name='subcategoryImage'
                          className='text-danger'
                        />
                      </Col>
                    </Row>

                  </Modal.Body>

                  <div className='mt-4 '>
                    <Modal.Footer className='d-flex justify-content-between align-items-center'>
                      <Button className='btn-danger' onClick={() => handleClose()}>
                        Cancel
                      </Button>
                      <Button
                        className='btn-primary'
                        onClick={handleSubmit}
                      >
                        Submit
                        {loading && (
                          <Spinner
                            as='span'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        )}
                      </Button>
                    </Modal.Footer>
                  </div>
                </Modal>
              </Form>
            )}
          </Formik>)}
    </>
  )
}

export default CreateCasinoCategory
