import React from "react";

import { DivSpinnerLoaderConainer } from "./style";
//working
const SpinnerLoader = () => {
    return (
        <DivSpinnerLoaderConainer className="d-flex justify-content-center">
            <div className="spinner-grow text-primary spinner-grow-sm" role="status">
                 <span class="visually-hidden">Loading...</span>
            </div>
            <div className="spinner-grow text-primary ml-2 spinner-grow-sm" role="status">
                 <span class="visually-hidden">Loading...</span>
            </div>
            <div className="spinner-grow text-primary ml-2 spinner-grow-sm" role="status">
                 <span class="visually-hidden">Loading...</span>
            </div>
        </DivSpinnerLoaderConainer>
    )
}

export default SpinnerLoader;
