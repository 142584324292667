import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    getAdminUsersStart,
    getAdminUsersSuccess,
    getAdminUsersFailure,
    createAdminUserStart,
    createAdminUserSuccess,
    createAdminUserFailure,
    getAdminUserDetailsStart,
    getAdminUserDetailsSuccess,
    getAdminUserDetailsFailure,
    createSuperAdminUserStart,
    createSuperAdminUserComplete,
    updateSuperAdminUserStart,
    updateSuperAdminUserComplete,
    updateSuperAdminStatusStart,
    updateSuperAdminStatusComplete,
    updateTenantAdminUserStart,
    updateTenantAdminUserComplete,
    getAllGroupsStart,
    getAllGroupsSuccess,
    getAllGroupsFailure
  },
  reducer
} = createSlice({
  name: 'adminUser',
  initialState: {
    loading: false,
    error: null,
    adminUsers: [],
    adminUserDetails: {},
    groups: []
  },
  reducers: {
    getAdminUsersStart: (state) => ({
      ...state,
      loading: true
    }),
    getAdminUsersSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      adminUsers: payload
    }),
    getAdminUsersFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),

    getAdminUserDetailsStart: (state) => ({
      ...state,
      loading: true
    }),
    getAdminUserDetailsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      adminUserDetails: payload
    }),
    getAdminUserDetailsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    createAdminUserStart: (state) => ({
      ...state,
      loading: true,
      error: null,
      success: false
    }),
    createAdminUserSuccess: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: true
    }),
    createAdminUserFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      success: false,
      error: payload
    }),
    updateSuperAdminUserStart: (state) => ({
      ...state,
      loading: true
    }),
    updateSuperAdminUserComplete: (state) => ({
      ...state,
      loading: false
    }),
    createSuperAdminUserStart: (state) => ({
      ...state,
      loading: true
    }),
    createSuperAdminUserComplete: (state) => ({
      ...state,
      loading: false
    }),
    updateSuperAdminStatusStart: (state) => ({
      ...state,
      loading: true
    }),
    updateSuperAdminStatusComplete: (state) => ({
      ...state,
      loading: false
    }),
    updateTenantAdminUserStart: (state) => ({
      ...state,
      loading: true
    }),
    updateTenantAdminUserComplete: (state) => ({
      ...state,
      loading: false
    }),
    getAllGroupsStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllGroupsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      groups: payload
    }),
    getAllGroupsFailure: (state, { payload }) => ({
      ...state,
      loading: false
    })
  }
})

export default reducer
export {
  getAdminUsersStart,
  getAdminUsersSuccess,
  getAdminUsersFailure,
  getAdminUserDetailsStart,
  getAdminUserDetailsSuccess,
  getAdminUserDetailsFailure,
  createAdminUserStart,
  createAdminUserSuccess,
  createAdminUserFailure,
  updateSuperAdminUserStart,
  updateSuperAdminUserComplete,
  createSuperAdminUserStart,
  createSuperAdminUserComplete,
  updateSuperAdminStatusStart,
  updateSuperAdminStatusComplete,
  updateTenantAdminUserStart,
  updateTenantAdminUserComplete,
  getAllGroupsStart,
  getAllGroupsSuccess,
  getAllGroupsFailure
}
