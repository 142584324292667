import React from "react";
import {
    Table,
  } from '@themesberg/react-bootstrap'
import PaginationComponent from '../../../components/Pagination'
import { NoDataContainer } from "./style";
import DivLoader from "../../../components/Loader/divLoader";

const SportsListTable = (props) => {
    const { 
        sportsTournamentInfo,
        pageNo,
        setPageNo,
        totalPages,
        limit,
        setLimit,
        isGetTournamentLoading
    } = props;
    return (
        <div className='TableBg mt-4 pt-1'>
			<Table  striped responsive hover size='sm' className='text-center mt-3'>
				<thead className='thead-light'>
                    <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Country</th>
                        <th>Sport</th>
                        <th>Actions</th>
                    </tr>
				</thead>

				{/* <tbody id={loading ? 'cover-spin' : ''}> */}
				<tbody>
                    {
                        !isGetTournamentLoading && sportsTournamentInfo?.rows?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.tournamentId}</td>
                                    <td>{item.tournamentName[0].name}</td>
                                    <td>{item.country?.countryName[0].name}</td>
                                    <td>{item.sports?.sportName[0].name}</td>
                                    <td>-</td>
                                </tr>
                            )
                        })
                    }
                    {
                        isGetTournamentLoading &&
                        <tr>
                            <td colSpan="5">
                                <NoDataContainer>
                                    <DivLoader />
                                </NoDataContainer>
                            </td>                            
                        </tr>
                        
                    }
                    
				</tbody>

				{/* {countries?.count === 0 && !loading &&
							(
							<tr>
								<td
								colSpan={5}
								className='text-danger text-center'
								>
								No data found
								</td>
							</tr>
							)} */}
			</Table>
			{
                sportsTournamentInfo?.totalPage !== 0 && !isGetTournamentLoading &&
                (
                    <PaginationComponent
                        page={ sportsTournamentInfo?.totalPage < pageNo ? setPageNo(1) : pageNo}
                        totalPages={totalPages}
                        setPage={setPageNo}
                        limit={limit}
                        setLimit={setLimit}
                    />
                )
            }
		</div>
    )
};

export default SportsListTable;