import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    getAllSuperAdminTransactionsStart,
    getAllSuperAdminTransactionsSuccess,
    getAllSuperAdminTransactionsFailure,
    getSuperAdminCasinoTransactionsStart,
    getSuperAdminCasinoTransactionsSuccess,
    getSuperAdminCasinoTransactionsFailure,
    getSuperAdminSportsTransactionsStart,
    getSuperAdminSportsTransactionsSuccess,
    getSuperAdminSportsTransactionsFailure
  },
  reducer
} = createSlice({
  name: 'transactions',
  initialState: {
    loading: false,
    transactions: {},
    casinoTransactions: {},
    sportTransactions:{}
  },
  reducers: {
    getAllSuperAdminTransactionsStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getAllSuperAdminTransactionsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      transactions: payload
    }),
    getAllSuperAdminTransactionsFailure: (state) => ({
      ...state,
      loading: false
    }),
    getSuperAdminCasinoTransactionsStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getSuperAdminSportsTransactionsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      sportTransactions: payload
    }),
    getSuperAdminSportsTransactionsFailure: (state) => ({
      ...state,
      loading: false
    }),
    getSuperAdminSportsTransactionsStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getSuperAdminCasinoTransactionsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      casinoTransactions: payload
    }),
    getSuperAdminCasinoTransactionsFailure: (state) => ({
      ...state,
      loading: false
    })
  }
})

export default reducer

export {
  getAllSuperAdminTransactionsStart,
  getAllSuperAdminTransactionsSuccess,
  getAllSuperAdminTransactionsFailure,
  getSuperAdminCasinoTransactionsStart,
  getSuperAdminCasinoTransactionsSuccess,
  getSuperAdminCasinoTransactionsFailure,
  getSuperAdminSportsTransactionsStart,
  getSuperAdminSportsTransactionsSuccess,
  getSuperAdminSportsTransactionsFailure
}
