import React, { useEffect, useState } from 'react'
import { Table } from '@themesberg/react-bootstrap'
import { getPercentageColor, getTextColor } from '../../utils/dashboardEffects'
import { defaultCurrency } from '../../utils/constant'
import PaginationComponent from '../Pagination'
import FormatNumber from '../../utils/formatNumber'
const GameReportTable = ({ tableData, isPlayer, setPage }) => {

  useEffect(() => {
    setPage(1)
  }, [])


  return (
    <div style={{ overflow: 'auto', maxHeight: '385px' }}>
      <Table striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-light'>
          <tr>
            {isPlayer
              ? ['ID',
                'Name',
                'Number of Rounds',
                'Total Bets',
                'Total Wins',
                'Game Revenue',
                'Payout'
              ].map((h) => (
                <th key={h}>{h}</th>
              ))
              : [
                'ID',
                'Nome',
                'Valor da aposta',
                'Quantidade de apostas',
                'Valores dos prêmios',
                'Quantidade de prêmios',
                'GGR'
              ].map((h) => (
                <th key={h}>{h}</th>
              ))}
          </tr>
        </thead>

        <tbody>
          {tableData && tableData.length > 0
            ? tableData.map(({ gameId, gameName, betAmount, betCount, winAmount, winCount, ggr }, idx) => {
              return (
                <tr key={`top-loosers ${gameId}`}>
                  <td>{gameId}</td>
                  <td>
                    {gameName}
                  </td>
                  <td style={{ color: 'green' }}>{FormatNumber.currency(betAmount || 0)}</td>
                  <td>{betCount || '0'}</td>
                  <td style={{ color: 'red' }}>{FormatNumber.currency(winAmount || 0)}</td>
                  <td>{winCount || '0'}</td>
                  <td className={`${getTextColor(ggr)}`}>{FormatNumber.currency(ggr || 0)}</td>
                  {/* {!isPlayer && <td>{playerCount || '0'}</td>}
                  <td className={getPercentageColor(payout)}>{payout || '0'}</td> */}
                </tr>
              )
            })
            : (
              <tr>
                <td colSpan={7} className='text-danger text-center'>
                  Registros não encontrados
                </td>
              </tr>
            )}
        </tbody>
      </Table>
    </div>
  )
}

export default GameReportTable
