

export const errorTypes = {
  AffiliatesNotFound: "Afiliado não encontrado!",
  PaymentProviderError: "Ocorreu um erro ao obter o provedor de pagamento",
  UserIdentificationError: "É necessário cadastrar seu CPF para essa solicitação",
  CpfValidationError: "Ocorreu um erro ao validar o CPF do usuário",
  PixCpfMustBeSameRegisteredError: "O CPF informado para saque deve ser o mesmo do cadastro",
  WalletNotFound: "Carteira não encontrada",
  // BalanceError: "",
  CpfInvalidError: "O número de CPF não é válido",
  CpfIrregularError: "O número de CPF está irregular",
  CashOutLimitPerDayError: "O limite de solicitação de saque é de 1 por dia",
  TransactionRepeatedInLess10MinutesError: "Transação repetida em menos de 10 minutos.",
  PixKeyDontBelongToDocumentErrorType: "A chave pix não pertence ao mesmo CPF do usuário",
  CashOutMaxValueIs50000ByDocumentPerDayErrorType: "O valor máximo de saque é de R$ 50.000,00 por documento por dia",
  WithdrawDataError: "Ocorreu um erro ao gerar os dados de saque",
  ThereIsAPendingWithdrawRequestError: "Já existe uma solicitação de saque pendente.",
  CpfAlreadyExistsErrorType: "Já existe um affiliado cadastrado com esse CPF",
  AffiliateStatusPendindErrorType: "Afiliado com status pendente, para atualizá-lo, primeiro você deve aprová-lo"
}

export const resolveErrors = (/**@type {{ name: string, description: String, errorCode: number }[]}*/ errors, /** @type { string }*/alternativeMessage) => {
  return errorTypes[errors[0].name] ?? alternativeMessage ?? errors[0]?.description
}