import { faGalacticSenate } from '@fortawesome/free-brands-svg-icons'
import { createSlice, current } from '@reduxjs/toolkit';

const {
  actions: {
    getSportsListStart,
    getSportsListSuccess,
    getSportsListFailure,
    getCountriesListStart,
    getCountriesListSuccess,
    getCountriesListFailure,
    getMatchesListStart,
    getMatchesListSuccess,
    getMatchesListFailure,
    getMarketsListStart,
    getMarketsListSuccess,
    getMarketsListFailure,
    getTournamentsListStart,
    getTournamentsListSuccess,
    getTournamentsListFailure,
    updateStatusStart,
    updateStatusSuccess,
    updateStatusFailure,
    addFeaturedStart,
    addFeaturedSuccess,
    addFeaturedFailure,
    uploadImageStart,
    uploadImageSuccess,
    uploadImagefailure,
    uploadImageReset,
    getSportsMatchesDetailStart,
    getSportsMatchesDetailSuccess,
    getSportsMatchesDetailfailure,
    updateOddsVariationStart,
    updateOddsVariationSuccess,
    updateOddsVariationFailure,
    detachOddsVariationStart,
    detachOddsVariationSuccess,
    detachOddsVariationFailure,
    updateCompanyOddStart,
    updateCompanyOddSuccess,
    updateCompanyOddFailure
  },
  reducer
} = createSlice({
  name: 'sportsBook',
  initialState: {
    isGetSportsLoading: false,
    sportsListInfo: {},
    isGetCountriesLoading: false,
    sportsCountriesInfo: {},
    isGetMatchesLoading: false,
    sportsMatchesInfo: {},
    isGetMarketsLoading: false,
    sportsMarketsInfo: {},
    isGetTournamentLoading: false,
    sportsTournamentInfo: {},
    isUpdateStatusLoading: false,
    isAddFeaturedLoading: false,
    uploadImgLoading: false,
    uploadImgSuccess: false,
    getSportsMatchesDetail: {},
    isGetSportsMatchesDetailLoading: false,
    updateOddsVariationLoading: false,
    detachOddsVariationLoading: false,
    updateCompanyOddLoading: false
  },
  reducers: {
    getSportsListStart: (state) => ({
      ...state,
      isGetSportsLoading: true
    }),
    getSportsListSuccess: (state, { payload }) => ({
      ...state,
      isGetSportsLoading: false,
      sportsListInfo: payload
    }),
    getSportsListFailure: (state, { payload }) => ({
      ...state,
      isGetSportsLoading: false
    }),
    getCountriesListStart: (state) => ({
      ...state,
      isGetCountriesLoading: true
    }),
    getCountriesListSuccess: (state, { payload }) => ({
      ...state,
      isGetCountriesLoading: false,
      sportsCountriesInfo: payload
    }),
    getCountriesListFailure: (state, { payload }) => ({
      ...state,
      isGetCountriesLoading: false
    }),
    getMatchesListStart: (state) => ({
      ...state,
      isGetMatchesLoading: true
    }),
    getMatchesListSuccess: (state, { payload }) => ({
      ...state,
      isGetMatchesLoading: false,
      sportsMatchesInfo: payload
    }),
    getMatchesListFailure: (state, { payload }) => ({
      ...state,
      isGetMatchesLoading: false
    }),
    getMarketsListStart: (state) => ({
      ...state,
      isGetMarketsLoading: true
    }),
    getMarketsListSuccess: (state, { payload }) => ({
      ...state,
      isGetMarketsLoading: false,
      sportsMarketsInfo: payload
    }),
    getMarketsListFailure: (state, { payload }) => ({
      ...state,
      isGetMarketsLoading: false
    }),
    getTournamentsListStart: (state) => ({
      ...state,
      isGetTournamentLoading: true
    }),
    getTournamentsListSuccess: (state, { payload }) => ({
      ...state,
      isGetTournamentLoading: false,
      sportsTournamentInfo: payload
    }),
    getTournamentsListFailure: (state, { payload }) => ({
      ...state,
      isGetTournamentLoading: false
    }),
    updateStatusStart: (state) => ({
      ...state,
      isUpdateStatusLoading: true
    }),
    updateStatusSuccess: (state, { payload }) => {
      const data = {
        ...state,
        isUpdateStatusLoading: false
      }
      if (payload.code === 'SPORTS') {
        const temp = { ...current(state).sportsListInfo };
        let newObject = temp?.sportsList?.rows?.map(obj => {
          return obj.sportId === payload.sportId ? { ...obj, isActive: payload.status ? true : false } : obj
        })
        const newData = {
          ...state.sportsListInfo,
          sportsList: {
            ...state.sportsListInfo.sportsList,
            rows: newObject
          }
        };
        data.sportsListInfo = newData;
      } else if (payload.code === 'SPORTCONTRY') {
        const temp = { ...current(state).sportsCountriesInfo };
        let newObject = temp?.countryList?.rows?.map(obj => {
          return obj.countryId === payload.sportCountryId ? { ...obj, isActive: payload.status ? true : false } : obj
        })
        const newData = {
          ...state.sportsCountriesInfo,
          countryList: {
            ...state.sportsCountriesInfo.countryList,
            rows: newObject
          }
        };
        data.sportsCountriesInfo = newData;
      }
      return (
        {
          ...state,
          ...data,
        })
    },
    updateStatusFailure: (state, { payload }) => ({
      ...state,
      isUpdateStatusLoading: false
    }),
    addFeaturedStart: (state) => ({
      ...state,
      isAddFeaturedLoading: true
    }),
    addFeaturedSuccess: (state, { payload }) => {
      const data = {
        ...state,
        isAddFeaturedLoading: false
      }
      const temp = { ...current(state).sportsMatchesInfo };
      let newObject = temp?.rows?.map(obj => {
        return obj.matchId === payload.matchId ? { ...obj, isFeatured: payload.isFeatured } : obj
      });
      const newData = {
        ...state.sportsMatchesInfo,
        rows: newObject
      };
      return (
        {
          ...state,
          isAddFeaturedLoading: false,
          sportsMatchesInfo: newData,
        })
    },
    addFeaturedFailure: (state, { payload }) => ({
      ...state,
      isAddFeaturedLoading: false
    }),
    uploadImageStart: (state, { payload }) => ({
      ...state,
      uploadImgLoading: true,
    }),
    uploadImageSuccess: (state) => ({
      ...state,
      uploadImgLoading: false,
      uploadImgSuccess: true
    }),
    uploadImageReset: (state) => ({
      ...state,
      uploadImgSuccess: false
    }),
    uploadImagefailure: (state) => ({
      ...state,
      uploadImgLoading: false,
    }),
    getSportsMatchesDetailStart: (state) => ({
      ...state,
      isGetSportsMatchesDetailLoading: true
    }),
    getSportsMatchesDetailSuccess: (state, { payload }) => ({
      ...state,
      isGetSportsMatchesDetailLoading: false,
      getSportsMatchesDetail: payload
    }),
    getSportsMatchesDetailFailure: (state, { payload }) => ({
      ...state,
      isGetSportsMatchesDetailLoading: false
    }),
    updateOddsVariationStart: (state, { payload }) => ({
      ...state,
      updateOddsVariationLoading: true
    }),
    updateOddsVariationSuccess: (state, { payload }) => ({
      ...state,
      updateOddsVariationLoading: false
    }),
    updateOddsVariationFailure: (state, { payload }) => ({
      ...state,
      updateOddsVariationLoading: false
    }),
    detachOddsVariationStart: (state, { payload }) => ({
      ...state,
      detachOddsVariationLoading: true
    }),
    detachOddsVariationSuccess: (state, { payload }) => ({
      ...state,
      detachOddsVariationLoading: false
    }),
    detachOddsVariationFailure: (state, { payload }) => ({
      ...state,
      detachOddsVariationLoading: false
    }),
    updateCompanyOddStart: (state, { payload }) => ({
      ...state,
      updateCompanyOddLoading: true
    }),
    updateCompanyOddSuccess: (state, { payload }) => {
      try {
        let temp = { ...current(state).getSportsMatchesDetail }
        temp = JSON.parse(JSON.stringify(temp))
        let newObject = temp?.rows?.map(obj => {
          let data = { ...obj };
          if (obj.matchMarketId == payload.matchMarketId) {
            data.outcome[payload.companyIndex] = { ...data?.outcome[payload.companyIndex], customOdd: payload.modificationValue };
          } else {
            data.outcome[payload.companyIndex] = { ...data?.outcome[payload.companyIndex] };
          }
          return data;
        });
        const newData = {
          ...state.getSportsMatchesDetail,
          rows: newObject
        };
        return ({
          ...state,
          updateCompanyOddLoading: false,
          getSportsMatchesDetail: newData
        })
      } catch (e) {
        console.log('eeeeeeeee', e);
      }
    },
    updateCompanyOddFailure: (state, { payload }) => ({
      ...state,
      updateCompanyOddLoading: false
    })
  }
})

export default reducer
export {
  getSportsListStart,
  getSportsListSuccess,
  getSportsListFailure,
  getCountriesListStart,
  getCountriesListSuccess,
  getCountriesListFailure,
  getMatchesListStart,
  getMatchesListSuccess,
  getMatchesListFailure,
  getMarketsListStart,
  getMarketsListSuccess,
  getMarketsListFailure,
  getTournamentsListStart,
  getTournamentsListSuccess,
  getTournamentsListFailure,
  updateStatusStart,
  updateStatusSuccess,
  updateStatusFailure,
  addFeaturedStart,
  addFeaturedSuccess,
  addFeaturedFailure,
  uploadImageStart,
  uploadImageSuccess,
  uploadImagefailure,
  uploadImageReset,
  getSportsMatchesDetailStart,
  getSportsMatchesDetailSuccess,
  getSportsMatchesDetailfailure,
  updateOddsVariationStart,
  updateOddsVariationSuccess,
  updateOddsVariationFailure,
  detachOddsVariationStart,
  detachOddsVariationSuccess,
  detachOddsVariationFailure,
  updateCompanyOddStart,
  updateCompanyOddSuccess,
  updateCompanyOddFailure
}
