import { Table } from '@themesberg/react-bootstrap'
import React from 'react'
import Trigger from '../../../../../components/OverlayTrigger'

const SumsubKYCTable = ({
  userDocuments
}) => {
  return (
    <>
      <Table
        striped
        responsive
        hover
        size='sm'
        className='text-center'
      >
        <thead className='thead-light'>
          <tr>
            <th>ID</th>
            <th>Nome</th>
            <th>Motivo</th>
            <th>Status</th>
          </tr>
        </thead>

        <tbody>
          {userDocuments && userDocuments?.IDENTITY
            ? Object.keys(userDocuments)?.map((docs, index) => (
              userDocuments[docs] &&
              (userDocuments[docs]?.imageIds
                ? userDocuments[docs]?.imageIds?.map((imgId, indx) => (
                  <tr key={`${userDocuments[docs]}-${indx}`}>
                    <td>{imgId}</td>
                    <td>{`${docs} ${userDocuments[docs].imageIds?.length > 1 ? (indx === 0 ? '(FRONT)' : '(BACK)') : ''}`}</td>
                    <td>
                      <Trigger message={userDocuments[docs]?.imageReviewResults?.[imgId]?.clientComment || userDocuments[docs]?.imageReviewResults?.[imgId]?.moderationComment || '-'}>
                        <span
                          style={{
                            width: '300px',
                            cursor: 'pointer'
                          }}
                          className='d-inline-block text-truncate'
                        >
                          {userDocuments[docs]?.imageReviewResults?.[imgId]?.clientComment || userDocuments[docs]?.imageReviewResults?.[imgId]?.moderationComment || '-'}
                        </span>
                      </Trigger>
                    </td>
                    <td>{userDocuments[docs]?.imageReviewResults?.[imgId]?.reviewAnswer
                      ? (userDocuments[docs]?.imageReviewResults?.[imgId]?.reviewAnswer === 'RED'
                        ? 'Rejected'
                        : 'Accepted')
                      : 'Requested'}
                    </td>
                  </tr>
                ))
                : (
                  <tr key={`${userDocuments[docs]}-${index}`}>
                    <td>N/A</td>
                    <td>{docs}</td>
                    <td>-</td>
                    <td>{userDocuments[docs]?.reviewResult
                      ? (userDocuments[docs]?.reviewResult === 'RED'
                        ? 'Rejected'
                        : 'Accepted')
                      : 'Requested'}
                    </td>
                  </tr>
                )
              )))
            : (
              <tr>
                <td colSpan={4} className='text-center text-danger'>No data found</td>
              </tr>
            )}
        </tbody>
      </Table>
    </>
  )
}

export default SumsubKYCTable
