import React, { useEffect } from "react";
import { Row, Col } from "@themesberg/react-bootstrap";
import { Form } from "formik";
import { AdminsRoutes, AffiliateRoutes } from "../../../../routes";
import useAffiliateForm from "../hooks/useAffiliateForm";
import Trigger from "../../../../components/OverlayTrigger";
import CreatableSelect from "react-select/creatable";
import { useParams, useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import InputMask from 'react-input-mask';
import { FormikErrorMessage } from "../../../../components/ErrorMessage";

import useRole from "../../../../hooks/useRole";
import UploadImageInput from "../../../../components/UploadImageInput";
import Button from "../../../../components/Button";
import { AFFILIATE_STATUS, AFFILIATE_TYPE, CPA_TYPE, CPA_TYPE_VALUE } from "../../../../utils/constant";
import CurrencyInput from "../../../../components/CurrencyInput";
import FormatNumber from "../../../../utils/formatNumber";

import { Form as BForm } from "react-bootstrap";
import config from "../../../../configs/app.config";
import { Loading } from "../../../../components/Preloader";

const AffiliateForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  handleBlur,
  setFieldValue,
  setErrors,
  isEdit = false,
  loading,
}) => {
  const {
    navigate,
    data,
    adminRole,
    adminDetails,
    permissionLabel,
    superAdminDetails,
    getAllAdmins,
    getAdminDetails,
    type,
    setType,
    groupOptions,
    setGroupOptions,
    selectedGroup,
    setSelectedGroup,
    superAdminUser,
    selectedGender,
    setSelectedGender,
    countriesOptions,
    selectedCountry,
    setSelectedCountry,
    affiliateTypeOptions
  } = useAffiliateForm({ group: values?.group });
  const { affiliateId } = useParams();
  const { affiliateByIdData, getAffiliateByIdStartLoader } = useSelector((state) => state.fetch);
  const { Admin, SuperAdmin, Affiliate } = useRole();

  const location = useLocation();

  useEffect(() => {
    if (values.role === "Manager") {
      getAllAdmins({
        limit: "",
        pageNo: "",
        sort: "desc",
        orderBy: "adminUserId",
        search: "",
        superRoleId: 2,
      });
      values?.adminId && getAdminDetails({ adminId: values.adminId });
    }
  }, [values.role]);

  if (getAffiliateByIdStartLoader) return (<Loading className="p-3" />);

  return ((!isEdit) || isEdit && affiliateByIdData?.status !== AFFILIATE_STATUS.PENDING) ?
    (
      <Form className="position-relative">
        <Row>
          <Col md={6} sm={12} className="mb-3">
            <BForm.Label>Nome</BForm.Label>
            <BForm.Control
              type="text"
              name="firstName"
              placeholder="Informe o nome"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormikErrorMessage
              component="div"
              name="firstName"
              className="text-danger"
            />
          </Col>
          <Col md={6} sm={12} className="mb-3">
            <BForm.Label>Sobrenome</BForm.Label>
            <BForm.Control
              type="text"
              name="lastName"
              placeholder="Informe o sobrenome"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormikErrorMessage
              component="div"
              name="lastName"
              className="text-danger"
            />
          </Col>
          <Col md={6} sm={12} className="mb-3">
            <BForm.Label>Nome de usuário</BForm.Label>
            <BForm.Control
              type="text"
              name="username"
              placeholder="Informe nome de usuário"
              value={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={affiliateId}
            />
            <FormikErrorMessage
              component="div"
              name="username"
              className="text-danger"
            />
          </Col>
          <Col md={6} sm={12} className="mb-3">
            <BForm.Label>Email</BForm.Label>
            <BForm.Control
              type="text"
              name="email"
              placeholder="Informe o email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              // disabled={isEdit}
              disabled={Affiliate}
            />
            <FormikErrorMessage
              component="div"
              name="email"
              className="text-danger"
            />
          </Col>

          <Col md={6} sm={12} className="mb-3">
            <BForm.Label>CPF</BForm.Label>
            <BForm.Control
              type="text"
              name="cpf"
              as={InputMask}
              mask="999.999.999-99"
              placeholder="Informe o CPF"
              value={values.cpf}
              onChange={handleChange}
              onBlur={handleBlur}
              // disabled={isEdit}
              disabled={affiliateByIdData?.cpf && Affiliate}
            />
            <FormikErrorMessage
              component="div"
              name="cpf"
              className="text-danger"
            />
          </Col>

          <Col md={6} sm={12} className="mb-3">
            <BForm.Label>País</BForm.Label>
            <CreatableSelect
              isClearable
              name="country"
              options={countriesOptions}
              value={countriesOptions.find(({ value }) => value == values.country)}
              classNamePrefix="select"
              onChange={(option, e) => {
                setFieldValue("country", option?.value);
              }}
            />
            <FormikErrorMessage
              component="div"
              name="group"
              className="text-danger"
            />
          </Col>



          {(Admin || SuperAdmin) ? <>
            <Col md={6} sm={12} className="mb-3">
              <BForm.Label>Tipo de Afiliado</BForm.Label>
              <CreatableSelect
                name="affiliateType"
                options={affiliateTypeOptions}
                value={affiliateTypeOptions.find(({ value }) => value == values.affiliateType)}
                classNamePrefix="select"
                onChange={(option, e) => {
                  if (option === null) {
                    setFieldValue("affiliateType", null);
                  } else {
                    setFieldValue("affiliateType", option?.value);
                  }
                }}
              />
              <FormikErrorMessage
                component="div"
                name="affiliateType"
                className="text-danger"
              />
            </Col>
          </> : null}

          <Col md={6} sm={12} className="mb-3">
            <BForm.Label>Telefone</BForm.Label>
            <BForm.Control
              type="text"
              name="phone"
              as={InputMask}
              mask="(99) 99999-9999"
              placeholder="Informe o número de telefone"
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormikErrorMessage
              component="div"
              name="phone"
              className="text-danger"
            />
          </Col>



          <Col md={6} sm={12} className="mb-3">
            <BForm.Label>Imagem de perfil</BForm.Label>
            <UploadImageInput
              name="avatar"
              src={values.avatar}
              onBase64Change={(base64) => {
                setFieldValue("avatar", base64)
              }}
              onBlur={handleBlur}
            />
            <FormikErrorMessage
              component="div"
              name="avatar"
              className="text-danger"
            />
          </Col>
          <Col md={6} sm={12} className="mb-3 d-flex flex-column">
            <BForm.Label>Biografia</BForm.Label>
            <BForm.Control
              as={"textarea"}
              name="bio"
              value={values.bio}
              onChange={handleChange}
              onBlur={handleBlur}
              className="h-100"
              style={{ height: "100%" }}
            />
            <FormikErrorMessage
              component="div"
              name="bio"
              className="text-danger"
            />
          </Col>

        </Row>

        {(Admin || SuperAdmin) ? <>
          <h5 className='mt-3 mb-3 d-flex gap-2 color-blue-800 align-items-center'>
            <span className='mw-fit'>
              Comissões
            </span>
            <hr className='w-100' />
          </h5>
          <Row>
            <Col md={affiliateByIdData?.affiliateType == AFFILIATE_TYPE.STREAMER ? 6 : 12} sm={12} className="mb-3">
              <BForm.Label>{Boolean(affiliateByIdData?.parentIdAffiliate) ? "Comissão do Sub (em %)" : "Comissão (em %)"}</BForm.Label>
              <BForm.Control
                type="number"
                name="commission"
                placeholder="Informe a porcentagem da comissão"
                value={values.commission}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormikErrorMessage
                component="div"
                name="commission"
                className="text-danger"
              />
            </Col>
            {affiliateByIdData?.affiliateType == AFFILIATE_TYPE.STREAMER ?
              <Col md={6} sm={12} className="mb-3">
                <BForm.Label>{Boolean(affiliateByIdData?.parentIdAffiliate) ? "Comissão do Sub de lives (em %)" : "Comissão de lives (em %)"}</BForm.Label>
                <BForm.Control
                  type="number"
                  name="liveCommission"
                  placeholder="Informe a porcentagem da comissão de lives"
                  value={values.liveCommission}
                  onChange={handleChange}
                  onBlur={handleBlur}

                />
                <FormikErrorMessage
                  component="div"
                  name="liveCommission"
                  className="text-danger"
                />
              </Col>
              : null}
            {Boolean(affiliateByIdData?.parentIdAffiliate) ?
              <>
                <Col md={affiliateByIdData?.affiliateType == AFFILIATE_TYPE.STREAMER ? 6 : 12} sm={12} className="mb-3">
                  <BForm.Label>Comissão do Afiliado Pai (em %)</BForm.Label>
                  <BForm.Control
                    type="number"
                    name="parentAffiliateCommission"
                    placeholder="Informe a porcentagem da comissão"
                    value={values.parentAffiliateCommission}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FormikErrorMessage
                    component="div"
                    name="parentAffiliateCommission"
                    className="text-danger"
                  />
                </Col>
                {affiliateByIdData?.affiliateType == AFFILIATE_TYPE.STREAMER ? <Col md={6} sm={12} className="mb-3">
                  <BForm.Label>Comissão de lives do Afiliado Pai (em %)</BForm.Label>
                  <BForm.Control
                    type="number"
                    name="parentAffiliateLiveCommission"
                    placeholder="Informe a porcentagem da comissão de lives"
                    value={values.parentAffiliateLiveCommission}
                    onChange={handleChange}
                    onBlur={handleBlur}

                  />
                  <FormikErrorMessage
                    component="div"
                    name="parentAffiliateLiveCommission"
                    className="text-danger"
                  />
                </Col> : null}
              </>
              : null
            }
          </Row>

          <h5 className='mt-3 mb-3 d-flex gap-2 color-blue-800 align-items-center'>
            <Trigger message={"Custo de aquisição de usuários"}>
              <span className='mw-fit'>
                CPA
              </span>
            </Trigger>
            <hr className='w-100' />
          </h5>
          <Row>
            <Col md={4} sm={12} className="mb-3">
              <BForm.Label>CPA</BForm.Label>
              <BForm.Select
                name="cpa"
                value={values.cpa}
                onChange={({ target: { value } }) => setFieldValue("cpa", Number(value))}
              >
                {Object.values(CPA_TYPE).map((value, key) => {
                  return (<option key={key} value={value}>{CPA_TYPE_VALUE[value]}</option>)
                })}
              </BForm.Select>
              <FormikErrorMessage
                component="div"
                name="cpa"
                className="text-danger"
              />
            </Col>
            <Col md={4} sm={12} className="mb-3">
              <BForm.Label>Comissão de CPA (valor bruto)</BForm.Label>
              <CurrencyInput
                placeholder={FormatNumber.currency(0)}
                defaultValue={FormatNumber.currency(affiliateByIdData?.commission?.cpaCommission || 0)}
                disabled={!Boolean(values.cpa)}
                onNumberFormat={(event) => {
                  setFieldValue("cpaCommission", event.detail.number)
                }}
              />
              <FormikErrorMessage
                component="div"
                name="cpaCommission"
                className="text-danger"
              />
            </Col>
            <Col md={4} sm={12} className="mb-3">
              <BForm.Label>Valor mínimo de depósito</BForm.Label>
              <CurrencyInput
                placeholder={FormatNumber.currency(0)}
                defaultValue={FormatNumber.currency(affiliateByIdData?.cpaMinimumDepositAmount || 0)}
                disabled={!Boolean(values.cpa)}
                onNumberFormat={(event) => {
                  setFieldValue("cpaMinimumDepositAmount", event.detail.number)
                }}
              />
              <FormikErrorMessage
                component="div"
                name="cpaMinimumDepositAmount"
                className="text-danger"
              />
            </Col>
          </Row>

          <hr className="my-4" />

          <Row>
            {/* <Col md={6} xs={12}>
              <BForm.Label>Link para cadastro de usuários</BForm.Label>
              <div>
                <BForm.Control disabled
                  placeholder={`${config.app.userBaseUrl.replace(/\/+$/, '')}/signup?referralCode`}
                />
                <BForm.Select
                  name="userLinkPath"
                  value={values.userLinkPath}
                  onChange={({ target: { value } }) => setFieldValue("userLinkPath", Number(value))}
                >
                  {Object.values(CPA_TYPE).map((value, key) => {
                    return (<option key={key} value={value}>{CPA_TYPE_VALUE[value]}</option>)
                  })}
                </BForm.Select>
              </div>
            </Col> */}
            {values?.affiliateType == AFFILIATE_TYPE.STREAMER ?
              <Col md={12} xs={12} className="mb-2 py-1 bg-gray-200-hover rounded-lg">
                <BForm.Check
                  reverse
                  name="isVisible"
                  id="isVisible"
                  checked={values.isVisible}
                  onChange={({ target }) => {
                    setFieldValue("isVisible", target.checked);
                  }}
                  type="switch"
                  label="Visível"
                  feedback="Visibilidade do afiliado na plataforma de casino caso seja do tipo Streamer"
                />
                <FormikErrorMessage
                  component="div"
                  name="isVisible"
                  className="text-danger"
                />
              </Col>
              : null}
            <Col md={12} xs={12} className="mb-2 py-1 bg-gray-200-hover rounded-lg">
              <BForm.Check
                reverse
                name="enableSubAffiliatesSystem"
                id="enableSubAffiliatesSystem"
                checked={values.enableSubAffiliatesSystem}
                onChange={({ target }) => {
                  setFieldValue("enableSubAffiliatesSystem", target.checked ? 1 : 0);
                }}
                type="switch"
                label="Sistema de Sub Afiliados"
                feedback="Habilita o link de cadastro e comissionamento de sub-afiliados"
              />
              <FormikErrorMessage
                component="div"
                name="enableSubAffiliatesSystem"
                className="text-danger"
              />
            </Col>
          </Row>
        </> : null}

        {values.role === "Manager" && (
          <Col md={6} sm={12} className="mb-3">
            <BForm.Label>Admin</BForm.Label>

            <BForm.Select
              name="adminId"
              value={values.adminId || ""}
              disabled={isEdit}
              onChange={(e) => {
                handleChange(e);
                getAdminDetails({ adminId: e.target.value });
              }}
              onBlur={handleBlur}
            >
              <option value="" disabled>
                Select Admin
              </option>
              {data &&
                data?.rows?.map((admin, index) => {
                  return (
                    <option key={index} value={admin && admin.adminUserId}>
                      {admin && `${admin?.firstName} ${admin?.lastName}`}
                    </option>
                  );
                })}
            </BForm.Select>

            <FormikErrorMessage
              component="div"
              name="adminId"
              className="text-danger"
            />
          </Col>
        )}

        <div className="mt-4 d-flex justify-content-between align-items-center">
          <Button
            variant="btn btn-danger"
            onClick={() => {
              if (location.pathname.startsWith('/admin')) {
                navigate(AdminsRoutes.AffiliatesManagement)
              }
              if (location.pathname.startsWith('/affiliate')) {
                navigate(AffiliateRoutes.Profile)
              }

            }}
          >
            Cancelar
          </Button>
          <Button
            loading={loading}
            variant="btn btn-primary"
            onClick={() => {
              handleSubmit();
            }}
            className="ml-2"
          >
            Salvar
          </Button>
        </div>
      </Form>
    ) : (
      <div className="d-flex justify-content-center align-items-center flex-column">
        <p>Afiliado está pendente de aprovação, no momento não é possível atualizar suas informações!</p>
        <Button className onClick={() => navigate(-1)}>
          Voltar
        </Button>
      </div>
    );
};

export default AffiliateForm;
