import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, InputGroup, Spinner } from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { Col, Row, Form as BForm } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { resetProfilePasswordStart } from '../../store/redux-slices/login'
import { profilePasswordSchema } from './schema'

const Password = ({
  loading,
  isTenant
}) => {
  const formData = {
    ANTIGA: 'password',
    NOVA: 'newPassword',
    CONFIRMAR: 'confirmPassword'
  }
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [showPass, setShowPass] = useState({
    OLD: false,
    NEW: false,
    CONFIRM: false
  })

  return (
    <>
      <div className='FormsBg'>
        <Formik
          enableReinitialize
          initialValues={{
            password: '',
            newPassword: '',
            confirmPassword: ''
          }}
          validationSchema={profilePasswordSchema}
          onSubmit={(formValues) => {
            dispatch(resetProfilePasswordStart({
              data: {
                password: formValues?.password ? Buffer.from(formValues?.password).toString('base64') : '',
                newPassword: formValues?.newPassword ? Buffer.from(formValues?.newPassword).toString('base64') : ''
              },
              navigate,
              isTenant
            }))
          }}
        >
          {({ values, handleChange, handleSubmit, handleBlur }) => {
            return (
              <Form>
                {Object.keys(formData)?.map((key, index) => (
                  <Row key={index} className='justify-content-center'>
                    <Col md={6} sm={12} className='mb-3'>
                      <BForm.Label>
                        {key} SENHA
                        <span className='text-danger'> *</span>
                      </BForm.Label>
                      <InputGroup>
                        <BForm.Control
                          type={showPass?.[key] ? 'text' : 'password'}
                          name={formData[key]}
                          value={values?.[formData[key]]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <InputGroup.Text style={{ border: '0.0625rem solid #d1d7e0' }}>
                          {!showPass?.[key]
                            ? <FontAwesomeIcon
                                icon={faEye} style={{ cursor: 'pointer' }}
                                onClick={() => { setShowPass({ ...showPass, [key]: true }) }}
                              />
                            : <FontAwesomeIcon
                                icon={faEyeSlash} style={{ cursor: 'pointer' }}
                                onClick={() => { setShowPass({ ...showPass, [key]: false }) }}
                              />}
                        </InputGroup.Text>
                      </InputGroup>

                      <ErrorMessage
                        component='div'
                        name={formData[key]}
                        className='text-danger'
                      />
                    </Col>
                  </Row>))}
                <Row className='justify-content-center'>
                  <Col md={6} sm={12}>
                    <div className='mt-4'>
                      <Button
                        variant='m-1 btn btn-primary'
                        onClick={handleSubmit}
                      >
                        Salvar
                        {loading && (
                          <Spinner
                            as='span'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        )}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export default Password
