import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { DateRange } from 'react-date-range'
import { formatDateYMD } from '../../utils/dateFormatter'
import useOutsideClick from '../../utils/useOutsideClick'
import './DateRangePicker.scss'
import FormatDate from '../../utils/formatDate';
import * as locales from 'react-date-range/dist/locale';

const DateRangePicker = ({ state, setState, size, width = '100%', disabled = false, bonus = false, transaction = false, player = false, maxDate, placement }) => {
  const { ref, isVisible, setIsVisible } = useOutsideClick(false)

  return (
    <div className='custom-container' style={{ marginTop: (bonus || player) && '0px' }}>
      <input
        readOnly
        disabled={disabled}
        className={
          size ? `form-control form-control-${size} ${player ? 'player' : ''}` : `form-control ${player ? 'player' : ''}`
        }
        onClick={() => setIsVisible(!isVisible)}
        value={`${FormatDate.toDateOnly(state[0]?.startDate)} até ${FormatDate.toDateOnly(state[0]?.endDate)}`}
      />

      {isVisible && (
        <div ref={ref} className='calendar-view' data-placement={placement}>
          {player
            ? (
              <DateRange
                locale={locales["pt"]}
                editableDateInputs
                onChange={(item) => {
                  setState([item.selection])
                }}
                moveRangeOnFirstSelection={false}
                ranges={state}
              />
            )
            : (
              <DateRange
                locale={locales["pt"]}
                editableDateInputs
                onChange={(item) => {
                  setState([item.selection])
                }}
                moveRangeOnFirstSelection={false}
                ranges={state}
                minDate={bonus ? (transaction ? new Date(1999) : new Date()) : new Date(1999)}
                maxDate={maxDate}
                showDateDisplay={false}
              />
            )}
        </div>
      )}
    </div>
  )
}

export default DateRangePicker

export const DateRangePickerWithoutInput = ({ state, setState }) => {
  const { ref, isVisible, setIsVisible } = useOutsideClick(false)
  return (
    <div className='custom-container date d-flex align-items-center mt-0'>
      <span
        className='d-flex '
        style={{ cursor: 'pointer' }}
        onClick={() => setIsVisible(!isVisible)}
      >
        {formatDateYMD(state?.[0].startDate)} - {formatDateYMD(state?.[0].endDate)}&nbsp;  PERIOD &nbsp; <FontAwesomeIcon icon={faCalendarAlt} className='' />{' '} &nbsp;
      </span>

      {isVisible && (
        <div ref={ref} style={{ zIndex: '9999', position: 'absolute', top: '40px', right: '0px' }}>
          <DateRange
            locale={locales["pt"]}
            editableDateInputs
            onChange={(item) => {
              setState([item.selection])
            }}
            moveRangeOnFirstSelection={false}
            ranges={state}
          />
        </div>
      )}
    </div>
  )
}
