import React from 'react'
import { Table } from '@themesberg/react-bootstrap'
import { formatDate } from '../../../../utils/dateFormatter'
import { currencyCodeList } from '../../../../utils/currencyCodeList'
import { Button, Card, Col, Row } from 'react-bootstrap'
import Trigger from '../../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedo } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { getCashbackDataStart } from '../../../../store/redux-slices/tenantUsers'
import { InlineLoader } from '../../../../components/Preloader'

const UserWalletTable = ({ myUserData }) => {
  return (
    <>
      {/* Table with UserWallet info */}
      <Table  striped responsive hover size='sm' className='text-left WalletTable'>
        <tbody>
          <tr key={`currencyCode-${myUserData?.userWallet?.currencyCode}`}>
            <td>Moeda</td>
            <td>{myUserData?.userWallet?.currencyCode}</td>
          </tr>
          <tr
            key={`totalBalance-${
              myUserData?.userWallet?.amount +
              myUserData?.userWallet?.nonCashAmount
            }`}
          >
            <td>Total</td>
            <td>
              {currencyCodeList[myUserData?.userWallet?.currencyCode]}&nbsp;
              {myUserData?.userWallet?.amount +
                myUserData?.userWallet?.nonCashAmount}
            </td>
          </tr>
          <tr key={`cashBalance-${myUserData?.userWallet?.amount}`}>
            <td>Saldo de caixa</td>
            <td>
              {currencyCodeList[myUserData?.userWallet?.currencyCode]}&nbsp;
              {myUserData?.userWallet?.amount}
            </td>
          </tr>
          <tr key={`bonusBalance-${myUserData?.userWallet?.nonCashAmount}`}>
            <td>Saldo de bônus</td>
            <td>
              {currencyCodeList[myUserData?.userWallet?.currencyCode]}&nbsp;
              {myUserData?.userWallet?.nonCashAmount}
            </td>
          </tr>
          <tr
            key={`pendingWithdrawals-${myUserData?.userWallet?.pendingWithdrawals}`}
          >
            <td>Retiradas pendentes</td>
            <td>
              {currencyCodeList[myUserData?.userWallet?.currencyCode]}&nbsp;
              {myUserData?.userWallet?.pendingWithdrawals}
            </td>
          </tr>
          <tr
            key={`pendingDeposits-${myUserData?.userWallet?.pendingDeposits}`}
          >
            <td>Depósitos Pendentes</td>
            <td>
              {currencyCodeList[myUserData?.userWallet?.currencyCode]}&nbsp;
              {myUserData?.userWallet?.pendingDeposits}
            </td>
          </tr>
          <tr key={`netLoss-${myUserData?.userWallet?.netLoss}`}>
            <td>Prejuízo líquido</td>
            <td>
              {currencyCodeList[myUserData?.userWallet?.currencyCode]}&nbsp;
              {myUserData?.userWallet?.netLoss}
            </td>
          </tr>
          <tr
            key={`userDeposits-${myUserData?.userWallet?.userDeposits || 0}`}
          >
            <td>Depósitos de usuários</td>
            <td>
              {currencyCodeList[myUserData?.userWallet?.currencyCode]}&nbsp;
              {myUserData?.userWallet?.userDeposits || 0}
            </td>
          </tr>
          <tr key={`totalDeposits-${myUserData?.userWallet?.totalDeposits || 0}`}>
            <td>Adicionar dinheiro</td>
            <td>
              {currencyCodeList[myUserData?.userWallet?.currencyCode]}&nbsp;
              {myUserData?.userWallet?.addMoney || 0}
            </td>
          </tr>
          <tr
            key={`totalWithdrawals-${myUserData?.userWallet?.totalWithdrawals}`}
          >
            <td>Retiradas totais</td>
            <td>
              {currencyCodeList[myUserData?.userWallet?.currencyCode]}&nbsp;
              {myUserData?.userWallet?.totalWithdrawals}
            </td>
          </tr>
          <tr key={`totalBonuses-${myUserData?.userWallet?.totalBonuses}`}>
            <td>Bônus totais</td>
            <td>
              {currencyCodeList[myUserData?.userWallet?.currencyCode]}&nbsp;
              {myUserData?.userWallet?.totalBonus}
            </td>
          </tr>
          <tr key={`totalBonuses-${myUserData?.userWallet?.depositToBonus || 0}`}>
            <td>Relação entre depósito e bônus</td>
            <td>
              {myUserData?.userWallet?.depositToBonus || 0}%
            </td>
          </tr>
          <tr key={`averageDeposit-${myUserData?.userWallet?.averageDeposit || 0}`}>
            <td>Depósito Médio</td>
            <td>
              {currencyCodeList[myUserData?.userWallet?.currencyCode]}&nbsp;
              {myUserData?.userWallet?.averageDeposit || 0}
            </td>
          </tr>
          <tr
            key={`averageWithdrawal-${myUserData?.userWallet?.averageWithdrawal || 0}`}
          >
            <td>Retirada Média</td>
            <td>
              {currencyCodeList[myUserData?.userWallet?.currencyCode]}&nbsp;
              {myUserData?.userWallet?.averageWithdrawal || 0}
            </td>
          </tr>
          <tr key={`averageBonus-${myUserData?.userWallet?.averageBonus || 0}`}>
            <td>Bônus Médio</td>
            <td>
              {currencyCodeList[myUserData?.userWallet?.currencyCode]}&nbsp;
              {myUserData?.userWallet?.averageBonus || 0}
            </td>
          </tr>
          <tr key={`createdAt-${myUserData?.userWallet?.createdAt}`}>
            <td>Inscrito desde </td>
            <td>
              {myUserData?.userWallet?.createdAt &&
                formatDate(myUserData?.userWallet?.createdAt)}
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  )
}

const UserWallet = ({ myUserData }) => {
  const dispatch = useDispatch()
  const { cashBackData, cashBackLoading } = useSelector(state => state.tenantUsers)

  return (
    <Row>
      <Col xs={12}>
        <Card className='card-overview'>
          <h4 className='h4-overview text-center mt-1'>Carteira do Jogador<hr className='h4-hr mt-1' /></h4>
          <div className='div-overview'>
            <UserWalletTable myUserData={myUserData} />
          </div>
        </Card>
      </Col>
      {/* <Col>
        <Card className='card-overview'>
          <h4 className='h4-overview text-center'>Cashback &nbsp;
            <Trigger message='Fetch Cashback Data' placement='top'>
              <Button
                variant='success'
                size='sm'
                onClick={() => {
                  dispatch(getCashbackDataStart({
                    userId: myUserData?.userId,
                    level: (myUserData?.level || 1),
                    tenantId: myUserData?.tenantId,
                    currencyCode: myUserData?.currencyCode,
                    isTenant: false
                  }))
                }}
              >
                <FontAwesomeIcon icon={faRedo} />
              </Button>
            </Trigger>
            <hr className='h4-hr' />
          </h4>

          <div className='div-overview'>

            {cashBackLoading
              ? <InlineLoader />
              : (
                <>
                  <div className='d-flex justify-content-between py-1'>
                    <h6 className='px-2'>Daily Cashback</h6>
                    <div>
                      <span>
                        {currencyCodeList[myUserData?.userWallet?.currencyCode]}&nbsp;
                        {cashBackData?.dailyCashback || '0'}
                      </span>
                    </div>
                  </div>

                  <div className='d-flex justify-content-between py-1'>
                    <h6 className='px-2'>Weekly Cashback</h6>
                    <div>
                      <span>
                        {currencyCodeList[myUserData?.userWallet?.currencyCode]}&nbsp;
                        {cashBackData?.weeklyCashback || '0'}
                      </span>
                    </div>
                  </div>

                  <div className='d-flex justify-content-between py-1'>
                    <h6 className='px-2'>Monthly Cashback</h6>
                    <div>
                      <span>
                        {currencyCodeList[myUserData?.userWallet?.currencyCode]}&nbsp;
                        {cashBackData?.monthlyCashback || '0'}
                      </span>
                    </div>
                  </div>
                </>
                )}

          </div>

        </Card>
      </Col> */}
    </Row>
  )
}

export default UserWallet
