import {
  Form as BForm,
  Button,
  Col,
  Modal,
  Row,
  Spinner
} from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import { useSelector } from 'react-redux'
import useCreateCasinoGame from '../hooks/useCreateCasinoGame'
import { casinoSchema, editCasinoSchema } from '../schema'

const CreateCasinoGames = ({
  handleClose,
  data,
  selectedCasinoProviderId,
  limit,
  pageNo,
  updateProvider,
  createProvider
}) => {
  const {
    casinoProvidersData, loading, updateCasinoGame, createCasinoGame
  } = useCreateCasinoGame()
  const { gameSubCategory } = useSelector((state) => state.superAdminCasino)
  return (
    <div>
      <Formik
        initialValues={
          data?.casinoGameId
            ? {
              casinoGameId: data.casinoGameId,
              orderId: data.orderId,
              name: data?.name,
              gameSubCategoryId: data?.gameSubCategoryId,
              casinoProviderId: data?.casinoProviderId,
              thumbnail: null,
              isActive: data?.isActive
            }
            : {
              name: '',
              gameSubCategoryId: '',
              casinoProviderId: null,
              thumbnail: null,
              isActive: false
            }
        }
        validationSchema={data?.casinoGameId ? editCasinoSchema : casinoSchema}
        onSubmit={(formValues) => {
          data?.casinoGameId
            ? updateProvider(
              formValues,
              { casinoGameId: data?.casinoGameId }
            )

            : createProvider(
              formValues,
              { casinoGameId: data?.casinoGameId }
            )
          handleClose()
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue
        }) => (
          <Form>
            <Modal.Body>
              <Row>
                <Col>
                  <BForm.Label>Game Name</BForm.Label>

                  <BForm.Control
                    type='text'
                    name='name'
                    placeholder='Enter Game Name'
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    component='div'
                    name='name'
                    className='text-danger'
                  />
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col>
                  <BForm.Label>Casino Sub-Category </BForm.Label>
                  <BForm.Select
                    name='gameSubCategoryId'
                    value={values.gameSubCategoryId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option selected disabled>
                      Select Sub-Category
                    </option>
                    {gameSubCategory &&
                      gameSubCategory?.rows?.map((data, index) => {
                        return (
                          <option key={index} value={data.gameSubCategoryId}>
                            {data.jname.EN}
                          </option>
                        )
                      })}
                  </BForm.Select>
                  <ErrorMessage
                    component='div'
                    name='gameSubCategoryId'
                    className='text-danger'
                  />
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col>
                  <BForm.Label>Casino Provider </BForm.Label>
                  <BForm.Select
                    name='casinoProviderId'
                    value={values.casinoProviderId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={values.casinoProviderId}
                  >
                    <option selected disabled>
                      Select Provider
                    </option>
                    {casinoProvidersData &&
                      casinoProvidersData?.rows?.map((provider, index) => {
                        return (
                          <option key={index} value={provider.casinoProviderId}>
                            {provider.name}
                          </option>
                        )
                      })}
                  </BForm.Select>
                  <ErrorMessage
                    component='div'
                    name='casinoProviderId'
                    className='text-danger'
                  />
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col>
                  <BForm.Label>Order ID</BForm.Label>
                  <BForm.Control
                    type='number'
                    name='orderId'
                    placeholder='Enter Game Order ID'
                    value={values.orderId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    component='div'
                    name='orderId'
                    className='text-danger'
                  />
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col className='d-grid'>
                  <BForm.Label>Thumbnail</BForm.Label>
                  <BForm.Text className='FileWidth'>
                    <input
                      id='file'
                      name='thumbnail'
                      type='file'
                      onChange={(event) => {
                        setFieldValue('thumbnail', event.currentTarget.files[0])
                      }}
                    />
                    {values?.thumbnail && (
                      <img
                        alt='not fount'
                        width='80px'
                        src={URL.createObjectURL(values.thumbnail)}
                      />
                    )}
                    {!values?.thumbnail && data?.thumbnailUrl && (
                      <img
                        alt='not fount'
                        width='80px'
                        src={data.thumbnailUrl}
                      />
                    )}
                  </BForm.Text>

                  <ErrorMessage
                    component='div'
                    name='thumbnail'
                    className='text-danger'
                  />
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col sm='2'>
                  <BForm.Label>Status</BForm.Label>
                </Col>
                <Col sm='3'>
                  <BForm.Check
                    className='mx-auto'
                    type='checkbox'
                    name='isActive'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.isActive}
                    defaultChecked={values.isActive}
                  />
                </Col>
                {/* <Col className='d-flex'>
                  <BForm.Label>Operator Status</BForm.Label>

                  <BForm.Check
                    className='mx-auto'
                    type='checkbox'
                    name='operatorStatus'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.operatorStatus}
                    defaultChecked={values.operatorStatus}
                  />
                </Col> */}
              </Row>
            </Modal.Body>

            <div className='mt-4'>
              <Modal.Footer className='d-flex justify-content-between align-items-center'>
                <Button className='btn-danger' onClick={() => handleClose()}>
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  className='ml-2'
                >
                  Submit
                  {loading && (
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  )}
                </Button>
              </Modal.Footer>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CreateCasinoGames
