import { ENDPOINTS } from "./endpoints"
import { transformGameCategoryData, transformGetAllCasinoGames } from "./transforms"
import { transformGameSubCategoryData } from "./transforms/getGameSubCategory"
import { transformProductReports } from "./transforms/getProductsReport"

const API_TRANSFORM_MAPPING = {
  [ENDPOINTS.getGameCategories]: transformGameCategoryData,
  [ENDPOINTS.getGameSubCategories]: transformGameSubCategoryData,
  [ENDPOINTS.getProductsReport]:transformProductReports,
  [ENDPOINTS.getAllCasinoGames]:transformGetAllCasinoGames

}

const removeParamsFromUrl = (inputUrl) => {
  const parsedUrl = new URL(inputUrl)
  const newUrl = `${parsedUrl.protocol}//${parsedUrl.host}${parsedUrl.pathname}`
  return newUrl
}

export const transformInterceptor = (api, responseData) => {
  try {
    const tApi = removeParamsFromUrl(api)
    if (API_TRANSFORM_MAPPING[tApi]) {
      return API_TRANSFORM_MAPPING[tApi](responseData)
    }
    return responseData
  } catch (error) {
    console.log(error)
    return null
  }
}

export function camelCaseToNormal(camelCaseString) {
  // Use regex to find all word segments in camelCase string
  // and replace with space-separated words
  return camelCaseString.replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    .split(' ') // Split by space
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
    .join(' '); // Join words with space
}