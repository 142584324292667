import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    getBetSettingsStart,
    getBetSettingsSuccess,
    getBetSettingsFailure,
    updateBetSettingsStart,
    updateBetSettingsSuccess,
    updateBetSettingsFailure,
    addBetSettingsStart,
    addBetSettingsSuccess,
    addBetSettingsFailure
  },
  reducer
} = createSlice({
  name: 'betsettings',
  initialState: {
    isGetBetSettingLoading: false,
    betSettingsList: {},
    isUpdateBetSettingLoading: false,
    isAddBetSettingLoading: false,
  },
  reducers: {
    getBetSettingsStart: (state) => ({
      ...state,
      isGetBetSettingLoading: true
    }),
    getBetSettingsSuccess: (state, { payload }) => ({
      ...state,
      isGetBetSettingLoading: false,
      betSettingsList: payload
    }),
    getBetSettingsFailure: (state, { payload }) => ({
      ...state,
      isGetBetSettingLoading: false
    }),
    updateBetSettingsStart: (state) => ({
        ...state,
        isUpdateBetSettingLoading: true
    }),
    updateBetSettingsSuccess: (state, { payload }) => ({
        ...state,
        isUpdateBetSettingLoading: false,
    }),
    updateBetSettingsFailure: (state, { payload }) => ({
        ...state,
        isUpdateBetSettingLoading: false
    }),
    addBetSettingsStart: (state) => ({
        ...state,
        isAddBetSettingLoading: true
    }),
    addBetSettingsSuccess: (state, { payload }) => ({
        ...state,
        isAddBetSettingLoading: false,
    }),
    addBetSettingsFailure: (state, { payload }) => ({
        ...state,
        isAddBetSettingLoading: false
    })
  }
})

export default reducer
export {
    getBetSettingsStart,
    getBetSettingsSuccess,
    getBetSettingsFailure,
    updateBetSettingsStart,
    updateBetSettingsSuccess,
    updateBetSettingsFailure,
    addBetSettingsStart,
    addBetSettingsSuccess,
    addBetSettingsFailure
}
