import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation, useSearchParams } from "react-router-dom";
import { Row, Col } from "@themesberg/react-bootstrap";

import { affliateInitialFormData, updateAffiliateSchema } from "../schemas";
import AffiliateForm from "./AffiliateForm";
import { toast } from "../../../../components/Toast";
import { getAffiliateByIdStart } from "../../../../store/redux-slices/fetchData";
import { updateAffiliate } from "../../../../utils/apiCalls";
import { AffiliateRoutes } from "../../../../routes";
import useRole from "../../../../hooks/useRole";
import { setSuperAdminAffiliateData } from "../../../../store/redux-slices/admins";
import { affiliateAdminDetailsSuccess } from "../../../../store/redux-slices/affiliates";
import { resolveErrors } from "../../../../utils/errorTypes";

const UpdateAffiliate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { Affiliate } = useRole();
  const { affiliateId } = useParams();
  const { affiliateByIdData } = useSelector((state) => state.fetch);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (affiliateId) {
      dispatch(getAffiliateByIdStart({ affiliateId }));
    }
  }, [affiliateId]);

  return (
    <>
      <div className="section-header-wrap mt-n3 mx-n3">
        <Row>
          <Col lg={8} xs={12}>
            <div className="d-flex align-items-center">
              <h4 className="title-text h4">{Boolean(affiliateByIdData?.parentIdAffiliate) ? "Sub-" : ""}Afiliado:&nbsp;</h4>
              <h2 className="mb-0">
                {affiliateByIdData && affiliateByIdData?.firstName}{" "}
                {affiliateByIdData && affiliateByIdData?.lastName}
              </h2>
            </div>
          </Col>
        </Row>
        {/* </div> */}
      </div>
      <div className="FormsBg mt-4">
        {affiliateByIdData ? <Formik
          initialValues={affliateInitialFormData(affiliateByIdData)}
          validationSchema={updateAffiliateSchema}
          enableReinitialize={true}
          onSubmit={async (formValues) => {
            const { gender, ...rest } = formValues;
            setLoading(true)
            try {
              const res = await updateAffiliate({ ...rest, affiliateId: parseInt(affiliateId) });
              toast("Informações atualizadas com sucesso!", "success");
              // if (Admin || SuperAdmin) navigate(AdminsRoutes.AffiliatesManagement);
              if (Affiliate) {
                dispatch(affiliateAdminDetailsSuccess({ affiliate: res.data.data?.updateAffiliate ?? {} }));
                dispatch(setSuperAdminAffiliateData(res.data.data?.updateAffiliate ?? {}));
                navigate(AffiliateRoutes.Profile);
              };
            } catch (err) {
              toast(resolveErrors(err?.response?.data?.errors, "Erro ao salvar dados do afiliado"), "error");
            } finally {
              setLoading(false);
            }
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            setErrors
          }) => (
            <AffiliateForm
              errors={errors}
              values={values}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              setErrors={setErrors}
              loading={loading}
              isEdit={true}
            />
          )}
        </Formik> : null}
      </div>
    </>
  );
};

export default UpdateAffiliate;
