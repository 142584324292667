import {
  Button,
  Row,
  Col,
  Table,
  ButtonGroup
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion } from 'framer-motion/dist/framer-motion'
import React from 'react'
import PaginationComponent from '../../../components/Pagination'
import CreateCasinoProviders from './components/CreateCasinoProvider'
import ConfirmationModal from '../../../components/ConfirmationModal'
import {
  faCheckSquare,
  faWindowClose,
  faEdit,
  faBan
} from '@fortawesome/free-solid-svg-icons'
import useProviderListing from './useProviderListing'
import Trigger from '../../../components/OverlayTrigger'
import useCheckPermission from '../../../utils/checkPermission'
import { Form } from 'react-bootstrap'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import ActionMenu from './ActionMenu'

export default () => {
  const {
    limit,
    setLimit,
    page,
    setPage,
    show,
    statusShow,
    setStatusShow,
    data,
    type,
    casinoProvidersData,
    totalPages,
    handleClose,
    handleShow,
    handleStatusShow,
    handleYes,
    loading,
    status,
    aggregators,
    createProvider,
    updateProvider,
    navigate,
    name,
    search,
    setSearch
  } = useProviderListing()
  const { isHidden } = useCheckPermission()
  return (
    <>
    <div className='section-header-wrap mt-n3 mx-n3'>
      <Row>
      <Col lg={8} xs={7}>
          <h2 className='title-text'>Casino Providers</h2>
        </Col>

        <Col lg={4} xs={5}>
          <div className='d-flex justify-content-end'>
          <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
            <Button
              variant='btn btn-primary w-100'
              style={{ maxWidth: '140px' }}
              onClick={() => handleShow('Create', null)}
              hidden={isHidden({ module: { key: 'CasinoManagement', value: 'C' } })}
            >
              <AddCircleOutlineRoundedIcon className='me-2' />
                  <span>Criar</span>
            </Button>
            </motion.div>
          </div>
        </Col>
      </Row>
      </div>
      <div className='FormsBg'>
        <Row>
          <Col xs='3'>
          <Form.Control
              type='search'
              name='search'
              placeholder='Pesquisar Provider'
              size='sm'
              className='me-2'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Col>
        </Row>
      
             </div>
      <div className='TableBg mt-4 pt-1'>
      
      <Table  striped responsive hover size='sm' className='text-center mt-3'>
        <thead className='thead-light'>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Thumbnail</th>
            <th>Status</th>
            {/* <th>Demo Mode</th> */}
            <th>Actions</th>
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && Boolean(casinoProvidersData) &&
                      casinoProvidersData?.rows?.map(
                        (
                          {
                            casinoProviderId,
                            name,
                            isActive,
                            thumbnailUrl,
                            demo
                          },
                          index
                        ) => {
                          return (
                            <tr key={casinoProviderId}>
                              <td>{casinoProviderId}</td>
                              <td>
                                <Trigger message={name}>
                                  <span
                                    style={{
                                      width: '100px',
                                      cursor: 'pointer'
                                    }}
                                    className='d-inline-block text-truncate'
                                  >
                                    {name}
                                  </span>
                                </Trigger>
                              </td>

                              <td>
                                {thumbnailUrl
                                  ? (
                                    <span
                                      onClick={() => window.open(thumbnailUrl)}
                                      className='text-link'
                                      style={{ cursor: 'pointer' }}
                                    >
                                      View Here
                                    </span>
                                    )
                                  : (
                                    <span className='text-danger'>
                                      No Image Available
                                    </span>
                                    )}
                              </td>

                              <td>
                                {isActive
                                  ? (
                                    <span className='text-success'>Active</span>
                                    )
                                  : (
                                    <span className='text-danger'>In Active</span>
                                    )}
                              </td>

                              {/* <td>{demo ? 'Yes' : 'No'}</td> */}

                              <td>
								<ActionMenu
									navigate={navigate}
									isHidden={isHidden}
									handleShow={handleShow}
									isActive={isActive}
									casinoProvidersData={casinoProvidersData}
									casinoProviderId={casinoProviderId}
									name={name}
									index={index}
									handleStatusShow={handleStatusShow}
								/>
                                {/* {(!isHidden({ module: { key: 'CasinoManagement', value: 'U' } }) || !isHidden({ module: { key: 'CasinoManagement', value: 'T' } }))
                                  ? (
                                    <ButtonGroup>
                                      <Trigger message='Edit'>

                                        <Button
                                          className='m-1'
                                          size='sm'
                                          variant='warning'
                                          onClick={() =>
                                            handleShow(
                                              'Edit',
                                              casinoProvidersData.rows[index]
                                            )}
                                          hidden={isHidden({ module: { key: 'CasinoManagement', value: 'U' } })}
                                        >
                                          <FontAwesomeIcon icon={faEdit} />
                                        </Button>
                                      </Trigger>

                                      {!isActive
                                        ? (
                                          <Trigger message='Set Status Active'>
                                            <Button
                                              className='m-1'
                                              size='sm'
                                              variant='success'
                                              onClick={() =>
                                                handleStatusShow(
                                                  casinoProviderId,
                                                  isActive,
                                                  name
                                                )}
                                              hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                                            >
                                              <FontAwesomeIcon icon={faCheckSquare} />
                                            </Button>
                                          </Trigger>
                                          )
                                        : (
                                          <Trigger message='Set Status In-Active'>
                                            <Button
                                              className='m-1'
                                              size='sm'
                                              variant='danger'
                                              onClick={() =>
                                                handleStatusShow(
                                                  casinoProviderId,
                                                  isActive,
                                                  name
                                                )}
                                              hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                                            >
                                              <FontAwesomeIcon icon={faWindowClose} />
                                            </Button>
                                          </Trigger>
                                          )}

                                      <Trigger message='View Blocked Countries'>
                                        <Button
                                          className='m-1'
                                          size='sm'
                                          variant='secondary'
                                          hidden={isHidden({ module: { key: 'CasinoManagement', value: 'U' } })}
                                          onClick={() => navigate(`/admin/casino-providers/restrict-countries/${casinoProviderId}`)}
                                        >
                                          <FontAwesomeIcon icon={faBan} />
                                        </Button>
                                      </Trigger>
                                    </ButtonGroup>)
                                  : 'NA'} */}
                              </td>
                            </tr>
                          )
                        }
                      )}

          {casinoProvidersData?.count === 0 && !loading &&
                        (
                          <tr>
                            <td
                              colSpan={5}
                              className='text-danger text-center'
                            >
                              No data found
                            </td>
                          </tr>
                        )}
        </tbody>
      </Table>
      {casinoProvidersData?.count !== 0 && !loading &&
            (
              <PaginationComponent
                page={casinoProvidersData?.count < page ? setPage(1) : page}
                totalPages={totalPages}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
              />
            )}
      </div>
   

      <ConfirmationModal
        setShow={setStatusShow}
        show={statusShow}
        handleYes={handleYes}
        active={status}
        name={name}
      />
      <CreateCasinoProviders
        handleClose={handleClose}
        data={data}
        type={type}
        show={show}
        aggregators={aggregators}
        loading={loading}
        createProvider={createProvider}
        updateProvider={updateProvider}
      />
    </>
  )
}
