import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getElasticHealthStart } from '../../../../store/redux-slices/dashboard'

const useDashboard = () => {
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedPortal, setSelectedPortal] = useState('')
  const { adminPermissions, loading } = useSelector(state => state.admins)
  const { elasticHealth } = useSelector(state => state.dashboard)
  const reportsToShow = ['GameReport', 'KpiReport', 'DemographReport', 'PlayerManagementReport', 'KpiSummaryReport', 'LivePlayerReport', 'PlayerLiabilityReport']
  const permissionKeys = adminPermissions && Object.keys(adminPermissions)
  const dispatch = useDispatch()
  const nonElasticReports = ['DemographReport', 'LivePlayerReport', 'PlayerManagementReport']

  useEffect(() => {
    dispatch(getElasticHealthStart({ isTenant: false }))
  }, [])

  return {
    selectedClient,
    setSelectedClient,
    selectedPortal,
    setSelectedPortal,
    reportsToShow,
    permissionKeys,
    loading,
    adminPermissions,
    elasticHealth,
    nonElasticReports
  }
}

export default useDashboard
