import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAffiliatePlayersReportStart } from '../../../../store/redux-slices/affiliates'
import { SORT_ORDER_HEADINGS } from '../playersReportConstant'

export const useProductsReport = () => {
  const [orderBy, setOrderBy] = useState('userId')
  const [sort, setSort] = useState('DESC')
  const [over, setOver] = useState(false)
  const [state, setState] = useState([
    {
      startDate: new Date('1923'),
      endDate: new Date(),
      key: 'selection'
    }
  ])
  const [page, setPage] = useState(1)
  const limit = 15

  const selected = (h) =>
    orderBy === h.value &&
    (SORT_ORDER_HEADINGS.includes(h.value))
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const affiliate = useSelector((state) => state.affiliate)

  useEffect(() => {
    const payload = { pageNo: page, limit }
    dispatch(getAffiliatePlayersReportStart(payload))
  }, [page])

  return {
    state,
    setState,
    orderBy,
    setOrderBy,
    selected,
    sort,
    setSort,
    over,
    setOver,
    loading: false,
    navigate,
    playersReport: {
      rows: affiliate?.affiliatePlayersReport?.playerDetails?.rows,
      count: affiliate?.affiliatePlayersReport?.count?.length
    },
    page,
    setPage,
    totalPages: Math.ceil(affiliate?.affiliatePlayersReport?.count?.length / limit)
  }
}
