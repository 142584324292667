import { Form, Table } from '@themesberg/react-bootstrap'
import React from 'react'
import { formatDate } from '../../../../utils/dateFormatter'
import { InlineLoader } from '../../../../components/Preloader'
import { NoDataContainer } from '../../../../components/ProfilePage/style'

const BonusDetailModal = ({
  bonusAmount,
  bonusDetail,
  setBonusAmount,
  bonusLoading
}) => {
  return (
    <div style={{ maxHeight: '500px', overflow: 'auto' }}>
      	{
				bonusLoading ?
					<NoDataContainer>
					<InlineLoader />
					</NoDataContainer>
				:
        <>
        <Table striped className='text-start'>
        {bonusDetail &&
          <tbody>
            <tr>
              <td>Promotion Title</td>
              <td>{bonusDetail?.promotionTitle?.EN}</td>
            </tr>
            <tr>
              <td>Bonus Type</td>
              <td>{bonusDetail?.bonusType}</td>
            </tr>
            {['match', 'deposit', 'wagering'].includes(bonusDetail?.bonusType) &&
              <tr>
                <td>Bonus Percentage</td>
                <td>{bonusDetail?.depositBonusPercent}%</td>
              </tr>}
            {['match', 'deposit', 'wagering'].includes(bonusDetail?.bonusType) &&
              <tr>
                <td>Sticky</td>
                <td>{bonusDetail?.isSticky ? 'Yes' : 'No'}</td>
              </tr>}
            {['match', 'deposit', 'wagering'].includes(bonusDetail?.bonusType) &&
              <tr>
                <td>Bonus Bet Only</td>
                <td>{bonusDetail?.bonusBetOnly ? 'Yes' : 'No'}</td>
              </tr>}
            {bonusDetail?.bonusType === 'freespins' &&
              <tr>
                <td>Quantity</td>
                <td>{bonusDetail?.quantity}</td>
              </tr>}
            {bonusDetail?.validFrom &&
              <tr>
                <td>Valid From</td>
                <td>{formatDate(bonusDetail?.validFrom)}</td>
              </tr>}
            {bonusDetail?.validTo &&
              <tr>
                <td>Valid To</td>
                <td>{formatDate(bonusDetail?.validTo)}</td>
              </tr>}
            <tr>
              <td>Days To Clear</td>
              <td>{bonusDetail?.daysToClear}</td>
            </tr>
            {(bonusDetail?.wageringMultiplier !== 0) &&
              <tr>
                <td>Wagering Type</td>
                <td>{bonusDetail?.wageringRequirementType}</td>
              </tr>}
            {(bonusDetail?.wageringMultiplier !== 0) &&
              <tr>
                <td>Wagering Multiplier</td>
                <td>{bonusDetail?.wageringMultiplier}</td>
              </tr>}
          </tbody>}
      </Table>
      {bonusDetail && ['deposit', 'wagering'].includes(bonusDetail?.bonusType) &&
        <div className='d-flex justify-content-start align-items-center'>
          <Form.Label className='mb-0' style={{ marginRight: '10px' }}>Amount <small>(optional)</small></Form.Label>
          <Form.Control
            type='number'
            min={0}
            name='amount'
            className='w-50'
            onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
            placeholder='Enter Amount'
            value={bonusAmount || ''}
            onChange={(e) => setBonusAmount(e?.target?.value)}
          />
        </div>}
        </>
}
      
    </div>
  )
}

export default BonusDetailModal
