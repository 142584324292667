import { toast } from "../../components/Toast";
import { AdminsRoutes } from "../../routes";
import { createSocialLink, deleteSocialLink, getSocialLinks, updateSocialLink } from "../../utils/apiCalls"
import { createSocialLinkFailure, createSocialLinkStart, createSocialLinkSuccess, deleteSocialLinkFailure, deleteSocialLinkStart, deleteSocialLinkSuccess, editSocialLinkFailure, editSocialLinkStart, editSocialLinkSuccess, getAllSocialLinksFailure, getAllSocialLinksStart, getAllSocialLinksSuccess } from "../redux-slices/socialLinks"
import { takeLatest, put } from 'redux-saga/effects';

export default function* socialLinksWatcher() {
    yield takeLatest(getAllSocialLinksStart.type, getSocialLinkWorker)
    yield takeLatest(editSocialLinkStart.type,editSocialLinkWorker)
    yield takeLatest(createSocialLinkStart.type, createSocialLinkWorker)
    yield takeLatest(deleteSocialLinkStart.type, deleteSocialLinkWorker)
}

function* getSocialLinkWorker() {
    try {
        const { data } = yield getSocialLinks()

        yield put(getAllSocialLinksSuccess(data?.data?.socialLinks))
    } catch (e) {
        yield toast(e?.response?.data?.errors[0]?.description, 'error')

        yield put(getAllSocialLinksFailure(e?.response?.data?.errors[0]?.description))
    }
}
function* editSocialLinkWorker({payload}) {
    try {
        const {navigate,...rest} = payload
        const { data } = yield updateSocialLink(rest)
        yield toast('Link Edit Successfully.', 'success')
        yield put(editSocialLinkSuccess())
        navigate(AdminsRoutes.SocialLinks)
    } catch (e) {
        yield toast(e?.response?.data?.errors[0]?.description, 'error')

        yield put(editSocialLinkFailure(e?.response?.data?.errors[0]?.description))
    }
}   

function* createSocialLinkWorker({payload}) {
    try {
        const {navigate,...rest} = payload
        const { data } = yield createSocialLink(rest)
        yield toast('Link Created Successfully.', 'success')
        navigate(AdminsRoutes.SocialLinks)
        yield put(createSocialLinkSuccess())
    } catch (e) {
        yield toast(e?.response?.data?.errors[0]?.description, 'error')

        yield put(createSocialLinkFailure(e?.response?.data?.errors[0]?.description))
    }
}

function* deleteSocialLinkWorker({payload}) {
    try {
        const {id} = payload
        const { data } = yield deleteSocialLink({iconId:id})
        yield toast('Link Removed Successfully.', 'success')
        yield put(deleteSocialLinkSuccess())
        yield put(getAllSocialLinksStart())
    } catch (e) {
        yield toast(e?.response?.data?.errors[0]?.description, 'error')

        yield put(deleteSocialLinkFailure(e?.response?.data?.errors[0]?.description))
    }
}  

