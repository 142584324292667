export const tableHeaders = [
  { label: 'Usuário', value: 'transactionUser.username' },
  { label: 'ID da Transação', value: 'transactionBankingUUID' },
  { label: 'Tipo de ação', value: 'actioneeType' },
  // { label: 'Payment Provider', value: 'paymentProvider' },
  { label: 'Valor', value: 'amount' },
  { label: 'Tipo de transação', value: 'transactionType' },
  { label: 'Status', value: 'status' },
  { label: 'Saldo antes da transação', value: 'sourceBeforeBalance' },
  { label: 'Saldo depois da transação', value: 'sourceAfterBalance' },
  { label: 'Primeiro depósito?', value: 'isFirstDeposit' },
  { label: 'Ação', value: 'actioneeEmail' },
  { label: 'Criado em:', value: 'createdAt' },
  { label: 'Atualizado em:', value: 'updatedAt' },
]

export const transactionType = [
  { label: 'Depósito', value: 'deposit' },
  { label: 'Saque', value: 'withdraw' },
  { label: 'Crédito', value: 'credit' },
  { label: 'Adição de saldo', value: 'addMoney' },
  { label: 'Remoção de Saldo', value: 'removeMoney' },
  { label: 'Internal Deposit', value: 'depositInternal' },
  { label: 'Internal Add Money', value: 'addMoneyInternal' },
  { label: 'Internal Withdraw', value: 'withdrawInternal' },
  { label: 'Internal Remove Money', value: 'removeMoneyInternal' },
  { label: 'Internal Remove Money', value: 'removeMoneyInternal' },
  { label: 'Deposit Bonus Claim', value: 'deposit_bonus_claim' },
  { label: 'Referral Bonus Claim', value: 'referral_bonus_claim' },
  { label: 'Joining Bonus Claim', value: 'joining_bonus_claim' },
  { label: 'Bônus de cadastro', value: 'joining_bonus' },
  { label: 'Bônus de referência', value: 'referral_bonus' },
  { label: 'Depósito de bônus', value: 'deposit_bonus' }
]

export const statusType = [
  { label: 'Todos', value: '' },
  { label: 'Pendente', value: 0 },
  { label: 'Completo', value: 1 },
  { label: 'Falhou', value: 2 },
  { label: 'Requisitado', value: 7 }
]

export const walletType = ['Cash', 'Bonus']
