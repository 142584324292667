import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLivePlayersReportStart } from '../../../../store/redux-slices/dashboard'
import { getLoginToken } from '../../../../utils/storageUtils'
import { formatDateYMD, getDateDaysAgo } from '../../../../utils/dateFormatter'
import FormatDate from '../../../../utils/formatDate'
import useDebounce from '../../../../hooks/useDebounce'

const initialRangeDate = {
  startDate: new Date(), //getDateDaysAgo(1),
  endDate: new Date(),
  key: 'selection'
}

const useLivePlayers = ({ selectedPortal, selectedClient }) => {
  const IntervalRef = useRef();

  const dispatch = useDispatch()
  const { livePlayerloading: loading, livePlayerData } = useSelector((state) => state.dashboard)
  const [count, setCount] = useState(0)
  const [dateRange, setDateRange] = useState([initialRangeDate])
  const { adminPermissions } = useSelector(state => state.admins)
  const permissionKeys = adminPermissions && Object.keys(adminPermissions)

  const fetchDetails = () => {
    if (getLoginToken()) {
      dispatch(getLivePlayersReportStart({
        //isTenant: false,
        //tenantId: selectedPortal,
        //adminId: selectedClient,
        startDate: formatDateYMD(dateRange.map(a => a.startDate)),
        endDate: formatDateYMD(dateRange.map(a => a.endDate)),
      }))
    }
  }

  const updateFetchDetails = useDebounce(fetchDetails, 800);

  const startLive = () => setInterval(fetchDetails, 15000);

  const toDay = () => {
    setDateRange([initialRangeDate]);
    // IntervalRef.current = startLive();
  }

  useEffect(() => {
    fetchDetails();
    // IntervalRef.current = startLive();
  }, [])

  useEffect(() => {
    updateFetchDetails();
    // if (FormatDate.differenceInDays(dateRange[0].startDate, dateRange[0].endDate) || FormatDate.differenceInDays(dateRange[0].startDate, new Date())) {
    //   clearInterval(IntervalRef.current);
    // } else if (!IntervalRef.current && FormatDate.differenceInDays(dateRange[0].startDate, new Date()) == 0) {
    //   IntervalRef.current = startLive();
    // }
  }, [dateRange])

  // useEffect(() => {
  //   if (permissionKeys.includes('LivePlayerReport')) {
  //     const intervals = setInterval(() => {
  //       clearInterval(intervals)
  //       fetchDetails()
  //       setCount(count + 1)
  //     }, 3000)
  //   }
  // }, [count])

  return {
    loading,
    livePlayerData,
    fetchDetails,
    dateRange,
    setDateRange,
    toDay
  }
}

export default useLivePlayers
