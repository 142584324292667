import React from 'react'
import {
  Button,
  Row,
  Col,
  Table,
  ButtonGroup,
  OverlayTrigger,
  Tooltip
} from '@themesberg/react-bootstrap'
import { motion } from 'framer-motion/dist/framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { AdminsRoutes } from '../../../routes'
import PaginationComponent from '../../../components/Pagination'
import useCurrenciesListing from './hooks/useCurrenciesListing'
import useCheckPermissions from '../../../utils/checkPermission'
import Preloader from '../../../components/Preloader'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import ActionMenu from './ActionMenu'

export default () => {
  const {
    navigate,
    limit,
    setLimit,
    page,
    setPage,
    allCurrencies,
    totalPages,
    loading
  } = useCurrenciesListing()

  const { isHidden } = useCheckPermissions()
  const hide = isHidden({ module: { key: 'Currencies', value: 'U' } })

  return (
    <>
      <>
      <div className='section-header-wrap mt-n3 mx-n3'>
      <Row>
          <Col lg={8} xs={7}>
            <h2 className='title-text'>Moedas</h2>
         </Col>

          <Col lg={4} xs={5}>
            {/* Button to create new admin */}
            <div className='d-flex justify-content-end'>
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Button
                  variant='btn btn-primary w-100'
                  onClick={() => navigate(AdminsRoutes.CreateCurrencies)}
                  hidden={isHidden({ module: { key: 'Currencies', value: 'C' } })}
                  style={{ maxWidth: '140px' }}
                >
                <AddCircleOutlineRoundedIcon className='me-2' />
                  <span>Criar</span>
                </Button>
              </motion.div>
            </div>
          </Col>

        </Row>
        </div>
        <div className='TableBg mt-4 pt-1'>
        
        <Table striped responsive hover size='sm' className='text-center mt-3'>
          <thead className='thead-light'>
            <tr>
              <th>Id</th>
              <th>Nome</th>
              <th>Code</th>
              <th>Taxa de câmbio</th>
              <th>Tipo</th>
              <th>Primário</th>
              {!hide && <th>Action</th>}
            </tr>
          </thead>

          <tbody>
            {loading
              ? <Preloader />
              : Boolean(allCurrencies) &&
                      allCurrencies?.rows?.map(
                        ({
                          name,
                          currencyId,
                          code,
                          exchangeRate,
                          type,
                          isPrimary,
                          loyaltyPoint
                        }) => {
                          return (
                            <tr key={currencyId}>
                              <td>{currencyId}</td>
                              <td>{name}</td>
                              <td>{code} </td>
                              <td>{exchangeRate}</td>
                              <td>{type ? 'Fiat' : 'Crypto'}</td>
                              <td>{isPrimary ? 'Yes' : 'No'}</td>
                              {!hide &&
                                <td>

                                   <ActionMenu
                                    navigate={navigate}
                                    currencyId={currencyId}
                                    isHidden={isHidden}
                                    hide={hide}
                                  />
                      
                                </td>}
                            </tr>
                          )
                        }
                      )}

            {allCurrencies?.count === 0 && !loading &&
                      (
                        <tr>
                          <td
                            colSpan={8}
                            className='text-danger text-center'
                          >
                            No data found
                          </td>
                        </tr>
                      )}
          </tbody>
        </Table>
			{allCurrencies?.count !== 0 && !loading &&
              (
                <PaginationComponent
                  page={allCurrencies?.count < page ? setPage(1) : page}
                  totalPages={totalPages}
                  setPage={setPage}
                  limit={limit}
                  setLimit={setLimit}
                />
              )}
        </div>
      </>
    </>
  )
}
