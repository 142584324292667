export const dataInfoHelper = (data) => {
    return ({
        maxBetAmount: data?.maxBetAmount || '',
        minBetAmount: data?.minBetAmount || '',
        maxBetCount: data?.maxBetCount || '',
        cashoutPercentage: data?.cashoutPercentage || '',
        minOddLimit: data?.minOddLimit || '',
        maxOddLimit: data?.maxOddLimit || '',
        maxEventCount: data?.maxEventCount || '',
        maxMarketOutcomeCount: data?.maxMarketOutcomeCount || '',
        maxWinAmount: data?.maxWinAmount || '',
        sportId: data?.sportId || ''
    })
}