import React, { useEffect, useState } from "react";

import {
  Table,
} from '@themesberg/react-bootstrap'

import PaginationComponent from '../../../components/Pagination'
import { NoDataContainer } from "./style";
import DivLoader from "../../../components/Loader/divLoader";
import { useDispatch, useSelector } from 'react-redux'
import { updateStatusStart, uploadImageReset } from '../../../store/redux-slices/sportsBook'
import Modal from "../../Modal/Modal";
import ImageUploader from "../ImageUploader";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faUpload } from "@fortawesome/free-solid-svg-icons";
// import { Dropdown } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import useCheckPermission from '../../../utils/checkPermission'
import ActionMenu from "./ActionMenu";
const SportsListTable = (props) => {
  const {
    sportsListInfo,
    pageNo,
    setPageNo,
    totalPages,
    limit,
    setLimit,
    isGetSportsLoading,
    searchByName
  } = props;

  const dispatch = useDispatch()
	const { isHidden } = useCheckPermission()
  const [show, setShow] = useState(false);
  const [sportId, setSportId] = useState(null);

  const { uploadImgSuccess } = useSelector((state) => state.sportsBook)

  useEffect(() => {
    if (uploadImgSuccess) {
      setShow(false)
      dispatch(uploadImageReset());
    }
  }, [uploadImgSuccess])

  const handleStatusChange = (sportId, status) => {
    dispatch(updateStatusStart({ sportId, code: 'SPORTS', status: !status, search: searchByName, limit, pageNo }))
  }

  return (
    <div className='TableBg mt-4 pt-1'>
      <Table striped responsive hover size='sm' className='text-center mt-3'>
        <thead className='thead-light'>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Status</th>
            <th>Icon</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            !isGetSportsLoading && sportsListInfo?.sportsList?.rows?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.sportId}</td>
                  <td>{item.sportName[0].name}</td>
                  <td className={item.isActive ? 'text-success' : 'text-danger'}>{item.isActive ? 'Active' : 'In-Active'}</td>
                  <td>-</td>
                  <td>
										<ActionMenu
											isHidden={isHidden}
											handleStatusChange={handleStatusChange}
											item={item}
											setShow={setShow}
											setSportId={setSportId}         
										/>
									</td>
                  {/* <td>
                    <Dropdown>
                      <ActionContainer>
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                          <span>&#8285;</span>
                        </Dropdown.Toggle>
                      </ActionContainer>

                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <span>
                          Status{"  "}
                            <Form.Check
                              name='status'
                              type='switch'
                              className="d-inline-block ms-4"
                              checked={item.isActive}
                              value={item.isActive}
                              onChange={() => handleStatusChange(item.sportId, item.isActive)}
                            />
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          Upload Icon <Button onClick={() => {
                            setShow(true)
                            setSportId(item.sportId)
                          }}>
                            <FontAwesomeIcon icon={faUpload} beat />
                          </Button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td> */}
                </tr>
              )
            })
          }
          {isGetSportsLoading &&
            <tr>
              <td colSpan="5">
                <NoDataContainer>
                  <DivLoader />
                </NoDataContainer>
              </td>
            </tr>
          }
        </tbody>
      </Table>
      {
        sportsListInfo?.sportsList?.count !== 0 && !isGetSportsLoading &&
        (
          <PaginationComponent
            page={sportsListInfo?.sportsList?.count < pageNo ? setPageNo(1) : pageNo}
            totalPages={totalPages}
            setPage={setPageNo}
            limit={limit}
            setLimit={setLimit}
          />
        )
      }
      <Modal
        show={show}
        content={
          <ImageUploader sportId={sportId} code={"SPORT"} />
        }
        onHide={() => setShow(false)}
      />
    </div>
  )
};

export default SportsListTable;