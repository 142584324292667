import { Button, Modal, Table } from "@themesberg/react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import useDebounce from "../../../../hooks/useDebounce";
import { getAffiliateStreamLogs } from "../../../../utils/apiCalls";
import FormatDate from "../../../../utils/formatDate";
import { Loading } from "../../../../components/Preloader";

export const affiliateTableHeaders = [
  { label: 'Id do Log', value: 'logId' },
  { label: 'Criado em:', value: 'createdAt' },
  { label: 'Atualizado em:', value: 'updatedAt' },
  { label: 'Ação', value: 'updatedAt' },
]

const StreamLogModalButton = ({ log, createdAt, updatedAt }) => {
  const [show, setShow] = useState(false);
  const tBodyRef = useRef(null);

  useEffect(() => {
    if (tBodyRef && tBodyRef.current) tBodyRef.current.scroll(0, 9e9)
  }, [])

  return (<>
    <Button size={"sm"} onClick={() => setShow(true)}>Visualizar</Button>
    <Modal {...{ show }} onHide={() => setShow(false)} size="lg" fullscreen dialogClassName="p-3 bg-transparent" contentClassName="rounded-3">
      <Modal.Header>
        <div className="d-flex flex-column">
          <span>Log: {FormatDate.default(createdAt)}</span>
        </div>
        <Button onClick={() => setShow(false)}>Fechar</Button>
      </Modal.Header>
      <Modal.Body as={Table} className="p-3" striped responsive hover size="sm" ref={tBodyRef}>
        <tbody>
          {Array.isArray(log) && log.length ? log.map((log, key) => {
            return (
              <tr {...{ key }}>
                <td style={{ width: "5rem" }}>{FormatDate.default(log.time)}</td>
                <td style={{ minheight: "50px", height: "100%", whiteSpace: "break-spaces" }}>{JSON.stringify(log.data)}</td>
              </tr>
            )
          }) : <tr><td>Nenhum registro de log!</td></tr>}
        </tbody>
      </Modal.Body>
    </Modal>
  </>)
}

const AffiliateStreamLogsTable = ({ affiliate }) => {
  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState([]);

  const getAffiliateLogs = useDebounce(async () => {
    setLoading(true);
    try {
      const res = await getAffiliateStreamLogs({ streamerId: affiliate?.streamerId });
      setLogs(res.data.data.data)
    } finally {
      setLoading(false);
    }
  }, 400)

  useEffect(() => {
    if (affiliate?.affiliateId) getAffiliateLogs()
  }, [affiliate])

  if (!affiliate) return;
  return (<>
    <Table striped responsive hover size="sm" className="text-center m-0">
      <thead className="thead-light">
        <tr>
          {affiliateTableHeaders.map((h, idx) => (
            <th
              key={idx}
              style={{
                cursor: "pointer",
              }}
            >
              {h.label}{" "}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {!loading &&
          Boolean(logs) &&
          logs?.map(
            ({ logId, log, createdAt, updatedAt }, key) => {
              return (
                <tr key={logId}>
                  <td>{logId}</td>
                  <td>{createdAt ? FormatDate.default(createdAt) : "N/A"}</td>
                  <td>{updatedAt ? FormatDate.default(updatedAt) : "N/A"}</td>
                  <td><StreamLogModalButton {...{ log, createdAt, updatedAt, key }} /></td>
                </tr>
              );
            }
          )}

        {logs.length === 0 && !loading && (
          <tr>
            <td colSpan={13} className="text-danger text-center">
              Nenhum registro de log encontrado!
            </td>
          </tr>
        )}
      </tbody>
    </Table>
    {loading ? <Loading className={"position-absolute bg-white top-0 w-100 h-100"} /> : null}
  </>)
}

export default AffiliateStreamLogsTable;