import { Col, Row, Table } from '@themesberg/react-bootstrap'
import React, { useEffect, useState } from 'react'

import { walletType, transactionType } from '../../../TransactionsBanking/constants'
import FormatNumber from '../../../../../utils/formatNumber'
import FormatDate from '../../../../../utils/formatDate'
import { getAffiliateWithdrawRequests } from '../../../../../utils/apiCalls'
import { Loading } from '../../../../../components/Preloader'
import { toast } from '../../../../../components/Toast'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { DashboardHeadTitle } from '../../style'
import useDebounce from '../../../../../hooks/useDebounce'
import { STATUS_LABEL, STATUS_VALUE } from '../../../../../utils/constant'
import PaginationComponent from '../../../../../components/Pagination'
import { formatDateYMD, getDateDaysAgo } from '../../../../../utils/dateFormatter'
import { resolveErrors } from '../../../../../utils/errorTypes'
import { TRANSACTION_STATUS } from '../../../SportsTransactions/constants'
import ConfirmAffiliateWithdraRequestApprovalModal from './components/ConfirmAffiliateWithdraRequestApprovalModal'
import CancelAffiliateWithdraRequestApprovalModal from './components/CancelAffiliateWithdraRequestApprovalModal'
import UpdateButtonIcon from '../../../../../components/UpdateButtonIcon'
import DateRangePicker from '../../../../../components/DateRangePicker'

export const tableHeaders = [
  { label: 'ID', value: 'affiliateWithdrawRequestId' },
  { label: 'Usuário', value: 'withdrawRequestAffiliate.username' },
  { label: 'ID Transação', value: 'actioneeType' },
  { label: 'Chave PIX', value: 'actioneeType' },
  { label: 'Tipo de Chave PIX', value: 'actioneeType' },
  { label: 'Valor', value: 'amount' },

  { label: 'Status', value: 'status' },
  { label: 'Criado em:', value: 'createdAt' },
  { label: 'Atualizado em:', value: 'updated' },
  { label: 'Ações', value: 'actions' },
]


const AffiliateWithdrawRequestsTable = ({ affiliate, ...props }) => {
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState({ rows: [], count: 0 });
  const [page, setPage] = useState(1);

  const [dateRange, setDateRange] = useState([{
    startDate: getDateDaysAgo(7),
    endDate: new Date(),
    key: 'selection'
  }])

  const loadAffiliateWithdrawRequests = useDebounce(async ({ loading, initialLoading, page } = {}) => {
    if (loading) setLoading(true);
    if (initialLoading) setInitialLoading(true);
    try {
      const transactions = await getAffiliateWithdrawRequests({
        affiliateId: affiliate?.affiliateId,
        startDate: formatDateYMD(dateRange.map(a => a.startDate)),
        endDate: formatDateYMD(dateRange.map(a => a.endDate)),
        pageNo: page || 1,
        limit: 15
      })
      setTransactions(transactions.data.data);
    } catch (err) {
      toast(resolveErrors(err?.response?.data?.errors, "Erro ao obter solicitações de saque do afiliado"), "error");
    } finally {
      setLoading(false);
      setInitialLoading(false);
    }
  }, 400)


  useEffect(() => {
    if (affiliate?.affiliateId) loadAffiliateWithdrawRequests({ initialLoading: true })
  }, [affiliate, dateRange])

  if (!affiliate) return;

  return (
    <div className='TableBg mt-4 p-2' {...props}>
      <DashboardHeadTitle className="mt-2 px-2 d-flex justify-content-between">
        <Col md={6} xs={12}>
          <h3 className="dashboard-spec-text">Solicitações de saque</h3>
        </Col>
        <Col md={6} xs={12}>
          <Row className='mt-0 justify-content-end'>
            <Col xs={1} md={1} className='d-flex align-items-center justify-content-end'>
              <UpdateButtonIcon
                onClick={() => loadAffiliateWithdrawRequests({ loading: true })}
                {...{ loading }}
              />
            </Col>
            <Col xs={6} md={6} >
              <DateRangePicker
                state={dateRange} setState={setDateRange}
                size='sm'
                placement={"right"}
              />
            </Col>
          </Row>
        </Col>
      </DashboardHeadTitle>
      <div className='position-relative h-100'>
        <Table striped responsive hover size='sm' className='text-center'>
          <thead className='thead-light'>
            <tr>
              {tableHeaders.map((h, idx) => (
                <th
                  key={`T-table_heading ${idx}`}
                  className={`${['Amount'].includes(h.label) && transactions?.count > 0 ? 'text-right' : ''}`}
                >
                  {h.label}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {transactions && !initialLoading &&
              transactions?.rows?.map(
                ({
                  affiliateWithdrawRequestId,
                  transactionAffiliate,
                  withdrawRequestAffiliate,
                  currency,
                  createdAt,
                  updatedAt,
                  amount,
                  status,
                }) => {
                  return (
                    <tr key={`transaction-list ${affiliateWithdrawRequestId}`}>
                      <td>{affiliateWithdrawRequestId}</td>
                      <td>{withdrawRequestAffiliate?.username}</td>
                      <td>{transactionAffiliate?.transactionUUID}</td>
                      <td>{transactionAffiliate?.metaData?.pix_key || 'N/A'}</td>
                      <td>{transactionAffiliate?.metaData?.pix_key_type || 'N/A'}</td>
                      <td className={`text-right text-danger`}>
                        -{FormatNumber.currency(amount || 0, currency)}
                      </td>
                      <td><span className='w-100' data-badge={String(STATUS_VALUE[status]).toLowerCase()}>{STATUS_LABEL[STATUS_VALUE[status]]}</span></td>
                      <td>{FormatDate.default(createdAt)}</td>
                      <td>{FormatDate.default(updatedAt)}</td>
                      <td>
                        <div className="d-flex gap-2">
                          <ConfirmAffiliateWithdraRequestApprovalModal
                            {...{ status, affiliateWithdrawRequestId, withdrawRequestAffiliate }}
                            onSuccess={loadAffiliateWithdrawRequests}
                          />

                          {status == TRANSACTION_STATUS.PENDING ? (
                            <CancelAffiliateWithdraRequestApprovalModal
                              {...{ status, affiliateWithdrawRequestId, withdrawRequestAffiliate }}
                              onSuccess={loadAffiliateWithdrawRequests}
                            />
                          ) : null}
                        </div>
                      </td>
                    </tr>
                  )
                })}

            {transactions?.count === 0 && !loading && (
              <tr>
                <td colSpan={10} className='text-danger text-center'>
                  Nenhuma solicitação de saque encontrada
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        {initialLoading ? <Loading className={"position-absolute bg-white top-0 w-100 h-100"} /> : null}
      </div>
      {transactions?.count > 0 && !initialLoading && (
        <PaginationComponent
          page={transactions?.count < page ? setPage(1) : page}
          totalPages={transactions.totalPages}
          setPage={(page) => {
            setPage(page);
            loadAffiliateWithdrawRequests({ loading: true, page });
          }}
        />
      )}
    </div>
  )
}

export default AffiliateWithdrawRequestsTable
