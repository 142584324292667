import * as Yup from 'yup'

export const createSocialLinkSchema = () => Yup.object().shape({
  name: Yup.string()
    .matches(/^[aA-zZ\s]+$/, 'Enter only alphabets')
    .min(3, 'Name should be of more than 3 characters')
    .max(50, 'Name Cannot be of more than 50 characters')
    .required('Name cannot be Empty'),
  label: Yup.string()
    .matches(/^[aA-zZ\s]+$/, 'Enter only alphabets')
    .max(50, 'Label Cannot be of more than 50 characters')
    .required('Label cannot be Empty'),
  link:  Yup.string()
  .url('Invalid URL format')
  .required('Link cannot be empty'),
})
