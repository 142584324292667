import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAllGroupsStart } from '../../../../store/redux-slices/adminUser'
import { permissionLabel } from '../../../../components/ProfilePage/constants'
import { getAdminDetailsStart, getAllAdminsStart, getSuperAdminDetailsStart } from '../../../../store/redux-slices/admins'
import { getCountriesStart } from '../../../../store/redux-slices/fetchData'
import { AFFILIATE_TYPE, AFFILIATE_TYPE_LABEL } from '../../../../utils/constant'

const affiliateTypeOptions = Object.keys(AFFILIATE_TYPE).map((key) => ({
  value: AFFILIATE_TYPE[key],
  label: AFFILIATE_TYPE_LABEL[key]
}));

const useAffiliateForm = ({ group }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [type, setType] = useState('password')
  const { adminRole } = useSelector((state) => state.login)
  const { data, adminDetails, loading, superAdminUser } = useSelector((state) => state.admins)
  const { groups } = useSelector((state) => state.adminUser)
  const [groupOptions, setGroupOptions] = useState()
  const [selectedGroup, setSelectedGroup] = useState()
  const [selectedGender, setSelectedGender] = useState('male')
  const [countriesOptions, setCountriesOptions] = useState([])
  const [selectedCountry, setSelectedCountry] = useState()
  const { countries } = useSelector((state) => state.fetch)

  useEffect(() => {
    dispatch(getAllGroupsStart({ isTenant: false }))
    dispatch(getCountriesStart({ limit: 200, pageNo: 1 }))
    if (group) {
      setSelectedGroup({ label: group, value: group })
    }
  }, [])

  useEffect(() => {
    if (groups?.length > 0) {
      const options = []
      groups.map((g) => {
        if (g !== '' && g !== null) {
          options.push({ label: g, value: g })
        }
      })
      setGroupOptions(options)
    }
  }, [groups])

  const superAdminDetails = () => dispatch(getSuperAdminDetailsStart({ isTenant: false }))
  const getAllAdmins = ({
    limit,
    pageNo,
    sort,
    orderBy,
    search,
    superRoleId,
    superAdminId
  }) => dispatch(
    getAllAdminsStart({
      limit,
      pageNo,
      sort,
      orderBy,
      search,
      superRoleId,
      // superAdminId
    })
  )

  const getAdminDetails = ({ adminId }) => dispatch(getAdminDetailsStart({ adminId }))

  useEffect(() => {
    if (countries?.count && countries?.count > 0) {
      setCountriesOptions(countries?.rows.map(country => ({ label: country?.name, value: country?.code })))
    }
  }, [countries])

  return {
    navigate,
    dispatch,
    adminRole,
    data,
    adminDetails,
    permissionLabel,
    superAdminDetails,
    getAllAdmins,
    getAdminDetails,
    loading,
    type,
    setType,
    groupOptions,
    setGroupOptions,
    selectedGroup,
    setSelectedGroup,
    superAdminUser,
    selectedGender,
    setSelectedGender,
    countriesOptions,
    selectedCountry,
    selectedCountry,
    setSelectedCountry,
    affiliateTypeOptions
  }
}

export default useAffiliateForm
