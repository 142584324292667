
export const subAffiliateTableHeaders = [
  { label: 'ID', value: 'affiliateId' },
  { label: 'Usuário', value: 'username' },
  { label: 'Tipo Afiliado', value: 'affiliateType' },
  { label: 'E-mail', value: 'email' },
  { label: 'Nome', value: 'firstName' },
  { label: 'Sobrenome', value: 'lastName' },
  { label: 'Status', value: 'status' },
  { label: 'Ativo', value: 'isActive' },
  // { label: 'É visível', value: 'isVisible' },
  // { label: 'Comissão em %', value: 'commission' },
  // { label: 'Ação', value: 'action' }
]
