import "../AffiliateSignupPage/style.scss";
import React from "react";
import { Formik, Form } from "formik";
import { Col, Row } from "@themesberg/react-bootstrap";

import signinPageBackground from "../../../assets/img/signin-page-background.png";
import onlybetsLogo from "../../../assets/img/logo.png";

import Button from "../../../components/Button";
import { affiliateSigninInitialValues, affiliateSigninSchema } from "./schema";
import { FormikInput } from "../../../components/utils/FormikInput";
import { CommonRoutes } from "../../../routes";
import { Link } from "react-router-dom";
import useAffiliateSignin from "./useAffiliateSignin";


export const AffiliateSigninPage = () => {

  const { loading, onSubmit, email } = useAffiliateSignin();

  return (
    <main className="d-flex bg-white signin-page-wrapper flex-sm-row flex-column-reverse">
      <Col sm={6} xs={12} className="col-left-side p-5 px-sm-5 px-3">
        <Col xs={12} className="d-flex align-items-center justify-content-center">
          <Formik
            initialValues={affiliateSigninInitialValues({ user: email })}
            validationSchema={affiliateSigninSchema}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, errors, values }) => {
              // console.log(errors)
              return (
                <Form style={{ maxWidth: "20rem" }} className="w-100">
                  <h1 className="mb-4 color-blue-dark-800">Login do Afiliado</h1>
                  <Row>
                    <Col md={12} xs={12} className="mb-3">
                      <FormikInput name={"user"} label={"E-mail ou Nome de Usuário"} disabled={Boolean(email)} />
                    </Col>
                    <Col md={12} xs={12} className="mb-3">
                      <FormikInput name={"password"} label={"Senha"} type={"password"} autoFocus={Boolean(email)} />
                    </Col>
                  </Row>
                  <Row className="justify-content-end">
                    <Col sm="auto" className="mb-3">
                      <Link
                        className="small"
                        to={`${CommonRoutes?.ForgotPassword}?${new URLSearchParams({ userType: 'Affiliate' })}`}
                        state={{ isSuperAdmin: true }}
                      >
                        Recuperar senha?
                      </Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button className="w-100" type="submit" /* onClick={handleSubmit} */ loading={loading}>Enviar</Button>
                    </Col>
                  </Row>
                </Form>
              )
            }}
          </Formik>
        </Col>
        <Col xs={12} className="d-flex justify-content-center mt-5">
          <p className="m-0 text-muted text-center">© {new Date().getFullYear()} Onlybets. Todos Direitos Reservados.</p>
        </Col>
      </Col>
      <Col sm={6} xs={12} className="col-right-side">
        <img src={signinPageBackground} className="w-100 h-100" alt="" />
        <img src={onlybetsLogo} className="onlybets-logo" alt="" />
      </Col>
    </main>
  );
};

export default AffiliateSigninPage;