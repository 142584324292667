import React from 'react'
import { Tabs, Tab, Row } from '@themesberg/react-bootstrap'
import Preloader from '../../../components/Preloader'
import useCheckPermission from '../../../utils/checkPermission'
import AddGamesToSubCategory from './components/AddRestrictedGames'
import useAddGames from './useAddGames'

const AddCasinoSubCategory = () => {
  const {
    loading,
    unRestrictedItemsLimit,
    setUnRestrictedItemsLimit,
    setUnRestrictedItemsPage,
    unRestrictedItemsPage,
    unRestrictedItemsTotalPages,
    casinoGamesData,
    addGame,
    selectedGames,
    removeGame,
    addRestrictedGames,
    search,
    setSearch,
    getProviderName,
    subCategoryName
  } = useAddGames()
  const { isHidden } = useCheckPermission()

  return (
    <>
      {loading
        ? (
          <Preloader />
          )
        : (
          <div className='mt-3'>

                <div className='mt-5'>
                  <Row className='mt-3 d-flex'>
                    <AddGamesToSubCategory
                      limit={unRestrictedItemsLimit}
                      setLimit={setUnRestrictedItemsLimit}
                      page={unRestrictedItemsPage}
                      setPage={setUnRestrictedItemsPage}
                      unRestrictedItems={casinoGamesData}
                      totalPages={unRestrictedItemsTotalPages}
                      addGame={addGame}
                      selectedGames={selectedGames}
                      removeGame={removeGame}
                      addRestrictedGames={addRestrictedGames}
                      search={search}
                      setSearch={setSearch}
                      getProviderName={getProviderName}
                      subCategoryName={subCategoryName}
                    />
                  </Row>
                </div>
          </div>
          )}
    </>
  )
}

export default AddCasinoSubCategory
