import React from "react";
import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Trigger from "../OverlayTrigger";

import "./UpdateButtonIcon.scss";

export const UpdateButtonIcon = ({ className, loading, ...props }) => {

  return (
    <Trigger message={"Atualizar"} placement="top">
      <span className={`position-relative update-button-icon ${className ?? ""}`} {...props} data-loading={loading ?? undefined}>
        <FontAwesomeIcon
          icon={faRedo}
          style={{
            fontSize: "15px",
            color: "gray",
            cursor: "pointer",
          }}
        />
      </span>
    </Trigger>
  )
}

export default UpdateButtonIcon;