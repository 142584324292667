import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ROLE } from "../utils/constant";
import { getItem } from "../utils/storageUtils";

/** @type {() => { SuperAdmin: boolean, Admin: boolean, Affiliate:  boolean,  Manager: boolean, AdminOrSuperAdmin: boolean }} */
export const useRole = () => {

  const defineRoles = () => ({
    SuperAdmin: ROLE.SUPER_ADMIN == getItem("role"),
    Admin: ROLE.ADMIN == getItem("role"),
    Affiliate: ROLE.AFFILIATE == getItem("role"),
    Manager: ROLE.MANAGER == getItem("role"),
    AdminOrSuperAdmin: [ROLE.SUPER_ADMIN, ROLE.ADMIN].includes(getItem("role"))
  });

  const [Role, setRole] = useState(defineRoles);

  let { role } = useSelector((state) => state.login);
  if (!role) role = getItem("role");

  useEffect(() => { setRole(defineRoles()) }, [role]);

  return Role;
};

export default useRole;