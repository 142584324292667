import React from 'react';
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded'
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded'
import {
    Button,
  } from '@themesberg/react-bootstrap'
const ActionMenu = (props) => {
    const { adminUserId, navigate, handleShow, isHidden,  firstName, lastName, email, getRole, isActive, adminRoleId } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl)
    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    return (
        <>
            <Button
                variant='btn text-btn'
                id='basic-button'
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
                }}
            >
                <MoreVertRoundedIcon />
            </Button>
            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button'
                }}
            >
                <MenuItem onClick={handleClose}>
                    <Button
                    variant='btn text-btn'
                    onClick={() => { navigate(`/admin/admins/edit/${adminUserId}`) }}
                    hidden={isHidden({ module: { key: 'Admins', value: 'U' } })}
                    >
                    <BorderColorRoundedIcon />
                    <span className='text'>Edit</span>
                    </Button>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Button
                        variant='btn text-btn'
                        onClick={() =>
                            navigate(
                            `/admin/admins/details/${adminUserId}`
                            )}
                        hidden={isHidden({ module: { key: 'Admins', value: 'R' } })}
                        >
                        <RemoveRedEyeRoundedIcon />
                        <span className='text'>View</span>
                    </Button>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                {(!isActive
                    ? (
                        <Button
                        variant='btn text-btn'
                        onClick={() =>
                            handleShow(adminUserId, isActive, `${firstName} ${lastName} (${email})`)}
                        hidden={isHidden({ module: { key: 'Admins', value: 'T' } })}
                        >
                        <CheckCircleRoundedIcon />
                        <span className='text'>Active</span>
                        </Button>
                    )
                    : (
                        <Button
                        variant='btn text-btn'
                        onClick={() =>
                            handleShow(adminUserId, isActive, `${firstName} ${lastName} (${email})`)}
                        hidden={isHidden({ module: { key: 'Admins', value: 'T' } })}
                        >
                        <CancelRoundedIcon />
                        <span className='text'>In-Active</span>
                        </Button>)
                )}
                </MenuItem>
                <MenuItem onClick={handleClose}>
                {getRole(adminRoleId) !== 'Support' &&
                    <Button
                        variant='btn text-btn'
                        onClick={() =>
                        navigate(
                        `/admin/admins/details/${adminUserId}`,
                        {
                            state: {
                            isTreeView: true
                            }
                        }
                        )}
                        hidden={isHidden({ module: { key: 'Admins', value: 'R' } })}
                    >
                        <AccountTreeRoundedIcon />
                        <span className='text'>View Tree</span>
                    </Button>
                }
                </MenuItem>
            </Menu>
        </>
    )
};

export default ActionMenu;