import * as Yup from 'yup'

export const tagSchema = () =>
  Yup.object().shape({
    tags: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string()
            .matches(/^[A-Za-z0-9_]*$/, 'Only Alphabets, Numbers and UnderScore(_) Allowed')
            .nullable()
        })
      ).nullable()
  })
