import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getLoyaltyRules } from '../../../../utils/apiCalls';
import { deleteLoyaltyRuleStart, getAllLoyaltyRulesStart, getLoyaltyRulesState, getMinimumBetAmountStart, setMinimumBetAmountStart } from '../../../../store/redux-slices/loyaltyRules';
import useCheckPermission from '../../../../utils/checkPermission';

export const useLoyaltyRulesListing = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [minimumBetAmountModal, setMinimumBetAmountModal] = useState(false)
  const { loyaltyRules,minimumBetAmount } = useSelector(getLoyaltyRulesState)
  useEffect(() => {
    dispatch(getAllLoyaltyRulesStart())
    dispatch(getMinimumBetAmountStart())
  }, [])
  const handlePopClick = () => {
    dispatch(deleteLoyaltyRuleStart())
  }
  const handleSetMinimumAmountSubmit = (formValues) => {
    dispatch(setMinimumBetAmountStart({formValues,setMinimumBetAmountModal}))
  }

  const { isHidden } = useCheckPermission()
  return {
    navigate,
    limit,
    setLimit,
    page,
    setPage,
    isHidden,
    loyaltyRules: {
      count: loyaltyRules?.length || 0,
      rows: loyaltyRules || []
    },
    loading: false,
    handlePopClick,
    handleSetMinimumAmountSubmit,
    minimumBetAmountModal,
    setMinimumBetAmountModal,
    minimumBetAmount
  }
}

