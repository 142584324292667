import React from 'react'

import {
  Row,
  Col,
  Form as BForm,
  Button,
  InputGroup
} from '@themesberg/react-bootstrap'
import { ErrorMessage } from 'formik'
import { BONUS_TYPE_CONSTANTS, convertAmountOptions } from './constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getSAConvertAmount } from '../../../../../utils/apiCalls'
import Trigger from '../../../../../components/OverlayTrigger'
import { toast } from '../../../../../components/Toast'
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons'
import { safeStringify } from '../../../../../utils/helpers'

const CurrenciesForm = ({
  values,
  handleChange,
  handleSubmit,
  handleBlur,
  setFieldValue,
  setCurr,
  bonusDetail,
  setSelectedTab,
  isEdit,
  enableSubmit,
  setEnableSubmit,
  isTenant
}) => {
  async function fetchData() {
    console.log("sasa")
    const code = 'BRL'
    const maxBonusThreshold = values?.currency?.[code]?.maxBonusThreshold
    const minDeposit = values?.currency?.[code]?.minDeposit
    const maxWinAmount = values?.currency?.[code]?.maxWinAmount
    const zeroOutThreshold = values?.currency?.[code]?.zeroOutThreshold
    const minBalance = values?.currency?.[code]?.minBalance
    const joiningAmount = values?.currency?.[code]?.joiningAmount
    const coinEquivalentTo = values?.currency?.[code]?.coinEquivalentTo
    if ([BONUS_TYPE_CONSTANTS.CASINO_DEPOSIT,BONUS_TYPE_CONSTANTS.SPORTS_DEPOSIT].includes(values?.bonusType)) {
      if (maxBonusThreshold === '' && !['balance', 'wagering'].includes(values.bonusType)) {
        toast('Enter Max Bonus Claimed', 'error')
      } else if (minBalance === '' && (values?.bonusType === 'wagering')) {
        toast('Enter Min Balance', 'error')
      } else if (minDeposit === '' && values.bonusType !== 'balance' && values?.bonusType !== 'wagering') {
        toast('Enter Min Deposit', 'error')
      } else if (maxWinAmount === '' && values.bonusType !== 'balance' && values?.bonusType !== 'wagering') {
        toast('Enter Max Win Amount', 'error')
      } else if (zeroOutThreshold === '' && (values.bonusType !== 'balance')) {
        toast('Enter Zero Out Threshold', 'error')
      } else {
        console.log("888888888888888888888")
        await getSAConvertAmount({
          currencyFields: {
            // maxBonusThreshold: maxBonusThreshold || 0,
            minDeposit: minDeposit || 0,
            maxWinAmount: maxWinAmount || 0,
            // zeroOutThreshold: zeroOutThreshold || 0,
            // minBalance: minBalance || 0
          },
          currencyCode: code,
          // tenantIds: safeStringify(values?.tenantIds.map((id) => id?.value))
        }).then((res) => {
          setFieldValue('currency', res?.data?.data?.currenciesAmount)
          setCurr(res?.data?.data?.currenciesAmount)
          setEnableSubmit(true)
        })
      }
    } else if (values?.bonusType === BONUS_TYPE_CONSTANTS.JOINING) {
      if (joiningAmount === '') {
        toast('Enter Joining Amount ', 'error')
      } else {
        await getSAConvertAmount({
          currencyFields: {
            joiningAmount: joiningAmount || 0,
            minDeposit:minDeposit || 0
          },
          currencyCode: code
          // tenantIds: safeStringify(values?.tenantIds.map((id) => id?.value))
        }).then((res) => {
          setFieldValue('currency', res?.data?.data?.currenciesAmount)
          setCurr(res?.data?.data?.currenciesAmount)
          setEnableSubmit(true)
        })
      }
    }else if(values?.bonusType === BONUS_TYPE_CONSTANTS.LOYALTY){
      if (coinEquivalentTo === '') {
        toast('Enter One Coin Equivalent amount ', 'error')
      } else {
        await getSAConvertAmount({
          currencyFields: {
            coinEquivalentTo: coinEquivalentTo || 0,
          },
          currencyCode: code
          // tenantIds: safeStringify(values?.tenantIds.map((id) => id?.value))
        }).then((res) => {
          setFieldValue('currency', res?.data?.data?.currenciesAmount)
          setCurr(res?.data?.data?.currenciesAmount)
          setEnableSubmit(true)
        })
      }
    } 
     else {

      if (maxWinAmount === '') {
        toast('Enter Max Win Amount', 'error')
      } else if (zeroOutThreshold === '' && (values?.isSticky === 'true' || values?.isSticky)) {
        toast('Enter Zero Out Threshold', 'error')
      } else {
        await getSAConvertAmount({
          currencyFields: {
            // maxBonusThreshold: maxBonusThreshold || 0,
            minDeposit: minDeposit || 0,
            maxWinAmount: maxWinAmount || 0,
            // zeroOutThreshold: zeroOutThreshold || 0,
            // minBalance: minBalance || 0
          },
          currencyCode: code
          // tenantIds: safeStringify(values?.tenantIds.map((id) => id?.value))
        }).then((res) => {
          setFieldValue('currency', res?.data?.data?.currenciesAmount)
          setCurr(res?.data?.data?.currenciesAmount)
          setEnableSubmit(true)
        })
      }
    }
  }
  console.log("enableSubmit || isEdit)",enableSubmit , isEdit)
  return (
    <>
      {bonusDetail && !enableSubmit && setEnableSubmit(false)}
      <Col className='d-flex justify-content-end mb-3'>
        <Trigger message='Fetch according to primary currency'>
          <Button onClick={fetchData}>
            <FontAwesomeIcon icon={faExchangeAlt} />
          </Button>
        </Trigger>
      </Col>

      {values.currency &&
        Object.keys(values.currency).length > 0 &&
        Object.keys(values.currency).map((key, index) => (
          <div key={index} className='FormsBg'>
            <Row key={index} className='g-2 mb-2'>
              <Col sm='auto' className='d-flex'>
                <Col className='px-1 text-center'>
                  {index < 1 && <label style={{ fontSize: '14px' }} htmlFor='currency'>Currency</label>}
                  <InputGroup>
                    <BForm.Control
                      name={`currency[${key}]`}
                      value={key}
                      onInput={handleChange}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled
                    />
                  </InputGroup>
                </Col>

                {Object.keys(values.currency[key]).map((currKey, currIndex) => {
                  console.log("currKey",key)
                 let hide = false;
                 if (values?.bonusType === 'wagering') {
                     if (currKey === 'minDeposit' || currKey === 'maxBonusThreshold' || currKey === 'maxWinAmount') {
                         hide = true;
                     }
                 } else if (values?.bonusType === 'joining') {
                     if (currKey !== 'joiningAmount' && currKey !== 'minDeposit') {
                         hide = true;
                     }
                 } else if (values?.bonusType === 'freespins' || values?.bonusType === 'cashfreespins') {
                     if (values?.isSticky === 'true' || values?.isSticky) {
                         if (currKey !== 'maxWinAmount' && currKey !== 'zeroOutThreshold') {
                             hide = true;
                         }
                     } else {
                         if (currKey !== 'maxWinAmount') {
                             hide = true;
                         }
                     }
                 } else {
                     if (currKey == 'joiningAmount' || currKey === 'minBalance') {
                         hide = true;
                     }
                 }
                 
                  return (
                    currKey !== 'minBonusThreshold' &&
                    <Col className='px-1 text-center' key={currIndex} hidden={hide}>
                      {index < 1 && !hide && (
                        <label htmlFor={currKey} style={{ fontSize: '14px' }}>
                          {
                            ['wagering'].includes(values?.bonusType)
                              ? convertAmountOptions?.find(
                                (val) => currKey === 'minBalance' ? val.value === 'minBalanceCash' : val.value === currKey
                              )?.label
                              : convertAmountOptions?.find(
                                (val) => val.value === currKey
                              )?.label
                          }
                          <span className='text-danger'> *</span>
                        </label>
                      )}
                      <InputGroup>
                        <BForm.Control
                          name={`currency[${key}][${currKey}]`}
                          value={values.currency[key][currKey]}
                          onInput={handleChange}
                          hidden={hide}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          disabled={key !== 'BRL'}
                        />
                      </InputGroup>
                      <ErrorMessage
                        component='div'
                        hidden={hide}
                        name={`currency[${key}][${currKey}]`}
                        className='text-danger'
                      />
                    </Col>
                  )
                })}
              </Col>
            </Row>
          </div>

        ))}

      <div className='mt-4 d-flex justify-content-between align-items-center'>
        <Button
          onClick={() => {
            setSelectedTab('general')
          }}
          className='ml-2'
        >
          Previous
        </Button>
        {(enableSubmit) && (
          <Button
            onClick={handleSubmit}
            className='ml-2'
          >
            Next
          </Button>
        )}
      </div>
    </>
  )
}
export default CurrenciesForm
