import { Button, Form, Tab, Tabs } from '@themesberg/react-bootstrap'
import { Formik } from 'formik'
import React from 'react'
import { AdminsRoutes } from '../../../routes'
import { keysNotToBeShown } from '../hooks/constant'
import useAddLanguage from '../hooks/useAddLanguage'
import { languageWiseSchema } from './schema'
import UserEndPages from './UserEndPages'

const AddLangauge = ({
  isTenant = false
}) => {
  const {
    languageKeyObj,
    selectedKeyTab,
    navigate,
    languageTabs,
    setSelectedKeyTab,
    myKeys,
    lang,
    updateData,
    isError,
    setIsError
  } = useAddLanguage(isTenant)

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={
            { languageKeys: { ...languageKeyObj } }
        }
        validationSchema={languageWiseSchema(myKeys)}
        onSubmit={(formValues) => {
          const data = Object.assign({ }, formValues?.languageKeys)
          for (const key in data) {
            if (keysNotToBeShown.includes(key)) {
              delete data[key]
            }
          }
          data.language = lang
          updateData({
            type: 'create',
            data
          })
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur
        }) => (
          <Form>
            <Tabs
              activeKey={selectedKeyTab}
              onSelect={(tab) => setSelectedKeyTab(tab)}
              className='nav-light'
            >
              {languageTabs && Object.keys(languageTabs)?.map(key => {
                return (
                  <Tab
                    disabled={isError && Object.keys(isError).length}
                    eventKey={key}
                    key={key}
                    title={key}
                  >
                    <div className='mt-5'>
                      <UserEndPages
                        myKeys={myKeys}
                        selectedTab={selectedKeyTab}
                        values={values}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        languageTabs={languageTabs[selectedKeyTab]}
                        isError={isError}
                        setIsError={setIsError}
                      />
                    </div>
                  </Tab>

                )
              })}
            </Tabs>

            <div className='mt-4 d-flex justify-content-between'>
              <Button
                variant='outline-warning'
                onClick={() => navigate(AdminsRoutes.LanguageManagement)}
                className='ml-2'
              >
                Cancel
              </Button>
              <Button
                className='ml-2'
                variant='outline-success'
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default AddLangauge
