import React, { useEffect } from 'react'
import {
  Button,
  Form as BForm,
  Row,
  Col,
  Spinner,
  InputGroup
} from '@themesberg/react-bootstrap'
import { Form, Field, ErrorMessage } from 'formik'
import { AdminsRoutes } from '../../../../routes'
import { toast } from '../../../../components/Toast'
import useAdminUserForm from '../hooks/useAdminUserForm'
import Trigger from '../../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import CreatableSelect from 'react-select/creatable'
import { permissionIcons } from '../../../../components/ProfilePage/constants'

const SuperAdminUserForm = ({
  values,
  handleChange,
  handleSubmit,
  handleBlur,
  setFieldValue,
  isEdit = false
}) => {
  const {
    navigate,
    data,
    adminRole,
    adminDetails,
    permissionLabel,
    superAdminDetails,
    getAllAdmins,
    getAdminDetails,
    loading,
    type,
    setType,
    groupOptions,
    setGroupOptions,
    selectedGroup,
    setSelectedGroup,
    superAdminUser
  } = useAdminUserForm({ group: values?.group })
  useEffect(() => {
    if (values.role === 'Manager') {
      getAllAdmins({
        limit: '',
        pageNo: '',
        sort: 'desc',
        orderBy: 'adminUserId',
        search: '',
        superRoleId: 2
        // superAdminId: ''
      })
      values?.adminId && getAdminDetails({ adminId: values.adminId })
    }
  }, [values.role])
  return (
    <>
      <Form>
        <Row>
          <Col md={6} sm={12} className='mb-3'>
            <BForm.Label>Email</BForm.Label>
            <BForm.Control
              type='text'
              name='email'
              placeholder='Digite o e-mail'
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isEdit}
            />
            <ErrorMessage
              component='div'
              name='email'
              className='text-danger'
            />
          </Col>
          <Col md={6} sm={12} className='mb-3'>
            <BForm.Label>Nome do usuário</BForm.Label>
            <BForm.Control
              type='text'
              name='adminUsername'
              placeholder='Nome de usuário'
              value={values.adminUsername}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <ErrorMessage
              component='div'
              name='adminUsername'
              className='text-danger'
            />
          </Col>
          <Col md={6} sm={12} className='mb-3'>
            <BForm.Label>Primeiro Nome</BForm.Label>
            <BForm.Control
              type='text'
              name='firstName'
              placeholder='Digite o primeiro nome'
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <ErrorMessage
              component='div'
              name='firstName'
              className='text-danger'
            />
          </Col>
          <Col md={6} sm={12} className='mb-3'>
            <BForm.Label>Sobrenome</BForm.Label>
            <BForm.Control
              type='text'
              name='lastName'
              placeholder='Digite o sobrenome'
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <ErrorMessage
              component='div'
              name='lastName'
              className='text-danger'
            />
          </Col>
          {(isEdit ? [1, 2].includes(superAdminUser?.superAdminUserId) : true) &&
            <Col md={6} sm={12} className='mb-3'>
              <BForm.Label>Senha</BForm.Label>
              <Trigger message='Must be atleast 8 characters long with 1 uppercase and 1 lowercase letters, 1 special character and 1 digit'>
                <InputGroup>
                  <BForm.Control
                    type={type}
                    name='password'
                    placeholder='Digite senha'
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <InputGroup.Text className='b-1' style={{ border: '0.0625rem solid #d1d7e0' }}>
                    <FontAwesomeIcon
                      icon={type === 'password' ? faEyeSlash : faEye}
                      onClick={() => {
                        type === 'password' ? setType('text') : setType('password')
                      }}
                    />
                  </InputGroup.Text>
                </InputGroup>
              </Trigger>
              <ErrorMessage
                component='div'
                name='password'
                className='text-danger'
              />
            </Col>}
          {/* <Col md={6} sm={12} className='mb-3'>
            <BForm.Label>Group</BForm.Label>
            <CreatableSelect
              isClearable
              name='group'
              onCreateOption={(option) => {
                groupOptions?.length > 0
                  ? setGroupOptions([
                    ...groupOptions,
                    { label: option, value: option }
                  ])
                  : setGroupOptions([
                    { label: option, value: option }
                  ])
                setSelectedGroup({ label: option, value: option })
                setFieldValue('group', option)
              }}
              options={groupOptions}
              value={selectedGroup}
              classNamePrefix='select'
              onChange={(option, e) => {
                if (option === null) {
                  setSelectedGroup()
                  setFieldValue('group', '')
                } else {
                  setSelectedGroup({ label: option?.label, value: option?.value })
                  setFieldValue('group', option?.value)
                }
              }}
            />
            <ErrorMessage
              component='div'
              name='group'
              className='text-danger'
            />
          </Col> */}
          <Col md={6} sm={12} className='mb-3'>
            <BForm.Label>Permissão</BForm.Label>

            <BForm.Select
              name='role'
              value={values.role || ''}
              disabled={isEdit}
              onChange={(e) => {
                handleChange(e)
                if (e.target.value !== 'Manager') {
                  superAdminDetails()
                } else {
                  setFieldValue('permission', {})
                  setFieldValue('adminId', '')
                }
              }}
              onBlur={handleBlur}
            >
              <option value='' disabled key=''>
               Selecionar permissão
              </option>
              {adminRole?.map((roles, index) => {
                return (
                  roles.name !== 'Super Admin' && roles.name !== 'Affiliate' &&
                  <option key={index} value={roles && roles.name}>
                    {roles && roles.name}
                  </option>
                )
              })}
            </BForm.Select>

            <ErrorMessage component='div' name='role' className='text-danger' />
          </Col>
          {values.role === 'Manager' && (
            <Col md={6} sm={12} className='mb-3'>
              <BForm.Label>Administrador</BForm.Label>

              <BForm.Select
                name='adminId'
                value={values.adminId || ''}
                disabled={isEdit}
                onChange={(e) => {
                  handleChange(e)
                  getAdminDetails({ adminId: e.target.value })
                }}
                onBlur={handleBlur}
              >
                <option value='' disabled>
                  Selecione admin
                </option>
                {data &&
                  data?.rows?.map((admin, index) => {
                    return (
                      <option
                        key={index}
                        value={admin && admin.adminUserId}
                      >
                        {admin && `${admin?.firstName} ${admin?.lastName}`}
                      </option>
                    )
                  })}
              </BForm.Select>

              <ErrorMessage
                component='div'
                name='adminId'
                className='text-danger'
              />
            </Col>
          )}

         {/* {values.role === 'Admin' && <Col md={6} sm={12} className='mb-3'>
            <BForm.Label>Commision in % </BForm.Label>
            <BForm.Control
              type='number'
              name='commission'
              placeholder='Enter Commission Percentage'
              value={values.commission}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <ErrorMessage
              component='div'
              name='commission'
              className='text-danger'
            />
          </Col>} */}
        </Row>

        <div className='my-3'>
          <div class='content-header-wrap' style={{ marginLeft: '-1.25rem', marginRight: '-1.25rem' }}>
            <h2 class='title-text'>Permissão</h2>
          </div>
        </div>


        <div className='row'>
          {(['Super Admin', 'Admin'].includes(values?.role) ||
            values.adminId) && (
              <>

                {(values.role === 'Manager' ? adminDetails : superAdminUser)?.userPermission && (
                  <>
                    {Object.keys(
                      (values.role === 'Manager' ? adminDetails : superAdminUser)?.userPermission?.permission
                    ).map((key, index) => {
                      return (
                        ((values.role === 'Manager' && key === 'Admins')
                          ? null
                          : (
                            <div class="mb-4 col-lg-3 col-md-4 col-sm-6">
                              <div class="permissions-card card">
                                <div class="fw-bold card-header">
                                  <span className='icon'>
                                    {permissionIcons()?.[key]}
                                  </span>
                                  <span className='text'>{key}</span>
                                </div>
                                <div class="list-group list-group-flush">
                                  {(values.role === 'Manager' ? adminDetails : superAdminUser)?.userPermission?.permission[
                                    key
                                  ].map((value, index) => {
                                    return (
                                      <div class="d-flex justify-content-between align-items-center py-1 px-3 
										list-group-item">
                                        <small>{permissionLabel(value)}</small>
                                        <div class='form-check'>
                                          {/* <input class='form-check-input' type='checkbox' checked id='adminsToggleStatus' name='admins' /> */}
                                          {/* <label class='form-check-label' for='adminsToggleStatus'></label> */}
                                          <label class='form-check-label' />
                                          {(values.role === 'Manager' ? adminDetails : superAdminUser)?.userPermission?.permission[
                                            key
                                          ].includes('R')
                                            ? (
                                              <Field
                                                className='form-check-input'
                                                type='checkbox'
                                                id={`${key}-permission[${value}]`}
                                                name={`permission[${key}]`}
                                                value={value}
                                                onChange={(e) => {
                                                  if (e.target.value === 'R' || values?.permission?.[key]?.includes('R')) {
                                                    if (
                                                      e.target.value === 'R' &&
                                                      !e.target.checked
                                                    ) {
                                                      delete values.permission[key]
                                                      setFieldValue(
                                                        'permission',
                                                        values.permission
                                                      )
                                                    } else {
                                                      handleChange(e)
                                                    }
                                                  } else {
                                                    toast(
                                                      'Please Select Read Permission Before Selecting Other For This Module',
                                                      'error'
                                                    )
                                                  }
                                                }}
                                              />
                                            )
                                            : (
                                              <Field
                                                className='form-check-input'
                                                type='checkbox'
                                                name={`permission[${key}]`}
                                                value={value}
                                                onChange={handleChange}
                                              />
                                            )}

                                        </div>
                                      </div>
                                      // <div class='form-check'>
                                      // <label class='form-check-label' >
                                      // {(values.role === 'Manager' ? adminDetails : superAdminUser)?.userPermission?.permission[
                                      // 	key
                                      // 	].includes('R')
                                      // 	? (
                                      // 		<Field
                                      // 		className='form-check-input'
                                      // 		type='checkbox'
                                      // 		id={`${key}-permission[${value}]`}
                                      // 		name={`permission[${key}]`}
                                      // 		value={value}
                                      // 		onChange={(e) => {
                                      // 			if (e.target.value === 'R' || values?.permission?.[key]?.includes('R')) {
                                      // 			if (
                                      // 				e.target.value === 'R' &&
                                      // 		!e.target.checked
                                      // 			) {
                                      // 				delete values.permission[key]
                                      // 				setFieldValue(
                                      // 				'permission',
                                      // 				values.permission
                                      // 				)
                                      // 			} else {
                                      // 				handleChange(e)
                                      // 			}
                                      // 			} else {
                                      // 			toast(
                                      // 				'Please Select Read Permission Before Selecting Other For This Module',
                                      // 				'error'
                                      // 			)
                                      // 			}
                                      // 		}}
                                      // 		/>
                                      // 		)
                                      // 	: (
                                      // 		<Field
                                      // 		className='form-check-input'
                                      // 		type='checkbox'
                                      // 		name={`permission[${key}]`}
                                      // 		value={value}
                                      // 		onChange={handleChange}
                                      // 		/>
                                      // 		)}
                                      // 	{permissionLabel(value)}</label>

                                      // </div>
                                    )
                                  })}
                                </div>
                              </div>
                            </div>
                          )
                        ))
                    })}
                  </>
                )}
              </>
            )}
        </div>
        <div className='mt-4 d-flex justify-content-between align-items-center'>
          <Button
            variant='btn btn-danger'
            onClick={() => navigate(AdminsRoutes.Admins)}
          >
            Cancelar
          </Button>
          <Button
            variant='btn btn-primary'
            onClick={() => {
              handleSubmit()
            }}
            className='ml-2'
          >
            Salvar
            {loading && (
              <Spinner
                as='span'
                animation='border'
                role='status'
                aria-hidden='true'
              />
            )}
          </Button>
        </div>
      </Form>
    </>
  )
}

export default SuperAdminUserForm
