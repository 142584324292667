import React from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import { faEnvelope, faUnlockAlt, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import {
  Spinner,
  Col,
  Row,
  Form as BForm,
  Container,
  InputGroup
} from '@themesberg/react-bootstrap'

import BgImage from '../../../assets/img/login-image.png'
import LogoImage from '../../../assets/img/logo.png'
import leafImage from '../../../assets/img/leaf.png'

import { superAdminLoginSchema } from './schema'
import useSuperAdminSignin from './useSuperAdminSignin'
import { CommonRoutes } from '../../../routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default () => {
  const {
    loading,
    showPassword,
    setShowPassword,
    handleSignIn,
    navigate,
    setShouldGoForOtpVerification,
    shouldGoForOtpVerification,
    handleGenerateOtp,
    settings,
  } = useSuperAdminSignin()
  return (
    <main>
      <section>
        <div className='logoImage'>
          <img src={LogoImage} width={200}></img>
        </div>
        <Row
          className='justify-content-center vh-100'
        >
          <Col
            xs={12} md={8}
            className='d-flex align-items-center BGImage admin-left justify-content-center'
          >
            <img src={BgImage} width={500}></img>
          </Col>
          <Col
            xs={12} md={4}
            className='d-flex align-items-center admin-right justify-content-center bg-white'
          >

            <div className='bg-white p-2 p-lg-5 w-100'>
              <div className='text-center text-md-center mb-4 mt-md-0'>
                <h2 className='mb-0 login-heading'>Painel de Administração Principal</h2>
              </div>

              <Formik
                initialValues={{ user: '', password: '', shouldGoForOtpVerification: true, otp: '' }}
                validationSchema={superAdminLoginSchema}
                onSubmit={({ user, password, shouldGoForOtpVerification, otp }, { setFieldValue }) => {
                  if (settings?.enableOtpCodeAdminLogin && shouldGoForOtpVerification) {
                    console.log("user,password", user, password)
                    handleGenerateOtp({ user, password, setFieldValue })
                  } else {
                    handleSignIn({ user, password, otp })
                  }

                }}
              >
                {({
                  touched,
                  errors,
                  values,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  setFieldValue
                }) => (
                  <div>
                    <Form>
                      <div className='form-group'>
                        <label
                          htmlFor='user'
                          className={
                            touched.user && errors.user ? 'text-danger' : ''
                          }
                        >
                          Digite seu e-mail ou nome de usuário:
                        </label>

                        <InputGroup
                          className={
                            touched.user && errors.user
                              ? 'border border-danger'
                              : ''
                          }
                        >

                          <BForm.Control
                            name='user'
                            autoFocus
                            required
                            type='text'
                            placeholder='example@company.com'
                            value={values.user}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </InputGroup>

                        <ErrorMessage
                          component='div'
                          name='user'
                          className='text-danger'
                        />
                      </div>

                      <div className='form-group'>
                        <label
                          htmlFor='password'
                          className={`mt-3 ${touched.password && errors.password
                            ? 'text-danger'
                            : ''
                            }`}
                        >
                          Password
                        </label>

                        <InputGroup
                          className={
                            touched.password && errors.password
                              ? 'border border-danger'
                              : ''
                          }
                        >


                          <BForm.Control
                            name='password'
                            required
                            type={showPassword ? 'text' : 'password'}
                            placeholder='qwerty'
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          <InputGroup.Text style={{ border: '0.0625rem solid #d1d7e0' }}>
                            {showPassword
                              ? <FontAwesomeIcon icon={faEye} style={{ cursor: 'pointer' }} onClick={() => { setShowPassword(prev => !prev) }} />
                              : <FontAwesomeIcon icon={faEyeSlash} style={{ cursor: 'pointer' }} onClick={() => { setShowPassword(prev => !prev) }} />}
                          </InputGroup.Text>

                        </InputGroup>

                        <ErrorMessage
                          component='div'
                          name='password'
                          className='text-danger'
                        />
                      </div>
                      <div className='d-flex justify-content-end'>
                        <p
                          className='mt-1 fwd-link'
                          onClick={() => navigate(`${CommonRoutes?.ForgotPassword}?${new URLSearchParams({ userType: 'Admin' })}`, { state: { isSuperAdmin: true } })}
                        >Recuperar senha?
                        </p>
                      </div>
                      {values.shouldGoForOtpVerification === false ? (<div className='form-group'>
                        <label
                          htmlFor='password'
                          className={`mt-3 ${touched.password && errors.password
                            ? 'text-danger'
                            : ''
                            }`}
                        >
                          OTP
                        </label>

                        <InputGroup
                          className={
                            touched.password && errors.password
                              ? 'border border-danger'
                              : ''
                          }
                        >


                          <BForm.Control
                            name='otp'
                            placeholder='otp'
                            value={values.otp}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </InputGroup>

                        <ErrorMessage
                          component='div'
                          name='otp'
                          className='text-danger'
                        />
                      </div>) : null}



                      <button
                        type='submit'
                        className='btn btn-primary btn-block mt-2'
                      // onClick={() => {
                      //     handleSubmit()
                      // }}
                      >
                        {loading && (
                          <Spinner
                            as='span'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        )}
                        {(settings?.enableOtpCodeAdminLogin && values.shouldGoForOtpVerification) ? 'Enviar código OTP' : 'Entrar'}
                      </button>
                    </Form>
                  </div>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
      </section>

    </main>
  )
}
