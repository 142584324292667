/* eslint-disable react/display-name */
import React from 'react'
import DropArea from './DropArea'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
export default (options, { handle }, isHidden) => {
  return (
    <>{!isHidden({ module: { key: 'ImageGallery', value: 'U' } }) && <DropArea>
      {isDrag => (
        <div className={`rug-handle ${isDrag ? '__dragging' : ''}`}>
          {/* <svg viewBox='0 -5 32 52' className='rug-handle-icon'>
            <g>
              <polyline points='1 19 1 31 31 31 31 19' />
              <polyline className='__arrow' points='8 9 16 1 24 9' />
              <line className='__arrow' x1='16' x2='16' y1='1' y2='25' />
            </g>
          </svg> */}
		  <CloudUploadIcon sx={{ fontSize: 50 }}  />

          <div className='rug-handle-info'>
            {typeof handle === 'function'
              ? (
                  handle(options)
                )
              : (
                <>
                  <div className='rug-handle-drop-text'>
                  Arraste e solte imagens aqui para fazer upload
                  </div>

                  <span>OR</span>

                  <div onClick={options.openDialogue} className='btn btn-primary'>
                    
Selecione imagens para enviar
                  </div>
                </>
                )}
          </div>
        </div>
      )}
    </DropArea>}
    </>
  )
}
