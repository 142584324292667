import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  fetchTenantDetailsStart,
  getAdminRoleStart,
  getTenantAdminRoleStart,
  getTenantRoleStart
} from '../../store/redux-slices/login'
import {
  getSuperAdminDetailsStart
} from '../../store/redux-slices/admins'
import { getItem, getLoginToken } from '../../utils/storageUtils'
import { AdminsRoutes } from '../../routes'
import { affiliateAdminDetailsStart } from '../../store/redux-slices/affiliates'

const usePrivateRoute = (isTenantRoute) => {
  const accessToken = getLoginToken()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loading } = useSelector((state) => state.login)
  const role = getItem('role')

  useEffect(() => {
    if (accessToken) {
      dispatch(getAdminRoleStart())
      dispatch(getTenantRoleStart())
      if (role === 'Affiliate') {
        dispatch(affiliateAdminDetailsStart({ onStart: true }))
      } else {
        dispatch(getSuperAdminDetailsStart({ isTenant: false, onStart: true }))
      }
    } else {
      navigate(AdminsRoutes.SuperAdminSignin)
    }
  }, [accessToken, isTenantRoute])

  return {
    accessToken,
    loading
  }
}

export default usePrivateRoute
