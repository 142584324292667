import React from "react";
import {
    Table,
    Form
} from '@themesberg/react-bootstrap'
import { useDispatch } from 'react-redux'
import PaginationComponent from '../../../components/Pagination'
import { NoDataContainer } from "./style";
import DivLoader from "../../../components/Loader/divLoader";
import { useNavigate } from 'react-router-dom';
import { addFeaturedStart } from '../../../store/redux-slices/sportsBook'
import { getDateTime } from "../../../utils/dateFormatter";
const SportsListTable = (props) => {
    const {
        sportsMatchesInfo,
        pageNo,
        setPageNo,
        totalPages,
        limit,
        setLimit,
        isGetMatchesLoading
    } = props;
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleStatusChange = (item) => {
        dispatch(addFeaturedStart({
            "providerMatchId": item.providerMatchId,
            "isFeatured": !item.isFeatured,
            "matchId": item.matchId
        }))
    }
    return (
        <div className='TableBg mt-4 pt-1'>
            <Table striped responsive hover size='sm' className='text-center mt-3'>
                <thead className='thead-light'>
                    <tr>
                        <th>Id</th>
                        <th>Title</th>
                        <th>Tournament</th>
                        <th>Sport</th>
                        <th>Is featured</th>
                        <th>Start Date</th>
                        <th>Status</th>
                        <th>Live</th>
                    </tr>
                </thead>

                {/* <tbody id={loading ? 'cover-spin' : ''}> */}
                <tbody>
                    {
                        !isGetMatchesLoading && sportsMatchesInfo?.rows?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.matchId}</td>
                                    <td>
                                        <span
                                            style={{
                                                width: '100px',
                                                cursor: 'pointer',
                                                textDecoration: 'underline',
                                                color: '#005fff'
                                            }}
                                            onClick={() =>
                                                navigate(`/sports/matches/detail/${item.matchId}`)}
                                        >
                                            {`${item?.teams?.[0]?.team?.teamName?.[0]?.name} Vs ${item?.teams?.[0]?.team?.teamName?.[1]?.name}`}
                                        </span>

                                    </td>
                                    <td>{item?.tournaments?.tournamentName?.[0]?.name}</td>
                                    <td>{item?.sportCountry?.sport?.sportName?.[0]?.name}</td>
                                    <td>
                                        <span>
                                            <Form.Check
                                                name='status'
                                                type='switch'
                                                checked={item.isFeatured}
                                                value={item.isFeatured}
                                                onChange={() => handleStatusChange(item)}
                                            />
                                        </span>
                                    </td>
                                    <td>{getDateTime(item?.matchDate)}</td>
                                    <td>{item?.status}</td>
                                    <td>{item?.isLive ? 'Yes' : 'No'}</td>
                                </tr>
                            )
                        })
                    }
                    {
                        isGetMatchesLoading &&
                        <tr>
                            <td colSpan="5">
                                <NoDataContainer>
                                    <DivLoader />
                                </NoDataContainer>
                            </td>
                        </tr>

                    }

                </tbody>

                {/* {countries?.count === 0 && !loading &&
							(
							<tr>
								<td
								colSpan={5}
								className='text-danger text-center'
								>
								No data found
								</td>
							</tr>
							)} */}
            </Table>
            {
                sportsMatchesInfo?.totalPage !== 0 && !isGetMatchesLoading &&
                (
                    <PaginationComponent
                        page={sportsMatchesInfo?.sportsList?.count < pageNo ? setPageNo(1) : pageNo}
                        totalPages={totalPages}
                        setPage={setPageNo}
                        limit={limit}
                        setLimit={setLimit}
                    />
                )
            }
        </div>
    )
};

export default SportsListTable;