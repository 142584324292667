export const tableHeaders = [
  { label: 'Game ID', value: 'casinoGameId' },
  { label: 'Order ID', value: 'orderId' },
  { label: 'Nome', value: 'name' },
  { label: 'Provider', value: 'casionoprovider' },
  // { label: 'RTP', value: 'returnToPlayer' },
  { label: 'Sub Categoria', value: 'casinosubcat' },
  { label: 'Thumbnail', value: 'thumbnail' },
  { label: 'Tipo de dispositivo', value: 'devices' },
  { label: 'Status', value: 'status' },
  // { label: 'Operator Status', value: 'operatorstatus' },
  { label: 'Action', value: 'action' }
]
