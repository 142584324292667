import { takeLatest, put } from 'redux-saga/effects'
import {
  getAllSuperAdminTransactionsStart,
  getAllSuperAdminTransactionsSuccess,
  getAllSuperAdminTransactionsFailure,
  getSuperAdminCasinoTransactionsStart,
  getSuperAdminCasinoTransactionsSuccess,
  getSuperAdminCasinoTransactionsFailure,
  getSuperAdminSportsTransactionsSuccess,
  getSuperAdminSportsTransactionsFailure,
  getSuperAdminSportsTransactionsStart
} from '../redux-slices/superAdminTransactions'
import {
  getAllSuperAdminTransactions,
  getSuperAdminCasinoTransactions,
  getSuperAdminSportsTransactions
} from '../../utils/apiCalls'
import { toast } from '../../components/Toast'

export default function * SuperAdminTransactionsWatcher () {
  yield takeLatest(getAllSuperAdminTransactionsStart.type, getAllSuperAdminTransactionsWorker)
  yield takeLatest(getSuperAdminCasinoTransactionsStart.type, getSuperAdminCasinoTransactionsWorker)
  yield takeLatest(getSuperAdminSportsTransactionsStart.type, getSuperAdminSportsTransactionsWorker)

}

function * getAllSuperAdminTransactionsWorker (action) {
  try {
    const {
      limit, pageNo, search, startDate, endDate, currencyId, transactionType, tenantId, adminId, paymentProvider, isUserDetail,
      userId, status
    } =
      action && action.payload

    const { data } = yield getAllSuperAdminTransactions({
      limit,
      pageNo,
      search,
      startDate,
      endDate,
      currencyId,
      transactionType,
      tenantId,
      adminId,
      paymentProvider,
      isUserDetail,
      userId,
      status
    })

    yield put(getAllSuperAdminTransactionsSuccess(data?.data?.transactionDetail))
  } catch (e) {
    yield put(getAllSuperAdminTransactionsFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}


function * getSuperAdminSportsTransactionsWorker (action) {
  try {
    const {
      status,
      email,
      limit,
      pageNo,
      startDate,
      endDate,
      currencyCode,
      transactionType,
      tenantId,
      adminId,
      userId,
      actionType
    } =
      action && action.payload

    const { data } = yield getSuperAdminSportsTransactions({
      limit,
      pageNo,
      startDate,
      endDate,
      currencyCode,
      transactionType,
      tenantId,
      status,
      email,
      adminId,
      userId,
      actionType
    })

    yield put(getSuperAdminSportsTransactionsSuccess(data?.data?.sportsTransaction))
  } catch (e) {
    yield put(getSuperAdminSportsTransactionsFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getSuperAdminCasinoTransactionsWorker (action) {
  try {
    const {
      status,
      email,
      limit,
      pageNo,
      startDate,
      endDate,
      currencyCode,
      transactionType,
      tenantId,
      adminId,
      userId
    } =
      action && action.payload

    const { data } = yield getSuperAdminCasinoTransactions({
      limit,
      pageNo,
      startDate,
      endDate,
      currencyCode,
      transactionType,
      tenantId,
      status,
      email,
      adminId,
      userId
    })

    yield put(getSuperAdminCasinoTransactionsSuccess(data?.data?.transactionDetail))
  } catch (e) {
    yield put(getSuperAdminCasinoTransactionsFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}
