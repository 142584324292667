import React from 'react';
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Button } from '@themesberg/react-bootstrap'
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import SpeakerNotesOffOutlinedIcon from '@mui/icons-material/SpeakerNotesOffOutlined';

const ActionMenu = (props) => {
  const {
    item,
    toggleModal,
    // setMarketId,
    setMatchMarketId,
    toggleDetachMarketModal,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl)

  const handleClick = event => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <Button
        variant='btn text-btn'
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MoreVertRoundedIcon />
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem onClick={handleClose}>
          <Button
            variant='btn text-btn'
            className="ms-1"
            onClick={(event) => {
              event.stopPropagation()
              toggleModal()
              setMatchMarketId(item.matchMarketId)
              handleClose()
            }}
            style={{ maxWidth: "180px" }}
          >
            <EditOutlinedIcon />
            <span className='text'>Odds Variation</span>
          </Button>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Button
            variant='btn text-btn'
            className="ms-1"
            onClick={(event) => {
              event.stopPropagation()
              toggleDetachMarketModal();
              setMatchMarketId(item.matchMarketId)
              handleClose()
            }}
            style={{ maxWidth: "180px" }}
          >
            {item.detach ?
              <SpeakerNotesIcon /> : <SpeakerNotesOffOutlinedIcon />
            }
            <span className='text'>Detach Market</span>
          </Button>
        </MenuItem>
      </Menu>
    </>
  )
};

export default ActionMenu;