import React from 'react'
import {
  Button,
  Row,
  Col
} from '@themesberg/react-bootstrap'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Preloader from '../../../../components/Preloader'
import useReorderSubCategories from '../useReorderCategories'

export default () => {
  const {
    loading,
    state, onDragEnd, handleSave
  } = useReorderSubCategories()

  return (
    <>
      {loading
        ? <Preloader />
        : (
          <>
            <Row>
              <Col sm={8}>
                <h3>Casino Categories Reorder</h3>
              </Col>

              <Col>
                <div className='text-right mb-3'>
                  <Button
                    variant='outline-success'
                    onClick={() => handleSave()}
                  >
                    Save
                  </Button>
                </div>
              </Col>
            </Row>

            <div className='reorder-heading'>
              {[
                'ID',
                'Name',
                'Status'
              ].map((h) => (
                <p key={h}>{h}</p>
              ))}
            </div>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='list'>
                {provided => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {/* <QuoteList quotes={state.quotes} /> */}
                    {state.count > 0 &&
                      state?.rows?.map(
                        ({

                          name,
                          gameCategoryId,
                          isActive

                        }, idx) => {
                          let casinoCategory
                          try {
                            casinoCategory = JSON.parse(name)?.EN
                          } catch (error) {
                            casinoCategory = name
                          }
                          return (
                            <Draggable draggableId={`id-${idx}`} key={idx} index={idx}>
                              {provided => (
                                <div
                                  className='reorder-content'
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <p>{gameCategoryId}</p>
                                  <p>
                                    {casinoCategory}
                                  </p>

                                  <p>
                                    {isActive
                                      ? (
                                        <span className='text-success'>Active</span>
                                      )
                                      : (
                                        <span className='text-danger'>In Active</span>
                                      )}
                                  </p>
                                </div>
                              )}
                            </Draggable>
                          )
                        })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            {state.count === 0
              ? <p className='text-danger text-center'>No data found</p>
              : null}
          </>
        )}
    </>
  )
}
