import React, { useEffect } from 'react'
import { Table } from '@themesberg/react-bootstrap'
import { getPercentageColor, getTextColor } from '../../utils/dashboardEffects'
import { defaultCurrency } from '../../utils/constant'
import FormatNumber from '../../utils/formatNumber'
const ProviderReport = ({ tableData, isPlayer, setPage }) => {
  useEffect(() => {
    setPage(1)
  }, [])
  return (
    <div style={{ overflow: 'auto', maxHeight: '385px' }}>
      <Table striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-light'>
          <tr>
            {isPlayer
              ? ['ID',
                'Nome',
                'Números de Rodadas',
                'Total de Apostas',
                'Total de Prêmios',
                'Game Revenue',
                'Payout'
              ].map((h) => (
                <th key={h}>{h}</th>
              ))
              : [
                'ID',
                'Quantidade de usuários',
                'Quantidade de Jogos',
                'Valores das Apostas',
                'Quantidade de Apostas',
                'Valores dos Prêmios',
                'Quantidade de Prêmios',
                'GGR',
              ].map((h) => (
                <th key={h}>{h}</th>
              ))}
          </tr>
        </thead>

        <tbody>
          {tableData && tableData.length > 0
            ? tableData.map(({ casinoProviderId, userCount, gameCount, betAmount, betCount, winAmount, winCount, ggr }, idx) => {
              return (
                <tr key={`top-loosers ${casinoProviderId}`}>
                  <td>{casinoProviderId}</td>
                  <td>
                    {userCount}
                  </td>
                  <td>{gameCount || '0'}</td>
                  <td>{FormatNumber.currency(betAmount || 0)}</td>
                  <td>{betCount || '0'}</td>
                  <td>{FormatNumber.currency(winAmount || 0)}</td>
                  <td>{winCount || '0'}</td>
                  <td className={`${getTextColor(ggr)}`}>{FormatNumber.currency(ggr || 0)}</td>
                  {/* {!isPlayer && <td>{playerCount || '0'}</td>}
                  <td className={getPercentageColor(payout)}>{payout || '0'}</td> */}
                </tr>
              )
            })
            : (
              <tr>
                <td colSpan={7} className='text-danger text-center'>
                  Registros não encontrados
                </td>
              </tr>
            )}
        </tbody>
      </Table>
    </div>
  )
}

export default ProviderReport
