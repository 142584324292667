import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from '../../../../components/Toast'
import {
  createEmailTemplateStart,
  getemailTemplateStart,
  resetEmailTemplate,
  updateEmailTemplateStart,
  getDynamicKeysStart,
  getImageGalleryStart,
  testEmailTemplateStart,
  deleteEmailTemplateLanguageStart,
  getEmailTypesStart
} from '../../../../store/redux-slices/emailTemplate'
import { getAllTenantsListStart } from '../../../../store/redux-slices/tenants'
import useCheckPermission from '../../../../utils/checkPermission'
import { getLanguagesStart } from '../../../../store/redux-slices/languages'

const useEditEmailTemplate = ({ isEdit }) => {
  const { emailTemplateId } = useParams()
  const navigate = useNavigate()
  const { isHidden } = useCheckPermission()
  const dispatch = useDispatch()

  const { emailTemplate, loading, dynamicKeys, testTemplateLoading } = useSelector(
    (state) => state.emailTemplate
  )
  const { tenantsList } = useSelector((state) => state.tenants)
  const { emailTypes } = useSelector((state) => state.emailTemplate)
  const { languages } = useSelector((state) => state.languages)
  const [requiredKeyData, setRequiredKeyData] = useState({})
  const [galleryModal, setGalleryModal] = useState(false)
  const [selectedTab, setSelectedTab] = useState('EN')
  const [show, setShow] = useState(false)
  const [data, setData] = useState({})
  const [dynamicKeyData, setDynamicKeyData] = useState({})
  const [template, setTemplate] = useState('')
  const [isTestTemplateModalVisible, setIsTestTemplateModalVisible] = useState(false)
  const [testEmail, setTestEmail] = useState('')

  const getTemplateKeys = (template) => {
    const mainKeys = []
    const keys = template.match(/{{{ *[A-Za-z0-9_-]* *}}}/g)

    // let keys = template.match(/{{{(.*)}}}/g)
    if (keys) {
      keys.forEach((key) => {
        mainKeys.push(key.replaceAll('{', '').replaceAll('}', '').trim())
      })
      return [...new Set(mainKeys)]
    } else {
      return []
    }
  }

  const updateTemplate = ({ data }) => {
    const allKeys = dynamicKeys.map((item) => item.key)
    const requiredKeys = dynamicKeys
      .filter((item) => item.required === true)
      .map((item) => item.key)

    const templateKeys = getTemplateKeys(data?.templateCode)

    if (templateKeys.length || requiredKeys.length) {
      if (allKeys.some((r) => templateKeys.includes(r))) {
        if (requiredKeys.every((v) => templateKeys.includes(v))) {
          dispatch(
            updateEmailTemplateStart({
              data: { ...data, dynamicData: templateKeys },
              navigate,
              isTenant: true
            })
          )
        } else {
          toast('Please Use All Required Dynamic Keys', 'error')
        }
      } else {
        toast('Invalid Dynamic Keys', 'error')
      }
    } else {
      dispatch(
        updateEmailTemplateStart({
          data: { ...data, dynamicData: templateKeys },
          navigate,
          isTenant: true
        })
      )
    }
  }

  const createTemplate = ({ data }) => {
    const allKeys = dynamicKeys.map((item) => item.key)
    const requiredKeys = dynamicKeys
      .filter((item) => item.required === true)
      .map((item) => item.key)

    const templateKeys = getTemplateKeys(data?.templateCode)
    if (templateKeys.length || requiredKeys.length) {
      if (allKeys.some((r) => templateKeys.includes(r))) {
        if (requiredKeys.every((v) => templateKeys.includes(v))) {
          dispatch(
            createEmailTemplateStart({
              data: { ...data, dynamicData: templateKeys },
              navigate,
              isTenant: true
            })
          )
        } else {
          toast('Please Use All Required Dynamic Keys', 'error')
        }
      } else {
        toast('Invalid Dynamic Keys', 'error')
      }
    } else {
      dispatch(
        createEmailTemplateStart({
          data: { ...data, dynamicData: templateKeys },
          navigate,
          isTenant: true
        })
      )
    }
  }

  useEffect(() => {
    if (emailTemplateId) {
      dispatch(getemailTemplateStart({ emailTemplateId, isTenant: true }))
      dispatch(getLanguagesStart({ limit: '', pageNo: '', name: "" }))
    }
  }, [])
  useEffect(() => {
    if (Object.keys(dynamicKeys).length) {
      let tempDataAll = {}
      let tempData = {}
      dynamicKeys.forEach((item) => {
        tempDataAll = { ...tempDataAll, [item.key]: item.description }
        if (item.required) {
          tempData = { ...tempData, [item.key]: item.description }
        }
      })
      setDynamicKeyData(tempDataAll)
      setRequiredKeyData(tempData)
    }
  }, [dynamicKeys])

  useEffect(() => {
    if (!isEdit) {
      emailTypes && dispatch(getDynamicKeysStart({ type: 0, emailTypes }))
      return () => {
        resetEmail()
      }
    }
  }, [emailTypes])

  const resetEmail = () => dispatch(resetEmailTemplate())

  useEffect(() => {
    if (isEdit && emailTemplate && Object.keys(emailTemplate).length) {
      emailTypes && dispatch(
        getDynamicKeysStart({ type: emailTemplate.type, isTenant: true, emailTypes })
      )
      setTemplate(emailTemplate.templateCode)
    }
  }, [emailTemplate, emailTypes])

  useEffect(() => {
    isHidden({ module: { key: 'ImageGallery', value: 'R' } }) && dispatch(getImageGalleryStart({ isTenant: true }))
    dispatch(getAllTenantsListStart())
    dispatch(getEmailTypesStart({ isTenant: true }))
  }, [])

  const testEmailTemplateHandler = (e) => {
    e.preventDefault()
    dispatch(
      testEmailTemplateStart({
        data: { templateCode: btoa(template), testEmail, dynamicData: requiredKeyData },
        isTenant: true,
        setIsTestTemplateModalVisible,
        setTestEmail
      })
    )
  }

  const deleteEmailTemplate = ({ data }) => {
    setData(data)
    setShow(true)
  }

  const handleDeleteYes = () => {
    dispatch(deleteEmailTemplateLanguageStart({ isTenant: true, data, emailTemplateId }))
    setSelectedTab('EN')
    setShow(false)
  }

  return {
    emailTemplateId,
    emailTemplate,
    loading,
    updateTemplate,
    tenantsList,
    createTemplate,
    dispatch,
    getDynamicKeysStart,
    dynamicKeys,
    template,
    setTemplate,
    galleryModal,
    setGalleryModal,
    navigate,
    isTestTemplateModalVisible,
    setIsTestTemplateModalVisible,
    testEmailTemplateHandler,
    testEmail,
    setTestEmail,
    testTemplateLoading,
    isHidden,
    selectedTab,
    setSelectedTab,
    languages,
    deleteEmailTemplate,
    show,
    setShow,
    handleDeleteYes,
    emailTypes,
    resetEmail
  }
}

export default useEditEmailTemplate
