import styled from 'styled-components'
export const NoDataContainer = styled.div`
    width: 300px;
    margin: 0px auto;
    color: white;
    padding-top: 30px;
    height: 350px;
    display: flex;
    align-items: center;
`;
