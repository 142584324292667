import React from 'react';
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded'
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded'
import {
    Button,
  } from '@themesberg/react-bootstrap'
import { makePrimaryEmailTemplatesStart } from '../../../store/redux-slices/emailTemplate';
import { useDispatch } from 'react-redux';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { DoneAllOutlined, DoneAllRounded, ForkRightRounded, ForkRightSharp } from '@mui/icons-material';
const ActionMenu = (props) => {
    const {  emailTemplateId,
    template,
    templateCode,
    type,
    isHidden,
    navigate,
    setModalData,
    handleDeleteItems} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl)
    const dispatch = useDispatch()

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    return (
        <>
            <Button
                variant="btn text-btn"
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                className='cursor-pointer'
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                >
                <MoreVertRoundedIcon />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleClose}>
                    <Button
                        variant="btn text-btn"
                        hidden={isHidden({
                        module: { key: 'EmailTemplate', value: 'U' },
                        })}
                        onClick={() =>
                          navigate(
                            `/admin/email-templates/edit/${emailTemplateId}`
                          )}
                    >
                        <BorderColorRoundedIcon />
                        <span className="text">Editar</span>
                    </Button>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Button
                    variant="btn text-btn"
                    // key={index}
                    onClick={() =>
                      setModalData(template,
                        templateCode
                      )}
                    >
                        <RemoveRedEyeRoundedIcon />
                        <span className="text">Visualizar</span>
                    </Button>
                </MenuItem>

                <MenuItem onClick={handleClose}>
                    <Button
                    variant="btn text-btn"
                    // key={index}
                    onClick={() =>
                      dispatch(
                        makePrimaryEmailTemplatesStart({
                          data: {
                            emailTemplateId,
                            type
                          }
                        })
                      )}
                    >
                        <DoneAllRounded />
                        <span className="text">Tornar o modelo primário</span>
                    </Button>
                </MenuItem>

                <MenuItem onClick={handleClose}>
                    <Button
                        variant='btn text-btn'
                        hidden={isHidden({
                            module: {
                            key: 'EmailTemplate',
                            value: 'U',
                            },
                        })}
                        onClick={() => handleDeleteItems({
                          data: {
                          emailTemplateId,
                          type
                          }
                        })}
                        >
                        <DeleteForeverOutlinedIcon />
                        <span className='text'>Delete</span>
                    </Button>
                </MenuItem>
            </Menu>
        </>
    )
};

export default ActionMenu;