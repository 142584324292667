import React from 'react'
import {
  Row,
  Col,
  Table,
  Form
} from '@themesberg/react-bootstrap'
import PaginationComponent from '../../../components/Pagination'
import Trigger from '../../../components/OverlayTrigger'
import useSubCategoryListing from './useSubCategoryListing'
import { tableHeaders } from './constants'
import ConfirmationModal from '../../../components/ConfirmationModal'
import { Button } from 'react-bootstrap'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faBan, faCheckSquare, faEdit, faPlus, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import useCheckPermission from '../../../utils/checkPermission'
import CreateCasinoCategory from './components/CreateCasinoCategory'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import { motion } from 'framer-motion/dist/framer-motion'
import ActionMenu from './ActionMenu'
import { ReorderOutlined } from '@mui/icons-material'

export default () => {
  const {
    limit,
    setLimit,
    page,
    setPage,
    loading,
    totalPages,
    gameSubCategory,
    getGameName,
    search,
    setSearch,
    active,
    setActive,
    gameCategory,
    categoryId,
    setCategoryId,
    handleClose,
    handleShow,
    handleStatusShow,
    handleYes,
    show,
    statusShow,
    setStatusShow,
    status,
    navigate,
    name,
    data,
    type,
    createCategory,
    updateCategory,
    handleDeleteItems,
    languages,
    language,
    setLangauge,
  } = useSubCategoryListing()
  const { isHidden } = useCheckPermission()

  return (
    <>
      <div className='section-header-wrap mt-n3 mx-n3'>
        <Row>
          <Col lg={8} xs={7}>
            <h2 className='title-text'>Subcategorias de cassino</h2>
          </Col>
          <Col lg={4} xs={5}>
            <div className='d-flex justify-content-end mr-2 '>
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Button
                  className='m-1'
                  variant='btn btn-primary '
                  style={{ maxWidth: '140px' }}
                  onClick={() => handleShow('Create Sub', null)}
                  hidden={isHidden({ module: { key: 'CasinoManagement', value: 'C' } })}
                >
                  <AddCircleOutlineRoundedIcon className='me-2' />
                  <span>Criar</span>
                </Button>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >

                <Button
                  className='m-1'
                  variant='btn btn-primary '
                  style={{ maxWidth: '140px' }}
                  hidden={isHidden({ module: { key: 'CasinoManagement', value: 'U' } })}
                  onClick={() => navigate('/admin/casino-sub-category/reorder')}
                >
                  <ReorderOutlined className='me-2' />
                  <span>Reodernar</span>
                </Button>
              </motion.div>
            </div>
          </Col>
        </Row>
      </div>
      <div className='FormsBg'>
        <Row>
          <Col xs={3}>
            {/* <div className='d-flex justify-content-start align-items-center w-100'> */}


            <Form.Select
              onChange={(e) => setCategoryId(e.target.value)}
              value={categoryId}
              style={{ marginBottom: '5px' }}
            >
              <option value=''>Category</option>
              {gameCategory && gameCategory?.rows?.map(({ gameCategoryId, name }) => {
                let casinoCategory
                try {
                  casinoCategory = JSON.parse(name).EN
                } catch (error) {
                  casinoCategory = name
                }
                return <option key={gameCategoryId} value={gameCategoryId}>{casinoCategory}</option>
              })}
            </Form.Select>
            {/* </div> */}
          </Col>

          <Col xs={3}>
            {/* <div className='d-flex justify-content-start align-items-center w-100'> */}


            <Form.Control
              type='search'
              value={search}
              placeholder='Pesquisar nome'
              size='sm'
              onChange={(event) => setSearch(event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, ''))}
            />
            {/* </div> */}
          </Col>

          <Col xs={3}>
            {/* <div className='d-flex justify-content-start align-items-center w-100'> */}


            <Form.Select
              onChange={(e) => setActive(e.target.value)}
              value={active}
            >
              <option value=''>Status</option>
              <option value='true'>Ativos</option>
              <option value='false'>Inativos</option>
            </Form.Select>
            {/* </div> */}
          </Col>

        </Row>
      </div>
      <div className='TableBg mt-4 pt-1'>

        <Table striped responsive hover size='sm' className='text-center mt-3'>
          <thead className='thead-light'>
            <tr>
              {tableHeaders.map((h, idx) => (
                <th
                  key={idx}
                >
                  {h.label}
                </th>
              ))}
            </tr>
          </thead>

          <tbody id={loading ? 'cover-spin' : ''}>
            {gameSubCategory && !loading &&
              gameSubCategory?.rows?.map(
                (
                  {
                    gameSubCategoryId,
                    name,
                    isActive,
                    imageUrl,
                    gameCategoryId,
                    isGlobal
                  }, index
                ) => {
                  let casinoSubCategory
                  try {
                    casinoSubCategory = JSON.parse(name)?.EN
                  } catch (error) {
                    casinoSubCategory = name
                  }
                  return (
                    <tr key={gameSubCategoryId}>
                      <td>{gameSubCategoryId}</td>

                      <td>
                        <Trigger message={casinoSubCategory}>
                          <span
                            style={{
                              width: '100px',
                              cursor: 'pointer',
                            }}
                            className="d-inline-block text-truncate"
                          >
                            {casinoSubCategory}
                          </span>
                        </Trigger>
                      </td>

                      <td>{getGameName(gameCategoryId)}</td>

                      <td>
                        <Trigger message={imageUrl}>
                          <span
                            style={{
                              width: '230px',
                              cursor: 'pointer',
                            }}
                            onClick={() => imageUrl && window.open(imageUrl)}
                            className="d-inline-block text-truncate text-link"
                          >
                            {imageUrl || 'NA'}
                          </span>
                        </Trigger>
                      </td>

                      <td>
                        {isActive ? (
                          <span className="text-success">Ativo</span>
                        ) : (
                          <span className="text-danger">Inativo</span>
                        )}
                      </td>

                      <td>
                        <ActionMenu
                          isHidden={isHidden}
                          handleShow={handleShow}
                          isActive={isActive}
                          name={name}
                          index={index}
                          isGlobal={isGlobal}
                          handleStatusShow={handleStatusShow}
                          gameSubCategory={gameSubCategory}
                          gameSubCategoryId={gameSubCategoryId}
                          handleDeleteItems={handleDeleteItems}

                        />
                        {/* {!isHidden({
                                  module: {
                                    key: 'CasinoManagement',
                                    value: 'U',
                                  },
                                }) ||
                                !isHidden({
                                  module: {
                                    key: 'CasinoManagement',
                                    value: 'T',
                                  },
                                }) ? (
                                  <ButtonGroup>
                                    <Trigger message="Edit">
                                      <Button
                                        className="m-1"
                                        size="sm"
                                        variant="warning"
                                        onClick={() =>
                                          handleShow(
                                            'Edit',
                                            gameSubCategory.rows[index],
                                          )
                                        }
                                        hidden={isHidden({
                                          module: {
                                            key: 'CasinoManagement',
                                            value: 'U',
                                          },
                                        })}
                                      >
                                        <FontAwesomeIcon icon={faEdit} />
                                      </Button>
                                    </Trigger>

                                    {!isActive ? (
                                      <Trigger message="Set Status Active">
                                        <Button
                                          className="m-1"
                                          size="sm"
                                          variant="success"
                                          onClick={() =>
                                            handleStatusShow(
                                              gameSubCategoryId,
                                              isActive,
                                              name,
                                            )
                                          }
                                          hidden={isHidden({
                                            module: {
                                              key: 'CasinoManagement',
                                              value: 'T',
                                            },
                                          })}
                                        >
                                          <FontAwesomeIcon
                                            icon={faCheckSquare}
                                          />
                                        </Button>
                                      </Trigger>
                                    ) : (
                                      <Trigger message="Set Status In-Active">
                                        <Button
                                          className="m-1"
                                          size="sm"
                                          variant="danger"
                                          onClick={() =>
                                            handleStatusShow(
                                              gameSubCategoryId,
                                              isActive,
                                              name,
                                            )
                                          }
                                          hidden={isHidden({
                                            module: {
                                              key: 'CasinoManagement',
                                              value: 'T',
                                            },
                                          })}
                                        >
                                          <FontAwesomeIcon
                                            icon={faWindowClose}
                                          />
                                        </Button>
                                      </Trigger>
                                    )}

                                    <Trigger message="Delete">
                                      <Button
                                        className="m-1"
                                        size="sm"
                                        variant="secondary"
                                        hidden={isHidden({
                                          module: {
                                            key: 'CasinoManagement',
                                            value: 'U',
                                          },
                                        })}
                                        disabled={isGlobal}
                                        onClick={() => handleDeleteItems()}
                                      >
                                        <FontAwesomeIcon icon={faTrash} />
                                      </Button>
                                    </Trigger>

                                   {!isGlobal && <Trigger message="Add Games">
                                      <Button
                                        className="m-1"
                                        size="sm"
                                        variant="secondary"
                                        hidden={isHidden({
                                          module: {
                                            key: 'CasinoManagement',
                                            value: 'U',
                                          },
                                        })}
                                        disabled={isGlobal}
                                        onClick={() => navigate(`/admin/casino-sub-category/addGames/${gameSubCategoryId}`)}
                                        >
                                        <FontAwesomeIcon icon={faPlus} />
                                      </Button>
                                    </Trigger>}


                                  </ButtonGroup>
                                ) : (
                                  'NA'
                                )} */}
                      </td>
                    </tr>
                  )
                }
              )}

            {gameSubCategory?.count === 0 && !loading &&
              (
                <tr>
                  <td
                    colSpan={5}
                    className='text-danger text-center'
                  >
                    Sem registros
                  </td>
                </tr>
              )}
          </tbody>
        </Table>
        {gameSubCategory?.count !== 0 && !loading &&
          (
            <PaginationComponent
              page={gameSubCategory?.count < page ? setPage(1) : page}
              totalPages={totalPages}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
            />
          )}
      </div>
      {statusShow && <ConfirmationModal
        setShow={setStatusShow}
        show={statusShow}
        handleYes={handleYes}
        active={status}
        name={name}
      />}

      {show && <CreateCasinoCategory
        handleClose={handleClose}
        data={data}
        type={type}
        show={show}
        gameCategory={gameCategory}
        loading={loading}
        createProvider={createCategory}
        updateProvider={updateCategory}
        languages={languages}
        language={language}
        setLangauge={setLangauge}
      />}

    </>
  )
}
