import {
  Button,
  Row,
  Col,
  Table,
  ButtonGroup
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion } from 'framer-motion/dist/framer-motion'
import React from 'react'
import PaginationComponent from '../../../components/Pagination'
import ConfirmationModal from '../../../components/ConfirmationModal'
import {
  faCheckSquare,
  faWindowClose
} from '@fortawesome/free-solid-svg-icons'
import Trigger from '../../../components/OverlayTrigger'
import useAggregatorListing from './useAggregatorListing'
import CreateAggregator from './components/CreateAggregator'
import useCheckPermission from '../../../utils/checkPermission'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import ActionMenu from './ActionMenu'

export default () => {
  const {
    aggregators,
    limit,
    setLimit,
    page,
    setPage,
    totalPages,
    handleStatusShow,
    handleYes,
    statusShow,
    setStatusShow,
    show,
    handleClose,
    handleShow,
    loading,
    status,
    createAggregator,
    name
  } = useAggregatorListing()
  const { isHidden } = useCheckPermission()

  return (
    <>
      <div className='section-header-wrap mt-n3 mx-n3'>
        <Row>
          <Col lg={8} xs={7}>
            <h2 className='title-text'>Agregadores de cassino</h2>
          </Col>

          {/* <Col lg={4} xs={5}>
            <div className='d-flex justify-content-end'>
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Button
                  variant='btn btn-primary w-100'
                  onClick={() => handleShow()}
                  style={{ maxWidth: '140px' }}
                  hidden={isHidden({ module: { key: 'CasinoManagement', value: 'C' } })}
                >
                  <AddCircleOutlineRoundedIcon className='me-2' />
                  <span>Create</span>
                </Button>
              </motion.div>
            </div>
          </Col> */}
        </Row>
      </div>
      <div className='TableBg mt-4 pt-1'>


        <Table striped responsive hover size='sm' className='text-center mt-3'>
          <thead className='thead-light'>
            <tr>
              <th>ID</th>
              <th>Nome</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody id={loading ? 'cover-spin' : ''}>
            {aggregators && !loading &&
              aggregators?.rows?.map(
                (
                  {
                    name,
                    gameAggregatorId,
                    isActive
                  }
                ) => {
                  return (
                    <tr key={gameAggregatorId}>
                      <td>{gameAggregatorId}</td>
                      <td>
                        <Trigger message={name}>
                          <span
                            style={{
                              width: '200px',
                              cursor: 'pointer'
                            }}
                            className='d-inline-block text-truncate'
                          >
                            {name}
                          </span>
                        </Trigger>
                      </td>

                      <td>
                        {isActive
                          ? (
                            <span className='text-success'>Active</span>
                          )
                          : (
                            <span className='text-danger'>In Active</span>
                          )}
                      </td>

                      <td>
                        {!isHidden({ module: { key: 'CasinoManagement', value: 'T' } })
                          ? (
                            <ActionMenu
                              status={status}
                              gameAggregatorId={gameAggregatorId}
                              isActive={isActive}
                              name={name}
                              handleStatusShow={handleStatusShow}
                              isHidden={isHidden}
                            />)
                          // <ButtonGroup>
                          //   {!isActive
                          //     ? (
                          //       <Trigger message='Set Status Active'>
                          //         <Button
                          //           className='m-1'
                          //           size='sm'
                          //           variant='success'
                          //           onClick={() =>
                          //             handleStatusShow(
                          //               gameAggregatorId,
                          //               isActive,
                          //               name
                          //             )}
                          //           hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                          //         >
                          //           <FontAwesomeIcon icon={faCheckSquare} />
                          //         </Button>
                          //       </Trigger>
                          //       )
                          //     : (
                          //       <Trigger message='Set Status In-Active'>
                          //         <Button
                          //           className='m-1'
                          //           size='sm'
                          //           variant='danger'
                          //           onClick={() =>
                          //             handleStatusShow(
                          //               gameAggregatorId,
                          //               isActive,
                          //               name
                          //             )}
                          //           hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                          //         >
                          //           <FontAwesomeIcon icon={faWindowClose} />
                          //         </Button>
                          //       </Trigger>
                          //       )}
                          // </ButtonGroup>)
                          : 'NA'}
                      </td>
                    </tr>
                  )
                }
              )}

            {aggregators?.count === 0 && !loading &&
              (
                <tr>
                  <td
                    colSpan={5}
                    className='text-danger text-center'
                  >
                    No data found
                  </td>
                </tr>
              )}
          </tbody>
        </Table>
        {aggregators?.count !== 0 && !loading &&
          (
            <PaginationComponent
              page={aggregators?.count < page ? setPage(1) : page}
              totalPages={totalPages}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
            />
          )}
      </div>
      <ConfirmationModal
        setShow={setStatusShow}
        show={statusShow}
        handleYes={handleYes}
        active={status}
        name={name}
      />
      <CreateAggregator
        handleClose={handleClose}
        show={show}
        createAggregator={createAggregator}
        loading={loading}
      />
    </>
  )
}
