import React, { useEffect, useState } from 'react'
import {
  Form as BForm,
  Button,
  Col,
  InputGroup,
  Row
} from '@themesberg/react-bootstrap'
import { ErrorMessage } from 'formik'
import 'react-quill/dist/quill.snow.css'
import { useDispatch, useSelector } from 'react-redux'
import Trigger from '../../../../../components/OverlayTrigger'
import { getAllBonusStart } from '../../../../../store/redux-slices/admins'
import { bonusType, BONUS_TYPE_CONSTANTS, checkLabels } from './constants'
import ReactQuill from 'react-quill'

const GeneralForm = ({
  isEdit,
  isClone,
  touched,
  errors,
  values,
  handleChange,
  handleSubmit,
  handleBlur,
  setFieldValue,
  bonusDetail,
  navigate,
  setGameIds,
  setEnableSubmit,
  appliedBonusOptions,
  data,
  setData,
  handleImagePreview,
  preview
}) => {
  const { bonusList } = useSelector((state) => state.admins)
  const dispatch = useDispatch()

  // const isAlreadyExistsBonus = (type) => {
  //   return bonusList?.rows.find((bonus)=>{
  //     return bonus.bonusType === 
  //   })
  // }

  const isAlreadyLoyaltyBonusExist = (bonusType)=>{
    if(bonusType === BONUS_TYPE_CONSTANTS.LOYALTY){
      return bonusList?.rows?.find((bonus)=>bonus.bonusType === BONUS_TYPE_CONSTANTS.LOYALTY)
    }else{
      return false
    }
  }

  useEffect(() => {
    dispatch(getAllBonusStart({
      limit: '15',
      pageNo: 1,
      search: '',
      bonusType: '',
      isActive: ''
    }))
  }, [])

  useEffect(() => {
    if ((isEdit) && appliedBonusOptions?.[0] && appliedBonusOptions?.[0]?.label?.EN !== '') {
      setFieldValue('appliedBonusId', appliedBonusOptions.filter(
        (opt) => JSON.parse(opt.value) === JSON.parse(bonusDetail?.appliedBonusId)
      ))
    }
  }, [appliedBonusOptions])

  useEffect(() => {
    if (bonusDetail?.gameIds && setGameIds) {
      setGameIds(bonusDetail?.gameIds)
    }
  }, [bonusDetail])
  const [err, setErr] = useState('')
  const [errDesc, setErrDesc] = useState('')
  return (
    <div className='FormsBg'>
      <Row className=' mt-3 mb-3'>
        <Col sm={4} className='mb-3'>
          <label
            htmlFor='promotionTitle'
            className={
              touched.promotionTitle && errors.promotionTitle
                ? 'text-danger'
                : ''
            }
          >
            Promotion Title<span className='text-danger'> *</span>
          </label>
          <InputGroup
            className={
              touched.promotionTitle && errors.promotionTitle
                ? 'border border-danger'
                : ''
            }
          >
            <BForm.Control
              name='promotionTitle'
              placeholder='Enter Promotion Title'
              defaultValue={values.promotionTitle}
              onInput={handleChange}
              onBlur={handleBlur}
              onChange={(e) => {
                setData({
                  ...data,
                  promoTitle: {
                    ...data?.promoTitle,
                    EN: e.target.value
                  }
                })
                handleChange(e)
              }}
            />
          </InputGroup>
          <ErrorMessage
            component='div'
            name='promotionTitle'
            className='text-danger'
          />
        </Col>

        <Col sm={4} className='mb-3'>
          <label
            htmlFor='bonusType'
            className={
              touched.bonusType && errors.bonusType ? 'text-danger' : ''
            }
          >
            Bonus Type<span className='text-danger'> *</span>
          </label>
          <InputGroup
            className={
              touched.bonusType && errors.bonusType
                ? 'border border-danger'
                : ''
            }
          >
            <BForm.Select
              name='bonusType'
              disabled={isEdit || isClone}
              onInput={handleChange}
              onBlur={handleBlur}
              onChange={(e) => {
                handleChange(e)
                setGameIds([])
                if (e.target.value === BONUS_TYPE_CONSTANTS.JOINING) {
                  setFieldValue('isSticky', false)
                }
                setFieldValue('currency', {
                  BRL: {
                    // maxBonusThreshold: '',
                    minDeposit: '',
                    maxWinAmount: '',
                    // zeroOutThreshold: '',
                    minBalance: '',
                    joiningAmount: ''
                  }
                })
                setEnableSubmit(false)
                setFieldValue('visibleInPromotions', false)
                setFieldValue('wageringRequirementType', 'bonus')
                if (e.target.value === BONUS_TYPE_CONSTANTS.LOYALTY) {
                  setFieldValue('currency', {
                    BRL: { coinEquivalentTo: '' },
                  })
                }
                if(e.target.value !== BONUS_TYPE_CONSTANTS.JOINING){
                  setFieldValue('isVisibleOnBanner',false)
                }
              }}
              value={values.bonusType}
            >
              <option value='' disabled key=''>
                Select Bonus Type
              </option>
              {bonusType.map((item) => {
                if (isAlreadyLoyaltyBonusExist(item.value)) return false
                else {
                  return (
                    <option key={`bonusType ${item.id}`} value={item.value}>
                      {item.label}
                    </option>
                  )
                }
              })}
            </BForm.Select>
          </InputGroup>
          <ErrorMessage
            component='div'
            name='bonusType'
            className='text-danger'
          />
        </Col>


        <Col sm={4} className='mb-3'>
          <label
            htmlFor='daysToClear'
            className={
              touched.daysToClear && errors.daysToClear ? 'text-danger' : ''
            }
          >
            Days To Clear<span className='text-danger'> *</span>
          </label>
          <InputGroup
            className={
              touched.daysToClear && errors.daysToClear
                ? 'border border-danger'
                : ''
            }
          >
            <BForm.Control
              name='daysToClear'
              type='number'
              min={1}
              // disabled={isEdit}
              placeholder='Enter Days To Clear'
              value={values.daysToClear}
              onInput={handleChange}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputGroup>
          <ErrorMessage
            component='div'
            name='daysToClear'
            className='text-danger'
          />
        </Col>

      </Row>

      <Row>

        <Col sm={4} className='mb-3'>
          <label
            htmlFor='wageringMultiplier'
            className={
              touched.wageringMultiplier && errors.wageringMultiplier
                ? 'text-danger'
                : ''
            }
          >
            Wagering Multiplier<span className='text-danger'> *</span>
          </label>
          <InputGroup
            className={
              touched.wageringMultiplier && errors.wageringMultiplier
                ? 'border border-danger'
                : ''
            }
          >
            <BForm.Control
              name='wageringMultiplier'
              type='number'
              min={1}
              placeholder='Enter Wagering Multiplier'
              value={values.wageringMultiplier}
              onInput={handleChange}
              onBlur={handleBlur}
              onChange={(e) => {
                handleChange(e)

              }}
            />
          </InputGroup>
          <ErrorMessage
            component='div'
            name='wageringMultiplier'
            className='text-danger'
          />
        </Col>
        {
         values.bonusType ===  BONUS_TYPE_CONSTANTS.JOINING && <Col xs={6} className='p-2'>
            <BForm.Label>Visible On Banner - (NOTE: Any old joining bonus will become invisble if this bonus will be visble on banner)</BForm.Label>
            <BForm.Check
              name={'isVisibleOnBanner'}
              type='switch'
              checked={values.isVisibleOnBanner}
              value={values.isAcisVisibleOnBannertive}
              onChange={(e) => handleChange(e, '')}
            />

          </Col>
        }

      </Row>

      {[BONUS_TYPE_CONSTANTS.SPORTS_DEPOSIT, BONUS_TYPE_CONSTANTS.CASINO_DEPOSIT, BONUS_TYPE_CONSTANTS.JOINING].includes(values.bonusType) && (
        <Row>
          <Col sm={4} className='mb-3'>
            <label
              htmlFor='depositBonusPercent'
              className={
                touched.depositBonusPercent && errors.depositBonusPercent
                  ? 'text-danger'
                  : ''
              }
            >
              Bonus Percentage
              <span className='text-danger'> *</span>
            </label>
            <InputGroup
              className={
                touched.depositBonusPercent && errors.depositBonusPercent
                  ? 'border border-danger'
                  : ''
              }
            >
              <BForm.Control
                name='depositBonusPercent'
                type='number'
                min={1}
                placeholder={'Enter Bonus Percentage'}
                defaultValue={values.depositBonusPercent}
                onInput={handleChange}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </InputGroup>
            <ErrorMessage
              component='div'
              name='depositBonusPercent'
              className='text-danger'
            />
          </Col>
          <Col sm={4} className='mb-3'>
            <label
              htmlFor='depositBonusCode'
              className={
                touched?.bonusCode && errors?.bonusCode
                  ? 'text-danger'
                  : ''
              }
            >
              Bonus Code<span className='text-danger'> *</span>
            </label>
            <InputGroup
              className={
                touched?.bonusCode && errors?.bonusCode
                  ? 'border border-danger'
                  : ''
              }
            >
              <BForm.Control
                id='bonusCode'
                name={`bonusCode`}
                type='text'
                placeholder='Enter Bonus Code'
                value={values?.bonusCode}
                onInput={handleChange}
                onBlur={handleBlur}
                onChange={(e) => {
                  console.log('e', e)
                  e.target.value = e.target.value.toUpperCase();
                  handleChange(e);
                }}
              />
            </InputGroup>
            <ErrorMessage
              component='div'
              name={`bonusCode`}
              className='text-danger'
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          <label
            htmlFor='bonusImage'
            className={
              touched.bonusImage && errors.bonusImage ? 'text-danger' : ''
            }
          >
            Bonus Image<span className='text-danger'> *</span>
          </label>
          <InputGroup
            className={
              touched.bonusImage && errors.bonusImage
                ? 'border border-danger'
                : ''
            }
          >
            <BForm.Control
              name='bonusImage'
              type='file'
              placeholder='Image'
              onInput={handleChange}
              onBlur={handleBlur}
              onChange={(event) => {
                setFieldValue('bonusImage', event.currentTarget.files[0])
                handleImagePreview(event)
              }}
            />
          </InputGroup>
          <ErrorMessage
            component='div'
            name='bonusImage'
            className='text-danger'
          />
        </Col>
        <Col>
          {
            !errors.bonusImage &&
            (preview?.image_preview
              ? (
                <img
                  src={values?.bonusImage ? (preview?.image_preview) : `${preview?.image_preview}`}
                  width='150'
                  height='150'
                  className='mt-2 border-0'
                  alt='bonus-img'
                />
              )
              : (
                bonusDetail &&
                bonusDetail?.imageUrl && !isClone && (
                  <img
                    src={bonusDetail?.imageUrl}
                    width='150'
                    height='150'
                    alt='bonus-img'
                    className='mt-2 border-0'
                    id='fgfgr'
                  />
                )
              ))}
        </Col>
      </Row>

      <Row className='mb-3'>
        <Col>
          <label
            htmlFor='termCondition'
            className={
              ((touched?.termCondition && (err || errors.termCondition))) ? 'text-danger' : ''
            }
          >
            Terms & Conditions<span className='text-danger'> *</span>
          </label>
          <ReactQuill
            name='termCondition'
            placeholder='Enter Terms and Conditions'
            value={values.termCondition}
            onChange={(e) => {
              setFieldValue('termCondition', e.replace(/\s/g, '&nbsp;'))
              setData({
                ...data,
                terms: {
                  ...data?.terms,
                  EN: e.replace(/\s/g, '&nbsp;')
                }
              })
            }}
            onKeyDown={() => setErr('')}
          />

          {err &&
            <div className='text-danger'>{err}</div>}

          {!err &&
            <ErrorMessage
              component='div'
              name='termCondition'
              className='text-danger'
            />}
        </Col>
      </Row>



      <Row className='mb-3'>
        <Col>
          <label
            htmlFor='description'
            className={
              ((touched?.description && (errDesc || errors.description))) ? 'text-danger' : ''
            }
          >
            Description<span className='text-danger'> *</span>
          </label>
          <ReactQuill
            name='description'
            placeholder='Enter Description'
            value={values.description}
            onChange={(e) => {
              setFieldValue('description', e.replace(/\s/g, '&nbsp;'))
              setData({
                ...data,
                desc: {
                  ...data?.desc,
                  EN: e.replace(/\s/g, '&nbsp;')
                }
              })
            }}
            onKeyDown={() => setErrDesc('')}
          />

          {errDesc &&
            <div className='text-danger'>{errDesc}</div>}

          {!errDesc &&
            <ErrorMessage
              component='div'
              name='description'
              className='text-danger'
            />}
        </Col>
      </Row>

      <div className='mt-4 d-flex justify-content-between'>
        <Button
          onClick={() => navigate(-1)}
          className='btn-danger'
        >
          Cancel
        </Button>

        <Button
          className='ml-2'
          onClick={handleSubmit}
        >
          Next
        </Button>
      </div>
    </div>
  )
}

export default GeneralForm
