const env = (key, defaultValue) => (key && process.env[key]) ? process.env[key] : defaultValue;

export const config = {
  app: {
    adminBaseUrl: env('REACT_APP_ADMIN_BASE_URL', 'https://admin.onlybets.tv/'),
    affiliateBaseUrl: env('REACT_APP_AFFILIATE_BASE_URL', 'https://affiliates.onlybets.tv/'),
    userBaseUrl: env('REACT_APP_AFFILIATE_REFERRAL_BASE_URL', 'https://onlybets.tv/'),
  },

}

export default config;