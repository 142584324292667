import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    getBetSettingsStart,
    addBetSettingsStart,
    updateBetSettingsStart
} from '../../../store/redux-slices/betSettingsSlice';
import { getSportsListStart } from '../../../store/redux-slices/sportsBook';

const useBetSettings = () => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [editData, setEditData] = useState({});
    const dispatch = useDispatch()
    const {
        isGetBetSettingLoading,
        betSettingsList,
        isUpdateBetSettingLoading } = useSelector(state => state.betSettings)

    const { sportsListInfo } = useSelector(state => state.sportsBook)

    const fetchData = () => {
        dispatch(getBetSettingsStart())
        dispatch(getSportsListStart({
            limit: 1,
            pageNo: 1,
            isAllListing: true
        }))
    }

    useEffect(() => {
        fetchData()
    }, []);

    const toggleAddModal = () => {
        if (showAddModal) {
            setEditData({});
        }
        setShowAddModal(!showAddModal);
    }

    return {
        isGetBetSettingLoading,
        betSettingsList,
        showAddModal,
        toggleAddModal,
        editData,
        setEditData,
        updateBetSettingsStart,
        isUpdateBetSettingLoading,
        addBetSettingsStart,
        sportsListInfo
    }
}

export default useBetSettings
