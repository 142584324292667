import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    getAllTenantUsersStart,
    getAllTenantUsersSuccess,
    getAllTenantUsersFailure,
    updateTenantUserStatusStart,
    updateTenantUserStatusSuccess,
    updateTenantUserStatusFailure,
    getTenantUserDetailsStart,
    getTenantUserDetailsSuccess,
    getTenantUserDetailsFailure,
    getUserFieldsStart,
    getUserFieldsSuccess,
    getUserFieldsFailure,
    updateUserFieldsStart,
    updateUserFieldsSuccess,
    updateUserFieldsFailure,
    createTenantUserStart,
    createTenantUserSuccess,
    createTenantUserFailure,
    updateTenantUserStart,
    updateTenantUserSuccess,
    updateTenantUserFailure,
    getAllWithdrawRequestStart,
    getAllWithdrawRequestSuccess,
    getAllWithdrawRequestFailure,
    updateTagsStart,
    updateTagsComplete,
    getDuplicateUsersStart,
    getDuplicateUsersSuccess,
    getDuplicateUsersFailure,
    updateLimitsStart,
    updateLimitsComplete,
    disableUserStart,
    disableUserComplete,
    updateUserAffiliateStart,
    updateUserAffiliateComplete,
    addAffiliateUserStart,
    addAffiliateUserComplete,
    verifyUserEmailStart,
    verifyUserEmailComplete,
    resetPasswordEmailStart,
    resetPasswordEmailComplete,
    resetUserPasswordStart,
    resetUserPasswordComplete,
    updateUserStart,
    updateUserComplete,
    getCashbackDataStart,
    getCashbackDataComplete,
    getCashbackDataFailure,
    approveWithdrawRequestStart,
    approveWithdrawRequestSuccess,
    approveWithdrawRequestFailure,
    cancelWithdrawRequestStart,
    cancelWithdrawRequestSuccess,
    cancelWithdrawRequestFailure,
    addLoyaltyPointsToUserStart,
    addLoyaltyPointsToUserSuccess,
    addLoyaltyPointsToUserFailure
  },
  reducer
} = createSlice({
  name: 'tenantUsers',
  initialState: {
    error: null,
    loading: false,
    allUsers: null,
    user: null,
    userFields: null,
    withdrawRequests: null,
    tags: [],
    duplicateUsers: [],
    duplicateLoading: false,
    limitsLoading: false,
    cashBackData: {},
    cashBackLoading: false,
    isUpdateUserLoading: false,
    cancelWithdrawRequestLoader: false,
  },
  reducers: {
    getAllTenantUsersStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getAllTenantUsersSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      allUsers: payload,
      user: null
    }),
    getAllTenantUsersFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    updateTenantUserStatusStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    updateTenantUserStatusSuccess: (state) => ({
      ...state,
      loading: false
    }),
    updateTenantUserStatusFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    getTenantUserDetailsStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getTenantUserDetailsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      user: payload
    }),
    getTenantUserDetailsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    getUserFieldsStart: (state) => ({
      ...state,
      loading: true
    }),
    getUserFieldsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      userFields: payload
    }),
    getUserFieldsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    updateUserFieldsStart: (state) => ({
      ...state,
      loading: true
    }),
    updateUserFieldsSuccess: (state) => ({
      ...state,
      loading: false
    }),
    updateUserFieldsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    createTenantUserStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    createTenantUserSuccess: (state) => ({
      ...state,
      loading: false
    }),
    createTenantUserFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    updateTenantUserStart: (state) => ({
      ...state,
      loading: true
    }),
    updateTenantUserSuccess: (state) => ({
      ...state,
      loading: false
    }),
    updateTenantUserFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    getAllWithdrawRequestStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllWithdrawRequestSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      withdrawRequests: payload
    }),
    getAllWithdrawRequestFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateTagsStart: (state) => ({
      ...state,
      loading: true
    }),
    updateTagsComplete: (state) => ({
      ...state,
      loading: false
    }),
    getDuplicateUsersStart: (state) => ({
      ...state,
      duplicateLoading: true
    }),
    getDuplicateUsersSuccess: (state, { payload }) => ({
      ...state,
      duplicateLoading: false,
      duplicateUsers: payload
    }),
    getDuplicateUsersFailure: (state) => ({
      ...state,
      duplicateLoading: false
    }),
    updateLimitsStart: (state) => ({
      ...state,
      limitsLoading: true
    }),
    updateLimitsComplete: (state) => ({
      ...state,
      limitsLoading: false
    }),
    disableUserStart: (state) => ({
      ...state,
      limitsLoading: true
    }),
    disableUserComplete: (state) => ({
      ...state,
      limitsLoading: false
    }),
    updateUserAffiliateStart: (state) => ({
      ...state,
      updateUserAffiliateLoading: true
    }),
    updateUserAffiliateComplete: (state) => ({
      ...state,
      updateUserAffiliateLoading: false
    }),
    addAffiliateUserStart: (state) => ({
      ...state,
      addUserAffiliateLoading: true
    }),
    addAffiliateUserComplete: (state) => ({
      ...state,
      addUserAffiliateLoading: false
    }),
    verifyUserEmailStart: (state) => ({
      ...state,
      loading: true
    }),
    verifyUserEmailComplete: (state) => ({
      ...state,
      loading: false
    }),
    resetPasswordEmailStart: (state) => ({
      ...state,
      loading: true
    }),
    resetPasswordEmailComplete: (state) => ({
      ...state,
      loading: false
    }),
    resetUserPasswordStart: (state) => ({
      ...state,
      loading: true
    }),
    resetUserPasswordComplete: (state) => ({
      ...state,
      loading: false
    }),
    updateUserStart: (state) => ({
      ...state,
      isUpdateUserLoading: true
    }),
    updateUserComplete: (state) => ({
      ...state,
      isUpdateUserLoading: false
    }),
    getCashbackDataStart: (state) => ({
      ...state,
      cashBackLoading: true
    }),
    getCashbackDataComplete: (state, { payload }) => ({
      ...state,
      cashBackLoading: false,
      cashBackData: payload
    }),
    getCashbackDataFailure: (state) => ({
      ...state,
      cashBackLoading: false
    }),
    approveWithdrawRequestStart: (state) => ({
      ...state
    }),
    approveWithdrawRequestSuccess: (state) => ({
      ...state
    }),
    approveWithdrawRequestFailure: (state) => ({
      ...state
    }),
    cancelWithdrawRequestStart: (state) => ({
      ...state,
      cancelWithdrawRequestLoader: true,
    }),
    cancelWithdrawRequestSuccess: (state) => ({
      ...state,
      cancelWithdrawRequestLoader: false,
    }),
    cancelWithdrawRequestFailure: (state) => ({
      ...state,
      cancelWithdrawRequestLoader: false,
    }),
    addLoyaltyPointsToUserStart:(state)=>({
      ...state,
      loading: true
    }),
    addLoyaltyPointsToUserSuccess:(state)=>({
      ...state,
      loading: false
    }),
    addLoyaltyPointsToUserFailure:(state,{payload})=>({
      ...state,
      loading: false,
      error:payload
    })
  }
})

export default reducer

export {
  getAllTenantUsersStart,
  getAllTenantUsersSuccess,
  getAllTenantUsersFailure,
  updateTenantUserStatusStart,
  updateTenantUserStatusSuccess,
  updateTenantUserStatusFailure,
  getTenantUserDetailsStart,
  getTenantUserDetailsSuccess,
  getTenantUserDetailsFailure,
  getUserFieldsStart,
  getUserFieldsSuccess,
  getUserFieldsFailure,
  updateUserFieldsStart,
  updateUserFieldsSuccess,
  updateUserFieldsFailure,
  createTenantUserStart,
  createTenantUserSuccess,
  createTenantUserFailure,
  updateTenantUserStart,
  updateTenantUserSuccess,
  updateTenantUserFailure,
  getAllWithdrawRequestStart,
  getAllWithdrawRequestSuccess,
  getAllWithdrawRequestFailure,
  approveWithdrawRequestStart,
  approveWithdrawRequestSuccess,
  cancelWithdrawRequestStart,
  approveWithdrawRequestFailure,
  cancelWithdrawRequestSuccess,
  cancelWithdrawRequestFailure,
  updateTagsStart,
  updateTagsComplete,
  getDuplicateUsersStart,
  getDuplicateUsersSuccess,
  getDuplicateUsersFailure,
  updateLimitsStart,
  updateLimitsComplete,
  disableUserStart,
  disableUserComplete,
  updateUserAffiliateStart,
  updateUserAffiliateComplete,
  addAffiliateUserStart,
  addAffiliateUserComplete,
  verifyUserEmailStart,
  verifyUserEmailComplete,
  resetPasswordEmailStart,
  resetPasswordEmailComplete,
  resetUserPasswordStart,
  resetUserPasswordComplete,
  updateUserStart,
  updateUserComplete,
  getCashbackDataStart,
  getCashbackDataComplete,
  getCashbackDataFailure,
  addLoyaltyPointsToUserStart,
  addLoyaltyPointsToUserSuccess,
  addLoyaltyPointsToUserFailure
}
