import { Col, Form, Row, Table } from '@themesberg/react-bootstrap'
import React from 'react'
import PaginationComponent from '../../../../components/Pagination'
import Preloader from '../../../../components/Preloader'
import ProviderFilter from '../../../../components/ProviderFilter'
import useWageringTepltateDetails from '../hooks/useWageringTepltateDetails'
import './wageringStyle.css'

const WageringTepltateDetails = () => {
  const {
    loading,
    wageringTemplateDetail,
    selectedProvider,
    setSelectedProvider,
    totalPages,
    limit,
    pageNo,
    setLimit,
    setPageNo,
    search,
    setSearch
  } = useWageringTepltateDetails()

  return (
    <>
      {loading
        ? <Preloader />
        : (
          <>
           <div className='section-header-wrap mt-n3 mx-n3'>
     <Row>
        <Col  lg={8} xs={7}>
          <h2 className='title-text'>Wagering Template: {wageringTemplateDetail?.name} </h2>

        </Col>
        </Row>
        </div>
        <div className='FormsBg'>
        <Row>
           
           <Col xs='12' sm='3'>
               <ProviderFilter
                 selectedProvider={selectedProvider}
                 setSelectedProvider={setSelectedProvider}
               />
           </Col>
           <Col xs='12' sm='3'>
             

               <Form.Control
                 type='search'
                 size='sm'
                 placeholder='Search Game Name'
                 value={search}
                 onChange={(e) => setSearch(e.target.value)}
               />
           </Col>
         </Row>
        </div>
         


              <div className='TableBg mt-4'>
              {wageringTemplateDetail && wageringTemplateDetail.gameDetail && wageringTemplateDetail?.gameDetail?.count !== 0 && (
              <PaginationComponent
                page={wageringTemplateDetail?.gameDetail?.count < pageNo ? setPageNo(1) : pageNo}
                totalPages={totalPages}
                setPage={setPageNo}
                limit={limit}
                setLimit={setLimit}
              />
            )}
                <Table  striped responsive hover size='sm' className='text-center scrollable'>
                  <thead className='thead-light'>
                    <tr>
                      {[
                        'Name',
                        'RTP',
                        'Default',
                        'Custom Value'
                      ].map((h) => (
                        <th key={h}>{h}</th>
                      ))}
                    </tr>
                  </thead>

                  <tbody>
                    {wageringTemplateDetail && wageringTemplateDetail.gameDetail && wageringTemplateDetail.gameDetail?.rows.length > 0
                      ? wageringTemplateDetail.gameDetail?.rows.map(({ casinoGameId, name, returnToPlayer, wageringContribution }, idx) => {
                        return (
                          <tr key={`wagering-template ${casinoGameId}`}>
                            <td>{name}</td>

                            <td>{returnToPlayer} %</td>
                            <td>{wageringContribution} %</td>
                            <td>{wageringTemplateDetail?.gameContribution?.[casinoGameId] || 100} %</td>

                          </tr>
                        )
                      })
                      : (
                        <tr>
                          <td colSpan={7} className='text-danger text-center'>
                            No data found
                          </td>
                        </tr>
                        )}
                  </tbody>
                </Table>
              </div>
             
        
          </>
          )}
    </>
  )
}

export default WageringTepltateDetails
