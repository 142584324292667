import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteSABannersStart, getAllSABannersStart, updateSABannerStart, uploadSABannerStart } from '../../../store/redux-slices/superAdminSettings'
import useCheckPermission from '../../../utils/checkPermission'

const useBannerManagement = () => {
  const { loading, SABanners } = useSelector(state => state.superAdminSettings)
  const { clientsFilterData, portalsFilterData } = useSelector(state => state.tenants)
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedPortal, setSelectedPortal] = useState('')
  const [type, setType] = useState('')
  const [data, setData] = useState({})
  const [show, setShow] = useState(false)
  const dispatch = useDispatch()
  const { isHidden } = useCheckPermission()
  const [validation, setValidation] = useState({
    minRequiredWidth: '',
    minRequiredHeight: '',
    maxRequiredWidth: '',
    maxRequiredHeight: ''
  })

  useEffect(() => {
    if (data?.bannerType) {
      if (data?.bannerType.substr(data?.bannerType?.length - 6) === 'Banner') {
        setValidation({
          minRequiredWidth: 400,
          minRequiredHeight: 400,
          maxRequiredWidth: 600,
          maxRequiredHeight: 600
        })
      } else {
        setValidation({
          minRequiredWidth: 1000,
          minRequiredHeight: 400,
          maxRequiredWidth: 1920,
          maxRequiredHeight: 768
        })
      }
    }
  }, [data])

  useEffect(() => {
    dispatch(getAllSABannersStart({
      limit: '',
      pageNo: '',
      adminId: selectedPortal ? '' : selectedClient,
      tenantId: selectedPortal
    }))
  }, [selectedPortal, selectedClient])

  const handleCreateEdit = (type, data) => {
    setType(type)
    setData(data)
    setShow(true)
  }


  const handleDelete = (bannerType) => {
    dispatch(deleteSABannersStart({bannerId:bannerType.bannerId,isMobileBanner:bannerType?.isMobileBanner || false, dispatch}))
    // dispatch(getAllSABannersStart({
    //   limit: '',
    //   pageNo: '',
    //   adminId: selectedPortal ? '' : selectedClient,
    //   tenantId: selectedPortal
    // }))
  }

  const createUpdate = (data) => {
    type === 'Create'
      ? dispatch(uploadSABannerStart({
        data: {
          tenantId: data?.tenantId,
          bannerKey: data?.bannerType,
          image: data?.thumbnail,
          isMobileBanner: data?.isMobileBanner
        },
        limit: '',
        pageNo: ''
      }))
      : dispatch(updateSABannerStart({
        data: {
          tenantId: data?.tenantId,
          bannerKey: data?.bannerType,
          image: data?.thumbnail
        },
        limit: '',
        pageNo: ''
      }))
  }

  return {
    loading,
    selectedClient,
    setSelectedClient,
    selectedPortal,
    setSelectedPortal,
    SABanners,
    handleCreateEdit,
    type,
    data,
    setShow,
    show,
    createUpdate,
    clientsFilterData,
    portalsFilterData,
    dispatch,
    isHidden,
    validation,
    setValidation,
    handleDelete
  }
}

export default useBannerManagement
