import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAffiliateCommissionReportStart, getAffiliateDashboardDataStart } from '../../../../store/redux-slices/affiliates'
import { subDays, startOfMonth } from 'date-fns';

// const format = (date) => {
//   const inputDate = date.toLocaleDateString()
//   const regex = /(\d{2})\/(\d{2})\/(\d{4})/;
//   const match = inputDate.match(regex)
//   if (match) {
//     const day = match[1];
//     const month = match[2];
//     const year = match[3];

//     const formattedDate = `${year}-${month}-${day}`;
//     return formattedDate;
//   } else {
//     return 'Invalid date format';
//   }
// }

const useAffiliateStats = () => {
  const { livePlayerloading: loading, livePlayerData } = useSelector((state) => state.dashboard)
  const [dashboardData, setDashboardData] = useState({
    newRegistredPlayers: 0,
    allPlayers: 0
  })
  const fetchDetails = () => { }
  const dispatch = useDispatch()
  const affiliateDashboard = useSelector((state) => state.affiliate?.affiliateDashboard)
  // useEffect(() => {
  //   const currentDate = new Date();
  //   const year = currentDate.getFullYear();
  //   const month = currentDate.getMonth();
  //   let TYPE = 'yesterday'

  //   const today = new Date();
  //   const yesterday = new Date(today);
  //   yesterday.setDate(today.getDate() - 1);
  //   const formattedYesterday = yesterday


  //   const paylod = {
  //     startsDate: format(formattedYesterday),
  //     endsDate: format(formattedYesterday),
  //     type: TYPE
  //   }
  //   dispatch(getAffiliateDashboardDataStart())
  //   dispatch(getAffiliateCommissionReportStart(paylod))

  //   TYPE = 'currentMonth'
  //   const startDateOfMonth = new Date(year, month, 1);
  //   paylod.startsDate = format(startDateOfMonth)
  //   paylod.type = TYPE
  //   dispatch(getAffiliateCommissionReportStart(paylod))

  // }, [])
  useEffect(() => {
    if (affiliateDashboard) {

      setDashboardData(affiliateDashboard)
    }
  }, [affiliateDashboard])
  return {
    loading,
    fetchDetails,
    affiliateDashboard,
    dashboardData
  }
}

export default useAffiliateStats
