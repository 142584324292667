import React from 'react'
import {
  Form,
  Table,
  Button,
  Spinner,
  Row
} from '@themesberg/react-bootstrap'
import useCheckPermission from '../../utils/checkPermission'
import { fields } from './constants'
import { Col } from 'react-bootstrap'
import { NoDataContainer } from './style'
import { InlineLoader } from '../Preloader'

const RegistrationFormFields = ({ loading, data, handleChange, updateFields, isTenant }) => {
  const { isHidden } = useCheckPermission()
  return (
    <>
      <div className='FormsBg mt-4 pt-1'>
        {
          loading &&
          <NoDataContainer>
            <InlineLoader />
          </NoDataContainer>
        }

        {/* <Table  responsive hover className='mt-4'>
        <thead className='thead-light'>
          <tr>
            <th>Field</th>
            {isTenant && <th>Action</th>}
            <th>Required</th>
          </tr>
        </thead> */}

        {/* <tbody id={loading ? 'cover-spin' : ''}> */}
        <Row className='m-0 pt-3'>
          {data && !loading &&
            fields.map((field, index) => (
              <Col xs={6}>
                <Row className='m-0'>
                  <Col xs={6} className='p-2 FieldsName'>
                    <span>{field.label}</span>
                  </Col>

                  <Col xs={6} className='p-2'>
                    <span>
                      <Form.Check
                        name={field.value}
                        type='switch'
                        checked={data[field.value]}
                        value={data[field.value]}
                        onChange={(e) => handleChange(e, '')}
                        disabled={data?.disable?.includes(field.value)}
                      />
                    </span>
                  </Col>
                </Row>
                {/* {
                  isTenant &&
                  <span>
                    <Form.Check
                      name={field.value}
                      type='switch'
                      checked={data[field.value] === 2}
                      value={data[field.value] === 2}
                      onChange={(e) => handleChange(e, e.target.checked ? 1 : 0)}
                      disabled={data?.disable?.includes(field.value)}
                    />
                  </span>
                } */}
              </Col>
            ))}
        </Row>

        {/* </tbody> */}
        {/* </Table> */}
        {!loading &&
          <div className='mt-3 d-flex justify-content-end'>
            <Button
              onClick={updateFields}
              hidden={isTenant ? isHidden({ module: { key: 'TenantSettings', value: 'U' } }) : isHidden({ module: { key: 'RegistrationField', value: 'U' } })}
            >
              Submit
              {loading && (
                <Spinner
                  as='span'
                  animation='border'
                  role='status'
                  aria-hidden='true'
                  style={{ marginLeft: '10px' }}
                />
              )}
            </Button>
          </div>}
      </div>

    </>
  )
}

export default RegistrationFormFields
