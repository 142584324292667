import { Row, Col, Form, Button, Modal } from '@themesberg/react-bootstrap'
import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import useCategoryReorder from '../hooks/useCategoryReorder'
import { InlineLoader } from '../../../../components/Preloader'

const CategoryReorder = ({
  setShow,
  show
}) => {
  const {
    paymentCategory,
    setPaymentCategory,
    handleSave,
    state,
    onDragEnd,
    loading
  } = useCategoryReorder()
  return (
    <Modal show={show} onHide={() => setShow(false)}>

      <Modal.Header><h3>Reorder Payment Category</h3></Modal.Header>

      <Modal.Body>
        <Row>
          <Col className='d-flex align-items-center justify-content-start'>
            <Form.Label style={{ minWidth: '120px' }}>Payment Type</Form.Label>
            <Form.Select
              size='sm'
              value={paymentCategory}
              onChange={(e) => setPaymentCategory(e.target.value)}
              style={{ width: '230px' }}
            >
              <option value='' disabled>--Select Payment Type--</option>
              <option value='deposit'>Deposit</option>
              <option value='withdraw'>Withdraw</option>
            </Form.Select>
          </Col>
        </Row>

        {loading
          ? <div className='mt-3'><InlineLoader /></div>
          : paymentCategory && !loading && state?.length > 0
            ? (
              <div className='mt-3'>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId='list'>
                    {provided => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {state.length > 0 &&
                    state?.map(
                      (category, idx) => (
                        <Draggable draggableId={`id-${idx}`} key={idx} index={idx}>
                          {provided => (
                            <div
                              className='reorder-content'
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <p>{category}</p>

                            </div>
                          )}
                        </Draggable>
                      ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                {(state?.length === 0 || !state) &&
                  <div className='text-danger text-center'>No data found</div>}
              </div>
              )
            : <p className='text-center mt-4 text-danger'> Select Payment Type </p>}
      </Modal.Body>

      <Modal.Footer className='d-flex justify-content-between'>
        <Button variant='outline-warning' onClick={() => setShow(false)}>
          Cancel
        </Button>

        <Button
          variant='outline-success'
          onClick={handleSave}
          hidden={state?.length === 0 || !state || loading}
        >
          Save
        </Button>

      </Modal.Footer>
    </Modal>
  )
}

export default CategoryReorder
