import { Button, Col, Form as BForm, Form, Row } from '@themesberg/react-bootstrap'
import useLanguageWise from '../hooks/useLanguageWise'
import { AdminsRoutes } from '../../../routes'
import React from 'react'
import Select from 'react-select'
import { ErrorMessage, Formik } from 'formik'
import { languageCode } from '../../../pages/Tenant/TenantConfigurations/Languages/constants'
import Preloader from '../../Preloader'
import { keysWiseSchema } from './schema'
import { GalleryModal } from '../../ConfirmationModal'
import Trigger from '../../OverlayTrigger'
import { faImages } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const KeyWise = () => {
  const {
    navigate,
    isTenant,
    keyOptions,
    setSelectedKey,
    selectedKey,
    myKeys,
    keys,
    myKeyData,
    loading,
    updateData,
    galleryModal,
    setGalleryModal
  } = useLanguageWise()

  return (
    <>
      {loading
        ? <Preloader />
        : (
          <>
          <div className='section-header-wrap mt-n3 mx-n3'> 
            <Row className='mb-3'>
              <Col>
                <h2 className='title-text'>Language Management</h2>
              </Col>
              <Col className='d-flex align-items-end justify-content-end'>
                {(selectedKey && ['footerImageOne', 'footerImageTwo', 'footerImageThree'].includes(selectedKey?.label)) &&
                  <Trigger message='Gallery'>
                    <Button
                     variant='btn btn-primary'
                      onClick={() => setGalleryModal(true)}
                    >
                      <FontAwesomeIcon icon={faImages} />
                    </Button>
                  </Trigger>}
                <Button
                   variant='btn btn-primary'
                  className='float-end ms-2'
                  onClick={() => navigate(AdminsRoutes.EditSupportLanguage)}
                >
                  Edit by Position
                </Button>
              </Col>
            </Row>
            </div>
            <div className='FormsBg'>
            {(myKeys[keys] !== undefined || keys === ':keys')
              ? (
                <>
                  <Row>
                       
                      <Col xs={6}>
                      <Form.Label>Keys</Form.Label>
                        <Select
                          isClearable={false}
                          name='keys'
                          options={keyOptions}
                          value={selectedKey}
                          className='basic-multi-select'
                          classNamePrefix='select'
                          onChange={(option, e) => {
                            setSelectedKey({ label: option.value, value: option.value })
                          }}
                        />
                      </Col>
                      <Formik
                    enableReinitialize
                    initialValues={
              { [selectedKey?.value]: { ...myKeyData?.[selectedKey?.value] } }
}
                    validationSchema={keysWiseSchema(selectedKey?.value, myKeyData?.[selectedKey?.value])}
                    onSubmit={(formValues) => {
                      const data = Object.assign({ }, formValues)
                      updateData({
                        data,
                        type: 'key'
                      })
                    }}
                  >
                    {({
                      values,
                      handleChange,
                      handleSubmit,
                      handleBlur
                    }) => (
                      <Form>
                        <Row className=' mt-3 mb-3'>
                          {selectedKey?.value && Object.keys(myKeyData?.[selectedKey?.value])?.map(lang => {
                            return (
                              <Col sm={6} key={lang} className='mb-3'>
                                <label
                                  htmlFor={lang}
                                >
                                  {languageCode[lang]}{!['footerImageOne', 'footerImageTwo', 'footerImageThree'].includes(selectedKey?.value) && <span className='text-danger'> *</span>}
                                </label>
                                <BForm.Control
                                  name={`${[selectedKey.value]}[${lang}]`}
                                  as='textarea'
                                  placeholder='Enter Value'
                                  value={values?.[selectedKey.value]?.[lang]}
                                  onInput={handleChange}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    handleChange(e)
                                  }}
                                />
                                <ErrorMessage
                                  component='div'
                                  name={`${[selectedKey.value]}[${lang}]`}
                                  className='text-danger'
                                />
                              </Col>
                            )
                          })}
                        </Row>
                        <div className='mt-4 d-flex justify-content-between'>
                          <Button
                            onClick={() => navigate(AdminsRoutes.LanguageManagement)}
                            className='ml-2 btn-danger'
                          >
                            Cancel
                          </Button>
                          <Button
                            className='ml-2 btn-primary'
                            onClick={handleSubmit}
                          >
                            Submit
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  </Row>
               
                </>
                )
              : (
                <>
                  <Row>
                    <h5 className='text-center text-danger'>Key Not Found</h5>
                  </Row>
                </>)}
                </div>
          </>
          )}

      {galleryModal &&
        <GalleryModal
          galleryModal={galleryModal}
          setGalleryModal={setGalleryModal}
          isTenant={isTenant}
        />}
    </>
  )
}

export default KeyWise
