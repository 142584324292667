import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getPaymentProviderStart, updatePaymentProviderStatusStart } from '../../../../store/redux-slices/payment'
import useDidMountEffect from '../../../../utils/useDidMountEffect'

const useCustomPayment = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selectedProviderId, setSelectedProviderId] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [active, setActive] = useState(false)
  const [name, setName] = useState('')
  const [data, setData] = useState({})
  const [type, setType] = useState('')
  const [status, setStatus] = useState('')
  const [search, setSearch] = useState('')
  const [category, setCategory] = useState('')
  const [reorderModal, setReorderModal] = useState(false)
  const isInitialRender = useDidMountEffect()
  const { providerLoading, paymentProviders } = useSelector(state => state.payment)

  useEffect(() => {
    dispatch(getPaymentProviderStart({
      isTenant: false,
      paymentType: type,
      aggregator: '',
      tenant: '',
      group: '',
      status,
      tenantIds: [],
      category
    }))
  }, [type, status, category])

  useEffect(() => {
    setProvidersData()
  }, [paymentProviders])

  const setProvidersData = () => {
    const myData = {}
    if (!Object.keys(myData)?.length && paymentProviders) {
      paymentProviders.forEach(({ aggregator, group }) => {
        if (myData?.[aggregator]?.[group] === undefined) {
          const provDetails = paymentProviders?.filter((item) =>
            item.group === group && item.aggregator === aggregator && item?.name?.toLowerCase().includes(search.toLowerCase()))
          if (provDetails?.length) {
            if (myData[aggregator] === undefined) myData[aggregator] = []
            if (myData[aggregator][group] === undefined) myData[aggregator][group] = []
            myData[aggregator][group] = provDetails
          }
        }
      }
      )
      setData(myData)
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        setProvidersData()
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const handleShowModal = (id, act, myName) => {
    setSelectedProviderId(id)
    setActive(!act)
    setName(myName)
    setShowModal(true)
  }

  const handleYes = () => {
    dispatch(updatePaymentProviderStatusStart({
      isTenant: false,
      status,
      paymentType: type,
      category,
      data: { paymentProviderId: selectedProviderId, status: active, code: 'PAYMENT_PROVIDER' }
    }))
    setShowModal(false)
    setData({})
  }

  return {
    loading: providerLoading,
    paymentProviders,
    navigate,
    showModal,
    setShowModal,
    handleYes,
    handleShowModal,
    active,
    name,
    data,
    type,
    setType,
    status,
    setStatus,
    search,
    setSearch,
    category,
    setCategory,
    reorderModal,
    setReorderModal
  }
}

export default useCustomPayment
