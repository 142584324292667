
import React from 'react';
import Preloader from '../../../../components/Preloader'
import useEditSocialLink from '../hooks/useEditSocialLink'
import CreateSocialLink from './CreateSocialLink'

const UpdateSocialLink = () => {
  const { socialLink, loading } = useEditSocialLink()
  console.log('socialLink :>> ', socialLink);
  
  return (
    <>
      {loading
        ? <Preloader />
        : (
          <>
            {socialLink && (
              <CreateSocialLink
                link={socialLink.link}
                iconId={socialLink.iconId}
                name={socialLink.name}
                label={socialLink.label}
              />
            )}
          </>
        )}
    </>
  )
}

export default UpdateSocialLink
