import React from "react";
import { Tabs, Tab, Row, Col } from "@themesberg/react-bootstrap";
import Overview from "./components/Overview";
import useAffiliateDetails from "./hooks/useAffiliateDetails";
import Preloader from "../../../components/Preloader";
import useCheckPermission from "../../../utils/checkPermission";
import {
  ApproveAffiliateModal,
  ResetPasswordEmailModal,
  ResetUserPasswordModal,
} from "../../../components/ConfirmationModal";

import Tools from "../../../pages/Affiliates/Dashboard/components/TotalReports/index";
import AffiliateStats from "./components/AffiliateStats";
import DateRangePicker from "../../../components/DateRangePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/free-solid-svg-icons";
import TotalReports from "./components/TotalReports";
import AffiliateLives from "./components/AffiliateLives";
import useDashboard from "../../Affiliates/Dashboard/hooks/useDashboard";
import AffiliateStatsChart from '../../Affiliates/Dashboard/components/AffiliateStats/AffiliateStatsChart'
import AffiliateWallet from "./components/AffiliateWallet";
import ManageMoneyModal from "./components/ManageMoneyModal";
import AffiliateTransactionsTable from "./components/AffiliateTransactions";
import AffiliateDailyPerformanceTable from "./components/AffiliateDailyPerformance";
import { getAvatarPlaceholder } from "../../../utils/getAvatarImage";
import useRole from "../../../hooks/useRole";
import { AFFILIATE_TYPE } from "../../../utils/constant";
import SubAffiliatesPerformanceTable from "./components/AffiliateSubAffiliatesPerformance";
import LinkCreater from "../../Affiliates/AffiliateProfile/components/LinkCreater";
import AffiliateUsersPerformanceTable from "./components/AffiliateUsersPerformance";
import AffiliateWithdrawRequestsTable from "./components/WithdrawRequests/AffiliateWithdrawRequests";
import AffiliateSubAffiliatesDailyPerformanceTable from "./components/AffiliateSubAffiliatesDailyPerformance";

const AffiliateDetails = ({ asSubAffiliateDetails }) => {
  const {
    selectedTab,
    userData,
    loading,
    basicInfo,
    kycInfo,
    contactInfo,
    setShowRemoveAffiliate,
    updateUserAffiliateLoading,
    addUserAffiliateLoading,
    setShowAddAffiliate,
    setShowModal,
    setSelectedTab,
    showManageMoneyModal,
    setShowManageMoneyModal,
    setFreeSpinModal,
    setIsInternalModal,
    setActiveInactiveModal,
    setVerifyEmailModal,
    setPasswordEmailModal,
    passwordEmailModal,
    setUserPasswordModal,
    setEditUserModal,
    addTag,
    getDuplicateUsers,
    duplicateUsers,
    affiliateByIdData,
    approveAffiliateModal,
    setApproveAffiliateModal,
    handleApproveAffiliate,
    handleApproveSubAffiliate,
    affiliateApproveLoading,
    handleResetUserPassword,
    handleResetPasswordEmail,
    userPasswordModal,
    dateRangePickerState,
    setDateRangePickerState,
    handleDateRangeReset,
    navigate,
    affiliateId,
    updateAffiliateData,
  } = useAffiliateDetails();
  const { isHidden } = useCheckPermission();
  const { AdminOrSuperAdmin } = useRole();
  const { getSubCategory } = useDashboard();

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <div className="section-header-wrap mt-n3 mx-n3">
            <Row>
              <Col lg={12} xs={12}>
                <div className="d-flex align-items-center">
                  <div className="title-image me-4 my-n3 cursor-pointer"
                    onClick={() =>
                      navigate(`/admin/update-affiliate/${affiliateByIdData?.affiliateId}`)
                    }
                  >
                    {affiliateByIdData?.avatar ? <img src={affiliateByIdData?.avatar} onError={(e) => e.target.src = getAvatarPlaceholder({ firstName: affiliateByIdData?.firstName, lastName: affiliateByIdData?.lastName })} /> : null}
                  </div>
                  <h2 className="mb-0">
                    {affiliateByIdData && affiliateByIdData?.firstName}{" "}
                    {affiliateByIdData && affiliateByIdData?.lastName}
                  </h2>
                </div>
              </Col>
            </Row>
          </div>
          <Tabs
            activeKey={selectedTab}
            onSelect={(tab) => {
              setSelectedTab(tab);
              navigate(`/admin/affiliate-details/${affiliateId}?tab=${tab}`)
            }}
            className="nav-light"
            mountOnEnter
            unmountOnExit
          >
            <Tab eventKey="overview" title="Visão Geral">
              <div className="mt-4 mt-lg-4">
                <Overview
                  basicInfo={basicInfo}
                  kycInfo={kycInfo}
                  contactInfo={contactInfo}
                  user={userData}
                  affiliate={affiliateByIdData}
                  setActiveInactiveModal={setActiveInactiveModal}
                  setIsInternalModal={setIsInternalModal}
                  setVerifyEmailModal={setVerifyEmailModal}
                  setShowModal={setShowModal}
                  setFreeSpinModal={setFreeSpinModal}
                  setShowManageMoneyModal={setShowManageMoneyModal}
                  setShowAddAffiliate={setShowAddAffiliate}
                  addUserAffiliateLoading={addUserAffiliateLoading}
                  setShowRemoveAffiliate={setShowRemoveAffiliate}
                  updateUserAffiliateLoading={updateUserAffiliateLoading}
                  isHidden={isHidden}
                  setPasswordEmail={setPasswordEmailModal}
                  setUserPassword={setUserPasswordModal}
                  setEditModal={setEditUserModal}
                  addTag={addTag}
                  getDuplicateUsers={getDuplicateUsers}
                  duplicateUsers={duplicateUsers}
                  setApproveAffiliateModal={setApproveAffiliateModal}
                />
              </div>
            </Tab>

            {AdminOrSuperAdmin && <Tab eventKey="dashboard" title="Painel Analítico">
              <div className="mt-4 d-flex flex-column">
                {<AffiliateStatsChart affiliate={affiliateByIdData} />}

                <TotalReports reportType={"casino"} getSubCategory={getSubCategory} />
                {/* <TotalReports reportType={"sports"} /> */}
                {(affiliateByIdData?.affiliateType == AFFILIATE_TYPE.STREAMER) ? <TotalReports reportType={"lives"} /> : null}

                {affiliateByIdData?.enableSubAffiliatesSystem ? <>
                  <TotalReports reportType={"subAffiliateComissions"} />
                  <TotalReports reportType={"subAffiliateLiveComissions"} />
                </>
                  : null}

              </div>
            </Tab>}
            {AdminOrSuperAdmin && <Tab eventKey="wallet" title="Carteira">
              <AffiliateWallet affiliate={affiliateByIdData} />
            </Tab>}
            {(AdminOrSuperAdmin && affiliateByIdData?.affiliateType == AFFILIATE_TYPE.STREAMER) ? <Tab eventKey="lives" title="Lives">
              <AffiliateLives affiliate={affiliateByIdData}></AffiliateLives>
            </Tab> : null}
            {AdminOrSuperAdmin && <Tab eventKey="transactions" title="Transações">
              <AffiliateTransactionsTable affiliate={affiliateByIdData} style={{ maring: 0 }} />
            </Tab>}
            {AdminOrSuperAdmin && <Tab eventKey="withdraw-requests" title="Solicitações de Saque">
              <AffiliateWithdrawRequestsTable affiliate={affiliateByIdData} style={{ maring: 0 }} />
            </Tab>}
            {AdminOrSuperAdmin && <Tab eventKey="daily-performance" title="Desempenho Diário">
              <AffiliateDailyPerformanceTable affiliate={affiliateByIdData} style={{ maring: 0 }} />
            </Tab>}
            {AdminOrSuperAdmin && <Tab eventKey="users-performance" title="Desempenho de Usuários">
              <AffiliateUsersPerformanceTable affiliate={affiliateByIdData} style={{ maring: 0 }} />
            </Tab>}
            {(AdminOrSuperAdmin && affiliateByIdData?.enableSubAffiliatesSystem) ? <Tab eventKey="sub-affiliates-performance" title="Desempenho de Sub-afiliados">
              <SubAffiliatesPerformanceTable affiliate={affiliateByIdData} style={{ maring: 0 }} />
            </Tab> : null}
            {(AdminOrSuperAdmin && affiliateByIdData?.enableSubAffiliatesSystem) ? <Tab eventKey="sub-affiliates-daily-performance" title="Desempenho Diário Sub-afiliados">
              <AffiliateSubAffiliatesDailyPerformanceTable affiliate={affiliateByIdData} style={{ maring: 0 }} />
            </Tab> : null}
            {AdminOrSuperAdmin && <Tab eventKey="links" title="Links">
              <div className="mt-4">
                <LinkCreater affiliate={affiliateByIdData} />
              </div>
            </Tab>}

          </Tabs>

          <ManageMoneyModal
            show={showManageMoneyModal}
            handleClose={setShowManageMoneyModal}
            updateAffiliateData={updateAffiliateData}
            affiliate={affiliateByIdData}
          />

          {approveAffiliateModal && (
            <ApproveAffiliateModal
              show={approveAffiliateModal}
              setShow={setApproveAffiliateModal}
              affiliateByIdData={affiliateByIdData}
              handleApproveAffiliate={handleApproveAffiliate}
              handleApproveSubAffiliate={handleApproveSubAffiliate}
              affiliateApproveLoading={affiliateApproveLoading}
            />
          )}

          {passwordEmailModal && (
            <ResetPasswordEmailModal
              show={passwordEmailModal}
              setShow={setPasswordEmailModal}
              userData={userData}
              handleResetPasswordEmail={handleResetPasswordEmail}
            />
          )}

          {userPasswordModal && (
            <ResetUserPasswordModal
              show={userPasswordModal}
              setShow={setUserPasswordModal}
              userData={userData}
              handleResetUserPassword={handleResetUserPassword}
            />
          )}
        </>
      )}
    </>
  );
};

export default AffiliateDetails;
