import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { superAdminGenerateOtpStart, superAdminLoginStart } from '../../../store/redux-slices/login'
import { getLoginToken, removeLoginToken, getItem } from '../../../utils/storageUtils'
import { AdminsRoutes } from '../../../routes'

const useSuperAdminSignin = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loading } = useSelector((state) => state.login)
  const { settings } = useSelector((state) => state.admins)
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    if (getLoginToken() && getItem('role') === 'Super Admin') {
      navigate(AdminsRoutes.Dashboard)
    } else {
      removeLoginToken()
    }
  }, [])

  const handleSignIn = ({ user, password, otp }) =>
    dispatch(superAdminLoginStart({ user, password, otp, navigate }))

  const [shouldGoForOtpVerification, setShouldGoForOtpVerification] = useState(true)
  const handleGenerateOtp = ({ user = '', password = '', setFieldValue }) => {
    dispatch(superAdminGenerateOtpStart({ user, password, setFieldValue }))
  }

  return {
    loading,
    showPassword,
    setShowPassword,
    navigate,
    handleSignIn,
    shouldGoForOtpVerification,
    setShouldGoForOtpVerification,
    handleGenerateOtp,
    settings
  }
}

export default useSuperAdminSignin
