import { Modal } from '@themesberg/react-bootstrap'
import React, { useEffect } from 'react'
import { getAllCasinoGames, getMasterGames } from '../../utils/apiCalls'
import FreeSpinForm from './FreeSpinForm'
import GamesTable from './GamesTable'
import useFreeSpinBonusModal from './useFreeSpinBonusModal'

export default ({
  isTenant = false,
  show,
  setShow,
  userData
}) => {
  const {
    search,
    setSearch,
    gameIds,
    handleSelect,
    handleSelectAll,
    availableGames,
    getData,
    bonusOptions,
    submitData,
    setGamesData,
    loading
  } = useFreeSpinBonusModal({ isTenant })

  useEffect(() => {
    async function fetchData () {
      isTenant
        ? await getMasterGames({
          limit: '',
          pageNo: '',
          search: '',
          casinoCategoryId: '',
          providerId: '',
          freespins: true,
          bonusId: ''
        }).then((res) => {
          setGamesData(res?.data?.data?.casinoGames)
        })
        : await getAllCasinoGames({
          limit: '',
          pageNo: '',
          casinoCategoryId: '',
          search: '',
          isActive: '',
          tenantId: '',
          selectedProvider: '',
          freespins: true,
          bonusId: ''
        }).then((res) => {
          setGamesData(res?.data?.data?.casinoGames)
        })
    }
    fetchData()
  }, [])

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setShow(false)
        }}
        size='xl'
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h4>Issue Freespins to '{userData?.firstName} {userData?.lastName}'</h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <GamesTable
            search={search}
            setSearch={setSearch}
            gameIds={gameIds}
            availableGames={availableGames}
            handleSelectAll={handleSelectAll}
            loading={loading}
            handleSelect={handleSelect}
            getData={getData}
          />
          <FreeSpinForm
            bonusOptions={bonusOptions}
            setShow={setShow}
            gameIds={gameIds}
            submitData={submitData}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
