import React from 'react'
import {
    Form,
    Row,
    Col,
    Button,
    //   Table,
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import useTournamentListing from './useTournamentListing'
import TournamentListTable from './TournamentListTable'
import { SIZE_CONSTANT } from './constants'
import Trigger from '../../../components/OverlayTrigger'

const SportsCountriesList = () => {
    const {
        sportsTournamentInfo,
        pageNo,
        setPageNo,
        totalPages,
        limit,
        setLimit,
        isGetTournamentLoading,
        searchByName,
        setSearchByName,
        searchByCountry,
        setSearchByCountry,
        searchBySports,
        setSearchBySports,
        sportsListInfo,
        sportsCountriesInfo
    } = useTournamentListing()
    //   const { isHidden } = useCheckPermission();
    return (
        <>
            <div className='section-header-wrap mt-n3 mx-n3'>
                <Row>
                    <Col>
                        <h2 className='title-text'>Tournaments</h2>
                    </Col>
                </Row>
            </div>
            <div className='FormsBg'>
                <Row>
                    <Col xs='12' sm='3'>
                        <Form.Label style={{ marginBottom: '0', marginRight: '15px' }}>
                            Search
                        </Form.Label>
                        <Form.Control
                            type='search'
                            placeholder='Search By Name'
                            size='sm'
                            // style={{ maxWidth: '230px', marginRight: '10px', maxHeight: '15px' }}
                            value={searchByName}
                            onChange={(event) =>
                                setSearchByName(event.target.value.replace(/[^\w\s]/gi, ''))}
                        />
                    </Col>
                    <Col xs='12' sm='3'>
                        <Form.Label style={{ marginBottom: '0', marginRight: '15px' }}>
                            Country
                        </Form.Label>
                        <Form.Select
                            onChange={(e) => {
                                setSearchByCountry(e.target.value)
                            }}
                            value={searchByCountry}
                        >
                            <option value=''>All</option>
                            {sportsCountriesInfo?.countryList?.rows.map(({ providerCountryId, countryName }) => (
                                <option value={providerCountryId}>{countryName[0]?.name}</option>
                            ))}
                        </Form.Select>
                    </Col>
                    <Col xs='12' sm='3'>
                        <Form.Label style={{ marginBottom: '0', marginRight: '15px' }}>
                            Sports
                        </Form.Label>
                        <Form.Select
                            onChange={(e) => {
                                setSearchBySports(e.target.value)
                            }}
                            value={searchBySports}
                        >
                            <option value=''>All</option>
                            {sportsListInfo?.sportsList?.rows.map(({ providerSportId, sportName }) => (
                                <option value={providerSportId}>{sportName[0]?.name}</option>
                            ))}
                        </Form.Select>
                    </Col>
                    <Col xs='12' sm='2'>
                        <Form.Label style={{ marginBottom: '0', marginRight: '15px' }}>
                            Size
                        </Form.Label>
                        <Form.Select
                            onChange={(e) => {
                                setLimit(e.target.value);
                                setPageNo(1);
                            }}
                            value={limit}
                        >
                            <option value=''>All</option>
                            {
                                SIZE_CONSTANT.map((item, index) => {
                                    return (
                                        <option value={item}>{item}</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </Col>
                    <Col xs={1} className='d-flex justify-content-end align-items-center'>
                        <Trigger message='Reset Filters'>
                            <Button
                                size='sm'
                                onClick={() => {
                                    setLimit(10)
                                    setPageNo(1)
                                    setSearchByName('')
                                    setSearchByCountry('')
                                    setSearchBySports('')
                                }}
                            >
                                <FontAwesomeIcon icon={faRedoAlt} />
                            </Button>
                        </Trigger>
                    </Col>
                </Row>
            </div>
            <TournamentListTable
                sportsTournamentInfo={sportsTournamentInfo}
                pageNo={pageNo}
                setPageNo={setPageNo}
                totalPages={totalPages}
                limit={limit}
                setLimit={setLimit}
                isGetTournamentLoading={isGetTournamentLoading}
            />

        </>
    )
}

export default SportsCountriesList;
