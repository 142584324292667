import { createSlice } from '@reduxjs/toolkit';

const loyaltyRulesSlice = createSlice({
    name: 'loyaltyRules',
    initialState: {
        loading: false,
        error: null,
        success: false,
        loyaltyRules: [],
        loyaltyRule: null,
        minimumBetAmount:null
    },
    reducers: {
        getAllLoyaltyRulesStart: (state) => ({
            ...state,
            loading: true
        }),
        getAllLoyaltyRulesSuccess: (state, { payload }) => ({
            ...state,
            loading: false,
            error: null,
            success: true,
            loyaltyRules: payload
        }),
        getAllLoyaltyRulesFailure: (state, { payload }) => ({
            ...state,
            loading: false,
            error: payload,
            success: false
        }),
        setLoyaltyRule: (state, { payload }) => ({
            ...state,
            loyaltyRule: payload
        }),
        editLoyaltyRuleStart: (state, { payload }) => ({
            ...state,
            loading: true,
            error: null
        }),
        editLoyaltyRuleSuccess: (state, { payload }) => ({
            ...state,
            loading: false,
            error: null,
            success: true
        }),
        editLoyaltyRuleFailure: (state, { payload }) => ({
            ...state,
            loading: false,
            error: payload,
            success: false
        }),
        createLoyaltyRuleStart: (state, { payload }) => ({
            ...state,
            loading: true,
            error: null
        }),
        createLoyaltyRuleSuccess: (state, { payload }) => ({
            ...state,
            loading: false,
            error: null,
            success: true
        }),
        createLoyaltyRuleFailure: (state, { payload }) => ({
            ...state,
            loading: false,
            error: payload,
            success: false
        }),
        deleteLoyaltyRuleStart: (state, { payload }) => ({
            ...state,
            loading: true,
            error: null
        }),
        deleteLoyaltyRuleSuccess: (state, { payload }) => ({
            ...state,
            loading: false,
            error: null,
            success: true
        }),
        deleteLoyaltyRuleFailure: (state, { payload }) => ({
            ...state,
            loading: false,
            error: payload,
            success: false
        }),
        setMinimumBetAmountStart:(state, { payload }) => ({
            ...state,
             loading: true
        }),
        setMinimumBetAmountSuccess: (state, { payload }) => ({
            ...state,
             loading: false,
             error: null,
             success: true
        }),
        setMinimumBetAmountFailure: (state, { payload }) => ({
            ...state,
             loading: false,
             error: payload,
             success: false
        }),
        getMinimumBetAmountSuccess: (state, { payload }) => ({  
            ...state,
             loading: false,
             error: null,
             success: true,
             minimumBetAmount: payload
        }),
        getMinimumBetAmountFailure: (state, { payload }) => ({
            ...state,
             loading: false,
             error: payload,
             success: false
        }),
        getMinimumBetAmountStart:(state, { payload }) => ({
            ...state,
             loading: true
        })
    }
});

export default loyaltyRulesSlice.reducer;

export const {
    getAllLoyaltyRulesStart,
    getAllLoyaltyRulesFailure,
    getAllLoyaltyRulesSuccess,
    setLoyaltyRule,
    editLoyaltyRuleSuccess,
    editLoyaltyRuleStart,
    editLoyaltyRuleFailure,
    createLoyaltyRuleFailure,
    createLoyaltyRuleStart,
    createLoyaltyRuleSuccess,
    deleteLoyaltyRuleStart,
    deleteLoyaltyRuleSuccess,
    deleteLoyaltyRuleFailure,
    setMinimumBetAmountStart,
    setMinimumBetAmountSuccess,
    setMinimumBetAmountFailure,
    getMinimumBetAmountSuccess,
    getMinimumBetAmountFailure,
    getMinimumBetAmountStart
} = loyaltyRulesSlice.actions;

export const getLoyaltyRulesState = (state) => state.loyaltyRules