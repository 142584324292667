import * as Yup from 'yup'
import { AFFILIATE_TYPE, AFFILIATE_TYPE_LABEL } from '../../../utils/constant';

export const affiliateTypeOptions = Object.keys(AFFILIATE_TYPE).map((key) => ({
  value: AFFILIATE_TYPE[key],
  label: AFFILIATE_TYPE_LABEL[key]
}));

export const affiliateSignupInitialValues = () => {
  return {
    firstName: null,
    lastName: null,
    username: null,
    phone: null,
    email: null,
    cpf: null,
    // password: null,
    // confirmPassword: null,
    affiliateType: null,
  }
}

export const affiliateSignupSchema = Yup.object().shape({
  firstName: Yup.string().nullable()
    .min(3, "Nome precisa ter no minímo 3 letras")
    .required("Nome é obrigátorio"),
  lastName: Yup.string().nullable()
    .min(3, "Sobrenome precisa ter no minímo 3 letras")
    .required("Sobrenome é obrigatório"),
  username: Yup.string().nullable()
    .min(3, "usernameIsShort")
    .matches(/^[a-zA-z0-9_-]+$/, "usernameInvalidCharacters")
    .required("Nome de usuário obrigátorio"),
  phone: Yup.string().nullable()
    .matches(/^(\+?[0-9]{1,6}([ .-])?)?(\([0-9]{2,6}\))?([0-9 .\-\(\)+]{1,32}[0-9])$/, 'Número de telefone inválido')
  // .required("Telefone é obrigátorio")
  ,
  email: Yup.string().nullable()
    .email("Informe um e-mail válido")
    .required("O e-mail é obrigátorio"),
  cpf: Yup.string().nullable()
    .matches(/^(?!(\d)\1{2}\.\1{3}\.\1{3}-\1{2}$)\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF Inválido')
    .required("CPF é obrigatório"),
  // password: Yup.string().nullable()
  //   .min(6, 'A senha deve ter no mínimo 6 caracteres')
  //   .matches(/[0-9]/, 'A senha requer pelo menos um número!')
  //   .matches(/[^\w]/, 'A senha requer pelo menos um símbolo!')
  //   .required('Senha obrigatório!'),
  // confirmPassword: Yup.string().nullable()
  //   .oneOf([Yup.ref('password'), ''], 'As senhas não conferem!')
  //   .required('Confirmação de senha é obrigatório!'),
  affiliateType: Yup.number().nullable().required("Tipo de affiliado é obrigatório"),
  avatar: Yup.string().nullable(),
  bio: Yup.string().nullable()
});
