import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdminPanelSettingsStart } from "../../store/redux-slices/admins";
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import { Loading } from "../Preloader";

const GetAdminSettings = ({ children }) => {
  const dispatch = useDispatch();
  const { settingsLoading } = useSelector((state) => state.admins);

  useEffect(() => {
    dispatch(getAdminPanelSettingsStart({}));
  }, []);

  return (
    <AnimatePresence mode="wait">
      {settingsLoading ? (
        <motion.div animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="mh-100-dvh w-100 d-flex justify-content-center align-items-center position-fixed z-9" style={{ backgroundBolor: "rgb(242, 245, 249) !important" }}>
          <Loading />
        </motion.div>
      ) : (children)}
    </AnimatePresence>
  )
}

export default GetAdminSettings;