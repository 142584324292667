export const transformGetAllCasinoGames = (casinoGames) => {
    const {rows,count} = casinoGames?.data.casinoGames
    const transformCasinoGames= {
        data: {
            casinoGames:{
                rows:[],
                count

            }

        }
    }

    transformCasinoGames.data.casinoGames.rows = rows?.map((game)=>{

        const gameData  = {
          ...game
        }

        try {
            gameData.subCategoryName = JSON.parse(game?.GameSubCategory.name)?.EN 
        } catch (error) {
            gameData.subCategoryName = 'N/A'
            
        }

        return gameData
    })

    return transformCasinoGames;
}