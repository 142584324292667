import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    getAdminPanelSettingsStart,
    getAdminPanelSettingsSuccess,
    getAdminPanelSettingsFailure,
    getAllAdminsStart,
    getAllAdminsSuccess,
    getAllAdminsFailure,
    getAdminDetailsStart,
    getAdminDetailsSuccess,
    getAdminDetailsFailure,
    getSuperAdminDetailsStart,
    getSuperAdminDetailsSuccess,
    getSuperAdminDetailsFailure,
    setSuperAdminAffiliateData,
    updateTenantAdminStatusStart,
    updateTenantAdminStatusComplete,
    getAllBonusStart,
    getAllBonusComplete,
    getAllBonusFailure,
    getAdminChildrenStart,
    getAdminChildrenSuccess,
    getAdminChildrenFailure,
    resetAdminChildren,
    getAdminDataStart,
    getAdminDataSuccess,
    getAdminDataFailure,
    addUserInternalTagtStart,
    addUserInternalTagtSuccess,
    addUserInternalTagtFailure,
    updateReorderBonusStart,
    updateReorderBonusComplete,
    getLoggedSADetailsSuccess
  },
  reducer
} = createSlice({
  name: 'admins',
  initialState: {
    loading: false,
    error: null,
    success: false,
    data: null,
    adminDetails: null,
    adminRoleId: null,
    adminPermissions: null,
    adminChildren: null,
    adminData: null,
    superAdminUser: null,
    settings: {},
    settingsLoading: true,
  },
  reducers: {
    getAdminPanelSettingsStart: (state) => ({
      ...state,
      settingsLoading: true
    }),
    getAdminPanelSettingsSuccess: (state, { payload }) => ({
      ...state,
      settingsLoading: false,
      settings: payload
    }),
    getAdminPanelSettingsFailure: (state, { payload }) => ({
      ...state,
      settingsLoading: false
    }),
    getAllAdminsStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllAdminsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      data: payload
    }),
    getAllAdminsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    getAdminDetailsStart: (state) => ({
      ...state,
      loading: true
    }),
    getAdminDetailsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      adminDetails: payload
    }),
    getAdminDetailsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    getSuperAdminDetailsStart: (state) => ({
      ...state,
      loading: true
    }),
    getSuperAdminDetailsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      adminDetails: { ...state.adminDetails, ...payload },
      adminPermissions: payload?.userPermission?.permission,
      adminRoleId: payload?.superRoleId
    }),
    setSuperAdminAffiliateData: (state, { payload }) => ({
      ...state,
      adminDetails: { ...state.adminDetails, affiliate: { ...state.adminDetails.affiliate, ...payload } },
    }),
    getSuperAdminDetailsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    updateTenantAdminStatusStart: (state) => ({
      ...state,
      loading: true
    }),
    updateTenantAdminStatusComplete: (state) => ({
      ...state,
      loading: false
    }),
    getAllBonusStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllBonusComplete: (state, { payload }) => ({
      ...state,
      loading: false,
      bonusList: payload

    }),
    getAllBonusFailure: (state) => ({
      ...state,
      loading: false
    }),
    getAdminChildrenStart: (state) => ({
      ...state,
      loading: true
    }),
    getAdminChildrenSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      adminChildren: payload
    }),
    getAdminChildrenFailure: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    resetAdminChildren: (state) => ({
      ...state,
      loading: false,
      adminChildren: null
    }),
    getAdminDataStart: (state) => ({
      ...state,
      loading: true,
      adminData: null
    }),
    getAdminDataSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      adminData: payload
    }),
    getAdminDataFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    addUserInternalTagtStart: (state) => ({
      ...state,
      loading: true
    }),
    addUserInternalTagtSuccess: (state) => ({
      ...state,
      loading: false
    }),
    addUserInternalTagtFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateReorderBonusStart: (state) => ({
      ...state,
      loading: true
    }),
    updateReorderBonusComplete: (state) => ({
      ...state,
      loading: false
    }),
    getLoggedSADetailsSuccess: (state, { payload }) => ({
      ...state,
      superAdminUser: payload
    })
  }
})

export default reducer

export {
  getAdminPanelSettingsStart,
  getAdminPanelSettingsSuccess,
  getAdminPanelSettingsFailure,
  getAllAdminsStart,
  getAllAdminsSuccess,
  getAllAdminsFailure,
  getAdminChildrenStart,
  getAdminChildrenSuccess,
  getAdminChildrenFailure,
  getAdminDetailsStart,
  getAdminDetailsSuccess,
  getAdminDetailsFailure,
  getSuperAdminDetailsStart,
  getSuperAdminDetailsSuccess,
  getSuperAdminDetailsFailure,
  setSuperAdminAffiliateData,
  updateTenantAdminStatusStart,
  updateTenantAdminStatusComplete,
  getAllBonusStart,
  getAllBonusComplete,
  getAllBonusFailure,
  resetAdminChildren,
  getAdminDataStart,
  getAdminDataSuccess,
  getAdminDataFailure,
  addUserInternalTagtStart,
  addUserInternalTagtSuccess,
  addUserInternalTagtFailure,
  updateReorderBonusStart,
  updateReorderBonusComplete,
  getLoggedSADetailsSuccess
}
