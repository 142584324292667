import { Button, Tabs, Tab } from '@themesberg/react-bootstrap'
import { Form, Formik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { AdminsRoutes } from '../../../routes'
import Preloader from '../../Preloader'
import { languageWiseSchema } from './schema'
import UserEndPages from './UserEndPages'

const EditLangWise = ({
  languageKeyObj,
  selectedTab,
  myKeys,
  isTenant = false,
  languageTabs,
  selectedKeyTab,
  setSelectedKeyTab,
  updateData,
  isError,
  setIsError,
  check
}) => {
  const navigate = useNavigate()

  return (
    <>
      {!check
        ? (
          <>
        
          <Formik
              enableReinitialize
              initialValues={
            { languageKeys: { ...languageKeyObj } || myKeys }
        }
              validationSchema={languageWiseSchema(myKeys)}
              onSubmit={(formValues) => {
                const data = Object.assign({ }, formValues?.languageKeys)
                for (const key in data) {
                  if (data[key] === '' || data?.[key] === languageKeyObj?.[key]) {
                    delete data[key]
                  }
                }
                data.language = selectedTab
                updateData({
                  type: 'language',
                  data
                })
              }}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                handleBlur,
                errors
              }) => (
               
                <Form>
                  <Tabs
                    activeKey={selectedKeyTab}
                    onSelect={(tab) => setSelectedKeyTab(tab)}
                    className='nav-light'
                  >
                    {languageTabs && Object.keys(languageTabs)?.map(key => {
                      return (
                        <Tab
                          eventKey={key}
                          key={key}
                          title={key}
                          disabled={isError && Object.keys(isError).length}
                        >
                          <div className='mt-5 FormsBg'>
                            <UserEndPages
                              myKeys={myKeys}
                              selectedTab={selectedKeyTab}
                              values={values}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              languageTabs={languageTabs[selectedKeyTab]}
                              setIsError={setIsError}
                              isError={isError}
                            />
                          </div>
                        </Tab>

                      )
                    })}
                  </Tabs>

                  <div className='mt-4 d-flex justify-content-between'>
                    <Button
                      className='btn-danger'
                      onClick={() => navigate(AdminsRoutes.LanguageManagement)}
                    >
                      Cancel
                    </Button>
                    <Button
                      className='ml-2'
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          
       
          </>
          )
        : <Preloader />}
    </>
  )
}

export default EditLangWise
