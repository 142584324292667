import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getCountriesStart } from '../../../../store/redux-slices/fetchData'
import { configProviderSettingsStart, getProviderDetailsStart } from '../../../../store/redux-slices/payment'
import { getCMSLanguagesStart } from '../../../../store/redux-slices/languages'
import useDidMountEffect from '../../../../utils/useDidMountEffect'

const useEditProvider = () => {
  const { providerId } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const details = window.location.pathname.includes('view')
  const [selectedKeyTab, setSelectedKeyTab] = useState('general')
  const { providerDetails, detailsLoading: loading } = useSelector(state => state.payment)
  const { countries } = useSelector(state => state.fetch)
  const [data, setData] = useState(providerDetails?.regions?.blocked)
  const [search, setSearch] = useState('')
  const [myCountries, setMyCountries] = useState([])
  const [kycCountries, setKycCountries] = useState([])
  const isInitialRender = useDidMountEffect()
  const [logoCheck, setLogoCheck] = useState('false')
  const [amtOptions, setAmtOptions] = useState({})
  const { tenantLanguages } = useSelector((state) => state.languages)

  useEffect(() => {
    providerId && dispatch(getProviderDetailsStart({ isTenant: false, providerId }))
  }, [providerId])

  useEffect(() => {
    providerDetails?.paymentProviderId === parseInt(providerId) &&
    setData(providerDetails?.regions?.blocked || [])
  }, [providerDetails?.regions?.blocked])

  useEffect(() => {
    setKycCountries(providerDetails?.kycCountries || [])
    providerDetails?.amountKeys && setAmtOptions(typeof providerDetails?.amountKeys === 'string' ? JSON.parse(providerDetails?.amountKeys) : providerDetails?.amountKeys)
  }, [providerDetails])

  const setCountries = () => {
    if (countries?.length) {
      if (providerDetails?.regions?.supported) {
        const country = []
        countries.forEach(({ name, code }) => {
          if (providerDetails?.regions?.supported[code] !== undefined && name.toLowerCase().includes(search.toLowerCase())) {
            if (providerDetails?.regions?.blocked?.includes(code)) {
              country.unshift({ name, code })
            } else {
              country.push({ name, code })
            }
          }
          setMyCountries(country)
        })
      } else {
        const country = []
        countries.forEach(({ name, code }) => {
          if (name.toLowerCase().includes(search.toLowerCase())) {
            if (providerDetails?.regions?.blocked?.includes(code)) {
              country.unshift({ name, code })
            } else {
              country.push({ name, code })
            }
          }
          setMyCountries(country)
        })
      }
    }
  }

  useEffect(() => {
    setCountries()
  }, [countries, providerDetails?.regions])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        setCountries()
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    dispatch(getCountriesStart({ limit: '', name: '', pageNo: '' }))
    dispatch(getCMSLanguagesStart({ adminId: '', tenantId: '' }))
  }, [])

  const updateData = ({ data, country }) => {
    dispatch(configProviderSettingsStart({
      data: {
        ...data,
        providerId
      },
      isTenant: false,
      providerId,
      country
    }))
    setSearch('')
    setLogoCheck('false')
  }

  return {
    providerDetails,
    loading,
    selectedKeyTab,
    setSelectedKeyTab,
    updateData,
    navigate,
    countries,
    data,
    setData,
    search,
    setSearch,
    myCountries,
    logoCheck,
    setLogoCheck,
    providerId,
    tenantLanguages,
    details,
    amtOptions,
    kycCountries,
    setKycCountries
  }
}

export default useEditProvider
