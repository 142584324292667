import React from "react";

import { Tabs, Tab, Row, Col } from "@themesberg/react-bootstrap";

import Preloader from "../../../components/Preloader";
import useAffiliateProfile from "./hooks/useAffiliateProfile";
import ProfileOverview from "./components/ProfileOverview";
import LinkCreater from "./components/LinkCreater";
import UpdateAffiliate from "../../Super-Admin/Affiliates/components/UpdateAffiliate";

const AffiliateProfile = () => {
  const { loading, selectedTab, setSelectedTab, basicInfo, kycInfo, affiliateDetails, isEditable, setIsEditable } =
    useAffiliateProfile();

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <div className="section-header-wrap mt-n3 mx-n3">
            <Row>
              <Col lg={8} xs={12}>
                <div className="d-flex align-items-center">
                  <h2 className="title-text">Meu Perfil:&nbsp;</h2>
                  <h4 className="mb-0">
                    {`${affiliateDetails?.affiliate?.firstName} ${affiliateDetails?.affiliate?.lastName}`}
                  </h4>
                </div>
              </Col>
            </Row>
          </div>
          <Tabs
            activeKey={selectedTab}
            onSelect={(tab) => setSelectedTab(tab)}
            className="nav-light"
            mountOnEnter
            unmountOnExit
          >
            <Tab eventKey="overview" title="Visão Geral">
              <div className="mt-3">
                <ProfileOverview
                  basicInfo={basicInfo}
                  user={affiliateDetails}
                  affiliate={affiliateDetails?.affiliate}
                  kycInfo={kycInfo}
                  setIsEditable={setIsEditable}
                />
              </div>
            </Tab>
            <Tab eventKey="linkCreater" title="Links">
              <div className="mt-3">
                <LinkCreater affiliate={affiliateDetails.affiliate} />
              </div>
            </Tab>
          </Tabs>
        </>
      )}
    </>
  );
};

export default AffiliateProfile;
