import { Button, Col, Modal, Row, Form as BForm } from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { getAllPortalsStart } from '../../../store/redux-slices/tenants.js'
import { bannerType } from './constants.js'
import { uploadBannerSchema } from './schema.js'

const EditUploadBanner = ({
  type,
  data,
  show,
  setShow,
  createUpdate,
  clientsFilterData,
  portalsFilterData,
  dispatch,
  SABanners,
  validation,
  setValidation
}) => {
  const [selectedClient, setSelectedClient] = useState('')
  useEffect(() => {
    if (selectedClient) {
      dispatch(getAllPortalsStart({ adminId: selectedClient }))
    }
  }, [selectedClient])

  useEffect(() => {
    data?.adminId && setSelectedClient(data?.adminId)
  }, [data?.adminId])

  const mobileBannerValidations = {
    minRequiredWidth: 200,
    minRequiredHeight: 200,
    maxRequiredWidth: 800,
    maxRequiredHeight: 800
  }
  return (
    
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{type} Banner</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Formik
            enableReinitialize
            initialValues={{
              thumbnail: null,
              adminId: data?.adminId || '',
              tenantId: data?.tenantId || '',
              bannerType: bannerType?.find((item) => item?.value === data?.bannerType)?.value || '',
              isMobileBanner: false
            }}
            validationSchema={uploadBannerSchema(
              {
                type,
                minRequiredWidth: validation?.minRequiredWidth,
                minRequiredHeight: validation?.minRequiredHeight,
                maxRequiredWidth: validation?.maxRequiredWidth,
                maxRequiredHeight: validation?.maxRequiredHeight
              })}
            onSubmit={(formValues) => {
              createUpdate(formValues)
              setShow(false)
            }}
          >
            {({
              values,
              errors,
              handleSubmit,
              handleBlur,
              setFieldValue,
              handleChange
            }) => (
              <Form>
                <Row className='mt-3'>
                  {/* <Col className='d-flex align-items-center'>
                    <Col>
                      <BForm.Label>Owner
                        <span className='text-danger'> *</span>
                      </BForm.Label>
                    </Col>

                    <Col xs={9}>
                      <BForm.Select
                        type='select'
                        name='adminId'
                        value={values?.adminId}
                        disabled={data?.tenantId}
                        onChange={(e) => {
                          handleChange(e)
                          setSelectedClient(e.target.value)
                        }}
                        onBlur={handleBlur}
                      >
                        <option key='' value=''>Select</option>
                        {clientsFilterData?.rows?.map(
                          ({ adminUserId, firstName, lastName }) => (
                            <option key={adminUserId} value={adminUserId}>
                              {`${firstName} ${lastName}`}
                            </option>
                          )
                        )}
                      </BForm.Select>

                      <ErrorMessage
                        component='div'
                        name='adminId'
                        className='text-danger'
                      />
                    </Col>
                  </Col> */}
                </Row>
                {/* <Row className='mt-3'>
                  <Col className='d-flex align-items-center'>
                    <Col>
                      <BForm.Label>
                        
                        <span className='text-danger'> *</span>
                      </BForm.Label>
                    </Col>

                    <Col xs={9}>
                      <BForm.Select
                        type='select'
                        name='tenantId'
                        value={values?.tenantId}
                        disabled={data?.tenantId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option key='' value=''>Select</option>
                        {selectedClient && portalsFilterData?.rows?.map(
                          ({ tenantId, name, domain }) => (
                            <option key={tenantId} value={tenantId}>
                              <div>
                                <p>{`${name} ( ${domain} )`}</p>
                              </div>
                            </option>
                          )
                        )}
                      </BForm.Select>

                      <ErrorMessage
                        component='div'
                        name='tenantId'
                        className='text-danger'
                      />
                    </Col>
                  </Col>
                </Row> */}

                <Row className='mt-3'>
                  <Col className='d-flex align-items-center'>
                    <Col>
                      <BForm.Label>Type
                        <span className='text-danger'>*</span>
                      </BForm.Label>
                    </Col>

                    <Col xs={9}>
                      <BForm.Select
                        type='select'
                        name='bannerType'
                        disabled={type === "Update"}
                        value={values?.bannerType}
                        onChange={(e) => {
                          handleChange(e)
                          if (values.isMobileBanner === false) {
                            if (e.target.value.substr(e.target.value?.length - 6) === 'Banner') {
                              setValidation({
                                minRequiredWidth: 1600,
                                minRequiredHeight: 300,
                                maxRequiredWidth: 1800,
                                maxRequiredHeight: 400
                              })
                            }
                          } else {
                            setValidation({...mobileBannerValidations})
                          }
                        }}
                        onBlur={handleBlur}
                      >
                        <option key='' value=''>Select</option>
                        {/* 
                        {type =="Update" ?
                          bannerType?.map(({ label, value }, i) => {
                          const selectedTenantData = SABanners?.find(item => parseInt(values?.tenantId) === item?.tenantId)
                          if (!data?.tenantId && (parseInt(values?.tenantId) === selectedTenantData?.tenantId && selectedTenantData?.bannerUrl?.[value])) {
                            return null
                          }
                          return (
                            <option key={i} value={value}>{label}</option>
                          )})
                          :bannerType?.map(({ label, value }, i) => {
                          let hideData=false
                         const data= SABanners?.map(item => {
                          Object.keys(item?.value).map((key)=>{
                            if(key===value){
                              hideData=true
                            }
                          }
                          )})                       
                         if(hideData){
                           return null
                         }
                          return (
                            <option key={i} value={value}>{label}</option>
                          )
                        })} */
                          bannerType.map((banner) => (
                            <option key={banner.value} value={banner.value}>{banner.label}</option>
                          ))
                        }


                      </BForm.Select>

                      <ErrorMessage
                        component='div'
                        name='bannerType'
                        className='text-danger'
                      />
                    </Col>
                  </Col>
                </Row>

                <Row className='mt-3'>
                  <Col className='d-flex align-items-center'>
                    <Col>
                      <BForm.Label>Banner
                        <span className='text-danger'> *</span>
                      </BForm.Label>
                    </Col>

                    <Col xs={9}>
                      <BForm.Control
                        type='file'
                        name='thumbnail'
                        disabled={!values?.bannerType}
                        onChange={(event) => setFieldValue('thumbnail', event.target.files[0])}
                        onBlur={handleBlur}
                      />

                      <ErrorMessage
                        component='div'
                        name='thumbnail'
                        className='text-danger'
                      />
                    </Col>


                  </Col>
                </Row>

                {!errors?.thumbnail &&
                  (type === 'Create'
                    ? values?.thumbnail &&
                    <Row className='text-center'>
                      <Col>
                        <img
                          alt='not found'
                          className='mt-2'
                          style={{ maxWidth: '300px', maxHeight: '300px' }}
                          src={
                            values?.thumbnail &&
                            URL.createObjectURL(values?.thumbnail)
                          }
                        />
                      </Col>
                    </Row>
                    : (
                      <Row className='text-center'>
                        <Col>
                          <img
                            alt='not found'
                            className='mt-2'
                            style={{ maxWidth: '300px', maxHeight: '300px' }}
                            src={
                              values?.thumbnail
                                ? URL.createObjectURL(values?.thumbnail)
                                : `${process.env.REACT_APP_AWS_GALLERY_URL}${data?.thumbnail}`
                            }
                          />
                        </Col>
                      </Row>))}
                <Row className='mt-3'>
                  <Col className='d-flex'>
                    <BForm.Label>
                      Is Mobile Banner <span className='text-danger'>*</span>
                    </BForm.Label>

                    <BForm.Check
                      type='checkbox'
                      className='mx-auto'
                      name='isMobileBanner'
                      onChange={(e) => {
                        if (e.target.checked) {
                          setValidation({...mobileBannerValidations})
                        } else {
                          setValidation({
                            minRequiredWidth: 1600,
                            minRequiredHeight: 300,
                            maxRequiredWidth: 1800,
                            maxRequiredHeight: 400
                          })
                        }
                        handleChange(e)
                      }}
                      onBlur={handleBlur}
                      value={values.isMobileBanner}
                    />
                  </Col>
                </Row>

                <div className='mt-4 d-flex justify-content-between align-items-center'>
                  <Button
                    className='btn-danger'
                    onClick={() => setShow(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className='btn-primary ml-2'
                    disabled={!values?.thumbnail}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    
  )
}

export default EditUploadBanner
