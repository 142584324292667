import { Row, Col, Table, Dropdown, Button, Form } from '@themesberg/react-bootstrap'
import React from 'react'
import { motion } from 'framer-motion/dist/framer-motion'
import Trigger from '../OverlayTrigger'
import useLanguageMgmt from './hooks/useLanguageMgmt'
import { AdminsRoutes } from '../../routes'
import { keysNotToBeShown } from './hooks/constant'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-regular-svg-icons'
import { faDownload, faUpload } from '@fortawesome/free-solid-svg-icons'
import { languageCode } from '../../pages/Tenant/TenantConfigurations/Languages/constants'
import { toast } from '../Toast'
import UploadModal from './components/uploadModal'
import './style.css'
import Select from 'react-select'
import Preloader from '../Preloader'
import useCheckPermission from '../../utils/checkPermission'

export default ({
  isTenant = false
}) => {
  const {
    myKeyData,
    navigate,
    handleDownload,
    handleUpload,
    uploadModal,
    setUploadModal,
    availableLanguages,
    setKeySearch,
    keyOptions,
    myKeys,
    search,
    setSearch,
    myLanguages
  } = useLanguageMgmt({ isTenant })

  const { isHidden } = useCheckPermission()

  return (
    <>
      <div className='section-header-wrap mt-n3 mx-n3'>
        <Row>
          <Col lg={8} xs={7}>
            <h2 className='title-text'>Language Management</h2>
          </Col>
          <Col lg={4} xs={5}>
            <div className='d-flex justify-content-end'>
              {!isHidden({ module: { key: 'MultiLanguage', value: 'U' } }) &&
                <div className='m-1'>
                  <Dropdown
                    className='d-inline'
                  >
                    <Dropdown.Toggle id='dropdown-autoclose-outside' className='float-end btn-primary'>
                      Add Language
                    </Dropdown.Toggle>

                    <Dropdown.Menu className='user-dropdown language'>
                      {availableLanguages?.length
                        ? availableLanguages?.map(lang => {
                          return (
                            <Dropdown.Item
                              key={lang}
                              onClick={() => navigate(`/admin/language-management/add-language/${lang}`)}
                            >{languageCode[lang]}
                            </Dropdown.Item>
                          )
                        })
                        : (
                          <Dropdown.Item
                            className='text-danger'
                            disabled
                          ><strong>No Languages Available To Add</strong>
                          </Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>}
              {!isHidden({ module: { key: 'MultiLanguage', value: 'U' } }) &&
                <div className='m-1'>
                  <Dropdown
                    className=' d-inline'
                  >
                    <Dropdown.Toggle id='dropdown-autoclose-outside' className='float-end btn-primary'>
                      Edit
                    </Dropdown.Toggle>

                    <Dropdown.Menu className='user-dropdown lang'>
                      <Dropdown.Item
                        onClick={() => navigate(AdminsRoutes.EditSupportLanguage)}
                      >Edit by Position
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => navigate(AdminsRoutes.EditSupportLanguageKeys)}
                      >Edit By Key
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>}
              <Trigger message='Download Xls File'>
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Button
                    variant='btn btn-primary'
                    className='m-1'
                    onClick={() => {
                      const newWindow = window.open(
                        handleDownload(),
                        'csv_window',
                        'width=700,height=700'
                      )

                      setTimeout(() => {
                        newWindow.close()
                        toast('File Downloaded', 'success')
                      }, 1000)
                    }}
                  >
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                </motion.div>
              </Trigger>
              <Trigger message='Upload Xls File'>
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Button
                    variant='btn btn-primary'
                    className='m-1'
                    hidden={isHidden({ module: { key: 'MultiLanguage', value: 'C' } })}
                    onClick={() => setUploadModal(true)}
                  >
                    <FontAwesomeIcon icon={faUpload} />
                  </Button>
                </motion.div>
              </Trigger>
            </div>
          </Col>
        </Row>
      </div>
      <div className='FormsBg'>
        <Row className='d-flex'>
          <Col xs='3'>
            {/* <Form.Label>Search Keys : </Form.Label> &nbsp; */}
            <Select
              name='keys'
              options={keyOptions}
              placeholder='Select Keys'
              defaultValue={{ label: 'All', value: '' }}
              styles={{ container: styles => ({ ...styles, zIndex: '2' }) }}
              className='basic-multi-select zindex-dropdown m-1 mt-0'
              classNamePrefix='select'
              onChange={(option, e) => {
                setKeySearch(option.value)
              }}
            />
          </Col>
          <Col xs='3'>
            {/* <Form.Label>Search : </Form.Label>&nbsp; */}
            <Form.Control
              name='search'
              type='search'
              placeholder='Search'
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
            />
          </Col>
          <Col />
        </Row>
      </div>
      {(!myKeys || !Object.keys(myKeys).length)
        // ? <Preloader />
        // NOTE: TEMPORARY FIX
        // TODO: FIX ME
        ? <></>
        : (
          <>

            <div style={{ overflow: 'auto', maxHeight: '82vh', marginTop: '10px' }}>
              <div className='TableBg mt-4 pt-1 language-table-wrap'>
                <Table
                  striped
                  size='sm'
                >
                  <thead className='thead-light fixTableHeadSA-th'>
                    <tr>
                      <th>Keys</th>
                      {!isHidden({ module: { key: 'MultiLanguage', value: 'U' } }) && <th>Action</th>}
                      {Array.from(myLanguages).map(language => {
                        return myLanguages.has(language) && (
                          <th key={language}>{languageCode[language]}</th>
                        )
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {myKeyData && Object.keys(myKeyData).map(key => {
                      return (
                        !keysNotToBeShown.includes(key) && (
                          <tr key={key}>
                            <td className='fw-bold fs-6 language-key-col'>{key}</td>
                            {!isHidden({ module: { key: 'MultiLanguage', value: 'U' } }) &&
                              <td className='language-action-col'>
                                <Button
                                  variant='btn btn-primary'
                                  size='sm'
                                  hidden={isHidden({ module: { key: 'MultiLanguage', value: 'U' } })}
                                  onClick={() => {
                                    navigate(`/admin/language-management/edit/${key}`)
                                  }}
                                >
                                  <FontAwesomeIcon icon={faEdit} />
                                </Button>
                              </td>
                            }
                            {
                              Array.from(myLanguages).map(lang => {
                                return (
                                  <td key={lang} className='language-english-col'>
                                    <Trigger message={myKeyData[key][lang]}>
                                      <div
                                        className='language-term-text'
                                      // style={{
                                      // 	width: '300px',
                                      // 	cursor: 'pointer'
                                      // }}
                                      >
                                        {myKeyData[key][lang]}
                                      </div>
                                    </Trigger>
                                  </td>
                                )
                              })
                            }

                          </tr>
                        )
                      )
                    })}
                  </tbody>
                </Table>
              </div>
              {(!myKeyData || !Object.keys(myKeyData)?.length) && (
                <p className='text-danger text-center'> No data found</p>
              )}
            </div>
          </>)}

      {uploadModal &&
        <UploadModal
          show={uploadModal}
          setShow={setUploadModal}
          handleYes={handleUpload}
        />}
    </>
  )
}
