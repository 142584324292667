import React from "react";

import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Row,
  Spinner,
} from "@themesberg/react-bootstrap";

const ProfileOverview = (props) => {
  const { basicInfo, user, kycInfo, setIsEditable, affiliate } = props;
  const navigate = useNavigate()

  const handleEditClick = () => {
    navigate(`/affiliate/update-profile/${user?.affiliate?.affiliateId}`)
  }
  return (
    <>
      <Row>

        <Col lg={6} xs={12} className="col-padding ">
          <Card className="card-overview">
            <h4 className="h4-overview text-center mt-1">
              <div className="d-flex align-items-center justify-content-between">
                <span>Informações básicas</span>
                <Button size="sm" className="px-3" onClick={handleEditClick}>Edit</Button>
              </div>

              <hr className="h4-hr mt-1" />
            </h4>
            {basicInfo?.map(({ label, value, subValue }) => {
              return user?.kycMethod !== 1 && label === "Applicant Id"
                ? ""
                : (label === "Reason" && value ? true : label !== "Reason") && (
                  <div
                    key={label}
                    className="d-flex justify-content-between m-1"
                  >
                    <h6 className="px-2 overview-leftlabel">{label}</h6>
                    <span className={`${subValue} px-2`}>
                      {value || "N/A"}
                    </span>
                  </div>
                );
            })}
          </Card>
        </Col>

        <Col lg={6} xs={12} className="">
          <Card className="card-overview">
            <h4 className="h4-overview text-center mt-1">
              Outras informações <hr className="h4-hr mt-1" />
            </h4>
            <div className="d-flex justify-content-between m-1">
              <h6 className="px-2 overview-leftlabel">Affiliate Token</h6>
              <span className="px-2">{user?.streamerId || "N/A"}</span>
            </div>
            <div className="d-flex justify-content-between m-1">
              <h6 className="px-2 overview-leftlabel">Affiliate Status</h6>
              {user?.status ? (
                <span className="text-success px-2">Vinculado</span>
              ) : (
                <span className="text-danger px-2">Não vinculado</span>
              )}
            </div>


          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ProfileOverview;
