import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getImageGalleryStart } from '../../../store/redux-slices/emailTemplate'
import { getLanguagesStart, getLanguageSupportKeysStart, updateLanguageSupportStart } from '../../../store/redux-slices/languages'
import { getItem } from '../../../utils/storageUtils'
import { keysNotToBeShown } from './constant'

const useLanguageWise = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { keys } = useParams()
  const isTenant = !window.location.href.match(/super/g)
  const tenantId = getItem('tenant-id')
  const [selectedTab, setSelectedTab] = useState('EN')
  const [isError, setIsError] = useState({})
  const { languageKeys, languages, languageTabs, loading } = useSelector(state => state.languages)
  const { tenantDetails } = useSelector(state => state.login)
  const [myKeys, setMyKeys] = useState({})
  const [selectedKey, setSelectedKey] = useState()
  const [check, setCheck] = useState(false)
  const [show, setShow] = useState(false)
  const [myKeyData, setMyKeyData] = useState({})
  const [askModal, setAskModal] = useState(false)
  const [selectedKeyTab, setSelectedKeyTab] = useState('')
  const [mySelectedTab, setMySelectedTab] = useState('')
  const [galleryModal, setGalleryModal] = useState(false)

  useEffect(() => {
    setCheck(true)
    setTimeout(() => setCheck(false), 100)
  }, [selectedTab])

  useEffect(() => {
    !isTenant && dispatch(getLanguagesStart({ limit: '', pageNo: '', name: '' }))
    dispatch(getLanguageSupportKeysStart({ isTenant, tenantId: isTenant ? tenantId : '', language: '' }))
    dispatch(getImageGalleryStart({ isTenant }))
  }, [])

  useEffect(() => {
    if (languageTabs) {
      setSelectedKeyTab(Object.keys(languageTabs)?.[0])
    }
  }, [languageTabs])

  useEffect(() => {
    setIsError({})
  }, [selectedTab])

  useEffect(() => {
    if (languageKeys && languageKeys?.length) {
      const data = {}
      const data2 = {}
      for (const key in languageKeys[0]) {
        if (!keysNotToBeShown.includes(key)) data[key] = ''
        if (keys) {
          const keyData = {}
          for (const i in languageKeys) {
            keyData[languageKeys[i].language] = languageKeys[i][key]
          }
          data2[key] = keyData
        }
      }
      setMyKeys(data)
      setMyKeyData(data2)
      setSelectedKey({
        label: keys === ':keys' ? Object.keys(data)[0] : keys,
        value: keys === ':keys' ? Object.keys(data)[0] : keys
      })
    }
  }, [languageKeys])

  const keyOptions = []
  myKeys && keys &&
  Object.keys(myKeys).map(element =>
    keyOptions.push({ label: element, value: element })
  )

  const handleYes = () => {
    setSelectedTab(mySelectedTab)
    setShow(false)
  }

  const updateData = (data) =>
    dispatch(updateLanguageSupportStart({ data, isTenant, tenantId: isTenant ? tenantId : '', navigate }))

  return {
    languageKeys,
    navigate,
    isTenant,
    languages: isTenant ? tenantDetails?.tenantConfig?.allowedLanguages : languages,
    selectedTab,
    setSelectedTab,
    myKeys,
    keyOptions,
    setSelectedKey,
    selectedKey,
    myKeyData,
    keys,
    askModal,
    setAskModal,
    languageTabs,
    selectedKeyTab,
    setSelectedKeyTab,
    loading,
    updateData,
    isError,
    setIsError,
    check,
    show,
    setShow,
    handleYes,
    setMySelectedTab,
    galleryModal,
    setGalleryModal
  }
}

export default useLanguageWise
