import { Button, Form, FormCheck, InputGroup, Modal, Table } from '@themesberg/react-bootstrap'
import { ErrorMessage, Formik } from 'formik'
import React from 'react'
import useManageMoney from './hooks/useManageMoney'
import { depositSchema } from './schemas'

const ManageMoney = ({
  show,
  handleClose,
  user,
  isTenant = false
}) => {
  const {
    deposit
  } = useManageMoney({ isTenant })

  return (
    <>
      <Formik
        initialValues={{
          addAmount: '',
          transactionType: '',
          walletType: ''
        }}
        validationSchema={depositSchema}
        onSubmit={(formValues, { resetForm }) => {
          deposit(formValues)
          handleClose()
          resetForm({ formValues: '' })
        }}
      >
        {({ values, touched, errors, handleChange, handleBlur, handleSubmit, resetForm }) => (
          <Form>
            <Modal
              show={show}
              onHide={handleClose}
              backdrop='static'
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>Gerenciar o dinheiro de: '{user?.firstName} {user?.lastName}'</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Table>
                  <tbody>
                    <tr>
                      <td>
                        <h6>Tipo de transações</h6>
                        <ErrorMessage
                          component='div'
                          name='transactionType'
                          className='text-danger'
                        />
                      </td>
                      <td>
                        <FormCheck.Input
                          type='radio'
                          id='add-money'
                          name='transactionType'
                          label='Adicionar Dinheiro'
                          aria-label='Adicionar Dinheiro'
                          value='add-money'
                          style={{ marginRight: '10px' }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <FormCheck.Label htmlFor='add-money'>Adicionar Dinheiro</FormCheck.Label>
                      </td>
                      <td>
                        <FormCheck.Input
                          type='radio'
                          id='remove-money'
                          name='transactionType'
                          label='Remove Money'
                          aria-label='Remove Money'
                          value='remove-money'
                          style={{ marginRight: '10px' }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <FormCheck.Label htmlFor='remove-money'>Remover Dinheiro</FormCheck.Label>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6>Tipo de carteira</h6>
                        <ErrorMessage
                          component='div'
                          name='walletType'
                          className='text-danger'
                        />
                      </td>
                      <td>
                        <FormCheck.Input
                          type='radio'
                          id='cash'
                          name='walletType'
                          aria-label='Carteira de dinheiro real'
                          value='cash'
                          style={{ marginRight: '10px' }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <FormCheck.Label htmlFor='cash'>Dinheiro Real</FormCheck.Label>
                      </td>
                      <td>
                        <FormCheck.Input
                          type='radio'
                          id='non-cash'
                          name='walletType'
                          aria-label='Carteira de bônus'
                          value='Bônus'
                          style={{ marginRight: '10px' }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <FormCheck.Label htmlFor='non-cash'>Bônus</FormCheck.Label>
                      </td>
                    </tr>
                  </tbody>
                </Table>

                <div className='form-group'>
                  <label
                    htmlFor='addAmount'
                    className={touched.addAmount && errors.addAmount ? 'text-danger' : ''}
                  >
                    Valor
                  </label>

                  <InputGroup
                    className={touched.addAmount && errors.addAmount
                      ? 'border border-danger'
                      : ''}
                  >
                    <InputGroup.Text>
                      {user?.currencyCode}
                    </InputGroup.Text>

                    <Form.Control
                      name='addAmount'
                      type='number'
                      step='any'
                      placeholder='Enter the amount'
                      value={values.addAmount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </InputGroup>

                  <ErrorMessage
                    component='div'
                    name='addAmount'
                    className='text-danger'
                  />
                </div>
              </Modal.Body>
              <div className='mt-4 '>
                <Modal.Footer className='d-flex justify-content-between align-items-center'>
                  <Button
                    variant='outline-warning'
                    onClick={() => {
                      resetForm({ formValues: '' })
                      handleClose(false)
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant='outline-success'
                    onClick={handleSubmit}
                    className='ml-2'
                  >
                    Enviar
                  </Button>
                </Modal.Footer>
              </div>
            </Modal>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ManageMoney
