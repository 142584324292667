import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { affiliateLoginStart } from '../../../store/redux-slices/login'
import { getLoginToken, removeLoginToken } from '../../../utils/storageUtils'
import { AdminsRoutes, AffiliateRoutes } from '../../../routes'
import useRole from '../../../hooks/useRole'

const useAffiliateSignin = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loading } = useSelector((state) => state.login);
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  const { Admin, SuperAdmin, Affiliate } = useRole();

  useEffect(() => {
    if (getLoginToken()) {
      if (Admin || SuperAdmin) navigate(AdminsRoutes.Dashboard);
      if (Affiliate) navigate(AffiliateRoutes.Dashboard);
    } if (window.location.pathname.includes('affiliate')) {
      return
    } else {
      removeLoginToken()
    }
  }, [])

  const onSubmit = ({ user, password }) => {
    dispatch(affiliateLoginStart({ user, password, navigate }))
  }

  return {
    loading,
    onSubmit,
    navigate,
    email
  }
}

export default useAffiliateSignin
