import React, { useEffect } from 'react'
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

// import TenantPages from './components/TenantPages1'
import SuperAdminPages from './components/SuperAdminPages'

import { AdminsRoutes, CommonRoutes, AffiliateRoutes } from '../../routes'
import NotFound from '../NotFound'
import SuperAdminSignIn from '../Super-Admin/SuperAdminSignin'
// import TenantSignIn from '../Tenant/TenantSignin'
import { getItem } from '../../utils/storageUtils'
// import TenantNotFound from '../TenantNotFound'
import ForgotPassword from './ForgotPassword'
import AffiliateSigninPage from '../Affiliates/AffiliateSigninPage'
import AffiliateSignupPage from '../Affiliates/AffiliateSignupPage'
import AffiliatePages from './components/AffiliatePages'

import config from '../../configs/app.config';
import Preloader from '../../components/Preloader';

const IndexRedirect = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (typeof window) {
      const currentHost = window.location.host;
      const adminHost = config.app.adminBaseUrl ? new URL(config.app.adminBaseUrl).host : null;
      const affiliategHost = config.app.affiliateBaseUrl ? new URL(config.app.affiliateBaseUrl).host : null;

      if (adminHost && currentHost == adminHost) navigate(AdminsRoutes.SuperAdminSignin)
      else if (affiliategHost && currentHost == affiliategHost) navigate(AffiliateRoutes.AffiliateSignIn)
      else navigate(CommonRoutes.NotFound);
    }
  }, [])
  return <Preloader center />
}

export default () => {
  // let { role } = useSelector((state) => state.login)
  const token = getItem('access-token')
  const navigate = useNavigate()

  // if (!role) {
  //   role = getItem('role')
  // }
  const isSuperAdmin = window.location.host === process.env.REACT_APP_SA_URL
  // const isTenant = window.location.host === process.env.REACT_APP_TA_URL

  useEffect(() => {
    if (!token && !window.location.pathname.includes('reset-password')) {
      isSuperAdmin && navigate(AdminsRoutes.SuperAdminSignin)
    }
  }, [token])

  return (
    <Routes>
      <Route index element={<IndexRedirect />} />

      {/* Public Routes without sidebar */}
      <Route
        path={AdminsRoutes.SuperAdminSignin}
        element={<SuperAdminSignIn />}
      />

      {/* <Route path={CommonRoutes.TenantNotFound} element={<TenantNotFound />} /> */}

      {/* <Route path={TenantRoutes.TenantSignin} element={<TenantSignIn />} /> */}

      <Route path={CommonRoutes.NotFound} element={<NotFound />} />

      <Route path={CommonRoutes?.ForgotPassword} element={<ForgotPassword />} />

      <Route path={CommonRoutes?.ResetPassword} element={<ForgotPassword />} />

      {SuperAdminPages()}

      {/* {role === 'Admin' && TenantPages()} */}

      <Route path={AffiliateRoutes?.AffiliateSignIn} element={<AffiliateSigninPage />} />
      <Route path={AffiliateRoutes?.AffiliateSignUp} element={<AffiliateSignupPage />} />

      {AffiliatePages()}

      <Route
        path='*'
        element={<Navigate replace to={CommonRoutes.NotFound} />}
      />
    </Routes>
  )
}
