import React from 'react'
import {
  Button,
  Form as BForm,
  Row,
  Col,
  Modal,
  Spinner
} from '@themesberg/react-bootstrap'
import { Formik, Form, ErrorMessage } from 'formik'
import { addForm, editForm } from '../schema'
import Preloader from '../../../../components/Preloader'
import ClientFilter from '../../../../components/ClientFilter'
import PortalFilter from '../../../../components/PortalFilter'

const AddEditReview = ({
  handleClose,
  data,
  show,
  type,
  loading,
  addReviewHandler,
  updateReviewHandler,
  selectedClient,
  selectedPortal,
  setSelectedClient,
  setSelectedPortal
}) => {
  return (
    <>
      {loading
        ? <Preloader />
        : (
          <Formik
            initialValues={{
              userName: data?.userName || '',
              status: data?.status || false,
              description: data?.description || '',
              rating: data?.rating || '1'
            }}
            enableReinitialize
            validationSchema={
              data?.reviewId ? editForm : addForm
            }
            onSubmit={(formValues) => {
              data?.reviewId
                ? updateReviewHandler(formValues, Number(data?.reviewId))
                : addReviewHandler(formValues)

              handleClose()
            }}
          >
            {({ values, handleChange, handleSubmit, handleBlur, setFieldValue }) => (
              <Form>
                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop='static'
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>{type} Review</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>

                    {/* {!data?.reviewId &&
                    (<>
                      <Row>
                        <Col>
                          <ClientFilter
                            setSelectedClient={setSelectedClient}
                            setSelectedPortal={setSelectedPortal}
                            selectedClient={selectedClient}
                          />
                        </Col>
                      </Row>

                      <Row>
                        {selectedClient && (
                          <Col>
                            <PortalFilter
                              setSelectedPortal={setSelectedPortal}
                              selectedPortal={selectedPortal}
                              selectedClient={selectedClient}
                            />
                          </Col>
                        )}
                      </Row>
                     </>)} */}

                    <Row className='mt-3'>
                      <Col>
                        <BForm.Label>
                         User Name<span className='text-danger'> *</span>
                        </BForm.Label>

                        <BForm.Control
                          type='text'
                          name='userName'
                          placeholder='Enter Provider Name'
                          value={values.userName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <ErrorMessage
                          component='div'
                          name='userName'
                          className='text-danger'
                        />
                      </Col>
                    </Row>

                    <Row className='mt-3'>
                      <Col>
                        <BForm.Label>
                          Description<span className='text-danger'> *</span>
                        </BForm.Label>

                        <BForm.Control
                          as='textarea'
                          name='description'
                          placeholder='Enter description'
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <ErrorMessage
                          component='div'
                          name='description'
                          className='text-danger'
                        />
                      </Col>
                    </Row>

                    <Row className='mt-3'>
                      <Col>
                        <BForm.Label>
                          Rating<span className='text-danger'> *</span>
                        </BForm.Label>

                        <BForm.Select
                          type='text'
                          name='rating'
                          value={values.rating}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option disabled>---Select Rating---</option>
                          {[1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5].map((item) => {
                            return (
                              <option value={item} key={item}>
                                {item}
                              </option>
                            )
                          })}
                        </BForm.Select>

                        <ErrorMessage
                          component='div'
                          name='rating'
                          className='text-danger'
                        />
                      </Col>
                    </Row>

                    <Row className='mt-3'>
                      <Col sm='2'>
                        <BForm.Label>
                          Status<span className='text-danger'> *</span>
                        </BForm.Label>
                      </Col>
                      <Col sm='3'>
                        <BForm.Check
                          className='mx-auto'
                          type='checkbox'
                          name='status'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.status}
                          defaultChecked={data?.status}
                        />

                      </Col>
                    </Row>

                  </Modal.Body>

                  <div className='mt-4 '>
                    <Modal.Footer className='d-flex justify-content-between align-items-center'>
                      <Button className='btn-danger' onClick={() => handleClose()}>
                        Cancel
                      </Button>
                      <Button
                        className='btn-primary'
                        onClick={handleSubmit}
                      >
                        Submit
                        {loading && (
                          <Spinner
                            as='span'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        )}
                      </Button>
                    </Modal.Footer>
                  </div>
                </Modal>
              </Form>
            )}
          </Formik>)}
    </>
  )
}

export default AddEditReview
