import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";

import { DashboardHeadTitle, DashboardLiveContainer } from "../../style";
import useAffiliateStats from "../../hooks/useAffiliateStats";

import {
  CardInfo,
  CardItemGraph,
  ColorCircle,
  DataCard,
  InfoLabel,
  InfoValue,
  LegendLabelGraph,
  MainCard,
  StyledCard,
  StyledCardMain,
  TitleMain,
} from "./style";
import FormatNumber from "../../../../../utils/formatNumber";
import { AFFILIATE_TYPE, MONTHS } from "../../../../../utils/constant";
import { LiveCommission, GameReport, Chart } from "../../hooks/useDashboard";
import { getChartAnalysis } from "../../../../../utils/apiCalls";
import { useSelector } from "react-redux";
import classNames from "classnames";

const ggrClassName = (ggr) => ggr < 0 ? "text-danger" : ggr == 0 ? "" : "text-success"

const AffiliateStatsChart = ({ affiliate }) => {
  const { affiliateDashboard, dashboardData } = useAffiliateStats();
  /** @type { GameReport } */
  const liveCommission =
    affiliateDashboard?.liveComission?.gameReportsByMonthLive[
      affiliateDashboard?.liveComission.gameReportsByMonthLive.length - 1
    ]?.gameReport || {};

  /** @type { LiveCommission["gameReportsByMonthLive"] } */
  const livesReport = affiliateDashboard?.liveComission?.gameReportsByMonthLive;

  /** @type { LiveCommission["gameReportsByMonthLive"] } */

  /** @type { Chart } */
  const chartAnalysis = affiliateDashboard?.chart

  const newRegistrations = dashboardData?.newRegistredPlayers || 0;
  const renda = (affiliateDashboard?.yesterday?.gameReport?.commission || 0) + liveCommission?.commission || 0;
  const deposit = affiliateDashboard?.totalDepositCounts || 0;

  const averageDailyRegistrations = newRegistrations / 30;
  const ratioDeposit = affiliateDashboard?.totalDepositCounts || 0 / 30;

  const { role } = useSelector((state) => state.login)

  const cardData = [
    {
      info: [
        {
          label: "Quantidade de cadastros pelo link de afiliado",
          value: FormatNumber.toLongNumber(dashboardData?.allPlayers || 0),
        },
      ],
    },
    {
      info: [
        {
          label: "Quantidade (FTD)",
          value: FormatNumber.toLongNumber(dashboardData?.totalFirstIsDeposit || 0),
        },
        {
          label: "Valor total de (FTD)",
          value: FormatNumber.currency(dashboardData?.valueTotalFirstDeposit || 0),
        },
        {
          label: "Quantidade de depósitos:",
          value: affiliateDashboard?.totalDepositCounts || 0,
        },
        {
          label: "Valor total de depósitos",
          value: FormatNumber.currency(dashboardData?.valueTotalDeposit || 0),
        },
        {
          label: "Comissão de CPA",
          value: FormatNumber.currency(dashboardData?.cpaCommission || 0),
          className: ggrClassName(dashboardData?.cpaCommission || 0)
        },
        ...(role == "Admin" ? [] : []),
        ...(role == "Admin" ? [] : []),
        ...(role == "Admin" ? [] : []),
      ],
    },
  ];

  const date = [{ label: "Mês" }, { label: "Semana" }, { label: "Hoje" }];

  const [chartOptions, setChartOptions] = useState({});

  const createChartData = (/** @type {{ livesReport: LiveCommission["gameReportsByMonthLive"] }} */ { livesReport, chartAnalysis }) => {

    const liveCommisionByMonths = Object.keys(MONTHS).map((month) => {
      let monthData = null;
      if (livesReport) monthData = livesReport.find((e) => e.month == month);
      return monthData ? monthData.gameReport.commission : 0;
    });

    const fillMissingMonths = (data) => {
      return Object.keys(MONTHS).map((month) => {
        let foundData;
        if (data) foundData = data.find((record) => record[month] !== undefined);
        return foundData ? Number(foundData[month]) : 0;
      });
    };

    const records = fillMissingMonths(chartAnalysis?.records);
    const depositFirst = fillMissingMonths(chartAnalysis?.deposits);
    const comissionCasino = fillMissingMonths(chartAnalysis?.comissionCasino);

    return {
      title: {
        text: "",
      },
      xAxis: {
        categories: Object.values(MONTHS),
      },
      yAxis: {
        title: {
          text: "",
        },
      },
      series: [
        {
          name: "Cadastros",
          data: records,
          color: "#FF2C00",
          marker: {
            symbol: "circle",
          },
        },
        {
          name: "Primeiros depósitos",
          data: depositFirst,
          color: "#F1C143",
          marker: {
            symbol: "circle",
          },
        },
        ...((affiliate?.affiliateType == AFFILIATE_TYPE.STREAMER) ? [{
          name: "Comissão por lives",
          data: liveCommisionByMonths,
          color: "#00B69B",
          custom: { currency: true },
          marker: {
            symbol: "circle",
          },
        }] : []),
      ],
      tooltip: {
        formatter: function () {

          return `<strong style="color:${this.point.color}">\u25CF ${this.key
            }</strong><br/><span style="color:${this.point.color
            }">\u25CF</span> ${this.series.name}: ${this.series.options?.custom?.currency ? FormatNumber.currency(this.point?.y) : parseInt(Number(this.point?.y || 0))}`;
        },
      },
    };
  };

  useEffect(() => {
    setChartOptions(createChartData({ livesReport, chartAnalysis }));
  }, [livesReport, chartAnalysis]);

  return (
    <>
      <DashboardLiveContainer className={"mb-3"}>
        {chartOptions ? (
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        ) : null}
        <div style={{ display: "flex", gap: "10px" }} className="ms-4">
          <CardItemGraph>
            <ColorCircle color="#FF2C00" />
            <LegendLabelGraph>Cadastros</LegendLabelGraph>
          </CardItemGraph>
          <CardItemGraph>
            <ColorCircle color="#F1C143" />
            <LegendLabelGraph>Primeiros depósitos</LegendLabelGraph>
          </CardItemGraph>
          {affiliate?.affiliateType == AFFILIATE_TYPE.STREAMER ? <CardItemGraph>
            <ColorCircle color="#00B69B" />
            <LegendLabelGraph>Comissão por lives</LegendLabelGraph>
          </CardItemGraph> : null}
        </div>
      </DashboardLiveContainer>

      <DashboardHeadTitle className="flex-column flex-sm-row align-items-sm-center align-items-start">
        <TitleMain className="d-flex align-items-center">
          <FontAwesomeIcon
            icon={faClock}
            style={{ marginRight: "8px", width: "1.2rem", height: "1.2rem" }}
          />
          Performance
        </TitleMain>
        <StyledCardMain className="flex-row mt-4 mt-sm-0 ms-0 ms-sm-4">
          <div style={{ display: "flex" }}>
            {/* {date.map((item, index) => (
              <DataCard key={index}>{item.label}</DataCard>
            ))} */}
          </div>
          {/* <InfoValue style={{ color: "#00B69B" }}>
            Renda: {FormatNumber.currency(renda || 0)}
          </InfoValue> */}
        </StyledCardMain>
      </DashboardHeadTitle>

      <DashboardLiveContainer className="p-0">
        <MainCard>
          {cardData.map((card, index) => (
            <StyledCard key={index} last={index === cardData.length - 1}>
              {card.info.map((item, idx) =>
                item.label ? (
                  <CardInfo key={idx}>
                    <InfoLabel>{item.label}</InfoLabel>
                    <InfoValue className={classNames(item.className)}>{item.value}</InfoValue>
                  </CardInfo>
                ) : null
              )}
            </StyledCard>
          ))}
        </MainCard>
      </DashboardLiveContainer>
    </>
  );
};

export default AffiliateStatsChart;
