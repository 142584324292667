import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllBonusStart, updateReorderBonusStart } from '../../store/redux-slices/admins'
import { getAllTABonusStart } from '../../store/redux-slices/bonus'

const useBonusReorder = (isTenant) => {
  const dispatch = useDispatch()

  const { bonusList, loading } = useSelector((state) => state.admins)
  const { bonusList: TABonusList, loading: TALoading } = useSelector((state) => state.bonus)
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedPortal, setSelectedPortal] = useState('')
  const [bonuses, setBonuses] = useState({ rows: [], count: 0 })
  const [reOrderedBonus, setReOrderedBonus] = useState({ rows: [], count: 0 })
  const bonusData = isTenant ? TABonusList : bonusList

  const fetchTABonus = () => {
    dispatch(getAllTABonusStart({
      limit: '',
      pageNo: '',
      search: '',
      bonusType: '',
      isActive: '',
      reorder: true
    }))
  }

  const fetchSABonus = () => {
    dispatch(getAllBonusStart({
      adminId: selectedClient,
      tenantId: selectedPortal,
      limit: '',
      pageNo: '',
      search: '',
      bonusType: '',
      isActive: '',
      reorder: true
    }))
  }

  useEffect(() => {
    isTenant ? fetchTABonus() : fetchSABonus()
  }, [selectedPortal])

  useEffect(() => {
    if (reOrderedBonus?.rows && bonusData?.length) {
      const filteredArray = bonusData?.filter((item) => {
        const exist = reOrderedBonus?.rows.find(common => common.bonusId === item.bonusId)
        if (exist) {
          return false
        } else {
          return true
        }
      })
      setBonuses({ rows: filteredArray, count: filteredArray?.length || 0 })
    } else {
      setBonuses({ rows: bonusData, count: bonusData?.length || 0 })
    }
  }, [bonusData])

  const handleAddBonus = (item) => {
    setReOrderedBonus((oldItem) => {
      const newArray = [...oldItem?.rows, item]
      return { rows: newArray, count: newArray.length }
    })
    setBonuses((oldItem) => {
      const newArray = oldItem?.rows.filter((bonus) => bonus.bonusId !== item.bonusId)
      return { rows: newArray, count: newArray.length }
    })
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const rows = reorder(
      reOrderedBonus.rows,
      result.source.index,
      result.destination.index
    )
    setReOrderedBonus({ rows, count: rows.length })
  }

  const reorder = (reorderItem, startIndex, endIndex) => {
    const result = Array.from(reorderItem)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const handleRemoveBonus = (item) => {
    setBonuses((oldItem) => {
      const newArray = [...oldItem.rows, item]
      return { rows: newArray, count: newArray.length }
    })
    setReOrderedBonus((oldItem) => {
      const newArray = oldItem?.rows.filter((bonus) => bonus.bonusId !== item.bonusId)
      return { rows: newArray, count: newArray.length }
    })
  }

  const handleSave = () => {
    const orderedBonus = []
    const unOrderedBonus = []
    reOrderedBonus && reOrderedBonus.rows.map((list) => orderedBonus.push(list.bonusId))
    bonuses && bonuses.rows.map((list) => unOrderedBonus.push(list.bonusId))

    const data = {
      order: [...orderedBonus, ...unOrderedBonus],
      tenantId: selectedPortal,
      adminId: selectedClient
    }
    dispatch(updateReorderBonusStart({ data, isTenant }))
    setReOrderedBonus({ rows: [], count: 0 })
    setBonuses({ rows: [], count: 0 })
    fetchSABonus();
  }

  return {
    selectedClient,
    selectedPortal,
    loading: isTenant ? TALoading : loading,
    bonuses,
    setSelectedClient,
    setSelectedPortal,
    handleAddBonus,
    onDragEnd,
    reOrderedBonus,
    handleRemoveBonus,
    handleSave
  }
}

export default useBonusReorder
