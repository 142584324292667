import { ErrorMessage, Formik } from 'formik';
import React, { useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';

import * as Yup from 'yup';
import Button from '../../../../../../components/Button';
import { TRANSACTION_STATUS } from '../../../../SportsTransactions/constants';
import { resolveErrors } from '../../../../../../utils/errorTypes';
import { toast } from '../../../../../../components/Toast';
import { cancelAffiliateWithdrawRequest } from '../../../../../../utils/apiCalls';

export const CancelWithdralRequestFormSchema = () =>
  Yup.object().shape({
    reason: Yup.string().min(5).required('O motivo do cancelamento é obrigatório'),
    affiliateId: Yup.number().required('affiliateId Required'),
    affiliateWithdrawRequestId: Yup.number().required('affiliateWithdrawRequestId Required')
  })


const CancelAffiliateWithdraRequestApprovalModal = ({ status, affiliateWithdrawRequestId, withdrawRequestAffiliate, onSuccess = () => null }) => {
  const [loading, setLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleCancel = async (formData, { resetForm }) => {
    setLoading(true);
    try {
      await cancelAffiliateWithdrawRequest(formData);

      resetForm();
      setShowConfirmModal(false);
      onSuccess();
      toast("Solicitação de saque cancelada!", "success");
    } catch (err) {
      toast(resolveErrors(err?.response?.data?.errors, "Erro ao cancelar solicitação de saque"), "error");
    } finally {
      setLoading(false);
    }
  }

  const toggleConfirmModal = () => setShowConfirmModal(!showConfirmModal);

  const showData = [
    { label: 'Motivo do cancelamento', value: 'reason' }
  ];

  return (
    <>
      <Button
        variant="m-1 btn w-100 btn-danger"
        disabled={status == TRANSACTION_STATUS.PENDING ? false : true}
        onClick={toggleConfirmModal}
        loading={loading}
      >
        Cancelar
      </Button>
      <Formik
        initialValues={{ reason: '', affiliateId: withdrawRequestAffiliate.affiliateId, affiliateWithdrawRequestId }}
        validationSchema={CancelWithdralRequestFormSchema}
        onSubmit={handleCancel}
      >
        {({ values, handleChange, handleBlur, handleSubmit, isValid }) => (
          <Modal
            show={showConfirmModal}
            onHide={() => setShowConfirmModal(false)}
            backdrop='static'
            keyboard={false}
            size='md'
          >
            <Modal.Header closeButton>
              <h5>Confirmação de cancelamento de solicitação de saque</h5>
            </Modal.Header>
            <Modal.Body>
              <Row>
                {showData?.map(({ label, value }) => (
                  <Col key={value}>
                    <Form.Label>{label}</Form.Label>
                    <Form.Control
                      name={value}
                      as='textarea'
                      rows={3}
                      className='h-auto'
                      value={values[value]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <ErrorMessage
                      component='div'
                      name={value}
                      className='text-danger'
                    />
                  </Col>
                ))}

              </Row>
            </Modal.Body>
            <div className='mt-0'>
              <Modal.Footer className='d-flex justify-content-between align-items-center'>
                <Button
                  variant='outline-warning'
                  onClick={() => setShowConfirmModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant='outline-success'
                  className='ml-2'
                  disabled={!isValid}
                  onClick={handleSubmit}
                  loading={loading}
                >
                  Confirmar
                </Button>
              </Modal.Footer>
            </div>
          </Modal>
        )}

      </Formik>
    </>
  )
}

export default CancelAffiliateWithdraRequestApprovalModal;
