import React, { useState } from 'react'
import {
  Table,
  Row,
  Col,
  Button,
  Card,
  Accordion,
  Form
} from '@themesberg/react-bootstrap'
import { useDispatch } from 'react-redux'
import ActionMenu from './ActionMenu';
import { NoDataContainer } from '../SportsList/style'
import DivLoader from '../../../components/Loader/divLoader'
import Typography from '@mui/material/Typography';
import { InputGroup } from 'react-bootstrap'
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { motion } from 'framer-motion/dist/framer-motion'
import { updateCompanyOddStart } from '../../../store/redux-slices/sportsBook';
import Trigger from '../../../components/OverlayTrigger';
import { toast } from '../../../components/Toast';

const AccordionListing = ({
  toggleModal,
  isGetSportsMatchesDetailLoading,
  getSportsMatchesDetail,
  setMatchMarketId,
  toggleDetachMarketModal,
  handleChange,
  matchId,
  setIsAllEvents
}) => {
  const [varyPercentageMap, setVaryPercentageMap] = useState({});
  const dispatch = useDispatch()
  return (
    <div className="section-header-wrap mt-n3 mx-n3">
      <Row>
        <Col>
          <h4 className="sub-title-text">Event Detail</h4>
        </Col>
        {/* <Col lg={4} xs={5}>
          <div className="d-flex justify-content-end">
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <Button
                variant="btn btn-primary w-100"
                onClick={() => {
                  toggleModal()
                  setIsAllEvents(true)
                }}
                style={{ maxWidth: "180px" }}
              >
                <EditRoundedIcon className="me-2" />
                <span>Vary Odds</span>
              </Button>
            </motion.div>
          </div>
        </Col> */}
      </Row>
      <Card style={{ marginTop: "1.5rem" }}>
        <div>
          <Accordion defaultActiveKey={0}>
            {getSportsMatchesDetail?.rows?.map((item, accordinIndex) => {
              return (
                <Accordion.Item
                  eventKey={accordinIndex}
                  key={`label-${accordinIndex}`}
                  onClick={() => {
                    handleChange(item)
                    setVaryPercentageMap({})
                    setIsAllEvents(false)
                  }}
                >
                  <Accordion.Header>
                    <Typography sx={{ width: "25%", flexShrink: 0 }}>
                      {accordinIndex + 1}.{" "}
                      {item?.markets?.marketName[0].name || "-"}
                    </Typography>

                    <Typography sx={{ width: "25%", flexShrink: 0 }}>
                      <Typography sx={{ color: "text.secondary" }}>
                        Modification Type
                      </Typography>
                      <Typography>
                        {item?.modificationType || "-"}
                      </Typography>
                    </Typography>

                    <Typography sx={{ width: "20%", flexShrink: 0 }}>
                      <Typography sx={{ color: "text.secondary" }}>
                        Modification Value
                      </Typography>
                      <Typography>
                        {item?.modificationValue || "0"}
                      </Typography>
                    </Typography>

                    <Typography sx={{ width: "20%", flexShrink: 0 }}>
                      <Typography sx={{ color: "text.secondary" }}>
                        Detached
                      </Typography>
                      <Typography>
                        <span
                          className={
                            item?.detach ? "text-success" : "text-danger"
                          }
                        >
                          {item?.detach ? "YES" : "NO"}
                        </span>
                      </Typography>
                    </Typography>

                    <ActionMenu
                      item={item}
                      toggleModal={toggleModal}
                      // setMarketId={setMarketId}
                      setMatchMarketId={setMatchMarketId}
                      toggleDetachMarketModal={toggleDetachMarketModal}
                    />
                  </Accordion.Header>
                  <Accordion.Body>
                    <Table
                      striped
                      responsive
                      hover
                      size="sm"
                      className="text-center mt-3"
                    >
                      <thead className="thead-light">
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Feed Odd</th>
                          <th>Company Odd</th>
                          <th>Actions</th>
                        </tr>
                      </thead>

                      <tbody>
                        {item.outcome &&
                          item.outcome.map((value, index) => {
                            const handleSetClick = (value, item, index) => {
                              if (!varyPercentageMap[index] || Number(varyPercentageMap[index] < 0)) {
                                toast("Please Error a Valid Number.", 'error')
                              } else {
                                dispatch(updateCompanyOddStart({
                                  matchId: matchId,
                                  matchMarketId: Number(item?.matchMarketId),
                                  outcomeId: Number(value.outcomeId),
                                  modificationValue: Number(varyPercentageMap[index]),
                                  accordinIndex,
                                  companyIndex: index,
                                }))
                              }
                            };
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{value?.providerOutcomeId}</td>
                                <td>{value?.odd}</td>
                                <td>{value?.customOdd}</td>
                                <td>
                                  <Trigger message={item.detach ? '' : 'Detached the market First'}>
                                    <InputGroup style={{
                                      justifyContent: 'center',
                                      cursor: item.detach ? 'pointer' : 'not-allowed'
                                    }}>
                                      <Form.Control
                                        type="number"
                                        disabled={!item?.detach}
                                        style={{ maxWidth: '50%' }}
                                        onChange={(e) => {
                                          const newVaryPercentage = e.target.value;
                                          setVaryPercentageMap((prevState) => ({
                                            ...prevState,
                                            [index]: newVaryPercentage,
                                          }));
                                        }}
                                        value={varyPercentageMap[index] || ""}
                                        maxLength={3}
                                      />
                                      <Button
                                        className="btn-primary"
                                        style={{
                                          height: "35px",
                                          borderRadius: "5px",
                                        }}
                                        disabled={!item?.detach}
                                        onClick={() => handleSetClick(value, item, index)}
                                      >
                                        Set
                                      </Button>
                                    </InputGroup>
                                  </Trigger>

                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
              )
            }
            )}
          </Accordion>
          {isGetSportsMatchesDetailLoading && (
            <tr>
              <td colSpan="5">
                <NoDataContainer>
                  <DivLoader />
                </NoDataContainer>
              </td>
            </tr>
          )}
        </div>
      </Card >
    </div >
  )
}

export default AccordionListing
