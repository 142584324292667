import "./style.scss";
import React, { useEffect, useState } from "react";
import { Form as BForm, } from 'react-bootstrap';
import { InlineLoader } from '../Preloader';
import classNames from "classnames";
const fn = () => null;

const UploadImageInput = ({ name, onBase64Change = fn, onChangeFile = fn, src, className, orientation = "portrait", ...props }) => {
  const [loading, setLoading] = useState(Boolean(src));
  const [preview, setPreview] = useState(src);

  const onChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      onBase64Change(null);
      return;
    };
    setLoading(true);
    const reader = new FileReader();
    reader.onloadend = () => {
      onBase64Change(reader.result);
      setPreview(reader.result);
      setLoading(false);
    };
    onChangeFile(file)
    reader.readAsDataURL(file);
  }

  useEffect(() => {
    setPreview(src);
  }, [src]);

  return (
    <label htmlFor={`id-${name}`} className={classNames("upload-image-input", className)} data-orientation={orientation}>
      <BForm.Control id={`id-${name}`} onChange={onChange} {...props} {...{ type: "file" }} hidden />
      {preview ? <img src={preview} onLoad={() => setLoading(false)} onError={() => setLoading(false)} /> : null}
      {loading ? <span className={"upload-image-input-load"}><InlineLoader /></span> : "Enviar imagem"}
    </label>
  )
}

export default UploadImageInput;