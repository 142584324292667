export const AdminsRoutes = {
  SuperAdminSignin: '/admin/signin',
  Dashboard: '/admin/dashboard',
  TenantOwner: '/admin/tenants/owners',
  Tenants: '/admin/tenants/:adminId',
  CreateTenant: '/admin/tenants/create/:adminId',
  TenantDetails: '/admin/tenants/details/:adminId/:tenantId',
  Admins: '/admin/admins',
  AdminDetails: '/admin/admins/details/:adminId',
  Currencies: '/admin/currencies',
  Languages: '/admin/languages',
  CreateCurrencies: '/admin/create-currencies',
  EditCurrency: '/admin/currencies/edit/:currencyId',
  CreateTenantAdmin: '/admin/tenants/owners/create',
  EditTenant: '/admin/tenants/edit/:adminId/:tenantId',
  EditTheme: '/admin/edit-theme/:themeId',
  CreateTheme: '/admin/create-theme',
  Themes: '/admin/theme',
  Credential: '/admin/tenant/:tenantId/credential',
  CMS: '/admin/cms',
  Users: '/admin/users',
  UserDetails: '/admin/users/details/:userId',
  CreateCurrency: '/admin/create-currency',
  // AffiliateDetail: '/admin/affiliate-details/:affiliateId',
  CMSDetail: '/admin/cms/details/:cmsPageId',
  CasinoProviders: '/admin/casino-providers',
  CasinoGames: '/admin/casino-games',
  Countries: '/admin/countries',
  CreateSuperAdminUser: '/admin/create-admin-user',
  EditSuperAdminUser: '/admin/admins/edit/:adminId',
  EditTenantAdmin: '/admin/tenants/owners/edit/:adminUserId',
  TransactionsBanking: '/admin/transactions-banking',
  Aggregators: '/admin/casino-aggregators',
  CasinoCategory: '/admin/casino-category',
  CasinoSubCategory: '/admin/casino-sub-category',
  AddCasinoSubCategory: '/admin/casino-sub-category/addGames/:gameSubCategoryId',
  RestrictedGames: '/admin/countries/restricted-games/:countryId',
  RestrictedProviders: '/admin/countries/restricted-providers/:countryId',
  RestrictedProviderCountries: '/admin/casino-providers/restrict-countries/:itemId',
  RestrictedGameCountries: '/admin/casino-games/restrict-countries/:itemId',
  CasinoTransactions: '/admin/casino-transactions',
  SportsTransactions: '/admin/sports-transactions',
  CreateCMS: '/admin/cms/create',
  EditCMS: '/admin/cms/edit/:cmsPageId',
  WithdrawRequests: '/admin/withdraw-requests',
  FormFields: '/admin/form-fields',
  CreateBonus: '/admin/bonus/create',
  EditBonus: '/admin/bonus/edit/:bonusId',
  Bonus: '/admin/bonus',
  ReferralManagement: '/admin/referral-management',
  BonusDetail: '/admin/bonus/details/:bonusId',
  CloneBonus: '/admin/bonus/clone/:bonusId',
  KYCLabels: '/admin/kyc-labels',
  WageringTemplate: '/admin/wagering-template',
  WageringEdit: '/admin/wagering-template/edit/:wageringTemplateId',
  WageringDetails: '/admin/wagering-template/details/:wageringTemplateId',
  CreateWagering: '/admin/wagering-template/create',
  Loyalty: '/admin/loyalty-management',
  CreateLoyaltyRule: '/admin/loyalty-management/create',
  EditLoyalty: '/admin/loyalty-management/edit/:loyaltyId',
  EmailTemplate: '/admin/email-templates',
  EditEmailTemplate: '/admin/email-templates/edit/:emailTemplateId',
  CreateEmailTemplate: '/admin/create-email-template/create',
  Profile: '/admin/profile',
  TenantOwnerDetail: 'admin/tenants/owners/details/:adminUserId',
  ImageGallery: '/admin/image-gallery',
  BannerManagement: '/admin/banner-management',
  LanguageManagement: '/admin/language-management',
  EditSupportLanguageKeys: '/admin/language-management/edit/:keys',
  EditSupportLanguage: '/admin/language-management/edit',
  AddLanguage: '/admin/language-management/add-language/:lang',
  PaymentAggregators: '/admin/cashier-management',
  EditCustomProviders: '/admin/cashier-management/edit-provider/:providerId',
  ViewCustomProviders: '/admin/cashier-management/view-provider/:providerId',
  BonusReorder: '/admin/bonus/reorder',
  ReviewManagement: '/admin/review-management',
  CreateCredentials: '/admin/create-credentials',
  GameReorder: '/admin/casino-games/reorder',
  ReorderSubCategory: '/admin/casino-sub-category/reorder',
  ReorderCategory: '/admin/casino-category/reorder',
  BetSettings: '/bet-settings',
  SportsList: '/sports',
  SportsCountries: '/sports/countries',
  SportsTournaments: '/sports/tournaments',
  SportsMatches: '/sports/matches',
  SportsMatchesDetail: '/sports/matches/detail/:id',
  SportsMarkets: '/sports/markets',
  AffiliatesManagement: '/admin/affiliates',
  CreateAffiliates: '/admin/create-affiliates',
  AffiliateDetails: '/admin/affiliate-details/:affiliateId',
  UpdateAffiliates: '/admin/update-affiliate/:affiliateId',
  SubCategorySecondaryOrder: '/admin/sub-category-secondary-order',
  SocialLinks: '/admin/social-links',
  createSocialLink: '/admin/create-social-link',
  editSocialLink: '/admin/social-link/edit/:iconId',

  /* SITE CONFIG ROUTES */
  GeneralSettings: '/admin/settings/general',
  TransactionsSettings: '/admin/settings/transactions',
}

export const TenantRoutes = {
  TenantSignin: '/tenant/signin',
  Dashboard: '/tenant/dashboard',
  CMS: '/tenant/CMS',
  CreateCMS: '/tenant/create-cms',
  CmsDetails: '/tenant/CMS/details/:cmsId',
  EditCms: '/tenant/CMS/edit/:cmsId',
  Admins: '/tenant/admins',
  AdminUserDetails: '/tenant/admins/details/:adminUserId',
  CreateAdminUser: '/tenant/admins/create',
  Credentials: '/tenant/credentials',
  Languages: '/tenant/languages',
  CreateCredentials: '/tenant/create-credentials',
  UpdateCredentials: '/tenant/update-credentials/:credKey',
  Users: '/tenant/users',
  UserDetails: '/tenant/users/details/:userId',
  CreateUser: '/tenant/create-user',
  UpdateUser: '/tenant/update-user/:userId',
  TenantConfigurations: '/tenant/configurations',
  FormFields: '/tenant/form-fields',
  TenantThemeSettings: '/tenant/settings/themeSettings',
  CasinoCategory: '/tenant/casino-category',
  CasinoSubCategory: '/tenant/casino-sub-category',
  CasinoGames: '/tenant/casino-games',
  AddSubCategoryGames: '/tenant/casino-sub-category/add-games/:tenantGameSubCategoryId',
  EditAdminUser: '/tenant/admins/edit/:tenantAdminUserId',
  ReorderSubCategory: '/tenant/casino-sub-category/reorder',
  ReorderCategory: '/tenant/casino-category/reorder',
  TransactionsBanking: '/tenant/transactions-banking',
  CasinoTransactions: '/tenant/casino-transactions',
  WithdrawRequests: '/tenant/withdraw-requests',
  WageringTemplate: '/tenant/wagering-template',
  WageringEdit: '/tenant/wagering-template/edit/:wageringTemplateId',
  WageringDetails: '/tenant/wagering-template/details/:wageringTemplateId',
  CreateWagering: '/tenant/wagering-template/create',
  CreateBonus: '/tenant/bonus/create',
  EditBonus: '/tenant/bonus/edit/:bonusId',
  Bonus: '/tenant/bonus',
  BonusDetail: '/tenant/bonus/details/:bonusId',
  CloneBonus: '/tenant/bonus/clone/:bonusId',
  Loyalty: '/tenant/loyalty-management',
  EmailTemplate: '/tenant/email-templates',
  EditEmailTemplate: '/tenant/email-templates/edit/:emailTemplateId',
  CreateEmailTemplate: '/tenant/email-templates/create',
  Profile: '/tenant/profile',
  ImageGallery: '/tenant/image-gallery',
  BannerManagement: '/tenant/banner-management',
  GameReorder: '/tenant/casino-games/reorder',
  LanguageManagement: '/tenant/language-management',
  EditSupportLanguageKeys: '/tenant/language-management/edit/:keys',
  EditSupportLanguage: '/tenant/language-management/edit',
  AddLanguage: '/tenant/language-management/add-language/:lang',
  PaymentAggregators: '/tenant/cashier-management',
  BonusReorder: '/tenant/bonus/reorder',
  ReviewManagement: '/tenant/review-management'
}

export const CommonRoutes = {
  ForgotPassword: '/forgot-password',
  ResetPassword: '/:role/reset-password',
  Lock: '/lock',
  NotFound: '/404',
  ServerError: '/500',
  TenantNotFound: '/406'
}

export const AffiliateRoutes = {
  AffiliateSignIn: '/affiliate/signin',
  AffiliateSignUp: '/affiliate/signup',
  Dashboard: '/affiliate/dashboard',
  Profile: '/affiliate/profile',
  ActivePlayers: '/affiliate/players',
  BlockedPlayers: '/affiliate/blocked-players',
  UserDetails: 'affiliate/player/details/:userId',
  TransactionsBanking: '/affiliate/transactions-banking',
  WithdrawRequests: '/affiliate/withdraw-requests',
  Streaming: '/affiliate/streaming',
  ProductsReport: '/affiliate/products-report',
  playersReport: '/affiliate/players-report',
  updateProfile: '/affiliate/update-profile/:affiliateId',
  Withdraw: '/affiliate/withdraw',
  SubAffiliatesManagement: '/affiliate/sub-affiliates',
  SubAffiliateDetails: (affiliateId = ":affiliateId") => `/affiliate/sub-affiliate-details/${affiliateId}`,
}