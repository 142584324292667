import React, { useEffect, useRef, useState } from 'react'
import { Col, Row, Modal, Button, Form } from '@themesberg/react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { AffiliateRoutes } from '../../../routes';
import { Formik } from 'formik';
import FormatNumber from '../../../utils/formatNumber';
import { getPaymentProviders, withdrawPix } from '../../../utils/apiCalls';
import { InlineLoader, Loading } from '../../../components/Preloader';

import { withdrawalInitialValues, withdrawalSchema, withdrawalValuesLimit, withdrawalValuesPlacehgolder } from './withdrawal.constants';
import { DEPOSIT_BLR_VALUES } from '../../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import CurrencyInput from '../../../components/CurrencyInput';
import { FormikErrorMessage } from '../../../components/ErrorMessage';
import { toast } from '../../../components/Toast';
import { resolveErrors } from '../../../utils/errorTypes';
import { affiliateAdminDetailsStart } from '../../../store/redux-slices/affiliates';


export const WithdrawPage = () => {
  const navigate = useNavigate();

  const currencyInputRef = useRef(null)

  const [initialFormValue, setInitialFormValue] = useState(withdrawalInitialValues);
  const [loading, setLoading] = useState(false);

  const [loadingProviders, setLoadingProviders] = useState(false);
  const [settings, setSettings] = useState({});
  const [currentProvider, setCurrentPorvider] = useState();
  const [pixKeyType, setPixKeyType] = useState(null);

  const dispatch = useDispatch()
  const { adminDetails: { affiliate } } = useSelector(
    (state) => state.admins
  );

  const loadPaymenyProviders = async () => {
    setLoadingProviders(true)
    try {
      const { data: { data } } = await getPaymentProviders({ withdraw: true });
      setCurrentPorvider(data.pix[0]);
      setSettings(data.settings);
    } catch (err) {
      toast("Ocorreu um erro ao obter os provedores de pagamento", "error")
    } finally {
      setLoadingProviders(false)
    }

  }

  const handleSubmit = async (formValues, { resetForm }) => {
    setLoading(true)
    try {
      const { data: { data } } = await withdrawPix(formValues);
      // setCurrentPorvider(data.pix[0]);
      toast("Solicitação de saque realizada com sucesso!", "success")
      resetForm();
      if (currencyInputRef && currencyInputRef.current) currencyInputRef.current.clear();
      dispatch(affiliateAdminDetailsStart({ onStart: true }))
    } catch (err) {
      const message = resolveErrors(err.response.data?.errors, "Ocorreu um erro ao realizar a solicitação");
      toast(message, "error");
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadPaymenyProviders();
  }, [])

  return (
    <>
      <div className='section-header-wrap mt-n3 mx-n3'>
        <Row>
          <Col lg={8} xs={7}>
            <h2 className='title-text'>
              Saque
            </h2>
          </Col>
        </Row>
      </div>
      {affiliate.cpf ?
        <div className='TableBg p-3 position-relative'>
          <Formik
            initialValues={{ ...initialFormValue, paymentProviderId: currentProvider?.paymentProviderId }}
            validationSchema={withdrawalSchema(settings, affiliate?.affiliateWallet.amount, pixKeyType)}
            onSubmit={handleSubmit}
            initialErrors={withdrawalInitialValues}
            enableReinitialize={true}
          >
            {({ values, errors, isValid, setFieldValue, validateField, handleSubmit }) => {
              // console.log(values)
              return (
                <Form>
                  <div className='row'>

                    <Form.Group className="mb-3 col-sm-2 col-12" controlId="exampleForm.ControlInput1">
                      <Form.Label>Tipo Pix</Form.Label>
                      <Form.Select
                        name="pix_key_type"
                        value={values.pix_key_type ?? ''}
                        onChange={(e) => {
                          setFieldValue('pix_key_type', e.target.value)
                          setPixKeyType(e.target.value)
                          setTimeout(() => validateField('pix_key'), 400)
                        }}
                      >
                        <option value={"null"}>Selecione</option>
                        {currentProvider?.settings?.pix_key_types.map((pixType, key) => {
                          const pixKeysAllowed = (settings.affiliatePixKeysAllowed || []).map(e => e.value);
                          return pixKeysAllowed.includes(pixType.value) ? <option key={key} value={pixType.value}>{pixType.value}</option> : null;
                        })}
                      </Form.Select>
                    </Form.Group>


                    <Form.Group className="mb-3 col-sm-6 col-12" controlId="exampleForm.ControlInput1">
                      <Form.Label>Chave Pix</Form.Label>
                      <Form.Control
                        type="text"
                        name="pix_key"
                        disabled={!values.pix_key_type}
                        placeholder={!values.pix_key_type ? "Selecione primeiro o tipo de chave pix" : withdrawalValuesPlacehgolder[values.pix_key_type]}
                        value={values.pix_key ?? ''}
                        onChange={(e) => {
                          let value = e.target.value
                          if (withdrawalValuesLimit[values.pix_key_type]) {
                            if (e.target.value.length <= withdrawalValuesLimit[values.pix_key_type]) {
                              setFieldValue('pix_key', value)
                            }
                          } else {
                            setFieldValue('pix_key', value)
                          }
                        }}
                      />
                      <FormikErrorMessage
                        name="pix_key"
                        className='mt-1'
                      />
                    </Form.Group>

                    <Form.Group className="mb-3 col-sm-4 col-12" controlId="exampleForm.ControlInput1">
                      <Form.Label>Valor</Form.Label>
                      <CurrencyInput
                        ref={currencyInputRef}
                        disabled={!values.pix_key}
                        name="value"
                        placeholder={FormatNumber.currency(0)}
                        onNumberFormat={(event) => {
                          setFieldValue('value', event.detail.number)
                        }}
                      />
                      <FormikErrorMessage
                        name={"value"}
                        className='mt-1'
                      />
                    </Form.Group>

                  </div>
                  <div className="row mt-3">
                    <Col className='mx-auto col-sm-3 col-12 col'>
                      <Button
                        className='w-100'
                        data-loading={loading}
                        disabled={!isValid || loading}
                        onClick={handleSubmit}
                      >
                        <span>Solicitar saque</span>
                        {loading && <InlineLoader />}
                      </Button>
                    </Col>
                  </div>
                </Form>
              )
            }}
          </Formik>
          {loadingProviders ? <Loading className={"position-absolute bg-white top-0 w-100 h-100"} /> : null}
        </div>
        :
        <div className='TableBg position-relative d-flex p-3 py-4 justify-content-center align-items-center flex-column'>
          (Novo) Atualize seus dados para liberar a solicitação de saque.
          <Button
            className='mt-3'
            onClick={() =>
              navigate(`/admin/update-affiliate/${affiliate.affiliateId}?redirect_uri=${AffiliateRoutes.Withdraw}`)
            }
          >Atualizar</Button>
        </div>
      }
      <Modal >
        <Modal.Body>
          <h4 className='text-center'>
            Shortly!
          </h4>
          <p className='text-center mb-0'>
            The withdrawal will be available within 15 days.
          </p>
        </Modal.Body>
        <Modal.Footer className={`d-flex justify-content-center`}>
          <Button
            className='m-2'
            variant='outline-warning'
            onClick={() => {
              navigate(AffiliateRoutes.Dashboard)
            }}
          >
            OK
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  )
}

export default WithdrawPage;