import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    getAllCasinoProvidersStart,
    getAllCasinoProvidersSuccess,
    getAllCasinoProvidersFailure,
    createCasinoProviderStart,
    createCasinoProviderComplete,
    updateCasinoProviderStart,
    updateCasinoProviderComplete,
    getAllCasinoGamesStart,
    getAllCasinoGamesSuccess,
    getAllCasinoGamesFailure,
    createCasinoGameStart,
    createCasinoGameComplete,
    updateCasinoGameStart,
    updateCasinoGameComplete,
    resetCasinoGameStart,
    resetCasinoGameSuccess,
    updateCasinoStatusStart,
    updateCasinoStatusComplete,
    getSuperAdminAggregatorsStart,
    getSuperAdminAggregatorsSuccess,
    getSuperAdminAggregatorsFailure,
    createSuperAdminAggregatorStart,
    createSuperAdminAggregatorComplete,
    updateSuperAdminAggregatorStatusStart,
    updateSuperAdminAggregatorStatusComplete,
    getSuperAdminGameCategoryStart,
    getSuperAdminGameCategorySuccess,
    getSuperAdminGameCategoryFailure,
    getSuperAdminGameSubCategoryStart,
    getSuperAdminGameSubCategorySuccess,
    getSuperAdminGameSubCategoryFailure,
    updateSACasinoGamesStatusStart,
    updateSACasinoGamesStatusComplete,
    getAllSAProvidersStart,
    getAllSAProvidersSuccess,
    getAllSAProvidersFailure,
    getFreeSpinGamesStart,
    getFreeSpinGamesSuccess,
    getFreeSpinGamesFailure,
    updateReorderGamesStart,
    updateReorderGamesSuccess,
    updateReorderGamesFailure,
    deleteCasinoSubCategoriesStart,
    deleteCasinoSubCategoriesSuccess,
    deleteCasinoSubCategoriesFailure,
    updateCasinoStart,
    updateCasinoComplete,
    createCasinoComplete,
    createCasinoStart,
    addGamesToSubCategoryStart,
    addGamesToSubCategoryComplete,
    updateCategoryReOrderStart,
    updateCategoryReOrderComplete
  },
  reducer
} = createSlice({
  name: 'fetchCasinoData',
  initialState: {
    loading: false,
    casinoProvidersData: null,
    casinoGamesData: null,
    aggregators: null,
    gameCategory: null,
    gameSubCategory: null,
    allProviders: null
  },
  reducers: {
    getAllCasinoProvidersStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllCasinoProvidersSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      casinoProvidersData: payload
    }),
    getAllCasinoProvidersFailure: (state) => ({
      ...state,
      loading: false
    }),
    getAllCasinoGamesStart: (state) => ({
      ...state,
      loading: true
    }),
    addGamesToSubCategoryStart: (state) => ({
      ...state,
      loading: true
    }),
    addGamesToSubCategoryComplete: (state) => ({
      ...state,
      loading: false
    }),
    resetCasinoGameStart: (state) => ({
      ...state,
      loading: true
    }),
    resetCasinoGameSuccess: (state) => ({
      ...state,
      loading: false,
      casinoGamesData: null
    }),
    getAllCasinoGamesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      casinoGamesData: payload
    }),
    getAllCasinoGamesFailure: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    createCasinoGameStart: (state) => ({
      ...state,
      loading: true
    }),
    createCasinoGameComplete: (state) => ({
      ...state,
      loading: false
    }),
    createCasinoProviderStart: (state) => ({
      ...state,
      loading: true
    }),
    createCasinoStart: (state) => ({
      ...state,
      loading: true
    }),
    createCasinoComplete: (state) => ({
      ...state,
      loading: false
    }),
    createCasinoProviderComplete: (state) => ({
      ...state,
      loading: false
    }),
    updateCasinoGameStart: (state) => ({
      ...state,
      loading: true
    }),
    updateCasinoGameComplete: (state) => ({
      ...state,
      loading: false
    }),
    updateCasinoProviderStart: (state) => ({
      ...state,
      loading: true
    }),
    updateCasinoProviderComplete: (state) => ({
      ...state,
      loading: false
    }),
    updateCasinoStatusStart: (state) => ({
      ...state,
      loading: true
    }),
    updateCasinoStatusComplete: (state) => ({
      ...state,
      loading: false
    }),
    getSuperAdminAggregatorsStart: (state) => ({
      ...state,
      loading: true
    }),
    getSuperAdminAggregatorsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      aggregators: payload
    }),
    getSuperAdminAggregatorsFailure: (state) => ({
      ...state,
      loading: false
    }),
    createSuperAdminAggregatorStart: (state) => ({
      ...state,
      loading: true
    }),
    createSuperAdminAggregatorComplete: (state) => ({
      ...state,
      loading: false
    }),
    updateSuperAdminAggregatorStatusStart: (state) => ({
      ...state,
      loading: true
    }),
    updateSuperAdminAggregatorStatusComplete: (state) => ({
      ...state,
      loading: false
    }),
    getSuperAdminGameCategoryStart: (state) => ({
      ...state,
      loading: true
    }),
    getSuperAdminGameCategorySuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      gameCategory: payload
    }),
    getSuperAdminGameCategoryFailure: (state) => ({
      ...state,
      loading: false
    }),
    getSuperAdminGameSubCategoryStart: (state) => ({
      ...state,
      loading: true
    }),
    getSuperAdminGameSubCategorySuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      gameSubCategory: payload
    }),
    getSuperAdminGameSubCategoryFailure: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    updateSACasinoGamesStatusStart: (state) => ({
      ...state,
      loading: true
    }),
    updateSACasinoGamesStatusComplete: (state) => ({
      ...state,
      loading: false
    }),
    deleteCasinoSubCategoriesStart: (state) => ({
      ...state,
      loading: true
    }),
    deleteCasinoSubCategoriesSuccess: (state) => ({
      ...state,
      loading: false
    }),
    deleteCasinoSubCategoriesFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateCasinoComplete: (state) => ({
      ...state,
      loading: false
    }),
    updateCasinoStart: (state) => ({
      ...state,
      loading: false
    }),

    getAllSAProvidersStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllSAProvidersSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      allProviders: payload
    }),
    getAllSAProvidersFailure: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    getFreeSpinGamesStart: (state) => ({
      ...state,
      loading: true
    }),
    getFreeSpinGamesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      casinoGamesData: payload
    }),
    getFreeSpinGamesFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateReorderGamesStart: (state) => ({
      ...state,
      loading: true
    }),
    updateReorderGamesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      reorderGames: payload
    }),
    updateReorderGamesFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateCategoryReOrderStart: (state) => ({
      ...state,
      loading: true
    }),
    updateCategoryReOrderComplete: (state) => ({
      ...state,
      loading: false
    }),
  }
})

export default reducer

export {
  getAllCasinoProvidersStart,
  getAllCasinoProvidersSuccess,
  getAllCasinoProvidersFailure,
  createCasinoProviderStart,
  createCasinoStart,
  createCasinoComplete,
  createCasinoProviderComplete,
  updateCasinoProviderStart,
  updateCasinoProviderComplete,
  getAllCasinoGamesStart,
  addGamesToSubCategoryStart,
  addGamesToSubCategoryComplete,
  getAllCasinoGamesSuccess,
  getAllCasinoGamesFailure,
  createCasinoGameStart,
  createCasinoGameComplete,
  updateCasinoGameStart,
  updateCasinoGameComplete,
  resetCasinoGameStart,
  resetCasinoGameSuccess,
  updateCasinoStatusStart,
  updateCasinoStatusComplete,
  getSuperAdminAggregatorsStart,
  getSuperAdminAggregatorsSuccess,
  getSuperAdminAggregatorsFailure,
  createSuperAdminAggregatorStart,
  createSuperAdminAggregatorComplete,
  updateSuperAdminAggregatorStatusStart,
  updateSuperAdminAggregatorStatusComplete,
  getSuperAdminGameCategoryStart,
  getSuperAdminGameCategorySuccess,
  getSuperAdminGameCategoryFailure,
  getSuperAdminGameSubCategoryStart,
  getSuperAdminGameSubCategorySuccess,
  getSuperAdminGameSubCategoryFailure,
  updateSACasinoGamesStatusStart,
  updateSACasinoGamesStatusComplete,
  getAllSAProvidersStart,
  getAllSAProvidersSuccess,
  getAllSAProvidersFailure,
  getFreeSpinGamesStart,
  getFreeSpinGamesSuccess,
  getFreeSpinGamesFailure,
  updateReorderGamesStart,
  updateReorderGamesSuccess,
  updateReorderGamesFailure,
  deleteCasinoSubCategoriesStart,
  deleteCasinoSubCategoriesSuccess,
  deleteCasinoSubCategoriesFailure,
  updateCasinoStart,
  updateCasinoComplete,
  updateCategoryReOrderStart,
  updateCategoryReOrderComplete
}
