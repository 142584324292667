import { Col, Modal, Row } from '@themesberg/react-bootstrap'
import React from 'react'

export const ImagePreviewModal = ({
  imagePreviewModalShow,
  setImagePreviewModalShow,
  imageUrl,
  setImageUrl
}) => {
  function getUrlExtension(url) {
    return url.split(/[#?]/)[0].split('.').pop().trim()
  }

  return (
    <Modal
      show={imagePreviewModalShow}
      onHide={() => {
        setImagePreviewModalShow(false)
        setImageUrl({ name: '', preview: [] })
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{imageUrl?.name}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {
          imageUrl
            ? (
              <Row xs={12} className='d-flex justify-content-center'>
                {imageUrl?.preview?.map((url, index) => (
                  <Col key={index} className='mt-2 text-center' xs={12}>
                    <Col className='ratio ratio-16x9'>
                      {getUrlExtension(url) === 'pdf'
                        ? <img
                          src='/pdfimage.png'
                          onClick={() => window.open(url, "_blank")}
                          style={{ border: '1px solid grey', borderRadius: '12px' }}
                          className='mb-2 ratio ratio-16x9 object-fit-contain cursor-pointer'
                        />
                        : (
                          <img
                            src={url} alt={url}
                            onClick={() => window.open(url, "_blank")}
                            style={{ border: '1px solid grey', borderRadius: '12px' }}
                            className='mb-2 ratio ratio-16x9 object-fit-contain cursor-pointer'
                          />)}

                    </Col>
                    <Col>
                      <label>{imageUrl?.preview?.length - 1 === index ? 'Pré visualização' : 'Document Preview' + (index + 1)}</label>
                    </Col>
                  </Col>

                ))}
              </Row>
            )
            : 'Nenhuma visualização'
        }
      </Modal.Body>

    </Modal>
  )
}
