import * as Yup from 'yup'

export const superAdminLoginSchema = Yup.object().shape({
  user: Yup.string().required('E-mail ou nome de usuário são obrigatórios.'),
  password: Yup.string().required('Senha é obrigatória'),
  shouldGoForOtpVerification: Yup.boolean(),
  otp: Yup.string().when('shouldGoForOtpVerification', {
    is: false,
    then: Yup.string().required('OTP é obrigatório.')
  })
})
