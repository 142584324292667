import { toast } from "../../components/Toast";
import { AdminsRoutes } from "../../routes";
import { createLoyaltyRule, deleteLoyaltyRule, getLoyaltyRules, getMinimumBetAmount, setMinimumBetAmount, updateLoyaltyRule } from "../../utils/apiCalls";
import { createLoyaltyRuleFailure, createLoyaltyRuleStart, createLoyaltyRuleSuccess, deleteLoyaltyRuleFailure, deleteLoyaltyRuleStart, deleteLoyaltyRuleSuccess, editLoyaltyRuleFailure, editLoyaltyRuleStart, editLoyaltyRuleSuccess, getAllLoyaltyRulesFailure, getAllLoyaltyRulesStart, getAllLoyaltyRulesSuccess, getMinimumBetAmountFailure, getMinimumBetAmountStart, getMinimumBetAmountSuccess, setMinimumBetAmountFailure, setMinimumBetAmountStart, setMinimumBetAmountSuccess } from "../redux-slices/loyaltyRules";
import { takeLatest, put } from 'redux-saga/effects';

function* getLoyaltyRuleWorker() {
    try {
        const { data } = yield getLoyaltyRules();
        yield put(getAllLoyaltyRulesSuccess(data?.data?.rules));
    } catch (e) {
        yield toast(e?.response?.data?.errors[0]?.description, 'error');
        yield put(getAllLoyaltyRulesFailure(e?.response?.data?.errors[0]?.description));
    }
}

function* editLoyaltyRuleWorker({ payload }) {
    try {
        const { navigate, ...rest } = payload;
        const { data } = yield updateLoyaltyRule(rest);
        yield toast('Loyalty Rule Edited Successfully.', 'success');
        yield put(editLoyaltyRuleSuccess());
        navigate(AdminsRoutes.Loyalty);
    } catch (e) {
        console.log('e :>> ', e);
        yield toast(e?.response?.data?.errors[0]?.description, 'error');
        yield put(editLoyaltyRuleFailure(e?.response?.data?.errors[0]?.description));
    }
}

function* createLoyaltyRuleWorker({ payload }) {
    try {
        const { navigate, ...rest } = payload;
        const { data } = yield createLoyaltyRule(rest);
        yield toast('Loyalty Rule Created Successfully.', 'success');
        navigate(AdminsRoutes.Loyalty);
        yield put(createLoyaltyRuleSuccess());
    } catch (e) {
        yield toast(e?.response?.data?.errors[0]?.description, 'error');
        yield put(createLoyaltyRuleFailure(e?.response?.data?.errors[0]?.description));
    }
}

function* deleteLoyaltyRuleWorker({ payload }) {
    try {
        const { data } = yield deleteLoyaltyRule();
        yield toast('Loyalty Rule Removed Successfully.', 'success');
        yield put(deleteLoyaltyRuleSuccess());
        yield put(getAllLoyaltyRulesStart());
    } catch (e) {
        yield toast(e?.response?.data?.errors[0]?.description, 'error');
        yield put(deleteLoyaltyRuleFailure(e?.response?.data?.errors[0]?.description));
    }
}
function* setMinimumBetAmountWorker({ payload }) {
    try {
        const {formValues,setMinimumBetAmountModal} = payload
        const { data } = yield setMinimumBetAmount(formValues);
        yield toast('Minimum Bet Amount Set Successfully', 'success');
        yield put(setMinimumBetAmountSuccess());
        setMinimumBetAmountModal(false)
        yield put(getMinimumBetAmountStart())

    } catch (e) {
        yield toast(e?.response?.data?.errors[0]?.description, 'error');
        yield put(setMinimumBetAmountFailure(e?.response?.data?.errors[0]?.description));
    }
}
function* getMinimumBetAmountWorker({ payload }) {
    try {
        const { data } = yield getMinimumBetAmount();
        yield put(getMinimumBetAmountSuccess(data?.data?.minimumBetAmount));
    } catch (e) {
        yield toast(e?.response?.data?.errors[0]?.description, 'error');
        yield put(getMinimumBetAmountFailure(e?.response?.data?.errors[0]?.description));
    }
}



export default function* loyaltyRulesWatcher() {
    yield takeLatest(getAllLoyaltyRulesStart.type, getLoyaltyRuleWorker);
    yield takeLatest(editLoyaltyRuleStart.type, editLoyaltyRuleWorker);
    yield takeLatest(createLoyaltyRuleStart.type, createLoyaltyRuleWorker);
    yield takeLatest(deleteLoyaltyRuleStart.type, deleteLoyaltyRuleWorker);
    yield takeLatest(setMinimumBetAmountStart.type, setMinimumBetAmountWorker);
    yield takeLatest(getMinimumBetAmountStart.type, getMinimumBetAmountWorker);
}
