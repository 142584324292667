import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLoginToken } from '../../../../utils/storageUtils'
import { formatDateYMD, getDateDaysAgo } from '../../../../utils/dateFormatter'
import { getKPISummaryStart } from '../../../../store/redux-slices/dashboard'
// import { getKPISummaryStart } from '../../../../store/redux-slices/dashboard'

const useKPISummary = ({ selectedPortal, selectedClient }) => {
  const dispatch = useDispatch()
  const { kpiSummeryLoading: loading, KPISummary } = useSelector((state) => state.dashboard)
  const [state, setState] = useState([
    {
      startDate: getDateDaysAgo(0),
      endDate: new Date(),
      key: 'selection'
    }
  ])
  const [summaryOption, setSummaryOption] = useState('todayReport')
  const [selectedTab, setSelectedTab] = useState('CASINO')

  const loadKPISummary = () => {
    dispatch(getKPISummaryStart({
      isTenant: false,
      startDate: formatDateYMD(state.map(a => a.startDate)),
      endDate: formatDateYMD(state.map(a => a.endDate)),
      tenantId: selectedPortal,
      adminId: selectedClient
    }))
  }

  useEffect(() => {
    loadKPISummary()
  }, [state, selectedClient, selectedPortal])

  // TODO: FIX THIS CSV DOWNLOAD URL
  const getCsvDownloadUrl = () =>
    `${process.env.REACT_APP_API_URL}/api/report/kpi-summary?reportOption=${summaryOption}&summeryType=${selectedTab}&adminId=${selectedClient}&tenantId=${selectedPortal}&startDate=${formatDateYMD(state.map(a => a.startDate))}&endDate=${formatDateYMD(state.map(a => a.endDate))}&csvDownload=true&token=${getLoginToken()}`

  return {
    loadKPISummary,
    loading,
    setState,
    state,
    getCsvDownloadUrl,
    KPISummary,
    summaryOption,
    setSummaryOption,
    selectedTab,
    setSelectedTab
  }
}

export default useKPISummary
