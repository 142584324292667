import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useDidMountEffect from '../../../utils/useDidMountEffect'
import { createCasinoStart, getSuperAdminGameCategoryStart, updateCasinoStart, updateSACasinoGamesStatusStart } from '../../../store/redux-slices/superAdminCasinoManagement'
import { useNavigate } from 'react-router-dom'
import { getLanguagesStart } from '../../../store/redux-slices/languages'

const useCategoryListing = () => {
  const dispatch = useDispatch()
  const isInitialRender = useDidMountEffect()
  const [show, setShow] = useState(false)
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [gameCategoryId, setCasinoGameId] = useState()
  const [active, setActive] = useState('')
  const [status, setStatus] = useState()
  const [statusShow, setStatusShow] = useState(false)
  const [name, setName] = useState()
  const [data, setData] = useState()
  const [type, setType] = useState('')
  const [selectedSubCategoryId, setSelectedCategoryId] =
  useState('')
  const { languages } = useSelector(state => state.languages)
  const [language, setLangauge] = useState('EN')
  const navigate = useNavigate()
  const [selectedProvider, setSelectedProvider] = useState('')
  const { loading, gameCategory } = useSelector((state) => state.superAdminCasino)
  const totalPages = Math.ceil(gameCategory?.count / limit)

  useEffect(() => {
    dispatch(getLanguagesStart({ limit: '', pageNo: '', name: '' }))
  }, [])
  useEffect(() => {
    dispatch(getSuperAdminGameCategoryStart({ limit, pageNo: page, search, tenantId: '' }))
  }, [limit, page])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          dispatch(getSuperAdminGameCategoryStart({ limit, pageNo: page, search, tenantId: '' }))
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])
  const handleStatusShow = (id, status, myName) => {
    setCasinoGameId(id)
    setStatus(!status)
    setName(myName?.EN)
    setStatusShow(true)
  }
  const handleClose = () => setShow(false)
  const handleShow = (type, data) => {
    setType(type)
    setData(data)
    setShow(true)
  }

  const handleYes = () => {
    dispatch(
      updateSACasinoGamesStatusStart({
        data: {
          code: 'CASINO_CATEGORY',
          gameCategoryId: gameCategoryId,
          status
        },
        limit,
        pageNo: page,
        casinoCategoryId: selectedSubCategoryId,
        search,
        isActive: active,
        tenantId: '',
        selectedProvider
      })
    )
    setStatusShow(false)
  }

  const updateCategory = (data, { gameCategoryId }) =>
  dispatch(
    updateCasinoStart({
      data: {
        ...data,
        gameCategoryId
      },
      code: 'CASINO_CATEGORY',
      limit,
      pageNo: page,
      search
    }))

const createCategory = (data) =>
  dispatch(
    createCasinoStart({
      data,
      code: 'CASINO_CATEGORY',
      limit,
      pageNo: page,
      search
    })
  )
  

  return {
    limit,
    setLimit,
    page,
    setPage,
    loading,
    gameCategory,
    totalPages,
    search,
    setSearch,
    handleStatusShow,
    handleYes,
    handleClose,
    statusShow,
    active,
    name,
    show,
    setSelectedCategoryId,
    setSelectedProvider,
    navigate,
    setStatusShow,
    status,
    handleShow,
    data,
    type,
    createCategory,
    updateCategory,
    language,
    setLangauge,
    languages,
  }
}

export default useCategoryListing
