import React, { forwardRef } from "react";
import { Button as BsButton } from 'react-bootstrap';
import { InlineLoader } from '../Preloader';

const Button = forwardRef(({ children, loading, hidden, disabled, ...props }, ref) => {
  return !hidden ? (
    <BsButton
      ref={ref}
      disabled={loading || disabled}
      {...props}
      data-loading={loading}
    >
      <span>{children}</span>
      {loading && <InlineLoader />}
    </BsButton>
  ) : null
})

export default Button;