import React, { useState } from "react";
import { Row, Col, Tabs, Tab } from "@themesberg/react-bootstrap";

import Preloader from "../../../components/Preloader";
import useDashboard from "./hooks/useDashboard";

import Tools from "./components/TotalReports";

import AffiliateStatsChart from "./components/AffiliateStats/AffiliateStatsChart";
import AffiliateLives from "./components/AffiliateLives";
import AffiliateTransactionsTable from "../../Super-Admin/AffiliateDetails/components/AffiliateTransactions";
import { AFFILIATE_TYPE } from "../../../utils/constant";
import AffiliateUsersPerformanceTable from "../../Super-Admin/AffiliateDetails/components/AffiliateUsersPerformance";
import AffiliateDailyPerformanceTable from "../../Super-Admin/AffiliateDetails/components/AffiliateDailyPerformance";
import AffiliateSubAffiliatesDailyPerformanceTable from "../../Super-Admin/AffiliateDetails/components/AffiliateSubAffiliatesDailyPerformance";
import SubAffiliatesPerformanceTable from "../../Super-Admin/AffiliateDetails/components/AffiliateSubAffiliatesPerformance";

export default () => {
  const {
    reportsToShow,
    permissionKeys,
    loading,
    adminPermissions,
    elasticHealth,
    nonElasticReports,
    dateRangePickerState,
    setDateRangePickerState,
    getSubCategory,
    handleDateRangeReset,
    adminDetails
  } = useDashboard();

  const [selectedTab, setSelectedTab] = useState("dashboard");
  return (
    <>
      {loading ? (
        <Preloader />
      ) : adminPermissions &&
        permissionKeys.some((x) => reportsToShow.indexOf(x) >= 0) &&
        (!elasticHealth ||
          permissionKeys.some((x) => nonElasticReports.indexOf(x) >= 0)) ? (
        <>
          <div className="section-header-wrap mt-n3 mx-n3">
            <Row>
              <Col lg={8} xs={7}>
                <h2 className="title-text">Painel Analítico</h2>
              </Col>
            </Row>
          </div>

          {/* {permissionKeys.includes("LivePlayerReport") && (
            <LivePlayers
              selectedPortal={selectedPortal}
              selectedClient={selectedClient}
            />
          )} */}
        </>
      ) : (
        <>
          {/* <Card className="p-2 mb-2"> */}
          <Row>
            <Col>
              <Tabs activeKey={selectedTab} onSelect={(tab) => setSelectedTab(tab)} className="nav-light" mountOnEnter unmountOnExit >
                <Tab eventKey="dashboard" title="Painel Analítico">
                  <div className="mt-4 d-flex flex-column">
                    {<AffiliateStatsChart affiliate={adminDetails.affiliate} />}

                    <Tools reportType={"casino"} getSubCategory={getSubCategory} />
                    {/* <Tools reportType={"sports"} /> */}
                    {(adminDetails?.affiliate?.affiliateType == AFFILIATE_TYPE.STREAMER) ? <Tools reportType={"lives"} /> : null}

                    {(adminDetails?.affiliate?.enableSubAffiliatesSystem) ?
                      <>
                        <Tools reportType={"subAffiliateComissions"} />
                        <Tools reportType={"subAffiliateLiveComissions"} />
                      </>
                      : null}

                  </div>
                </Tab>
                {(adminDetails?.affiliate?.affiliateType == AFFILIATE_TYPE.STREAMER) ?
                  <Tab eventKey="lives" title="Lives">
                    <div className="mt-4 d-flex flex-column">
                      <AffiliateLives affiliate={adminDetails.affiliate} />
                    </div>
                  </Tab>
                  : null}
                <Tab eventKey="transactions" title="Transações">
                  <div className="mt-4 d-flex flex-column">
                    <AffiliateTransactionsTable affiliate={adminDetails.affiliate} />
                  </div>
                </Tab>
                <Tab eventKey="users-by-days" title="Desempenho Diário">
                  <AffiliateDailyPerformanceTable affiliate={adminDetails.affiliate} style={{ maring: 0 }} />
                </Tab>
                <Tab eventKey="users" title="Desempenho de Usuários">
                  <AffiliateUsersPerformanceTable affiliate={adminDetails.affiliate} style={{ maring: 0 }} />
                </Tab>
                {(adminDetails?.affiliate?.enableSubAffiliatesSystem) ?
                  <Tab eventKey="sub-affiliates-performance" title="Desempenho de Sub-afiliados">
                    <SubAffiliatesPerformanceTable affiliate={adminDetails.affiliate} style={{ maring: 0 }} />
                  </Tab>
                  : null}
                {(adminDetails?.affiliate?.enableSubAffiliatesSystem) ?
                  <Tab eventKey="sub-affiliates-daily-performance" title="Desempenho Diário Sub-afiliados">
                    <AffiliateSubAffiliatesDailyPerformanceTable affiliate={adminDetails.affiliate} style={{ maring: 0 }} />
                  </Tab>
                  : null}
              </Tabs>
            </Col>
          </Row>

          {/* </Card> */}
        </>
      )}
    </>
  );
};
