import { takeLatest, put } from 'redux-saga/effects'
import {
    getBetSettings,
    updateBetSettings,
    addBetSettings
} from '../../utils/apiCalls'
import { toast } from '../../components/Toast'

import {
  getBetSettingsStart,
  getBetSettingsSuccess,
  getBetSettingsFailure,
  updateBetSettingsStart,
  updateBetSettingsSuccess,
  updateBetSettingsFailure,
  addBetSettingsStart,
  addBetSettingsSuccess,
  addBetSettingsFailure
} from '../redux-slices/betSettingsSlice'

export default function * adminUserWatcher () {
  yield takeLatest(getBetSettingsStart.type, getBetSettingsWorker);
  yield takeLatest(updateBetSettingsStart.type, updateBetSettingsWorker);
  yield takeLatest(addBetSettingsStart.type, addBetSettingsWorker);
}



function * getBetSettingsWorker () {
  try {
    const { data } = yield getBetSettings()
    yield put(getBetSettingsSuccess(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getBetSettingsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * updateBetSettingsWorker (action) {
    try {
      const { data, toggleAddModal } = action && action.payload;
  
      const res = yield updateBetSettings(data)
      yield toast(res.data.message, 'success')
      yield put(updateBetSettingsSuccess());
      toggleAddModal && toggleAddModal()
      yield put(getBetSettingsStart())
    } catch (e) {
      yield toast(e?.response?.data?.errors[0]?.description, 'error')
  
      yield put(updateBetSettingsFailure(e?.response?.data?.errors[0]?.description))
    }
}

function * addBetSettingsWorker (action) {
    try {
      const { data, toggleAddModal } = action && action.payload;
      const res = yield addBetSettings(data)
      yield toast(res.data.message, 'success')
      yield put(addBetSettingsSuccess());
      toggleAddModal && toggleAddModal()
      yield put(getBetSettingsStart())
    } catch (e) {
      yield toast(e?.response?.data?.errors[0]?.description, 'error')
  
      yield put(addBetSettingsFailure(e?.response?.data?.errors[0]?.description))
    }
}
