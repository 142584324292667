import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from '../../../components/Toast'
import SportsMatchTable from './SportsMatchTable'
import AccordionListing from './AccordionListing'
import {
  Form,
  Row,
  Col,
  Modal,
  Button,
} from '@themesberg/react-bootstrap'
import {
  updateOddsVariationStart,
  getSportsMatchesDetailStart,
  detachOddsVariationStart,
} from '../../../store/redux-slices/sportsBook'
import { InputGroup, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

const SportsMatchesDetail = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const {
    getSportsMatchesDetail,
    isGetSportsMatchesDetailLoading,
    updateOddsVariationLoading,
  } = useSelector(state => state.sportsBook)

  const [expanded, setExpanded] = useState(false);
  const [showOddsModal, setShowOddsModal] = useState(false);
  const [varyType, setVaryType] = useState('increase');
  const [varyPercentage, setVaryPercentage] = useState('')
  const [isAllEvents, setIsAllEvents] = useState('')
  const [matchMarketId, setMatchMarketId] = useState('')
  const [showDetachMarketModal, setShowDetachMarketModal] = useState(false)
  const [marketDetail, setMarketDetail] = useState({
    name: '',
    isDetached: '',
  })

  const handleChange = (item) => {
    setMarketDetail({ ...marketDetail, name: item?.markets?.marketName[0]?.name, isDetached: item?.detach || false })
  };

  const toggleModal = () => {
    setShowOddsModal(!showOddsModal);
  }

  useEffect(() => {
    if (id) {
      dispatch(getSportsMatchesDetailStart({ matchId: id }))
    }
  }, [])

  const handleVarySubmit = () => {
    if (varyPercentage > 100) {
      toast("Odd Percentage can not be more than 100", 'error')
    } else if (varyPercentage < 1) {
      toast("Odd Percentage can not be less than 1", 'error')
    } else {
      dispatch(updateOddsVariationStart({
        matchId: id,
        modificationType: "percentage",
        modificationValue: varyPercentage,
        matchMarketId: !isAllEvents && matchMarketId ? matchMarketId : '',
        type: varyType,
        toggleModal,
        setVaryPercentage
      }))
    }
  }

  const toggleDetachMarketModal = (event) => {
    setShowDetachMarketModal(!showDetachMarketModal)
  }

  const handleDetachMarket = () => {
    const currentMarket = getSportsMatchesDetail?.rows?.find((item) => item.matchMarketId == matchMarketId);
    dispatch(detachOddsVariationStart({
      matchMarketId: matchMarketId ? matchMarketId : '',
      toggleFlag: currentMarket?.detach ? 0 : 1,
      marketId: id,
      toggleDetachMarketModal,
    }))
  }

  return (
    <>
      <div className="section-header-wrap mt-n3 mx-n3">
        <Row>
          <Col>
            <h2 className="title-text">Market Detail</h2>
          </Col>
        </Row>
      </div>
      <SportsMatchTable
        isGetSportsMatchesDetailLoading={isGetSportsMatchesDetailLoading}
        getSportsMatchesDetail={getSportsMatchesDetail}
      />
      <AccordionListing
        toggleModal={toggleModal}
        isGetSportsMatchesDetailLoading={isGetSportsMatchesDetailLoading}
        getSportsMatchesDetail={getSportsMatchesDetail}
        handleChange={handleChange}
        expanded={expanded}
        matchId={id}
        setIsAllEvents={setIsAllEvents}
        setMatchMarketId={setMatchMarketId}
        toggleDetachMarketModal={toggleDetachMarketModal}
      />
      <Modal
        size="md"
        show={showDetachMarketModal}
        onHide={toggleDetachMarketModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!marketDetail.isDetached ? `Are you sure, you want to detach Market ${marketDetail.name}` :
            `Are you sure, you want to attach Market ${marketDetail.name} With More Including Overtime (No Draw)`} ?
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between align-items-center">
          <Button className="btn-danger" onClick={() => toggleDetachMarketModal()}>
            Cancel
          </Button>
          <Button
            className="btn-primary"
            onClick={handleDetachMarket}
          >
            Ok
            {updateOddsVariationLoading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="md"
        show={showOddsModal}
        onHide={toggleModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Odds Variation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Col xs="12" sm="12">
              <Form.Select
                onChange={(e) => {
                  setVaryType(e.target.value)
                }}
                value={varyType}>
                <option value="increase">Increase</option>
                <option value="decrease">Decrease</option>
              </Form.Select>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs="12" sm="12">
              <InputGroup >
                <Form.Control
                  type='number'
                  onChange={(e) => setVaryPercentage(e.target.value)}
                  value={varyPercentage}
                  placeholder="Enter odd Percentage Value"
                  aria-label=""
                  aria-describedby="percentage"
                />
                <InputGroup.Text id="percentage">%</InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between align-items-center">
          <Button className="btn-danger" onClick={() => toggleModal()}>
            Cancel
          </Button>
          <Button
            className="btn-primary"
            onClick={handleVarySubmit}
          >
            Ok
            {updateOddsVariationLoading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SportsMatchesDetail
