import * as Yup from 'yup'

export const passwordValidation = Yup.object().shape({
  password: Yup.string()
    .matches(
      // /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
      /^(?!.*(\d)\1\1)[A-Za-z0-9~!@#$%^&*()]{8,}$/gm,
      'Digite uma senha com no mínimo 8 dígitos contendo letras e/ou números'
    )
    .required('Password Required')
})
