import React from 'react'
import PropTypes from 'prop-types'
// material-ui
import { Box, Typography, Icon } from '@mui/material'
import { StateBoxContainer } from './style'
// ==============================|| DASHBOARD - DEFAULT ||============================== //

const DashboardIconBox = ({ bgColor, title, count, icon, iconColor, footerText }) => {
  return (
    <StateBoxContainer bgColorVal={bgColor}>
      <Box className='dashboard-stats-common'>
      <div className='stats-icon'>
          <Icon fontSize='medium' color='inherit' style={{ color: iconColor }}>
            {icon}
          </Icon>
        </div>
        <div className='state-text-wrap'>
          <Typography className='state-text-head'>
            {title}
          </Typography>
          <Typography variant='h4' className='state-text-subhead'>{count}</Typography>
        </div>
       
      </Box>
        {
            footerText &&
            <Typography className='stats-footer-text'>{footerText}</Typography>
        }

    </StateBoxContainer>
  )
}
DashboardIconBox.propTypes = {
  bgColor: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.number,
  icon: PropTypes.node,
  variant: PropTypes.string
}
export default DashboardIconBox
