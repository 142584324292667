import { ErrorMessage, Formik } from 'formik';
import React, { useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';

import * as Yup from 'yup';
import Button from '../../Button';

export const CancelWithdralRequestFormSchema = () =>
  Yup.object().shape({
    comments: Yup.string().min(5).required('O motivo do cancelamento é obrigatório'),
    userId: Yup.number().required('userId Required'),
    withdrawRequestId: Yup.number().required('withdrawRequestId Required')
  })


const CancelWithdralRequestModal = ({ show, setShow, userId, withdrawRequestId, handleCancel, cancelWithdrawRequestLoader }) => {
  const [loading, setLoading] = useState(false);

  const showData = [
    { label: 'Motivo do cancelamento', value: 'comments' }
  ];

  return (
    <>
      <Formik
        initialValues={{ comments: '', userId, withdrawRequestId }}
        validationSchema={CancelWithdralRequestFormSchema}
        onSubmit={async (formValues, { resetForm }) => {
          setLoading(true)
          handleCancel(formValues)
            .then(() => {
              setShow(false)
            })
            .finally(() => {
              setLoading(false);
            });
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit, isValid }) => (
          <Modal
            show={show}
            onHide={() => setShow(false)}
            backdrop='static'
            keyboard={false}
            size='md'
          >
            <Modal.Header closeButton>
              <h5>Confirmação de cancelamento de solicitação de saque</h5>
            </Modal.Header>
            <Modal.Body>
              <Row>
                {showData?.map(({ label, value }) => (
                  <Col key={value}>
                    <Form.Label>{label}</Form.Label>
                    <Form.Control
                      name={value}
                      as='textarea'
                      rows={3}
                      className='h-auto'
                      value={values[value]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <ErrorMessage
                      component='div'
                      name={value}
                      className='text-danger'
                    />
                  </Col>
                ))}

              </Row>
            </Modal.Body>
            <div className='mt-0'>
              <Modal.Footer className='d-flex justify-content-between align-items-center'>
                <Button
                  variant='outline-warning'
                  onClick={() => setShow(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant='outline-success'
                  className='ml-2'
                  disabled={!isValid}
                  onClick={handleSubmit}
                  loading={loading}
                >
                  Confirmar
                </Button>
              </Modal.Footer>
            </div>
          </Modal>
        )}

      </Formik>
    </>
  )
}

export default CancelWithdralRequestModal
