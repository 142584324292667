import { Col, Row, Table } from '@themesberg/react-bootstrap'
import React, { useEffect, useState } from 'react'
import FormatNumber from '../../../../utils/formatNumber'
import FormatDate from '../../../../utils/formatDate'
import { getSubAffiliateDailyPerformance } from '../../../../utils/apiCalls'
import { Loading } from '../../../../components/Preloader'
import { toast } from '../../../../components/Toast'

import { DashboardHeadTitle } from '../style'
import useDebounce from '../../../../hooks/useDebounce'
import DateRangePicker from '../../../../components/DateRangePicker'
import { formatDateYMD, getDateDaysAgo } from '../../../../utils/dateFormatter'
import UpdateButtonIcon from '../../../../components/UpdateButtonIcon'
import Trigger from '../../../../components/OverlayTrigger'
import PaginationComponent from '../../../../components/Pagination'
import classNames from 'classnames'
import { resolveErrors } from '../../../../utils/errorTypes'

export const tableHeaders = [
  { label: 'Data', value: 'date' },
  { label: 'Cadastros', value: 'newRegister' },
  { label: 'Visitas Afil..', value: 'affiliateLinkVisitCount', message: "Total de visitas no link de afiliado" },
  // { label: 'Visitas Sub-afil..', value: 'subAffiliateLinkVisitCount', message: "Total de visitas no link de sub-afiliado" },
  { label: 'Total FTD', value: 'firstIsDeposit', message: "Total de primeiros depósitos" },
  { label: 'FTD', value: 'amountFirstIsDeposit', message: "Valor total em primeiro depósito" },
  { label: 'QT DEP', value: 'qtdIsDeposit', message: "Total de depóstios" },
  { label: 'Total DEP', value: 'amountQtdIsDeposit', message: "Valor total de depóstios" },
  { label: 'Total Saque', value: 'winthDrawRequestAmount', message: "Valor total de saques" },
  { label: 'Net Deposit', value: 'netDeposit' },
  { label: 'Tunover', value: 'turnover' },
  { label: 'NGR', value: 'ngr' },
  { label: 'Revenue Share', value: 'revenueShare' },
  { label: 'CPA', value: 'cpaCommission' },
  { label: 'Comissão Sub-afiliados', value: 'subAffiliateCommission' },
];

const textColor = (v = 0) => v == 0 ? "" : v > 0 ? "text-success" : "text-danger";

const AffiliateDailyPerformanceTable = ({ affiliate, ...props }) => {
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState({});
  const [page, setPage] = useState(1);

  const [dateRange, setDateRange] = useState([{
    startDate: getDateDaysAgo(30),
    endDate: new Date(),
    key: 'selection'
  }])

  const loadAffiliateUsers = useDebounce(async ({ loading, initialLoading, page }) => {
    if (loading) setLoading(true);
    if (initialLoading) setInitialLoading(true);
    try {
      const users = await getSubAffiliateDailyPerformance({
        affiliateId: affiliate?.affiliateId,
        startDate: formatDateYMD(dateRange.map(a => a.startDate)),
        endDate: formatDateYMD(dateRange.map(a => a.endDate)),
        pageNo: page || 1,
        limit: 15
      })

      setUsers(users.data.data);
    } catch (err) {
      toast(resolveErrors(err?.response?.data?.errors, "Error ao obter dados de desempenho diário"), "error");
    } finally {
      setLoading(false);
      setInitialLoading(false);
    }
  }, 400)

  useEffect(() => {
    if (affiliate?.affiliateId) loadAffiliateUsers({ initialLoading: true })
  }, [affiliate, dateRange])

  if (!affiliate) return;

  return (<>
    <div className='TableBg mt-4 p-2' {...props}>
      <DashboardHeadTitle as={Row} className="mt-2 px-2 d-flex">
        <Col md={6} xs={12}>
          <h3 className="dashboard-spec-text">Desempenho Diário dos Sub-afiliados</h3>
        </Col>
        <Col md={6} xs={12}>
          <Row className='mt-0 justify-content-end'>
            <Col xs={1} md={1} className='d-flex align-items-center justify-content-end'>
              <UpdateButtonIcon
                onClick={() => loadAffiliateUsers({ loading: true })}
                {...{ loading }}
              />
            </Col>
            <Col xs={6} md={6} >
              <DateRangePicker
                state={dateRange} setState={setDateRange}
                size='sm'
                placement={"right"}
              />
            </Col>
          </Row>
        </Col>

      </DashboardHeadTitle>
      <div className='position-relative h-100'>
        <Table striped responsive hover size='sm' className='text-center m-0'>
          <thead className='thead-light'>
            <tr>
              {tableHeaders.map((h, idx) => (
                <Trigger message={h.message} placement='top'>
                  <th
                    key={`T-table_heading ${idx}`}
                    className={`${['Amount'].includes(h.label) && users?.count > 0 ? 'text-right' : ''}`}
                  >
                    {h.label}
                  </th>
                </Trigger>
              ))}
            </tr>
          </thead>

          <tbody>
            {users && !initialLoading &&
              users?.result?.map(
                ({
                  date,
                  newRegister,
                  firstIsDeposit,
                  amountFirstIsDeposit,
                  qtdIsDeposit,
                  amountQtdIsDeposit,
                  winthDrawRequestAmount,
                  netDeposit,
                  turnover,
                  ngr,
                  revenueShare,
                  subAffiliateCommission,
                  affiliateLinkVisitCount,
                  cpaCommission,
                  subAffiliateLinkVisitCount,
                }) => {
                  return (
                    <tr key={`transaction-list ${date}`}>
                      <td>{FormatDate.toDayMonthYear(date)}</td>
                      <td>{newRegister}</td>
                      <td>{affiliateLinkVisitCount}</td>
                      {/* <td>{subAffiliateLinkVisitCount}</td> */}
                      <td>{firstIsDeposit}</td>
                      <td className={classNames("text-right", textColor(amountFirstIsDeposit))}>{FormatNumber.currency(amountFirstIsDeposit || 0)}</td>
                      <td>{qtdIsDeposit}</td>
                      <td className={classNames("text-right", textColor(amountQtdIsDeposit))}>{FormatNumber.currency(amountQtdIsDeposit || 0)}</td>
                      <td className={classNames("text-right", textColor(winthDrawRequestAmount))}>{FormatNumber.currency(winthDrawRequestAmount || 0)}</td>
                      <td className={classNames("text-right", textColor(netDeposit))}>{FormatNumber.currency(netDeposit || 0)}</td>
                      <td className={classNames("text-right", textColor(turnover))}>{FormatNumber.currency(turnover || 0)}</td>
                      <td className={classNames("text-right", textColor(ngr))}>{FormatNumber.currency(ngr || 0)}</td>
                      <td className={classNames("text-right", textColor(revenueShare))}>{FormatNumber.currency(revenueShare || 0)}</td>
                      <td className={classNames("text-right", textColor(cpaCommission))}>{FormatNumber.currency(cpaCommission || 0)}</td>
                      <td className={classNames("text-right", textColor(subAffiliateCommission))}>{FormatNumber.currency(subAffiliateCommission || 0)}</td>
                    </tr>
                  )
                })}

            {users?.count === 0 && !loading && (
              <tr>
                <td colSpan={10} className='text-danger text-center'>
                  Nenhum dado encontrado!
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        {initialLoading ? <Loading className={"position-absolute bg-white top-0 w-100 h-100"} /> : null}
      </div>
      {users?.result?.length > 0 && (users?.totalPages) && !initialLoading && (
        <PaginationComponent
          page={page}
          totalPages={users?.totalPages}
          setPage={(page) => {
            setPage(page);
            loadAffiliateUsers({ loading: true, page });
          }}
        // limit={limit}
        // setLimit={setLimit}
        />
      )}
    </div>
  </>)
}

export default AffiliateDailyPerformanceTable;


