import { Table } from '@themesberg/react-bootstrap'
import React from 'react'
// import { getTextColor } from '../../utils/dashboardEffects'
// import { defaultCurrency } from '../../utils/constant'
import { camelCaseToNormal } from '../../utils/transform'
import FormatNumber from '../../utils/formatNumber'

export const KPISummaryTable = ({ KPISummary, type }) => {

  return (
    <div style={{ overflowY: 'auto' }}>
      <Table striped hover size='sm' className='text-center mt-4'>
        <thead className='thead-light'>
          <tr>
            {type === 'banking' && (
              <>
                <th className='text-left'>Tipo de transação</th>
                <th className='text-left'>Número de transações</th>
                <th className='text-left'>Valor das transações</th>
                {/* <th className='text-left'>Currency Code</th> */}
              </>
            )}
            {type === 'casino' && (
              <>
                <th className='text-left'>Valores das apostas</th>
                <th className='text-left'>Quantidade de apostas</th>
                <th className='text-left'>Valores dos prêmios</th>
                <th className='text-left'>Quantidade de prêmios</th>
                <th className='text-left'>GGR</th>
              </>
            )}
            {type === 'sports' && (
              <>
                <th className='text-left'>Valores das apostas</th>
                <th className='text-left'>Valores dos prêmios</th>
                <th className='text-left'>GGR</th>
              </>
            )}
          </tr>
        </thead>

        <tbody>
          {KPISummary.length && KPISummary?.map(({ transactionType, count, amount, betAmount, betCount, winAmount, winCount, ggr }, index) => {
            return (
              <tr key={index}>
                {type === 'banking' && (
                  <>
                    <td className='text-left'>{transactionType || 'N/A'}</td>
                    <td className='text-left'>{count || 0}</td>
                    <td className='text-left'>{FormatNumber.currency(amount || 0)}</td>
                  </>
                )}

                {type === 'casino' && (
                  <>
                    <td className='text-left'>{FormatNumber.currency(betAmount || 0)}</td>
                    <td className='text-left'>{betCount || 0}</td>
                    <td className='text-left'>{FormatNumber.currency(winAmount || 0)}</td>
                    <td className='text-left'>{winCount || 0}</td>
                    <td className='text-left'>{FormatNumber.currency(ggr || 0)}</td>
                  </>
                )}

                {type === 'sports' && (
                  <>
                    <td className='text-left'>{FormatNumber.currency(betAmount || 0)}</td>
                    <td className='text-left'>{FormatNumber.currency(winAmount || 0)}</td>
                    <td className='text-left'>{FormatNumber.currency(ggr || 0)}</td>
                  </>
                )}
              </tr>
            )
          })}
        </tbody>

        {
          KPISummary?.length < 1 && (
            <td className='text-danger' colSpan={3}>Registros não encontrados.</td>
          )
        }

      </Table>
    </div>
  )
}
