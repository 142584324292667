import React from 'react'
import {
  Button,
  Form as BForm,
  InputGroup,
  Row,
  Col,
  Spinner
} from '@themesberg/react-bootstrap'
import { Formik, Form, ErrorMessage } from 'formik'

import { AdminsRoutes } from '../../../../routes'
import { createLoyaltyRuleSchema } from '../schema'
import useCreateLoyaltyRule from '../hooks/useCreateLoyaltyRule'

const CreateLoyaltyRule =  ({
  level,
  loyaltyPoint,
  loyaltyCoins,
  id
}) => {
  const { navigate, createLoyaltyRule, editLoyaltyRule, loading } =
  useCreateLoyaltyRule()

  return (
    <>
          <div className='section-header-wrap mt-n3 mx-n3'>
          <h2 className='title-text'>
        {id ? 'Edit Loyalty Rule' : 'Create Loyalty Rule'}
       </h2>
          </div>
      
          <div className='FormsBg'>
      <Formik
        initialValues={{
          level: level || '',
          loyaltyPoint: loyaltyPoint || '',
          loyaltyCoins: loyaltyCoins || '',
        }}
        validationSchema={createLoyaltyRuleSchema()}
        onSubmit={({ level, loyaltyPoint, loyaltyCoins}) => {
          id
            ? editLoyaltyRule({
              loyaltyId:id,
              loyaltyPoint,
              loyaltyCoins,
              navigate
            })
            : createLoyaltyRule({
              loyaltyPoint,
              loyaltyCoins,
              navigate
            })
        }}
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleSubmit,
          handleBlur
        }) => (
          <div className='m-4'>
            <Form>
              {/* Div for name */}
              <Row>
                <Col xs='12' sm='6'>
                  <div className='form-group mb-2'>
                    <label
                      htmlFor='loyaltyCoins'
                      className={touched.loyaltyCoins && errors.loyaltyCoins ? 'text-danger' : ''}
                    >
                      <b>Loyalty Coins</b>
                      <span className='text-danger'> *</span>
                    </label>

                    <InputGroup
                      className={
                          touched.loyaltyCoins && errors.loyaltyCoins ? 'border border-danger' : ''
                        }
                    >
                      <BForm.Control
                        name='loyaltyCoins'
                        required
                        type='number'
                        placeholder='Enter loyalty coins'
                        value={values.loyaltyCoins}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </InputGroup>

                    <ErrorMessage
                      component='div'
                      name='name'
                      className='text-danger'
                    />
                  </div>
                </Col>

                {/* Div for code */}
                <Col xs='12' sm='6'>
                  <div className='form-group  mb-2'>
                    <label
                      htmlFor='loyaltyPoint'
                      className={touched.loyaltyPoint && errors.loyaltyPoint ? 'text-danger' : ''}
                    >
                      <b>Loyalty Point</b>
                      <span className='text-danger'> *</span>
                    </label>

                    <InputGroup
                      className={
                          touched.loyaltyPoint && errors.loyaltyPoint ? 'border border-danger' : ''
                        }
                    >
                      <BForm.Control
                        name='loyaltyPoint'
                        required
                        type='number'
                        placeholder='Enter loyalty point'
                        value={values.loyaltyPoint}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        // disabled={!!editName}
                      />
                    </InputGroup>

                    <ErrorMessage
                      component='div'
                      name='code'
                      className='text-danger'
                    />
                  </div>
                </Col>

                {/* Div for Symbol */}
               
              </Row>

              <div className='mt-4 d-flex justify-content-between align-items-center'>
                <Button
                  className='btn-danger ml-2'
                  onClick={() => navigate(AdminsRoutes.Loyalty)}
                >
                  Cancel
                </Button>
                <Button
                  className='btn-primary'
                  onClick={handleSubmit}
                >
                  Submit
                  {loading && (
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  )}
                </Button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
      </div>
    </>
  )
}

export default CreateLoyaltyRule
