import {
  parseISO,
  format,
  differenceInDays,
  startOfDay,
  endOfDay,
} from 'date-fns';

/**A Helper to format date */
export class FormatDate {

  static default(/** @type { string }*/date) {
    if (date == null || typeof date == 'undefined' || !date) return 'N/A'
    return format(
      parseISO(date),
      "dd-MM-yyyy' às ' HH:mm'h'",
      { timeZone: 'America/Sao_Paulo' }
    );
  }

  static toDateHour(/** @type { string }*/date) {
    if (date == null || typeof date == 'undefined' || !date) return 'N/A'
    return format(
      parseISO(date),
      "dd-MM-yyyy' às ' HH:mm'h'",
      { timeZone: 'America/Sao_Paulo' }
    );
  }

  static toDateOnly(/** @type { string }*/date, separator = "-") {
    if (date == null || typeof date == 'undefined' || !date) return 'N/A'
    return format(
      parseISO(new Date(date).toISOString()),
      `dd${separator}MM${separator}yyyy`,
      { timeZone: 'America/Sao_Paulo' }
    );
  }

  static differenceInDays(/** @type { string | Date }*/startDate, /** @type { string | Date }*/ endDate = new Date()) {
    if (startDate == null || typeof startDate == 'undefined' || !startDate) return 'N/A'
    return differenceInDays(parseISO(new Date(endDate).toISOString()), parseISO(new Date(startDate).toISOString()));
  }

  static toStartDate(/** @type { string | Date }*/startDate,) {
    return startOfDay(new Date(startDate))
  }

  static toEndDate(/** @type { string | Date }*/endDate,) {
    return endOfDay(new Date(endDate))
  }

  /** `YYYY-MM-DD` --> `DD-MM-YYYY` */
  static toDayMonthYear(/** @type { string }*/date, separator = "-") {
    return date.split(separator).reverse().join(separator);
  }

}

export default FormatDate
