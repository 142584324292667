import { takeLatest, put, select } from 'redux-saga/effects'
import {
  //  getAllTenants,
  // createTenant,
  // createTenantAdmin,
  // getTenant,
  // editTenant,
  // superAdminViewToggleStatus,
  // getAllCredentials,
  // createTenantCredentials,
  // updateTenantCredentials,
  // updateTenantAdmin,
  addDepositToOther,
  // getAllClients,
  // getAllPortals,
  // getTenantList,
  // getOwnerPermissions
} from '../../utils/apiCalls'

import {
  getAllTenantsStart,
  getAllTenantsSuccess,
  getAllTenantsFailure,
  createTenantStart,
  createTenantSuccess,
  createTenantFailure,
  createTenantAdminStart,
  createTenantAdminSuccess,
  createTenantAdminFailure,
  getTenantStart,
  getTenantSuccess,
  getTenantFailure,
  editTenantStart,
  editTenantSuccess,
  editTenantFailure,
  updateTenantStatusStart,
  updateTenantStatusSuccess,
  updateTenantStatusFailure,
  getAllCredentialsStart,
  getAllCredentialsSuccess,
  getAllCredentialsFailure,
  createCredentialsStart,
  createCredentialsSuccess,
  createCredentialsFailure,
  updateCredentialsStart,
  updateCredentialsSuccess,
  updateCredentialsFailure,
  updateTenantAdminStart,
  updateTenantAdminComplete,
  addDepositToOtherStart,
  addDepositToOtherCompleted,
  getAllClientsStart,
  getAllClientsSuccess,
  getAllClientsFailure,
  getAllPortalsStart,
  getAllPortalsSuccess,
  getAllPortalsFailure,
  getAllTenantsListStart,
  getAllTenantsListFailure,
  getAllTenantsListSuccess,
  getOwnerPermissionStart,
  getOwnerPermissionSuccess,
  getOwnerPermissionFailure
} from '../redux-slices/tenants'

import { toast } from '../../components/Toast'
import { AdminsRoutes } from '../../routes'
import { objectToFormData } from '../../utils/objectToFormdata'
import { getTenantUserDetailsStart } from '../redux-slices/tenantUsers'
import { getUserStart } from '../redux-slices/fetchData'
import { serialize } from 'object-to-formdata'
const getTenantData = state => state.tenants

export default function * tenantsWatcher () {
  // yield takeLatest(getAllTenantsStart.type, getAllTenantsWorker)
  // yield takeLatest(createTenantStart.type, createTenantWorker)
  // yield takeLatest(createTenantAdminStart.type, createTenantAdminWorker)
  // yield takeLatest(getTenantStart.type, getTenantWorker)
  // yield takeLatest(editTenantStart.type, editTenantWorker)
  // yield takeLatest(updateTenantStatusStart.type, updateTenantStatusWorker)
  // yield takeLatest(getAllCredentialsStart.type, getAllCredentialsWorker)
  // yield takeLatest(createCredentialsStart.type, createTenantCredentialsWorker)
  // yield takeLatest(updateCredentialsStart.type, updateTenantCredentialsWorker)
  // yield takeLatest(updateTenantAdminStart.type, updateTenantAdminWorker)
  yield takeLatest(addDepositToOtherStart.type, AddDepositWorker)
  // yield takeLatest(getAllClientsStart.type, getClientsWorker)
  // yield takeLatest(getAllPortalsStart.type, getPortalsWorker)
  // yield takeLatest(getAllTenantsListStart.type, getTenantsListWorker)
  // yield takeLatest(getOwnerPermissionStart.type, getOwnerPermissionWorker)
}
function * AddDepositWorker (action) {
  try {
    const { data, isTenant } = action && action.payload

    yield addDepositToOther({ data, isTenant })

    yield put(addDepositToOtherCompleted())

     yield put(getUserStart({ userId: data?.userId }))

    data?.addAmount > 0
      ? yield toast('Deposit Successful', 'success')
      : yield toast('Amount Removed from Wallet Successful', 'success')
  } catch (e) {
    yield put(addDepositToOtherCompleted())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

// function * getAllTenantsWorker (action) {
//   try {
//     const { limit, pageNo, search, adminId, status } = action && action.payload

//     const { data } = yield getAllTenants({ limit, pageNo, search, adminId, status })

//     yield put(getAllTenantsSuccess(data?.data?.tenants))
//   } catch (e) {
//     yield toast(e?.response?.data?.errors[0]?.description, 'error')

//     yield put(getAllTenantsFailure(e?.response?.data?.errors[0]?.description))
//   }
// }

// function * createTenantWorker (action) {
//   try {
//     const { createTenantData, navigate } = action && action.payload
//     createTenantData.depositMethods = JSON.stringify(createTenantData.depositMethods)
//     createTenantData.withdrawMethods = JSON.stringify(createTenantData.withdrawMethods)
//     const data = serialize(createTenantData)

//     yield createTenant(data)

//     yield put(createTenantSuccess())

//     yield toast('Tenant Created Successfully', 'success')

//     navigate(`/admin/tenants/${createTenantData?.adminId}`)
//   } catch (e) {
//     const { setCreateTenantData, setSelectedTab, createTenantData } = action && action.payload
//     setCreateTenantData({
//       ...createTenantData,
//       depositMethods: [],
//       withdrawMethods: []
//     })
//     setSelectedTab('deposit')
//     yield toast(e?.response?.data?.errors[0]?.description, 'error')

//     yield put(createTenantFailure(e?.response?.data?.errors[0]?.description))
//   }
// }

// function * createTenantAdminWorker (action) {
//   try {
//     const { createTenantAdminData, navigate } = action && action.payload

//     yield createTenantAdmin(createTenantAdminData)

//     yield put(createTenantAdminSuccess())

//     yield toast('Tenant Owner Created Successfully', 'success')

//     navigate(AdminsRoutes.TenantOwner)
//   } catch (e) {
//     yield toast(e?.response?.data?.errors[0]?.description, 'error')

//     yield put(createTenantAdminFailure(e?.response?.data?.errors[0]?.description))
//   }
// }

// function * getTenantWorker (action) {
//   try {
//     const { tenantId } = action && action.payload

//     const { data } = yield getTenant({ tenantId })

//     yield put(getTenantSuccess(data?.data?.tenantDetails))
//   } catch (e) {
//     yield toast(e?.response?.data?.errors[0]?.description, 'error')

//     yield put(getTenantFailure(e?.response?.data?.errors[0]?.description))
//   }
// }

// function * editTenantWorker (action) {
//   try {
//     const { createTenantData, navigate, val, tenantId } =
//       action && action.payload

//     createTenantData.depositMethods = JSON.stringify(createTenantData.depositMethods)
//     createTenantData.withdrawMethods = JSON.stringify(createTenantData.withdrawMethods)
//     const { tenantDetails } = yield select(getTenantData)
//     if (createTenantData?.providerIds && tenantDetails?.tenantConfig?.gameProviders) {
//       createTenantData.providerIds = createTenantData?.providerIds?.filter(id => !tenantDetails?.tenantConfig?.gameProviders?.includes(id))
//     }
//     createTenantData.providerIds = JSON.stringify(createTenantData?.providerIds)

//     yield editTenant(objectToFormData(createTenantData))

//     yield put(editTenantSuccess())

//     yield put(getTenantStart({ tenantId }))

//     yield toast('Tenant Edited Successfully', 'success')

//     val && navigate(-1)
//   } catch (e) {
//     yield toast(e?.response?.data?.errors[0]?.description, 'error')

//     yield put(editTenantFailure(e?.response?.data?.errors[0]?.description))
//   }
// }

// function * updateTenantStatusWorker (action) {
//   try {
//     const { tenantId, status, limit, page, search, adminId, isActive = '' } =
//       action && action.payload

//     yield superAdminViewToggleStatus({ tenantId, status, code: 'TENANT' })

//     yield put(updateTenantStatusSuccess())

//     yield put(getAllTenantsStart({ limit, pageNo: page, search, adminId, status: isActive }))

//     yield toast('Tenant Status Updated Successfully', 'success')
//   } catch (e) {
//     yield toast(e?.response?.data?.errors[0]?.description, 'error')

//     yield put(updateTenantStatusFailure(e?.response?.data?.errors[0]?.description))
//   }
// }

// function * getAllCredentialsWorker () {
//   try {
//     const { data } = yield getAllCredentials()

//     yield put(getAllCredentialsSuccess(data?.data?.credentialsKeys))
//   } catch (e) {
//     yield toast(e?.response?.data?.errors[0]?.description, 'error')

//     yield put(getAllCredentialsFailure(e?.response?.data?.errors[0]?.description))
//   }
// }

// function * createTenantCredentialsWorker (action) {
//   try {
//     const { tenantId, adminId, key, value, description, navigate } =
//       action && action.payload

//     yield createTenantCredentials({ tenantId, key, value, description })

//     yield put(createCredentialsSuccess())
//     yield toast('Credentials Created Successfully', 'success')
//     navigate(`/admin/tenants/details/${adminId}/${tenantId}`)
//   } catch (e) {
//     yield toast(e?.response?.data?.errors[0]?.description, 'error')

//     yield put(createCredentialsFailure(e?.response?.data?.errors[0]?.description))
//   }
// }

// function * updateTenantCredentialsWorker (action) {
//   try {
//     const { tenantId, adminId, key, value, description, navigate } =
//       action && action.payload

//     yield updateTenantCredentials({ tenantId, key, value, description })

//     yield put(updateCredentialsSuccess())
//     yield toast('Credentials updated Successfully', 'success')
//     navigate(`/admin/tenants/details/${adminId}/${tenantId}`)
//   } catch (e) {
//     yield toast(e?.response?.data?.errors[0]?.description, 'error')

//     yield put(updateCredentialsFailure(e?.response?.data?.errors[0]?.description))
//   }
// }

// function * updateTenantAdminWorker (action) {
//   try {
//     const { createTenantAdminData, adminUserId, navigate } = action && action.payload
//     createTenantAdminData.adminUserId = adminUserId

//     yield updateTenantAdmin(createTenantAdminData)

//     yield put(updateTenantAdminComplete())

//     yield toast('Tenant Owner Updated Successfully', 'success')
//     navigate(AdminsRoutes.TenantOwner)
//   } catch (e) {
//     yield toast(e?.response?.data?.errors[0]?.description, 'error')
//     yield put(updateTenantAdminComplete())
//   }
// }



// function * getClientsWorker (action) {
//   try {
//     const { orderBy, limit, pageNo, sort, search, status = '' } = action && action.payload

//     const { data } = yield getAllClients({ orderBy, limit, pageNo, sort, search, status })

//     yield put(getAllClientsSuccess(data?.data?.adminDetails))
//   } catch (e) {
//     yield toast(e?.response?.data?.errors[0]?.description, 'error')

//     yield put(getAllClientsFailure())
//   }
// }

// function * getPortalsWorker (action) {
//   try {
//     const { adminId } = action && action.payload

//     const { data } = yield getAllPortals({ adminId })

//     yield put(getAllPortalsSuccess(data?.data?.tenantDetails))
//   } catch (e) {
//     yield toast(e?.response?.data?.errors[0]?.description, 'error')

//     yield put(getAllPortalsFailure())
//   }
// }

// function * getTenantsListWorker () {
//   try {
//     const { data } = yield getTenantList()

//     yield put(getAllTenantsListSuccess(data?.data?.tenantDetails))
//   } catch (e) {
//     yield toast(e?.response?.data?.errors[0]?.description, 'error')

//     yield put(getAllTenantsListFailure())
//   }
// }

// function * getOwnerPermissionWorker () {
//   try {
//     const { data } = yield getOwnerPermissions()

//     yield put(getOwnerPermissionSuccess(data?.data?.permissions))
//   } catch (e) {
//     yield toast(e?.response?.data?.errors[0]?.description, 'error')

//     yield put(getOwnerPermissionFailure())
//   }
// }
