import { faEdit } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Accordion, Button, Col, Row, Table } from '@themesberg/react-bootstrap'
import React from 'react'
import ClientFilter from '../../../components/ClientFilter'
import PortalFilter from '../../../components/PortalFilter'
import Preloader from '../../../components/Preloader'
import EditUploadBanner from './EditUploadBanner'
import useBannerManagement from './useBannerManagement'
import Trigger from '../../../components/OverlayTrigger'
import { bannerType } from './constants'
import { motion } from 'framer-motion/dist/framer-motion'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import ActionMenu from './ActionMenu'
import { camelCaseToNormal } from '../../../utils/transform'

export default () => {
  const {
    loading,
    handleDelete,
    handleCreateEdit,
    type,
    data,
    setShow,
    show,
    createUpdate,
    clientsFilterData,
    portalsFilterData,
    dispatch,
    SABanners,
    isHidden,
    validation,
    setValidation,
  } = useBannerManagement()

  const getCheckCreateBanner = () => {
    let showButton = false
    const uploadedBanner = SABanners && SABanners.length > 0 && Object.keys(SABanners[0].value) || []
    //  const isExit= bannerType.some(v => uploadedBanner.includes(v.value))
    if (uploadedBanner.length === bannerType.length) {
      showButton = true
    }
    return showButton;
  }



  return (
    <>
      <div className='section-header-wrap mt-n3 mx-n3'>
        <Row>
          <Col lg={8} xs={7}>
            <h2 className='title-text'>Banner Management</h2>
          </Col>


          <Col lg={4} xs={5}>
            {/* <div className='d-flex justify-content-end align-items-center'> */}

            <div className='d-flex justify-content-end'>
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Button
                  // disabled={getCheckCreateBanner()}
                  variant='btn btn-primary w-100'
                  onClick={() => handleCreateEdit('Create', {
                    
                  })}
                  hidden={isHidden({ module: { key: 'BannerManagement', value: 'C' } })}
                >
                  <AddCircleOutlineRoundedIcon className='me-2' />
                  <span>Upload</span>
                </Button>
              </motion.div>
            </div>
            {/* </div> */}
          </Col>
        </Row>
      </div>
      {/* 
        <div className='FormsBg'>
        <Row>
        <Col xs={6}>
        <ClientFilter
              setSelectedClient={setSelectedClient}
              setSelectedPortal={setSelectedPortal}
              selectedClient={selectedClient}
            />

            {selectedClient && (
              <PortalFilter
                setSelectedPortal={setSelectedPortal}
                selectedPortal={selectedPortal}
                selectedClient={selectedClient}
              />
            )}
        </Col>

        </Row>
        </div> */}
      {/* <Accordion> */}
      {

        <div className='TableBg mt-4'>
          <Table striped responsive hover size='sm' className='text-center'>
            <thead className='thead-light'>
              <tr>
                {['Pages', 'Banner Preview', 'Action'].map((h) => (
                  <th key={h}>{h}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              {SABanners?.map((key, indx) => {
                return (
                  <tr key={indx}>
                    <td>{camelCaseToNormal(key?.bannerKey || 'N/A')} {key?.isMobileBanner ? '(Mobile)':''}</td>
                    <td>
                      <span
                        style={{
                          cursor: 'pointer'
                        }}
                        className='text-link'
                        onClick={() => window.open(key?.file)}
                      >
                        Banner Preview
                      </span>
                    </td>

                    
                    {/* <td>{bannerType?.map((item) => {
                      if (key === item?.value) {
                        return item?.label
                      }
                      return null
                    })}
                    </td>
                     */}
                    <td>
                      <ActionMenu
                        isHidden={isHidden}
                        handleCreateEdit={handleCreateEdit}
                        handleDelete={handleDelete}
                        // thumbnail={item?.value?.[key]}
                        bannerType={key}

                      />
                      {/* {isHidden({ module: { key: 'BannerManagement', value: 'U' } })
                                ? '-'
                                : (
                                  <Trigger message='Update Banner'>
                                    <Button
                                      size='sm'
                                      className='btn-primary'
                                      onClick={() => {
                                        handleCreateEdit('Update',
                                          {
                                            // adminId: item?.['tenant.adminUserId'],
                                            // tenantId: item?.tenantId,
                                            thumbnail: item?.value?.[key],
                                            bannerType: key
                                          })
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                  </Trigger>)} */}
                    </td>
                  </tr>
                )
              })}

              {(!SABanners || SABanners?.length === 0) && (
                <tr>
                  <td colSpan={4} className='text-danger text-center'>
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        

      }

 
      <EditUploadBanner
        type={type}
        data={data}
        show={show}
        setShow={setShow}
        createUpdate={createUpdate}
        clientsFilterData={clientsFilterData}
        portalsFilterData={portalsFilterData}
        dispatch={dispatch}
        SABanners={SABanners}
        validation={validation}
        setValidation={setValidation}
      />

    </>
  )
}
