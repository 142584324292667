import React, { useState } from "react";
import { Modal, Button } from "@themesberg/react-bootstrap";

const ConfirmApprovalModal = ({ show, setShow, handleApproveRequest, User }) => {
  const handleApprove = () => {
    handleApproveRequest();
    setShow(false);
  };

  const handleClose = () => {
    setShow(false)
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmar aprovação de saque</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Você tem certeza que deseja confirmar a solicitação de saque de {User?.username}?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button
          variant="primary"
          onClick={handleApprove}
        >
          Aprovar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmApprovalModal;
