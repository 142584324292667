import React from 'react'
import './Preloader.scss'

export const Preloader = ({ center, ...props }) => {
  return <div id='cover-spin' data-center={center??undefined} {...props}/>
}

export const Loading = ({ center, ...props }) => {
  return <div data-type="loading" data-center={center??undefined} {...props}><span id='cover-spin'/></div>
}

const SpinnerLoader = () => {
  return <div id='small-spinner' />
}

const InlineLoader = () => {
  return <div id='inline-spinner' />
}

export default Preloader
export { SpinnerLoader, InlineLoader }
