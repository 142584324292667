import styled from 'styled-components';

export const ComingSoonContainer = styled.div`
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    font-weight: bold;
    font-family: inherit;
    color: #07003d;
`;
