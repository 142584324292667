import React from 'react';
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded'
import {
    Button,
  } from '@themesberg/react-bootstrap'
import { CancelOutlined, Edit, EditAttributesOutlined, InfoRounded, RedoOutlined } from '@mui/icons-material';
const ActionMenu = (props) => {
    const {label,
    value,
    minimum ,
    setLimit,
    setLimitModal,
    isHidden,
    setData,
    setResetModal} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl)
    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    return (
        <>
            <Button
                variant='btn text-btn'
                id='basic-button'
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
                }}
            >
                <MoreVertRoundedIcon />
            </Button>
            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
                >
                <MenuItem onClick={handleClose}>
                    <Button
                    // size='sm'
                    variant='btn text-btn'
                    onClick={() => {
                      setLimit({ label, value, minimum })
                      setLimitModal(true)
                    }}                   
                     hidden={isHidden({ module: { key: 'Users', value: 'SR' } })}    

                    >
                        <Edit />
                        <span className="text">Set Limit</span>
                    </Button>
                </MenuItem>

                <MenuItem onClick={handleClose}>
                    <Button
                    // size='sm'
                    variant='btn text-btn'
                    disabled={!value}
                      onClick={() => {
                        setData(label)
                        setResetModal(true)
                      }}                     
                    >
                        <RedoOutlined />{label}
                        <span className="text">Reset Limit</span>
                    </Button>
                </MenuItem>
            </Menu> 
        </>
    )
};

export default ActionMenu;