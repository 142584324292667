import React from 'react'
import { Formik, Form, ErrorMessage, Field } from 'formik'
import {
  Col,
  Row,
  Form as BForm,
  Button,
  Spinner,
  InputGroup,
  Card,
  Badge
} from '@themesberg/react-bootstrap'
import { createTenantAdminSchema } from '../../Tenants/schemas'
import { AdminsRoutes } from '../../../../routes'
import useCreateTenantAdmin from '../hooks/useCreateTenantAdmin'
import Preloader from '../../../../components/Preloader'
import Trigger from '../../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import { toast } from '../../../../components/Toast'

const CreateTenantAdmin = ({ tenantData }) => {
  const {
    createTenantAdmin,
    navigate,
    loading,
    updateTenantAdmin,
    type,
    setType,
    ownerPermission,
    permissionLabel
  } = useCreateTenantAdmin(tenantData)

  return (
    <>
      {loading
        ? (
          <Preloader />
          )
        : (
          <div>
            <Row>
              <Col className='d-flex'>
                <h3>{tenantData ? 'Edit' : 'Create'} Tenant Owner</h3>
              </Col>
            </Row>

            <>
              <Formik
                enableReinitialize
                initialValues={{
                  email: tenantData ? tenantData?.email : '',
                  password: '',
                  firstName: tenantData ? tenantData?.firstName : '',
                  lastName: tenantData ? tenantData?.lastName : '',
                  agentName: tenantData ? tenantData?.agentName : '',
                  phone: tenantData ? tenantData?.phone : '',
                  permission: tenantData
                    ? tenantData?.userPermission?.permission
                    : { ...ownerPermission, CMS: ['R'], Users: ['R', 'U', 'T', 'AB'], Bonus: ['R', 'U', 'C', 'T'] }
                }}
                validationSchema={createTenantAdminSchema(tenantData)}
                onSubmit={(formValues) => {
                  const createTenantAdminData = {
                    ...formValues,
                    firstName: formValues.firstName.trim(),
                    lastName: formValues.lastName.trim(),
                    ...(!tenantData && { password: Buffer.from(formValues.password).toString('base64') }),
                    agentName: formValues?.agentName.trim()
                  }
                  tenantData
                    ? updateTenantAdmin({
                      createTenantAdminData,
                      adminUserId: tenantData?.adminUserId
                    })
                    : createTenantAdmin(createTenantAdminData)
                }}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  setFieldValue
                }) => (
                  <Form>
                    <Row>
                      <Col>
                        <BForm.Label>
                          Email<span className='text-danger'> *</span>
                        </BForm.Label>

                        <BForm.Control
                          type='text'
                          name='email'
                          placeholder='Enter Email'
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <ErrorMessage
                          component='div'
                          name='email'
                          className='text-danger'
                        />
                      </Col>

                      <Col>
                        <BForm.Label>Phone Number</BForm.Label>

                        <BForm.Control
                          type='text'
                          name='phone'
                          placeholder='Enter Phone'
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <ErrorMessage
                          component='div'
                          name='phone'
                          className='text-danger'
                        />
                      </Col>

                    </Row>

                    <Row className='mt-3'>
                      <Col>
                        <BForm.Label>
                          First Name<span className='text-danger'> *</span>
                        </BForm.Label>

                        <BForm.Control
                          type='text'
                          name='firstName'
                          placeholder='Enter First Name'
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <ErrorMessage
                          component='div'
                          name='firstName'
                          className='text-danger'
                        />
                      </Col>

                      <Col>
                        <BForm.Label>
                          Last Name<span className='text-danger'> *</span>
                        </BForm.Label>

                        <BForm.Control
                          type='text'
                          name='lastName'
                          placeholder='Enter Last Name'
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <ErrorMessage
                          component='div'
                          name='lastName'
                          className='text-danger'
                        />
                      </Col>
                    </Row>

                    <Row className='mt-3'>
                      <Col xs={12} md={6}>
                        <BForm.Label>
                          Username<span className='text-danger'> *</span>
                        </BForm.Label>

                        <BForm.Control
                          type='text'
                          name='agentName'
                          placeholder='Enter Username'
                          value={values.agentName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <ErrorMessage
                          component='div'
                          name='agentName'
                          className='text-danger'
                        />
                      </Col>

                      {!tenantData &&
                        <Col>
                          <BForm.Label>
                            Password<span className='text-danger'> *</span>
                          </BForm.Label>

                          <Trigger message='Must be at least 8 characters along with 1 uppercase, 1 lowercase letter, 1 special character and 1 digit'>
                            <InputGroup>
                              <BForm.Control
                                type={type}
                                name='password'
                                placeholder='Enter Password'
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <InputGroup.Text className='b-1' style={{ border: '0.0625rem solid #d1d7e0' }}>
                                <FontAwesomeIcon
                                  icon={type === 'password' ? faEyeSlash : faEye}
                                  onClick={() => {
                                    type === 'password' ? setType('text') : setType('password')
                                  }}
                                />
                              </InputGroup.Text>
                            </InputGroup>
                          </Trigger>

                          <ErrorMessage
                            component='div'
                            name='password'
                            className='text-danger'
                          />
                        </Col>}
                    </Row>

                    <Card className='mt-3'>
                      <Card.Header>Permissions</Card.Header>
                      {ownerPermission && (
                        <Card.Body>
                          {Object.keys(
                            ownerPermission
                          ).map((key, index) => {
                            return (
                              ((key === 'CMS' || (key === 'Users') || (key === 'Bonus')
                              ) && (
                                <Row key={index}>
                                  <Col>
                                    <BForm.Label>{key}</BForm.Label>
                                  </Col>

                                  <Col className='d-flex '>
                                    {ownerPermission[
                                      key
                                    ].map((value, index) => {
                                      return ((key === 'Users' && (value === 'AB' || value === 'SR' || value === 'UP')) ||
                                        (key === 'CMS' && (value === 'U' || value === 'T')) ||
                                        (key === 'Bonus' && value === 'Issue')) && (
                                          <label key={index}>
                                            {ownerPermission[
                                              key
                                            ].includes('R')
                                              ? (
                                                <Field
                                                  className='d-none'
                                                  type='checkbox'
                                                  name={`permission[${key}]`}
                                                  value={value}
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.value === 'R' ||
                                      values?.permission?.[key]?.includes('R')
                                                    ) {
                                                      if (
                                                        e.target.value === 'R' &&
                                        !e.target.checked
                                                      ) {
                                                        setFieldValue(
                                                          'permission',
                                                          { ...values.permission, CMS: [] }
                                                        )
                                                      } else {
                                                        handleChange(e)
                                                      }
                                                    } else {
                                                      toast(
                                                        'Please Select Read Permission Before Selecting Other For This Module',
                                                        'error'
                                                      )
                                                    }
                                                  }}
                                                />
                                                )
                                              : (
                                                <Field
                                                  className='d-none'
                                                  type='checkbox'
                                                  name={`permission[${key}]`}
                                                  value={value}
                                                  onChange={handleChange}
                                                />
                                                )}

                                            <h3>
                                              <Badge
                                                className='p-2 mx-2'
                                                type='button'
                                                bg={
                                    values?.permission?.[key]?.includes(value)
                                      ? 'success'
                                      : 'primary'
                                  }
                                              >
                                                {permissionLabel(value)}
                                              </Badge>
                                            </h3>
                                          </label>
                                      )
                                    })}
                                  </Col>
                                </Row>)
                              ))
                          })}

                        </Card.Body>
                      )}
                    </Card>

                    <div className='mt-4 d-flex justify-content-between align-items-center'>
                      <Button
                        variant='outline-warning'
                        onClick={() => navigate(AdminsRoutes.TenantOwner)}
                      >
                        Cancel
                      </Button>

                      <Button
                        variant='outline-success'
                        onClick={handleSubmit}
                        className='ml-2'
                      >
                        Submit
                        {loading && (
                          <Spinner
                            as='span'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        )}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          </div>
          )}
    </>
  )
}

export default CreateTenantAdmin
