import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { formatDateYMD, getDateDaysAgo } from '../../../../utils/dateFormatter'
import { getGameReportStart } from '../../../../store/redux-slices/dashboard'
import { getLoginToken } from '../../../../utils/storageUtils'
import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { REPORT_TYPE } from '../../../../utils/constant'

const useTopPlayers = ({ selectedPortal, selectedClient }) => {
  const dispatch = useDispatch()
  const { gameReportLoading: loading, gameReport } = useSelector((state) => state.dashboard)
  const [selectedTab, setSelectedTab] = useState('game')
  const [dateOptions, setDateOptions] = useState('today')
  const [limit, setLimit] = useState(10)
  const isInitialRender = useDidMountEffect()
  const [page,setPage] = useState(1)
  const [state, setState] = useState([
    {
      startDate: getDateDaysAgo(0),
      endDate: new Date(),
      key: 'selection'
    }
  ])

  
  const fetchDetails = () => {
    const restPayload = {
      isTenant: false,
      startDate: formatDateYMD(state.map(a => a.startDate)),
      endDate: formatDateYMD(state.map(a => a.endDate)),
      tenantId: selectedPortal,
      adminId: selectedClient,
      dateOptions,
      selectedTab,
      pageNo:page
    }
    switch (selectedTab) {
      case REPORT_TYPE.GAME:
        dispatch(getGameReportStart({
          reportType :REPORT_TYPE.GAME,
          ...restPayload
         
        }))
        break;
      case REPORT_TYPE.PROVIDER:
        dispatch(getGameReportStart({
          reportType :REPORT_TYPE.PROVIDER,
          ...restPayload
         
        }))
        break;
      case REPORT_TYPE.USER:
        dispatch(getGameReportStart({
          reportType :REPORT_TYPE.USER,
          ...restPayload
         
        }))
        break;
    
     
    }
  }

  useEffect(() => {
    fetchDetails()
  }, [state, selectedClient, selectedPortal, selectedTab,page])

  useEffect(() => {
    if (dateOptions !== 'custom' && !isInitialRender) fetchDetails()
  }, [dateOptions])

  const getCsvDownloadUrl = () =>{
    
    switch (selectedTab) {
      case 'game':
      return`${process.env.REACT_APP_API_URL}/api/report/game-report?adminId=${selectedClient}&tenantId=${selectedPortal}&startDate=${formatDateYMD(state.map(a => a.startDate))}&endDate=${formatDateYMD(state.map(a => a.endDate))}&tab=${selectedTab}&csvDownload=true&token=${getLoginToken()}`
      case 'provider':
      return`${process.env.REACT_APP_API_URL}/api/report/provider-report?adminId=${selectedClient}&tenantId=${selectedPortal}&startDate=${formatDateYMD(state.map(a => a.startDate))}&endDate=${formatDateYMD(state.map(a => a.endDate))}&tab=${selectedTab}&csvDownload=true&token=${getLoginToken()}`
      case 'user':
      return`${process.env.REACT_APP_API_URL}/api/report/user-report?adminId=${selectedClient}&tenantId=${selectedPortal}&startDate=${formatDateYMD(state.map(a => a.startDate))}&endDate=${formatDateYMD(state.map(a => a.endDate))}&tab=${selectedTab}&csvDownload=true&token=${getLoginToken()}`

    }
  }

  return {

    fetchDetails,
    loading,
    setState,
    state,
    selectedTab,
    setSelectedTab,
    gameReport,
    limit,
    setLimit,
    dateOptions,
    setDateOptions,
    getCsvDownloadUrl,
    page,setPage
  }
}

export default useTopPlayers
