import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AFFILIATE_TYPE_LABEL_BY_VALUE, STATUS_LABEL } from '../../../../utils/constant'

const printData = (data) => (data ? 'Sim' : 'Não');
const printEnableDisable = (data) => (data ? 'Habilitado' : 'Desabilitado');
const showStyle = (data) => (data ? 'text-success' : 'text-danger');

const useAffiliateProfile = () => {
  const [selectedTab, setSelectedTab] = useState('overview')
  const [isEditable, setIsEditable] = useState('false')
  const affiliateDetails = useSelector((state) => state.affiliate.affiliateDetails)
  const affiliate = affiliateDetails?.affiliate;

  const basicInfo = [
    // { label: 'ID', value: affiliateDetails?.affiliate?.affiliateId },
    { label: 'Tipo de Afiliado', value: AFFILIATE_TYPE_LABEL_BY_VALUE[affiliate?.affiliateType] },
    { label: 'Email', value: affiliate?.email },
    { label: 'Nome', value: `${affiliate?.firstName} ${affiliate?.lastName}` },
    { label: 'Usuário', value: affiliate?.username },
    // { label: 'Email Verificado', value: '' },
    { label: 'Status', value: STATUS_LABEL[String(affiliate?.status).toUpperCase()], subValue: showStyle(affiliate?.status) },
    { label: 'Sistema de Sub Afiliados', value: printEnableDisable(affiliate?.enableSubAffiliatesSystem), subValue: showStyle(affiliate?.enableSubAffiliatesSystem) },
  ]

  const kycInfo = [
    { label: 'KYC Method', value: 'System KYC' },
    { label: 'KYC Status', value: '' }
  ];

  return {
    loading: false,
    selectedTab,
    setSelectedTab,
    basicInfo,
    kycInfo,
    affiliateDetails,
    isEditable,
    setIsEditable
  }
}

export default useAffiliateProfile