import React from "react";
import {
  Button,
  Row,
  Col,
  Table,
  Modal,
  Form,
  InputGroup,
  BForm,
} from "@themesberg/react-bootstrap";
import { motion } from "framer-motion/dist/framer-motion";
import { AdminsRoutes } from "../../../routes";
import { useLoyaltyRulesListing } from "./hooks/useLoyaltyRulesListing";
import Preloader from "../../../components/Preloader";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import ActionMenu from "./components/ActionMenu";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { ErrorMessage, Formik } from "formik";
import { setMinimumBetAmountForLoyaltySchema } from "./schema";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
const LoyaltyRulesListing = () => {
  const {
    navigate,
    limit,
    setLimit,
    page,
    setPage,
    loyaltyRules,
    loading,
    handlePopClick,
    isHidden,
    handleSetMinimumAmountSubmit,
    minimumBetAmountModal,
    setMinimumBetAmountModal,
    minimumBetAmount,
  } = useLoyaltyRulesListing();

  return (
    <>
      <>
        <div className="section-header-wrap mt-n3 mx-n3">
          <Row>
            <Col lg={8} xs={7}>
              <h2 className="title-text">Regras de fidelidade</h2>
            </Col>

            <Col lg={4} xs={5}>
              {/* Button to create new admin */}
              <div className="d-flex flex-row justify-content-end">
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Button
                    variant="btn btn-warning w-100"
                    onClick={() => setMinimumBetAmountModal(true)}
                    hidden={isHidden({
                      module: { key: "LoyaltyManagement", value: "U" },
                    })}
                    style={{ maxWidth: "250px", marginRight: "10px" }}
                  >
                    <CurrencyExchangeIcon className="me-2" />
                    <span>Definir valor mínimo de aposta</span>
                  </Button>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Button
                    variant="btn btn-primary w-100 "
                    onClick={() => navigate(AdminsRoutes.CreateLoyaltyRule)}
                    hidden={isHidden({
                      module: { key: "LoyaltyManagement", value: "C" },
                    })}
                    style={{ maxWidth: "140px" }}
                  >
                    <AddCircleOutlineRoundedIcon className="me-2" />
                    <span>Criar</span>
                  </Button>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Button
                    variant="btn btn-secondary w-100"
                    onClick={() => handlePopClick()}
                    hidden={isHidden({
                      module: { key: "LoyaltyManagement", value: "U" },
                    })}
                    style={{ maxWidth: "140px", marginLeft: "5px" }}
                  >
                    <RemoveCircleOutlineOutlinedIcon className="me-2" />
                    <span>Pop</span>
                  </Button>
                </motion.div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="TableBg mt-4 pt-1">
          <Table
            striped
            responsive
            hover
            size="sm"
            className="text-center mt-3"
          >
            <thead className="thead-light">
              <tr>
                <th>Id</th>
                <th>Level</th>
                <th>Pontos de fidelidade</th>
                <th>Moeda de Fidelidade</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {loading ? (
                <Preloader />
              ) : (
                Boolean(loyaltyRules) &&
                loyaltyRules?.rows?.map(
                  ({ id, level, loyaltyPoint, loyaltyCoins }) => {
                    return (
                      <tr key={id}>
                        <td>{id}</td>
                        <td>{level}</td>
                        <td>{loyaltyPoint || "Max"}</td>
                        <td>{loyaltyCoins} </td>
                        <td>
                          <ActionMenu
                            navigate={navigate}
                            currencyId={id}
                            hide={false}
                            id={id}
                            loyaltyRule={{
                              id,
                              level,
                              loyaltyPoint,
                              loyaltyCoins,
                            }}
                          />
                        </td>
                      </tr>
                    );
                  }
                )
              )}

              {loyaltyRules?.count === 0 && !loading && (
                <tr>
                  <td colSpan={8} className="text-danger text-center">
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </>

      {/* Set Minimum Bet Amount Modal / Dialog  */}
      <Modal size="lg" show={minimumBetAmountModal}>
        <Modal.Header className="justify-content-center">
          <h4>Set Minimum Bet Amount</h4>
        </Modal.Header>
        <Formik
          initialValues={{
            minimumBetAmount: minimumBetAmount?.BRL?.minimumBetAmount || 0,
          }}
          validationSchema={setMinimumBetAmountForLoyaltySchema}
          onSubmit={handleSetMinimumAmountSubmit}
        >
          {({ values, handleChange, handleSubmit, setFieldValue }) => (
            <Form className="align-items-center mt-3">
              <Modal.Body>
                <Col>
                  <Form.Label>
                    Minimum Bet Amount <span className="text-danger"> *</span>
                  </Form.Label>
                  <div className="row">
                    <div className="col-3">
                      <InputGroup>
                        <Form.Control
                          name={"BRL"}
                          value={"BRL"}
                          // onInput={handleChange}
                          // onBlur={handleBlur}
                          // onChange={handleChange}
                          disabled={true}
                        />
                      </InputGroup>
                    </div>
                    <div className="col-6">
                      <InputGroup>
                        <Form.Control
                          name="minimumBetAmount"
                          type={"number"}
                          placeholder="Enter Minimum Bet Amount"
                          value={values?.minimumBetAmount}
                          onChange={handleChange}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <ErrorMessage
                    component="div"
                    name="minimumBetAmount"
                    className="text-danger"
                  />
                </Col>
              </Modal.Body>
              <Modal.Footer className={`d-flex justify-content-center`}>
                <Button
                  className="m-2"
                  variant="outline-warning"
                  onClick={() => {
                    setMinimumBetAmountModal(false);
                  }}
                >
                  Cancel
                </Button>
                {
                  <Button
                    className="m-2"
                    variant="outline-success"
                    onClick={handleSubmit}
                  >
                    Add
                  </Button>
                }
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default LoyaltyRulesListing;
