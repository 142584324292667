import React from 'react'
import { Pagination, Row, Col, Form } from '@themesberg/react-bootstrap'
import classNames from 'classnames'

const PaginationComponent = ({ page, totalPages, setPage, limit, setLimit, className = "mt-3" }) => {
  const OPTIONS_ARR = [15, 20, 50, 100]
  const items = []
  if (totalPages > 7) {
    const showFirstPages = page < 7
    const showLastPages = page > totalPages - 2

    if (!showFirstPages) {
      items.push(
        <Pagination.Item key={1} active={page === 1} onClick={() => setPage(1)}>
          {1}
        </Pagination.Item>
      )

      items.push(<Pagination.Ellipsis key='ellipsis1' />)
    } else {
      for (let i = 1; i <= 7; i++) {
        items.push(
          <Pagination.Item key={i} active={i === page} onClick={() => setPage(i)}>
            {i}
          </Pagination.Item>
        )
      }
    }

    if (showLastPages) {
      for (let i = totalPages - 6; i <= totalPages; i++) {
        items.push(
          <Pagination.Item key={i} active={i === page} onClick={() => setPage(i)}>
            {i}
          </Pagination.Item>
        )
      }
    }

    if (!showFirstPages && !showLastPages) {

      for (let i = 1; i <= 3; i++) {
        const pageNo = (page - 1) - (3 - i);
        items.push(
          <Pagination.Item key={pageNo} active={page === pageNo} onClick={() => setPage(pageNo)}>
            {pageNo}
          </Pagination.Item>
        )
      }

      /* CURRENT PAGE / ACTIVE PAGE */
      items.push(
        <Pagination.Item key={page} active onClick={() => setPage(page)}>
          {page}
        </Pagination.Item>
      )

      for (let i = 1; i <= 3; i++) {
        items.push(
          <Pagination.Item key={page + i} active={page === page + i} onClick={() => setPage(page + i)}>
            {page + i}
          </Pagination.Item>
        )
      }
    }

    if (!showLastPages) {
      items.push(<Pagination.Ellipsis key='ellipsis2' />)

      items.push(
        <Pagination.Item key={totalPages} active={page === totalPages} onClick={() => setPage(totalPages)}>
          {totalPages}
        </Pagination.Item>
      )
    }
  } else {
    for (let i = 1; i <= totalPages; i++) {
      items.push(
        <Pagination.Item key={i} active={i === page} onClick={() => setPage(i)}>
          {i}
        </Pagination.Item>
      )
    }
  }

  return (
    <Row className={classNames('pagination', className)}>
      <Col xs={12} className='d-flex align-items-center justify-content-end'>
        {/* <Form.Label style={{ marginBottom: '0', marginRight: '15px' }}>
            Rows Per Page :
          </Form.Label>

          <Form.Select
            style={{ maxWidth: '70px', height: '40px', padding: 'o, 0.5rem' }}
            value={limit}
            onChange={(event) => {
              setLimit(event.target.value)
              setPage(1)
            }}
          >
            {OPTIONS_ARR.map((o) => (
              <option key={o} value={o}>
                {o}
              </option>
            ))}
          </Form.Select> */}

        <Pagination>
          <Pagination.Prev
            disabled={page === 1}
            onClick={() => setPage(page - 1)}
          />
          {items}
          <Pagination.Next
            disabled={page >= totalPages}
            onClick={() => setPage(page + 1)}
          />
        </Pagination>
      </Col>
    </Row>

  )
}

export default PaginationComponent
