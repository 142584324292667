import { combineReducers } from '@reduxjs/toolkit'

import loginReducer from './login'
import tenantsReducer from './tenants'
import adminReducer from './admins'
import themeReducer from './theme'
import currencyReducer from './currencies'
import fetchReducer from './fetchData'
import tenantsFetchDataReducer from './tenantsFetchData'
import credentialsReducer from './tenantcredentials'
import tenantUsersReducer from './tenantUsers'
import adminUserReducer from './adminUser'
import tenantAffiliatesReducer from './tenantAffiliates'
import tenantCasinoReducer from './tenantCasino'
import superAdminCasinoManagementReducer from './superAdminCasinoManagement'
import superAdminTransactionReducer from './superAdminTransactions'
import tenantTransactionReducer from './tenantTransactions'
import bonusReducer from './bonus'
import superAdminSettingsReducer from './superAdminSettings'
import dashboardReducer from './dashboard'
import wageringTemplateReducer from './wageringTemplate'
import emailTemplateReducer from './emailTemplate'
import languageReducer from './languages'
import paymentReducer from './payment'
import betSettingsReducer from './betSettingsSlice'
import sportsBookReducer from './sportsBook'
import affiliateReducer from './affiliates'
import socialLinkReducer from './socialLinks'
import loyaltyRulesReducer from './loyaltyRules'
import referralManagementReducer from './referralManagement'
const appReducer = combineReducers({
  login: loginReducer,
  tenants: tenantsReducer,
  admins: adminReducer,
  theme: themeReducer,
  currencies: currencyReducer,
  tenantsFetch: tenantsFetchDataReducer,
  fetch: fetchReducer,
  tenantcredentials: credentialsReducer,
  tenantUsers: tenantUsersReducer,
  adminUser: adminUserReducer,
  tenantAffiliate: tenantAffiliatesReducer,
  tenantCasino: tenantCasinoReducer,
  superAdminCasino: superAdminCasinoManagementReducer,
  superAdminTransactions: superAdminTransactionReducer,
  tenantTransactions: tenantTransactionReducer,
  bonus: bonusReducer,
  superAdminSettings: superAdminSettingsReducer,
  dashboard: dashboardReducer,
  wageringTemplate: wageringTemplateReducer,
  emailTemplate: emailTemplateReducer,
  languages: languageReducer,
  payment: paymentReducer,
  betSettings: betSettingsReducer,
  sportsBook: sportsBookReducer,
  affiliate: affiliateReducer,
  socialLinks:socialLinkReducer,
  loyaltyRules: loyaltyRulesReducer,
  referralManagement:referralManagementReducer
})

export const resetState = () => ({
  type: 'resetState'
})

const rootReducer = (state, action) => {
  if (action.type === 'resetState') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export default rootReducer
