import {
  Row,
  Col,
  Button,
  Form as BForm,
  InputGroup
} from '@themesberg/react-bootstrap'
import React from 'react'
import useCheckPermission from '../../../utils/checkPermission'
import { ActionInputContainer } from './style'
import { ErrorMessage, Form, Formik } from 'formik'
import { defaultCurrency } from '../../../utils/constant'
import { referralManagementScheme } from './schema'
import useReferralManagement from './hooks/useReferralManagement'
import { InlineLoader, SpinnerLoader } from '../../../components/Preloader'
const ReferralManagement = () => {
  const {
    loading,
    navigate,
    referralManagement,
    handelSetClick
  } = useReferralManagement()
  const { isHidden } = useCheckPermission()
  return (
    <>
      <div className='section-header-wrap mt-n3 mx-n3'>
        <Row>
          <Col lg={4} xs={12}>
            <h2 className='title-text'>Gerenciamento de referências </h2>
          </Col>
        </Row>
      </div>
      <ActionInputContainer>
        {/* <h5 className='limit-heading'>Set {limit?.label} {user ? `for '${userData?.firstName} ${userData?.lastName}'` : userData ? `for '${userData?.firstName} ${userData?.lastName}'` : ''}</h5> */}
        
        <h5 className='limit-heading'>
Definir valores de referência</h5>
        
         <div className="limit-form-wrap">
          <Formik
            initialValues={{
              minimumDeposite: referralManagement?.currency?.BRL?.minDeposit || 0,
              referralAmount: referralManagement?.currency?.BRL?.referralAmount || 0,
              daysToClear:referralManagement?.daysToClear || 0,
              wageringMultiplier: referralManagement?.wageringMultiplier || 0,
              isActive:referralManagement?.isActive || false
            }}
            enableReinitialize={true}
            validationSchema={referralManagementScheme}
            onSubmit={(formValues) => {
              handelSetClick(formValues)
            }}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              handleBlur
            }) => (
              <Form className="limit-form">
                <Row>
                  <Col lg={6}>
                    <div className="limitinputwrap">
                      <BForm.Label>Depósito Mínimo</BForm.Label>
                      <InputGroup>
                        {<InputGroup.Text>{defaultCurrency.code}</InputGroup.Text>}
                        <BForm.Control
                          type='number'
                          name='minimumDeposite'
                          placeholder={'Enter Minimum Deposite Value'}
                          value={values.minimumDeposite}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={isHidden({module:{key:'Bonus',value:'C'}}) && isHidden({module:{key:'Bonus',value:'U'}})}
                        />
                      </InputGroup>
                      <ErrorMessage
                        component='div'
                        name='minimumDeposite'
                        className='text-danger'
                      />

                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="limitinputwrap">
                      <BForm.Label>Valor de referência</BForm.Label>
                      <InputGroup>
                        {<InputGroup.Text>{defaultCurrency.code}</InputGroup.Text>}
                        <BForm.Control
                          type='number'
                          name='referralAmount'
                          placeholder={'Enter Referral Amount'}
                          value={values.referralAmount}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={isHidden({module:{key:'Bonus',value:'C'}}) && isHidden({module:{key:'Bonus',value:'U'}})}
                        />
                      </InputGroup>
                      <ErrorMessage
                        component='div'
                        name='referralAmount'
                        className='text-danger'
                      />

                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <div className="limitinputwrap">
                      <BForm.Label>
Multiplicador de apostas</BForm.Label>
                      <InputGroup>
                        
                        <BForm.Control
                          type='number'
                          name='wageringMultiplier'
                          placeholder={'Enter Wagering Multiplier '}
                          value={values.wageringMultiplier}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={isHidden({module:{key:'Bonus',value:'C'}}) && isHidden({module:{key:'Bonus',value:'U'}})}
                        />
                      </InputGroup>
                      <ErrorMessage
                        component='div'
                        name='wageringMultiplier'
                        className='text-danger'
                      />

                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="limitinputwrap">
                      <BForm.Label>Dias para limpar</BForm.Label>
                      <InputGroup>
                        <BForm.Control
                          type='number'
                          name='daysToClear'
                          placeholder={'Enter Expiry In Days'}
                          value={values.daysToClear}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={isHidden({module:{key:'Bonus',value:'C'}}) && isHidden({module:{key:'Bonus',value:'U'}})}
                        />
                      </InputGroup>
                      <ErrorMessage
                        component='div'
                        name='daysToClear'
                        className='text-danger'
                      />

                    </div>
                  </Col>
                </Row>
                <Row>
                <Col xs={6} className='p-2'>
                <BForm.Label>Ativos</BForm.Label>
                      <BForm.Check
                        name={'isActive'}
                        type='switch'
                        checked={values.isActive}
                        value={values.isActive}
                        onChange={(e) => handleChange(e, '')}
                      />
                 
                  </Col>
                </Row>

                <div className='limit-action-btnwrap'>
                  <Button
                    variant='outline-success'
                    className='limit-action-btn'
                    onClick={() => {
                      handleSubmit()
                    }}
                    disable={loading}
                    hidden={isHidden({module:{key:'Bonus',value:'C'}}) && isHidden({module:{key:'Bonus',value:'U'}}) }
                  >
                   { loading ? <InlineLoader></InlineLoader> : 'Definir' }
                  </Button>
                </div>

              </Form>
            )}
          </Formik>
        </div>

      </ActionInputContainer>

    </>

  )
}

export default ReferralManagement
