import React from 'react'
import { Form, FormLabel } from '@themesberg/react-bootstrap'
import useAllProviderList from './useAllProviderList'

const ProvidersFilter = ({ setSelectedProvider, selectedProvider, isTenantRoute = false }) => {
  const { allProviders } = useAllProviderList(isTenantRoute)

  return (
    <>
    {/* <FormLabel>Provider Name</FormLabel> */}

      <Form.Select
        onChange={(e) => {
          setSelectedProvider(e.target.value)
        }}
        value={selectedProvider}
        style={{  marginRight: isTenantRoute && '15px' }}
      >
        <option value=''>
        Provider
        </option>

        {allProviders?.count === 0 && (
          <option value='' disabled>
            No Providers Available
          </option>
        )}

        {allProviders?.rows?.map(
          ({ casinoProviderId, name }) => (
            <option key={casinoProviderId} value={casinoProviderId}>
              {name}
            </option>
          )
        )}
      </Form.Select>
    </>
  )
}

export default ProvidersFilter
