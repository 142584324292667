
export const tableHeaders = [
  { label: 'Id', value: 'Id' },
  { label: 'Sports Name', value: 'sportsId' },
  { label: 'Max bet amount', value: 'Max_bet_amount' },
  { label: 'Min bet amount', value: 'Minbetamount' },
  { label: 'Max bet count', value: 'Maxbetcount' },
  { label: 'Max win amount', value: 'Maxwinamount' },
  { label: 'Cashout percentage', value: 'Cashoutpercentage' },
  { label: 'Min odd limit', value: 'Minoddlimit' },
  { label: 'Max odd limit', value: 'Maxoddlimit' },
  { label: 'Max event Count', value: 'MaxeventCount' },
  { label: 'Max Market Outcome Count', value: 'MaxMarketOutcomeCount' },
  { label: 'Updated At', value: 'updatedAt' },
  { label: 'action', value: 'action' }
]
