import React from 'react'
import {
  Button,
  Row,
  Col,
  Table,
  ButtonGroup,
  OverlayTrigger,
  Tooltip
} from '@themesberg/react-bootstrap'
import { motion } from 'framer-motion/dist/framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { AdminsRoutes } from '../../../routes'
import PaginationComponent from '../../../components/Pagination'
import useSocialLinksListing from './hooks/useSocialLinksListing'
import useCheckPermissions from '../../../utils/checkPermission'
import Preloader from '../../../components/Preloader'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import ActionMenu from './ActionMenu'

export default () => {
  const {
    navigate,
    limit,
    setLimit,
    page,
    setPage,
    socialLinks,
    loading
  } = useSocialLinksListing()

  const { isHidden } = useCheckPermissions()
  const hide = isHidden({ module: { key: 'Currencies', value: 'U' } })

  return (
    <>
      <>
        <div className='section-header-wrap mt-n3 mx-n3'>
          <Row>
            <Col lg={8} xs={7}>
              <h2 className='title-text'>Social Links</h2>
            </Col>

            <Col lg={4} xs={5}>
              {/* Button to create new admin */}
              <div className='d-flex justify-content-end'>
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Button
                    variant='btn btn-primary w-100'
                    onClick={() => navigate(AdminsRoutes.createSocialLink)}
                    hidden={isHidden({ module: { key: 'SocialLinks', value: 'C' } })}
                    style={{ maxWidth: '140px' }}
                  >
                    <AddCircleOutlineRoundedIcon className='me-2' />
                    <span>Criar</span>
                  </Button>
                </motion.div>
              </div>
            </Col>

          </Row>
        </div>
        <div className='TableBg mt-4 pt-1'>

          <Table striped responsive hover size='sm' className='text-center mt-3'>
            <thead className='thead-light'>
              <tr>
                <th>Name</th>
                <th>Label</th>
                <th>Link</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {loading
                ? <Preloader />
                : Boolean(socialLinks) &&
                socialLinks?.map(
                  ({
                    iconId,
                    name,
                    label,
                    link,

                  }) => {
                    return (
                      <tr key={iconId}>
                        <td>{name || 'N/A'}</td>
                        <td>{label || 'N/A'} </td>
                        <td>{link || 'N/A'}</td>
                        <td>{<ActionMenu navigate={navigate} socialLink={{
                          iconId,
                          name,
                          label,
                          link,
                        }} />}</td>
                      </tr>
                    )
                  }
                )}

              {socialLinks?.length === 0 && !loading &&
                (
                  <tr>
                    <td
                      colSpan={8}
                      className='text-danger text-center'
                    >
                     Sem argumentos
                    </td>
                  </tr>
                )}
            </tbody>
          </Table>
          {/* {allCurrencies?.count !== 0 && !loading &&
              (
                <PaginationComponent
                  page={allCurrencies?.count < page ? setPage(1) : page}
                  totalPages={totalPages}
                  setPage={setPage}
                  limit={limit}
                  setLimit={setLimit}
                />
              )} */}
        </div>
      </>
    </>
  )
}
