import React from 'react';
import { Table } from '@themesberg/react-bootstrap';
import { Button, Card, Col, Row } from 'react-bootstrap';

import FormatNumber from '../../../../utils/formatNumber';
import FormatDate from '../../../../utils/formatDate';
import { useNavigate } from "react-router-dom";

const AffiliateWalletTable = ({ affiliate }) => {
  const navigate = useNavigate();
  
  if(!affiliate) return;

  return (
    <>
      {affiliate?.affiliateWallet ? <Table  striped responsive hover size='sm' className='text-left WalletTable'>
        <tbody>
          <tr>
            <td>Moeda</td>
            <td>{affiliate?.affiliateWallet?.currencyCode}</td>
          </tr>
          <tr>
            <td>Saldo</td>
            <td>
              {FormatNumber.currency(affiliate?.affiliateWallet?.amount||0, affiliate?.affiliateWallet?.currencyCode)}
            </td>
          </tr>
          <tr>
            <td>Criado em:</td>
            <td>
              {FormatDate.toDateOnly(affiliate?.affiliateWallet?.createdAt)}
            </td>
          </tr>
        </tbody>
      </Table> :
      <div className='d-flex p-3 py-4 justify-content-center align-items-center flex-column'>
        (Novo) Atualize os dados do afiliado para criação da carteira!
        <Button 
          className='mt-3'
          onClick={() =>
            navigate(`/admin/update-affiliate/${affiliate.affiliateId}?redirect_uri=/admin/affiliate-details/${affiliate.affiliateId}?tab=wallet`)
          }
        >Atualizar</Button>
      </div>
      }
    </>
  )
};

const AffiliateWallet = ({ affiliate }) => {
  return (
    <Row className='mt-4'>
      <Col xs={12}>
        <Card className='card-overview'>
          {/* <h4 className='h4-overview text-center mt-1'>Carteira do afiliado<hr className='h4-hr mt-1' /></h4> */}
          <div className='div-overview m-2'>
            <AffiliateWalletTable {...{ affiliate }} />
          </div>
        </Card>
      </Col>
    </Row>
  )
};

export default AffiliateWallet;
