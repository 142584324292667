import * as Yup from 'yup'

export const depositSchema = () =>
  Yup.object().shape({
    addAmount: Yup.number()
      .typeError('Apenas números são permitidos')
      .min(0, 'O valor precisa ser maior que 0')
      .max(10000,'O valor precisa ser menor que 1000')
      .required('O campo valor é requerido'),
    transactionType: Yup.string()
      .required('O tipo de transação é requerido'),
    walletType: Yup.string()
      .required('O tipo de carteira é requerida')
  })
