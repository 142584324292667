import { takeLatest, put } from 'redux-saga/effects'
import {
  // getTenantDetails,
  superAdminLogin,
  // tenantLogin,
  getAdminRole,
  // getTenantRole,
  // getTenantAdminRole,
  // getTenantWallet,
  getSuperAdminWallet,
  //   getTenantList,
  updateProfile,
  // updateAdminProfile,
  setEmailCreds,
  sendMail,
  resetPassword,
  resetProfilePassword,
  updateSiteConfiguration,
  affiliateLogin,
  generateAdminOtp,
} from '../../utils/apiCalls'
import {
  superAdminLoginStart,
  superAdminLoginSuccess,
  superAdminLoginFailure,
  //   tenantLoginStart,
  //   tenantLoginSuccess,
  //   tenantLoginFailure,
  //   fetchTenantDetailsStart,
  //   fetchTenantDetailsSuccess,
  //   fetchTenantDetailsFailure,
  getAdminRoleStart,
  getAdminRoleSuccess,
  getAdminRoleFailure,
  //   getTenantRoleStart,
  //   getTenantRoleSuccess,
  //   getTenantRoleFailure,
  //   getTenantAdminRoleStart,
  //   getTenantAdminRoleSuccess,
  //   getTenantAdminRoleFailure,
  //   getTenantWalletStart,
  //   getTenantWalletSuccess,
  //   getTenantWalletFailure,
  getSuperAdminWalletStart,
  getSuperAdminWalletSuccess,
  getSuperAdminWalletFailure,
  updateProfileStart,
  updateSiteConfigurationStart,
  updateSiteConfigurationComplete,
  updateProfileComplete,
  setEmailCredsStart,
  setEmailCredsComplete,
  sendMailStart,
  sendMailComplete,
  resetPasswordStart,
  resetPasswordComplete,
  resetProfilePasswordStart,
  resetProfilePasswordComplete,
  affiliateLoginStart,
  superAdminGeneratePasswordSuccess,
  superAdminGenerateOtpSuccess,
  superAdminGenerateOtpFailure,
  superAdminGenerateOtpStart,
} from '../redux-slices/login'
import {
  // CommonRoutes,
  AdminsRoutes, AffiliateRoutes
} from '../../routes'
import { toast } from '../../components/Toast'
import {
  removeLoginToken,
  setItem,
  setLoginToken
} from '../../utils/storageUtils'
// import { getAllTenantsListSuccess } from '../redux-slices/tenants'
import { objectToFormData } from '../../utils/objectToFormdata'
import { resolveErrors } from '../../utils/errorTypes'

export default function* loginWatcher() {
  yield takeLatest(superAdminLoginStart.type, superAdminLoginWorker)
  // yield takeLatest(tenantLoginStart.type, tenantLoginWorker)
  // yield takeLatest(fetchTenantDetailsStart.type, fetchTenantDetailsWorker)
  yield takeLatest(getAdminRoleStart.type, getAdminRoleWorker)
  // yield takeLatest(getTenantRoleStart.type, getTenantRoleWorker)
  // yield takeLatest(getTenantAdminRoleStart.type, getTenantAdminRoleWorker)
  // yield takeLatest(getTenantWalletStart.type, getTenantWalletWorker)
  yield takeLatest(getSuperAdminWalletStart.type, getSuperAdminWalletWorker)
  yield takeLatest(updateProfileStart.type, updateProfileWorker)
  yield takeLatest(updateSiteConfigurationStart.type, updateSiteConfigurationWorker)
  yield takeLatest(setEmailCredsStart.type, setEmailCredsWorker)
  yield takeLatest(sendMailStart.type, sendMailWorker)
  yield takeLatest(resetPasswordStart.type, resetPasswordWorker)
  yield takeLatest(resetProfilePasswordStart.type, resetProfilePasswordWorker)
  yield takeLatest(affiliateLoginStart.type, affiliateLoginWorker)
  yield takeLatest(superAdminGenerateOtpStart.type, generateAdminOtpWorker)
}

function* superAdminLoginWorker(action) {
  try {
    const { user, password, otp, navigate } = action && action.payload

    const encryptedPass = Buffer.from(password).toString('base64')

    const { data } = yield superAdminLogin({ user, password: encryptedPass, otp, userType: 'ADMIN' })
    const { accessToken } = data?.data

    setLoginToken(accessToken)
    setItem('role', 'Admin')

    yield put(superAdminLoginSuccess('Admin'))

    yield toast('Login Successfull', 'success')
    navigate(AdminsRoutes.Dashboard)
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put(superAdminLoginFailure(e?.response?.data?.errors[0].description))

      yield toast(resolveErrors(e?.response?.data?.errors), "error");
    } else {
      yield put(superAdminLoginFailure(e.message))

      yield toast(e.message, 'error')
    }
  }
}

// function * tenantLoginWorker (action) {
//   try {
//     const { user, password, navigate } = action && action.payload

//     const encryptedPass = Buffer.from(password).toString('base64')

//     const { data } = yield tenantLogin({ user, password: encryptedPass })

//     const { accessToken } = data?.data

//     setLoginToken(accessToken)
//     setItem('role', 'Admin')

//     yield put(tenantLoginSuccess('Admin'))
//     const tenantData = yield getTenantList()
//     const tenantsFilterDetails = tenantData?.data?.data?.tenantDetails

//     if (tenantsFilterDetails && tenantsFilterDetails.rows && tenantsFilterDetails.rows.length > 0) {
//       const { rows } = { ...tenantsFilterDetails }
//       const defaultTenantId = rows[0].tenantId
//       setItem('tenant-id', defaultTenantId)
//     }
//     yield put(getAllTenantsListSuccess(tenantsFilterDetails))

//     yield toast('Login Successfull', 'success')
//     // navigate(TenantRoutes.Dashboard)
//   } catch (e) {
//     if (e?.response?.status === 406) {
//       window.location.href = `${CommonRoutes.TenantNotFound}`
//       yield put(tenantLoginFailure(e?.response?.data?.errors[0].description))
//     } else if (e?.response?.status === 401) {
//       yield put(tenantLoginFailure(e?.response?.data?.errors[0].description))

//       yield toast(resolveErrors(e?.response?.data?.errors), "error");
//     } else {
//       yield put(tenantLoginFailure(e.message))

//       yield toast(e.message, 'error')
//     }
//   }
// }

// function * fetchTenantDetailsWorker () {
//   try {
//     const { data } = yield getTenantDetails()

//     yield put(fetchTenantDetailsSuccess(data?.data?.tenantDetails))
//   } catch (e) {
//     yield toast(e?.response?.data?.errors[0]?.description, 'error')

//     yield put(fetchTenantDetailsFailure(e?.response?.data?.errors[0]?.description))

//     removeLoginToken()
//   }
// }

function* getAdminRoleWorker() {
  try {
    const { data } = yield getAdminRole()

    yield put(getAdminRoleSuccess(data?.data?.roles))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAdminRoleFailure(e?.response?.data?.errors[0]?.description))
  }
}

// function * getTenantRoleWorker () {
//   try {
//     const { data } = yield getTenantRole()

//     yield put(getTenantRoleSuccess(data?.data?.roles))
//   } catch (e) {
//     yield toast(e?.response?.data?.errors[0]?.description, 'error')

//     yield put(getTenantRoleFailure(e?.response?.data?.errors[0]?.description))
//   }
// }

// function * getTenantAdminRoleWorker () {
//   try {
//     const { data } = yield getTenantAdminRole()

//     yield put(getTenantAdminRoleSuccess(data?.data?.roles))
//   } catch (e) {
//     yield toast(e?.response?.data?.errors[0]?.description, 'error')

//     yield put(getTenantAdminRoleFailure(e?.response?.data?.errors[0]?.description))
//   }
// }

// function * getTenantWalletWorker () {
//   try {
//     // const { data } = yield getTenantWallet()

//     yield put(getTenantWalletSuccess(data?.data?.wallet))
//   } catch (e) {
//     yield toast(e?.response?.data?.errors[0]?.description, 'error')

//     yield put(getTenantWalletFailure(e?.response?.data?.errors[0]?.description))
//   }
// }

function* getSuperAdminWalletWorker() {
  try {
    const { data } = yield getSuperAdminWallet()

    yield put(getSuperAdminWalletSuccess(data?.data?.wallet))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getSuperAdminWalletFailure(e?.response?.data?.errors[0]?.description))
  }
}

function* updateProfileWorker(action) {
  try {
    const { data, isTenant } = action && action.payload
    yield updateProfile(data)

    yield put(updateProfileComplete())
    yield toast('Profile Updated SuccessFully', 'success')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateProfileComplete())
  }
}

function* updateSiteConfigurationWorker(action) {
  try {
    const { data, isTenant } = action && action.payload
    yield updateSiteConfiguration(objectToFormData(data))

    yield put(updateSiteConfigurationComplete())
    yield toast('Site Configuration Updated SuccessFully', 'success')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateSiteConfigurationComplete())
  }
}


function* setEmailCredsWorker(action) {
  try {
    const { data } = action && action.payload
    yield setEmailCreds(data)

    yield put(setEmailCredsComplete())
    yield toast('Credentials Updated SuccessFully', 'success')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(setEmailCredsComplete())
  }
}

function* sendMailWorker(action) {
  try {
    const { email, isSuperAdmin, setEmailStatus } = action && action.payload
    yield sendMail({ email, isSuperAdmin })

    yield setEmailStatus(prev => ({ ...prev, takeUserMail: false, emailSent: true }))

    yield put(sendMailComplete())

    yield toast('Email sent successfully and is valid for 3 hours', 'success')
  } catch (e) {
    yield toast(resolveErrors(e?.response?.data?.errors), "error");
    yield put(sendMailComplete())
  }
}

function* resetPasswordWorker(action) {
  try {
    const { isSuperAdmin, password, token, navigate } = action && action.payload
    const { data } = yield resetPassword({ isSuperAdmin, data: { newPasswordKey: token } })
    yield resetPassword({ isSuperAdmin, data: { ...data?.data, newPasswordKey: token, password } })

    yield put(resetPasswordComplete())

    yield toast('Password reset successfully', 'success')

    yield removeLoginToken()
    navigate(AdminsRoutes?.SuperAdminSignin)
    // if (isSuperAdmin) {
    //   navigate(AdminsRoutes?.SuperAdminSignin)
    // } else {
    //   navigate(TenantRoutes?.TenantSignin)
    // }
  } catch (e) {
    yield toast(resolveErrors(e?.response?.data?.errors), "error");
    yield put(resetPasswordComplete())
  }
}

function* resetProfilePasswordWorker(action) {
  try {
    const { data, navigate } = action && action.payload
    yield resetProfilePassword({ data })

    yield put(resetProfilePasswordComplete())

    yield toast('Password Reset Successfully', 'success')

    yield removeLoginToken()
    navigate(AdminsRoutes?.SuperAdminSignin)
    // if (!isTenant) {
    //   navigate(AdminsRoutes?.SuperAdminSignin)
    // } else {
    //   navigate(TenantRoutes?.TenantSignin)
    // }
  } catch (e) {
    yield toast(e?.response?.data?.errors?.[0]?.description, 'error')
    yield put(resetProfilePasswordComplete())
  }
}

function* affiliateLoginWorker(action) {
  try {
    const { user, password, navigate } = action && action.payload
    const encryptedPass = Buffer.from(password).toString('base64')
    const { data } = yield superAdminLogin({ user, password: encryptedPass, userType: 'AFFILIATE' })

    const { accessToken } = data?.data

    setLoginToken(accessToken)
    setItem('role', 'Affiliate')

    yield put(superAdminLoginSuccess('Affiliate'))

    yield toast('Login Successful', 'success')
    navigate(AffiliateRoutes.Dashboard)

  } catch (e) {
    if (e?.response?.status === 401) {
      yield put(superAdminLoginFailure(e?.response?.data?.errors[0].description))

      yield toast(resolveErrors(e?.response?.data?.errors), "error");
    } else {
      yield put(superAdminLoginFailure(e.message))

      yield toast(e.message, 'error')
    }
  }
}
function* generateAdminOtpWorker(action) {
  const { user, password, setFieldValue } = action && action.payload
  try {
    const encryptedPass = Buffer.from(password).toString('base64')
    const { data } = yield generateAdminOtp({ user, password: encryptedPass, userType: 'ADMIN' })
    setFieldValue('shouldGoForOtpVerification', false)
    yield put(superAdminGenerateOtpSuccess())
    yield toast('OTP Sent Successfull.', 'success')

  } catch (e) {
    setFieldValue('shouldGoForOtpVerification', true)
    yield toast(resolveErrors(e?.response?.data?.errors), "error");
    yield put(superAdminGenerateOtpFailure(e?.response?.data?.errors[0].description))
  }
}
