import React, { useEffect, useState } from 'react';

import { getAffiliateLivesReport } from '../../../../utils/apiCalls';
import { DashboardHeadTitle, DashboardLiveContainer } from '../style';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';

import { Button, Tab, Table, Tabs } from "@themesberg/react-bootstrap";
import FormatDate from '../../../../utils/formatDate';
import { Loading } from '../../../../components/Preloader';
import useDebounce from '../../../../hooks/useDebounce';
import UpdateButtonIcon from '../../../../components/UpdateButtonIcon';
import AffiliateStreamLogsTable from './AffiliateStreamLogs';


export const affiliateTableHeaders = [
  { label: 'Id da Live', value: 'liveId' },
  { label: 'Duração', value: 'timeReadable' },
  /*  { label: 'Visualizações', value: 'viewers' }, */
  { label: 'Visualizações Únicas', value: 'unique_total_viewers' },
  { label: 'Iniciado em:', value: 'startedAt' },
  { label: 'Finalizado em:', value: 'finishedAt' },
]

const AffiliateLives = ({ affiliate }) => {
  const [loading, setLoading] = useState(true);
  const [lives, setLives] = useState([]);
  const [activeKey, onSelect] = useState("lives");

  const getAffiliateLives = useDebounce(async () => {
    setLoading(true);
    try {
      const res = await getAffiliateLivesReport({ streamerId: affiliate?.streamerId });
      setLives(res.data.data.lives)
    } finally {
      setLoading(false);
    }
  }, 400)

  useEffect(() => {
    if (affiliate?.affiliateId) getAffiliateLives()
  }, [affiliate])

  if (!affiliate) return;

  return (
    <div className='TableBg mt-4 p-2'>

      <div className='position-relative h-100'>
        <Tabs {...{ activeKey, onSelect }} mountOnEnter unmountOnExit>
          <Tab
            eventKey='lives'
            title={
              <div className='d-flex align-items-center'>
                <span>Lives</span>
                <UpdateButtonIcon
                  className={'ms-3'}
                  {...{ loading }}
                  onClick={getAffiliateLives}
                />
              </div>
            }
            className='mt-4 position-relative'
          >
            <Table striped responsive hover size="sm" className="text-center m-0">
              <thead className="thead-light">
                <tr>
                  {affiliateTableHeaders.map((h, idx) => (
                    <th
                      key={idx}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {h.label}{" "}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {!loading &&
                  Boolean(lives) &&
                  lives?.map(
                    ({ liveId, timeReadable, viewers, unique_total_viewers, startedAt, finishedAt }) => {
                      return (
                        <tr key={liveId}>
                          <td>{liveId}</td>
                          <td>{timeReadable}</td>
                          {/* <td>{viewers.length}</td> */}
                          <td>{unique_total_viewers}</td>
                          <td>{startedAt ? FormatDate.default(startedAt) : "N/A"}</td>
                          <td>{finishedAt ? FormatDate.default(finishedAt) : "N/A"}</td>
                        </tr>
                      );
                    }
                  )}

                {lives.length === 0 && !loading && (
                  <tr>
                    <td colSpan={13} className="text-danger text-center">
                      Nenhum registro de live encontrado!
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            {loading ? <Loading className={"position-absolute bg-white top-0 w-100 h-100"} /> : null}
          </Tab>
          <Tab eventKey='logs' title="Logs" className='mt-4 position-relative'>
            <AffiliateStreamLogsTable {...{ affiliate }} />
          </Tab>
        </Tabs>
      </div>
    </div>
  )
}

export default AffiliateLives
