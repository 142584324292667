import React, { useState } from 'react'
import UserKycDocumentsList from './UserKycDocumentsList'
import { ApproveRejectModal } from '../../../../../components/ConfirmationModal'
import Reason from '../Reason'
import { ImagePreviewModal } from '../../../../../components/ImagePreviewModal'
import SumsubKYCTable from './SumsubKYCTable'
import { KYC_METHOD } from '../../../../../utils/constant'
import SendUserDocumentModal from './SendUserDocumentModal'

const KYCDocuments = ({
  userDocuments,
  handleVerify,
  documentLabels,
  updateDocument,
  show,
  setShow,
  handleYes,
  handleClose,
  showReasonModal,
  status,
  enable,
  setEnable,
  docLabels,
  handleReRequest,
  title,
  imagePreviewModalShow,
  setImagePreviewModalShow,
  handleImagePreview,
  imageUrl,
  setImageUrl,
  name,
  labelName,
  userDetails,
  getUserDocuments
}) => {

  const [showSendUserDocumentModal, setShowSendUserDocumentModal] = useState(false);

  return (
    <>
      {userDetails?.kycMethod === KYC_METHOD.SUMSUB
        ? <SumsubKYCTable userDocuments={userDocuments} />
        : <UserKycDocumentsList
          userDocuments={userDocuments}
          documentLabels={documentLabels}
          updateDocument={updateDocument}
          handleVerify={handleVerify}
          docLabels={docLabels}
          handleReRequest={handleReRequest}
          handleImagePreview={handleImagePreview}
          {...{ setShowSendUserDocumentModal }}
        />}

      {show &&
        <ApproveRejectModal
          show={show}
          setShow={setShow}
          handleYes={handleYes}
          status={status}
          name={name}
        />}
      {showReasonModal &&
        <Reason
          show={showReasonModal}
          handleYes={handleYes}
          handleClose={handleClose}
          title={title}
          enable={enable}
          setEnable={setEnable}
          name={name}
          labelName={labelName}
        />}
      {imagePreviewModalShow &&
        <ImagePreviewModal
          imagePreviewModalShow={imagePreviewModalShow}
          setImagePreviewModalShow={setImagePreviewModalShow}
          imageUrl={imageUrl}
          setImageUrl={setImageUrl}
        />}

      {showSendUserDocumentModal &&
        <SendUserDocumentModal
          show={showSendUserDocumentModal}
          setShow={setShowSendUserDocumentModal}
          userData={userDetails}
          documentLabels={documentLabels}
          getUserDocuments={getUserDocuments}
        />
      }
    </>
  )
}

export default KYCDocuments
