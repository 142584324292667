import * as Yup from 'yup'

const MAXIMUM_VALUE = 10000;

export const referralManagementScheme = () => Yup.object().shape({
  referralAmount: Yup.number()
    .positive('Limit must be positive number')
    .min(0.1, 'Referral amount must be greater than zero.')
    .max(MAXIMUM_VALUE, `Referral amount must be less than ${MAXIMUM_VALUE}`)
    .required('Referral Amount is required.'),
  minimumDeposite: Yup.number()
    .positive('Minimum deposite must be positive number')
    .min(0.1, 'Minimum deposite must be greater than zero.')
    .max(MAXIMUM_VALUE, `Minimum deposite must be less than ${MAXIMUM_VALUE}`)
    .required('Minimum deposite Amount is required.'),
  wageringMultiplier: Yup.number()
    .positive('Wagering multiplier must be positive number')
    .min(1, 'Wagering multiplier must be greater than or equal to one.')
    .max(MAXIMUM_VALUE, `Wagering multiplier must be less than ${MAXIMUM_VALUE}`)
    .required('Wagering multiplier is required.'),
  daysToClear: Yup.number()
    .positive('Expiry in days must be positive number')
    .min(1, 'Expiry in days must be greater than or equal to one.')
    .max(MAXIMUM_VALUE, `Expiry in days must be less than ${MAXIMUM_VALUE}`)
    .required('Expiry in days is required.')
});
