import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import map from '@highcharts/map-collection/custom/world.geo.json'
import { getLoginToken } from '../../../../utils/storageUtils'
import { formatDateYMD, getDateDaysAgo } from '../../../../utils/dateFormatter'
import { getDemographicReportStart } from '../../../../store/redux-slices/dashboard'
import { countryFilter } from '../../../../utils/countryFilter'
import useDidMountEffect from '../../../../utils/useDidMountEffect'

const useDemographicReport = ({ selectedPortal, selectedClient }) => {
  const dispatch = useDispatch()
  const { demogLoading: loading, demogData } = useSelector((state) => state.dashboard)
  const [formatedData, setFormatedData] = useState([])
  const [dateOptions, setDateOptions] = useState('yeartodate')
  const isInitialRender = useDidMountEffect()
  const getColor = (value) => {
    if (value <= 1000) {
      return '#b4ecff'
    } else if (value <= 100000) {
      return 'rgb(0, 217, 255)'
    } else {
      return '#00a2ff'
    }
  }

  const [state, setState] = useState([
    {
      startDate: getDateDaysAgo(0),
      endDate: new Date(),
      key: 'selection'
    }
  ])

  const formatDataHandler = (list) => {
    const data = []

    list && list.map((item) => {
      const { countryName } = countryFilter(item.country_code)
      const row = {
        // z: item.signUpCount,
        // code: item.country_code,
        // countryName,
        // color: getColor(item.signUpCount),
        name: countryName,
        y: Number(item.signUpCount),
        count: Number(item.signUpCount),
      }
      data.push(row)
      return null
    })

    setFormatedData(data)
  }

  useEffect(() => {
    if (demogData) formatDataHandler(demogData)
  }, [demogData])

  const loadDemogData = () => {
    dispatch(getDemographicReportStart({
      isTenant: false,
      startDate: formatDateYMD(state.map(a => a.startDate)),
      endDate: formatDateYMD(state.map(a => a.endDate)),
      // tenantId: selectedPortal,
      // adminId: selectedClient,
      dateOptions
    }))
  }

  useEffect(() => {
    loadDemogData()
  }, [state, selectedClient, selectedPortal])

  useEffect(() => {
    if (dateOptions !== 'custom' && !isInitialRender) {
      loadDemogData()
    }
  }, [dateOptions])

  const getCsvDownloadUrl = () =>
  `${process.env.REACT_APP_API_URL}/api/admin/report/demographic?adminId=${selectedClient}&tenantId=${selectedPortal}&startDate=${formatDateYMD(state.map(a => a.startDate))}&endDate=${formatDateYMD(state.map(a => a.endDate))}&dateOptions=${dateOptions}&csvDownload=true&token=${getLoginToken()}`

//   const options = {
//     chart: {
//       map
//     },

//     title: {
//       text: ''
//     },
//     series: [
//       {
//         name: 'Signups Report',
//         data: formatedData,
//         joinBy: ['iso-a2', 'code'],
//         tooltip: {
//           pointFormat: '{point.properties.hc-a2}: {point.countryName} Users'
//         }
//       }
//     ]
//   }
const options = {
    chart: {
        type: 'column',
        renderTo: 'container',
        options3d: {
            enabled: true,
            alpha: 25,
            beta: 15,
            depth: 50,
            viewDistance: 25
        }
    },
    tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.count}</b> <br/>'
    },
    title: {
		text: 'Sign ups reports',
    },
    xAxis: {
        type: 'category',
        labels: {
            enabled: true,
        },
        tickLength: 1,
    },
  
    yAxis: {
        min: 0,
        tickInterval: 1,
        title: {
            enabled: false
        }
    },
    plotOptions: {
      series: {
        borderRadius: 3,
        pointPadding: 0,
        groupPadding: 0.05,
      },
    },
    series: [
        {
            name: 'Signups Report',
            data: formatedData,
            colorByPoint: true,
            type: 'column',
            // joinBy: ['iso-a2', 'code'],
            // tooltip: {
            //     pointFormat: '{point.properties.hc-a2}: {point.countryName} Users'
            // }
        },
    ]
  };

  return {
    options,
    demogData,
    formatedData,
    loadDemogData,
    loading,
    setState,
    state,
    getCsvDownloadUrl,
    dateOptions,
    setDateOptions
  }
}

export default useDemographicReport
