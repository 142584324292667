import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAllUsersStart, clearAllUsers, getAllAffiliatesStart } from '../../../../store/redux-slices/fetchData'
import { formatDateYMD } from '../../../../utils/dateFormatter'
import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { updateAffiliateStatusStart } from '../../../../store/redux-slices/affiliates'
import { updateSuperAdminStatusStart } from '../../../../store/redux-slices/adminUser'

const useAllAffiliateListing = (props = {}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isInitialRender = useDidMountEffect()

  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [affiliateSearch, setAffiliateSearch] = useState('')
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedPortal, setSelectedPortal] = useState('')
  const [kycOptions, setKycOptions] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [activeUserId, setUserId] = useState('')
  const [parentIdAffiliate, setParentIdAffiliate] = useState(props.parentIdAffiliate ?? "")
  const [orderBy, setOrderBy] = useState('userId')
  const [sortBy, setSortBy] = useState('desc')
  const [over, setOver] = useState(false)
  const [status, setStatus] = useState("")
  const [isVisible, setIsVisible] = useState("")
  const [affiliateType, setAffiliateType] = useState("")
  const [state, setState] = useState([
    {
      startDate: new Date('1923'),
      endDate: new Date(),
      key: 'selection'
    }
  ])
  const [show, setShow] = useState(false)
  const [active, setActive] = useState()
  const [affiliateId, setAffiliateId] = useState()
  const [adminIdAffiliate, setAdminIdAffiliate] = useState()
  const { adminRole } = useSelector((state) => state.login)
  const getRole = (id) => adminRole && adminRole.find((obj) => obj.adminRoleId === id).name

  const { loading, affiliatesData } = useSelector((state) => state.fetch)
  const totalPages = Math.ceil(affiliatesData?.count / limit)

  const selected = (h) =>
    orderBy === h.value &&
    (h.value === 'email' || h.value === 'userId' || h.value === 'username')

  const fetchData = () => {
    dispatch(
      getAllAffiliatesStart({
        limit,
        pageNo: page,
        tenantId: selectedPortal,
        adminId: selectedClient,
        search: search.replace('+', '%2B'),
        kycStatus: kycOptions,
        affiliateSearch,
        dobStart: formatDateYMD(state.map(a => a.startDate)),
        dobEnd: formatDateYMD(state.map(a => a.endDate)),
        userId: activeUserId,
        parentIdAffiliate,
        phoneNumber,
        orderBy,
        sortBy,
        status,
        affiliateType,
        isVisible
      })
    )
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          dispatch(
            getAllAffiliatesStart({
              limit,
              pageNo: page,
              tenantId: selectedPortal,
              adminId: selectedClient,
              search: search.replace('+', '%2B'),
              kycStatus: kycOptions,
              affiliateSearch,
              dobStart: formatDateYMD(state.map(a => a.startDate)),
              dobEnd: formatDateYMD(state.map(a => a.endDate)),
              userId: activeUserId,
              parentIdAffiliate,
              phoneNumber,
              orderBy,
              sortBy,
              search,
              status,
              affiliateType,
              isVisible
            })
          )
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search, affiliateSearch, activeUserId, phoneNumber, parentIdAffiliate, status, isVisible, affiliateType])

  useEffect(() => {
    !isInitialRender && fetchData()

    return () => dispatch(clearAllUsers())
  }, [page])

  useEffect(() => {
    setPage(1)
    fetchData()

    return () => dispatch(clearAllUsers())
  }, [limit, selectedPortal, kycOptions, selectedClient, state, orderBy, sortBy])

  const handleYes = () => {
    console.log('active', active)
    dispatch(
      updateAffiliateStatusStart({
        data: {
          code: 'AFFILIATE',
          status: !active,
          affiliateId: affiliateId
        },
        limit,
        pageNo: page,
        orderBy,
        // sort,
        search: search.replace('+', '%2B'),
        superAdminId: '',
        superRoleId: '',
        adminIdAffiliate
      })
    )

    setShow(false)
  }

  const handleShow = (id, active, myName, admindIdAffiliate) => {
    setAffiliateId(id)
    setActive(active)
    setShow(true)
    setAdminIdAffiliate(admindIdAffiliate)
    // setName(myName)
  }

  const resetFilter = () => {
    setSearch("")
    setUserId("")
    setParentIdAffiliate("")
  }

  return {
    limit,
    setLimit,
    page,
    setPage,
    search,
    setSearch,
    affiliatesData,
    totalPages,
    navigate,
    affiliateSearch,
    setAffiliateSearch,
    selectedClient,
    selectedPortal,
    setSelectedPortal,
    setSelectedClient,
    loading,
    kycOptions,
    setKycOptions,
    state,
    setState,
    activeUserId,
    phoneNumber,
    setUserId,
    parentIdAffiliate,
    setParentIdAffiliate,
    setPhoneNumber,
    orderBy,
    setOrderBy,
    sortBy,
    setSortBy,
    over,
    setOver,
    selected,
    getRole,
    show,
    setShow,
    active,
    setActive,
    handleShow,
    handleYes,
    resetFilter,
    setStatus,
    setIsVisible,
    setAffiliateType,
  }
}

export default useAllAffiliateListing
