import * as Yup from 'yup'

export const sendEmailSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email Required')
})

export const resetPasswordSchema = Yup.object().shape({
  password:
    Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        'Invalid Password'
      )
      .max(50)
      .required('Password Required'),
  confirmPassword:
    Yup.string()
      .max(50)
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password Required')
})
