import React from 'react'
import { Route } from 'react-router-dom'

import PrivateRoute from '../../../components/PrivateRoute'
import { AdminsRoutes } from '../../../routes'
// Super-Admin routes
import Tenants from '../../Super-Admin/Tenants'
import TenantOwner from '../../Super-Admin/TenantOwner'
import Themes from '../../Super-Admin/Themes'
import Admins from '../../Super-Admin/Admins'
import AdminDetails from '../../Super-Admin/AdminDetails'
import TenantDetails from '../../Super-Admin/TenantDetails'
import Currencies from '../../Super-Admin/Currencies'
import CreateCurrencies from '../../Super-Admin/Currencies/components/CreateCurrencies'
import EditCurrency from '../../Super-Admin/Currencies/components/editCurrency'
import CreateTenantAdmin from '../../Super-Admin/TenantOwner/components/CreateTenantAdmin'
import Dashboard from '../../Super-Admin/Dashboard'
import EditTenant from '../../Super-Admin/EditTenant'
import EditTheme from '../../Super-Admin/Themes/EditTheme'
import CreateTheme from '../../Super-Admin/Themes/CreateTheme'
import Cms from '../../Super-Admin/CMS'
import Users from '../../Super-Admin/Users'
import UserDetails from '../../Super-Admin/UserDetails'
import CreateCredential from '../../Super-Admin/TenantDetails/components/CreateCredential'
import CMSDetail from '../../Super-Admin/CMS/components/CmsDetail'
import CasinoProviders from '../../Super-Admin/CasinoProviders'
import CasinoGames from '../../Super-Admin/CasinoGames'
import Countries from '../../Super-Admin/Countries'
import CreateSuperAdminUser from '../../Super-Admin/Admins/components/CreateSuperAdminUser'
import EditSuperAdminUser from '../../Super-Admin/Admins/components/EditSuperAdminUser'
import EditTenantAdmin from '../../Super-Admin/TenantOwner/components/EditTenantAdmin'
import TransactionsBanking from '../../Super-Admin/TransactionsBanking'
import Wallet from '../../../components/Sidebar/components/Wallet'
import Aggregators from '../../Super-Admin/Aggregators'
import CasinoCategory from '../../Super-Admin/CasinoCategory'
import CasinoSubCategory from '../../Super-Admin/CasinoSubCategory'
import RestrictedGames from '../../Super-Admin/RestrictedGames'
import RestrictedProviders from '../../Super-Admin/RestrictedProviders'
import RestrictProviderCountries from '../../Super-Admin/RestrictProviderCountries'
import RestrictGamesCountries from '../../Super-Admin/RestrictGamesCountries'
import CasinoTransactions from '../../Super-Admin/CasinoTransactions'
import CreateCMSNew from '../../Super-Admin/CMS/components/CreateCMSNew'
import EditCms from '../../Super-Admin/CMS/components/EditCms'
import WithdrawRequestList from '../../../components/WithdrawRequestList'
import FormFields from '../../Super-Admin/FormFields'
import CreateBonus from '../../Super-Admin/Bonus/components/CreateBonus'
import BonusManagement from '../../Super-Admin/Bonus'
import EditBonus from '../../Super-Admin/Bonus/components/EditBonus'
import KYCLabels from '../../Super-Admin/KYCLabels'
import CloneBonus from '../../Super-Admin/Bonus/components/CloneBonus'
import BonusDetail from '../../Super-Admin/Bonus/BonusDetail'
import WageringTemplate from '../../Super-Admin/WageringTemplate'
import WageringTeplateDetails from '../../Super-Admin/WageringTemplate/components/WageringTeplateDetails'
import EditWageringTemplate from '../../Super-Admin/WageringTemplate/components/EditWageringTemplate'
import CreateWageringTemplate from '../../Super-Admin/WageringTemplate/components/CreateWageringTemplate'
import ProfilePage from '../../../components/ProfilePage'
import LoyaltyPoints from '../../../components/LoyaltyPoints'
import EmailTemplate from '../../Super-Admin/EmailTemplate'
import EditEmailTemplate from '../../Super-Admin/EmailTemplate1/editEmailTemplate'
import OwnerDetails from '../../Super-Admin/TenantOwner/components/OwnerDetails'
import Languages from '../../Super-Admin/Languages'
import ImageGallery from '../../Super-Admin/ImageGallery'
import BannerManagement from '../../Super-Admin/BannerManagement'
import LanguageManagement from '../../Super-Admin/Languages/components/LanguageManagement'
import LanguageWise from '../../../components/LanguageMgmt/components/LanguageWise'
import KeyWise from '../../../components/LanguageMgmt/components/KeyWise'
import AddLangauge from '../../../components/LanguageMgmt/components/AddLanguage'
import CreateTenant from '../../Super-Admin/CreateTenant'
import CustomPayment from '../../../pages/Super-Admin/CustomPayment'
import EditProvider from '../../Super-Admin/CustomPayment/components/EditProvider'
import ReorderBonus from '../../../components/ReorderBonus'
import ReviewManagement from '../../Super-Admin/ReviewManagement'
import SportsTransactions from '../../Super-Admin/SportsTransactions'
import ComingSoon from '../../../components/ComingSoon'
import AddCasinoSubCategory from '../../Super-Admin/AddCasinoSubCategory'
import EmailTemplate1 from '../../Super-Admin/EmailTemplate1'
import GameReorder from '../../Super-Admin/GameReorder'
import ReorderSubCategory from '../../Super-Admin/CasinoSubCategory/components/ReorderSubCategory'
import ReorderCategory from '../../Super-Admin/CasinoCategory/components/ReorderCategory'
import BetSettings from '../../SportsBook/BetSettings'
import SportsList from '../../SportsBook/SportsList';
import SportsCountriesList from '../../SportsBook/SportsCountriesList';
import TournamentList from '../../SportsBook/SportsTournamentList';
import SportsMatchesList from '../../SportsBook/SportsMatchesList';
import SportsMarketList from '../../SportsBook/SportsMarketList';
import SportsMatchesDetail from '../../SportsBook/SportsMatchesDetail'
import Affiliates from '../../Super-Admin/Affiliates/Affiliates'
import CreateAffiliate from '../../Super-Admin/Affiliates/components/CreateAffiliate'
import AffiliateDetails from '../../Super-Admin/AffiliateDetails'
import UpdateAffiliate from '../../Super-Admin/Affiliates/components/UpdateAffiliate'
import ReorderSubCategorySecondary from '../../Super-Admin/CasinoSubCategory/components/ReorderSubCategorySecondary'
import SocialLinks from '../../Super-Admin/SocialLinks'
import CreateSocialLink from '../../Super-Admin/SocialLinks/components/CreateSocialLink'
import UpdateSocialLink from '../../Super-Admin/SocialLinks/components/EditSocialLink'
import LoyaltyRulesListing from '../../Super-Admin/LoyaltyRules/index'
import CreateLoyaltyRule from '../../Super-Admin/LoyaltyRules/components/CreateLoyaltyRule'
import UpdateLoyaltyRule from '../../Super-Admin/LoyaltyRules/components/editLoyaltyRule'
import ReferralManagement from '../../Super-Admin/ReferralManagement'
import TransactionsSettingsPage from '../../Super-Admin/SiteConfig/TransactionsSettings'
import GeneralSettingsPage from '../../Super-Admin/SiteConfig/GeneralSettings'

const SuperAdminPages = () => (
  <>
    {/* <Route
      path={AdminsRoutes.Wallet}
      element={
        <PrivateRoute>
          <Wallet />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.TenantOwner}
      element={
        <PrivateRoute module={{ Tenant: 'R' }}>
          <TenantOwner />
          <Tenants />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.Tenants}
      element={
        <PrivateRoute module={{ Tenant: 'R' }}>
          <Tenants />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.CreateTenant}
      element={
        <PrivateRoute module={{ Tenant: 'C' }}>
          <CreateTenant />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.TenantDetails}
      element={
        <PrivateRoute module={{ Tenant: 'R' }}>
          <TenantDetails />
        </PrivateRoute>
      }
    /> */}


    <Route
      path={AdminsRoutes.Admins}
      element={
        <PrivateRoute module={{ Admins: 'R' }} isWithoutCard>
          <Admins />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.AdminDetails}
      element={
        <PrivateRoute module={{ Admins: 'R' }} isWithoutCard>
          <AdminDetails />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.Users}
      element={
        <PrivateRoute module={{ Users: 'R' }} isWithoutCard>
          <Users />
        </PrivateRoute>
      }
    />


    <Route
      path={AdminsRoutes.CreateEmailTemplate}
      element={
        <PrivateRoute module={{ EmailTemplate: 'R' }} isWithoutCard>
          <EditEmailTemplate />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.UserDetails}
      element={
        <PrivateRoute module={{ Users: 'R' }} isWithoutCard>
          <UserDetails />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.Currencies}
      element={
        <PrivateRoute module={{ Currencies: 'R' }} isWithoutCard>
          <Currencies />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.CreateCurrencies}
      element={
        <PrivateRoute module={{ Currencies: 'C' }} isWithoutCard>
          <CreateCurrencies />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.Countries}
      element={
        <PrivateRoute module={{ RestrictedCountry: 'R' }} isWithoutCard>
          <Countries />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.EditCurrency}
      element={
        <PrivateRoute module={{ Currencies: 'U' }} isWithoutCard>
          <EditCurrency />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.RestrictedProviders}
      element={
        <PrivateRoute module={{ RestrictedCountry: 'U' }} isWithoutCard>
          <RestrictedProviders />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.RestrictedGameCountries}
      element={
        <PrivateRoute module={{ CasinoManagement: 'U' }} isWithoutCard>
          <RestrictGamesCountries />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.EditCMS}
      element={
        <PrivateRoute module={{ CMS: 'U' }} isWithoutCard>
          <EditCms />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.CMSDetail}
      element={
        <PrivateRoute module={{ CMS: 'R' }} isWithoutCard>
          <CMSDetail />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.CMS}
      element={
        <PrivateRoute module={{ CMS: 'R' }} isWithoutCard>
          <Cms />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.CreateCMS}
      element={
        <PrivateRoute module={{ CMS: 'C' }} isWithoutCard>
          <CreateCMSNew />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.ImageGallery}
      element={
        <PrivateRoute module={{ ImageGallery: 'R' }} isWithoutCard>
          <ImageGallery />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.ReviewManagement}
      element={
        <PrivateRoute module={{ Reviews: 'U' }} isWithoutCard>
          <ReviewManagement />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.KYCLabels}
      element={
        <PrivateRoute module={{ KycLabel: 'R' }} isWithoutCard>
          {/* <ComingSoon /> */}
          <KYCLabels />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.Languages}
      element={
        <PrivateRoute module={{ MultiLanguage: 'R' }} isWithoutCard>
          <Languages />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.CasinoGames}
      element={
        <PrivateRoute module={{ CasinoManagement: 'R' }} isWithoutCard>
          <CasinoGames />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.CasinoProviders}
      element={
        <PrivateRoute module={{ CasinoManagement: 'R' }} isWithoutCard>
          <CasinoProviders />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.BannerManagement}
      element={
        <PrivateRoute isWithoutCard>
          <BannerManagement module={{ CasinoManagement: 'R' }} />
        </PrivateRoute>
      }
    />

    {/* <Route
      path={AdminsRoutes.Themes}
      element={
        <PrivateRoute module={{ Themes: 'R' }}>
          <Themes />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.Currencies}
      element={
        <PrivateRoute module={{ Currencies: 'R' }}>
          <Currencies />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.Languages}
      element={
        <PrivateRoute module={{ Currencies: 'R' }}>
          <Languages />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.CreateTheme}
      element={
        <PrivateRoute module={{ Themes: 'C' }}>
          <CreateTheme />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.CMSDetail}
      element={
        <PrivateRoute module={{ CMS: 'R' }}>
          <CMSDetail />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.EditTheme}
      element={
        <PrivateRoute module={{ Themes: 'U' }}>
          <EditTheme />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.CreateCurrencies}
      element={
        <PrivateRoute module={{ Currencies: 'C' }}>
          <CreateCurrencies />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.CreateTenantAdmin}
      element={
        <PrivateRoute module={{ Tenant: 'C' }}>
          <CreateTenantAdmin />
        </PrivateRoute>
      }
    />

   

    <Route
      path={AdminsRoutes.CMS}
      element={
        <PrivateRoute module={{ CMS: 'R' }}>
          <Cms />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.CreateCMS}
      element={
        <PrivateRoute module={{ CMS: 'C' }}>
          <CreateCMSNew />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.Users}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <Users />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.UserDetails}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <UserDetails />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.EditTenant}
      element={
        <PrivateRoute module={{ Tenant: 'U' }}>
          <EditTenant />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.EditCurrency}
      element={
        <PrivateRoute module={{ Currencies: 'U' }}>
          <EditCurrency />
        </PrivateRoute>
      }
    />
*/}
    <Route
      path={AdminsRoutes.Dashboard}
      element={
        <PrivateRoute isWithoutCard>
          <Dashboard />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.RestrictedGames}
      element={
        <PrivateRoute module={{ RestrictedCountry: 'U' }} isWithoutCard>
          <RestrictedGames />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.AddCasinoSubCategory}
      element={
        <PrivateRoute module={{ RestrictedCountry: 'U' }} isWithoutCard>
          <AddCasinoSubCategory />
        </PrivateRoute>
      }
    />

    {/* <Route
      path={AdminsRoutes.Dashboard}
      element={<Dashboard />}
    /> */}
    {/*
    <Route
      path={AdminsRoutes.Credential}
      element={
        <PrivateRoute module={{ TenantCredentials: 'C' }}>
          <CreateCredential />
        </PrivateRoute>
      }
    />


    <Route
      path={AdminsRoutes.Countries}
      element={
        <PrivateRoute module={{ RestrictedCountry: 'R' }}>
          <Countries />
        </PrivateRoute>
      }
    />
    */}
    <Route
      path={AdminsRoutes.CreateSuperAdminUser}
      element={
        <PrivateRoute module={{ Admins: 'C' }} isWithoutCard>
          <CreateSuperAdminUser />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.WageringTemplate}
      element={
        <PrivateRoute module={{ WageringTemplate: 'R' }} isWithoutCard>
          <WageringTemplate />
        </PrivateRoute>
      }
    /> <Route
      path={AdminsRoutes.WageringEdit}
      element={
        <PrivateRoute module={{ WageringTemplate: 'U' }} isWithoutCard>
          <EditWageringTemplate />
        </PrivateRoute>
      }
    /><Route
      path={AdminsRoutes.WageringDetails}
      element={
        <PrivateRoute module={{ WageringTemplate: 'R' }} isWithoutCard>
          <WageringTeplateDetails />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.CreateWagering}
      element={
        <PrivateRoute module={{ WageringTemplate: 'C' }} isWithoutCard>
          <CreateWageringTemplate />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.GeneralSettings}
      element={
        <PrivateRoute module={{ SiteConfiguration: 'U' }} isWithoutCard>
          <GeneralSettingsPage />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.TransactionsSettings}
      element={
        <PrivateRoute module={{ SiteConfiguration: 'U' }} isWithoutCard>
          <TransactionsSettingsPage />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.TransactionsBanking}
      element={
        <PrivateRoute module={{ Transactions: 'R' }} isWithoutCard>
          <TransactionsBanking />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.CasinoTransactions}
      element={
        <PrivateRoute module={{ Transactions: 'R' }} isWithoutCard>
          <CasinoTransactions />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.SportsTransactions}
      element={
        <PrivateRoute module={{ SportbookManagement: 'R' }} isWithoutCard>
          <SportsTransactions />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.WithdrawRequests}
      element={
        <PrivateRoute module={{ Transactions: 'R' }} isWithoutCard>
          <WithdrawRequestList type='SuperAdmin' />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.FormFields}
      element={
        <PrivateRoute module={{ RegistrationField: 'R' }} isWithoutCard>
          <FormFields />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.CreateBonus}
      element={
        <PrivateRoute module={{ Bonus: 'C' }} isWithoutCard>
          <CreateBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.Bonus}
      element={
        <PrivateRoute module={{ Bonus: 'R' }} isWithoutCard>
          <BonusManagement />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.EditBonus}
      element={
        <PrivateRoute module={{ Bonus: 'U' }} isWithoutCard>
          <EditBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.CloneBonus}
      element={
        <PrivateRoute module={{ Bonus: 'C' }} isWithoutCard>
          <CloneBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.BonusDetail}
      element={
        <PrivateRoute module={{ Bonus: 'R' }} isWithoutCard>
          <BonusDetail />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.ReferralManagement}
      element={
        <PrivateRoute module={{ Bonus: 'R' }} isWithoutCard>
          <ReferralManagement />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.EditSuperAdminUser}
      element={
        <PrivateRoute module={{ Admins: 'U' }} isWithoutCard>
          <EditSuperAdminUser />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.Aggregators}
      element={
        <PrivateRoute module={{ CasinoManagement: 'R' }} isWithoutCard>
          <Aggregators />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.CasinoCategory}
      element={
        <PrivateRoute module={{ CasinoManagement: 'R' }} isWithoutCard>
          <CasinoCategory />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.CasinoSubCategory}
      element={
        <PrivateRoute module={{ CasinoManagement: 'R' }} isWithoutCard>
          <CasinoSubCategory />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.RestrictedProviderCountries}
      element={
        <PrivateRoute module={{ CasinoManagement: 'U' }} isWithoutCard>
          <RestrictProviderCountries />
        </PrivateRoute>
      }
    />

    {/*    

    <Route
      path={AdminsRoutes.EditTenantAdmin}
      element={
        <PrivateRoute module={{ Tenant: 'U' }}>
          <EditTenantAdmin />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.TransactionsBanking}
      element={
        <PrivateRoute module={{ Transactions: 'R' }}>
          <TransactionsBanking />
        </PrivateRoute>
      }
    />

   

    <Route
      path={AdminsRoutes.RestrictedGames}
      element={
        <PrivateRoute module={{ RestrictedCountry: 'U' }}>
          <RestrictedGames />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.RestrictedProviders}
      element={
        <PrivateRoute module={{ RestrictedCountry: 'U' }}>
          <RestrictedProviders />
        </PrivateRoute>
      }
    />

    
    <Route
      path={AdminsRoutes.RestrictedGameCountries}
      element={
        <PrivateRoute module={{ CasinoManagement: 'U' }}>
          <RestrictGamesCountries />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.ReviewManagement}
      element={
        <PrivateRoute module={{ Reviews: 'U' }}>
          <ReviewManagement />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.CasinoTransactions}
      element={
        <PrivateRoute module={{ Transactions: 'R' }}>
          <CasinoTransactions />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.EditCMS}
      element={
        <PrivateRoute module={{ CMS: 'U' }}>
          <EditCms />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.WithdrawRequests}
      element={
        <PrivateRoute module={{ Transactions: 'R' }}>
          <WithdrawRequestList type='SuperAdmin' />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.FormFields}
      element={
        <PrivateRoute module={{ RegistrationField: 'R' }}>
          <FormFields />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.CreateBonus}
      element={
        <PrivateRoute module={{ Bonus: 'C' }}>
          <CreateBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.Bonus}
      element={
        <PrivateRoute module={{ Bonus: 'R' }}>
          <BonusManagement />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.EditBonus}
      element={
        <PrivateRoute module={{ Bonus: 'U' }}>
          <EditBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.CloneBonus}
      element={
        <PrivateRoute module={{ Bonus: 'C' }}>
          <CloneBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.BonusDetail}
      element={
        <PrivateRoute module={{ Bonus: 'R' }}>
          <BonusDetail />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.KYCLabels}
      element={
        <PrivateRoute module={{ KycLabel: 'R' }}>
          <KYCLabels />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.EmailTemplate}
      element={
        <PrivateRoute module={{ EmailTemplate: 'R' }}>
          <EmailTemplate />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.EditEmailTemplate}
      element={
        <PrivateRoute module={{ EmailTemplate: 'U' }}>
          <EditEmailTemplate />
        </PrivateRoute>
      }
    />
    */}
    <Route
      path={AdminsRoutes.EmailTemplate}
      element={
        <PrivateRoute module={{ EmailTemplate: 'R' }} isWithoutCard>
          <EmailTemplate1 />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.EditEmailTemplate}
      element={
        <PrivateRoute module={{ EmailTemplate: 'U' }} isWithoutCard>
          <EditEmailTemplate isEdit={true} />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.Profile}
      element={
        <PrivateRoute isWithoutCard>
          <ProfilePage isTenant={false} />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.Loyalty}
      element={
        <PrivateRoute isWithoutCard module={{ LoyaltyManagement: 'R' }}>
          <LoyaltyRulesListing></LoyaltyRulesListing>
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.CreateLoyaltyRule}
      element={
        <PrivateRoute isWithoutCard>
          {/* <LoyaltyPoints /> */}
          <CreateLoyaltyRule />
          {/* <ComingSoon /> */}
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.EditLoyalty}
      element={
        <PrivateRoute isWithoutCard>
          <UpdateLoyaltyRule />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.LanguageManagement}
      element={
        <PrivateRoute isWithoutCard>
          <LanguageManagement module={{ MultiLanguage: 'R' }} />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.EditSupportLanguage}
      element={
        <PrivateRoute module={{ MultiLanguage: 'U' }} isWithoutCard>
          <LanguageWise />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.EditSupportLanguageKeys}
      element={
        <PrivateRoute module={{ MultiLanguage: 'U' }} isWithoutCard>
          <KeyWise />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.AddLanguage}
      element={
        <PrivateRoute module={{ MultiLanguage: 'U' }} isWithoutCard>
          <AddLangauge />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.PaymentAggregators}
      element={
        <PrivateRoute module={{ CashierManagement: 'R' }} isWithoutCard>
          <CustomPayment />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.EditCustomProviders}
      element={
        <PrivateRoute module={{ CashierManagement: 'U' }} isWithoutCard>
          <EditProvider />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.ViewCustomProviders}
      element={
        <PrivateRoute module={{ CashierManagement: 'R' }}>
          <EditProvider />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.BonusReorder}
      element={
        <PrivateRoute module={{ Bonus: 'U' }} isWithoutCard>
          <ReorderBonus />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.GameReorder}
      element={
        <PrivateRoute module={{ CasinoManagement: 'U' }} isWithoutCard>
          <GameReorder />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.ReorderSubCategory}
      element={
        <PrivateRoute module={{ CasinoManagement: 'U' }} isWithoutCard>
          <ReorderSubCategory />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.ReorderCategory}
      element={
        <PrivateRoute module={{ CasinoManagement: 'U' }} isWithoutCard>
          <ReorderCategory />
        </PrivateRoute>
      }
    />
    {/*

    <Route
      path={AdminsRoutes.TenantOwnerDetail}
      element={
        <PrivateRoute>
          <OwnerDetails />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.ImageGallery}
      element={
        <PrivateRoute module={{ ImageGallery: 'R' }}>
          <ImageGallery />
        </PrivateRoute>
      }
    />

   

    <Route
      path={AdminsRoutes.LanguageManagement}
      element={
        <PrivateRoute>
          <LanguageManagement module={{ MultiLanguage: 'R' }} />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.EditSupportLanguage}
      element={
        <PrivateRoute module={{ MultiLanguage: 'U' }}>
          <LanguageWise />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.EditSupportLanguageKeys}
      element={
        <PrivateRoute module={{ MultiLanguage: 'U' }}>
          <KeyWise />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.AddLanguage}
      element={
        <PrivateRoute module={{ MultiLanguage: 'U' }}>
          <AddLangauge />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.PaymentAggregators}
      element={
        <PrivateRoute module={{ CashierManagement: 'R' }}>
          <CustomPayment />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.EditCustomProviders}
      element={
        <PrivateRoute module={{ CashierManagement: 'U' }}>
          <EditProvider />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.ViewCustomProviders}
      element={
        <PrivateRoute module={{ CashierManagement: 'R' }}>
          <EditProvider />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminsRoutes.BonusReorder}
      element={
        <PrivateRoute module={{ Bonus: 'U' }}>
          <ReorderBonus />
        </PrivateRoute>
      }
    /> */}

    <Route
      path={AdminsRoutes.BetSettings}
      element={
        <PrivateRoute module={{ SportbookManagement: 'R' }} isWithoutCard>
          <BetSettings />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.SportsList}
      element={
        <PrivateRoute module={{ SportbookManagement: 'R' }} isWithoutCard>
          <SportsList />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.SportsCountries}
      element={
        <PrivateRoute module={{ SportbookManagement: 'R' }} isWithoutCard>
          <SportsCountriesList />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.SportsTournaments}
      element={
        <PrivateRoute module={{ SportbookManagement: 'R' }} isWithoutCard>
          <TournamentList />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.SportsMatches}
      element={
        <PrivateRoute module={{ SportbookManagement: 'R' }} isWithoutCard>
          <SportsMatchesList />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.SportsMatchesDetail}
      element={
        <PrivateRoute module={{ SportbookManagement: 'R' }} isWithoutCard>
          <SportsMatchesDetail />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.SportsMarkets}
      element={
        <PrivateRoute module={{ SportbookManagement: 'R' }} isWithoutCard>
          <SportsMarketList />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.AffiliatesManagement}
      element={
        <PrivateRoute module={{ Affiliates: 'R' }} isWithoutCard>
          <Affiliates />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.CreateAffiliates}
      element={
        <PrivateRoute module={{ Affiliates: 'C' }} isWithoutCard>
          <CreateAffiliate />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.AffiliateDetails}
      element={
        <PrivateRoute module={{ Affiliates: 'R' }} isWithoutCard>
          <AffiliateDetails />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.UpdateAffiliates}
      element={
        <PrivateRoute module={{ Affiliates: 'U' }} isWithoutCard>
          <UpdateAffiliate />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.SubCategorySecondaryOrder}
      element={
        <PrivateRoute module={{ SlickListReorderning: 'R' }} isWithoutCard>
          <ReorderSubCategorySecondary></ReorderSubCategorySecondary>
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.SocialLinks}
      element={
        <PrivateRoute module={{ SocialLinks: 'R' }} isWithoutCard>
          <SocialLinks></SocialLinks>
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.createSocialLink}
      element={
        <PrivateRoute module={{ SocialLinks: 'C' }} isWithoutCard>
          <CreateSocialLink />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminsRoutes.editSocialLink}
      element={
        <PrivateRoute module={{ SocialLinks: 'C' }} isWithoutCard>
          <UpdateSocialLink />
        </PrivateRoute>
      }
    />
  </>
)

export default SuperAdminPages
