import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    getAllTenantsStart,
    getAllTenantsSuccess,
    getAllTenantsFailure,
    createTenantStart,
    createTenantFailure,
    createTenantSuccess,
    createTenantAdminStart,
    createTenantAdminSuccess,
    createTenantAdminFailure,
    getTenantStart,
    getTenantSuccess,
    getTenantFailure,
    editTenantStart,
    editTenantSuccess,
    editTenantFailure,
    updateTenantStatusStart,
    updateTenantStatusSuccess,
    updateTenantStatusFailure,
    getAllCredentialsStart,
    getAllCredentialsSuccess,
    getAllCredentialsFailure,
    createCredentialsStart,
    createCredentialsSuccess,
    createCredentialsFailure,
    updateCredentialsStart,
    updateCredentialsSuccess,
    updateCredentialsFailure,
    updateTenantAdminStart,
    updateTenantAdminComplete,
    addDepositToOtherStart,
    addDepositToOtherCompleted,
    getAllClientsStart,
    getAllClientsSuccess,
    getAllClientsFailure,
    getAllPortalsStart,
    getAllPortalsSuccess,
    getAllPortalsFailure,
    getAllTenantsListStart,
    getAllTenantsListSuccess,
    getAllTenantsListFailure,
    getOwnerPermissionStart,
    getOwnerPermissionSuccess,
    getOwnerPermissionFailure
  },
  reducer
} = createSlice({
  name: 'getAllTenants',
  initialState: {
    loading: false,
    error: null,
    success: false,
    allTenantList: null,
    tenantDetails: null,
    allCredentialsKey: [],
    clientsFilterData: null,
    portalsFilterData: null,
    tenantsList: null,
    ownerPermission: {},
    permissionLoading: false
  },
  reducers: {
    getAllTenantsStart: (state) => ({
      ...state,
      loading: true,
      error: null,
      success: false
    }),
    getAllTenantsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      allTenantList: payload
    }),
    getAllTenantsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    createTenantStart: (state) => ({
      ...state,
      loading: true,
      error: null,
      success: false
    }),
    createTenantSuccess: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: true
    }),
    createTenantFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      success: false,
      error: payload
    }),
    createTenantAdminStart: (state) => ({
      ...state,
      loading: true,
      error: null,
      success: false
    }),
    createTenantAdminSuccess: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: true
    }),
    createTenantAdminFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      success: false,
      error: payload
    }),
    getTenantStart: (state) => ({
      ...state,
      loading: true,
      error: null,
      success: false
    }),
    getTenantSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      tenantDetails: payload
    }),
    getTenantFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false,
      tenantDetails: null
    }),
    editTenantStart: (state) => ({
      ...state,
      loading: true,
      error: null,
      success: false
    }),
    editTenantSuccess: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: true
    }),
    editTenantFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      success: false,
      error: payload
    }),
    updateTenantStatusStart: (state) => ({
      ...state,
      loading: true,
      error: null,
      success: false
    }),
    updateTenantStatusSuccess: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: true
    }),
    updateTenantStatusFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    getAllCredentialsStart: (state) => ({
      ...state,
      loading: true,
      error: null,
      success: false
    }),
    getAllCredentialsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      allCredentialsKey: payload
    }),
    getAllCredentialsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    createCredentialsStart: (state) => ({
      ...state,
      loading: true,
      error: null,
      success: false
    }),
    createCredentialsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true
    }),
    createCredentialsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    updateCredentialsStart: (state) => ({
      ...state,
      loading: true,
      error: null,
      success: false
    }),
    updateCredentialsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true
    }),
    updateCredentialsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    updateTenantAdminStart: (state) => ({
      ...state,
      loading: true
    }),
    updateTenantAdminComplete: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    addDepositToOtherStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    addDepositToOtherCompleted: (state) => ({
      ...state,
      loading: false
    }),
    getAllClientsStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllClientsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      clientsFilterData: payload
    }),
    getAllClientsFailure: (state) => ({
      ...state,
      loading: false
    }),
    getAllPortalsStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllPortalsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      portalsFilterData: payload
    }),
    getAllPortalsFailure: (state) => ({
      ...state,
      loading: false
    }),
    getAllTenantsListStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllTenantsListSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      tenantsList: payload
    }),
    getAllTenantsListFailure: (state) => ({
      ...state,
      loading: false
    }),
    getOwnerPermissionStart: (state) => ({
      ...state,
      permissionLoading: true
    }),
    getOwnerPermissionSuccess: (state, { payload }) => ({
      ...state,
      permissionLoading: false,
      ownerPermission: { ...payload, CMS: ['R', 'U', 'T'], Users: ['R', 'U', 'T', 'AB', 'SR', 'UP'], Bonus: ['R', 'U', 'C', 'T', 'Issue'] }
    }),
    getOwnerPermissionFailure: (state) => ({
      ...state,
      permissionLoading: false
    })
  }
})

export default reducer

export {
  getAllTenantsStart,
  getAllTenantsSuccess,
  getAllTenantsFailure,
  createTenantStart,
  createTenantSuccess,
  createTenantFailure,
  createTenantAdminStart,
  createTenantAdminSuccess,
  createTenantAdminFailure,
  getTenantStart,
  getTenantSuccess,
  getTenantFailure,
  editTenantStart,
  editTenantSuccess,
  editTenantFailure,
  updateTenantStatusStart,
  updateTenantStatusSuccess,
  updateTenantStatusFailure,
  getAllCredentialsStart,
  getAllCredentialsSuccess,
  getAllCredentialsFailure,
  createCredentialsStart,
  createCredentialsSuccess,
  createCredentialsFailure,
  updateCredentialsStart,
  updateCredentialsSuccess,
  updateCredentialsFailure,
  updateTenantAdminStart,
  updateTenantAdminComplete,
  addDepositToOtherStart,
  addDepositToOtherCompleted,
  getAllClientsStart,
  getAllClientsSuccess,
  getAllClientsFailure,
  getAllPortalsStart,
  getAllPortalsSuccess,
  getAllPortalsFailure,
  getAllTenantsListStart,
  getAllTenantsListSuccess,
  getAllTenantsListFailure,
  getOwnerPermissionStart,
  getOwnerPermissionSuccess,
  getOwnerPermissionFailure
}
