import { Col, Container, Row } from '@themesberg/react-bootstrap'
import BgImage from '../../../../assets/img/illustrations/signin.svg'

import React from 'react'
import { useNavigate,useSearchParams } from 'react-router-dom'
import { AdminsRoutes, AffiliateRoutes, TenantRoutes } from '../../../../routes'

const EmailSent = ({ isSuperAdmin, userEmail }) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const userType = searchParams.get('userType');

  return (
    <>
      <main>
        <section className='d-flex align-items-center my-5 mt-lg-6 mb-lg-5'>
          <Container>
            <Row
              className='justify-content-center form-bg-image'
              style={{ backgroundImage: `url(${BgImage})` }}
            >
              <Col
                xs={12}
                className='d-flex align-items-center justify-content-center'
              >
                <div className='bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500'>
                  <div className='text-center text-md-center mb-4 mt-md-0'>
                    <h2 className='mb-3'>Check your Email</h2>
                    <p>We’ve sent a password reset link to {userEmail}</p>
                  </div>
                  <button
                    type='button'
                    className='btn btn-primary btn-block mt-4'
                    onClick={() => navigate(`${ userType === 'Admin' ? AdminsRoutes?.SuperAdminSignin :AffiliateRoutes.AffiliateSignIn }`)}
                  >
                    {/* {loading && (
                            <Spinner
                              as='span'
                              animation='border'
                              size='sm'
                              role='status'
                              aria-hidden='true'
                            />
                          )} */}
                    Back To Login
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  )
}

export default EmailSent
