import { takeLatest, put, takeEvery } from 'redux-saga/effects'
import {
  // getDemographicDetailsTenant,
  getDemographicDetailsSuperAdmin,
  getSuperAdminPlayerManagement,
  // getTenantPlayerManagement,
  // getTenantLivePlayerReport,
  getSuperAdminLivePlayerReport,
  // getTAPlayerLiability,
  getSAPlayerLiability,
  getSAKPIReport,
  getSAKPISummary,
  // getTAKPIReport,
  // getTAKPISummary,
  getSAGameReport,
  // getTAGameReport,
  // getTAPlayerGameReport,
  getSAPlayerGameReport,
  getElasticHealth,
  getAffiliateAdminUserList,
  getSAProviderReport,
  getSAUserReport
} from '../../utils/apiCalls'

import {
  getDemographicReportFailed,
  getDemographicReportComplete,
  getDemographicReportStart,
  getPlayerManagementStart,
  getPlayerManagementComplete,
  getPlayerManagementFailure,
  getLivePlayersReportStart,
  getLivePlayersReportComplete,
  getLivePlayersReportFailure,
  getPlayerLiabilityStart,
  getPlayerLiabilitySuccess,
  getPlayerLiabilityFailure,
  getKPISummaryStart,
  getKPISummarySuccess,
  getKPISummaryFailure,
  getKPIReportStart,
  getKPIReportSuccess,
  getKPIReportFailure,
  getGameReportFailure,
  getGameReportSuccess,
  getGameReportStart,
  getPlayerGameReportFailure,
  getPlayerGameReportSuccess,
  getPlayerGameReportStart,
  getElasticHealthComplete,
  getElasticHealthFailure,
  getAdminAffiliateUsersStart,
  getAdminAffiliateUsersSuccess,
  getAdminAffiliateUsersFailure,
} from '../redux-slices/dashboard'
import { toast } from '../../components/Toast'
import { REPORT_TYPE } from '../../utils/constant'

export default function* dataWatcher() {
  yield takeLatest(getDemographicReportStart.type, getDemographicDataWorker)
  yield takeLatest(getPlayerManagementStart.type, getPlayerManagementWorker)
  yield takeLatest(getLivePlayersReportStart.type, getTopPlayerReportWorker)
  yield takeLatest(getPlayerLiabilityStart.type, getPlayerLiabilityWorker)
  yield takeLatest(getKPISummaryStart.type, getKPISummaryWorker)
  yield takeLatest(getKPIReportStart.type, getKPIReportWorker)
  yield takeEvery(getGameReportStart.type, getGameReportWorker)
  yield takeLatest(getPlayerGameReportStart.type, getPlayerGameReportWorker)
  // yield takeLatest(getElasticHealthStart.type, getElasticHealthWorker)
  yield takeLatest(getAdminAffiliateUsersStart.type, getAdminAffiliateUsersWorker)
}

function* getDemographicDataWorker(action) {
  try {
    const { isTenant, endDate, startDate, dateOptions } = action && action.payload

    const { data } = yield getDemographicDetailsSuperAdmin({ endDate, startDate, dateOptions });

    yield put(getDemographicReportComplete(data?.data?.demographic))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getDemographicReportFailed(e?.response?.data?.errors[0]?.description))
  }
}

function* getPlayerManagementWorker(action) {
  try {
    const { endDate, startDate, limit, dateOptions, adminId } = action && action.payload

    const { data } = yield getSuperAdminPlayerManagement({ endDate, startDate, adminId, limit, dateOptions })

    yield put(getPlayerManagementComplete(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getPlayerManagementFailure(e?.response?.data?.errors[0]?.description))
  }
}

function* getTopPlayerReportWorker(action) {
  try {
    // const { adminId, tenantId } = action && action.payload

    // const { data } = getSuperAdminLivePlayerReport({ adminId, tenantId })
    const { data } = yield getSuperAdminLivePlayerReport(action.payload)
    yield put(getLivePlayersReportComplete(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getLivePlayersReportFailure(e?.response?.data?.errors[0]?.description))
  }
}

function* getPlayerLiabilityWorker(action) {
  try {
    const { endDate, startDate, adminId, tenantId, dateOptions } = action && action.payload

    const { data } = yield getSAPlayerLiability({ endDate, startDate, adminId, tenantId, dateOptions })
    yield put(getPlayerLiabilitySuccess(data?.data?.playerLiability))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getPlayerLiabilityFailure(e?.response?.data?.errors[0]?.description))
  }
}

function* getKPISummaryWorker(action) {
  try {
    const { endDate, startDate, adminId, tenantId } = action && action.payload

    const { data } = yield getSAKPISummary({ endDate, startDate, adminId, tenantId })

    yield put(getKPISummarySuccess(data?.data?.KPISummary))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getKPISummaryFailure(e?.response?.data?.errors[0]?.description))
  }
}

function* getKPIReportWorker(action) {
  try {
    const { isTenant, endDate, startDate, adminId, tenantId, dateOptions, selectedTab } = action && action.payload

    const { data } = yield getSAKPIReport({ endDate, startDate, adminId, tenantId, dateOptions, selectedTab })
    yield put(getKPIReportSuccess(data?.data?.KPIReport))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getKPIReportFailure(e?.response?.data?.errors[0]?.description))
  }
}

function* getGameReportWorker(action) {
  try {
    const { isTenant, endDate, startDate, adminId, tenantId, dateOptions, selectedTab, reportType, pageNo } = action && action.payload

    if (reportType === REPORT_TYPE.GAME) {
      const { data } = yield getSAGameReport({ endDate, startDate, adminId, tenantId, dateOptions, selectedTab, pageNo })
      yield put(getGameReportSuccess({ game: data?.data?.result }))
    } else if (reportType === REPORT_TYPE.PROVIDER) {
      const { data } = yield getSAProviderReport({ endDate, startDate, adminId, tenantId, dateOptions, selectedTab, pageNo })
      yield put(getGameReportSuccess({ provider: data?.data?.result }))
    } else if (reportType === REPORT_TYPE.USER) {
      const { data } = yield getSAUserReport({ endDate, startDate, adminId, tenantId, dateOptions, selectedTab, pageNo })
      yield put(getGameReportSuccess({ user: data?.data?.result }))
    }

  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getGameReportFailure(e?.response?.data?.errors[0]?.description))
  }
}

function* getPlayerGameReportWorker(action) {
  try {
    const { isTenant, endDate, startDate, dateOptions, selectedTab, limit, userId } = action && action.payload

    const { data } = yield getSAPlayerGameReport({ endDate, startDate, dateOptions, selectedTab, limit, userId })

    yield put(getPlayerGameReportSuccess(data?.data?.gameReport))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getPlayerGameReportFailure(e?.response?.data?.errors[0]?.description))
  }
}

function* getElasticHealthWorker(action) {
  try {
    const { isTenant } = action && action.payload

    const { data } = yield getElasticHealth(isTenant)

    if (data?.data?.success) {
      yield put(getElasticHealthComplete(data?.data?.success))
    } else {
      yield toast(data?.data?.message, 'error')

      yield put(getElasticHealthFailure())
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getElasticHealthFailure(e?.response?.data?.errors[0]?.description))
  }
}

function* getAdminAffiliateUsersWorker(action) {
  try {
    const { data } = yield getAffiliateAdminUserList(action.payload)
    yield put(getAdminAffiliateUsersSuccess(data?.data))

  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getAdminAffiliateUsersFailure(e?.response?.data?.errors[0]?.description))
  }
}