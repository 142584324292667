import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { formatDateYMD } from '../../../utils/dateFormatter'
import { getAssociatedPlayerDetailsStart } from '../../../store/redux-slices/affiliates'
import FormatDate from '../../../utils/formatDate'
import { ACTIVE_LABEL, ACTIVE_VALUE, STATUS_LABEL } from '../../../utils/constant'
import classNames from 'classnames'
// import { safeStringify } from '../../../utils/helpers'

const useUserDetails = () => {
  const { associatedPlayerDetails, loading } = useSelector((state) => state.affiliate)

  const [selectedTab, setSelectedTab] = useState('overview')
  const { userId } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAssociatedPlayerDetailsStart({ userId }))
  }, [userId])



  const showStyle = (data) => (data ? 'text-success' : 'text-danger')
  const printData = (data) => (data ? 'Sim' : 'Não')

  const basicInfo = [
    { label: 'ID', value: userId },
    { label: 'Nome', value: `${associatedPlayerDetails?.firstName} ${associatedPlayerDetails?.lastName}` },

    { label: 'Usuário', value: associatedPlayerDetails?.username },
    { label: 'E-mail', value: associatedPlayerDetails?.email },
    { label: 'Data de nascimento', value: FormatDate.toDateOnly(associatedPlayerDetails?.dateOfBirth) },

    { label: 'Status', value: <span className="w-100" data-badge={ACTIVE_VALUE[associatedPlayerDetails?.isActive]}>{ACTIVE_LABEL[associatedPlayerDetails?.isActive]}</span> },
    { label: 'Email Verificado', value: printData(associatedPlayerDetails?.isEmailVerified), subValue: showStyle(associatedPlayerDetails?.isEmailVerified) },
    { label: 'Razão Inativa', value: associatedPlayerDetails?.defaultDisableReason || '-' },
    { label: 'Razão', value: !associatedPlayerDetails?.isActive ? associatedPlayerDetails?.reason : '' },
    {
      label: 'Tags',
      value: associatedPlayerDetails?.tags
        ? associatedPlayerDetails?.tags?.length < 1 ? 'N/A' : associatedPlayerDetails?.tags?.join(', ')
        : 'N/A'
    },
    { label: 'ID do candidato SumSub', value: associatedPlayerDetails?.applicantId },
    { label: 'Pontos de fidelidade', value: associatedPlayerDetails?.loyaltyPoints || 'N/A' },

  ]

  const kycInfo = [
    { label: 'KYC Método', value: associatedPlayerDetails?.kycMethod === 1 ? 'Sumsub' : 'System KYC' },
    { label: 'KYC Status', value: associatedPlayerDetails && <span className="w-100" data-badge={String(associatedPlayerDetails?.kycStatus).toLowerCase()}>{STATUS_LABEL[associatedPlayerDetails?.kycStatus]}</span> }
  ];

  const contactInfo = [
    { label: 'Telefone', value: associatedPlayerDetails?.phone },
    { label: 'Endereço', value: classNames(associatedPlayerDetails?.address, associatedPlayerDetails?.city, associatedPlayerDetails?.zipCode).split(" ").join(", ") },
    { label: 'Código do país', value: associatedPlayerDetails?.countryCode },
    { label: 'Notícias', value: printData(associatedPlayerDetails?.newsLetter), subValue: showStyle(associatedPlayerDetails?.newsLetter) },
    { label: 'SMS', value: printData(associatedPlayerDetails?.sms), subValue: showStyle(associatedPlayerDetails?.sms) }
  ];

  return {
    selectedTab,
    associatedPlayerDetails,
    loading,
    basicInfo,
    kycInfo,
    contactInfo,
    setSelectedTab,
  }
}

export default useUserDetails
