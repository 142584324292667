import React from 'react'
import {
  Row,
  Col
} from '@themesberg/react-bootstrap'

import useFormFields from './useFormFields'
import RegistrationFormFields from '../../../components/RegistrationFormField'

const FormFields = () => {
  const { loading, data, handleChange, updateFields } = useFormFields()

  return (
    <>
          <div className='section-header-wrap mt-n3 mx-n3'>
      <Row>
        <Col sm={8}>
          <h2 className='title-text'>Registration Fields</h2>
        </Col>
      </Row>
      </div>
      <RegistrationFormFields
        loading={loading}
        data={data}
        handleChange={handleChange}
        updateFields={updateFields}
        isTenant={false}
      />
    </>
  )
}

export default FormFields
