import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllSAProvidersStart } from '../../store/redux-slices/superAdminCasinoManagement'
import { getAllTAProvidersStart } from '../../store/redux-slices/tenantCasino'

const useAllProviderList = (isTenantRoute) => {
  const dispatch = useDispatch()
  const { allProviders } = useSelector((state) => isTenantRoute ? state.tenantCasino : state.superAdminCasino)

  useEffect(() => {
    if (!allProviders) {
      if (isTenantRoute) {
        dispatch(getAllTAProvidersStart())
      } else {
        dispatch(getAllSAProvidersStart())
      }
    }
  }, [])

  return {
    allProviders
  }
}

export default useAllProviderList
