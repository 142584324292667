import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAllUsersStart, clearAllUsers } from '../../../store/redux-slices/fetchData'
import { formatDateYMD, getDateDaysAgo } from '../../../utils/dateFormatter'
import useDidMountEffect from '../../../utils/useDidMountEffect'

const useAllUserListing = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isInitialRender = useDidMountEffect()

  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [searchByIp, setSearchByIp] = useState('')
  const [affiliateSearch, setAffiliateSearch] = useState('')
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedPortal, setSelectedPortal] = useState('')
  const [kycOptions, setKycOptions] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [activeUserId, setUserId] = useState('')
  const [orderBy, setOrderBy] = useState('userId')
  const [sortBy, setSortBy] = useState('desc')
  const [over, setOver] = useState(false)
  const [state, setState] = useState([
    {
      startDate: new Date('01-01-2023'),
      endDate: new Date(),
      key: 'selection'
    }
  ])

  const { userData, loading } = useSelector((state) => state.fetch)
  const totalPages = Math.ceil(userData?.count / limit)

  const selected = (h) =>
    orderBy === h.value &&
    (h.value === 'email' || h.value === 'userId' || h.value === 'username')

  const fetchData = () => {
    dispatch(
      getAllUsersStart({
        limit,
        pageNo: page,
        tenantId: selectedPortal,
        adminId: selectedClient,
        search: search.replace('+', '%2B'),
        ip: searchByIp,
        kycStatus: kycOptions,
        affiliateSearch,
        startDate: formatDateYMD(state.map(a => a.startDate)),
        endDate: formatDateYMD(state.map(a => a.endDate)),
        userId: activeUserId,
        phoneNumber,
        orderBy,
        sortBy
      })
    )
  }

  const resetFilter = () => {
    setSearch("");
    setAffiliateSearch("");
    setUserId("");
    setLimit(15);
    setPhoneNumber("");
    setPage(1);
    setOrderBy("userId");
    setSortBy("desc");
    setKycOptions("");
    setSearchByIp("");
    setState([
      {
        startDate: new Date('01-01-2023'),
        endDate: new Date(),
        key: "selection",
      },
    ]);
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          dispatch(
            getAllUsersStart({
              limit,
              pageNo: page,
              tenantId: selectedPortal,
              adminId: selectedClient,
              search: search.replace('+', '%2B'),
              ip: searchByIp,
              kycStatus: kycOptions,
              affiliateSearch,
              dobStart: formatDateYMD(state.map(a => a.startDate)),
              dobEnd: formatDateYMD(state.map(a => a.endDate)),
              userId: activeUserId,
              phoneNumber,
              orderBy,
              sortBy
            })
          )
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search, affiliateSearch, activeUserId, phoneNumber, searchByIp])

  useEffect(() => {
    !isInitialRender && fetchData()

    return () => dispatch(clearAllUsers())
  }, [page])

  useEffect(() => {
    setPage(1)
    fetchData()

    return () => dispatch(clearAllUsers())
  }, [limit, selectedPortal, kycOptions, selectedClient, state, orderBy, sortBy])

  return {
    limit,
    setLimit,
    page,
    setPage,
    search,
    setSearch,
    searchByIp,
    setSearchByIp,
    userData,
    totalPages,
    navigate,
    affiliateSearch,
    setAffiliateSearch,
    selectedClient,
    selectedPortal,
    setSelectedPortal,
    setSelectedClient,
    loading,
    kycOptions,
    setKycOptions,
    state,
    setState,
    activeUserId,
    phoneNumber,
    setUserId,
    setPhoneNumber,
    orderBy,
    setOrderBy,
    sortBy,
    setSortBy,
    over,
    setOver,
    selected,
    resetFilter
  }
}

export default useAllUserListing
