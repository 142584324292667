import React from 'react'
import { Tabs, Tab, Row } from '@themesberg/react-bootstrap'
import useProfilePage from './useProfilePage'
import { permissionLabel, profileConstants, profileConstantsTA, siteConfigConstants } from './constants'
import Preloader from '../Preloader'
import Hierarchy from '../Hierarchy'
import Overview from './Overview'
import Permission from './Permission'
import Credentials from './Credentials'
import Password from './Password'

export default ({ isTenant }) => {
  const {
    details,
    selectedTab,
    setSelectedTab,
    editable,
    setEditable,
    updateData,
    updateSiteConfiguration,
    loading,
    adminDetails,
    updateCredentials,
    editableCreds,
    setEditableCreds,
    resetPassLoading,
    isGetLoading,
    editableSiteConfig,
    setEditableSiteConfig,
    isHidden
  } = useProfilePage(isTenant)

  const constant = profileConstants
  const siteConstant = siteConfigConstants
  return (
    <>
      {loading
        ? <Preloader />
        : (
          <>
            <div className='section-header-wrap mt-n3 mx-n3'>
              <h2 className='title-text'>Perfil</h2>
            </div>

            <Tabs
              activeKey={selectedTab}
              onSelect={(tab) => {
                setEditable(false)
                setEditableCreds(false)
                setSelectedTab(tab)
              }}
              mountOnEnter
              unmountOnExit
              className='nav-light'
            >
              <Tab eventKey='overview' title='Visão Geral'>
                <div className='mt-3'>
                  <Overview
                    details={details}
                    isTenant={isTenant}
                    setEditable={setEditable}
                    editable={editable}
                    updateData={updateData}
                    constant={constant}
                    loading={loading}
                    isGetLoading={isGetLoading}
                  />
                </div>
              </Tab>

              <Tab eventKey='password' title='Resetar Senha'>
                <div className='mt-3'>
                  <Password
                    isTenant={isTenant}
                    loading={resetPassLoading}
                  />
                </div>
              </Tab>

              {adminDetails?.superRoleId === 1 &&
                <Tab eventKey='credentials' title='Credenciais'>
                  <div className='mt-3'>
                    <Credentials
                      details={details}
                      updateCreds={updateCredentials}
                      loading={loading}
                      editableCreds={editableCreds}
                      setEditableCreds={setEditableCreds}
                    />
                  </div>
                </Tab>}

              <Tab eventKey='permissions' title='Permissões'>
                <div className='mt-3'>
                  <Permission details={details} permissionLabel={permissionLabel} />
                </div>
              </Tab>

              {!isTenant &&
                <Tab eventKey='usersTree' title='Àrvore de afiliados'>
                  <div className='mt-3'>
                    <Row className='mt-3 d-flex flex-row-reverse text-right'>
                      {details && details?.adminUserId && (
                        <Hierarchy
                          isTenant={isTenant}
                          adminDetails={{
                            name: `${details?.firstName} ${details?.lastName}`,
                            id: details?.adminUserId,
                            children: [],
                            isInitial: true,
                            data: { superRoleId: details?.adminRoleId }
                          }}
                        />
                      )}
                    </Row>
                  </div>
                </Tab>}
            </Tabs>
          </>)}
    </>
  )
}
