import React, { useState } from 'react';
import { uploadImageStart } from '../../../store/redux-slices/sportsBook';
import { Container, Form, Button, Image as Img, Row, Col } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { BigSize } from './style';

const ImageUploader = ({ sportId, countryId, code }) => {

  const dispatch = useDispatch()
  const [uploadedFile, setUploadedFile] = useState(null);
  const [error, setError] = useState('');

  const validateImageSize = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const image = new Image();

    image.onload = function () {
      if (image.width === 30 && image.height === 30) {
        setUploadedFile(file);
        setError('');
      } else {
        setError('File size must be 30x30 pixels.');
      }
    };

    image.src = URL.createObjectURL(file);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (uploadedFile) {
      const fd = new FormData();
      fd.append("image", uploadedFile);
      fd.append("code", code);
      sportId && fd.append("sportId", sportId);
      countryId && fd.append("countryId", countryId);
      dispatch(uploadImageStart(fd))
    } else {
      console.error('Image validation failed.');
    }
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={6}>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Upload an Image (30x30 pixels)</Form.Label>
              <Dropzone
                onDrop={(acceptedFiles) => validateImageSize(acceptedFiles)}
                accept="image/*"
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    className="dropzone"
                    style={{
                      border: '2px dashed #ccc',
                      padding: '20px',
                      textAlign: 'center',
                    }}
                  >
                    <input {...getInputProps()} />
                    <BigSize>
                      <FontAwesomeIcon className='2xl' icon={faCloudUploadAlt} beat size="2xl" />
                    </BigSize>
                    <p>Drag & drop an image here, or click to select one.</p>
                  </div>
                )}
              </Dropzone>
            </Form.Group>
            {error && <p className="text-danger">{error}</p>}
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
      <Row className="justify-content-center mt-4">
        {uploadedFile && (
          <div className="text-center">
            <Img src={URL.createObjectURL(uploadedFile)} thumbnail />
          </div>
        )}
      </Row>
    </Container>
  );
};

export default ImageUploader;
