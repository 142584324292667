import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { Form } from "react-bootstrap";
import { InputNumberFormat, useNumberFormat } from "@react-input/number-format";

export const PercentInput = forwardRef(({ currency = "BRL", ...props }, ref) => {
  const inputRef = useRef();

  const locales = {
    BRL: 'pt-BR',
    USD: 'en-UR',
  }

  const config = {
    locales: locales[currency],
    format: "percent",
    currency,
    maximumFractionDigits: 2,
  };

  const clear = () => {
    inputRef.current.value = ""
  }

  useImperativeHandle(ref, () => ({ clear }))

  return (<InputNumberFormat component={Form.Control} ref={inputRef} {...props} {...config} />)
})

export default PercentInput;