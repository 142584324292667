import * as Yup from 'yup'

export const createLoyaltyRuleSchema = () => Yup.object().shape({
  loyaltyPoint: Yup.number()
  .min(1, 'Loyalty Points should be of more than 0')
  .max(10000, 'Loyalty Points should be of less than 10000'),
  loyaltyCoins: Yup.number()
  .min(1, 'Loyalty Coins should be of more than 0')
  .max(10000, 'Loyalty Points should be less than 10000')
  .required('Code cannot be Empty'),
 
})


export const setMinimumBetAmountForLoyaltySchema = () => Yup.object().shape({
  minimumBetAmount: Yup.number()
  .min(1, 'Minimum Bet Amount should be of more than 0')
  .max(10000, 'Minimum Bet Amount should be of more than 10000')
  .required('Minimum be amount cannot  be Empty'),
})