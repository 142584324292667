import React from 'react'
import {
  Button,
  Row,
  Col,
  Table,
  Card
} from '@themesberg/react-bootstrap'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import '../../pages/Tenant/GameReorder/GameReorder.scss'
import useBonusReorder from './useBonusReorder'
// import ClientFilter from '../ClientFilter'
// import PortalFilter from '../PortalFilter'
import useCheckPermission from '../../utils/checkPermission'

export default ({ isTenant = false }) => {
  const {
    loading,
    reOrderedBonus,
    onDragEnd,
    handleSave,
    handleRemoveBonus,
    handleAddBonus,
    // selectedClient,
    setSelectedClient,
    // selectedPortal,
    setSelectedPortal,
    bonuses
  } = useBonusReorder(isTenant)

  const { isHidden } = useCheckPermission()

  return (
    <>
    <div className='section-header-wrap mt-n3 mx-n3'>
    <Row>
        <Col sm={8}>
          <h3>Bonus Reorder</h3>
        </Col>
        <Col>
          <div className='text-right mb-3'>
            <Button
              hidden={isHidden({ module: { key: 'Bonus', value: 'U' } })}
              onClick={() => handleSave()}
              disabled={reOrderedBonus?.count === 0}
            >
              Update
            </Button>
          </div>
        </Col>
      </Row>
    </div>
    
      <div className='game-reordering-container'>
        <Row>
          <Col xs={12} sm={6}>
              <Card className='p-2 game-reordering-subcontainer'>
              {/* {!isTenant &&
                <Row>
                  <div className='d-flex align-items-center'>
                    <ClientFilter
                      setSelectedClient={setSelectedClient}
                      setSelectedPortal={setSelectedPortal}
                      selectedClient={selectedClient}
                      hasAllOptions={false}
                    />

                    <PortalFilter
                      setSelectedPortal={setSelectedPortal}
                      selectedPortal={selectedPortal}
                      selectedClient={selectedClient}
                      cms={selectedClient === ''}
                      hasAllOptions={false}
                    />

                  </div>
                </Row>} */}
              <div style={{ overflow: 'auto' }}>
                    <>
                      <Table  striped hover size='sm' className='text-center mt-4'>
                        <thead className='thead-light'>
                          <tr>
                            <th>Order ID</th>
                            <th>Bonus Name (ID)</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody id={loading ? 'table-spinner' : ''}>
                          {!loading && bonuses?.count !== 0 && bonuses?.rows?.map((item, idx) => {
                            return (
                              <tr key={idx}>
                                <td>{idx + 1}</td>
                                <td>{item.promotionTitle?.EN} ({item.bonusId})</td>
                                <td>
                                  <Button
                                    className='m-1'
                                    size='sm'
                                    onClick={() => handleAddBonus(item)}
                                  >+
                                  </Button>
                                </td>
                              </tr>
                            )
                          })}

                          {(bonuses?.count === 0 || !bonuses) && !loading && (
                            <tr><td className='text-danger' colSpan={10}>Registros não encontrados.</td></tr>
                          )}
                        </tbody>

                      </Table>
                    </>
                    
              </div>
            </Card>
          </Col>
          <Col xs={12} sm={6}>
          <Card className='p-2 game-reordering-subcontainer'>
          {reOrderedBonus?.count !== 0
            ? (
              <div className='game-reorder'>
                <div className='game-reorder-heading'>
                  {[
                    'ID',
                    'Bonus Name',
                    'Action'
                  ].map((h, idx) => (
                    <p className={`game-heading-${idx}`} key={h}>{h}</p>
                  ))}
                </div>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId='list'>
                    {provided => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {reOrderedBonus?.rows?.map(
                          (item, idx) => (
                            <Draggable draggableId={`id-${idx}`} key={idx} index={idx}>
                              {provided => (
                                <div
                                  className='game-reorder-content'
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <p className='game-id'>{idx + 1}</p>
                                  <p className='game-name'>{item.promotionTitle?.EN}</p>
                                  <Button
                                    className='m-1 game-button'
                                    size='sm'
                                    variant='danger'
                                    onClick={() => handleRemoveBonus(item)}
                                  >X
                                  </Button>
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
              )
            : <p className='text-danger text-center mt-7'>Bonus Not Selected</p>}
        </Card>
          </Col>
        </Row>
     
      

      </div>

    </>
  )
}
