import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getAdminAffiliateUsersStart,
  getElasticHealthStart,
} from "../../../../store/redux-slices/dashboard";
import {
  getAffiliateCommissionReportStart,
  getAffiliateCommissionLiveReportStart,
  getSubAffiliateCommissionReportStart,
  getAffiliateDashboardDataStart,
  getAffiliateProductsReportStart,
  getAffiliateTopPlayersStart,
  getDashboardTotalReportsStart,
  getChartReportStart,
  getSubAffiliateLiveCommissionReportStart,
} from "../../../../store/redux-slices/affiliates";
import {
  getAllCasinoProvidersStart,
  getSuperAdminGameSubCategoryStart,
} from "../../../../store/redux-slices/superAdminCasinoManagement";
import { format, subDays, startOfMonth } from "date-fns";
import { getDateDaysAgo } from "../../../../utils/dateFormatter";

const useDashboard = () => {
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedPortal, setSelectedPortal] = useState("");
  const { adminPermissions, loading, adminDetails } = useSelector(
    (state) => state.admins
  );
  const { elasticHealth, adminAffiliateList } = useSelector(
    (state) => state.dashboard
  );
  const { userId, affiliateId } = useParams();

  const reportsToShow = [
    "GameReport",
    "KpiReport",
    "DemographReport",
    "PlayerManagementReport",
    "KpiSummaryReport",
    "LivePlayerReport",
    "PlayerLiabilityReport",
  ];
  const permissionKeys = adminPermissions && Object.keys(adminPermissions);
  const dispatch = useDispatch();
  const nonElasticReports = [
    "DemographReport",
    "LivePlayerReport",
    "PlayerManagementReport",
  ];
  const [dateRangePickerState, setDateRangePickerState] = useState([
    {
      startDate: getDateDaysAgo(0),
      endDate: getDateDaysAgo(0),
      key: "selection",
    },
  ]);
  const [dateFilterPayload, setDateFilterPayload] = useState({
    customStartDate: format(new Date(), "yyyy-MM-dd"),
    customEndDate: format(new Date(), "yyyy-MM-dd"),
  });

  const isFirstRender = useRef(true);
  const handleDateRangeReset = () => {
    setDateRangePickerState([
      {
        startDate: getDateDaysAgo(0),
        endDate: getDateDaysAgo(0),
        key: "selection",
      },
    ]);
  };

  useEffect(() => {
    let TYPE = "yesterday";
    const paylod = {
      customStartDate: format(subDays(new Date(), 1), "yyyy-MM-dd"),
      customEndDate: format(subDays(new Date(), 1), "yyyy-MM-dd"),
      type: TYPE,
      reportOf: "both",
      ...(affiliateId ? { affiliateId } : {})
    };

    dispatch(getAffiliateDashboardDataStart({ ...(affiliateId ? { affiliateId } : {}) }));
    dispatch(getAffiliateCommissionReportStart(paylod));
    dispatch(
      getAffiliateCommissionLiveReportStart({
        ...(affiliateId ? { affiliateId } : {}),
      })
    );

    dispatch(
      getSubAffiliateCommissionReportStart({
        ...(affiliateId ? { affiliateId } : {}),
      })
    );
    dispatch(
      getSubAffiliateLiveCommissionReportStart({
        ...(affiliateId ? { affiliateId } : {}),
      })
    );

    dispatch(getChartReportStart({ ...(affiliateId ? { affiliateId } : {}) }));

    TYPE = "currentMonth";
    paylod.customStartDate = format(startOfMonth(new Date()), "yyyy-MM-dd");
    paylod.customEndDate = format(new Date(), "yyyy-MM-dd");
    paylod.type = TYPE;
    dispatch(getAffiliateCommissionReportStart(paylod));
    dispatch(
      getSuperAdminGameSubCategoryStart({
        limit: 1000,
        pageNo: 1,
        gameCategoryId: "",
        isActive: true,
        search: "",
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      getDashboardTotalReportsStart({
        ...dateFilterPayload,
        gameSubCategoryId: "",
        reportOf: "casino",
        ...(affiliateId ? { affiliateId } : {})
      })
    );
    dispatch(
      getDashboardTotalReportsStart({
        ...dateFilterPayload,
        gameSubCategoryId: "",
        reportOf: "sports",
        ...(affiliateId ? { affiliateId } : {})
      })
    );
    dispatch(getAffiliateTopPlayersStart({ ...dateFilterPayload }));
    dispatch(getAffiliateProductsReportStart({ ...dateFilterPayload }));
  }, [dateFilterPayload]);

  useEffect(() => {
    const { startDate, endDate } = dateRangePickerState[0];

    if (!isFirstRender.current) {
      setDateFilterPayload({
        customStartDate: format(startDate, "yyyy-MM-dd"),
        customEndDate: format(endDate, "yyyy-MM-dd"),
      });
    }
    isFirstRender.current = false;
  }, [dateRangePickerState]);

  const getSubCategory = (subCategory) => {
    if (subCategory === "all") subCategory = "";
    dispatch(
      getDashboardTotalReportsStart({
        ...dateFilterPayload,
        gameSubCategoryId: subCategory || "",
        reportOf: "casino",
      })
    );
  };
  return {
    selectedClient,
    setSelectedClient,
    selectedPortal,
    setSelectedPortal,
    reportsToShow,
    permissionKeys,
    loading,
    adminPermissions,
    elasticHealth,
    nonElasticReports,
    adminAffiliateList,
    dateRangePickerState,
    setDateRangePickerState,
    getSubCategory,
    handleDateRangeReset,
    adminDetails,
  };
};

export default useDashboard;

/**
 * @typedef {{
*  topPlayers: TopPlayers
*  newRegistredPlayers: number
*  allPlayers: number
*  totalDepositCounts: number
*  totalWithdrawCounts: number
*  yesterday: Yesterday
*  currentMonth: CurrentMonth
*  liveComission: LiveCommission
*  sportsReport: SportsReport
*  chart: Chart
* }} AffiliateDashboard
*
* @typedef {{
*  playerDetails: any[];
* }} TopPlayers
*
* @typedef {{
*  gameReport: GameReport;
* }} Yesterday
*
* @typedef {{
*  gameReport: GameReport;
* }} CurrentMonth
*
* @typedef {{
*  gameReport: GameReport;
* }} SportsReport
*
* @typedef {{
*  betAmount: number
*  betCount: number
*  winAmount: number
*  winCount: number
*  ggr: number
*  commission: number
* }} GameReport
*
* @typedef {{
*  gameReportsByMonthLive: {
*   month: string;
*   gameReport: GameReport;
*   totalCommissionByMonth: number;
*  }[]
*  liveTotalEarnings: number
* }} LiveCommission

* @typedef {{
  records: any[];
  deposit: any[];
}} Chart
*/
