import React from 'react';
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
// import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded'
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import BlockIcon from '@mui/icons-material/Block';

import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
// import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded'
import {
    Button,
  } from '@themesberg/react-bootstrap'
const ActionMenu = (props) => {
    const { handleShow, isHidden, isActive,parentId, name, handleStatusShow, casinoGamesData, casinoGameId, indx, handleDeleteItems, navigate } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl)
    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    return (
        <>
        {(!isHidden({ module: { key: 'CasinoManagement', value: 'U' } }) || 
        !isHidden({ module: { key: 'CasinoManagement', value: 'T' } }))
        ? (
        <>
            <Button
                variant='btn text-btn'
                id='basic-button'
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
                }}
            >
                <MoreVertRoundedIcon />
            </Button>
            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button'
                }}
            >
                <MenuItem onClick={handleClose}>
                    <Button
                        variant='btn text-btn'
                        onClick={() =>
                            handleShow(
                            'Edit',
                            casinoGamesData.rows[indx],
                            )
                        }
                        hidden={isHidden({
                            module: {
                            key: 'CasinoManagement',
                            value: 'U',
                            },
                        })}
                        >
                        <BorderColorRoundedIcon />
                        <span className='text'>Edit</span>
                    </Button>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    
                    <Button
                        variant='btn text-btn'
                        onClick={() =>
                        handleStatusShow(casinoGameId, isActive, name)}
                        hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                    >
                        {
                            !isActive ? 
                                <>
                                    <CheckCircleRoundedIcon />
                                    <span className='text'>
Definir status ativo</span>
                                </>
                                :
                                <>
                                    <CancelRoundedIcon />
                                    <span className='text'>Definir status inativo</span>
                                </>
                        }                    
                    </Button>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Button
                        variant='btn text-btn'
                        disabled={parentId?false:true}
                        hidden={isHidden({
                            module: {
                            key: 'CasinoManagement',
                            value: 'U',
                            },
                        })}
                        onClick={() => handleDeleteItems(casinoGameId)}
                        >
                        <DeleteForeverOutlinedIcon />
                        <span className='text'>Delete</span>
                    </Button>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Button
                       variant='btn text-btn'
                        hidden={isHidden({ module: { key: 'CasinoManagement', value: 'U' } })}
                        onClick={() => navigate(`/admin/casino-games/restrict-countries/${casinoGameId}`)}
                    >
                        <BlockIcon />
                        <span className='text'>Ver países bloqueados</span>
                    </Button>
                </MenuItem>
            </Menu></>) : 'NA' }
        </>
    )
};

export default ActionMenu;