import React from 'react'
import { Tabs, Tab, Row, Col, Button, Modal, Form, Table } from '@themesberg/react-bootstrap'
import Overview from './components/Overview'
import UserWallet from './components/UserWallet'
import useUserDetails from './useUserDetails'
import Preloader from '../../../components/Preloader'
import CasinoTransactions from './components/CasinoTransactions'
import TransactionBanking from './components/TransactionBanking'
import KYCDocuments from './components/KYCDocuments'
import GameReport from './components/GameReport'
import useCheckPermission from '../../../utils/checkPermission'
import YourBonuses from './components/YourBonuses'
import FreeSpinBonusModal from '../../../components/FreeSpinBonusModal/FreeSpinBonusModal'
import ManageMoney from '../../../components/ManageMoney'
import Comments from './components/Comments'
import DisableReason from './components/DisableReason'
import ConfirmationModal, { DuplicateUserModal, ResetPasswordEmailModal, ResetUserPasswordModal, VerifyEmailModal } from '../../../components/ConfirmationModal'
import BonusDetailModal from './components/BonusDetailModal'
import EditUserModal from './components/EditUserModal'
import TagModal from '../../Tenant/UserDetails/components/TagModal'
import SportsTransactions from '../SportsTransactions'
import ResponsibleGaming from '../../../components/ResponsibleGaming'
import { SendSomeLoyaltyPointsUserModal } from '../../../components/ConfirmationModal/index';
import SendUserDocumentModal from './components/KYCDocuments/SendUserDocumentModal'
import classNames from 'classnames'

const UserDetails = (props) => {
  const {
    selectedTab,
    bonusList,
    show,
    userData,
    loading,
    userDocuments,
    documentLabels,
    status,
    showReasonModal,
    enable,
    docLabels,
    title,
    imagePreviewModalShow,
    imageUrl,
    showModal,
    selectedBonus,
    bonusDetail,
    bonusAmount,
    basicInfo,
    kycInfo,
    rolloverInfo,
    contactInfo,
    freespinModal,
    showManageMoneyModal,
    showRemoveAffiliate,
    setShowRemoveAffiliate,
    updateUserAffiliateLoading,
    addUserAffiliateLoading,
    showAddAffiliate,
    labelName,
    addAffiliateHandler,
    setShowAddAffiliate,
    removeAffiliateHandler,
    setBonusAmount,
    issueBonusHandler,
    setSelectedBonus,
    setShowModal,
    setSelectedTab,
    updateDocument,
    handleVerify,
    setShow,
    handleYes,
    handleClose,
    setEnable,
    handleReRequest,
    setImagePreviewModalShow,
    setShowManageMoneyModal,
    handleImagePreview,
    setImageUrl,
    setFreeSpinModal,
    setIsInternalModal,
    isInternalModal,
    internalPlayerHandler,
    name,
    navigate,
    isActiveInactivemodalVisible,
    setActiveInactiveModal,
    updateUserStatus,
    verifyEmailModal,
    setVerifyEmailModal,
    handleVerifyEmail,
    setPasswordEmailModal,
    setUserPasswordModal,
    userPasswordModal,
    passwordEmailModal,
    handleResetPasswordEmail,
    handleResetUserPassword,
    editUserModal,
    setEditUserModal,
    limit,
    pageNo,
    setLimit,
    setPageNo,
    showTagModal,
    showDuplicateModal,
    setShowTagModal,
    setShowDuplicateModal,
    updateTags,
    tagOptions,
    setTagOptions,
    selectedTag,
    setSelectedTag,
    addTag,
    getDuplicateUsers,
    duplicateUsers,
    bonusLoading,
    setBonusLoading,
    sendLoyaltyPointsToUserModal,
    setSendLoyaltyPointsToUserModal,
    handleAddLoyaltyPointsToUser,
    handleLogoutUser,
    logoutUserLoading,
    getUserDocuments,
  } = useUserDetails()
  const { isHidden } = useCheckPermission();

  return (
    <>
      {loading
        ? (
          <Preloader />
        )
        : (
          <>
            <div className='section-header-wrap mt-n3 mx-n3'>
              <Row>
                <Col lg={8} xs={12}>
                  <div className='d-flex align-items-center'>
                    <h2 className='title-text mb-2'>Usuário:&nbsp;</h2>
                    <h5 className='mb-0'>
                      {classNames(userData?.firstName, userData?.lastName)}
                    </h5>
                  </div>
                </Col>
              </Row>
            </div>
            <Tabs
              activeKey={selectedTab}
              onSelect={(tab) => setSelectedTab(tab)}
              className='nav-light'
              mountOnEnter
              unmountOnExit
            >
              <Tab eventKey='overview' title='Overview'>
                <div className='mt-3'>
                  <Overview
                    basicInfo={basicInfo}
                    kycInfo={kycInfo}
                    rolloverInfo={rolloverInfo}
                    contactInfo={contactInfo}
                    userLimits={userData?.userLimit}
                    user={userData}
                    setActiveInactiveModal={setActiveInactiveModal}
                    setIsInternalModal={setIsInternalModal}
                    setVerifyEmailModal={setVerifyEmailModal}
                    setShowModal={setShowModal}
                    setFreeSpinModal={setFreeSpinModal}
                    setShowManageMoneyModal={setShowManageMoneyModal}
                    setShowAddAffiliate={setShowAddAffiliate}
                    addUserAffiliateLoading={addUserAffiliateLoading}
                    setShowRemoveAffiliate={setShowRemoveAffiliate}
                    updateUserAffiliateLoading={updateUserAffiliateLoading}
                    isHidden={isHidden}
                    setPasswordEmail={setPasswordEmailModal}
                    setUserPassword={setUserPasswordModal}
                    setEditModal={setEditUserModal}
                    addTag={addTag}
                    getDuplicateUsers={getDuplicateUsers}
                    duplicateUsers={duplicateUsers}
                    sendLoyaltyPointsToUserModal={sendLoyaltyPointsToUserModal}
                    setSendLoyaltyPointsToUserModal={setSendLoyaltyPointsToUserModal}
                    handleLogoutUser={handleLogoutUser}
                    logoutUserLoading={logoutUserLoading}
                  />
                </div>
              </Tab>
              <Tab eventKey='wallet' title='Carteira'>
                <div className='mt-3 d-fle text-right'>
                  <UserWallet myUserData={userData} />
                </div>
              </Tab>

              {!isHidden({ module: { key: 'Transactions', value: 'R' } }) &&
                <Tab eventKey='casino-transactions' title='Histórico de apostas'>
                  <div className='mt-3 m-0'>
                    <CasinoTransactions />
                  </div>
                </Tab>}

              {!isHidden({ module: { key: 'Transactions', value: 'R' } }) &&
                <Tab eventKey='Betting-transactions' title='Histórico de apostas esportivas'>
                  <div className='mt-3 m-0 '>
                    <SportsTransactions />
                  </div>
                </Tab>}

              {!isHidden({ module: { key: 'Transactions', value: 'R' } }) &&
                <Tab eventKey='transactions-banking' title='Transações'>
                  <div className='mt-3 m-0'>
                    <TransactionBanking />
                  </div>
                </Tab>}

              <Tab eventKey='kyc-documents' title='Documentos KYC'>
                <div className='mt-3 m-0 '>
                  {userData &&
                    <KYCDocuments
                      userDocuments={userDocuments}
                      documentLabels={documentLabels}
                      updateDocument={updateDocument}
                      handleVerify={handleVerify}
                      show={show}
                      setShow={setShow}
                      handleYes={handleYes}
                      status={status}
                      showReasonModal={showReasonModal}
                      handleClose={handleClose}
                      enable={enable}
                      setEnable={setEnable}
                      docLabels={docLabels}
                      handleReRequest={handleReRequest}
                      title={title}
                      imagePreviewModalShow={imagePreviewModalShow}
                      setImagePreviewModalShow={setImagePreviewModalShow}
                      handleImagePreview={handleImagePreview}
                      imageUrl={imageUrl}
                      setImageUrl={setImageUrl}
                      name={name}
                      labelName={labelName}
                      userDetails={userData}
                      getUserDocuments={getUserDocuments}
                    />}
                </div>
              </Tab>

            </Tabs>
          </>)}
      {/* )} */}
      <Modal size='xl' show={showModal}>
        <Modal.Header className='justify-content-center'>
          <h4>Give Bonus to '{userData?.firstName} {userData?.lastName}'</h4>
        </Modal.Header>
        <Modal.Body>
          {bonusList?.length > 0 &&
            <Row>
              <Col style={{ maxWidth: '35%' }}>
                <p style={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>AVAILABLE BONUSES</p>
                <div style={{ maxHeight: '500px', overflow: 'auto' }}>
                  <Table>
                    <tbody>
                      {bonusList?.length > 0 && bonusList?.map((item, i) => {
                        return (
                          <tr key={`player-modal-bonus-history ${i}`}>
                            <td>
                              <Form.Check
                                readOnly
                                type='checkbox'
                                label={item?.promotionTitle?.EN}
                                checked={item?.bonusId === selectedBonus?.bonusId}
                                onClick={() => {
                                  setBonusLoading(true)
                                  setSelectedBonus(item)
                                }}
                              />
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col style={{ maxWidth: '65%' }}>
                <p className='text-center' style={{ fontSize: '14px', fontWeight: 'bold' }}>BONUS DETAILS</p>
                <BonusDetailModal
                  bonusAmount={bonusAmount}
                  bonusDetail={bonusDetail}
                  setBonusAmount={setBonusAmount}
                  bonusLoading={bonusLoading}
                />
              </Col>
            </Row>}
          {bonusList?.length === 0 &&
            <div className='text-danger text-center'>Nenhum bônus encontrado</div>}
        </Modal.Body>
        <Modal.Footer className={`d-flex justify-content-${bonusList?.length > 0 ? 'between' : 'center'}`}>
          <Button
            className='m-2'
            variant='outline-warning'
            onClick={() => {
              setShowModal(false)
              setBonusAmount('')
            }}
          >
            Cancelar
          </Button>
          {bonusList?.length > 0 &&
            <Button
              className='m-2'
              variant='outline-success'
              style={{ width: '75px' }}
              onClick={issueBonusHandler}
            >
              Adicionar
            </Button>}
        </Modal.Footer>
      </Modal>

      <Modal show={isInternalModal}>
        <Modal.Header>
          <h4>Marcar '{userData?.firstName} {userData?.lastName}' como interno?</h4>
        </Modal.Header>
        <Modal.Body>
          <p>Do you really want to mark <strong>{userData?.firstName} {userData?.lastName}</strong> as Internal?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='m-2'
            variant='outline-warning'
            onClick={() => {
              setIsInternalModal(false)
            }}
          >
            Não
          </Button>
          <Button
            className='m-2'
            variant='outline-success'
            style={{ width: '75px' }}
            onClick={internalPlayerHandler}
          >
            Sim
          </Button>
        </Modal.Footer>
      </Modal>

      {freespinModal &&
        <FreeSpinBonusModal
          show={freespinModal}
          setShow={setFreeSpinModal}
          userData={userData}
        />}

      <ManageMoney
        show={showManageMoneyModal}
        handleClose={setShowManageMoneyModal}
        user={userData}
      />

      <Modal show={showAddAffiliate}>
        <Modal.Header className='d-flex justify-content-center'>
          <h4>Add '{userData?.firstName} {userData?.lastName}' as Affiliate</h4>
        </Modal.Header>
        <Modal.Body className='d-flex justify-content-center'>
          <p>Do you really want to link <strong>{userData?.firstName} {userData?.lastName}</strong> as Affiliate using token <strong>'{userData?.trackingToken}'</strong> ?</p>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-between'>
          <Button
            className='m-2'
            variant='outline-warning'
            onClick={() => {
              setShowAddAffiliate(false)
            }}
          >
            No
          </Button>
          <Button
            className='m-2'
            variant='outline-success'
            style={{ width: '75px' }}
            onClick={addAffiliateHandler}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showRemoveAffiliate}>
        <Modal.Header className='d-flex justify-content-center'>
          <h4>Remove '{userData?.firstName} {userData?.lastName}' from Affiliate</h4>
        </Modal.Header>
        <Modal.Body className='d-flex justify-content-center'>
          <p className='text-center'>Do you really want to remove <strong>{userData?.firstName} {userData?.lastName}</strong> from Affiliate(<strong>{userData?.affiliate?.username}</strong>) (<strong>{userData?.trackingToken}</strong>) ?</p>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-between'>
          <Button
            className='m-2'
            variant='outline-warning'
            onClick={() => {
              setShowRemoveAffiliate(prev => false)
            }}
          >
            No
          </Button>
          <Button
            className='m-2'
            variant='outline-success'
            style={{ width: '75px' }}
            onClick={() => {
              setShowRemoveAffiliate(prev => false)
              removeAffiliateHandler()
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {userData?.isActive
        ? <DisableReason
          show={isActiveInactivemodalVisible}
          handleClose={() => setActiveInactiveModal(false)}
          markUserStatusInactive={updateUserStatus}
          name={`${userData?.firstName} ${userData?.lastName} (${userData?.email})`}
        />
        : <ConfirmationModal
          show={isActiveInactivemodalVisible}
          setShow={setActiveInactiveModal}
          handleYes={() => {
            updateUserStatus()
            setActiveInactiveModal(false)
          }}
          active={userData?.isActive}
          name={`${userData?.firstName} ${userData?.lastName} (${userData?.email})`}
        />}

      {verifyEmailModal &&
        <VerifyEmailModal
          show={verifyEmailModal}
          setShow={setVerifyEmailModal}
          userData={userData}
          handleVerifyEmail={handleVerifyEmail}
        />}

      {passwordEmailModal &&
        <ResetPasswordEmailModal
          show={passwordEmailModal}
          setShow={setPasswordEmailModal}
          userData={userData}
          handleResetPasswordEmail={handleResetPasswordEmail}
        />}

      {showTagModal &&
        <TagModal
          show={showTagModal}
          handleClose={setShowTagModal}
          updateTags={updateTags}
          tagOptions={tagOptions}
          setTagOptions={setTagOptions}
          selectedTag={selectedTag}
          setSelectedTag={setSelectedTag}
          name={`${userData?.firstName} ${userData?.lastName}`}
        />}

      {showDuplicateModal &&
        <DuplicateUserModal
          show={showDuplicateModal}
          setShow={setShowDuplicateModal}
          limit={limit}
          pageNo={pageNo}
          setLimit={setLimit}
          setPageNo={setPageNo}
        />}

      {userPasswordModal &&
        <ResetUserPasswordModal
          show={userPasswordModal}
          setShow={setUserPasswordModal}
          userData={userData}
          handleResetUserPassword={handleResetUserPassword}
        />}

      {editUserModal &&
        <EditUserModal
          show={editUserModal}
          setShow={setEditUserModal}
          userData={userData}
        />}


      {sendLoyaltyPointsToUserModal &&
        <SendSomeLoyaltyPointsUserModal show={sendLoyaltyPointsToUserModal} setSendLoyaltyPointsToUserModal={setSendLoyaltyPointsToUserModal} handleSendSomeLoyaltyPointsUser={handleAddLoyaltyPointsToUser} loading={false}>
        </SendSomeLoyaltyPointsUserModal>}
    </>
  )
}

export default UserDetails
