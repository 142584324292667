import React from 'react'
import {
  Button,
  Form,
  Row,
  Col,
  Table
} from '@themesberg/react-bootstrap'
import { motion } from 'framer-motion/dist/framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowCircleUp,
  faArrowCircleDown,
  faRedoAlt
} from '@fortawesome/free-solid-svg-icons'
import ConfirmationModal from '../../../components/ConfirmationModal'
import PaginationComponent from '../../../components/Pagination'
import { tableHeaders } from './constants'
import useAdminListing from './hooks/useAdminListing'
// import leafImage from '../../../assets/img/leaf.png'
import Trigger from '../../../components/OverlayTrigger'
import { AdminsRoutes } from '../../../routes'
import useCheckPermission from '../../../utils/checkPermission'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded'
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded'
import { InputGroup } from 'react-bootstrap'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import ActionMenu from './ActionMenu'

export default () => {
  const {
    navigate,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
    sort,
    setSort,
    setSearch,
    search,
    show,
    setShow,
    over,
    setOver,
    data,
    totalPages,
    getRole,
    handleShow,
    handleYes,
    selected,
    active,
    name,
    loading,
    status,
    setStatus
  } = useAdminListing()

  const { isHidden } = useCheckPermission()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <div className='section-header-wrap mt-n3 mx-n3'>
        <Row>
          <Col lg={8} xs={7}>
            <h2 className='title-text'>Staff</h2>
          </Col>
          <Col lg={4} xs={5}>
            {/* Button to create new admin */}
            <div className='d-flex justify-content-end'>
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Button
                  variant='btn btn-primary w-100'
                  onClick={() => navigate(AdminsRoutes.CreateSuperAdminUser)}
                  hidden={isHidden({ module: { key: 'Admins', value: 'C' } })}
                  style={{ maxWidth: '140px' }}
                >
                  <AddCircleOutlineRoundedIcon className='me-2' />
                  <span>Criar</span>
                </Button>
                </motion.div>
              </div>
            </Col>
          </Row>
        </div>
      <div className='FormsBg'>
        <Row>
          <Col xs={5} md={3}>
            <Form.Select
              onChange={(e) => { setStatus(e.target.value) }}
              value={status}
            >
              <option value=''>Status</option>
              <option value='true'>Ativos</option>
              <option value='false'>Inativo</option>
            </Form.Select>

          </Col>

          <Col xs={7} md={3}>
            <InputGroup>
              <InputGroup.Text className='input-addon icon-first'>
                <SearchRoundedIcon />
              </InputGroup.Text>
              <Form.Control
                type='search'
                placeholder='Email, Nome, Grupo'
                value={search}
                onChange={(event) => {
                  const mySearch = event.target.value.replace(/[^\w\s+@.]/gi, '')
                  setSearch(mySearch)
                }}
              />
            </InputGroup>
          </Col>
          <Col xs={1} >
            <div className='d-flex justify-content-end align-item-center'>
              <Trigger message='Reset Filters'>
                <Button
                  size='sm'
                  onClick={() => {
                    setStatus('')
                    setSearch('')
                  }}
                >
                  <FontAwesomeIcon icon={faRedoAlt} />
                </Button>
              </Trigger>
            </div>
          </Col>
        </Row>
      </div>
      <div className='TableBg mt-4 pt-1'>
        
        {show && (
          <ConfirmationModal
            setShow={setShow}
            show={show}
            handleYes={handleYes}
            active={active}
            name={name}
          />
        )}
        <Table striped responsive hover size='sm' className='text-center mt-3'>
          <thead className='thead-light'>
            <tr>
              {tableHeaders.map((h, idx) => (
                <th
                  key={idx}
                  onClick={() => setOrderBy(h.value)}
                  style={{
                    cursor: 'pointer'
                  }}
                  className={
                        selected(h)
                          ? 'border-3 border border-blue'
                          : ''
                      }
                >
                  {h.label}{' '}
                  {selected(h) &&
                        (sort === 'asc'
                          ? (
                            <FontAwesomeIcon
                              style={over ? { color: 'red' } : {}}
                              icon={faArrowCircleUp}
                              onClick={() => setSort('desc')}
                              onMouseOver={() => setOver(true)}
                              onMouseLeave={() => setOver(false)}
                            />
                            )
                          : (
                            <FontAwesomeIcon
                              style={over ? { color: 'red' } : {}}
                              icon={faArrowCircleDown}
                              onClick={() => setSort('asc')}
                              onMouseOver={() => setOver(true)}
                              onMouseLeave={() => setOver(false)}
                            />
                            ))}
                </th>
              ))}
            </tr>
          </thead>
          <tbody id={loading ? 'cover-spin' : ''}>
            {!loading && data &&
                  data?.rows?.map(
                    ({
                      adminUserId,
                      email,
                      firstName,
                      lastName,
                      adminRoleId,
                      isActive,
                      group
                    }, index) => {
                      return (
                        <tr key={email}>
                          <td>{adminUserId}</td>
                          <td>
                            <span
                              onClick={() =>
                                navigate(
                                  `/admin/admins/details/${adminUserId}`
                                )}
                              className='text-link'
                              style={{ cursor: 'pointer' }}
                            >
                              {email}
                            </span>
                          </td>
                          <td>
                            <Trigger message={`${firstName} ${lastName}`}>
                              <span
                                style={{
                                  width: '150px',
                                  cursor: 'pointer'
                                }}
                                className='d-inline-block text-truncate text-link'
                              >
                                {firstName} {lastName}
                              </span>
                            </Trigger>

                          </td>
                          <td>{getRole(adminRoleId)}</td>

                          {/* <td>{group || '-'}</td> */}

                          <td>
                            {isActive
                              ? (
                                <span className='text-success fw-bold'>Active</span>
                                )
                              : (
                                <span className='text-danger fw-bold'>In Active</span>
                                )}
                          </td>
                          <td>
                          	<ActionMenu
								adminUserId={adminUserId}
								navigate={navigate}
								isHidden={isHidden}
								handleShow={handleShow}
								firstName={firstName}
								lastName={lastName}
								email={email}
								getRole={getRole}
								isActive={isActive}
								adminRoleId={adminRoleId}
							/>
                            {/* <Button
                              variant='btn text-btn'
                              id='basic-button'
                              aria-controls={open ? 'basic-menu' : undefined}
                              aria-haspopup='true'
                              aria-expanded={open ? 'true' : undefined}
                              onClick={handleClick}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                              }}
                            >
                              <MoreVertRoundedIcon />
                            </Button> */}
                            {/* <Menu
                              id='basic-menu'
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              MenuListProps={{
                                'aria-labelledby': 'basic-button'
                              }}
                            >
                              <MenuItem onClick={handleClose}>
                                <Trigger message='Edit'>
                                  <Button
                                    variant='btn text-btn'
                                    onClick={() => { navigate(`/admin/admins/edit/${adminUserId}`) }}
                                    hidden={isHidden({ module: { key: 'Admins', value: 'U' } })}
                                  >
                                    <BorderColorRoundedIcon />
                                    <span className='text'>Edit</span>
                                  </Button>
                                </Trigger>
                              </MenuItem>
                              <MenuItem onClick={handleClose}>
                                <Trigger message='View Details'>
                                  <Button
                                    variant='btn text-btn'
                                    onClick={() =>
                                      navigate(
                                        `/admin/admins/details/${adminUserId}`
                                      )}
                                    hidden={isHidden({ module: { key: 'Admins', value: 'R' } })}
                                  >
                                    <RemoveRedEyeRoundedIcon />
                                    <span className='text'>View</span>
                                  </Button>
                                </Trigger>
                              </MenuItem>
                              <MenuItem onClick={handleClose}>
                                {(!isActive
                                  ? (
                                    <Trigger message='Set Status Active'>
                                      <Button
                                        variant='btn text-btn'
                                        onClick={() =>
                                          handleShow(adminUserId, isActive, `${firstName} ${lastName} (${email})`)}
                                        hidden={isHidden({ module: { key: 'Admins', value: 'T' } })}
                                      >
                                        <CheckCircleRoundedIcon />
                                        <span className='text'>Active</span>
                                      </Button>
                                    </Trigger>
                                    )
                                  : (
                                    <Trigger message='Set Status In-Active'>
                                      <Button
                                        variant='btn text-btn'
                                        onClick={() =>
                                          handleShow(adminUserId, isActive, `${firstName} ${lastName} (${email})`)}
                                        hidden={isHidden({ module: { key: 'Admins', value: 'T' } })}
                                      >
                                        <CancelRoundedIcon />
                                        <span className='text'>In-Active</span>
                                      </Button>
                                    </Trigger>)
                                )}
                              </MenuItem>
                              <MenuItem onClick={handleClose}>
                                {getRole(adminRoleId) !== 'Support' &&
                                  <Trigger message='View Tree'>
                                    <Button
                                      variant='btn text-btn'
                                      onClick={() =>
                                        navigate(
                                        `/admin/admins/details/${adminUserId}`,
                                        {
                                          state: {
                                            isTreeView: true
                                          }
                                        }
                                        )}
                                      hidden={isHidden({ module: { key: 'Admins', value: 'R' } })}
                                    >
                                      <AccountTreeRoundedIcon />
                                      <span className='text'>View Tree</span>
                                    </Button>
                                  </Trigger>}
                              </MenuItem>
                            </Menu> */}
                          </td>
                        </tr>
                      )
                    }
                  )}

            {data?.count === 0 && !loading &&
                  (
                    <tr>
                      <td
                        colSpan={7}
                        className='text-danger text-center'
                      >
                        No data found
                      </td>
                    </tr>
                  )}
          </tbody>
        </Table>
		{data?.count !== 0 && !loading &&
          (
            <PaginationComponent
              page={data?.count < page ? setPage(1) : page}
              totalPages={totalPages}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
            />

        )}

      </div>

    </>
  )
}
