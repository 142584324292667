import React from 'react'
import { Route } from 'react-router-dom'

import { AffiliateRoutes } from '../../../routes'
import PrivateRoute from '../../../components/PrivateRoute'

// Affiliate Components
import Dashboard from '../../Affiliates/Dashboard'
import AffiliateProfile from '../../Affiliates/AffiliateProfile'
import ActivePlayers from '../../Affiliates/Players'
import { AFFILIATE_PLAYERS_TYPE } from '../../Affiliates/constants/index'
import ProductsReport from '../../Affiliates/ProductsReport'
import PlayersReport from '../../Affiliates/PlayersReport'
import UpdateAffiliate from '../../Super-Admin/Affiliates/components/UpdateAffiliate'
import UserDetails from '../../Super-Admin/UserDetails'
import PlayerDetails from '../../Affiliates/PlayerDetails'
import TransactionsBanking from '../../Affiliates/TransactionsBanking'
import WithdrawPage from '../../Affiliates/WithdrawPage'
import SubAffiliatePage from '../../Affiliates/SubAffiliatesPage'
import AffiliateDetails from '../../Super-Admin/AffiliateDetails'

const AffiliatePages = () => (
  <>
    <Route
      path={AffiliateRoutes.Dashboard}
      element={
        <PrivateRoute isWithoutCard>
          <Dashboard />
        </PrivateRoute>
      }
    />
    <Route
      path={AffiliateRoutes.Profile}
      element={
        <PrivateRoute isWithoutCard>
          <AffiliateProfile />
        </PrivateRoute>
      }
    />
    <Route
      path={AffiliateRoutes.updateProfile}
      element={
        <PrivateRoute isWithoutCard>
          <UpdateAffiliate />
        </PrivateRoute>
      }
    />

    <Route
      path={AffiliateRoutes.ActivePlayers}
      element={
        <PrivateRoute isWithoutCard>
          <ActivePlayers playerType={AFFILIATE_PLAYERS_TYPE.ACTIVE} search={''} isInitialRender={true} />
        </PrivateRoute>
      }
    />
    <Route
      path={AffiliateRoutes.BlockedPlayers}
      element={
        <PrivateRoute isWithoutCard>
          <ActivePlayers playerType={AFFILIATE_PLAYERS_TYPE.BLOCKED} search={''} isInitialRender={true} />
        </PrivateRoute>
      }
    />

    <Route
      path={AffiliateRoutes.UserDetails}
      element={
        <PrivateRoute isWithoutCard>
          <PlayerDetails />
        </PrivateRoute>
      }
    />


    <Route
      path={AffiliateRoutes.TransactionsBanking}
      element={
        <PrivateRoute isWithoutCard>
          <TransactionsBanking></TransactionsBanking>
        </PrivateRoute>
      }
    />
    <Route
      path={AffiliateRoutes.WithdrawRequests}
      element={
        <PrivateRoute isWithoutCard>
          <>Withdraw Requests</>
        </PrivateRoute>
      }
    />
    <Route
      path={AffiliateRoutes.Withdraw}
      element={
        <PrivateRoute isWithoutCard>
          <WithdrawPage />
        </PrivateRoute>
      }
    />
    <Route
      path={AffiliateRoutes.ProductsReport}
      element={
        <PrivateRoute isWithoutCard>
          <ProductsReport />
        </PrivateRoute>
      }
    />
    <Route
      path={AffiliateRoutes.playersReport}
      element={
        <PrivateRoute isWithoutCard>
          <PlayersReport />
        </PrivateRoute>
      }
    />
    <Route
      path={AffiliateRoutes.SubAffiliatesManagement}
      element={
        <PrivateRoute module={{ Affiliates: 'R' }} isWithoutCard>
          <SubAffiliatePage />
        </PrivateRoute>
      }
    />
    <Route
      path={AffiliateRoutes.SubAffiliateDetails()}
      element={
        <PrivateRoute module={{ Affiliates: 'R' }} isWithoutCard>
          <AffiliateDetails asSubAffiliateDetails />
        </PrivateRoute>
      }
    />
  </>
)

export default AffiliatePages