import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    getLanguageSupportKeysStart,
    getLanguageSupportKeysSuccess,
    getLanguageSupportKeysFailure,
    uploadLanguageCSVStart,
    uploadLanguageCSVComplete,
    getLanguagesStart,
    getLanguagesSuccess,
    getLanguagesFailure,
    updateLanguageSupportStart,
    updateLanguageSupportComplete,
    getCMSLanguagesStart,
    getCMSLanguagesSuccess,
    getCMSLanguagesFailure
  },
  reducer
} = createSlice({
  name: 'languages',
  initialState: {
    loading: false,
    languageKeys: null,
    languages: null,
    languageTabs: null,
    tenantLanguages: null
  },
  reducers: {
    getLanguageSupportKeysStart: (state) => ({
      ...state,
      loading: true,
      languageKeys: null
    }),
    getLanguageSupportKeysSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      languageKeys: payload.languageData,
      languageTabs: payload.languageKeys
    }),
    getLanguageSupportKeysFailure: (state) => ({
      ...state,
      loading: false
    }),
    uploadLanguageCSVStart: (state) => ({
      ...state,
      loading: true
    }),
    uploadLanguageCSVComplete: (state) => ({
      ...state,
      loading: false
    }),
    getLanguagesStart: (state) => ({
      ...state,
      loading: true
    }),
    getLanguagesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      languages: payload
    }),
    getLanguagesFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateLanguageSupportStart: (state) => ({
      ...state,
      loading: true
    }),
    updateLanguageSupportComplete: (state) => ({
      ...state,
      loading: false
    }),
    getCMSLanguagesStart: (state) => ({
      ...state,
      loading: true
    }),
    getCMSLanguagesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      tenantLanguages: payload
    }),
    getCMSLanguagesFailure: (state) => ({
      ...state,
      loading: false
    })
  }
})

export default reducer

export {
  getLanguageSupportKeysStart,
  getLanguageSupportKeysSuccess,
  getLanguageSupportKeysFailure,
  uploadLanguageCSVStart,
  uploadLanguageCSVComplete,
  getLanguagesStart,
  getLanguagesSuccess,
  getLanguagesFailure,
  updateLanguageSupportStart,
  updateLanguageSupportComplete,
  getCMSLanguagesStart,
  getCMSLanguagesSuccess,
  getCMSLanguagesFailure
}
