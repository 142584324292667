import {
  Form as BForm,
  Col,
  Row,
  Tab,
  Tabs
} from '@themesberg/react-bootstrap'
import { Formik } from 'formik'
import React from 'react'
import Preloader from '../../../../../components/Preloader'
import { formatDate } from '../../../../../utils/dateFormatter'
import useCreateBonus from '../hooks/useCreateBonus'
import BonusCountry from './BonusCountry'
import { BONUS_TYPE_CONSTANTS, tabLabels } from './constants'
import CurrenciesForm from './currencies'
import Games from './Games'
import GeneralForm from './general'
import Languages from './Languages'
import LoyaltySettings from './LoyaltySettings'
import { bonusSchema } from './schema'
import WageringContributionForm from './wageringContribution'

const CreateBonus = () => {
  const {
    curr,
    setCurr,
    selectedTab,
    setSelectedTab,
    state,
    setState,
    preview,
    handleImagePreview,
    setActiveTab,
    dispatch,
    navigate,
    enableSubmit,
    setEnableSubmit,
    loading,
    handelCreateBonus,
    clientsFilterData,
    setSelectedClient,
    portalOptions,
    getBalanceBonusesOptions,
    balanceBonusOptions,
    wageringTemplateList,
    wageringTemplateDetail,
    pageNo,
    setPageNo,
    limit,
    setLimit,
    totalPages,
    search,
    setSearch,
    selectedProvider,
    setSelectedProvider,
    gameIds,
    setGameIds,
    casinoGamesData,
    selectedClient,
    gamesLimit,
    gamesPageNo,
    setGamesLimit,
    setGamesPageNo,
    appliedBonusOptions,
    languages,
    data,
    setData,
    loyaltyCount,
    setLoyaltyCount,
    countryData,
    setCountryData
  } = useCreateBonus()

  return (
    <>
        {loading && <Preloader />}
        <div className='section-header-wrap mt-n3 mx-n3'>
          <Col>
            <h3>Create Bonus </h3>
          </Col>
        </div>

      <Formik
        initialValues={{
          promotionTitle: '',
          bonusType: '',
          validFrom: formatDate(state?.[0].startDate),
          validTo: formatDate(state?.[0].endDate),
          termCondition: '',
          quantity: 1,
          wageringMultiplier: 1,
          currency: {
            BRL: {
              maxBonusThreshold: '',
              minDeposit: '',
              maxWinAmount: '',
              zeroOutThreshold: '',
              minBalance: '',
              joiningAmount: ''
            }
          },
          providers: '',
          daysToClear: 1,
          games: '',
          maxBonusThreshold: '',
          status: '',
          minDeposit: '',
          wageringRequirementType: 'bonus',
          maxWinAmount: '',
          isWinCashAmt: '',
          isBonusWagering: '',
          depositBonusPercent: 1,
          isMultipleAllowed: '',
          validOnDays: [],
          bonusImage: null,
          isActive: false,
          showBonusValidity: true,
          visibleInPromotions: false,
          isSticky: false,
          paymentMethods: {},
          wageringTemplateId: wageringTemplateList?.[0]?.wageringTemplateId,
          appliedBonusId: '',
          appliedBonusVal: '',
          description: '',
          loyaltyLevel: null,
          other: null,
          minBalance: '',
          timePeriod: '1',
          betLevel: 1,
          bonusCode:'',
          isVisibleOnBanner:false
          
        }}
        validationSchema={
          bonusSchema(curr, { bonusDetail: null })[tabLabels.findIndex((val) => val === selectedTab)]
        }
        onSubmit={(formValues) => handelCreateBonus(formValues)}
      >
        {({
          touched,
          errors,
          values,
          setSubmitting,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue
        }) => (
          <BForm>
            <Tabs
              key={selectedTab}
              activeKey={selectedTab}
              className='nav-light'
              mountOnEnter
              unmountOnExit
              onSelect={(k) => setSelectedTab(k)}
            >
              <Tab disabled eventKey='general' title='General'>
                <div className='mt-5'>
                  <GeneralForm
                    state={state}
                    setState={setState}
                    setSelectedTab={setSelectedTab}
                    touched={touched}
                    errors={errors}
                    values={values}
                    setSubmitting={setSubmitting}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    preview={preview}
                    handleImagePreview={handleImagePreview}
                    navigate={navigate}
                    clientsFilterData={clientsFilterData}
                    setSelectedClient={setSelectedClient}
                    portalOptions={portalOptions}
                    getBalanceBonusesOptions={getBalanceBonusesOptions}
                    balanceBonusOptions={balanceBonusOptions}
                    selectedClient={selectedClient}
                    setGameIds={setGameIds}
                    wageringTemplateList={wageringTemplateList}
                    setEnableSubmit={setEnableSubmit}
                    appliedBonusOptions={appliedBonusOptions}
                    data={data}
                    setData={setData}
                    languages={languages}
                    setCountryData={setCountryData}
                  />
                </div>
              </Tab>
              
             
                <Tab disabled eventKey='currency' title='Currency'>
                  <div className='mt-3'>
                    <CurrenciesForm
                      touched={touched}
                      errors={errors}
                      values={values}
                      setSubmitting={setSubmitting}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      setCurr={setCurr}
                      setSelectedTab={setSelectedTab}
                      setActiveTab={setActiveTab}
                      enableSubmit={enableSubmit}
                      setEnableSubmit={setEnableSubmit}
                    />
                  </div>
                </Tab>
            

              {[BONUS_TYPE_CONSTANTS.CASINO_DEPOSIT,BONUS_TYPE_CONSTANTS.SPORTS_DEPOSIT].includes((values.bonusType)) && (
                <Tab disabled eventKey='countries' title='Countries'>
                  <div className='mt-5'>
                    <BonusCountry
                      data={countryData}
                      setData={setCountryData}
                      values={values}
                      setSelectedTab={setSelectedTab}
                      handleSubmit={handleSubmit}
                      languages={languages}
                      setEnableSubmit={setEnableSubmit}
                    />
                  </div>
                </Tab>
              )}
            </Tabs>
          </BForm>
        )}
      </Formik>
    </>
  )
}

export default CreateBonus
