import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { useNavigate } from 'react-router-dom'

import { getMarketsListStart } from '../../../store/redux-slices/sportsBook'

const useMarketListing = () => {
  const dispatch = useDispatch()

  const [limit, setLimit] = useState(10)
  const [pageNo, setPageNo] = useState(1);
  const [searchByName, setSearchByName] = useState("");
  const [searchByStatus, setSearchByStatus] = useState("");

  const { sportsMarketsInfo, isGetMarketsLoading } = useSelector(state => state.sportsBook)
  const totalPages = Math.ceil(sportsMarketsInfo?.totalPage / limit)

  useEffect(() => {
    dispatch(getMarketsListStart({
      limit: limit,
      pageNo: pageNo,
      isLive: true,
      providerSportId: 1,
      seacrh: searchByName
    })
    )
  }, [limit, pageNo, searchByName])
  return {
    limit,
    setLimit,
    pageNo,
    setPageNo,
    sportsMarketsInfo,
    totalPages,
    isGetMarketsLoading,
    searchByName,
    setSearchByName,
    searchByStatus,
    setSearchByStatus
  }
}

export default useMarketListing
