import React, { useState } from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "@themesberg/react-bootstrap";

import { affliateInitialFormData, createAffiliateSchema } from "../schemas";
import { createAffiliateStart } from "../../../../store/redux-slices/affiliates";
import AffiliateForm from "./AffiliateForm";
import { toast } from "../../../../components/Toast";
import { registerAffiliate } from "../../../../utils/apiCalls";
import { AdminsRoutes } from "../../../../routes";
import { resolveErrors } from "../../../../utils/errorTypes";

const CreateAffiliateUser = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  return (
    <>
      <div className="section-header-wrap mt-n3 mx-n3">
        <Row>
          <Col lg={8} xs={7}>
            <h2 className="title-text">Criação de Affiliado</h2>
          </Col>
        </Row>
      </div>
      <div className="FormsBg mt-4">
        <Formik
          initialValues={affliateInitialFormData()}
          validationSchema={createAffiliateSchema}
          onSubmit={async (formValues) => {
            setLoading(true)
            try {
              await registerAffiliate({ ...formValues });
              toast("Afiliado criado com sucesso!", "success");
              navigate(AdminsRoutes.AffiliatesManagement);
            } catch (err) {
              toast(resolveErrors(err?.response?.data?.errors, "Erro ao criar afiliado"), "error");
            } finally {
              setLoading(false);
            }
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
          }) => (
            <AffiliateForm
              values={values}
              errors={errors}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              loading={loading}
            />
          )}
        </Formik>
      </div>
    </>
  );
};

export default CreateAffiliateUser;
