import { Col, Row, Button, Accordion, Table } from '@themesberg/react-bootstrap'
import React from 'react'
import { motion } from 'framer-motion/dist/framer-motion'
import useKYCLabels from './useKYCLabels'
import Preloader from '../../../components/Preloader'
import CreateEdit from './CreateEdit'
import useCheckPermission from '../../../utils/checkPermission'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { languageCode } from '../../Tenant/TenantConfigurations/Languages/constants'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'

export default () => {
  const {
    loading,
    handleAdd,
    newLabels,
    updateLabels,
    handleEdit,
    data,
    show,
    handleClose,
    type,
    language,
    setLangauge,
    languages,
    createLabels
  } = useKYCLabels()
  const { isHidden } = useCheckPermission()

  return (
    <>
    <div className='section-header-wrap mt-n3 mx-n3'>
      <Row>
        <Col lg={8} xs={7}>
          <h2 className='title-text'>KYC Labels</h2>
        </Col>
          <Col lg={4} xs={5}>
            {/* Button to create new admin */}
            <div className='d-flex justify-content-end'>
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
            <Button
              variant='btn btn-primary w-100'
              style={{ maxWidth: '140px' }}
              onClick={() => {
                handleAdd()
              }}
              hidden={isHidden({ module: { key: 'KycLabel', value: 'C' } })}
            >
            <AddCircleOutlineRoundedIcon className='me-2' />
            <span>Criar</span>
            </Button>
            </motion.div>
          </div>
        </Col>
      </Row>
      </div>
      <div className='TableBg mt-4 pt-1'>
      {loading
        ? <Preloader />
        : newLabels?.length > 0 &&
          <Accordion defaultActiveKey={0}>
            {newLabels?.map((key, index) => {
              return (
                <Accordion.Item
                  eventKey={index}
                  key={`label-${index}`}
                >
                  <Accordion.Header>
                    Label {newLabels[index]?.documentLabelId}&nbsp;
                    ({newLabels[index]?.isRequired
                    ? <span className='text-success'>Required</span>
                    : <span className='text-danger'>Not Required</span>})
                    <Button
                      style={{ marginLeft: '10px' }}
                      size='sm'
                      onClick={() => {
                        handleEdit(newLabels[index])
                        setLangauge('')
                      }}
                      hidden={isHidden({ module: { key: 'KycLabel', value: 'U' } })}
                    ><FontAwesomeIcon icon={faEdit} />
                    </Button>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Table
                      
                      striped
                      responsive
                      hover
                      size='sm'
                      className='text-center mt-4'
                    >
                      <thead className='thead-light'>
                        <tr>
                          {['Language', 'Label Name'].map((c) => (
                            <th key={c}>{c}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(newLabels[index]?.name).map((language, indx) => {
                          return (
                            <tr key={indx}>
                              <td>{`${languageCode[language]} (${language})`}</td>
                              <td>{newLabels[index]?.name?.[language]}</td>
                            </tr>
                          )
                        }
                        )}
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
              )
            }
            )}
          </Accordion>}
          </div>
      <CreateEdit
        handleClose={handleClose}
        show={show}
        updateLabels={type === 'Edit' ? updateLabels : createLabels}
        loading={loading}
        data={type === 'Edit' ? data : null}
        type={type}
        language={language}
        setLangauge={setLangauge}
        languages={languages}
      />
    </>
  )
}
