export const tableHeaders = [
  { label: 'Country', value: 'country' },
  { label: 'Sign Ups', value: 'signups' },
  { label: 'Depositors', value: 'depositors' },
  { label: 'Deposit Amount', value: 'depostamount' },
  { label: 'Conversion Rate', value: 'conversionrate' }
]

export const currencySymbols = {
  USD: '$', // US Dollar
  EUR: '€', // Euro
  CRC: '₡', // Costa Rican Colón
  GBP: '£', // British Pound Sterling
  ILS: '₪', // Israeli New Sheqel
  INR: '₹', // Indian Rupee
  JPY: '¥', // Japanese Yen
  KRW: '₩', // South Korean Won
  NGN: '₦', // Nigerian Naira
  PHP: '₱', // Philippine Peso
  PLN: 'zł', // Polish Zloty
  PYG: '₲', // Paraguayan Guarani
  THB: '฿', // Thai Baht
  UAH: '₴', // Ukrainian Hryvnia
  VND: '₫', // Vietnamese Dong
  NAD: 'N$' // Namibian dollar
}
