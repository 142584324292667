import React from 'react'
import useCasinoTransactionsList from './hooks/useCasinoTransactionsList'
import { Button, Col, Row, Form } from '@themesberg/react-bootstrap'
import DateRangePicker from '../../../components/DateRangePicker'
import { getDateDaysAgo } from '../../../utils/dateFormatter'
import { transactionType, statusType } from './constants'
import CasinoTransactionsList from '../../../components/CasinoTransactionList'
import ClientFilter from '../../../components/ClientFilter'
import PortalFilter from '../../../components/PortalFilter'
import { toast } from '../../../components/Toast'
import Trigger from '../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt, faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { downloadFile } from '../../../utils/fileDownloader'

const CasinoTransactions = () => {
  const {
    allCurrencies,
    setSelectedCurrency,
    setSearch,
    setLimit,
    setPage,
    totalPages,
    limit,
    page,
    setSelectedAction,
    selectedCurrency,
    selectedAction,
    search,
    state,
    setState,
    casinoTransactions,
    loading,
    status,
    setStatus,
    selectedClient,
    selectedPortal,
    setSelectedClient,
    setSelectedPortal,
    getCsvDownloadUrl
  } = useCasinoTransactionsList()

  return (
    <>
          <div className='section-header-wrap mt-n3 mx-n3'>
          <Row>
        <Col>
          <h2 className='title-text'>Transações de Cassino</h2>
        </Col>
        {/* <Col>
          <div className='d-flex justify-content-end align-items-center'>
            <ClientFilter
              setSelectedClient={setSelectedClient}
              setSelectedPortal={setSelectedPortal}
              selectedClient={selectedClient}
              hasAllOptions
            />

            {selectedClient && (
              <PortalFilter
                setSelectedPortal={setSelectedPortal}
                selectedPortal={selectedPortal}
                selectedClient={selectedClient}
                hasAllOptions
              />
            )}
          </div>
        </Col> */}
      </Row>
      </div>
      <div className='FormsBg'>
      <Row>

<Col xs={2}>
  {/* <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
    Currency
  </Form.Label> */}

  <Form.Select
    size='sm'
    style={{ maxWidth: '230px' }}
    onChange={(e) => setSelectedCurrency(e.target.value)}
    value={selectedCurrency}
  >
    <option value=''>Selecione uma moeda</option>
    {allCurrencies && allCurrencies?.rows?.map(
      ({ name: currName, currencyId, code }) => (
        <option key={currencyId} value={code}>
          {currName}
        </option>
      )
    )}
  </Form.Select>
</Col>

<Col xs={2} >
  {/* <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
    Action Type
  </Form.Label> */}

  <Form.Select
    size='sm'
    style={{ maxWidth: '230px' }}
    onChange={(e) => setSelectedAction(e.target.value)}
    value={selectedAction}
  >
    {transactionType && transactionType?.map(
      ({ label, value }) => (
        <option key={label} value={value}>
          {label}
        </option>
      )
    )}
  </Form.Select>
</Col>

{/* <Col xs={2}> */}
  {/* <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
    Search
  </Form.Label> */}

  {/* <Form.Control
    size='sm'
    style={{ maxWidth: '230px' }}
    type='search'
    value={search}
    placeholder='Search Email'
    onChange={e =>
      setSearch(e.target.value.replace(/[~`!$%^&*#=)()><?]+/g, ''))}
  /> */}
{/* </Col> */}
{/* 
<Col xs={2}>
 

  <Form.Select
    onChange={(e) => setStatus(e.target.value)}
    value={status}
    size='sm'
    style={{ maxWidth: '230px' }}
  >
    {statusType && statusType?.map(
      ({ label, value }) => (
        <option key={label} value={value}>
          {label}
        </option>
      )
    )}
  </Form.Select>

</Col> */}

<Col  xs={3}>
  {/* <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
    Time Period
  </Form.Label> */}
  <DateRangePicker
    state={state} setState={setState}size='sm'
  />
</Col>
<Col xs={1}>
<div className='d-flex justify-content-end align-item-center'>

  <Trigger message='Reset Filters'>
    <Button
      size='sm'
      onClick={() => {
        setSearch('')
        setSelectedAction('all')
        setSelectedCurrency('')
        setSelectedClient('')
        setLimit(10)
        setPage(1)
        setState([
          {
            startDate: getDateDaysAgo(10),
            endDate: new Date(),
            key: 'selection'
          }
        ])
        setStatus('all')
      }}
    >
      <FontAwesomeIcon icon={faRedoAlt} />
    </Button>
  </Trigger>
  <Trigger message='Download as CSV'>
    <Button
     className='sports-csv'
      size='sm'
      disabled={casinoTransactions?.count === 0}
      onClick={() => {
        downloadFile(getCsvDownloadUrl())
      }}
    >
      <FontAwesomeIcon icon={faFileDownload} />
    </Button>
  </Trigger>
  </div>
</Col>


</Row>
      </div>
      
      <CasinoTransactionsList
        page={page}
        setLimit={setLimit}
        limit={limit}
        setPage={setPage}
        totalPages={totalPages}
        transactions={casinoTransactions}
        isTenant={false}
        loading={loading}
      />
    </>
  )
}

export default CasinoTransactions
