import { takeLatest, put } from 'redux-saga/effects'
import {
  cancelDocumentRequest,
  getDocumentLabel,
  updateDocumentLabel,
  createDocumentLabel,
  requestDocument,
  getloyaltyLevel,
  updateloyaltyLevel,
  getAllSABanners,
  uploadSABanner,
  updateSABanner,
  updateSAUserStatus,
  deleteSABanners
} from '../../utils/apiCalls'
import {
  getDocumentLabelStart,
  getDocumentLabelSuccess,
  getDocumentLabelFailure,
  updateDocumentLabelStart,
  updateDocumentLabelComplete,
  createDocumentLabelStart,
  createDocumentLabelComplete,
  updateDocumentStart,
  updateDocumentComplete,
  getloyaltyLevelStart,
  getloyaltyLevelSuccess,
  getloyaltyLevelFailure,
  updateloyaltyLevelStart,
  updateloyaltyLevelComplete,
  getAllSABannersStart,
  getAllSABannersSuccess,
  getAllSABannersFailure,
  uploadSABannerStart,
  uploadSABannerComplete,
  updateSABannerStart,
  updateSABannerComplete,
  updateSAUserStatusStart,
  updateSAUserStatusCompleted,
  updateSAUserStatusFailure,
  deleteSABannersSuccess,
  deleteSABannersFailure,
  deleteSABannersStart
} from '../redux-slices/superAdminSettings'
import { toast } from '../../components/Toast'
import { getSAUserDocumentStart, getUserStart } from '../redux-slices/fetchData'
import { getTenantStart } from '../redux-slices/tenants'
import { serialize } from 'object-to-formdata'
import { getAllBannersStart } from '../redux-slices/tenantcredentials'

export default function* superAdminSettingsWatcher() {
  yield takeLatest(getDocumentLabelStart.type, getDocumentLabelWorker)
  yield takeLatest(updateDocumentLabelStart.type, updateDocumentLabelWorker)
  yield takeLatest(createDocumentLabelStart.type, createDocumentLabelWorker)
  yield takeLatest(updateDocumentStart.type, updateDocumentWorker)
  yield takeLatest(getloyaltyLevelStart.type, getloyaltyLevelWorker)
  yield takeLatest(updateloyaltyLevelStart.type, updateloyaltyLevelWorker)
  yield takeLatest(getAllSABannersStart.type, getAllSABannersWorker)
  yield takeLatest(deleteSABannersStart.type, deleteSABannersWorker)
  yield takeLatest(updateSABannerStart.type, updateSABannerWorker)
  yield takeLatest(uploadSABannerStart.type, uploadSABannerWorker)
  yield takeLatest(updateSAUserStatusStart.type, updateSAUserUpdateWorker)
}

function* getDocumentLabelWorker(action) {
  try {
    const { userId = "" } = action && action.payload

    const { data } = yield getDocumentLabel(userId)

    yield put(getDocumentLabelSuccess(data?.data?.documentLabel))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getDocumentLabelFailure(e?.response?.data?.errors[0]?.description))
  }
}

function* updateDocumentLabelWorker(action) {
  try {
    const { data } = action && action.payload

    yield updateDocumentLabel(data)

    yield put(updateDocumentLabelComplete())
    yield toast('Labels Updated SuccessFully', 'success')
    yield put(getDocumentLabelStart({ userId: '' }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateDocumentLabelComplete())
  }
}

function* createDocumentLabelWorker(action) {
  try {
    const { data } = action && action.payload

    yield createDocumentLabel(data)

    yield put(createDocumentLabelComplete())
    yield toast('Label Created SuccessFully', 'success')
    yield put(getDocumentLabelStart({ userId: '' }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(createDocumentLabelComplete())
  }
}

function* updateDocumentWorker(action) {
  try {
    const { data, isRequested } = action && action.payload

    isRequested ? yield requestDocument(data) : yield cancelDocumentRequest(data)

    yield updateDocumentComplete()
    yield put(getUserStart({ userId: data?.userId }))
    yield put(getSAUserDocumentStart({ userId: data?.userId }))
    yield put(getDocumentLabelStart({ userId: data?.userId }))

    yield toast(`Document ${isRequested ? 'Requested' : 'UnRequested'} Successfully`, 'success')
  } catch (e) {
    yield updateDocumentComplete()

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function* getloyaltyLevelWorker(action) {
  try {
    const { isTenant, tenantId } = action && action.payload

    const { data } = yield getloyaltyLevel({ isTenant, tenantId: tenantId || '' })

    yield put(getloyaltyLevelSuccess(data?.data?.loyaltyLevel))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getloyaltyLevelFailure(e?.response?.data?.errors[0]?.description))
  }
}

function* updateloyaltyLevelWorker(action) {
  try {
    const { loyaltyLevel, isTenant, tenant = false, tenantId = '' } = action && action.payload

    yield updateloyaltyLevel({ isTenant, data: { loyaltyLevel: loyaltyLevel?.loyaltyLevel, tenantId } })

    yield put(updateloyaltyLevelComplete())

    yield toast('Data Updated SuccessFully', 'success')
    if (tenant) yield put(getTenantStart({ tenantId }))
    else yield put(getloyaltyLevelStart({ isTenant }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateloyaltyLevelComplete())
  }
}

function* getAllSABannersWorker(action) {
  try {
    const { adminId, tenantId, limit, pageNo } = action && action.payload

    const { data } = yield getAllSABanners({ adminId, tenantId, limit, pageNo })

    yield put(getAllSABannersSuccess(data?.data?.value))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllSABannersFailure(e?.response?.data?.errors[0]?.description))
  }
}

function* deleteSABannersWorker(action) {
  try {
    const { bannerId, isMobileBanner, dispatch } = action && action.payload

    const { data } = yield deleteSABanners({ bannerId, isMobileBanner })
    dispatch(getAllSABannersStart({
      limit: '',
      pageNo: '',
      // adminId: selectedPortal ? '' : selectedClient,
      // tenantId: selectedPortal
    }))
    yield put(getAllBannersStart())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(deleteSABannersFailure(e?.response?.data?.errors[0]?.description))
  }
}

function* updateSABannerWorker(action) {
  try {
    const { data: banner, limit, pageNo } = action && action.payload
    const data = serialize(banner)

    if (banner?.image) {
      yield updateSABanner(data)
    }

    yield put(updateSABannerComplete())

    yield toast('Banner Updated SuccessFully', 'success')
    yield put(getAllSABannersStart({
      limit,
      pageNo,
      adminId: '',
      tenantId: ''
    }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateSABannerComplete())
  }
}

function* uploadSABannerWorker(action) {
  try {
    const { data: banner, limit, pageNo } = action && action.payload
    const data = serialize(banner)

    yield uploadSABanner(data)

    yield put(uploadSABannerComplete())

    yield toast('Banner Uploaded SuccessFully', 'success')
    yield put(getAllSABannersStart({
      limit,
      pageNo,
      adminId: '',
      tenantId: ''
    }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(uploadSABannerComplete())
  }
}

function* updateSAUserUpdateWorker(action) {
  try {
    const { data } = action && action.payload

    yield updateSAUserStatus({ ...data })
    yield toast('User Status Updated SuccessFully', 'success')
    yield put(updateSAUserStatusCompleted())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateSAUserStatusFailure(e?.response?.data?.errors[0]?.description))
  }
}
