import React from 'react'
import {
  Form,
  Row,
  Col,
  Button,
//   Table,
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons'
// import useCheckPermission from '../../../utils/checkPermission'
import useMarketListing from './useMarketListing'
import SportsMarketTable from './SportsMarketTable'
import { SIZE_CONSTANT } from './constants'
import Trigger from '../../../components/OverlayTrigger'
const SportsMarketList = () => {
  const {
    sportsMarketsInfo,
    pageNo,
    setPageNo,
    totalPages,
    limit,
    setLimit,
    isGetMarketsLoading,
    searchByName,
    setSearchByName,
    searchByStatus,
    setSearchByStatus
  } = useMarketListing()
//   const { isHidden } = useCheckPermission();
  return (
    <>
		<div className='section-header-wrap mt-n3 mx-n3'>
			<Row>
				<Col>
				<h2 className='title-text'>Markets</h2>
				</Col>
			</Row>
		</div>
      	<div className='FormsBg'>
      		<Row>
                <Col xs='12' sm='3'>   
                    <Form.Label style={{ marginBottom: '0', marginRight: '15px' }}>
                    Search
                    </Form.Label>      
                    <Form.Control
                        type='search'
                        placeholder='Search By Name'
                        size='sm'
                        // style={{ maxWidth: '230px', marginRight: '10px', maxHeight: '15px' }}
                        value={searchByName}
                        onChange={(event) =>
                        setSearchByName(event.target.value.replace(/[^\w\s]/gi, ''))}
                    />
                </Col>
                {/* <Col xs='12' sm='3'>
                    <Form.Label style={{ marginBottom: '0', marginRight: '15px' }}>
                    Status
                    </Form.Label>   
                    <Form.Select
                        onChange={(e) => { setSearchByStatus(e.target.value) }}
                        value={searchByStatus}
                    >
                        <option value=''>All</option>
                        <option value='1'>Enabled</option>
                        <option value='0'>Disabled</option>
                    </Form.Select>
                </Col> */}
                <Col xs='12' sm='3'>
                    <Form.Label style={{ marginBottom: '0', marginRight: '15px' }}>
                    Size
                    </Form.Label>  
                    <Form.Select
                        onChange={(e) => { 
                            setLimit(e.target.value);
                            setPageNo(1);
                        }}
                        value={limit}
                    >
                        <option value=''>All</option>
                        {
                            SIZE_CONSTANT.map((item, index) => {
                                return (
                                    <option value={item}>{item}</option>
                                )
                            })
                        }
                    </Form.Select>
                </Col>
                <Col xs={6} className='d-flex justify-content-end align-items-center'>
                    <Trigger message='Reset Filters'>
                        <Button
                            size='sm'
                            onClick={() => {
                            setSearchByName('')
                            setLimit(10)
                            setPageNo(1)
                        }}
                        >
                            <FontAwesomeIcon icon={faRedoAlt} />
                        </Button>
                    </Trigger>
                </Col>
            </Row>			
        </div>
		<SportsMarketTable
            sportsMarketsInfo={sportsMarketsInfo}
            pageNo={pageNo}
            setPageNo={setPageNo}
            totalPages={totalPages}
            limit={limit}
            setLimit={setLimit}
            isGetMarketsLoading={isGetMarketsLoading}
        />
		
    </>
  )
}

export default SportsMarketList;
