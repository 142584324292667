export const tableHeaders = [
  { label: 'Id', value: 'superAdminUserId' },
  { label: 'Título', value: 'title' },
  // { label: 'Owner Name', value: 'ownerName' },
  // { label: 'Portal Name', value: 'tenantName' },
  { label: 'Bonus Tipo', value: 'bonusType' },
  // { label: 'Valid Till', value: 'validTo' },
  // { label: 'Is Expired', value: 'isExpired' },
  { label: 'Multiplicador de apostas', value: 'wageringMultiplier' },
  { label: 'Dias para liberar (apostas)', value: 'daysToClear' },
  { label: 'Bonus Code', value: 'bonusCode' },
  { label: 'Reivindicado', value: 'isClaimed' },
  { label: 'Status', value: 'isActive' },
  { label: 'Action', value: 'superAdminUserId' }
]
