import styled from 'styled-components';

export const ActionInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: baseline;
    align-items: baseline;
    border: 2px solid #e4dcdc;
    margin-bottom: 20px;
    // margin: 0px 20px 20px 20px;
    padding-bottom: 20px;
    padding: 20px;

    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    -ms-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    -o-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

    .limit-form-wrap {
        width: 80%;
        @media screen and (max-width: 992px) {
            & {
                width: 300px;
            }
        }
        margin: 0 auto;
        .limit-form {
            // display: flex;
            // align-items: baseline;
        }
        .limitinputwrap {
            width: 100%;
        }
    }
    .limit-action-btnwrap {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        .limit-action-btn {
            width: 100px;
            margin-right: 10px;
            height: 40px;
        }
    }
    .limit-heading {
        font-size: 18px;
        font-weight: 600;
    }
    .input-group-text {
        font-weight: 800;
        color: #597e9f;
        
    }
`;

export const ResponsibleContainer = styled.div`
    .div-overview {
        padding-left: 20px;
        padding-right: 20px;
    }
`;
