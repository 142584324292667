import {
  Button,
  Row,
  Col,
  Table,
  ButtonGroup,
  Form
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import React from 'react'
import PaginationComponent from '../../../components/Pagination'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import useReviewManagement from './useReviewManagement'
import Trigger from '../../../components/OverlayTrigger'
import useCheckPermission from '../../../utils/checkPermission'
import ClientFilter from '../../../components/ClientFilter'
import PortalFilter from '../../../components/PortalFilter'
import AddEditReview from './components/AddReviews'
import ActionMenu from './ActionMenu'

export default () => {
  const {
    limit,
    setLimit,
    page,
    setPage,
    show,
    data,
    type,
    reviewList,
    totalPages,
    handleClose,
    handleShow,
    loading,
    addReviewHandler,
    updateReviewHandler,
    selectedClient,
    selectedPortal,
    setSelectedClient,
    setSelectedPortal,
    selectedClientL,
    selectedPortalL,
    setSelectedClientL,
    setSelectedPortalL,
    search,
    setSearch,
    active,
    setActive
  } = useReviewManagement()
  const { isHidden } = useCheckPermission()
  return (
    <>
    <div className='section-header-wrap mt-n3 mx-n3'>
      <Row>
        <Col>
          <h2 className='title-text'>Review Management</h2>
        </Col>

        <Col xs='auto'>
          <div className='d-flex justify-content-end align-items-end'>
            <Button
             variant='btn btn-primary w-100'
              className='f-right'
              onClick={() => handleShow('Create', null)}
              style={{ maxWidth: '140px' }}
            >
              <AddCircleOutlineRoundedIcon className='me-2' />
                <span>Create</span>
            </Button>
          </div>
        </Col>
      </Row>
      </div>
      <div className='FormsBg'>
      <Row>
        {/* <Col xs='3'>
        <ClientFilter
              setSelectedClient={setSelectedClientL}
              setSelectedPortal={setSelectedPortalL}
              selectedClient={selectedClientL}
            />

            {selectedClientL && (
              <PortalFilter
                setSelectedPortaRegistration Fields
                l={setSelectedPortalL}
                selectedPortal={selectedPortalL}
                selectedClient={selectedClientL}
              />
            )}
      </Col> */}
   
<Col xs='3'>
            <Form.Control
              type='search'
              size='sm'
              style={{ marginRight: '10px' }}
              value={search}
              placeholder='Search title, description'
              onChange={(event) =>
                setSearch(
                  event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, '')
                )}
            />

            
</Col>
<Col xs='3'>
            <Form.Select
              size='sm'
              style={{ marginRight: '10px' }}
              value={active}
              onChange={(event) =>
                setActive(
                  event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, '')
                )}
            >
              <option key='' value=''>Status</option>
              <option key='true' value>Active</option>
              <option key='false' value={false}>In-Active</option>
            </Form.Select>

        </Col>
      </Row>
     </div>
     <div className='TableBg mt-4 pt-1'> 
    
      <Table striped responsive hover size='sm' className='text-center mt-3'>
        <thead className='thead-light'>
          <tr>
            <th>Id</th>
            <th>UserName</th>
            <th>Description</th>
            <th>Rating</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && Boolean(reviewList) &&
                      reviewList?.rows?.map(
                        (
                          {
                            reviewId,
                            userName,
                            description,
                            status,
                            rating
                          },
                          index
                        ) => {
                          return (
                            <tr key={reviewId}>
                              <td>{reviewId}</td>
                              <td>
                                {userName}
                              </td>

                              <td title={description}>

                                <span className='review-desc'>{description}</span>
                              </td>
                              <td>{rating}</td>
                              <td>
                                {status
                                  ? (
                                    <span className='text-success'>Active</span>
                                    )
                                  : (
                                    <span className='text-danger'>In Active</span>
                                    )}
                              </td>

                              <td>
                                {(!isHidden({ module: { key: 'CasinoManagement', value: 'U' } }) || !isHidden({ module: { key: 'CasinoManagement', value: 'T' } }))
                                  ? (
                                    <ActionMenu
                                    isHidden={isHidden}                        
                                    handleShow={handleShow}
                                    reviewList={reviewList}
                                    index={index}
                                  />)
                                    // <ButtonGroup>
                                    //   <Trigger message='Edit'>

                                    //     <Button
                                    //       className='m-1'
                                    //       size='sm'
                                    //       onClick={() =>
                                    //         handleShow(
                                    //           'Edit',
                                    //           reviewList.rows[index]
                                    //         )}
                                    //       hidden={isHidden({ module: { key: 'CasinoManagement', value: 'U' } })}
                                    //     >
                                    //       <FontAwesomeIcon icon={faEdit} />
                                    //     </Button>
                                    //   </Trigger>
                                    // </ButtonGroup>)
                                  : 'NA'}
                              </td>
                            </tr>
                          )
                        }
                      )}

          {reviewList?.count === 0 && !loading &&
                        (
                          <tr>
                            <td
                              colSpan={5}
                              className='text-danger text-center'
                            >
                              No data found
                            </td>
                          </tr>
                        )}
        </tbody>
      </Table>
      {reviewList?.count !== 0 && !loading &&
            (
              <PaginationComponent
                page={reviewList?.count < page ? setPage(1) : page}
                totalPages={totalPages}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
              />
            )}
      </div>

      

      <AddEditReview
        handleClose={handleClose}
        data={data}
        type={type}
        show={show}
        loading={loading}
        addReviewHandler={addReviewHandler}
        updateReviewHandler={updateReviewHandler}
        selectedClient={selectedClient}
        selectedPortal={selectedPortal}
        setSelectedClient={setSelectedClient}
        setSelectedPortal={setSelectedPortal}
      />
    </>
  )
}
