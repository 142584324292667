import * as Yup from 'yup'

export const tenantSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Name must be atleast 3 characters')
    .max(200, 'Name must be of lesser then 200 characters!!')
    .required('Name Required!')
    .matches(/^([a-zA-Z]+\s)*[a-zA-Z]+$/, 'Only Alphabets and Single Space Between Words Allowed'),
  domain: Yup.string()
    .max(200, 'Domain cannot be larger than 200 characters!')
    .matches(
      /^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
      'Enter correct url!'
    )
    .required('Domain Required!'),
  allowedCurrencies: Yup.array().min(1, 'Allowed Currencies Required!'),
  primaryCurrency: Yup.string().required('Primary Currency Required!').nullable(),
  logo: Yup.mixed().required('A file is required!').test('File Size',
    'File Size Should be Less Than 1MB', (value) => !value || (value && value.size <= 1024 * 1024))
    .test('FILE_FORMAT', 'Uploaded file has unsupported format.',
      (value) => !value || (value && ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'].includes(value.type)))
})

export const editTenantSchema = (logo) => Yup.object().shape({
  name: Yup.string()
    .max(200, 'Name must be of lesser then 200 characters!!')
    .required('Name Required!')
    .matches(/^([a-zA-Z]+\s)*[a-zA-Z]+$/, 'Only Alphabets and Single Space Between Words Allowed'),
  domain: Yup.string()
    .max(200, 'Domain cannot be larger than 200 characters!')
    .matches(
      /^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
      'Enter correct url!'
    )
    .required('Domain Required!'),
  allowedCurrencies: Yup.array().min(1, 'Allowed Currencies Required!'),
  primaryCurrency: Yup.string().required('Primary Currency Required!').nullable(),
  logo: typeof logo !== 'string' && Yup.mixed()
    .test('File Size',
      'File Size Should be Less Than 1MB',
      (value) => !value || (value && value.size <= 1024 * 1024))
    .test('FILE_FORMAT', 'Uploaded file has unsupported format.',
      (value) => !value || (value && ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'].includes(value.type)))
})
