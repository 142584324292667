import { Button } from '@themesberg/react-bootstrap'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoyaltyPoint from '../../../../../components/LoyaltyPoints/LoyaltyPoint'
import { getloyaltyLevelStart } from '../../../../../store/redux-slices/superAdminSettings'

const LoyaltySettings = ({
  handleSubmit,
  handleChange,
  handleBlur,
  values,
  setSelectedTab,
  isTenant,
  setFieldValue,
  touched,
  selectedTab,
  loyaltyCount,
  setLoyaltyCount
}) => {
  const { loyaltyLevel, loading } = useSelector(state => state.superAdminSettings)
  const { bonusDetail } = useSelector(state => state.bonus)

  const dispatch = useDispatch()
  useEffect(() => {
    values?.tenantIds?.[0]?.value && dispatch(getloyaltyLevelStart({ isTenant, tenantId: values?.tenantIds?.[0]?.value }))
  }, [values?.tenantIds?.[0]?.value])

  useEffect(() => {
    const newLoyaltyLevel = []
    if ((!values?.loyaltyLevel || values?.loyaltyLevel.length < 1) && (values?.bonusType === 'deposit' || values?.bonusType === 'wagering') && selectedTab === 'loyalty') {
      for (const level in loyaltyLevel) {
        const obj = loyaltyLevel[level]
        newLoyaltyLevel.push({
          level: obj.level,
          startPoint: obj.startPoint,
          endPoint: obj.endPoint,
          bonusPercentage: obj.cashback_multiplier,
          cashback_multiplier: values?.wageringMultiplier,
          maxBonusThreshold: obj?.maxBonusThreshold || '',
          maxWinAmount: obj?.maxWinAmount || ''
        })
      }
      setFieldValue('loyaltyLevel', newLoyaltyLevel)
    }
  }, [])

  useEffect(() => {
    if (values?.other && bonusDetail?.wageringMultiplier === values?.wageringMultiplier && loyaltyCount < 3) {
      const newLoyaltyLevel = []
      for (const level in loyaltyLevel) {
        const obj = loyaltyLevel[level]
        newLoyaltyLevel.push({
          level: obj.level,
          startPoint: obj.startPoint,
          endPoint: obj.endPoint,
          bonusPercentage: (values?.other?.[level]?.bonusPercentage * 100).toFixed(2),
          cashback_multiplier: values?.other?.[level]?.cashback_multiplier,
          maxBonusThreshold: values?.other?.[level]?.maxBonusThreshold,
          maxWinAmount: values?.other?.[level]?.maxWinAmount
        })
      }
      setFieldValue('loyaltyLevel', newLoyaltyLevel)
      setLoyaltyCount(loyaltyCount + 1)
    }
  }, [loyaltyLevel])

  return (
    <>
      <LoyaltyPoint
        values={values}
        handleBlur={handleBlur}
        handleChange={handleChange}
        touched={touched}
        bonus
        loading={loading}
      />
      <div className='mt-4 d-flex justify-content-between align-items-center'>
        <Button
          variant='outline-warning'
          onClick={() => {
            setSelectedTab('currency')
          }}
          className='ml-2'
        >
          Previous
        </Button>
        <Button
          variant='outline-success'
          onClick={handleSubmit}
          className='ml-2'
        >
          Next
        </Button>
      </div>
    </>
  )
}

export default LoyaltySettings
