import React from 'react'

import {
  faArrowCircleDown,
  faArrowCircleUp
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Row, Table } from '@themesberg/react-bootstrap'

import { useProductsReport } from './hooks/usePlayersReport'
import { SORT_ORDER_HEADINGS, tableHeaders } from './playersReportConstant'
import PaginationComponent from '../../../components/Pagination'

const PlayersReport = () => {
  const {
    setOrderBy,
    selected,
    sort,
    setSort,
    over,
    setOver,
    loading,
    playersReport,
    page,
    setPage,
    totalPages
  } = useProductsReport()


  return (
    <>
      <div className='section-header-wrap mt-n3 mx-n3'>
        <Row>
          <Col lg={8} xs={7}>
            <h2 className='title-text'>
              Players Report
            </h2>
          </Col>
        </Row>
      </div>
      <div className='TableBg mt-4 pt-1'>
        <Table striped responsive hover size='sm' className='text-center mt-3'>
          <thead className='thead-light'>
            <tr>
              {tableHeaders.map((h, idx) => (
                <th
                  key={idx}
                  onClick={() =>
                    (SORT_ORDER_HEADINGS.includes(h.value)) &&
                    setOrderBy(h.value)}
                  style={{
                    cursor: 'pointer'
                  }}
                  className={selected(h) ? 'border-3 border border-blue' : ''}
                >
                  {h.label}{' '}
                </th>
              ))}
            </tr>
          </thead>

          <tbody id={loading ? 'cover-spin' : ''}>
            {
              Boolean(playersReport.rows) &&
              playersReport.rows.map(
                ({
                  userId,
                  betAmount,
                  winAmount,
                  ggr,
                  gameName
                }, indx) => {
                  return (
                    <tr key={indx}>
                      <td>{userId}</td>
                      <td>{gameName}</td>
                      <td>{betAmount?.toFixed(2) || 0} R$</td>
                      <td>{winAmount?.toFixed(2) || 0} R$</td>
                      <td>{ggr?.toFixed(2) || 0} R$</td>
                    </tr>
                  )
                }
              )}

            {playersReport?.count === 0 && !loading && (
              <tr>
                <td colSpan={13} className='text-danger text-center'>
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        {playersReport?.count !== 0 && !loading && (
          <PaginationComponent
            page={page}
            totalPages={totalPages}
            setPage={setPage}
          />
        )}
      </div>
    </>
  )
}

export default PlayersReport
