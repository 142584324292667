export const transformProductReports = (productsReport) => {
    const {gameReport} = productsReport?.data
    const transformProductReportsData = {
        data: {
            gameReport: []
        }
    }

    transformProductReportsData.data.gameReport = gameReport?.map((product)=>{

        const productcData  = {
            ...product
        }

        try {
            productcData.productName = JSON.parse(product?.subCategoryName)?.EN 
        } catch (error) {
            productcData.productName = 'N/A'
            
        }

        return productcData
    })

    return transformProductReportsData;
}