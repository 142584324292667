import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { useNavigate } from 'react-router-dom'

import { getCountriesListStart } from '../../../store/redux-slices/sportsBook'

const useCountriesListing = () => {
  const dispatch = useDispatch()

  const [limit, setLimit] = useState(10)
  const [pageNo, setPageNo] = useState(1);
  const [searchByName, setSearchByName] = useState("");
  const [searchByStatus, setSearchByStatus] = useState("");

  const { sportsCountriesInfo, isGetCountriesLoading } = useSelector(state => state.sportsBook)
  const totalPages = Math.ceil(sportsCountriesInfo?.countryList?.count / limit)
  // useEffect(() => {
  //     dispatch(getCountriesListStart({
  //         limit: limit,
  //         pageNo: pageNo,
  //         search: searchByName
  //     }))
  // }, []);

  useEffect(() => {
    dispatch(getCountriesListStart({
      limit: limit,
      pageNo: pageNo,
      search: searchByName,
      isActive: searchByStatus === '1' ? 'true' : searchByStatus === '0' ? 'false' : ''
    })
    )
  }, [limit, pageNo, searchByName, searchByStatus])

  return {
    limit,
    setLimit,
    pageNo,
    setPageNo,
    sportsCountriesInfo,
    totalPages,
    isGetCountriesLoading,
    searchByName,
    setSearchByName,
    searchByStatus,
    setSearchByStatus
  }
}

export default useCountriesListing
