import { takeLatest, put } from 'redux-saga/effects'
import {
  getLanguages,
  getLanguageSupportKeys,
  uploadLanguageCSV,
  updateLanguageSupport,
  getCMSLanguage
} from '../../utils/apiCalls'
import {
  getLanguagesStart,
  getLanguagesSuccess,
  getLanguagesFailure,
  getLanguageSupportKeysStart,
  getLanguageSupportKeysSuccess,
  getLanguageSupportKeysFailure,
  uploadLanguageCSVStart,
  uploadLanguageCSVComplete,
  updateLanguageSupportStart,
  updateLanguageSupportComplete,
  getCMSLanguagesStart,
  getCMSLanguagesSuccess,
  getCMSLanguagesFailure
} from '../redux-slices/languages'
import { toast } from '../../components/Toast'
import { serialize } from 'object-to-formdata'
import { AdminsRoutes } from '../../routes'

export default function* languagesWatcher() {
  yield takeLatest(getLanguagesStart.type, getLanguagesWorker)
  yield takeLatest(getLanguageSupportKeysStart.type, getLanguageSupportKeysWorker)
  yield takeLatest(uploadLanguageCSVStart.type, uploadLanguageCSVWorker)
  yield takeLatest(updateLanguageSupportStart.type, updateLanguageSupportWorker)
  yield takeLatest(getCMSLanguagesStart.type, getCMSLanguagesWorker)
}

function* getLanguagesWorker(action) {
  try {
    const { limit, pageNo, name } = action && action.payload

    const { data } = yield getLanguages({ limit, pageNo, name })

    yield put(getLanguagesSuccess(data?.data?.languages))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(getLanguagesFailure(e?.response?.data?.errors[0].description))
  }
}

function* getLanguageSupportKeysWorker(action) {
  try {
    const { tenantId, language, isTenant } = action && action.payload

    const { data } = yield getLanguageSupportKeys({ tenantId, language, isTenant })

    yield put(getLanguageSupportKeysSuccess(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(getLanguageSupportKeysFailure(e?.response?.data?.errors[0].description))
  }
}

function* uploadLanguageCSVWorker(action) {
  try {
    let { data, isTenant, tenantId, language } = action && action.payload
    data = serialize(data)

    yield uploadLanguageCSV({ data, isTenant })

    yield put(uploadLanguageCSVComplete())
    yield toast('File Uploaded Successfully', 'success')

    yield put(getLanguageSupportKeysStart({ isTenant, tenantId, language }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(uploadLanguageCSVComplete())
  }
}

function* updateLanguageSupportWorker(action) {
  try {
    const { data, isTenant, tenantId, navigate } = action && action.payload

    yield updateLanguageSupport({ data, isTenant })

    yield put(updateLanguageSupportComplete())
    data?.type === 'create'
      ? yield toast('Language Support Keys Created Successfully', 'success')
      : yield toast('Language Support Keys Updated Successfully', 'success')
    if (data?.type === 'key') {
      navigate(`/admin/language-management/edit/${Object.keys(data?.data)?.[0]}`)
    } else if (data?.type === 'create') {
      navigate(AdminsRoutes.LanguageManagement)
    }
    yield put(getLanguageSupportKeysStart({ isTenant, tenantId, language: '' }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(updateLanguageSupportComplete())
  }
}

function* getCMSLanguagesWorker(action) {
  try {
    const { adminId = '', tenantId = '' } = action && action.payload

    const { data } = yield getCMSLanguage({
      adminId: adminId === null ? '' : adminId,
      tenantId: tenantId === null ? '' : tenantId
    })
    yield put(getCMSLanguagesSuccess(Object.keys(data?.data?.allowedLanguage)))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(getCMSLanguagesFailure(e?.response?.data?.errors[0].description))
  }
}
