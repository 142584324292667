import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteEmailTemplatesStart, getAllemailTemplatesStart, getImageGalleryStart, makePrimaryEmailTemplatesStart, resetEmailTemplate } from '../../../../store/redux-slices/emailTemplate'
import useCheckPermission from '../../../../utils/checkPermission'

const useEmailTemplate = () => {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const [type, setType] = useState(false)
  const [lang, setLang] = useState('EN')
  const [templateData, setTemplateData] = useState('')
  const { tenantDetails } = useSelector(state => state.login)
  const { isHidden } = useCheckPermission()
  const setModalData = (template, templateCode) => {
    setType(template)
    setTemplateData(templateCode)
    setShow(true)
  }

  const handleDeleteItems = (data) => {
    dispatch(deleteEmailTemplatesStart(data))
  }
  const { emailTemplate, emailTemplates, templateCount, loading, emailTemplateOrder } = useSelector((state) => state.emailTemplate)

  useEffect(() => {
    dispatch(getAllemailTemplatesStart({ isTenant: true }))
    !isHidden({ module: { key: 'ImageGallery', value: 'R' } }) && dispatch(getImageGalleryStart({ isTenant: true }))
  }, [])

  const resetEmail = () => dispatch(resetEmailTemplate())

  return {
    emailTemplate,
    emailTemplates,
    loading,
    show,
    setShow,
    setModalData,
    templateData,
    templateCount,
    makePrimaryEmailTemplatesStart,
    dispatch,
    isHidden,
    emailTemplateOrder,
    languages: tenantDetails?.tenantConfig?.allowedLanguages,
    lang,
    setLang,
    resetEmail,
    type,
    handleDeleteItems
  }
}

export default useEmailTemplate
