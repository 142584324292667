import React from 'react';
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded'
import {
    Button,
} from '@themesberg/react-bootstrap'
const ActionMenu = (props) => {
    const { userId, navigate } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl)
    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    return (
        <>
            <Button
                variant='btn text-btn'
                id='basic-button'
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <MoreVertRoundedIcon />
            </Button>
            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
            >
                <MenuItem onClick={handleClose}>
                    <Button
                        variant='btn text-btn'
                        onClick={() => navigate(
                            `/admin/users/details/${userId}`
                        )}
                    >
                        <RemoveRedEyeRoundedIcon />
                        <span className="text">View</span>
                    </Button>
                </MenuItem>
            </Menu>
        </>
    )
};

export default ActionMenu;