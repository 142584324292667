export const reasonOptions = [
  'Image Not Visible Properly',
  'Not A Valid Document',
  'Document Validation Expired',
  'Add Custom Reason'
]

export const tableHeaders = [
  { label: 'Id', value: 'userBonusId' },
  { label: 'Promotion Title', value: 'promotionTitle' },
  { label: 'Bonus Type', value: 'bonusType' },
  { label: 'Valid Till', value: 'validTo' },
  { label: 'Is Expired', value: 'isExpired' },
  { label: 'Status', value: 'isActive' },
  { label: 'Issued By', value: 'issuerRole' },
  { label: 'Issued At', value: 'createdAt' },
  { label: 'Amount To Wager', value: 'amountToWager' },
  { label: 'Wagered Amount', value: 'wageredAmount' },
  { label: 'Cancelled By', value: 'cancelledBy' },
  { label: 'Updated At', value: 'updatedAt' },
  { label: 'Action', value: 'action' }
]

export const bonusStatus = [
  { label: 'All', value: '' },
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Pending', value: 'PENDING' },
  { label: 'Cancelled', value: 'CANCELLED' },
  { label: 'Forfeited', value: 'FORFEITED' },
  { label: 'Expired', value: 'EXPIRED' },
  { label: 'Completed', value: 'COMPLETED' }
]

export const bonusTypes = [
  { label: 'All', value: '' },
  { label: 'MATCH', value: 'match', id: 0 },
  { label: 'BALANCE', value: 'balance', id: 1 },
  { label: 'FREESPINS', value: 'freespins', id: 2 },
  { label: 'DEPOSIT(CASHBACK)', value: 'deposit', id: 4 },
  { label: 'WAGERING(CASHBACK)', value: 'wagering', id: 5 }
]

export const affiliateList = [
  { label: 'Parent Affiliate ID', value: 'parentIdAffiliate' },
  { label: 'Parent Username', value: 'parentUsername' },
  { label: 'Affiliate ID', value: 'affiliateId' },
  { label: 'User Name', value: 'username' },
  { label: 'First Name', value: 'firstName' },
  { label: 'Last Name', value: 'lastName' },
  { label: 'Email', value: 'email' },
  { label: 'Country', value: 'country' },
  { label: 'Currency', value: 'currency' },
  { label: 'Mobile', value: 'mobile' }
]

export const userDisableReasons = [
  'Bonus abuser',
  'Duplicate account',
  'Fraudulent',
  'Risk country',
  'Rude behaviour',
  'Bannec country - connect with VPN or Tor Browser',
  'KYC declined',
  'Suspicios - constant deposits and withdrawals (money laundering)',
  'Contacts support for bigger amount of deposit than allowed',
  'Resonsible Gambling',
  'pending',
  'frozen',
  'deactivated',
  'permbanned',
  'tempbannedblocked',
  'gambling_issues',
  'self_excluded',
  'dpo_erasure_requested',
  'kyc_blocked_suspended',
  'kyc_blocked_duplicate',
  'kyc_blocked_bonus_abuse',
  'kyc_blocked_chargeback',
  'kyc_fraud',
  'failed_kyc',
  'aml_failed_sow',
  'aml_management_ban',
  'aml_on_hold',
  'aml_under_review',
  'rg_underaged',
  'rg_under_review',
  'enforced_self_ex',
  'location_ban',
  'cs_management_ban',
  'validated',
  'Other'
]

export const transactionType = [
  { label: 'All', value: 'all' },
  { label: 'Bet', value: 'bet' },
  { label: 'Win', value: 'win' },
  { label: 'RollBack', value: 'rollback' },
  { label: 'Pre RollBack', value: 'rollbackbeforebetwin' },
  { label: 'Free Spin', value: 'freespins' }
]

export const statusType = [
  { label: 'All', value: 'all' },
  { label: 'Pending', value: 'pending' },
  { label: 'Complete', value: 'completed' },
  { label: 'Failed', value: 'failed' },
  { label: 'RollBack', value: 'rollback' }
]


export const transactionBankingTableHeaders = [
  { label: 'Username', value: 'transactionUser.username' },
  { label: 'Transaction ID', value: 'transactionBankingUUID' },
  { label: 'Actionee', value: 'actioneeEmail' },
  // { label: 'Payment Provider', value: 'paymentProvider' },
  { label: 'Amount', value: 'amount' },
  { label: 'Action Type', value: 'transactionType' },
  { label: 'Actionee Type', value: 'actioneeType' },
  { label: 'Status', value: 'status' },
  { label: 'Created At', value: 'createdAt' }
]

export const transactionBankingTransactionType = [
  { label: 'Deposit', value: 'deposit' },
  { label: 'Withdraw', value: 'withdraw' },
  { label: 'Add Money', value: 'addMoney' },
  { label: 'Remove Money', value: 'removeMoney' },
  { label: 'Internal Deposit', value: 'depositInternal' },
  { label: 'Internal Add Money', value: 'addMoneyInternal' },
  { label: 'Internal Withdraw', value: 'withdrawInternal' },
  { label: 'Internal Remove Money', value: 'removeMoneyInternal' }
]

export const transactionBankingStatusType = [
  { label: 'All', value: '' },
  { label: 'Pending', value: 0 },
  { label: 'Complete', value: 1 },
  { label: 'Failed', value: 2 },
  { label: 'Requested', value: 7 }
]

export const walletType = ['Cash', 'Bonus']
