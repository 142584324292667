import { Reorder } from '@mui/icons-material'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import {
  Form as BForm,
  Button,
  Col,
  Row
} from '@themesberg/react-bootstrap'
import { motion } from 'framer-motion/dist/framer-motion'
import React from 'react'
import BonusListing from '../../../components/BonusListing'
import { AdminsRoutes } from '../../../routes'
import useCheckPermission from '../../../utils/checkPermission'
import { tableHeaders } from './bonusConstant'
import { bonusType } from './components/CreateBonus/constants'
import useBonus from './hooks/useBonus'

const BonusManagement = () => {
  const {
    bonusList,
    navigate,
    loading,
    handleShow,
    show,
    setShow,
    handleYes,
    active,
    limit,
    setLimit,
    setPage,
    page,
    totalPages,
    bonusTyp,
    setBonusTyp,
    search,
    setSearch,
    isActive,
    setIsActive,
    name,
    showDeleteModal,
    setShowDeleteModal
  } = useBonus()
  const { isHidden } = useCheckPermission()
  return (
    <>

      <div className='section-header-wrap mt-n3 mx-n3'>
        <Row>
          <Col lg={4} xs={12}>
            <h2 className='title-text'>Bonus </h2>
          </Col>
          <Col xs={12} sm={8}>
            <div className='d-flex justify-content-end align-items-center'>
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Button
                  className='m-1'
                  variant='btn btn-primary'
                  hidden={isHidden({ module: { key: 'Bonus', value: 'C' } })}
                  onClick={() => navigate(AdminsRoutes.CreateBonus)}
                >
                  <AddCircleOutlineRoundedIcon className='me-2' />
                  <span>Criar</span>
                </Button>
                {/* <Button
                  className='m-1'
                  variant='btn btn-primary'
                  hidden={isHidden({ module: { key: 'Bonus', value: 'U' } })}
                  onClick={() => navigate(AdminsRoutes.BonusReorder)}
                >
                  <Reorder className='me-2' />

                  Reorder
                </Button> */}
              </motion.div>
            </div>
          </Col>

        </Row>
      </div>

      <div className='FormsBg'>
        <Row className='mt-2'>
          <Col xs={12} sm={3}>

            <BForm.Select
              name='bonusType'
              size='sm'
              value={bonusTyp}
              onChange={(e) => setBonusTyp(e.target.value)}
            >
              <option value='' key=''>
               Tipo Bonus
              </option>
              {bonusType.map((item) => {
                return (
                  item.value !== 'cashfreespins' &&
                  <option key={`bonusType ${item.value}`} value={item.value}>
                    {item.value === 'freespins' ? 'FREESPINS' : item?.label}
                  </option>
                )
              })}
            </BForm.Select>
          </Col>

          <Col xs={12} sm={3}>
            <BForm.Control
              type='search'
              value={search}
              placeholder='Título da promoção de pesquisa'
              size='sm'
              onChange={(event) => setSearch(event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, ''))}
            />
          </Col>

          <Col xs={12} sm={3}>

            <BForm.Select
              name='isActive'
              size='sm'
              value={isActive}
              onChange={(e) => setIsActive(e.target.value)}
            >
              <option value='' key=''>
                Status
              </option>
              <option value='true' key='active'>
                Ativos
              </option>
              <option value='false' key='in-active'>
               Inativos
              </option>
            </BForm.Select>
          </Col>
        </Row>
      </div>

      <div className='TableBg mt-4'>
        <BonusListing
          bonusList={{ ...bonusList, rows: bonusList?.rows?.filter((bonus) => bonus.bonusType !== 'referral') || [] }}
          limit={limit}
          setLimit={setLimit}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          tableHeaders={tableHeaders}
          show={show}
          setShow={setShow}
          handleYes={handleYes}
          active={active}
          handleShow={handleShow}
          navigate={navigate}
          name={name}
          loading={loading}
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
        />
      </div>
    </>
  )
}

export default BonusManagement
