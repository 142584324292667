import { takeLatest, put } from 'redux-saga/effects'
import {
  getAllCasinoProviders,
  getAllCasinoGames,
  createCasinoProvider,
  createCasinoGame,
  updateCasinoGame,
  updateCasinoProvider,
  superAdminViewToggleStatus,
  getSuperAdminAggregators,
  createSuperAdminAggregator,
  getSuperAdminGameCategory,
  getSuperAdminGameSubCategory,
  getAllSAProviders,
  getFreeSpinGames,
  updateReorderGames,
  deleteTenantAdminCategory,
  deleteTenantAdminSubCategory,
  deleteTenantAdminCasinoProvider,
  deleteTenantAdminCasinoGames,
  updateCasinoSubCategory,
  updateCasinoCategory,
  createCasinoSubCategory,
  createCasinoCategory,
  addGamesToSubCategory,
  updateSubCategoryReOrder,
  updateCategoryReOrder
} from '../../utils/apiCalls'
import {
  getAllCasinoProvidersStart,
  getAllCasinoProvidersSuccess,
  getAllCasinoProvidersFailure,
  createCasinoProviderStart,
  createCasinoProviderComplete,
  updateCasinoProviderStart,
  updateCasinoProviderComplete,
  getAllCasinoGamesStart,
  getAllCasinoGamesSuccess,
  getAllCasinoGamesFailure,
  createCasinoGameStart,
  createCasinoGameComplete,
  updateCasinoGameStart,
  updateCasinoGameComplete,
  resetCasinoGameStart,
  resetCasinoGameSuccess,
  updateCasinoStatusStart,
  updateCasinoStatusComplete,
  getSuperAdminAggregatorsStart,
  getSuperAdminAggregatorsSuccess,
  getSuperAdminAggregatorsFailure,
  createSuperAdminAggregatorStart,
  createSuperAdminAggregatorComplete,
  updateSuperAdminAggregatorStatusStart,
  updateSuperAdminAggregatorStatusComplete,
  getSuperAdminGameCategoryStart,
  getSuperAdminGameCategorySuccess,
  getSuperAdminGameCategoryFailure,
  getSuperAdminGameSubCategoryStart,
  getSuperAdminGameSubCategorySuccess,
  getSuperAdminGameSubCategoryFailure,
  updateSACasinoGamesStatusStart,
  updateSACasinoGamesStatusComplete,
  getAllSAProvidersStart,
  getAllSAProvidersSuccess,
  getAllSAProvidersFailure,
  getFreeSpinGamesStart,
  getFreeSpinGamesSuccess,
  getFreeSpinGamesFailure,
  updateReorderGamesStart,
  updateReorderGamesSuccess,
  updateReorderGamesFailure,
  deleteCasinoSubCategoriesStart,
  deleteCasinoSubCategoriesSuccess,
  updateCasinoStart,
  updateCasinoComplete,
  createCasinoStart,
  createCasinoComplete,
  addGamesToSubCategoryStart,
  addGamesToSubCategoryComplete,
  updateCategoryReOrderStart,
  updateCategoryReOrderComplete
} from '../redux-slices/superAdminCasinoManagement'
import { toast } from '../../components/Toast'

import { objectToFormData } from '../../utils/objectToFormdata'
import { getAllGamesStart } from '../redux-slices/tenantCasino'

export default function * superAdminCasinoManagementWatcher () {
  yield takeLatest(getAllCasinoProvidersStart.type, getAllCasinoProvidersWorker)
  yield takeLatest(getAllCasinoGamesStart.type, getAllCasinoGamesWorker)
  yield takeLatest(createCasinoGameStart.type, createCasinoGameWorker)
  yield takeLatest(createCasinoProviderStart.type, createCasinoProviderWorker)
  yield takeLatest(updateCasinoGameStart.type, updateCasinoGameWorker)
  yield takeLatest(updateCasinoProviderStart.type, updateCasinoProviderWorker)
  yield takeLatest(updateCasinoStart.type, updateCasinoWorker)
  yield takeLatest(createCasinoStart.type, createCasinoWorker)
  yield takeLatest(resetCasinoGameStart.type, resetCasinoGameWorker)
  yield takeLatest(updateCasinoStatusStart.type, updateCasinoStatusWorker)
  yield takeLatest(getSuperAdminAggregatorsStart.type, getSuperAdminAggregatorsWorker)
  yield takeLatest(createSuperAdminAggregatorStart.type, createSuperAdminAggregatorWorker)
  yield takeLatest(updateSuperAdminAggregatorStatusStart.type, updateSuperAdminAggregatorStatusWorker)
  yield takeLatest(getSuperAdminGameCategoryStart.type, getSuperAdminGameCategoryWorker)
  yield takeLatest(getSuperAdminGameSubCategoryStart.type, getSuperAdminGameSubCategoryWorker)
  yield takeLatest(updateSACasinoGamesStatusStart.type, updateSACasinoGamesStatusWorker)
  yield takeLatest(getAllSAProvidersStart.type, getAllSAProvidersWorker)
  yield takeLatest(getFreeSpinGamesStart.type, getFreeSpinGamesWorker)
  yield takeLatest(updateReorderGamesStart.type, updateReorderGamesWorker)
  yield takeLatest(deleteCasinoSubCategoriesStart.type, deleteCasinoWorker)
  yield takeLatest(addGamesToSubCategoryStart.type,addGamesToSubCategoryWorker)
  yield takeLatest(
    updateCategoryReOrderStart.type,updateSubCategoryOrder
  )
}

function * getAllCasinoProvidersWorker (action) {
  try {
    const { limit, pageNo, search = '' } = action && action.payload
    const { data } = yield getAllCasinoProviders({
      limit,
      pageNo,
      search
    })

    yield put(getAllCasinoProvidersSuccess(data?.data?.providerList))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(getAllCasinoProvidersFailure(e?.response?.data?.errors[0].description))
  }
}

function * resetCasinoGameWorker () {
  yield put(resetCasinoGameSuccess())
}

function * addGamesToSubCategoryWorker (action) {
  try {
    const { gameSubCategoryId, games, navigate } = action && action.payload

    yield addGamesToSubCategory({
      gameSubCategoryId: parseInt(gameSubCategoryId),
      games
    })

    yield toast('Games Added To Sub Category', 'success')

    // navigate(TenantRoutes.CasinoSubCategory)

    yield put((addGamesToSubCategoryComplete()))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put((addGamesToSubCategoryComplete()))
  }
}

function * updateSubCategoryOrder (action) {
  try {
    console.log('action :>> ', action);
    const { data, navigate, isSubCategory } = action && action.payload
    console.log('data :>> ', data);
    isSubCategory ? yield updateSubCategoryReOrder(data) : updateCategoryReOrder(data)
    yield put(updateCategoryReOrderComplete())
    yield toast(`Casino${isSubCategory ? ' Sub' : ''} Category Updated Successfully`, 'success')
    let adminCasinoSubCategoryRoute = data?.isPrimaryOrder ? '/admin/casino-sub-category' :'/admin/sub-category-secondary-order' 
    navigate(isSubCategory ? adminCasinoSubCategoryRoute : '/admin/casino-category')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * getAllCasinoGamesWorker (action) {
  try {
    const { bonusId, limit, pageNo, casinoCategoryId, search, isActive, tenantId, selectedProvider, freespins,addGame,gameSubCategoryId,reorder } = action && action.payload

    const { data } = yield getAllCasinoGames({
      limit,
      pageNo,
      casinoCategoryId,
      search,
      isActive,
      tenantId,
      selectedProvider,
      freespins: freespins || '',
      bonusId: bonusId || '',
      addGame:addGame||false,
      gameSubCategoryId:gameSubCategoryId||"",
      reorder:reorder||"false"
      

    })

    yield put(getAllCasinoGamesSuccess(data?.data?.casinoGames))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllCasinoGamesFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * createCasinoProviderWorker (action) {
  try {
    const { data, limit, pageNo, search } = action && action.payload

    yield createCasinoProvider(objectToFormData(data))
    yield put(getAllCasinoProvidersStart({ limit, pageNo, search }))

    yield toast('Provider created', 'success')
    yield put(createCasinoProviderComplete())
  } catch (e) {
    yield put(createCasinoProviderComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * createCasinoWorker (action) {
  try {
      const { data, limit, pageNo, search,code } = action && action.payload
  
      switch(code) {
        case 'CASINO_CATEGORY':
          yield createCasinoCategory((data))     
          yield put(getSuperAdminGameCategoryStart({
            limit, pageNo, search, isActive: '', gameCategoryId: '', tenantId: ''
          }))  
          yield toast('Category created', 'success')
          break;
        case 'CASINO_PROVIDER':
          yield createCasinoProvider(objectToFormData(data))
          yield put(getAllCasinoProvidersStart({
            limit, pageNo, search, isActive: '', gameCategoryId: '', tenantId: ''
          })) 
          yield toast('Provider created', 'success')
          // code block
          break;
          case 'CASINO_SUB_CATEGORY':
            yield createCasinoSubCategory(data)   
            yield put(getSuperAdminGameSubCategoryStart({
              limit, pageNo, search, isActive: '', gameCategoryId: '', tenantId: ''
            }))
            yield toast('casino Sub-Category created', 'success')
        
              break;
          case 'CASINO_GAME':
            yield createCasinoGame(objectToFormData(data))
            yield put(getAllCasinoGamesStart({
              limit, pageNo, search, isActive: '', gameCategoryId: '', tenantId: ''
            }))
            yield toast('casino games created', 'success')
            break;
  
          
        default:{
             return null
        }
      }
  
       yield put(createCasinoComplete())
    } catch (e) {
       yield put(createCasinoComplete())
  
      yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}


function * createCasinoGameWorker (action) {
  try {
    const { data, casinoProviderId, limit, pageNo } = action && action.payload
    yield createCasinoGame(objectToFormData(data))

    yield toast('Game created', 'success')
    if (casinoProviderId) {
      yield put(getAllCasinoGamesStart({
        casinoProviderId,
        limit,
        pageNo
      }))
    }

    yield put(createCasinoGameComplete())
  } catch (e) {
    yield put(createCasinoGameComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateCasinoProviderWorker (action) {
  try {
    const { data, limit, pageNo, search } = action && action.payload

    yield updateCasinoProvider(objectToFormData(data))

    yield toast('Provider updated', 'success')
    yield put(getAllCasinoProvidersStart({ limit, pageNo, search }))

    yield put(updateCasinoProviderComplete())
  } catch (e) {
    yield put(updateCasinoProviderComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateCasinoWorker (action) {
  try {
    const { data, limit, pageNo, search,code } = action && action.payload

    switch(code) {
      case 'CASINO_CATEGORY':
        yield updateCasinoCategory(data)     
        yield put(getSuperAdminGameCategoryStart({
          limit, pageNo, search, isActive: '', gameCategoryId: '', tenantId: ''
        }))  
        yield toast('Category updated', 'success')
        break;
      case 'CASINO_PROVIDER':
        yield updateCasinoProvider(objectToFormData(data))
        yield put(getAllCasinoProvidersStart({
          limit, pageNo, search, isActive: '', gameCategoryId: '', tenantId: ''
        })) 
        yield toast('Provider updated', 'success')
        // code block
        break;
        case 'CASINO_SUB_CATEGORY':
          yield updateCasinoSubCategory(objectToFormData(data))   
          yield put(getSuperAdminGameSubCategoryStart({
            limit, pageNo, search, isActive: '', gameCategoryId: '', tenantId: ''
          }))
          yield toast('casino Sub-Category updated', 'success')
      
            break;
        case 'CASINO_GAME':
          yield updateCasinoGame(objectToFormData(data))
          yield put(getAllCasinoGamesStart({
            limit,
            pageNo,
            casinoCategoryId:'',
            search,
            isActive:"",
            tenantId:"",
            selectedProvider:"",
            freespins:   '',
            bonusId:""
          }))
          yield toast('casino games updated', 'success')
          break;

        
      default:{

      }
    }

     yield put(updateCasinoComplete())
  } catch (e) {
     yield put(updateCasinoComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateCasinoGameWorker (action) {
  try {
    const { data, casinoProviderId, limit, pageNo } = action && action.payload

    yield updateCasinoGame(objectToFormData(data))

    yield put(updateCasinoGameComplete())

    yield put(getAllCasinoGamesStart({
      casinoProviderId,
      limit,
      pageNo
    }))

    yield toast('Game updated', 'success')
  } catch (e) {
    yield put(updateCasinoGameComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateCasinoStatusWorker (action) {
  try {
    const { data, limit, pageNo, search } = action && action.payload
    yield superAdminViewToggleStatus(data)
    yield toast('Status updated', 'success')

    yield put(getAllCasinoProvidersStart({ limit, pageNo, search }))
    yield put(getSuperAdminGameSubCategoryStart({limit,pageNo,search,gameCategoryId:'',isActive:''}))
    yield put(updateCasinoStatusComplete())
  } catch (e) {
    yield put(updateCasinoStatusComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}


function * deleteCasinoWorker (action) {
  try {
    const {
      data,
      limit,
      pageNo,
      casinoCategoryId,
      search,
      isActive,
      tenantId,
      selectedProvider,
      gameSubCategoryId,
      casinoGameId
    } = action && action.payload


    switch(data.code) {
      case 'CASINO_CATEGORY':
        yield deleteTenantAdminCategory()     
        yield put(getSuperAdminGameCategoryStart({
          data,
          limit,
          pageNo,
          casinoCategoryId,
          search,
          isActive,
          tenantId,
          selectedProvider
        }))  
        break;
      case 'CASINO_PROVIDER':
        yield deleteTenantAdminCasinoProvider()  
        yield put(getAllCasinoProvidersStart({
          data,
          limit,
          pageNo,
          casinoCategoryId,
          search,
          isActive,
          tenantId,
          selectedProvider
        })) 
        // code block
        break;
        case 'CASINO_SUB_CATEGORY':
          yield deleteTenantAdminSubCategory({gameSubCategoryId:gameSubCategoryId})   
          yield put(getSuperAdminGameSubCategoryStart({
            data,
            limit,
            pageNo,
            casinoCategoryId:"",
            search,
            isActive:"",
            tenantId:"",
            selectedProvider:"",
            gameCategoryId:""
          }))      
            break;
        case 'CASINO_GAME':
          yield deleteTenantAdminCasinoGames({casinoGameId:casinoGameId}) 
          yield put(getAllCasinoGamesStart({
            data,
            limit,
            pageNo,
            casinoCategoryId:"",
            search,
            isActive:"",
            tenantId:"",
            selectedProvider:"",
            gameCategoryId:""
          }))
          break;

        
      default:{

      }
        // code block
        yield toast('Status updated', 'success')

    }
    

    

    yield put(updateSACasinoGamesStatusComplete())
  } catch (e) {
    yield put(updateSACasinoGamesStatusComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateSACasinoGamesStatusWorker (action) {
  try {
    const {
      data,
      limit,
      pageNo,
      casinoCategoryId,
      search,
      isActive,
      tenantId,
      selectedProvider
    } = action && action.payload

    yield superAdminViewToggleStatus(data)
    yield toast('Status updated', 'success')

    switch(data.code) {
      case 'CASINO_CATEGORY':
        yield put(getSuperAdminGameCategoryStart({
          data,
          limit,
          pageNo,
          casinoCategoryId,
          search,
          isActive,
          tenantId,
          selectedProvider
        }))  
        break;
      case 'CASINO_PROVIDER':
        // code block
        break;
        case 'CASINO_SUB_CATEGORY':
          yield put(getSuperAdminGameCategoryStart({
            data,
            limit,
            pageNo,
            casinoCategoryId,
            search,
            isActive,
            tenantId,
            selectedProvider
          }))      
            break;
        case 'CASINO_GAME':
          yield put(getAllCasinoGamesStart({
            data,
            limit,
            pageNo,
            casinoCategoryId,
            search,
            isActive,
            tenantId,
            selectedProvider
          }))
          break;

        
      default:
        // code block
    }
    yield put(deleteCasinoSubCategoriesSuccess())
  } catch (e) {
    yield put(deleteCasinoSubCategoriesSuccess())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getSuperAdminAggregatorsWorker (action) {
  try {
    const { limit, pageNo } = action && action.payload
    const { data } = yield getSuperAdminAggregators({
      limit,
      pageNo
    })

    yield put(getSuperAdminAggregatorsSuccess(data?.data?.aggregators))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(getSuperAdminAggregatorsFailure(e?.response?.data?.errors[0].description))
  }
}

function * createSuperAdminAggregatorWorker (action) {
  try {
    const { data, limit, pageNo } = action && action.payload
    yield createSuperAdminAggregator(data)

    yield toast('Aggregator Created', 'success')

    yield put(createSuperAdminAggregatorComplete())
    yield put(getSuperAdminAggregatorsStart({ limit, pageNo }))
  } catch (e) {
    yield put(createSuperAdminAggregatorComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateSuperAdminAggregatorStatusWorker (action) {
  try {
    const { data, limit, pageNo } = action && action.payload

    yield superAdminViewToggleStatus(data)

    yield toast('Status Updated', 'success')

    yield put(updateSuperAdminAggregatorStatusComplete())
    yield put(getSuperAdminAggregatorsStart({ limit, pageNo }))
  } catch (e) {
    yield put(updateSuperAdminAggregatorStatusComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getSuperAdminGameCategoryWorker (action) {
  try {
    const { limit, pageNo, search, tenantId } = action && action.payload
    const { data } = yield getSuperAdminGameCategory({
      limit,
      pageNo,
      search,
      tenantId
    })

    yield put(getSuperAdminGameCategorySuccess(data?.data?.casinoCategories))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(getSuperAdminGameCategoryFailure(e?.response?.data?.errors[0].description))
  }
}

function * getSuperAdminGameSubCategoryWorker (action) {
  try {
    const { limit, pageNo, search, gameCategoryId, isActive, tenantId } = action && action.payload
    const { data } = yield getSuperAdminGameSubCategory({
      limit,
      pageNo,
      search,
      gameCategoryId,
      isActive,
      tenantId
    })

    yield put(getSuperAdminGameSubCategorySuccess(data?.data?.casinoSubCategory))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(getSuperAdminGameSubCategoryFailure(e?.response?.data?.errors[0].description))
  }
}

function * getAllSAProvidersWorker () {
  try {
    const { data } = yield getAllSAProviders()

    yield put(getAllSAProvidersSuccess(data?.data?.providerList))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllSAProvidersFailure())
  }
}

function * getFreeSpinGamesWorker (action) {
  try {
    const { limit, pageNo, providerId, search, bonusId } = action && action.payload

    const { data } = yield getFreeSpinGames({
      limit, pageNo, providerId, search, bonusId, isTenant: false
    })

    yield put(getFreeSpinGamesSuccess(data?.data?.gameWithFreespin))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getFreeSpinGamesFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * updateReorderGamesWorker (action) {
  try {
    const { data, casinoCategoryId } = action && action.payload
    const res = yield updateReorderGames({ data })

    yield put(updateReorderGamesSuccess())
    yield put(
      getAllGamesStart({
        limit: '',
        pageNo: '',
        casinoCategoryId,
        isActive: '',
        orderBy: '',
        sort: '',
        providerId: ''
      })
    )
    yield toast(res.data.message, 'success')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateReorderGamesFailure(e?.response?.data?.errors[0]?.description))
  }
}
