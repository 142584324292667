import { createSlice } from '@reduxjs/toolkit'

const affiliateSlice = createSlice({
  name: 'affiliates',
  initialState: {
    loading: false,
    error: null,
    success: false,
    data: null,
    affiliateDetails: null,
    affiliatePermissions: null,
    affiliateDashboard: null,
    affiliateProductsReport: null,
    affiliatePlayersReport: null,
    affiliateCommissionReport: null,
    associatedPlayerDetails: null,
    associatePlayerCasinoTransactions: null,
    associatePlayerBankingTransactions: null,
    transactionBanking: null
  },
  reducers: {
    createAffiliateStart: (state) => ({
      ...state,
      loading: true
    }),
    createAffiliateSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      data: payload.createAffiliate
    }),
    createAffiliateFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    updateAffiliateStart: (state) => ({
      ...state,
      loading: true
    }),
    updateAffiliateSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      data: payload.updateAffiliate
    }),
    updateAffiliateFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    registerAffiliateStart: (state) => ({
      ...state,
      loading: true
    }),
    registerAffiliateSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
    }),
    registerAffiliateFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    affiliateAdminDetailsStart: (state) => ({
      ...state,
      loading: true
    }),
    affiliateAdminDetailsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      affiliateDetails: { ...state.affiliateDetails, ...payload },
      affiliatePermissions: payload?.userPermission?.permission
    }),
    affiliateAdminDetailsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    getAffiliateReferralCodeStart: (state, { payload }) => ({
      ...state,
      loading: true
    }),
    getAffiliateReferralCodeSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      affiliateDetails: { ...state.affiliateDetails, referralCode: payload.referralcode }
    }),
    getAffiliateReferralCodeFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    getAffiliateDashboardDataStart: (state) => {
      return { ...state, loading: true }
    },
    getAffiliateDashboardDataFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    getAffliateDashboardSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      affiliateDashboard: { ...state.affiliateDashboard, ...payload }
    }),
    getAffiliateProductsReportStart: (state, { payload }) => ({
      ...state,
      loading: true
    }),
    getAffiliateProductsReportSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      affiliateProductsReport: payload
    }),
    getAffiliateProductsReportFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false,
    }),
    getAffiliatePlayersReportStart: (state, { payload }) => ({
      ...state,
      loading: true
    }),
    getAffiliatePlayersReportSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      affiliatePlayersReport: payload
    }),
    getAffiliatePlayersReportFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false,
    }),
    getAffiliateCommissionReportStart: (state, { payload }) => ({
      ...state,
      loading: true
    }),
    getAffiliateCommissionReportSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      affiliateDashboard: { ...state.affiliateDashboard, ...payload }
    }),
    getAffiliateCommissionReportFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    getAffiliateCommissionLiveReportStart: (state, { payload }) => ({
      ...state,
      loading: true
    }),
    getAffiliateCommissionLiveReportSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      affiliateDashboard: { ...state.affiliateDashboard, ...payload }
    }),
    getSubAffiliateCommissionReportStart: (state, { payload }) => ({
      ...state,
      loading: true
    }),
    getSubAffiliateCommissionReportSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      affiliateDashboard: { ...state.affiliateDashboard, ...payload }
    }),
    getSubAffiliateLiveCommissionReportStart: (state, { payload }) => ({
      ...state,
      loading: true
    }),
    getSubAffiliateLiveCommissionReportSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      affiliateDashboard: { ...state.affiliateDashboard, ...payload }
    }),

    getChartReportStart: (state, { payload }) => ({
      ...state,
      loading: true
    }),

    getChartReportSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      affiliateDashboard: { ...state.affiliateDashboard, ...payload }
    }),
    getAffiliateCommissionLiveReportFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    getDashboardTotalReportsStart: (state, { payload }) => ({
      ...state,
      loading: true
    }),
    getDashboardTotalReportsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      affiliateDashboard: { ...state.affiliateDashboard, ...payload }
    }),
    getDashboardTotalReportsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    getAffiliateTopPlayersStart: (state, { payload }) => ({
      ...state,
      loading: true,
    }),
    getAffiliateTopPlayersSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      affiliateDashboard: { ...state.affiliateDashboard, ...payload }
    }),
    getAffiliateTopPlayersFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    getAssociatedPlayerDetailsStart: (state, { payload }) => ({
      ...state,
      loading: true
    }),
    getAssociatedPlayerDetailsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      associatedPlayerDetails: payload
    }),
    getAssociatedPlayerDetailsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    getAssociatedPlayerCasinoTransactionStart: (state, { payload }) => ({
      ...state,
      // loading:true
    }),
    getAssociatedPlayerCasinoTransactionSuccess: (state, { payload }) => ({
      ...state,
      // loading:false,
      // error:null,
      // success:true,
      associatePlayerCasinoTransactions: payload
    }),
    getAssociatedPlayerCasinoTransactionFailure: (state, { payload }) => ({
      ...state,
      // loading:false,
      error: payload,
      // success:false
    }),
    getAssociatePlayerBankingTransactionsStart: (state, { }) => ({
      // ...state,
      // loading:true
    }),
    getAssociatePlayerBankingTransactionsSuccess: (state, { payload }) => ({
      ...state,
      // loading:false,
      // error:null,
      // success:true,
      associatePlayerBankingTransactions: payload
    }),
    getAssociatePlayerBankingTransactionsFailure: (state, { payload }) => ({
      ...state,
      // loading:false,
      error: payload,
      // success:false
    }),
    getTransactionBankingStart: ((state) => ({
      ...state,
      // loading:true
    })),
    getTransactionBankingSuccess: ((state, { payload }) => ({
      ...state,
      // loading:false,
      // error:null,
      // success:true,
      transactionBanking: payload

    })),
    getTransactionBankingFailure: ((state, { payload }) => ({
      ...state,
      // loading:false,
      error: payload,
      // success:false
    })),
    updateAffiliateStatusStart: (state) => ({
      ...state,
      loading: true
    }),
    updateAffiliateStatusSuccess: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: true
    }),
    updateAffiliateStatusFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    })
  }
})

export default affiliateSlice.reducer

export const {
  createAffiliateStart,
  createAffiliateSuccess,
  createAffiliateFailure,
  updateAffiliateStart,
  updateAffiliateSuccess,
  updateAffiliateFailure,
  registerAffiliateStart,
  registerAffiliateSuccess,
  registerAffiliateFailure,
  affiliateAdminDetailsStart,
  affiliateAdminDetailsSuccess,
  affiliateAdminDetailsFailure,
  getAffiliateReferralCodeStart,
  getAffiliateReferralCodeSuccess,
  getAffiliateReferralCodeFailure,
  getAffiliateDashboardDataStart,
  getAffliateDashboardSuccess,
  getAffiliateDashboardDataFailure,
  getAffiliateProductsReportStart,
  getAffiliateProductsReportFailure,
  getAffiliateProductsReportSuccess,
  getAffiliatePlayersReportStart,
  getAffiliatePlayersReportSuccess,
  getAffiliatePlayersReportFailure,
  getAffiliateCommissionReportStart,
  getAffiliateCommissionReportSuccess,
  getAffiliateCommissionReportFailure,
  getAffiliateCommissionLiveReportStart,
  getAffiliateCommissionLiveReportSuccess,
  getAffiliateCommissionLiveReportFailure,
  getSubAffiliateCommissionReportStart,
  getSubAffiliateCommissionReportSuccess,
  getSubAffiliateCommissionReportFailure,
  getSubAffiliateLiveCommissionReportStart,
  getSubAffiliateLiveCommissionReportSuccess,
  getDashboardTotalReportsStart,
  getDashboardTotalReportsSuccess,
  getDashboardTotalReportsFailure,
  getAffiliateTopPlayersStart,
  getAffiliateTopPlayersSuccess,
  getAffiliateTopPlayersFailure,
  getAssociatedPlayerDetailsStart,
  getAssociatedPlayerDetailsSuccess,
  getAssociatedPlayerDetailsFailure,
  getAssociatedPlayerCasinoTransactionStart,
  getAssociatedPlayerCasinoTransactionSuccess,
  getAssociatedPlayerCasinoTransactionFailure,
  getAssociatePlayerBankingTransactionsStart,
  getAssociatePlayerBankingTransactionsSuccess,
  getAssociatePlayerBankingTransactionsFailure,
  getTransactionBankingStart,
  getTransactionBankingSuccess,
  getTransactionBankingFailure,
  updateAffiliateStatusStart,
  updateAffiliateStatusSuccess,
  updateAffiliateStatusFailure,
  getChartReportSuccess,
  getChartReportStart
} = affiliateSlice.actions
