import React from 'react'
import { Button, Row, Col, Table, ButtonGroup, Form } from '@themesberg/react-bootstrap'
import useWageringTemplate from './hooks/useWageringTemplate'
import ClientFilter from '../../../components/ClientFilter'
import Trigger from '../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
import PaginationComponent from '../../../components/Pagination'
import useCheckPermission from '../../../utils/checkPermission'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import { motion } from 'framer-motion/dist/framer-motion'
import ActionMenu from './ActionMenu'

export default () => {
  const {
    loading,
    wageringTemplates,
    setSelectedClient,
    setSelectedPortal,
    selectedClient,
    navigate,
    setLimit,
    setPageNo,
    setSearch,
    search,
    totalPages,
    limit,
    pageNo
  } = useWageringTemplate()
  const { isHidden } = useCheckPermission()

  return (
    <>
     <div className='section-header-wrap mt-n3 mx-n3'>
     <Row>
        <Col  lg={8} xs={7}>
          <h2 className='title-text'>Wagering Template List</h2>
        </Col>
        <Col  lg={4} xs={5}>
        <div className='d-flex justify-content-end'>
          <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
        <Button
             variant='btn btn-primary w-100'
              onClick={() =>
                navigate('/admin/wagering-template/create')}
              hidden={isHidden({ module: { key: 'WageringTemplate', value: 'C' } })}
            >
          <AddCircleOutlineRoundedIcon className='me-2' />
                  <span>Create</span>
            </Button>
            </motion.div>
            </div>
            </Col>
    </Row>

     </div>

     <div className='FormsBg'>
      <Row >
      
        <Col xs={3}>
          {/* <div className='d-flex justify-content-end align-items-center'> */}
            {/* <ClientFilter
              hasAllOptions
              setSelectedClient={setSelectedClient}
              setSelectedPortal={setSelectedPortal}
              selectedClient={selectedClient}
            /> */}
           

            <Form.Control
              type='search'
              placeholder='Search Templates'
              size='sm'
              value={search}
              onChange={(event) =>
                setSearch(
                  event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, '')
                )}
            />
            </Col>
      </Row>
      </div>
        <div className='TableBg mt-4 pt-1'>
        {wageringTemplates && wageringTemplates?.count !== 0 && !loading && (
        <PaginationComponent
          page={wageringTemplates?.count < pageNo ? setPageNo(1) : pageNo}
          totalPages={totalPages}
          setPage={setPageNo}
          limit={limit}
          setLimit={setLimit}
        />
      )}
        <Table  striped responsive hover size='sm' className='text-center pt-2'>
          <thead className='thead-light'>
            <tr>
              {['Template ID',
                'Template Name',
                'Action'
              ].map((h) => (
                <th key={h}>{h}</th>
              ))}
            </tr>
          </thead>

          <tbody id={loading ? 'cover-spin' : ''}>
            {!loading && wageringTemplates && wageringTemplates?.rows.length > 0
              ? wageringTemplates?.rows.map(({ wageringTemplateId, name }, idx) => {
                return (
                  <tr key={`wagering-template ${wageringTemplateId}`}>
                    <td>{wageringTemplateId}</td>
                    <td>{name}</td>

                    <td>
                    <ActionMenu
                    wageringTemplateId={wageringTemplateId}
									  isHidden={isHidden}
                    navigate={navigate}
								/>
                      {/* <ButtonGroup>
                        <Trigger message='View Details'>
                          <Button
                            className='m-1'
                            size='sm'
                            onClick={() =>
                              navigate(`/admin/wagering-template/details/${wageringTemplateId}`)}
                            hidden={isHidden({ module: { key: 'WageringTemplate', value: 'R' } })}
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </Button>
                        </Trigger>
                        <Trigger message='Edit'>
                          <Button
                            className='m-1'
                            size='sm'
                            onClick={() =>
                              navigate(`/admin/wagering-template/edit/${wageringTemplateId}`)}
                            hidden={isHidden({ module: { key: 'WageringTemplate', value: 'U' } })}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>
                        </Trigger>
                        <Trigger message='Clone'>
                          <Button
                            className='m-1'
                            size='sm'
                            onClick={() =>
                              navigate('/admin/wagering-template/create', { state: { isClone: true, wageringTemplateId } })}
                            hidden={isHidden({ module: { key: 'WageringTemplate', value: 'C' } })}
                          >
                            <FontAwesomeIcon icon={faCopy} />
                          </Button>
                        </Trigger>
                      </ButtonGroup> */}
                    </td>

                  </tr>
                )
              })
              : !loading && (
                <tr>
                  <td colSpan={3} className='text-danger text-center'>
                    No data found
                  </td>
                </tr>
                )}
          </tbody>
        </Table>
        
        </div>
      
     
    </>

  )
}
