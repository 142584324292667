import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    getAllCasinoCategoriesStart,
    getAllCasinoCategoriesSuccess,
    getAllCasinoCategoriesFailure,
    getAllSubCategoriesStart,
    getAllSubCategoriesSuccess,
    getAllSubCategoriesFailure,
    updateCategoryStatusStart,
    updateCategoryStatusComplete,
    createCasinoCategoryStart,
    createCasinoCategoryComplete,
    updateCasinoCategoryStart,
    updateCasinoCategoryComplete,
    updateSubCategoryStatusStart,
    updateSubCategoryStatusComplete,
    createSubCategoryStart,
    createSubCategoryComplete,
    updateSubCategoryStart,
    updateSubCategoryComplete,
    getAllGamesStart,
    getAllGamesSuccess,
    getAllGamesFailure,
    getAllMasterGamesStart,
    getAllMasterGamesSuccess,
    getAllMasterGamesFailure,
    addGamesToSubCategoryStart,
    addGamesToSubCategoryComplete,
    deleteTenantCasinoGameStart,
    deleteTenantCasinoGameComplete,
    updateTenantCasinoGameStatusStart,
    updateTenantCasinoGameStatusComplete,
    updateTenantCategoryGameStart,
    updateTenantCategoryGameComplete,
    updateCategoryReOrderStart,
    updateCategoryReOrderComplete,
    deleteCategoryStart,
    deleteCategoryComplete,
    deleteSubCategoryStart,
    deleteSubCategoryComplete,
    getAllTAProvidersStart,
    getAllTAProvidersSuccess,
    getAllTAProvidersFailure,
    getTAFreeSpinGamesStart,
    getTAFreeSpinGamesSuccess,
    getTAFreeSpinGamesFailure
  },
  reducer
} = createSlice({
  name: 'casinomenu',
  initialState: {
    loading: false,
    casinoCategories: null,
    subCategories: null,
    games: null,
    masterGames: null,
    allProviders: null
  },
  reducers: {
    getAllCasinoCategoriesStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllCasinoCategoriesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      casinoCategories: payload
    }),
    getAllCasinoCategoriesFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateCategoryStatusStart: (state) => ({
      ...state,
      loading: true
    }),
    updateCategoryStatusComplete: (state) => ({
      ...state,
      loading: false
    }),
    createCasinoCategoryStart: (state) => ({
      ...state,
      loading: true
    }),
    createCasinoCategoryComplete: (state) => ({
      ...state,
      loading: false
    }),
    updateCasinoCategoryStart: (state) => ({
      ...state,
      loading: true
    }),
    updateCasinoCategoryComplete: (state) => ({
      ...state,
      loading: false
    }),
    getAllSubCategoriesStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllSubCategoriesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      subCategories: payload
    }),
    getAllSubCategoriesFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateSubCategoryStatusStart: (state) => ({
      ...state,
      loading: true
    }),
    updateSubCategoryStatusComplete: (state) => ({
      ...state,
      loading: false
    }),
    createSubCategoryStart: (state) => ({
      ...state,
      loading: true
    }),
    createSubCategoryComplete: (state) => ({
      ...state,
      loading: false
    }),
    updateSubCategoryStart: (state) => ({
      ...state,
      loading: true
    }),
    updateSubCategoryComplete: (state) => ({
      ...state,
      loading: false
    }),
    getAllGamesStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllGamesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      games: payload
    }),
    getAllGamesFailure: (state) => ({
      ...state,
      loading: false
    }),
    getAllMasterGamesStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllMasterGamesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      masterGames: payload
    }),
    getAllMasterGamesFailure: (state) => ({
      ...state,
      loading: false
    }),
    addGamesToSubCategoryStart: (state) => ({
      ...state,
      loading: true
    }),
    addGamesToSubCategoryComplete: (state) => ({
      ...state,
      loading: false
    }),
    deleteTenantCasinoGameStart: (state) => ({
      ...state,
      loading: true
    }),
    deleteTenantCasinoGameComplete: (state) => ({
      ...state,
      loading: false
    }),
    updateTenantCasinoGameStatusStart: (state) => ({
      ...state,
      loading: true
    }),
    updateTenantCasinoGameStatusComplete: (state) => ({
      ...state,
      loading: false
    }),
    updateTenantCategoryGameStart: (state) => ({
      ...state,
      loading: true
    }),
    updateTenantCategoryGameComplete: (state) => ({
      ...state,
      loading: false
    }),
    updateCategoryReOrderStart: (state) => ({
      ...state,
      loading: true
    }),
    updateCategoryReOrderComplete: (state) => ({
      ...state,
      loading: false
    }),
    deleteCategoryStart: (state) => ({
      ...state,
      loading: true
    }),
    deleteCategoryComplete: (state) => ({
      ...state,
      loading: false
    }),
    deleteSubCategoryStart: (state) => ({
      ...state,
      loading: true
    }),
    deleteSubCategoryComplete: (state) => ({
      ...state,
      loading: false
    }),
    getAllTAProvidersStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllTAProvidersSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      allProviders: payload
    }),
    getAllTAProvidersFailure: (state) => ({
      ...state,
      loading: false
    }),
    getTAFreeSpinGamesStart: (state) => ({
      ...state,
      loading: true
    }),
    getTAFreeSpinGamesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      masterGames: payload
    }),
    getTAFreeSpinGamesFailure: (state) => ({
      ...state,
      loading: false
    })
  }
})

export default reducer

export {
  getAllCasinoCategoriesStart,
  getAllCasinoCategoriesSuccess,
  getAllCasinoCategoriesFailure,
  getAllSubCategoriesStart,
  getAllSubCategoriesSuccess,
  getAllSubCategoriesFailure,
  updateCategoryStatusStart,
  updateCategoryStatusComplete,
  createCasinoCategoryStart,
  createCasinoCategoryComplete,
  updateCasinoCategoryStart,
  updateCasinoCategoryComplete,
  updateSubCategoryStatusStart,
  updateSubCategoryStatusComplete,
  createSubCategoryStart,
  createSubCategoryComplete,
  updateSubCategoryStart,
  updateSubCategoryComplete,
  getAllGamesStart,
  getAllGamesSuccess,
  getAllGamesFailure,
  getAllMasterGamesStart,
  getAllMasterGamesSuccess,
  getAllMasterGamesFailure,
  addGamesToSubCategoryStart,
  addGamesToSubCategoryComplete,
  deleteTenantCasinoGameStart,
  deleteTenantCasinoGameComplete,
  updateTenantCasinoGameStatusStart,
  updateTenantCasinoGameStatusComplete,
  updateTenantCategoryGameStart,
  updateTenantCategoryGameComplete,
  updateCategoryReOrderComplete,
  updateCategoryReOrderStart,
  deleteCategoryStart,
  deleteCategoryComplete,
  deleteSubCategoryStart,
  deleteSubCategoryComplete,
  getAllTAProvidersStart,
  getAllTAProvidersSuccess,
  getAllTAProvidersFailure,
  getTAFreeSpinGamesStart,
  getTAFreeSpinGamesSuccess,
  getTAFreeSpinGamesFailure
}
