import React from "react";

import { Grid, CircularProgress } from "@mui/material";

import { DivLoaderConainer } from "./style";
const DivLoader = ({ className }) => {
    return (
        <Grid xs="12" className={className ? className : "py-5"}>
            <DivLoaderConainer>
                <CircularProgress />
            </DivLoaderConainer>
        </Grid>
    )
}

export default DivLoader;
