import styled from 'styled-components';

export const BoxContainer = styled.div`
    min-height: 300px;
`;
export const NoDataContainer = styled.div`
    margin: 0px auto;
    color: white;
    padding-top: 30px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
`;