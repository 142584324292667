import { Col, Row, Form as BForm, Button, Spinner } from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'

import Preloader from '../../../../components/Preloader'
import ProviderFilter from '../../../../components/ProviderFilter'
import { editWageringTemplate } from './wageringTemplateSchema'
import useEditWageringTemplate from '../hooks/useEditWageringTemplate'
import WageringListEdit from './WageringListEdit'
import { toast } from '../../../../components/Toast'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import { motion } from 'framer-motion/dist/framer-motion'
const EditWageringTemplate = () => {
  const {
    loading,
    navigate,
    selectedProvider,
    setSelectedProvider,
    gameContribution,
    casinoGamesData,
    setGameContribution,
    customValue,
    customValueHandler,
    wageringTemplateDetail,
    formSubmitHandler,
    search,
    setSearch,
    setContributionDefaultValue,
    contributionDefaultValue,
    page,
    limit,
    setLimit,
    setPage,
    totalPages
  } = useEditWageringTemplate()

  return (
    <>
      {loading
        ? <Preloader />
        : (
          <>
          <div className='section-header-wrap mt-n3 mx-n3'>
          <Row>
          <Col lg={8} xs={7}>
                <h2 className='title-text'>Edit Wagering Template: {wageringTemplateDetail?.name || ''}</h2>
              </Col>

              <Col lg={4} xs={5}>
              <div className='d-flex justify-content-end'>
          <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Button
                  variant='btn btn-primary w-100'
                  onClick={() => {
                    if (Object.keys(gameContribution).length < 1) {
                      toast('select at least one game', 'error')
                    } else if (!customValue) {
                      toast('enter custom value before save', 'error')
                    } else {
                      toast('Contribution Saved Successfully', 'success')
                      setContributionDefaultValue((oldValue) => {
                        return { ...oldValue, ...gameContribution }
                      })
                      setGameContribution({})
                    }
                  }}
                >
                  <AddCircleOutlineRoundedIcon className='me-2' />
                  <span>Save</span>
            </Button>
            </motion.div>
          </div>
              </Col>
            </Row>
          </div>
         

      
            {wageringTemplateDetail &&
              <Formik
                initialValues={{
                  name: wageringTemplateDetail?.name,
                  customValue: ''
                }}
                validationSchema={editWageringTemplate}
                onSubmit={(formValues) => {
                  formSubmitHandler(formValues)
                }}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  handleBlur
                }) => (
                  <Form>
                    <div className='FormsBg'>
                      <Row>
                        <Col xs='12' sm='3'>
                          <BForm.Label>
                            Template Name <span className='text-danger'>*</span>
                          </BForm.Label>

                          <BForm.Control
                            type='text'
                            name='name'
                            size='sm'
                            style={{ maxWidth: '400px' }}
                            placeholder='Enter Name'
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          <ErrorMessage
                            component='div'
                            name='name'
                            className='text-danger small-size-error'
                          />
                        </Col>
                        <Col xs='12' sm='3'>
                          <BForm.Label>
                            Custom Value
                          </BForm.Label>

                          <BForm.Control
                            type='number'
                            name='customValue'
                            size='sm'
                            min='0'
                            style={{ maxWidth: '400px' }}
                            placeholder='Custom Value'
                            value={customValue}
                            onChange={(e) => {
                              if (e.target.value >= 0) {
                                handleChange(e)
                                customValueHandler(e.target.value)
                              }
                            }}
                            onBlur={handleBlur}
                          />
                        </Col>
                        <Col xs='12' sm='3' className='align-items-center provider'>
                          <ProviderFilter
                            selectedProvider={selectedProvider}
                            setSelectedProvider={setSelectedProvider}
                          />
                        </Col>
                        <Col xs='12' sm='3'>
                          <BForm.Label>
                            Search
                          </BForm.Label>

                          <BForm.Control
                            type='search'
                            size='sm'
                            style={{ maxWidth: '400px' }}
                            placeholder='Search Game Name'
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onBlur={handleBlur}
                          />
                        </Col>
                      </Row>
                    </div>
                    <div className='TableBg mt-4'>
                    <Row>
                      {casinoGamesData &&
                        <WageringListEdit
                          gameContribution={gameContribution}
                          casinoGamesData={casinoGamesData || []}
                          setGameContribution={setGameContribution}
                          customValue={customValue}
                          defaultGames={contributionDefaultValue || {}}
                          page={page}
                          limit={limit}
                          setLimit={setLimit}
                          setPage={setPage}
                          totalPages={totalPages}
                        />}
                    </Row>

                    <div
                      className='mt-4 d-flex p-2 justify-content-between align-items-center'
                    >
                      <Button
                        className="btn-danger"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>

                      <div>
                        <Button
                          onClick={handleSubmit}
                        >
                          Submit
                          {loading && (
                            <Spinner
                              as='span'
                              animation='border'
                              size='sm'
                              role='status'
                              aria-hidden='true'
                            />
                          )}
                        </Button>
                      </div>
                    </div>
                    </div>
                  
                  </Form>
                )}
              </Formik>}             
          </>
          )}
    </>
  )
}

export default EditWageringTemplate
