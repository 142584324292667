import React, { useEffect, useState } from 'react';

import { getAffiliateLivesReport } from '../../../../utils/apiCalls';
import { DashboardHeadTitle, DashboardLiveContainer } from '../style';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';

import { Table } from "@themesberg/react-bootstrap";
import FormatDate from '../../../../utils/formatDate';


export const affiliateTableHeaders = [
  { label: 'Id da Live', value: 'liveId' },
  { label: 'Duração', value: 'timeReadable' },
 /*  { label: 'Visualizações', value: 'viewers' }, */
  { label: 'Visualizações Únicas', value: 'unique_total_viewers' },
  { label: 'Iniciado em:', value: 'startedAt' },
  { label: 'Finalizado em:', value: 'finishedAt' },
]

const AffiliateLives = ({ affiliate }) => {
  const [loading, setLoading] = useState(false);
  const [lives, setLives] = useState([]);

  const getAffiliateLives = async () => {
    setLoading(true);
    try {
      const res = await getAffiliateLivesReport({ streamerId: affiliate.streamerId });
      setLives(res.data.data.lives)
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getAffiliateLives()
  }, [])

  return (
    <DashboardLiveContainer>
      <Table striped responsive hover size="sm" className="text-center mt-3">
        <thead className="thead-light">
          <tr>
            {affiliateTableHeaders.map((h, idx) => (
              <th
                key={idx}
                style={{
                  cursor: "pointer",
                }}
              >
                {h.label}{" "}
              </th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? "cover-spin" : ""}>
          {!loading &&
            Boolean(lives) &&
            lives?.map(
              ({ liveId, timeReadable, viewers, unique_total_viewers, startedAt, finishedAt }) => {
                return (
                  <tr key={liveId}>
                    <td>{liveId}</td>
                    <td>{timeReadable}</td>
                   {/*  <td>{viewers.length}</td> */}
                    <td>{unique_total_viewers}</td>
                    <td>{startedAt ? FormatDate.default(startedAt) : "N/A"}</td>
                    <td>{finishedAt ? FormatDate.default(finishedAt) : "N/A"}</td>
                  </tr>
                );
              }
            )}

          {lives.length === 0 && !loading && (
            <tr>
              <td colSpan={13} className="text-danger text-center">
                Nenhum dado encontrado!
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </DashboardLiveContainer>
  )
}

export default AffiliateLives
