import styled from "styled-components";

export const TitleMain = styled.h6`
  color: #2e3650;
  font-weight: bold;
  font-size: 24px;
  flex-shrink: 0;
  margin: 0;
  @media (max-width: 1300px) {
    font-size: 14px;
  }
`;


export const StyledCardMain = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1190px;
  margin-left: 1.2rem;
  justify-content: space-between;
    @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const DataCard = styled.div`
  padding-right: 15px;
  border-right: 1px solid rgba(46, 54, 80, 0.1);
  padding-left: 15px;
  border-left: 1px solid rgba(46, 54, 80, 0.1);
  color: #1b1b4450;
  @media (max-width: 1100px) {
    font-size: 14px;
  }
`;


export const MainCard = styled.div`
  display: flex;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const StyledCard = styled.div`
  width: 100%;
  // max-width: 490px;
  display: flex;
  flex-direction: column;
  border-right: ${({ last }) =>
    last ? "none" : "1px solid rgba(46, 54, 80, 0.1)"};
  @media (max-width: 1100px) {
    border: none;
    max-width: 750px;
    border-bottom: ${({ last }) =>
    last ? "none" : "1px solid rgba(46, 54, 80, 0.1)"};
  }
  padding: 20px;
  gap: 1rem;
`;

export const CardInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const InfoLabel = styled.h6`
  color: #afb1bb;
  font-weight: bold;
  font-size: 0.85rem;
  margin: 0;
`;

export const InfoValue = styled.h6`
  color: #2e3650;
  font-weight: bold;
  font-size: 0.85rem;
  margin: 0;
  @media (max-width: 1300px) {
    font-size: 12px;
  }
`;

export const CardItemGraph = styled.div`
  display: flex;
  gap: 8px;
  border-right: 1px solid rgba(46, 54, 80, 0.1);
  padding-right: 15px;
  align-items: center;

`;

export const LegendLabelGraph = styled.h6`
  color: #1b1b44;
  font-size: 16px;
  margin: 0;
  @media (max-width: 1300px) {
    font-size: 12px;
  }
`;

export const ColorCircle = styled.div`
  background-color: ${(props) => props.color || "#000"};
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  flex-shrink: 0;
`;
