import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useDidMountEffect from '../../../utils/useDidMountEffect'
import { getSuperAdminGameCategoryStart, getSuperAdminGameSubCategoryStart, updateCategoryReOrderStart } from '../../../store/redux-slices/superAdminCasinoManagement'
import useCheckPermission from '../../../utils/checkPermission'

const useReorderSubCategories = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isInitialRender = useDidMountEffect()

  const { loading, gameCategory:casinoCategories, gameSubCategory:subCategories } = useSelector((state) => state.superAdminCasino)

  const [state, setState] = useState({ rows: [], count: 0 })
  const [categoryFilter, setCategoryFilter] = useState('')

  const reorder = (subCategories, startIndex, endIndex) => {
    const result = Array.from(subCategories)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  useEffect(() => {
    if (subCategories) {
      setState({
        ...subCategories,
        rows:subCategories.rows.toSorted((a,b)=>a.secondaryOrderId - b.secondaryOrderId)
      })
    }
  }, [subCategories])

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const rows = reorder(
      state.rows,
      result.source.index,
      result.destination.index
    )
    setState({ rows, count: rows.length })
  }

  const handleSave = () => {
    const row = []
    state.rows.map((list) => row.push(list.gameSubCategoryId))
    
    dispatch(updateCategoryReOrderStart({ data: { order: row, gameCategoryId: Number(categoryFilter),isPrimaryOrder:false }, navigate, isSubCategory: true }))
    dispatch(getSuperAdminGameCategoryStart({limit: '', pageNo: '', search: '', tenantId: ''  }))

  }

  useEffect(() => {
    dispatch(getSuperAdminGameCategoryStart({limit: '', pageNo: '', search: '', tenantId: ''  }))
  }, [])

  useEffect(() => {
    if (!isInitialRender) {
      dispatch(
        getSuperAdminGameSubCategoryStart({
          limit: '',
          pageNo: '',
          gameCategoryId: categoryFilter,
          search: '',
          isActive: '',
          orderBy: 'gameSubCategoryId',
          sort: 'desc',
          tenantId: ''
        })
      )
    }
  }, [categoryFilter])

  const getGameName = (id) => {
    const selected = subCategories?.rows?.find((obj) => obj.gameSubCategoryId === id)
    let casinoCategory
    try {
      casinoCategory = JSON.parse(selected?.name).EN
    } catch (error) {
      casinoCategory = selected?.name
    }
    return casinoCategory
  }

  const {isHidden} = useCheckPermission()

  return {
    loading,
    state,
    onDragEnd,
    handleSave,
    navigate,
    casinoCategories,
    categoryFilter,
    setCategoryFilter,
    getGameName,
    isHidden
  }
}

export default useReorderSubCategories
