import { Formik } from 'formik'
import React, { useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'

import { FormikErrorMessage } from '../../../../../components/ErrorMessage'
import Button from '../../../../../components/Button';
import UploadImageInput from '../../../../../components/UploadImageInput'
import { updateUserDocuments } from '../../../../../utils/apiCalls'
import { sendUserDocumentsSchema } from './form.schema';
import { serialize } from 'object-to-formdata';

import { toast } from '../../../../../components/Toast';
import { resolveErrors } from '../../../../../utils/errorTypes';

const SendUserDocumentModal = ({ show, setShow, userData, documentLabels, getUserDocuments }) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = async (formData) => {
    const promises = formData.documents.map(async (data) => {
      if (!data) return;
      const formData = {
        labelId: data.documentLabelId,
        [data.documentLabelName]: data.documentLabelFile,
      }
      await updateUserDocuments(serialize({ ...formData, userId: userData.userId }));
    });

    setLoading(true);
    try {
      await Promise.all(promises);
      setShow(false);
      getUserDocuments();
      toast("Documentos enviado com sucesso!", "success")
    } catch (err) {
      toast(resolveErrors(err?.response?.data?.errors, "Ocorreu um erro ao enviar dados de documento do usuário!"), "error");
    } finally {
      setLoading(false);
    }
  }


  return (
    <>
      <Formik
        initialValues={{}}
        validationSchema={sendUserDocumentsSchema()}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, handleChange, handleBlur, handleSubmit, touched, setFieldValue, setFieldTouched, errors }) => {
          return <Modal
            show={true/* show */}
            onHide={() => setShow(false)}
            backdrop='static'
            keyboard={false}
            size='xl'
          >
            <Modal.Header closeButton>
              <h5>Enviar documentos KYC de {userData?.username}</h5>
            </Modal.Header>
            <Modal.Body>
              <Row>
                {documentLabels.length ? documentLabels.map(({ documentLabelId, isRequired, name }, key) => {

                  return (
                    <Col key={key} className='col-sm-4 col-12'>
                      <Form.Label>{name?.PT || name?.EN}</Form.Label>
                      <UploadImageInput orientation={"landscape"} name={`document-${key}`} onChangeFile={(file) => {
                        setFieldValue(`documents.${key}.documentLabelId`, documentLabelId);
                        setFieldValue(`documents.${key}.documentLabelFile`, file);
                        setFieldValue(`documents.${key}.documentLabelName`, (name?.PT || name?.EN));
                      }} />
                    </Col>
                  )
                }) : null}
              </Row>

            </Modal.Body>
            <div className='mt-4 '>
              <Modal.Footer className='d-flex justify-content-between align-items-center'>
                <Button
                  variant='outline-warning'
                  onClick={() => setShow(false)}
                >
                  Cancelar
                </Button>
                <Button
                  variant='outline-success'
                  onClick={handleSubmit}
                  className='ml-2'
                  loading={loading}
                >
                  Enviar
                </Button>
              </Modal.Footer>
            </div>
          </Modal>
        }}

      </Formik>
    </>
  )
}

export default SendUserDocumentModal
