import React, { useState } from "react";

import { Button, Form, Row, Col, Table } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleDown,
  faArrowCircleUp,
  faRedoAlt,
} from "@fortawesome/free-solid-svg-icons";

import Trigger from "../../../components/OverlayTrigger";
import { useProductsReport } from "./hooks/useProductsReport";
import { tableHeaders } from "./productsReportConstant";
// import ActionMenu from "./components/ActionMenu";
import { useDispatch } from 'react-redux';
import PaginationComponent from '../../../components/Pagination/index';

const ProductsReport = () => {
  const [filterState, setFilterState] = useState({
    emailNameUserName: '',
    userId: '',
    phoneNumber: '',
  })
  const {
    state,
    setState,
    orderBy,
    setOrderBy,
    selected,
    sort,
    setSort,
    over,
    setOver,
    loading,
    productsReport,
    navigate,
    page,
    setPage,
    limit,
    setLimit,
    totalPages
  } = useProductsReport({ filterState });

  return (
    <>
      <div className="section-header-wrap mt-n3 mx-n3">
        <Row>
          <Col lg={8} xs={7}>
            <h2 className="title-text">
              Products Report
            </h2>
          </Col>
        </Row>
      </div>
      <div className="TableBg mt-4 pt-1">
        <Table striped responsive hover size="sm" className="text-center mt-3">
          <thead className="thead-light">
            <tr>
              {tableHeaders.map((h, idx) => (
                <th
                  key={idx}
                  onClick={() =>
                    (h.value === "product" ||
                      h.value === "winLoss" ||
                      h.value === "activePlayers") &&
                    setOrderBy(h.value)
                  }
                  style={{
                    cursor: "pointer",
                  }}
                  className={selected(h) ? "border-3 border border-blue" : ""}
                >
                  {h.label}{" "}
                  {selected(h) &&
                    (sort === "ASC" ? (
                      <FontAwesomeIcon
                        style={over ? { color: "red" } : {}}
                        icon={faArrowCircleUp}
                        onClick={() => setSort("DESC")}
                        onMouseOver={() => setOver(true)}
                        onMouseLeave={() => setOver(false)}
                      />
                    ) : (
                      <FontAwesomeIcon
                        style={over ? { color: "red" } : {}}
                        icon={faArrowCircleDown}
                        onClick={() => setSort("ASC")}
                        onMouseOver={() => setOver(true)}
                        onMouseLeave={() => setOver(false)}
                      />
                    ))}
                </th>
              ))}
            </tr>
          </thead>

          <tbody id={loading ? "cover-spin" : ""}>
            {
              Boolean(productsReport.rows) &&
              productsReport.rows?.map(
                ({
                  userId,
                  betAmount,
                  winAmount,
                  ggr,
                  commission,
                  productName
                }) => {
                  return (
                    <tr key={userId}>
                      <td>{productName || 'N/A'} </td>
                      <td>{betAmount?.toFixed(2) || 0} R$</td>
                      <td>{winAmount?.toFixed(2) || 0} R$</td>
                      <td>{(winAmount / betAmount)?.toFixed(2) || 0}</td>
                      <td>{commission?.toFixed(2) || 0} R$</td>
                      <td>{ggr?.toFixed(2) || 0} R$</td>
                    </tr>
                  );
                }
              )}

            {productsReport?.count === 0 && !loading && (
              <tr>
                <td colSpan={13} className="text-danger text-center">
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {/* {productsReport?.count !== 0 && !loading && (
          <PaginationComponent
            page={productsReport?.count < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
          />
        )} */}
      </div>
    </>
  );
};
export default ProductsReport