import { Table } from '@themesberg/react-bootstrap'
import React from 'react'
import { currencySymbols } from '../DemographicWIthMap/demographicConstant'

export default ({ playerLiabilityData }) => {
  return (
    <Table  striped responsive hover size='sm' className='text-center mt-4'>
      <thead className='thead-light'>
        <tr>
          <th>Portal</th>
          <th>Currency</th>
          <th>Liability</th>
        </tr>
      </thead>

      <tbody>
        {playerLiabilityData && playerLiabilityData?.map(({
          liability, currencyCode, name
        }, index) => {
          return (
            <tr key={index}>
              <td>{name}</td>
              <td>{currencyCode}</td>
              <td>{currencySymbols[currencyCode]}{' '}{liability}</td>
            </tr>
          )
        })}
      </tbody>

      <tbody>
        <tr>
          {
        playerLiabilityData?.length < 1 && (
          <td className='text-danger' colSpan={3}>Registros não encontrados.</td>
        )
      }
        </tr>
      </tbody>

    </Table>
  )
}
