import * as Yup from 'yup'
import FormatNumber from '../../../utils/formatNumber';

export const affliateInitialFormData = (affiliate) => {
  return {
    email: affiliate?.email ?? null,
    username: affiliate?.username ?? null,
    firstName: affiliate?.firstName ?? null,
    lastName: affiliate?.lastName ?? null,
    country: affiliate?.country ?? null,
    affiliateType: affiliate?.affiliateType ?? null,
    commission: affiliate?.commission?.commission ?? 0,
    liveCommission: affiliate?.commission?.liveCommission ?? 0,
    parentAffiliateCommission: affiliate?.commission?.parentAffiliateCommission ?? 0,
    parentAffiliateLiveCommission: affiliate?.commission?.parentAffiliateLiveCommission ?? 0,
    bio: affiliate?.bio ?? null,
    avatar: affiliate?.avatar ?? null,
    cpf: affiliate?.cpf ?? null,
    phone: affiliate?.phone ?? null,
    isVisible: affiliate ? affiliate?.isVisible : true,
    enableSubAffiliatesSystem: affiliate?.enableSubAffiliatesSystem ?? null,

    cpa: affiliate?.cpa ?? 0,
    cpaCommission: affiliate?.commission?.cpaCommission ?? 0,
    cpaMinimumDepositAmount: affiliate?.cpaMinimumDepositAmount ?? 0,
  }
}

export const createAffiliateSchema = Yup.object().shape({
  email: Yup.string().nullable()
    .email('Informe um e-mail válido')
    .max(200)
    .required('O e-mail é obrigatório'),
  firstName: Yup.string().min(3, 'Informe no minímo 3 caracteres').nullable()
    .max(200)
    .matches(/^[a-zA-Z\u00C0-\u00FF ]+$/i, 'Only Alphabets and Space Allowed and Must Start with Alphabet')
    .required('O nome é obrigatório'),
  lastName: Yup.string().min(3, 'Informe no miními 3 caracteres').nullable()
    .max(200)
    .matches(/^[a-zA-Z\u00C0-\u00FF ]+$/i, 'Only Alphabets and Space Allowed and Must Start with Alphabet')
    .required('O sobrenome é obrigatório'),
  username: Yup.string().nullable()
    .matches(/^[a-zA-z0-9_-]+$/, 'Only Alphabets Allowed')
    .min(3)
    .max(100)
    .required('Nome de usuário é obrigatório'),
  gender: Yup.string().nullable()
    .oneOf(['male', 'female', 'others'], 'Must match of the values'),
  affiliateType: Yup.number().nullable().required('O tipo de afiliado é obrigatório'),
  bio: Yup.string().nullable().max(250, 'Escreva no máximo 250 caracteres'),
  avatar: Yup.string().nullable(),
  phone: Yup.string().nullable(),
  cpf: Yup.string('Informe um CPF válido').nullable()
    .test('valid-cpf', 'Informe um CPF válido', (value) => {
      if (!value) return false; // Reject if CPF is empty
      const match = value.match(/^\d{3}\.?\d{3}\.?\d{3}\-?\d{2}$/);
      if (!match) return false; // Reject if CPF format is invalid
      const cpf = match[0].replace(/\.|-/g, '');
      if (cpf.length !== 11) return false; // Reject if CPF length is not 11 characters
      return true; // CPF is valid
    }),
  commission: Yup.number('Informe um valor de 0 à 100').nullable()
    .min(0, 'Informe um valor de 0 à 100')
    .max(100, 'Informe um valor de 0 à 100')
    .required('A comissão é obrigatório'),
  liveCommission: Yup.number('Informe um valor de 0 à 100').nullable()
    .min(0, 'Informe um valor de 0 à 100')
    .max(100, 'Informe um valor de 0 à 100'),

  cpa: Yup.number().nullable(),
  cpaCommission: Yup.number().nullable(),
  cpaMinimumDepositAmount: Yup.number().nullable().min(Yup.ref("cpaCommission"), ({ min }) => `O valor deve ser igual ou superior à ${FormatNumber.currency(min)}`),
})

export const updateAffiliateSchema = Yup.object().shape({
  email: Yup.string().nullable()
    .email('Informe um e-mail válido')
    .max(200)
    .required('O e-mail é obrigatório'),
  password: Yup.string().nullable()
    .matches(
      // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      /^(?!.*(\d)\1\1)[A-Za-z0-9~!@#$%^&*()]{8,}$/gm,
      'Invalid Password'
    )
    .max(50),
  firstName: Yup.string().min(3, 'Informe no minímo 3 caracteres').nullable()
    .max(200)
    .matches(/^[a-zA-Z\u00C0-\u00FF ]+$/i, 'Only Alphabets and Space Allowed and Must Start with Alphabet')
    .required('O nome é obrigatório'),
  lastName: Yup.string().min(3, 'Informe no miními 3 caracteres').nullable()
    .max(200)
    .matches(/^[a-zA-Z\u00C0-\u00FF ]+$/i, 'Only Alphabets and Space Allowed and Must Start with Alphabet')
    .required('O sobrenome é obrigatório'),
  username: Yup.string().nullable()
    .matches(/^[a-zA-z0-9_-]+$/, 'Only Alphabets Allowed')
    .min(3)
    .max(100)
    .required('Nome de usuário é obrigatório'),
  gender: Yup.string().nullable()
    .oneOf(['male', 'female', 'others'], 'Must match of the values'),
  affiliateType: Yup.number().nullable().required('O tipo de afiliado é obrigatório'),
  bio: Yup.string().nullable().max(250, 'Escreva no máximo 250 caracteres'),
  avatar: Yup.string().nullable(),
  phone: Yup.string().nullable(),
  cpf: Yup.string('Informe um CPF válido').nullable()
    .test('valid-cpf', 'Informe um CPF válido', (value) => {
      if (!value) return false; // Reject if CPF is empty
      const match = value.match(/^\d{3}\.?\d{3}\.?\d{3}\-?\d{2}$/);
      if (!match) return false; // Reject if CPF format is invalid
      const cpf = match[0].replace(/\.|-/g, '');
      if (cpf.length !== 11) return false; // Reject if CPF length is not 11 characters
      return true; // CPF is valid
    }),

  commission: Yup.number('Informe um valor de 0 à 100').nullable()
    .min(0, 'Informe um valor de 0 à 100')
    .max(100, 'Informe um valor de 0 à 100')
    .required('A comissão é obrigatório'),
  liveCommission: Yup.number('Informe um valor de 0 à 100').nullable()
    .min(0, 'Informe um valor de 0 à 100')
    .max(100, 'Informe um valor de 0 à 100'),

  cpa: Yup.number().nullable(),
  cpaCommission: Yup.number().nullable(),
  cpaMinimumDepositAmount: Yup.number().nullable().min(Yup.ref("cpaCommission"), ({ min }) => `O valor deve ser igual ou superior à ${FormatNumber.currency(min)}`),
})

export const updateSuperAdminUserSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .max(200)
    .required('Email Required'),
  firstName: Yup.string().min(3, 'First Name must be atleast 3 characters')
    .max(200)
    .matches(/^[a-zA-Z\u00C0-\u00FF ]+$/i, 'Only Alphabets and Space Allowed and Must Start with Alphabet')
    .required('First Name Required'),
  lastName: Yup.string().min(3, 'Last Name must be atleast 3 characters')
    .max(200)
    .matches(/^[a-zA-Z\u00C0-\u00FF ]+$/i, 'Only Alphabets and Space Allowed and Must Start with Alphabet')
    .required('Last Name Required'),
  password: Yup.string()
    .matches(
      // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      /^(?!.*(\d)\1\1)[A-Za-z0-9~!@#$%^&*()]{8,}$/gm,
      'Invalid Password'
    )
    .max(50),
  role: Yup.string().required('Role Required').nullable(),
  adminId: Yup.string().when('role', {
    is: (role) => role === 'Support',
    then: Yup.string().required('Parent Admin is required').nullable(),
    otherwise: Yup.string().nullable()
  }),
  adminUsername: Yup.string()
    .matches(/^[a-zA-Z][a-zA-Z0-9_]+$/, 'Must Start with Alphabet.Only Alphabets, Numbers and UnderScore(_) allowed')
    .min(8, 'Minimum 8 characters required')
    .max(100)
    .required('User Name Required'),
  group: Yup.string().min(3, 'Group Name must be atleast 3 characters')
    .max(200)
    .matches(/^[A-Za-z0-9 ]+$/, 'Only Alphabets, Numbers and Space Allowed')
    .required('Group Name Required').nullable()
})
