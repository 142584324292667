export const tableHeaders = [
    {label:'Product',value:'product'},
    {label:'Bet Amount',value:'betAmount'},
    {label:'Win Amount',value:'winAmount'},
    {label:'Win/Bet',value:'win/bet'},
    {label:'Commission',value:'commission'},
    {label:'GGR',value:'ggr'},
    // { label: 'Active Players', value: 'activePlayers' },
    // {label:'Bets', value: 'bets' },
    // { label: 'Wins', value: 'wins' },
    // { label: 'Win/Loss', value: 'winLoss' },
]