import { Button, Form, FormCheck, InputGroup, Modal, Table } from '@themesberg/react-bootstrap'
import { ErrorMessage, Formik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup';
import { InlineLoader } from '../../../../components/Preloader';
import { manageAffiliateMoney } from '../../../../utils/apiCalls';
import { toast } from '../../../../components/Toast';
import FormatNumber from '../../../../utils/formatNumber';
import { resolveErrors } from '../../../../utils/errorTypes';

export const depositSchema = () =>
    Yup.object().shape({
      amount: Yup.number()
        .typeError('Apenas números são permitidos')
        .min(0, 'O valor precisa ser maior que R$ 0,00')
        .max(10000,'O valor precisa ser menor que R$ 1.000,00')
        .required('O campo valor é requerido'),
      transactionType: Yup.string()
        .required('O tipo de transação é requerido'),
       affiliateId: Yup.number()
    })
  

const ManageMoneyModal = ({ show, handleClose, affiliate, updateAffiliateData }) => {
    const [ loading, setLoading ] = useState(false);

    return (
        <>
        <Formik
            initialValues={{
            amount: "",
            transactionType: '',
            affiliateId: affiliate?.affiliateId,
            }}
            validationSchema={depositSchema}
            onSubmit={async (formValues, { resetForm }) => {
                setLoading(true)
                try {
                    await manageAffiliateMoney(formValues)

                    handleClose()
                    resetForm({ formValues: '' })
                    toast("Alteração realizada com sucesso!", "success")
                    updateAffiliateData()
                } catch(err) {
                    const message = resolveErrors(err.response.data?.errors, "Ocorreu um erro ao gerenciar dinheiro para o afiliado")
                    toast(message, "error")
                } finally {
                    setLoading(false)
                }
            }}
            enableReinitialize
        >
            {({ values, touched, errors, isValid, handleChange, handleBlur, handleSubmit, resetForm }) => (
            <Form>
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop='static'
                    keyboard={false}
                >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h4>Gerenciar o dinheiro de: '{affiliate?.firstName} {affiliate?.lastName}'</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='form-group mb-3'>
                    <label className='mb-2'>
                        Tipo de transação
                    </label>
                    <div className="row">
                        <div className="col">
                            <FormCheck.Input
                            type='radio'
                            id='addMoney'
                            name='transactionType'
                            label='Adicionar Dinheiro'
                            aria-label='Adicionar Dinheiro'
                            value='addMoney'
                            style={{ marginRight: '10px' }}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />
                            <FormCheck.Label htmlFor='addMoney'>Adicionar Dinheiro</FormCheck.Label>
                        </div>
                        <div className="col">
                            <FormCheck.Input
                            type='radio'
                            id='removeMoney'
                            name='transactionType'
                            label='Remove Money'
                            aria-label='Remove Money'
                            value='removeMoney'
                            style={{ marginRight: '10px' }}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />
                            <FormCheck.Label htmlFor='removeMoney'>Remover Dinheiro</FormCheck.Label>
                        </div>
                    </div>
                    </div>

                    <div className='form-group'>
                    <label htmlFor='amount' className={touched.amount && errors.amount ? 'text-danger' : ''}>
                        Valor
                    </label>

                    <InputGroup
                        className={touched.amount && errors.amount
                        ? 'border border-danger'
                        : ''}
                    >
                        <InputGroup.Text>
                        {affiliate?.currency ||"R$"}
                        </InputGroup.Text>

                        <Form.Control
                        name='amount'
                        type='number'
                        step='any'
                        placeholder='Insira o valor'
                        value={values.amount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        />
                    </InputGroup>

                    <ErrorMessage
                        component='div'
                        name='amount'
                        className='text-danger'
                    />
                    </div>
                    <div className='mt-3 d-none'>Carteira: { FormatNumber.currency(affiliate?.affiliateWallet?.amount) }</div>
                </Modal.Body>
                <div className=''>
                    <Modal.Footer className='d-flex justify-content-between align-items-center'>
                        <Button
                            variant='outline-warning'
                            onClick={() => {
                            resetForm({ formValues: '' })
                            handleClose(false)
                            }}
                            disabled={loading}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant='outline-success'
                            className='ml-2'
                            data-loading={loading}
                            disabled={!isValid}
                            onClick={handleSubmit}
                            >
                            <span>Enviar</span>
                            {loading && <InlineLoader/>}
                        </Button>
                    </Modal.Footer>
                </div>
                </Modal>
            </Form>
            )}
        </Formik>
        </>
    )
}

export default ManageMoneyModal
