import React from 'react'
import { Form } from '@themesberg/react-bootstrap'
import usePortalFilter from './usePortalFilter'

const PortalFilter = ({ cms = false, cmsData = false, setSelectedPortal, selectedClient, selectedPortal, hasAllOptions = true }) => {
  const { portalsFilterData } = usePortalFilter(selectedClient)

  return (
    <>
      {/* <Form.Label style={{ marginRight: '15px', marginTop: '3px' }}>
        Tenant
      </Form.Label> */}

      <Form.Select
        onChange={(e) => setSelectedPortal(e.target.value)}
        value={selectedPortal}
        style={{ maxWidth: '200px', marginRight: '10px' }}
        size={cmsData ? '' : 'sm'}
        disabled={cms}
      >
        <option value='' disabled={!hasAllOptions}>
          {hasAllOptions ? 'All' : 'Choose Tenant'}
        </option>

        {portalsFilterData?.count === 0 && (
          <option value='' disabled>
            No Tenants Available
          </option>
        )}

        {portalsFilterData?.rows?.map(
          ({ tenantId, name, domain }) => (
            <option key={tenantId} value={tenantId}>
              {`${name} ( ${domain} )`}
            </option>
          )
        )}
      </Form.Select>
    </>
  )
}

export default PortalFilter
