import { takeLatest, put } from 'redux-saga/effects';
import { getReferralManagement, updateReferralManagement } from '../../utils/apiCalls';
import { getReferralManagementFailure, getReferralManagementStart, getReferralManagementSuccess, updateReferralManagementFailure, updateReferralManagementStart, updateReferralManagementSuccess } from '../redux-slices/referralManagement';
import { toast } from '../../components/Toast/index';
export default function * referralManagementWatcher () {
    yield takeLatest(getReferralManagementStart, getReferralManagementWorker)
    yield takeLatest(updateReferralManagementStart, updateReferralManagementWorker)
  
}

function * getReferralManagementWorker ({payload}) {
    try {

      const { data } = yield getReferralManagement(payload)
      yield put(getReferralManagementSuccess(data?.data?.referralManagement || {}))

    } catch (e) {
      yield toast(e.response.data.message, 'error')
  
      yield put(getReferralManagementFailure(e?.response?.data?.errors[0]  ))
    }
}
function * updateReferralManagementWorker ({payload}) {
    try {
      const { data } =  yield updateReferralManagement(payload)
      yield put(updateReferralManagementSuccess())
      yield toast(data?.data?.message ||  'Referral Management Update Successfully', 'success')
      yield put(getReferralManagementStart())

    } catch (e) {
      yield toast(e.response.data.message, 'error')
  
      yield put(updateReferralManagementFailure(e?.response?.data?.errors[0]  ))
    }
}