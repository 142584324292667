import * as Yup from 'yup'

export const affiliateSigninInitialValues = ({ user = null } = {}) => {
  return {
    user,
    password: null,
  }
}

export const affiliateSigninSchema = Yup.object().shape({
  user: Yup.string().nullable().required('E-mail ou nome de usuário são obrigatórios.'),
  password: Yup.string().nullable().required('Senha é obrigatória.')
});

export const superAdminLoginSchema = Yup.object().shape({
  user: Yup.string().required('E-mail ou nome de usuário são obrigatórios.'),
  password: Yup.string().required('Senha é obrigatória.')
})
