export const kpiReportConstant = {
    "game": {
        "softswiss:Gemhalla": {
            "GGR": "38.31",
            "deltaGGR": "26.81 %",
            "realBet": "518.1",
            "realWin": "479.79",
            "bonusBet": "65.55",
            "bonusWin": "49.56",
            "bonusGGR": "15.99",
            "totalBets": "583.65",
            "deltaTotalBets": "-42.39 %"
        },
        "relax:Millionaire": {
            "GGR": "35.86",
            "deltaGGR": "77.09 %",
            "realBet": "227.6",
            "realWin": "191.74",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "227.6",
            "deltaTotalBets": "634.19 %"
        },
        "pragmaticexternal:WolfGold": {
            "GGR": "27.6",
            "deltaGGR": "100 %",
            "realBet": "273.5",
            "realWin": "245.9",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "273.5",
            "deltaTotalBets": "100 %"
        },
        "evoplay:LuckyPorkerBonusBuy": {
            "GGR": "24.96",
            "deltaGGR": "100 %",
            "realBet": "105.5",
            "realWin": "80.54",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "105.5",
            "deltaTotalBets": "100 %"
        },
        "spinomenal:BookofDemiGodsIIReloaded": {
            "GGR": "31.95",
            "deltaGGR": "100 %",
            "realBet": "40.05",
            "realWin": "8.1",
            "bonusBet": "209.5",
            "bonusWin": "123.75",
            "bonusGGR": "85.75",
            "totalBets": "249.55",
            "deltaTotalBets": "859.81 %"
        },
        "technology:MagicCrown": {
            "GGR": "2.1",
            "deltaGGR": "100 %",
            "realBet": "8.1",
            "realWin": "6",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "8.1",
            "deltaTotalBets": "100 %"
        },
        "pragmaticexternal:GatesOfOlympus1": {
            "GGR": "-66.68",
            "deltaGGR": "77.57 %",
            "realBet": "467.6",
            "realWin": "534.28",
            "bonusBet": "21.8",
            "bonusWin": "2.42",
            "bonusGGR": "19.38",
            "totalBets": "489.4",
            "deltaTotalBets": "-26.80 %"
        },
        "tomhorn:FruitsGoPop": {
            "GGR": "11.9",
            "deltaGGR": "100 %",
            "realBet": "14.6",
            "realWin": "2.7",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "14.6",
            "deltaTotalBets": "7200.00 %"
        },
        "belatra:LegacyofDoom": {
            "GGR": "8.5",
            "deltaGGR": "197.70 %",
            "realBet": "12.55",
            "realWin": "4.05",
            "bonusBet": "0.2",
            "bonusWin": "0.05",
            "bonusGGR": "0.15",
            "totalBets": "12.75",
            "deltaTotalBets": "-57.50 %"
        },
        "pragmaticexternal:ForgeofOlympus": {
            "GGR": "40.76",
            "deltaGGR": "185.11 %",
            "realBet": "382.8",
            "realWin": "342.04",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "382.8",
            "deltaTotalBets": "-20.18 %"
        },
        "slotmill:ChilliBandits": {
            "GGR": "14.87",
            "deltaGGR": "-16.93 %",
            "realBet": "40.5",
            "realWin": "25.63",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "40.5",
            "deltaTotalBets": "87.50 %"
        },
        "evoplay:WildBullets": {
            "GGR": "19.2",
            "deltaGGR": "100 %",
            "realBet": "19.2",
            "realWin": "0",
            "bonusBet": "0",
            "bonusWin": "5.19",
            "bonusGGR": "-5.19",
            "totalBets": "19.2",
            "deltaTotalBets": "100 %"
        },
        "pragmaticexternal:HotFiesta1": {
            "GGR": "14.52",
            "deltaGGR": "100 %",
            "realBet": "19.5",
            "realWin": "4.98",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "19.5",
            "deltaTotalBets": "100 %"
        },
        "evolution:BountyRaid2": {
            "GGR": "64",
            "deltaGGR": "100 %",
            "realBet": "97",
            "realWin": "33",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "97",
            "deltaTotalBets": "100 %"
        },
        "softswiss:AllLuckyClover5": {
            "GGR": "1.6",
            "deltaGGR": "100 %",
            "realBet": "6.9",
            "realWin": "5.3",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "6.9",
            "deltaTotalBets": "100 %"
        },
        "relax:LeBandit92": {
            "GGR": "14.8",
            "deltaGGR": "106.91 %",
            "realBet": "14.8",
            "realWin": "0",
            "bonusBet": "33.6",
            "bonusWin": "19.2",
            "bonusGGR": "14.4",
            "totalBets": "48.4",
            "deltaTotalBets": "-93.43 %"
        },
        "evolution:speed_roulette": {
            "GGR": "5",
            "deltaGGR": "100 %",
            "realBet": "101",
            "realWin": "96",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "101",
            "deltaTotalBets": "100 %"
        },
        "pragmaticexternal:PubKings": {
            "GGR": "12",
            "deltaGGR": "-79.76 %",
            "realBet": "12",
            "realWin": "0",
            "bonusBet": "0",
            "bonusWin": "6",
            "bonusGGR": "-6",
            "totalBets": "12",
            "deltaTotalBets": "-87.63 %"
        },
        "truelab:BookofTruth94": {
            "GGR": "0.21",
            "deltaGGR": "100 %",
            "realBet": "0.26",
            "realWin": "0.05",
            "bonusBet": "17.47",
            "bonusWin": "15",
            "bonusGGR": "2.47",
            "totalBets": "17.73",
            "deltaTotalBets": "100 %"
        },
        "softswiss:AllLuckyClover100": {
            "GGR": "8.7",
            "deltaGGR": "100 %",
            "realBet": "13",
            "realWin": "4.3",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "13",
            "deltaTotalBets": "100 %"
        },
        "thunderkick:TheFalconHuntress_tk": {
            "GGR": "-11",
            "deltaGGR": "-100 %",
            "realBet": "8.5",
            "realWin": "19.5",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "8.5",
            "deltaTotalBets": "-84.55 %"
        },
        "softswiss:BonanzaBillion": {
            "GGR": "3.14",
            "deltaGGR": "-77.16 %",
            "realBet": "7.4",
            "realWin": "4.26",
            "bonusBet": "3.6",
            "bonusWin": "2.06",
            "bonusGGR": "1.54",
            "totalBets": "11",
            "deltaTotalBets": "-59.85 %"
        },
        "relax:OutlawsInc92": {
            "GGR": "67.3",
            "deltaGGR": "100 %",
            "realBet": "80",
            "realWin": "12.7",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "80",
            "deltaTotalBets": "100 %"
        },
        "thunderkick:EsqueletoExplosivo2Reborn92_tk": {
            "GGR": "1.06",
            "deltaGGR": "-99.29 %",
            "realBet": "1.4",
            "realWin": "0.34",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "1.4",
            "deltaTotalBets": "-99.84 %"
        },
        "thunderkick:RidersoftheStorm_tk": {
            "GGR": "6.9",
            "deltaGGR": "-70.13 %",
            "realBet": "7.1",
            "realWin": "0.2",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "7.1",
            "deltaTotalBets": "-77.81 %"
        },
        "pragmaticexternal:TheDogHouseMegaways1": {
            "GGR": "13",
            "deltaGGR": "-84.94 %",
            "realBet": "13",
            "realWin": "0",
            "bonusBet": "141.6",
            "bonusWin": "144.09",
            "bonusGGR": "-2.49",
            "totalBets": "154.6",
            "deltaTotalBets": "41.58 %"
        },
        "thunderkick:FlameBusters_tk": {
            "GGR": "4.95",
            "deltaGGR": "108.51 %",
            "realBet": "6.5",
            "realWin": "1.55",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "6.5",
            "deltaTotalBets": "-94.67 %"
        },
        "thunderkick:Luchadora_tk": {
            "GGR": "7.3",
            "deltaGGR": "100 %",
            "realBet": "7.5",
            "realWin": "0.2",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "7.5",
            "deltaTotalBets": "100 %"
        },
        "mascot:blackjack_mg": {
            "GGR": "-1",
            "deltaGGR": "-100 %",
            "realBet": "23",
            "realWin": "24",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "23",
            "deltaTotalBets": "100 %"
        },
        "yggdrasil:SunnyShores": {
            "GGR": "-1.18",
            "deltaGGR": "-100 %",
            "realBet": "6",
            "realWin": "7.18",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "6",
            "deltaTotalBets": "100 %"
        },
        "evolution:speed_auto_roulette": {
            "GGR": "-1",
            "deltaGGR": "-100 %",
            "realBet": "39",
            "realWin": "40",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "39",
            "deltaTotalBets": "100 %"
        },
        "nolimit:FireInTheHolexBomb1": {
            "GGR": "-785.2",
            "deltaGGR": "-100 %",
            "realBet": "456",
            "realWin": "1,241.2",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "456",
            "deltaTotalBets": "100 %"
        },
        "pragmaticexternal:SugarRush1": {
            "GGR": "27.7",
            "deltaGGR": "100.59 %",
            "realBet": "30",
            "realWin": "2.3",
            "bonusBet": "20",
            "bonusWin": "0.42",
            "bonusGGR": "19.58",
            "totalBets": "50",
            "deltaTotalBets": "-98.42 %"
        },
        "evolution:BigBassAdventure": {
            "GGR": "-1.94",
            "deltaGGR": "-100 %",
            "realBet": "20.6",
            "realWin": "22.54",
            "bonusBet": "3",
            "bonusWin": "0.44",
            "bonusGGR": "2.56",
            "totalBets": "23.6",
            "deltaTotalBets": "100 %"
        },
        "evoplay:EuropeTransit": {
            "GGR": "17",
            "deltaGGR": "-86.08 %",
            "realBet": "17",
            "realWin": "0",
            "bonusBet": "0.3",
            "bonusWin": "0",
            "bonusGGR": "0.3",
            "totalBets": "17.3",
            "deltaTotalBets": "-89.26 %"
        },
        "thunderkick:Barbershop_Uncut_tk": {
            "GGR": "3.1",
            "deltaGGR": "100 %",
            "realBet": "3.5",
            "realWin": "0.4",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "3.5",
            "deltaTotalBets": "100 %"
        },
        "yggdrasil:Incinerator": {
            "GGR": "1.38",
            "deltaGGR": "100 %",
            "realBet": "1.6",
            "realWin": "0.22",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "1.6",
            "deltaTotalBets": "100 %"
        },
        "evolution:immersive_roulette": {
            "GGR": "4,000.1",
            "deltaGGR": "100 %",
            "realBet": "4,000.1",
            "realWin": "0",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "4,000.1",
            "deltaTotalBets": "100 %"
        },
        "evolution:speed_baccaratf": {
            "GGR": "0",
            "deltaGGR": "0 %",
            "realBet": "18",
            "realWin": "18",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "18",
            "deltaTotalBets": "100 %"
        },
        "relax:LuckyCloverLady": {
            "GGR": "10.44",
            "deltaGGR": "100 %",
            "realBet": "10.8",
            "realWin": "0.36",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "10.8",
            "deltaTotalBets": "100 %"
        },
        "softswiss:ElvisFrogTrueways": {
            "GGR": "8.4",
            "deltaGGR": "100 %",
            "realBet": "9",
            "realWin": "0.6",
            "bonusBet": "2.2",
            "bonusWin": "0.83",
            "bonusGGR": "1.37",
            "totalBets": "11.2",
            "deltaTotalBets": "100 %"
        },
        "yggdrasil:Jokerizer": {
            "GGR": "2.5",
            "deltaGGR": "100 %",
            "realBet": "2.5",
            "realWin": "0",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "2.5",
            "deltaTotalBets": "-50.98 %"
        },
        "yggdrasil:PumpkinSmash": {
            "GGR": "-0.08",
            "deltaGGR": "-100 %",
            "realBet": "1.2",
            "realWin": "1.28",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "1.2",
            "deltaTotalBets": "100 %"
        },
        "evolution:speed_baccaratj": {
            "GGR": "6",
            "deltaGGR": "100 %",
            "realBet": "14",
            "realWin": "8",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "14",
            "deltaTotalBets": "100 %"
        },
        "evolution:speed_baccarato": {
            "GGR": "5",
            "deltaGGR": "100 %",
            "realBet": "13",
            "realWin": "8",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "13",
            "deltaTotalBets": "100 %"
        },
        "evolution:speed_baccarati": {
            "GGR": "2.1",
            "deltaGGR": "100 %",
            "realBet": "6",
            "realWin": "3.9",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "6",
            "deltaTotalBets": "100 %"
        },
        "pragmaticexternal:SweetBonanza": {
            "GGR": "34.2",
            "deltaGGR": "-80.81 %",
            "realBet": "100",
            "realWin": "65.8",
            "bonusBet": "20.4",
            "bonusWin": "10.88",
            "bonusGGR": "9.52",
            "totalBets": "120.4",
            "deltaTotalBets": "-69.70 %"
        },
        "evolution:lightning_roulette": {
            "GGR": "1.5",
            "deltaGGR": "103.75 %",
            "realBet": "1.5",
            "realWin": "0",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "1.5",
            "deltaTotalBets": "-98.64 %"
        },
        "evolution:speed_baccarata": {
            "GGR": "6",
            "deltaGGR": "100 %",
            "realBet": "6",
            "realWin": "0",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "6",
            "deltaTotalBets": "100 %"
        },
        "evolution:speed_baccaratc": {
            "GGR": "3",
            "deltaGGR": "100 %",
            "realBet": "3",
            "realWin": "0",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "3",
            "deltaTotalBets": "100 %"
        },
        "kagaming:ButterflyLovers": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": "142.5",
            "bonusWin": "128.45",
            "bonusGGR": "14.05",
            "totalBets": "142.5",
            "deltaTotalBets": "54.89 %"
        },
        "pragmaticexternal:MustangTrail": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": "334.8",
            "bonusWin": "473.75",
            "bonusGGR": "-138.95",
            "totalBets": "334.8",
            "deltaTotalBets": "3748.28 %"
        },
        "yggdrasil:FastFruitsDoubleMax": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": "21.8",
            "bonusWin": "19.42",
            "bonusGGR": "2.38",
            "totalBets": "21.8",
            "deltaTotalBets": "100 %"
        },
        "pragmaticexternal:FatPanda": {
            "GGR": 0,
            "deltaGGR": "100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": "135",
            "bonusWin": "55.1",
            "bonusGGR": "79.9",
            "totalBets": "135",
            "deltaTotalBets": "74.87 %"
        },
        "pragmaticexternal:BigBassSplash": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": "9.1",
            "bonusWin": "5.95",
            "bonusGGR": "3.15",
            "totalBets": "9.1",
            "deltaTotalBets": "-86.87 %"
        },
        "pragmaticexternal:ZeusvsHadesGodsofWar": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": "10.4",
            "bonusWin": "1.2",
            "bonusGGR": "9.2",
            "totalBets": "10.4",
            "deltaTotalBets": "-91.42 %"
        },
        "softswiss:SavageBuffaloSpirit": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": "70",
            "bonusWin": "35.2",
            "bonusGGR": "34.8",
            "totalBets": "70",
            "deltaTotalBets": "-61.94 %"
        },
        "pragmaticexternal:BigBassHoldandSpinner": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": "17.4",
            "bonusWin": "8.43",
            "bonusGGR": "8.97",
            "totalBets": "17.4",
            "deltaTotalBets": "100 %"
        },
        "playngo:HugoLegacy": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": "55",
            "bonusWin": "23.4",
            "bonusGGR": "31.6",
            "totalBets": "55",
            "deltaTotalBets": "25.86 %"
        },
        "kagaming:DragonInn": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": "10.8",
            "bonusWin": "9.35",
            "bonusGGR": "1.45",
            "totalBets": "10.8",
            "deltaTotalBets": "100 %"
        },
        "everymatrix:BookofSouls": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": "3.5",
            "bonusWin": "2.5",
            "bonusGGR": "1",
            "totalBets": "3.5",
            "deltaTotalBets": "100 %"
        },
        "netgame:TheBigGameHoldNLink": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": "6",
            "bonusWin": "1.92",
            "bonusGGR": "4.08",
            "totalBets": "6",
            "deltaTotalBets": "100 %"
        },
        "pragmaticexternal:BigBassBonanza": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": "2.3",
            "bonusWin": "1.4",
            "bonusGGR": "0.9",
            "totalBets": "2.3",
            "deltaTotalBets": "-98.03 %"
        },
        "pragmaticexternal:KingdomofTheDead": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": "1.7",
            "bonusWin": "0.7",
            "bonusGGR": "1",
            "totalBets": "1.7",
            "deltaTotalBets": "-94.06 %"
        },
        "softswiss:ScrollOfAdventure": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": "2.35",
            "bonusWin": "0.24",
            "bonusGGR": "2.11",
            "totalBets": "2.35",
            "deltaTotalBets": "261.54 %"
        },
        "relax:JokerBombs2": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": "40.6",
            "bonusWin": "19.18",
            "bonusGGR": "21.42",
            "totalBets": "40.6",
            "deltaTotalBets": "100 %"
        },
        "relax:ToshiVideoClubrtp": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": "48.2",
            "bonusWin": "32.56",
            "bonusGGR": "15.64",
            "totalBets": "48.2",
            "deltaTotalBets": "100 %"
        },
        "mascot:wild_phoenix_rises": {
            "GGR": 0,
            "deltaGGR": "100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": "0.5",
            "bonusWin": "0.18",
            "bonusGGR": "0.32",
            "totalBets": "0.5",
            "deltaTotalBets": "-99.39 %"
        },
        "softswiss:BookOfPyramids": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": "0.27",
            "bonusWin": "0",
            "bonusGGR": "0.27",
            "totalBets": "0.27",
            "deltaTotalBets": "-80.00 %"
        },
        "3oaks:buddha_megaways": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": "0.2",
            "bonusWin": "0",
            "bonusGGR": "0.2",
            "totalBets": "0.2",
            "deltaTotalBets": "-93.33 %"
        },
        "nolimit:DjPsychoDX1": {
            "GGR": 0,
            "deltaGGR": "100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": "1",
            "bonusWin": "0",
            "bonusGGR": "1",
            "totalBets": "1",
            "deltaTotalBets": "-99.02 %"
        },
        "pragmaticexternal:RouletteAzure": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": "9.9",
            "bonusWin": "0",
            "bonusGGR": "9.9",
            "totalBets": "9.9",
            "deltaTotalBets": "100 %"
        },
        "playngo:Reactoonz_desktop": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pragmaticexternal:3DancingMonkeys": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "wazdan:BurningSunEaster": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "yggdrasil:VikingsGoBerzerk": {
            "GGR": 0,
            "deltaGGR": "100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "wazdan:BurningSun94": {
            "GGR": 0,
            "deltaGGR": "100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "playngo:LegacyofDead": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "playngo:moonprincess100": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "spinomenal:MajesticKingChristmasEdition": {
            "GGR": 0,
            "deltaGGR": "100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pragmaticexternal:BookofFallen": {
            "GGR": 0,
            "deltaGGR": "100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "relax:WantedDeadoraWild92": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "yggdrasil:RoaminRomansUltraNudge": {
            "GGR": 0,
            "deltaGGR": "100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pragmaticexternal:BiggerBassBlizzardChristmasCatch": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "softswiss:DiceBonanza": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pragmaticexternal:ExtraJuicy": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "thunderkick:CarnivalQueen_tk": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "playngo:Gigantoonz": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pragmaticexternal:PirateGold": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "softswiss:LuckAndMagic": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pragmaticexternal:BiggerBassBonanza": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "evolution:CrazyCoinFlip": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pragmaticexternal:SuperJoker": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "softswiss:ElvisFroginVegas": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "technology:40ShiningCoins": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "platipus:magicalwolf": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "relax:TreasuresOfAztec": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "spinomenal:MajesticKing": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pragmaticexternal:WildWestGoldMegaways": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "wazdan:MightyWildPanther94": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pragmaticexternal:TheDogHouseMultihold": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "1spin4win:DiamondLuck243": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "slotmill:MoneyJar2": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "kagaming:QuickPlayJewels": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pragmaticexternal:ChristmasBassBananza": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "nolimit:BonusBunnies1": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pragmaticexternal:HoneyHoneyHoney": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "relax:SlothTumble94": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "1x2gaming:ProspectorWildsMegaways": {
            "GGR": 0,
            "deltaGGR": "100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "thunderkick:MidasGoldenTouchReborn92_tk": {
            "GGR": 0,
            "deltaGGR": "100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pragmaticexternal:MadameDestinyMegaways1": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "yggdrasil:BarbarossaDoubleMax": {
            "GGR": 0,
            "deltaGGR": "100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "yggdrasil:FuryofHydeMegaways": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "relax:TempleTumble94": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "relax:TheRace": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "yggdrasil:VikingsgotoHell": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "thunderkick:GrandMelee92_tk": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "kagaming:SailorMan": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pragmaticexternal:TheDogHouse": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pragmaticexternal:FruitParty2": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "relax:DorkUnit92": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "kagaming:VolcanoAdventure": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "relax:Outlaw": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "spinomenal:BluePanther": {
            "GGR": 0,
            "deltaGGR": "100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "thunderkick:BeattheBeastGriffinsGold_tk": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "relax:ExtraChilli": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "1spin4win:BookOfWild": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "booming:GoldGoldGold": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pragmaticexternal:WildCelebrityBusMegaways": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "kagaming:Jungle": {
            "GGR": 0,
            "deltaGGR": "100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "softswiss:WildCash": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "nolimit:TombOfAkhenaten1": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "kagaming:TreasureBowl": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "nolimit:BenjiKilledInVegasDX1": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "yggdrasil:BookofBooks": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "evolution:MegaRise": {
            "GGR": 0,
            "deltaGGR": "100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "gameart:LuckyFruitsAndDiamonds": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "relax:Coins92": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pragmaticexternal:CandyStars": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "softswiss:WildChicago": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "wazdan:16Coins94": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "kagaming:FluffyBuddy": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "kagaming:MonsterBuster": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "playngo:RascalRiches": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "softswiss:AlienFruits": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "1spin4win:LandyCandy100": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "highfive:LegacyoftheNileExtreme": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "playngo:SecretOfDead": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "softswiss:FruitMillion": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "nolimit:xWaysHoarderxSplitrtp": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "technology:TheOldestOak": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "thunderkick:BeattheBeastCerberusInferno": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "1x2gaming:RockTheReelsMegawaysrtp": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "bsg:QuesttotheWest": {
            "GGR": 0,
            "deltaGGR": "100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pragmaticexternal:WildWestDuels": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "thunderkick:DragonHorn_tk": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "platipus:wildspin": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "nolimit:MentalDX1": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pragmaticexternal:CowboyCoins": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "thunderkick:FullMoonRomance_tk": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pragmaticexternal:FloatingDragonDragonBoatFestival": {
            "GGR": 0,
            "deltaGGR": "100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "technology:5xCherryParty": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "kagaming:AnimalLand": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "mancala:FortuneTumble": {
            "GGR": 0,
            "deltaGGR": "100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pragmaticexternal:CurseoftheWerewolfMegaways": {
            "GGR": 0,
            "deltaGGR": "100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "relax:PugLife92": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "1spin4win:WildLuckyChance": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "gamebeat:BookOfAncients": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "lucky:PiggyIronside": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pragmaticexternal:GodsofGiza": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "relax:RasputinMegaways": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "relax:SafariSun": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "evoplay:BudaiReels": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "bsg:BlackGold": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "kagaming:PartyGirl": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "nolimit:GluttonyDX1": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pragmaticexternal:DiamondStrike100000": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "kalamba:MachinaReloadedMegaways_k": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "playngo:BookofDead": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "relax:KlusterKrystals": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "relax:RoninStackways92": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "relax:WhiteRabbit": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "3oaks:more_magic_apple": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "netgame:WildBuffaloHoldNLink": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "spinomenal:DemiGodsIICE": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "thunderkick:CrystalQuestArcaneTower_tk": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "3oaks:book_of_wizard": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "3oaks:sun_of_egypt_2": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "3oaks:sun_of_egypt_3": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "5men:Robinzon": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "evolution:moneywheel_rng": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "playngo:ScrollofSeth": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pragmaticexternal:GoldRush250000": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pragmaticexternal:HotSafari50000": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "wazdan:BurningSunExtremelyLight94": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "wazdan:HotSlotMagicPearls94": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "everymatrix:BookOfSoulsRemastered": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "relax:RoyalMint": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "evolution:10001nightsmegaways": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "thunderkick:CrystalQuestDeepJungle_tk": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pragmaticexternal:FloatingDragon1": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pragmaticexternal:JokersJewels": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "thunderkick:BeattheBeastQuetzalcoatlsTrial": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "softswiss:LuckyLadyMoon": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "wazdan:9Lions94": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "hollegames:FruitsXL": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "softswiss:AlohaKingElvis": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pragmaticexternal:UltraHoldandSpin": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "relax:5Monsters": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "technology:MagicHammer": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "playngo:FoxMayhem": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "yggdrasil:ValleyOfTheGods": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "relax:Pop": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "thunderkick:BeattheBeastMightySphinx": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "yggdrasil:ValleyoftheGods2": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "thunderkick:BigFinBay_tk": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "thunderkick:PinkElephants_tk": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "playngo:EasterEggs": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "relax:WildFlower": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "mrslotty:LuckyRooster": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "spribe:plinko": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "technology:FireKing": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "thunderkick:CrystalQuestFrostlands_tk": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pragmaticexternal:MasterJoker": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "mascot:book_of_amaterasu": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "wazdan:BurningStars394": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "thunderkick:KrakensLair_tk": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "thunderkick:CosmicVoyager_tk": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "thunderkick:JaguarTemple_tk": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "softswiss:AztecMagicDeluxe": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "thunderkick:RextheHunt92_tk": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "nolimit:PixiesvsPirates1": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "nolimit:WhackedDX1": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "spinomenal:QueenOfFire": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "thunderkick:RavensEye_tk": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "thunderkick:DivineLotus_tk": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "softswiss:SavageBuffaloSpiritMegaways": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "playngo:BlackMamba": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "relax:TemplarTumble94": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "relax:WildBandito": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "thunderkick:EsqueletoExplosivo_tk": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pragmaticexternal:AztecGemsDeluxe1": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pragmaticexternal:AncientEgyptClassic": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        }
    },
    "provider":{
        "tomhorn": {
            "GGR": "11.9",
            "deltaGGR": "100 %",
            "realBet": "14.6",
            "realWin": "2.7",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "14.6",
            "deltaTotalBets": "7200.00 %"
        },
        "zillion": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "spribe": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "reflexgaming": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": "21.8",
            "bonusWin": "19.42",
            "bonusGGR": "2.38",
            "totalBets": "21.8",
            "deltaTotalBets": "100 %"
        },
        "3oaks": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": "0.2",
            "bonusWin": "0",
            "bonusGGR": "0.2",
            "totalBets": "0.2",
            "deltaTotalBets": "-97.56 %"
        },
        "evolution": {
            "GGR": "4,027.7",
            "deltaGGR": "30270.04 %",
            "realBet": "4,201.6",
            "realWin": "173.9",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "4,201.6",
            "deltaTotalBets": "2693.62 %"
        },
        "bingo": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "redtiger": {
            "GGR": "64",
            "deltaGGR": "40100.01 %",
            "realBet": "97",
            "realWin": "33",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "97",
            "deltaTotalBets": "62.48 %"
        },
        "leapgaming": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "pragmaticplay": {
            "GGR": "103.1",
            "deltaGGR": "102.38 %",
            "realBet": "1,298.4",
            "realWin": "1,195.3",
            "bonusBet": "714.5",
            "bonusWin": "710.34",
            "bonusGGR": "4.16",
            "totalBets": "2,012.9",
            "deltaTotalBets": "-73.08 %"
        },
        "pragmaticplaylive": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": "9.9",
            "bonusWin": "0",
            "bonusGGR": "9.9",
            "totalBets": "9.9",
            "deltaTotalBets": "100 %"
        },
        "bsg": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "booming": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "playson": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "booongo": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "playngo": {
            "GGR": "0",
            "deltaGGR": "-100.00 %",
            "realBet": "0",
            "realWin": "0",
            "bonusBet": "55",
            "bonusWin": "23.4",
            "bonusGGR": "31.6",
            "totalBets": "55",
            "deltaTotalBets": "-98.98 %"
        },
        "onlyplay": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "nucleus": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "mannaplay": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "hacksaw": {
            "GGR": "82.1",
            "deltaGGR": "-83.55 %",
            "realBet": "94.8",
            "realWin": "12.7",
            "bonusBet": "122.4",
            "bonusWin": "70.94",
            "bonusGGR": "51.46",
            "totalBets": "217.2",
            "deltaTotalBets": "-87.68 %"
        },
        "merkur": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "kiron": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "netent": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "hollegames": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "gamzix": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "mancala": {
            "GGR": 0,
            "deltaGGR": "100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "igrosoft": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "nolimit": {
            "GGR": "-785.2",
            "deltaGGR": "-368.00 %",
            "realBet": "456",
            "realWin": "1,241.2",
            "bonusBet": "1",
            "bonusWin": "0",
            "bonusGGR": "1",
            "totalBets": "457",
            "deltaTotalBets": "-63.30 %"
        },
        "high5": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "revolver": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "maxwingaming": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pgsoft": {
            "GGR": "10.44",
            "deltaGGR": "33.50 %",
            "realBet": "10.8",
            "realWin": "0.36",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "10.8",
            "deltaTotalBets": "-80.51 %"
        },
        "fantasma": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "bigtimegaming": {
            "GGR": "35.86",
            "deltaGGR": "-96.56 %",
            "realBet": "227.6",
            "realWin": "191.74",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "227.6",
            "deltaTotalBets": "-90.44 %"
        },
        "silverback": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "relax": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "gamesinc": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "printstudios": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "sthlmgaming": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "fourleafgaming": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "bluegurugames": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "bgaming": {
            "GGR": "60.15",
            "deltaGGR": "-72.38 %",
            "realBet": "554.4",
            "realWin": "494.25",
            "bonusBet": "143.97",
            "bonusWin": "87.89",
            "bonusGGR": "56.08",
            "totalBets": "698.37",
            "deltaTotalBets": "-61.30 %"
        },
        "plankgaming": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "sapphire": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "gameslab": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "goldenrock": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "electricelephant": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "1spin4win": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "highfive": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "igtech": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "bet2tech": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "tvbet": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "goldenrace": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "technology": {
            "GGR": "2.1",
            "deltaGGR": "-94.87 %",
            "realBet": "8.1",
            "realWin": "6",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "8.1",
            "deltaTotalBets": "-97.72 %"
        },
        "evoplay": {
            "GGR": "61.16",
            "deltaGGR": "-50.07 %",
            "realBet": "141.7",
            "realWin": "80.54",
            "bonusBet": "0.3",
            "bonusWin": "5.19",
            "bonusGGR": "-4.89",
            "totalBets": "142",
            "deltaTotalBets": "-12.07 %"
        },
        "softswiss": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "5men": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "gamebeat": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "yggdrasil": {
            "GGR": "2.62",
            "deltaGGR": "2481.81 %",
            "realBet": "11.3",
            "realWin": "8.68",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "11.3",
            "deltaTotalBets": "-96.69 %"
        },
        "bangbanggames": {
            "GGR": 0,
            "deltaGGR": "100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "petersons": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "bulletproof": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "gamevy": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "jelly": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "boomerangstudios": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "truelab": {
            "GGR": "0.21",
            "deltaGGR": "100 %",
            "realBet": "0.26",
            "realWin": "0.05",
            "bonusBet": "17.47",
            "bonusWin": "15",
            "bonusGGR": "2.47",
            "totalBets": "17.73",
            "deltaTotalBets": "100 %"
        },
        "netgame": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": "6",
            "bonusWin": "1.92",
            "bonusGGR": "4.08",
            "totalBets": "6",
            "deltaTotalBets": "1100.00 %"
        },
        "baddingo": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "hotrisegames": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "bbgames": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "reellifegames": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "fazi": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "northernlights": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "eagaming": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "wazdan": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "quickspin": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "gameart": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "7mojos": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "religa": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "alg": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "altente": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "rfranco": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "belatra": {
            "GGR": "8.5",
            "deltaGGR": "197.70 %",
            "realBet": "12.55",
            "realWin": "4.05",
            "bonusBet": "0.2",
            "bonusWin": "0.05",
            "bonusGGR": "0.15",
            "totalBets": "12.75",
            "deltaTotalBets": "-57.50 %"
        },
        "felixgaming": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "avatarux": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "habanero": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "luckystreak": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "fugaso": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "spinthon": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "kalamba": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "mascot": {
            "GGR": "-1",
            "deltaGGR": "99.15 %",
            "realBet": "23",
            "realWin": "24",
            "bonusBet": "0.5",
            "bonusWin": "0.18",
            "bonusGGR": "0.32",
            "totalBets": "23.5",
            "deltaTotalBets": "-73.52 %"
        },
        "kagaming": {
            "GGR": "0",
            "deltaGGR": "-100.00 %",
            "realBet": "0",
            "realWin": "0",
            "bonusBet": "153.3",
            "bonusWin": "137.8",
            "bonusGGR": "15.5",
            "totalBets": "153.3",
            "deltaTotalBets": "-76.91 %"
        },
        "spearhead": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": "3.5",
            "bonusWin": "2.5",
            "bonusGGR": "1",
            "totalBets": "3.5",
            "deltaTotalBets": "-97.98 %"
        },
        "armadillostudios": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "liveslots": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "atmosfera": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "platipus": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "mrslotty": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "ezugi": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "thunderkick": {
            "GGR": "12.31",
            "deltaGGR": "-93.51 %",
            "realBet": "34.5",
            "realWin": "22.19",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "34.5",
            "deltaTotalBets": "-98.51 %"
        },
        "lucky": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "pushgaming": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "spinomenal": {
            "GGR": "31.95",
            "deltaGGR": "123.53 %",
            "realBet": "40.05",
            "realWin": "8.1",
            "bonusBet": "209.5",
            "bonusWin": "123.75",
            "bonusGGR": "85.75",
            "totalBets": "249.55",
            "deltaTotalBets": "13.56 %"
        },
        "retrogaming": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "vivogaming": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "swintt": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "slotmill": {
            "GGR": "14.87",
            "deltaGGR": "-80.73 %",
            "realBet": "40.5",
            "realWin": "25.63",
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": "40.5",
            "deltaTotalBets": "-84.48 %"
        },
        "irondogstudio": {
            "GGR": 0,
            "deltaGGR": "-100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "1x2gaming": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "vave": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "4theplayer": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "reelplay": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "amatic": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "playtech": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "acerun": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "adlunam": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "prospectgaming": {
            "GGR": 0,
            "deltaGGR": "100.00 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "-100.00 %"
        },
        "gamomat": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        },
        "maxwin": {
            "GGR": "-1.94",
            "deltaGGR": "-100 %",
            "realBet": "20.6",
            "realWin": "22.54",
            "bonusBet": "3",
            "bonusWin": "0.44",
            "bonusGGR": "2.56",
            "totalBets": "23.6",
            "deltaTotalBets": "100 %"
        },
        "trigger": {
            "GGR": 0,
            "deltaGGR": "0 %",
            "realBet": 0,
            "realWin": 0,
            "bonusBet": 0,
            "bonusWin": 0,
            "bonusGGR": 0,
            "totalBets": 0,
            "deltaTotalBets": "0 %"
        }
    }
}