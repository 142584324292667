import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllClientsStart } from '../../store/redux-slices/tenants'

const useClientFilter = () => {
  const dispatch = useDispatch()
  const { clientsFilterData } = useSelector(state => state.tenants)

  useEffect(() => {
    dispatch(getAllClientsStart({
      search: '',
      limit: 100,
      pageNo: 1,
      orderBy: 'email',
      sort: 'desc'
    }))
  }, [])

  return {
    clientsFilterData
  }
}

export default useClientFilter
