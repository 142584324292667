import React from 'react';
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

import {
    Button,
    Form
  } from '@themesberg/react-bootstrap'
const ActionMenu = (props) => {
    const { 
        isHidden, 
        handleStatusChange,
        item,
        setShow,
        setCountryId
    } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl)

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    return (
        <>
           {!isHidden({
                module: {
                key: 'SportbookManagement',
                value: 'R',
                },
            }) ||
            !isHidden({
                module: {
                key: 'SportbookManagement',
                value: 'U',
                },
            }) ? (
                <>
            <Button
                variant='btn text-btn'
                id='basic-button'
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
                }}
            >
                <MoreVertRoundedIcon />
            </Button>
            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button'
                }}
            >              
                <MenuItem onClick={handleClose}>
                    <div className='text-btn'>
                        <Form.Check
                            name='status'
                            type='switch'
                            className="d-inline-block ms-1"
                            checked={item.isActive}
                            value={item.isActive}
                            onChange={() => handleStatusChange(item.countryId, item.isActive)}
                        />
                        <span className='text'>{ item.isActive ? 'Active' : 'InActive' }</span>
                    </div>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Button
                        variant='btn text-btn'
                        className="ms-1"
                        //  disabled={claimedCount}
                        // hidden={isHidden({ module: { key: 'BannerManagement', value: 'D' } })}
                        onClick={() => {
                            setShow(true)
                            setCountryId(item.countryId)
                        }}
                    >
                        <CloudUploadOutlinedIcon />
                        <span className='text'>Upload Icon</span>
                    </Button>
                </MenuItem>
            </Menu></>) : 'NA' }
        </>
    )
};

export default ActionMenu;