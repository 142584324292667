import { Card, Col, Row } from '@themesberg/react-bootstrap'
import React from 'react'
import { affiliateList } from '../constants'

const Affiliate = ({ userData }) => {
  const affiliate = userData?.affiliate
  return (
    <>
      <Row className='mt-3'>
        <Col xs={12} md={6} lg={6}>
          <Card className='card-overview'>
            <h4 className='h4-overview text-center mt-1'>Affiliate Details <hr className='h4-hr mt-1' /></h4>
            {affiliateList.map(({ label, value }) =>
              <div className='d-flex justify-content-between m-1' key={value}>
                <h6 className='px-2'>{label}</h6>
                <span className='px-2'>{affiliate?.[value] || 'NA'}</span>
              </div>)}
            <div className='d-flex justify-content-between m-1'>
              <h6 className='px-2'>Status</h6>
              <span className='px-2 text-capitalize'>{affiliate?.status || 'NA'}</span>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Affiliate
