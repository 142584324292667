import React from 'react'
import {
  Form,
  Row,
  Col,
  Table,
  // ButtonGroup,
  // Button
} from '@themesberg/react-bootstrap'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faStoreSlash, faBan, faEdit, faCheckSquare, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import useCheckPermission from '../../../utils/checkPermission'
import PaginationComponent from '../../../components/Pagination'
// import Trigger from '../../../components/OverlayTrigger'
import useCountriesListing from './useCountriesListing'
import ConfirmationModal, { UpdateKycMethodModal } from '../../../components/ConfirmationModal'
import { kycMethodsList } from './constants'
// import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
// import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded'
// import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded'
// import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
// import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
// import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded'
// import Menu from '@mui/material/Menu'
// import MenuItem from '@mui/material/MenuItem'
import ActionMenu from './ActionMenu'
const Countries = () => {
  const {
    page,
    limit,
    showKycUpdateModal,
    countryData,
    name,
    totalPages,
    countries,
    loading,
    handleKycUpdateMethod,
    setCountryData,
    setShowKycUpdateModal,
    setLimit,
    setName,
    setPage,
    navigate,
    handleStatusShow,
    status,
    statusShow,
    myName,
    handleYes,
    setStatusShow,
    // active,
    // setActive,
    KYCMethod,
    setKYCMethod,
    languages
  } = useCountriesListing()
  const { isHidden } = useCheckPermission();
  // const [anchorEl, setAnchorEl] = React.useState(null)
  // const open = Boolean(anchorEl)
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget)
  // }
  // const handleClose = () => {
  //   setAnchorEl(null)
  // }

  return (
    <>
     <div className='section-header-wrap mt-n3 mx-n3'>
     <Row>
        <Col>
          <h2 className='title-text'>Países</h2>
        </Col>
      </Row>
      </div>
      <div className='FormsBg'>
      <Row>

        {/* <Col xs='12' sm='3'>
         
           

                <Form.Select
                  onChange={(e) => { setKYCMethod(e.target.value) }}
                  value={KYCMethod}
                >
                  <option value=''>Kyc Method</option>
                  <option value='0'>System KYC</option>
                  <option value='1'>Sumsub</option>
                </Form.Select>
              </Col> */}

              <Col xs='12' sm='3'>
                

                <Form.Control
                  type='search'
                  placeholder='
                  Pesquisar nome do país'
                  // style={{ maxWidth: '3230px' }}
                  value={name}
                  onChange={(event) =>
                    setName(event.target.value.replace(/[^\w\s]/gi, ''))}
                />
                {/* <Form.Label style={{ marginBottom: '0', marginRight: '15px' }}>
              Status
            </Form.Label>

            <Form.Select
              onChange={(e) => { setActive(e.target.value) }}
              size='sm'
              style={{ maxWidth: '230px' }}
              value={active}
            >
              <option value=''>All</option>
              <option value='true'>Active</option>
              <option value='false'>In-Active</option>
            </Form.Select> */}
              </Col>
            </Row>
          </div>
      
      
     
      
     <div className='TableBg mt-4 pt-1'>
     
      <Table  striped responsive hover size='sm' className='text-center mt-3'>
        <thead className='thead-light'>
          <tr>
            <th>Id</th>
            <th>Código do país</th>
            <th>Nome do país</th>
            <th>Linguagem</th>
            {/* <th>Kyc Method</th> */}
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {countries && !loading &&
                      countries?.rows?.map(
                        ({ countryId, code, name: countryName, kycMethod, status, language }) => (
                          <tr key={countryId}>
                            <td>{countryId}</td>
                            <td>{code}</td>
                            <td>{countryName}</td>
                            <td>{language?.languageName}</td>
                            {/* <td>{kycMethodsList[kycMethod] || 'NA'}</td> */}
                            <td className={status ? 'text-success' : 'text-danger'}>{status ? 'Active' : 'In-Active'}</td>

                            <td>
                              {!isHidden({ module: { key: 'RestrictedCountry', value: 'U' } })
                                ? (
                                  <ActionMenu
                                    navigate={navigate}
                                    status={status}
                                    countryName={countryName}
                                    countryId={countryId}
                                    kycMethod={kycMethod}
                                    language={language}
                                    setCountryData={setCountryData}
                                    setShowKycUpdateModal={setShowKycUpdateModal}
                                    handleStatusShow={handleStatusShow}
                                    isHidden={isHidden}
                                  />
                            //       <ButtonGroup>
                            //        <Button
                            //   variant='btn text-btn'
                            //   id='basic-button'
                            //   aria-controls={open ? 'basic-menu' : undefined}
                            //   aria-haspopup='true'
                            //   aria-expanded={open ? 'true' : undefined}
                            //   onClick={handleClick}
                            //   anchorOrigin={{
                            //     vertical: 'bottom',
                            //     horizontal: 'right'
                            //   }}
                            //   transformOrigin={{
                            //     vertical: 'top',
                            //     horizontal: 'right'
                            //   }}
                            // >
                            //   <MoreVertRoundedIcon />
                            // </Button>
                            // <Menu
                            //   id='basic-menu'
                            //   anchorEl={anchorEl}
                            //   open={open}
                            //   onClose={handleClose}
                            //   MenuListProps={{
                            //     'aria-labelledby': 'basic-button'
                            //   }}
                            // >
                            //   <MenuItem onClick={handleClose}>
                            //         <Trigger message='Edit'>
                            //           <Button
                            //              variant='btn text-btn'
                            //             onClick={() => {
                            //               setCountryData((prev) => ({ ...prev, countryName, countryId, kycMethod: kycMethod || 0, languageId: language?.languageId }))
                            //               setShowKycUpdateModal(true)
                            //             }}
                            //           >
                            //             <BorderColorRoundedIcon />
                            //         <span className='text'>Edit</span>
                            //           </Button>
                            //         </Trigger>
                            //         </MenuItem>
                            //         <MenuItem onClick={handleClose}>

                            //         <Trigger message='View Blocked Games'>
                            //           <Button
                            //            variant='btn text-btn'
                            //             onClick={() => navigate(`/admin/countries/restricted-games/${countryId}`)}
                            //             disabled
                            //           >
                            //           <RemoveRedEyeRoundedIcon />
                            //         <span className='text'>View Blocked Games</span>
                            //           </Button>
                            //         </Trigger>
                            //      </MenuItem>
                                
                            //      <MenuItem onClick={handleClose}>
                            //         <Trigger message='View Blocked Providers'>
                            //           <Button
                            //             variant='btn text-btn'
                            //             disabled
                            //             onClick={() => navigate(`/admin/countries/restricted-providers/${countryId}`)}
                            //           >
                            //             <RemoveRedEyeRoundedIcon />
                            //         <span className='text'>View Blocked Providers</span>                                      </Button>
                            //         </Trigger>
                            //         </MenuItem>
                            //         <MenuItem onClick={handleClose}>
                            //         {!status
                            //           ? (
                            //             <Trigger message='Set Status Active'>
                            //               <Button
                            //                variant='btn text-btn'
                            //                 onClick={() =>
                            //                   handleStatusShow(
                            //                     countryId,
                            //                     status,
                            //                     countryName
                            //                   )}
                            //                 hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                            //               >
                            //                <CheckCircleRoundedIcon />
                            //             <span className='text'>Active</span>
                            //               </Button>
                            //             </Trigger>
                            //             )
                            //           : (
                            //             <Trigger message='Set Status In-Active'>
                            //               <Button
                            //                variant='btn text-btn'
                            //                 onClick={() =>
                            //                   handleStatusShow(
                            //                     countryId,
                            //                     status,
                            //                     countryName
                            //                   )}
                            //                 hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                            //               >
                            //                <CancelRoundedIcon />
                            //             <span className='text'>In-Active</span>
                            //               </Button>
                            //             </Trigger>
                                      
                            //             )}
                            //               </MenuItem>
                            //               </Menu>
                            //       </ButtonGroup>
                                  )
                                : '-'}
                            </td>
                          </tr>
                        )
                      )}
        </tbody>

        {countries?.count === 0 && !loading &&
                    (
                      <tr>
                        <td
                          colSpan={5}
                          className='text-danger text-center'
                        >
                          No data found
                        </td>
                      </tr>
                    )}
      </Table>
	  {countries?.count !== 0 && !loading &&
              (
                <PaginationComponent
                  page={countries?.count < page ? setPage(1) : page}
                  totalPages={totalPages}
                  setPage={setPage}
                  limit={limit}
                  setLimit={setLimit}
                />
              )}
      </div>

    

      {languages?.count > 0 &&
        <UpdateKycMethodModal
          show={showKycUpdateModal}
          setShow={setShowKycUpdateModal}
          countryData={countryData}
          setCountryData={setCountryData}
          handleKycUpdateMethod={handleKycUpdateMethod}
          languages={languages}
        />}

      <ConfirmationModal
        setShow={setStatusShow}
        show={statusShow}
        handleYes={handleYes}
        active={status}
        name={myName}
      />
    </>
  )
}

export default Countries
