import React from 'react'
import {
  Navbar,
  Image,
  Col,
  Dropdown
} from '@themesberg/react-bootstrap'
import './Header.css'
import useHeader from './hooks/useHeader'

const Header = () => {
  const {
    navigate,
    tenantsList,
    tenantDetails,
    tenantChangeHandler
  } = useHeader()

  return (
    <Navbar
      sticky='top'
      variant='light'
      bg='light'
      className='tenant main-nav justify-content-between navbar-theme-primary'
    >
      <Navbar.Brand className='me-lg-3 mx-4'>
        {tenantDetails?.tenantConfig.logoUrl &&
          <Image
            thumbnail
            alt=''
            src={tenantDetails.tenantConfig.logoUrl}
            className='d-inline-block align-top mx-2'
          />}
        {tenantDetails?.name}
      </Navbar.Brand>
      <Col md='auto' className='float-right mb-3'>
        {tenantsList && tenantsList.rows && tenantDetails?.name &&
          <Dropdown className=' d-inline mx-2'>
            <Dropdown.Toggle id='dropdown-autoclose-outside' variant='white'>
              <button
                size='sm'
                className={`tenant-circle-button ${tenantDetails?.isActive ? 'active-button' : 'inactive-button'}`}
              />&nbsp;{`${tenantDetails?.name} (${tenantDetails?.domain})`}
            </Dropdown.Toggle>

            <Dropdown.Menu className='user-dropdown tenant-dropdown'>
              {tenantsList.rows.map(({ name, tenantId, domain, isActive }) => {
                return (
                  <Dropdown.Item
                    key={name}
                    onClick={(e) => {
                      tenantChangeHandler(tenantId)
                      navigate(0)
                    }}
                  >
                    <button
                      size='sm'
                      className={`tenant-circle-button ${isActive ? 'active-button' : 'inactive-button'}`}
                    />&nbsp;{name}({domain})
                  </Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>}
      </Col>
    </Navbar>
  )
}

export default Header
