import React from 'react'
import { Table } from '@themesberg/react-bootstrap'
import { NoDataContainerSingleRow } from '../SportsList/style'
import DivLoader from '../../../components/Loader/divLoader'
import { getDateTime } from '../../../utils/dateFormatter'

const SportsMatchTable = ({
  isGetSportsMatchesDetailLoading,
  getSportsMatchesDetail
}) => {
  return (
    <div className="section-header-wrap mt-n3 mx-n3 TableBg mt-4 pt-1">
      <Table striped responsive hover size="sm" className="text-center mt-3">
        <thead className="thead-light">
          <tr>
            <th>Id</th>
            <th>TITLE</th>
            <th>TOURNAMENT</th>
            <th>SPORT</th>
            <th>IS FEATURED</th>
            <th>STATUS</th>
            <th>LIVE</th>
            <th>START DATE</th>
          </tr>
        </thead>

        <tbody>
          {!isGetSportsMatchesDetailLoading &&
            getSportsMatchesDetail?.match && (
              <tr key={1}>
                <td>{getSportsMatchesDetail.match.matchId}</td>
                <td>{`${getSportsMatchesDetail.match?.teams?.[0]?.team?.teamName?.[0]?.name} Vs
                    ${getSportsMatchesDetail.match?.teams?.[0]?.team?.teamName?.[1]?.name} `}
                </td>
                <td>
                  {getSportsMatchesDetail.match?.tournaments?.tournamentName?.[0]?.name}
                </td>
                <td>
                  {getSportsMatchesDetail.match.sportCountry?.sport?.sportName?.[0]?.name}
                </td>
                <td
                  className={
                    getSportsMatchesDetail.match?.isFeatured
                      ? "text-success"
                      : "text-danger"
                  }
                >
                  {getSportsMatchesDetail.match?.isFeatured ? "YES" : "NO"}
                </td>
                <td>{getSportsMatchesDetail.match?.status}</td>
                <td
                  className={
                    getSportsMatchesDetail.match?.isLive
                      ? "text-success"
                      : "text-danger"
                  }
                >
                  {getSportsMatchesDetail.match?.isLive ? "YES" : "NO"}
                </td>
                <td>
                  {getDateTime(getSportsMatchesDetail.match?.matchDate)}
                </td>
              </tr>
            )}

          {isGetSportsMatchesDetailLoading && (
            <tr>
              <td colSpan="8">
                <NoDataContainerSingleRow>
                  <DivLoader className='py1'/>
                </NoDataContainerSingleRow>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  )
}

export default SportsMatchTable