import { Row, Col, Button, Tabs, Tab } from '@themesberg/react-bootstrap'
import React from 'react'
import useLanguageWise from '../hooks/useLanguageWise'
import { AdminsRoutes } from '../../../routes'
import Trigger from '../../OverlayTrigger'
import EditLangWise from './EditLangWise'
import Preloader from '../../Preloader'
import { languageCode } from '../../../pages/Tenant/TenantConfigurations/Languages/constants'
import { ChangeLangConfirmationModal, GalleryModal } from '../../ConfirmationModal'
import { faImages } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const LanguageWise = () => {
  const {
    navigate,
    isTenant,
    languages,
    languageKeys,
    selectedTab,
    myKeys,
    languageTabs,
    selectedKeyTab,
    setSelectedKeyTab,
    loading,
    updateData,
    isError,
    setIsError,
    check,
    show,
    setShow,
    handleYes,
    setMySelectedTab,
    galleryModal,
    setGalleryModal
  } = useLanguageWise()

  return (
    <>
      {(!selectedKeyTab || loading)
        ? <Preloader />
        : (
          <>
          <div className='section-header-wrap mt-n3 mx-n3'> 
            <Row className='mb-3'>
              <Col>
                <h2 className='title-text'>Language Management</h2>
              </Col>
              <Col className='d-flex align-items-end justify-content-end'>
                <Trigger message='Gallery'>
                  <Button
                    onClick={() => setGalleryModal(true)}
                    variant='btn btn-primary'
                  >
                    <FontAwesomeIcon icon={faImages} />
                  </Button>
                </Trigger>
                <Button
                  variant='btn btn-primary'
                  className='float-end ms-2'
                  onClick={() => navigate(AdminsRoutes.EditSupportLanguageKeys)}
                >
                  Edit by Key
                </Button>
              </Col>
            </Row>
            </div>
            <Tabs
              activeKey={selectedTab}
              onSelect={(tab) => {
                setShow(true)
                setMySelectedTab(tab)
              }}
              className='nav-light custom-nav'
            >
              <Tab 
                eventKey='EN'
                tabClassName={selectedTab !== 'EN' ? 'email' : 'email-active'}
                title={
                  <Trigger message='English'>
                    <span>EN</span>
                  </Trigger>
                }
              >
                <div className='mt-5'>
                  <EditLangWise
                    languageKeyObj={languageKeys?.find(obj => obj.language === 'EN')}
                    selectedTab={selectedTab}
                    myKeys={myKeys}
                    isTenant={isTenant}
                    languageTabs={languageTabs}
                    selectedKeyTab={selectedKeyTab}
                    setSelectedKeyTab={setSelectedKeyTab}
                    updateData={updateData}
CMS
                    setIsError={setIsError}
                    check={check}
                  />
                </div>
              </Tab>
              {!isTenant
                ? languages?.count && languages?.rows?.map(({ languageName, code }) => {
                  return code !== 'EN' && languageKeys?.find(obj => obj.language === code) && (
                    <Tab
                      eventKey={code}
                      title={
                        <Trigger message={languageName}>
                          <span>{code}</span>
                        </Trigger>
                }
                      key={code}
                      mountOnEnter
                      tabClassName={selectedTab !== code ? languageKeys?.find(obj => obj.language === code) ? 'email' : '' : 'email-active'}
                    >
                      <div className='mt-5'>
                        <EditLangWise
                          languageKeyObj={languageKeys?.find(obj => obj.language === selectedTab)}
                          selectedTab={selectedTab}
                          myKeys={myKeys}
                          isTenant={isTenant}
                          languageTabs={languageTabs}
                          selectedKeyTab={selectedKeyTab}
                          setSelectedKeyTab={setSelectedKeyTab}
                          updateData={updateData}
                          isError={isError}
                          setIsError={setIsError}
                          check={check}
                        />
                      </div>
                    </Tab>
                  )
                })
                : languages?.length && languages?.map((code) => {
                  return code !== 'EN' && languageKeys?.find(obj => obj.language === code) && (
                    <Tab
                      eventKey={code}
                      title={
                        <Trigger message={languageCode[code]}>
                          <span>{code}</span>
                        </Trigger>
                }
                      key={code}
                      mountOnEnter
                      tabClassName={selectedTab !== code ? languageKeys?.find(obj => obj.language === code) ? 'email' : '' : 'email-active'}
                    >
                      <div className='mt-5'>
                        <EditLangWise
                          languageKeyObj={languageKeys?.find(obj => obj.language === selectedTab)}
                          selectedTab={selectedTab}
                          myKeys={myKeys}
                          isTenant={isTenant}
                          languageTabs={languageTabs}
                          selectedKeyTab={selectedKeyTab}
                          setSelectedKeyTab={setSelectedKeyTab}
                          updateData={updateData}
                          isError={isError}
                          setIsError={setIsError}
                          check={check}
                        />
                      </div>
                    </Tab>
                  )
                })}
            </Tabs>
          </>
          )}
      <ChangeLangConfirmationModal
        show={show}
        setShow={setShow}
        handleYes={handleYes}
      />

      {galleryModal &&
        <GalleryModal
          galleryModal={galleryModal}
          setGalleryModal={setGalleryModal}
          isTenant={isTenant}
        />}
    </>
  )
}

export default LanguageWise
