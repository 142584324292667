export const tableHeaders = [
  'ID',
  'User Email',
  'Game Name',
  'Action Type',
  'Amount',
  'Bonus Money',
  'Status',
  'Created At'
]

export const actionType = [
  { label: 'All', value: 'all' },
  { label: 'BetPlacing', value: 'betPlacing' },
  // { label: 'Win', value: 'win' },
  // { label: 'RollBack', value: 'rollback' },
  // { label: 'Pre RollBack', value: 'rollbackbeforebetwin' },
  // { label: 'Free Spin', value: 'freespins' }
]

export const transactionType = [
  { label: 'All', value: 'all' },
  { label: 'Debit', value: 'debit' },
  { label: 'Credit', value: 'credit' },
]

export const TRANSACTION_STATUS = {
  PENDING: [0],
  COMPLETED: [1, 5],
  CANCELLED: [2, 3, 6],
  REQUESTED: [7],
  REFUNDED: [8],
  CHARGEBACK: [9]
}

export const findTypeStatus = (ids) => {
  let name = null;
  Object.keys(TRANSACTION_STATUS).forEach(function (key, index) {
    if (TRANSACTION_STATUS[key].includes(ids)) {
      name = key;
    }
  });
  return name;
}
export const ACTION_TYPE_CONST = [
  { "label": "Deposit", "value": "deposit" },
  { "label": "Withdraw", "value": "withdraw" },
  { "label": "addMoney", "value": "Add Money" },
  { "label": "removeMoney", "value": "Remove Money" },
  { "label": "depositInternal", "value": "Internal Deposit" },
  { "label": "addMoneyInternal", "value": "Internal Add Money" },
  { "label": "withdrawInternal", "value": "Internal Withdraw" },
  { "label": "removeMoneyInternal", "value": "Internal Remove Money" },
  { "label": "reversal", "value": "Reversed" },
  { "label": "depositInternal", "value": "Internal Deposit" },
]