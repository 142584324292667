import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { useNavigate } from 'react-router-dom'

import { getMatchesListStart, getSportsListStart } from '../../../store/redux-slices/sportsBook'

const useSportsListing = () => {
  const dispatch = useDispatch()

  const [limit, setLimit] = useState(10)
  const [pageNo, setPageNo] = useState(1);
  const [searchByName, setSearchByName] = useState("");
  const [searchByStatus, setSearchByStatus] = useState("");
  const [isLive, setIsLive] = useState("");
  const [isFeatured, setIsFeatured] = useState("");

  const [searchBySports, setSearchBySports] = useState("");

  const { sportsMatchesInfo, isGetMatchesLoading, sportsListInfo } = useSelector(state => state.sportsBook)
  const totalPages = Math.ceil(sportsMatchesInfo?.totalPage / limit)

  useEffect(() => {
    dispatch(getSportsListStart({
      limit: 15,
      pageNo: 1,
      isAllListing: true
    }))
  }, [])

  useEffect(() => {
    dispatch(getMatchesListStart({
      limit: limit,
      pageNo: pageNo,
      search: searchByName,
      isLive,
      isFeatured,
      eventStatus: searchByStatus,
      providerSportId: searchBySports
    })
    )
  }, [limit, pageNo, searchByName, searchByStatus, isLive, isFeatured, searchBySports])

  return {
    limit,
    setLimit,
    pageNo,
    setPageNo,
    sportsMatchesInfo,
    totalPages,
    isGetMatchesLoading,
    searchByName,
    setSearchByName,
    searchByStatus,
    setSearchByStatus,
    isLive,
    setIsLive,
    isFeatured,
    setIsFeatured,
    sportsListInfo,
    searchBySports,
    setSearchBySports
  }
}

export default useSportsListing
