import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { AdminsRoutes } from '../../../../routes';

const useEditCurrency = () => {
 

  const { socialLink, loading } = useSelector((state) => state.socialLinks)
 const navigate =  useNavigate()
  useEffect(()=>{
    if(!socialLink) navigate(AdminsRoutes.SocialLinks) 
  },[])
  return {
   
    socialLink,
    loading
  }
}

export default useEditCurrency
