import React from "react";
import { Button, Form, Row, Col, Table } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";

import Trigger from "../../../components/OverlayTrigger";
import { subAffiliateTableHeaders } from "./constants";
import useAllAffiliateListing from "../../Super-Admin/Affiliates/hooks/useAllAffiliateListing";
import PaginationComponent from "../../../components/Pagination";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { ACTIVE_LABEL, ACTIVE_VALUE, AFFILIATE_TYPE_LABEL_BY_VALUE, STATUS_LABEL, VISIBILYT_LABEL, VISIBILYT_VALUE } from "../../../utils/constant";
import { useSelector } from "react-redux";

import ActionMenu from "./components/ActionMenu";
import { AffiliateRoutes } from "../../../routes";

const SubAffiliatePage = () => {
  const { adminDetails: { affiliate } } = useSelector(
    (state) => state.admins
  );

  const {
    limit,
    setLimit,
    page,
    setPage,
    search,
    setSearch,
    affiliatesData,
    totalPages,
    navigate,
    loading,
    show,
    setShow,
    active,
    handleYes,
    resetFilter,
  } = useAllAffiliateListing({ parentIdAffiliate: affiliate.affiliateId });

  return (
    <>
      <div className="section-header-wrap mt-n3 mx-n3">
        <Row>
          <Col lg={8} xs={7}>
            <h2 className="title-text">Sub Affiliados</h2>
          </Col>
        </Row>
      </div>

      <div className="FormsBg">
        <Row>
          <Col xs={12} sm={6} className="mb-3 mb-sm-0">
            <Form.Control
              type="search"
              placeholder="Pesquisar Email, Nome, Usuário"
              size="sm"
              value={search}
              onChange={(event) =>
                setSearch(
                  event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, "")
                )
              }
            />
          </Col>
        </Row>
      </div>

      <div className="TableBg mt-4 pt-1">
        <Table striped responsive hover size="sm" className="text-center mt-3">
          <thead className="thead-light">
            <tr>
              {subAffiliateTableHeaders.map((h, idx) => (
                <th key={idx}>{h.label}{" "}</th>
              ))}
            </tr>
          </thead>

          <tbody id={loading ? "cover-spin" : ""}>
            {!loading &&
              Boolean(affiliatesData) &&
              affiliatesData?.rows?.map(
                ({
                  affiliateId,
                  affiliateType,
                  email,
                  isActive,
                  userId,
                  firstName,
                  lastName,
                  username,
                  status,
                  isVisible,
                }) => {

                  return (
                    <tr key={affiliateId} >
                      <td>{affiliateId}</td>
                      <td className="text-left cursor-pointer" onClick={() => navigate(`${AffiliateRoutes.SubAffiliateDetails(affiliateId)}`)}>
                        <Trigger message={username}>
                          <span className="text-link d-inline-block text-truncate">
                            {username}
                          </span>
                        </Trigger>
                      </td>
                      <td className="text-left">{AFFILIATE_TYPE_LABEL_BY_VALUE[affiliateType]}</td>
                      <td className="text-left">{email}</td>
                      <td className="text-left">{firstName}</td>
                      <td className="text-left">{lastName}</td>
                      <td>{status ? <span className="w-100" data-badge={status}>{STATUS_LABEL[String(status).toUpperCase()]}</span> : "N/A"}</td>
                      <td><span className="w-100" data-badge={ACTIVE_VALUE[isActive]}>{ACTIVE_LABEL[isActive]}</span></td>
                      {/* <td><span className="w-100" data-badge={VISIBILYT_VALUE[isVisible]}>{VISIBILYT_LABEL[isVisible]}</span></td> */}
                      {/* <td>{commission?.commission || 0}</td> */}
                    </tr>
                  );
                }
              )}

            {affiliatesData?.count === 0 && !loading && (
              <tr>
                <td colSpan={13} className="text-danger text-center">
                  Nenhum dado encontrado
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {affiliatesData?.count !== 0 && !loading && (
          <PaginationComponent
            page={affiliatesData?.count < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
          />
        )}
      </div>

      {show && (
        <ConfirmationModal
          setShow={setShow}
          show={show}
          handleYes={handleYes}
          active={active}
          name={'name'}
        />
      )}
    </>
  );
};

export default SubAffiliatePage;
