import { Card, Col, Row } from '@themesberg/react-bootstrap'
import React from 'react'
import './style.css'
import Affiliate from './Affiliate'

const Overview = ({
  basicInfo,
  kycInfo,
  contactInfo,
  user,

}) => {
  return (
    <>
      <Row>
        <Col lg={6} xs={12} className='col-padding'>
          <Card className='card-overview'>
            <h4 className='h4-overview text-center mt-1'>Informações básicas <hr className='h4-hr mt-1' /></h4>
            {/* <div className='div-overview'> */}
            {basicInfo?.map(({ label, value, subValue }) => {
              return (user?.kycMethod !== 1 && label === 'Applicant Id')
                ? ''
                : (label === 'Reason' && value ? true : label !== 'Reason') && (
                  <div key={label} className='d-flex justify-content-between m-1'>
                    <h6 className='px-2 overview-leftlabel'>{label}</h6>
                    <span className={`${subValue} px-2`}>{value || 'N/A'}</span>
                  </div>
                )
            })}
          </Card>
        </Col>
        <Col lg={6} xs={12} className=''>
          <Card className='card-overview'>
            <h4 className='h4-overview text-center mt-1'>Outras informações<hr className='h4-hr mt-1' /></h4>
            <h5 className='overview-subhead mt-1'>Contact Info </h5>
            {contactInfo?.map(({ label, value, subValue }) => {
              return (user?.kycMethod !== 1 && label === 'Applicant Id')
                ? ''
                : (label === 'Reason' && value ? true : label !== 'Reason') && (
                  <div key={label} className='d-flex justify-content-between m-1'>
                    <h6 className='px-2 overview-leftlabel'>{label}</h6>
                    <span className={`${subValue} px-2`}>{value || 'N/A'}</span>
                  </div>
                )
            })}

            {/* <div className='div-overview'>	 */}
            <h5 className='overview-subhead mt-2'>Afiliado</h5>
            <div className='d-flex justify-content-between m-1'>
              <h6 className='px-2 overview-leftlabel'>Token afiliado</h6>
              <span className='px-2'>{user?.trackingToken || 'N/A'}</span>
            </div>
            <div className='d-flex justify-content-between m-1'>
              <h6 className='px-2 overview-leftlabel'>Status afiliado</h6>
              {user?.affiliateStatus
                ? (
                  <span className='text-success px-2'>Vinculado</span>
                )
                : (
                  <span className='text-danger px-2'>Não vinculado</span>)}
            </div>
            {/* </div> */}
            <h5 className='overview-subhead mt-2'>Documentos KYC </h5>
            {kycInfo?.map(({ label, value, subValue }) => {
              return (user?.kycMethod !== 1 && label === 'Applicant Id')
                ? ''
                : (label === 'Reason' && value ? true : label !== 'Reason') && (
                  <div key={label} className='d-flex justify-content-between m-1'>
                    <h6 className='px-2 overview-leftlabel'>{label}</h6>
                    <span className={`${subValue} px-2`}>{value || 'N/A'}</span>
                  </div>
                )
            })}
          </Card>
        </Col>
      </Row>

      <Row>
        {user?.affiliate &&
          <Col xs={5}>
            <Affiliate userData={user} />
          </Col>}
      </Row>
    </>
  )
}

export default Overview
