import { takeLatest, put, select, takeEvery } from "redux-saga/effects";
import {
  getAfffiliateTransactionBanking,
  getAffiliateAdminDetails,
  getAffiliateAssociatedPlayerCasinoTransaction,
  getAffiliateAssociatedPlayerDetails,
  getAffiliateAssociatedPlayerTransactionBanking,
  getAffiliateCommissionsReport,
  getAffiliateLiveCommissionsReport,
  getAffiliateDashboardData,
  getAffiliatePlayersReport,
  getAffiliateProductsReport,
  getAffiliateReferralCode,
  getAffiliateTopPlayers,
  registerAffiliate,
  updateAffiliate,
  updateAffiliateStatus,
  getChartAnalysis,
  getSubAffiliateCommissionsReport,
  getSubAffiliateLiveCommissionsReport,
} from "../../utils/apiCalls";

import {
  createAffiliateStart,
  createAffiliateSuccess,
  createAffiliateFailure,
  updateAffiliateStart,
  updateAffiliateSuccess,
  updateAffiliateFailure,
  registerAffiliateStart,
  registerAffiliateSuccess,
  registerAffiliateFailure,
  affiliateAdminDetailsStart,
  affiliateAdminDetailsSuccess,
  affiliateAdminDetailsFailure,
  getAffiliateReferralCodeStart,
  getAffiliateReferralCodeSuccess,
  getAffiliateReferralCodeFailure,
  getAffiliateDashboardDataStart,
  getAffliateDashboardSuccess,
  getAffiliateDashboardDataFailure,
  getAffiliateProductsReportSuccess,
  getAffiliateProductsReportFailure,
  getAffiliatePlayersReportSuccess,
  getAffiliatePlayersReportFailure,
  getAffiliateProductsReportStart,
  getAffiliatePlayersReportStart,
  getAffiliateCommissionReportStart,
  getAffiliateCommissionReportSuccess,
  getAffiliateCommissionLiveReportStart,
  getAffiliateCommissionLiveReportSuccess,
  getSubAffiliateCommissionReportStart,
  getSubAffiliateCommissionReportSuccess,
  getSubAffiliateLiveCommissionReportStart,
  getSubAffiliateLiveCommissionReportSuccess,
  getDashboardTotalReportsSuccess,
  getDashboardTotalReportsFailure,
  getDashboardTotalReportsStart,
  getAffiliateTopPlayersStart,
  getAffiliateTopPlayersFailure,
  getAffiliateTopPlayersSuccess,
  getAssociatedPlayerDetailsSuccess,
  getAssociatedPlayerDetailsFailure,
  getAssociatedPlayerDetailsStart,
  getAssociatedPlayerCasinoTransactionSuccess,
  getAssociatedPlayerCasinoTransactionFailure,
  getAssociatePlayerBankingTransactionsSuccess,
  getAssociatePlayerBankingTransactionsFailure,
  getAssociatedPlayerCasinoTransactionStart,
  getAssociatePlayerBankingTransactionsStart,
  getTransactionBankingSuccess,
  getTransactionBankingFailure,
  getTransactionBankingStart,
  updateAffiliateStatusSuccess,
  updateAffiliateStatusFailure,
  updateAffiliateStatusStart,
  getChartReportSuccess,
  getChartReportStart,
} from "../redux-slices/affiliates";
import {
  getSuperAdminDetailsSuccess,
  getLoggedSADetailsSuccess,
} from "../redux-slices/admins";

import { toast } from "../../components/Toast";
import { AdminsRoutes, AffiliateRoutes } from "../../routes";
import {
  getAffiliateByIdStart,
  getAllAffiliatesStart,
} from "../redux-slices/fetchData";
import { updateSuperAdminStatusStart } from "../redux-slices/adminUser";
import { superAdminLoginSuccess } from "../redux-slices/login";
import { getItem } from "../../utils/storageUtils";

export default function* affiliatesWatcher() {
  yield takeLatest(createAffiliateStart.type, createAffiliateWorker);
  yield takeLatest(updateAffiliateStart.type, updateAffiliateWorker);
  yield takeLatest(registerAffiliateStart.type, registerAffiliateWorker);
  yield takeLatest(affiliateAdminDetailsStart, affiliateAdminDetailsWorker);
  yield takeLatest(getAffiliateReferralCodeStart, affiliateReferralCodeWorker);
  yield takeLatest(getAffiliateDashboardDataStart, affiliateDashboardWorker);
  yield takeLatest(
    getAffiliateProductsReportStart,
    affiliateProductsReportWorker
  );
  yield takeLatest(
    getAffiliatePlayersReportStart,
    affiliatePlayersReportWorker
  );
  yield takeEvery(
    getAffiliateCommissionReportStart,
    affiliateCommissionReportWorker
  );
  yield takeEvery(
    getAffiliateCommissionLiveReportStart,
    affiliateCommissionLiveReportWorker
  );
  yield takeEvery(
    getSubAffiliateCommissionReportStart,
    subAffiliateCommissionReportWorker
  );
  yield takeEvery(
    getSubAffiliateLiveCommissionReportStart,
    subAffiliateLiveCommissionReportWorker
  );
  yield takeEvery(
    getChartReportStart,
    getChartReportWorker
  );
  yield takeEvery(
    getDashboardTotalReportsStart,
    affiliateDashboardTotalReportWorker
  );
  yield takeLatest(getAffiliateTopPlayersStart, affiliateTopPlayersWorker);
  yield takeLatest(
    getAssociatedPlayerDetailsStart,
    getAssociatedPlayerDetailWorker
  );
  yield takeLatest(
    getAssociatedPlayerCasinoTransactionStart.type,
    getAssociatedPlayerCasinoTransactionWorker
  );
  yield takeLatest(
    getAssociatePlayerBankingTransactionsStart,
    getAssociatedPlayerBankingTransactionWorker
  );
  yield takeLatest(
    getTransactionBankingStart,
    getAffiliateTransactionBankingWorker
  );
  yield takeLatest(updateAffiliateStatusStart, updateAffiliateStatusWorker);
}

function* createAffiliateWorker(action) {
  const { data, navigate } = action.payload;
  try {
    const { data: affiliateData } = yield registerAffiliate(data);
    yield toast(affiliateData?.data.message, "success");
    yield put(createAffiliateSuccess(affiliateData?.data?.createAffiliates));
    navigate(AdminsRoutes.AffiliatesManagement);
  } catch (error) {
    yield toast(error?.response?.data?.errors[0]?.description, "error");
    yield put(
      createAffiliateFailure(error?.response?.data?.errors[0]?.description)
    );
  }
}

function* updateAffiliateWorker(action) {
  const { data, navigate, location, redirect_uri } = action.payload;
  try {
    const { data: affiliateData } = yield updateAffiliate(data);
    // yield toast(affiliateData?.data.message, 'success')
    yield toast("Record updated successfully", "success");
    // yield put(createAffiliateSuccess(affiliateData?.data?.createAffiliate))
    if (redirect_uri) {
      navigate(redirect_uri)
    }
    else if (location.pathname.startsWith("/admin")) {
      navigate(AdminsRoutes.AffiliatesManagement);
    }
    else if (location.pathname.startsWith("/affiliate")) {
      navigate(AffiliateRoutes.Profile);
    }

    if (getItem("role") == "Affiliate") {
      yield put(affiliateAdminDetailsStart({ onStart: true }));
    }
  } catch (error) {
    // yield toast(error?.response?.data?.errors[0]?.description, 'error')
    yield put(
      createAffiliateFailure(error?.response?.data?.errors[0]?.description)
    );
  }
}

function* registerAffiliateWorker(action) {
  const { affiliateData } = action.payload;
  try {
    const { data } = yield registerAffiliate(affiliateData);
    yield toast("Affiliate Approved Successfully.", "success");
    yield put(registerAffiliateSuccess());
    yield put(
      getAffiliateByIdStart({ affiliateId: affiliateData.affiliateId })
    );
  } catch (error) {
    yield toast(error?.response?.data?.errors[0]?.description, "error");
    yield put(
      registerAffiliateFailure(error?.response?.data?.errors[0]?.description)
    );
  }
}

function* affiliateAdminDetailsWorker(action) {
  try {
    const { onStart = false } = action && action.payload;
    const { data } = yield getAffiliateAdminDetails();
    yield put(getSuperAdminDetailsSuccess(data?.data?.adminDetails));
    yield put(affiliateAdminDetailsSuccess(data?.data?.adminDetails));
    yield put(superAdminLoginSuccess('Affiliate'))
    if (onStart) yield put(getLoggedSADetailsSuccess(data?.data?.adminDetails));
  } catch (error) {
    yield put(
      affiliateAdminDetailsFailure(
        error?.response?.data?.errors[0]?.description
      )
    );
  }
}

function* affiliateReferralCodeWorker(action) {
  try {
    const { data } = yield getAffiliateReferralCode();
    yield put(getAffiliateReferralCodeSuccess(data?.data));
  } catch (error) {
    yield put(
      getAffiliateReferralCodeFailure(
        error?.response?.data?.errors[0]?.description
      )
    );
  }
}

function* affiliateDashboardWorker(action) {
  try {
    const { data } = yield getAffiliateDashboardData(action.payload);
    yield put(getAffliateDashboardSuccess(data?.data));
  } catch (error) {
    yield put(
      getAffiliateDashboardDataFailure(
        error?.response?.data?.errors[0]?.description
      )
    );
  }
}

function* affiliateProductsReportWorker({ payload }) {
  try {
    const { data } = yield getAffiliateProductsReport(payload);

    yield put(getAffiliateProductsReportSuccess(data?.data?.gameReport));
  } catch (error) {
    yield put(
      getAffiliateProductsReportFailure(
        error?.response?.data?.errors[0]?.description
      )
    );
  }
}

function* affiliatePlayersReportWorker({ payload }) {
  try {
    const { data } = yield getAffiliatePlayersReport(payload);
    yield put(getAffiliatePlayersReportSuccess(data?.data));
  } catch (error) {
    yield put(
      getAffiliatePlayersReportFailure(
        error?.response?.data?.errors[0]?.description
      )
    );
  }
}

function* affiliateCommissionReportWorker({ payload }) {
  try {
    const { type, ...rest } = payload;

    if (type === "yesterday") {
      const { data } = yield getAffiliateCommissionsReport(rest);
      const dataToBeStore = {
        gameReport: data?.data?.gameReport,
      };
      yield put(
        getAffiliateCommissionReportSuccess({ yesterday: dataToBeStore })
      );
    }
    if (type === "currentMonth") {
      const { data } = yield getAffiliateCommissionsReport(rest);
      const dataToBeStore = {
        gameReport: data?.data?.gameReport,
      };
      yield put(
        getAffiliateCommissionReportSuccess({ currentMonth: dataToBeStore })
      );
    }
  } catch (error) {
    yield put(
      getAffiliatePlayersReportFailure(
        error?.response?.data?.errors[0]?.description
      )
    );
  }
}

function* affiliateCommissionLiveReportWorker({ payload }) {
  try {
    const { type, ...rest } = payload;
    const { data } = yield getAffiliateLiveCommissionsReport(rest);
    yield put(
      getAffiliateCommissionLiveReportSuccess({ liveComission: data.data })
    );
  } catch (error) {
    yield put(
      getAffiliatePlayersReportFailure(
        error?.response?.data?.errors[0]?.description
      )
    );
  }
}
function* subAffiliateCommissionReportWorker({ payload }) {
  try {
    const { type, ...rest } = payload;
    const { data } = yield getSubAffiliateCommissionsReport(rest);
    yield put(
      getSubAffiliateCommissionReportSuccess({ subAffiliateCommission: data.data })
    );
  } catch (error) {
    yield put(
      getAffiliatePlayersReportFailure(
        error?.response?.data?.errors[0]?.description
      )
    );
  }
}
function* subAffiliateLiveCommissionReportWorker({ payload }) {
  try {
    const { type, ...rest } = payload;
    const { data } = yield getSubAffiliateLiveCommissionsReport(rest);
    yield put(
      getSubAffiliateLiveCommissionReportSuccess({ subAffiliateLiveCommission: data.data })
    );
  } catch (error) {
    yield put(
      getAffiliatePlayersReportFailure(
        error?.response?.data?.errors[0]?.description
      )
    );
  }
}

function* getChartReportWorker({ payload }) {
  try {
    const { type, ...rest } = payload;
    const { data } = yield getChartAnalysis(rest);
    yield put(
      getChartReportSuccess({ chart: data.data })
    );
  } catch (error) {
    yield put(
      getAffiliatePlayersReportFailure(
        error?.response?.data?.errors[0]?.description
      )
    );
  }
}

function* affiliateDashboardTotalReportWorker({ payload }) {
  try {
    if (payload.reportOf === "casino") {
      const { data } = yield getAffiliateCommissionsReport(payload);
      const dataToBeStore = {
        gameReport: data?.data?.gameReport,
      };
      yield put(
        getDashboardTotalReportsSuccess({ totalreports: dataToBeStore })
      );
    }

    if (payload.reportOf === "sports") {
      const { data } = yield getAffiliateCommissionsReport(payload);
      const dataToBeStore = {
        gameReport: data?.data?.gameReport,
      };
      yield put(
        getDashboardTotalReportsSuccess({ sportsReport: dataToBeStore })
      );
    }
  } catch (error) {
    console.log("error :>> ", error);
    yield put(
      getDashboardTotalReportsFailure(
        error?.response?.data?.errors[0]?.description
      )
    );
  }
}

function* affiliateTopPlayersWorker({ payload }) {
  try {
    const { data } = yield getAffiliateTopPlayers(payload);
    yield put(getAffiliateTopPlayersSuccess({ topPlayers: data?.data }));
  } catch (error) {
    yield put(
      getAffiliateTopPlayersFailure(
        error?.response?.data?.errors[0]?.description
      )
    );
  }
}

function* getAssociatedPlayerDetailWorker({ payload }) {
  try {
    const { data } = yield getAffiliateAssociatedPlayerDetails(payload);
    yield put(getAssociatedPlayerDetailsSuccess(data?.data?.getUser));
  } catch (error) {
    yield put(
      getAssociatedPlayerDetailsFailure(
        error?.response?.data?.errors[0]?.description
      )
    );
  }
}

function* getAssociatedPlayerCasinoTransactionWorker({ payload }) {
  try {
    const { data } = yield getAffiliateAssociatedPlayerCasinoTransaction(
      payload
    );
    // const data  = null
    console.log("object :>> ", payload);
    yield put(
      getAssociatedPlayerCasinoTransactionSuccess(data?.data?.transactionDetail)
    );
  } catch (error) {
    yield put(
      getAssociatedPlayerCasinoTransactionFailure(
        error?.response?.data?.errors[0]?.description
      )
    );
  }
}

function* getAssociatedPlayerBankingTransactionWorker({ payload }) {
  try {
    const { data } = yield getAffiliateAssociatedPlayerTransactionBanking(
      payload
    );
    yield put(
      getAssociatePlayerBankingTransactionsSuccess(
        data?.data?.transactionDetail
      )
    );
  } catch (error) {
    yield put(
      getAssociatePlayerBankingTransactionsFailure(
        error?.response?.data?.errors[0]?.description
      )
    );
  }
}
function* getAffiliateTransactionBankingWorker({ payload }) {
  try {
    const { data } = yield getAfffiliateTransactionBanking(payload);
    yield put(getTransactionBankingSuccess(data?.data?.transactionDetail));
  } catch (error) {
    yield put(
      getTransactionBankingFailure(
        error?.response?.data?.errors[0]?.description
      )
    );
  }
}
function* updateAffiliateStatusWorker({ payload }) {
  try {
    const { data, adminIdAffiliate, ...rest } = payload;
    yield updateAffiliateStatus(data);
    yield put(updateAffiliateStatusSuccess());
    yield put(getAllAffiliatesStart(rest));
    yield put(
      updateSuperAdminStatusStart({
        data: {
          ...data,
          code: "ADMIN",
          adminId: adminIdAffiliate,
        },
        limit: 15,
        pageNo: 1,
        orderBy: "adminUserId",
        sort: "desc",
        search: "",
        superAdminId: "",
        superRoleId: "",
        status: "",
        shouldGetAffiliateUsers: false,
      })
    );
  } catch (error) {
    yield put(
      updateAffiliateStatusFailure(
        error?.response?.data?.errors[0]?.description
      )
    );
  }
}
