import React from 'react'
import { Card, Row, Col } from '@themesberg/react-bootstrap'
import { Formik } from 'formik'
import { updateSuperAdminUserSchema } from '../schemas'
import SuperAdminUserForm from './SuperAdminUserForm'
import useEditAdmin from '../hooks/useEditAdmin'
import { toast } from '../../../../components/Toast'

const EditSuperAdminUser = () => {
  const {
    adminData,
    editAdmin
  } = useEditAdmin()
  return (
    <>
   <div className='section-header-wrap mt-n3 mx-n3'>

      <Row>
        <Col sm={8}>
          <h2 className='title-text'>Edit Staff</h2>
        </Col>
      </Row>
      </div>
      <div className='FormsBg'>
        {adminData && (
          <Formik
            initialValues={{
              email: adminData?.adminDetails.email,
              adminUsername: adminData?.adminDetails.adminUsername,
              firstName: adminData?.adminDetails.firstName,
              lastName: adminData?.adminDetails.lastName,
              role: adminData?.adminDetails.AdminRole?.name,
              adminId: adminData?.adminDetails.parentId,
              permission:
                adminData?.adminDetails.userPermission?.permission,
              group:
                adminData?.adminDetails.group
            }}
            validationSchema={()=>updateSuperAdminUserSchema({maxCommissionPercentage:100})}
            onSubmit={(formValues) => {
              ([undefined, null].includes(formValues.permission) || Object.keys(formValues.permission).length < 1)
                ? toast('Please Select At Least One Permission', 'error')
                : editAdmin({
                  data: {
                    ...formValues,
                    adminUserId: adminData?.adminDetails?.adminUserId,
                    password: formValues?.password ? Buffer.from(formValues.password).toString('base64') : ''
                  }
                })
            }}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              handleBlur,
              setFieldValue
            }) => (
              <SuperAdminUserForm
                values={values}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                isEdit
              />
            )}
          </Formik>
        )}
        </div>
    </>
  )
}

export default EditSuperAdminUser
