import React from 'react'
import useSportsTransactionsList from './hooks/useSportsTransactionsList'
import { Button, Col, Row, Form } from '@themesberg/react-bootstrap'
import DateRangePicker from '../../../components/DateRangePicker'
import { getDateDaysAgo } from '../../../utils/dateFormatter'
import { transactionType, actionType, ACTION_TYPE_CONST } from './constants'
import SportsTransactionsList from './SportsTransactionsList'
import ClientFilter from '../../../components/ClientFilter'
import PortalFilter from '../../../components/PortalFilter'
import { downloadFile } from '../../../utils/fileDownloader'
import { toast } from '../../../components/Toast'
import Trigger from '../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt, faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { InputGroup } from 'react-bootstrap'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
const SportsTransactions = () => {
  const {
    allCurrencies,
    setSelectedCurrency,
    setSearch,
    setLimit,
    setPage,
    totalPages,
    limit,
    page,
    setSelectedAction,
    selectedCurrency,
    selectedAction,
    search,
    state,
    setState,
    sportTransactions,
    loading,
    status,
    setStatus,
    // selectedClient,
    // selectedPortal,
    setSelectedClient,
    // setSelectedPortal,
    // getCsvDownloadUrl,
    selectedActionType, 
    setSelectedActionType
  } = useSportsTransactionsList();
  return (
    <>
      {window.location.pathname === "/admin/sports-transactions" && <div className='section-header-wrap mt-n3 mx-n3'>
        <Row>
          <Col>
            <h2 className='title-text'>Sports Transactions</h2>
          </Col>
        </Row>
      </div>}
      <div className='FormsBg'>
        <Row>
          <Col xs={2}>
            <Form.Select
              size='sm'
              onChange={(e) => setSelectedCurrency(e.target.value)}
              value={selectedCurrency}
            >
              <option value=''>Selecionar moeda</option>
              {allCurrencies && allCurrencies?.rows?.map(
                ({ name: currName, currencyId, code }) => (
                  <option key={currencyId} value={currencyId}>
                    {currName}
                  </option>
                )
              )}
            </Form.Select>
          </Col>
          <Col xs={2}>
            <InputGroup>
              <Form.Control
                size='sm'
                type='search'
                value={search}
                placeholder='Pesquisar Email'
                onChange={e => {
                  if (e.target.value) {
                    setSearch(e.target.value.replace(/[~`!$%^&*#=)()><?]+/g, ''))
                  } else {
                    setSearch('');
                    setPage(1);
                  }

                }}

              />
            </InputGroup>
          </Col>
          <Col xs={2}>
            <Form.Select
              size='sm'
              onChange={(e) => setSelectedActionType(e.target.value)}
              value={selectedActionType}
            >
              <option value='all'>Tipos de ação</option>
              <option key="DISCARD" value="discard">descartar</option>
              <option key="SETTLEMENT" value="settlement">povoado</option>
              <option key="BET" value="bet">aposta</option>
              <option key="ROLLBACK" value="rollback">reverter</option>
              <option key="LOST" value="lost">perdido</option>
              <option key="WIN" value="win">vitorias</option>
              <option key="REFUND" value="refund">reembolso</option>
            </Form.Select>
          </Col>
          {/* <Col xs={2}>
            <Form.Select
              size='sm'
              onChange={(e) => setSelectedAction(e.target.value)}
              value={selectedAction}
            >
             <option value="all">Action Type</option>
              {ACTION_TYPE_CONST && ACTION_TYPE_CONST?.map(
                ({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                )
              )}
            </Form.Select>
          </Col> */}
          {/* <Col xs={2}>
            <Form.Select
              onChange={(e) => setStatus(e.target.value)}
              value={status}
              size='sm'
              // style={{ maxWidth: '230px' }}
            >
              {transactionType && transactionType?.map(
                ({ label, value }) => (
                  <option key={label} value={value}>
                    {label}
                  </option>
                )
              )}
            </Form.Select>
          </Col> */}
          <Col xs={3}>
            <DateRangePicker
              state={state} setState={setState}
              size='sm'
            />
          </Col>
          <Col xs={1}>
            <div className='d-flex align-item-center'>
              <Trigger message='Reset Filters'>
                <Button
                  size='sm'
                  onClick={() => {
                    setSearch('')
                    setSelectedAction('all')
                    setSelectedCurrency('')
                    setSelectedClient('')
                    setLimit(10)
                    setPage(1)
                    setState([
                      {
                        startDate: getDateDaysAgo(10),
                        endDate: new Date(),
                        key: 'selection'
                      }
                    ])
                    setStatus('all')
                  }}
                >
                  <FontAwesomeIcon icon={faRedoAlt} />
                </Button>
              </Trigger>
            </div>
          </Col>
        </Row>
      </div>
      <SportsTransactionsList
        page={page}
        setLimit={setLimit}
        limit={limit}
        setPage={setPage}
        totalPages={totalPages}
        transactions={sportTransactions}
        isTenant={false}
        loading={loading}
      />
    </>
  )
}

export default SportsTransactions
