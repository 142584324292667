import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { Form } from "react-bootstrap";
import { InputNumberFormat, useNumberFormat } from "@react-input/number-format";

// export const CurrencyInput = forwardRef(({ onChange, currency = "BRL", onNumberFormat, ...props}, ref) => {

//     const locales = { 
//         BRL: 'pt-BR',
//         USD: 'en-UR',
//       }

//     const inputRef = useNumberFormat({ 
//         locales: locales[currency], 
//         format:"currency",
//         currency,
//         maximumFractionDigits: 2,
//         onNumberFormat: ({ detail }) => onNumberFormat(detail)
//     },
//     );

//     const clear = () => {
//         inputRef.current.value = ""
//     }

//     useImperativeHandle(ref, () => ({ clear }))

//     return (<Form.Control ref={inputRef} {...props} onChange={onChange}/>)
// })

// export default CurrencyInput;



export const CurrencyInput = forwardRef(({ currency = "BRL", ...props }, ref) => {
  const inputRef = useRef();

  const locales = {
    BRL: 'pt-BR',
    USD: 'en-UR',
  }

  const config = {
    locales: locales[currency],
    format: "currency",
    currency,
    maximumFractionDigits: 2,
  };

  const clear = () => {
    inputRef.current.value = ""
  }

  // useEffect(() => {
  //   console.log(inputRef)

  // }, [props.value, props.defaultValue])

  useImperativeHandle(ref, () => ({ clear }))

  return (<InputNumberFormat component={Form.Control} ref={inputRef} {...props} {...config} />)
})

export default CurrencyInput;