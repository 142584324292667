import React from 'react';
import { Col, Row } from '@themesberg/react-bootstrap';
import AffiliateTransactionsTable from '../../Super-Admin/AffiliateDetails/components/AffiliateTransactions';
import { useSelector } from 'react-redux';

export default () => {

  const { adminDetails: { affiliate } } = useSelector((state) => state.admins);

  return (
    <>
      <div className='section-header-wrap mt-n3 mx-n3'>
        <Row>
          <Col>
            <h2 className='title-text'>Transações bancárias</h2>
          </Col>
        </Row>
      </div>
      <AffiliateTransactionsTable affiliate={affiliate} style={{ maring: 0 }} />
    </>
  )
}
