import { takeLatest, put } from 'redux-saga/effects'
import {
  getPaymentAggregators,
  getPaymentProvider,
  getProviderDetails,
  configProviderSettings,
  reOrderPaymentProviders,
  superAdminViewToggleStatus,
  getPaymentCategories,
  orderPaymentCategories
} from '../../utils/apiCalls'

import {
  getPaymentAggregatorsStart,
  getPaymentAggregatorsSuccess,
  getPaymentAggregatorsFailure,
  getPaymentProviderStart,
  getPaymentProviderSuccess,
  getPaymentProviderFailure,
  getProviderDetailsStart,
  getProviderDetailsSuccess,
  getProviderDetailsFailure,
  configProviderSettingsStart,
  configProviderSettingsComplete,
  updatePaymentProviderStatusStart,
  updatePaymentProviderStatusComplete,
  reOrderPaymentProvidersStart,
  reOrderPaymentProvidersComplete,
  getPaymentCategoriesStart,
  getPaymentCategoriesSuccess,
  getPaymentCategoriesFailure,
  reOrderPaymentCategoriesStart,
  reOrderPaymentCategoriesComplete
} from '../redux-slices/payment'
import { toast } from '../../components/Toast'
import { serialize } from 'object-to-formdata'

export default function * paymentWatcher () {
  yield takeLatest(getPaymentAggregatorsStart.type, getPaymentAggregatorsWorker)
  yield takeLatest(getPaymentProviderStart.type, getPaymentProviderWorker)
  yield takeLatest(getProviderDetailsStart.type, getProviderDetailsWorker)
  yield takeLatest(configProviderSettingsStart.type, configProviderSettingsWorker)
  yield takeLatest(updatePaymentProviderStatusStart.type, updatePaymentProviderStatusWorker)
  yield takeLatest(reOrderPaymentProvidersStart.type, reOrderPaymentProvidersWorker)
  yield takeLatest(getPaymentCategoriesStart.type, getPaymentCategoriesWorker)
  yield takeLatest(reOrderPaymentCategoriesStart.type, orderPaymentCategoriesWorker)
}

function * getPaymentAggregatorsWorker (action) {
  try {
    const { isTenant } = action && action.payload

    const { data } = yield getPaymentAggregators(isTenant)

    yield put(getPaymentAggregatorsSuccess(data?.data?.aggregatorGroups))
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(getPaymentAggregatorsFailure(e.response.data.message))
  }
}

function * getPaymentProviderWorker (action) {
  try {
    const { isTenant, paymentType, aggregator, tenant, group, status, tenantIds, category = '' } = action && action.payload

    const { data } = yield getPaymentProvider({
      isTenant, paymentType, aggregator, tenant, group, status, tenantIds, category
    })

    yield put(getPaymentProviderSuccess(data?.data?.paymentMethods))
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(getPaymentProviderFailure(e.response.data.message))
  }
}

function * getProviderDetailsWorker (action) {
  try {
    const { isTenant, providerId } = action && action.payload

    const { data } = yield getProviderDetails({ isTenant, providerId })

    yield put(getProviderDetailsSuccess(data?.data?.provider))
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(getProviderDetailsFailure(e.response.data.message))
  }
}

function * configProviderSettingsWorker (action) {
  try {
    let { data, isTenant, providerId, country } = action && action.payload

    if (!country) data = serialize(data)

    yield configProviderSettings({ data, isTenant })

    yield put(configProviderSettingsComplete())
    yield put(getProviderDetailsStart({ isTenant, providerId }))

    yield toast('Settings Updated Successfully', 'success')
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(configProviderSettingsComplete(e.response.data.message))
  }
}

function * updatePaymentProviderStatusWorker (action) {
  try {
    const { data, isTenant, status, catgeory, paymentType } =
        action && action.payload

    yield superAdminViewToggleStatus(data)

    yield put(updatePaymentProviderStatusComplete())

    yield toast('Payment Provider Status Updated Successfully', 'success')
    yield put(getPaymentAggregatorsStart(isTenant))
    yield put(getPaymentProviderStart({
      isTenant: false,
      paymentType,
      aggregator: '',
      tenant: '',
      group: '',
      status,
      tenantIds: [],
      catgeory
    }))
  } catch (e) {
    yield put(updatePaymentProviderStatusComplete())

    yield toast(e.response.data.message, 'error')
  }
}

function * reOrderPaymentProvidersWorker (action) {
  try {
    const { data } = action && action.payload

    yield reOrderPaymentProviders(data)

    yield put(reOrderPaymentProvidersComplete())

    yield toast('Payment Provider ReOrdered Successfully', 'success')
  } catch (e) {
    yield put(reOrderPaymentProvidersComplete())

    yield toast(e.response.data.message, 'error')

    yield put(getPaymentProviderStart({
      isTenant: true,
      paymentType: '',
      aggregator: '',
      tenant: '',
      group: '',
      status: '',
      tenantIds: []
    }))
  }
}

function * getPaymentCategoriesWorker (action) {
  try {
    const { paymentType } = action && action.payload

    const { data } = yield getPaymentCategories({ paymentType })

    yield put(getPaymentCategoriesSuccess(data?.data?.categories))
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(getPaymentCategoriesFailure(e?.response?.data?.message))
  }
}

function * orderPaymentCategoriesWorker (action) {
  try {
    const { data } = action && action.payload

    yield orderPaymentCategories(data)

    yield put(reOrderPaymentCategoriesComplete())

    yield toast('Payment Categories Re-Ordered Successfully', 'success')
  } catch (e) {
    const { data } = action && action.payload

    yield put(reOrderPaymentCategoriesComplete())

    yield toast(e?.response?.data?.message, 'error')
    yield put(getPaymentCategoriesStart({ paymentType: data?.paymentType }))
  }
}
