import * as Yup from 'yup'

export const updateProviderSchema = (
  depositImage,
  withdrawImage,
  supportsDeposit,
  supportsWithdrawal,
  logoCheck,
  keys) => Yup.object().shape({
  depositImage: supportsDeposit && (!depositImage
    ? Yup.mixed().required('A file is required').test('File Size',
      'File Size Should be Less Than 1MB', (value) => !value || (value && value.size <= 1024 * 1024))
      .test('FILE_FORMAT', 'Uploaded file has unsupported format.',
        (value) => !value || (value && ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'].includes(value.type))).nullable()
    : Yup.mixed().test('File Size',
      'File Size Should be Less Than 1MB', (value) => !value || (value && value.size <= 1024 * 1024))
      .test('FILE_FORMAT', 'Uploaded file has unsupported format.',
        (value) => !value || (value && ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'].includes(value.type))).nullable()),
  withdrawImage: (supportsWithdrawal && logoCheck === 'false') && (!withdrawImage
    ? Yup.mixed().required('A file is required').test('File Size',
      'File Size Should be Less Than 1MB', (value) => !value || (value && value.size <= 1024 * 1024))
      .test('FILE_FORMAT', 'Uploaded file has unsupported format.',
        (value) => !value || (value && ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'].includes(value.type))).nullable()
    : Yup.mixed().test('File Size',
      'File Size Should be Less Than 1MB', (value) => !value || (value && value.size <= 1024 * 1024))
      .test('FILE_FORMAT', 'Uploaded file has unsupported format.',
        (value) => !value || (value && ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'].includes(value.type))).nullable()),
  minDeposit: Yup.number().min(0, 'Minimum Deposit must be Greater than or equal to 0.').nullable(),
  maxDeposit: Yup.number().min(1, 'Maximum Deposit must be Greater than or equal to 1.').nullable(),
  minWithdraw: Yup.number().min(0, 'Minimum Withdraw must be Greater than or equal to 0.').nullable(),
  maxWithdraw: Yup.number().min(1, 'Maximum Withdraw must be Greater than or equal to 1.').nullable(),
  amountKeys: amtKeysValidate({ keys })
})

const amtKeysValidate = ({ keys }) => {
  const validationObject = {}
  for (const file in keys) {
    if (keys[file] !== 'Other') {
      validationObject[file] = Yup.number().typeError('Only Numbers Allowed').min(0, 'Minimum Amount 0(Zero) is Required').required('Value Required.')
    }
  }
  return Yup.object(validationObject)
}
