import { Table } from '@themesberg/react-bootstrap'
import React from 'react'
import PaginationComponent from '../../../../components/Pagination'

import './wageringStyle.css'

const WageringListEdit = ({
  casinoGamesData, gameContribution, defaultGames, setGameContribution, customValue,
  page,
  limit,
  setLimit,
  setPage,
  totalPages
}) => {
  const selectHandler = (e, casinoGameId) => {
    if (e.target.checked) {
      const newObj = { ...gameContribution }
      newObj[casinoGameId] = customValue
      setGameContribution(newObj)
    } else {
      const newGameContribution = {}
      for (const key in gameContribution) {
        if (casinoGameId !== Number(key)) {
          newGameContribution[key] = gameContribution[key]
        }
      }
      setGameContribution(newGameContribution)
    }
  }

  const selectAllHandler = (isAll) => {
    if (isAll && casinoGamesData) {
      const newGameContribution = { ...gameContribution }
      for (let i = 0; casinoGamesData.rows?.length > i; i++) {
        newGameContribution[casinoGamesData.rows[i]?.casinoGameId] = customValue
      }
      setGameContribution(newGameContribution)
    } else {
      const newGameContribution = { ...gameContribution }
      for (let i = 0; casinoGamesData.rows?.length > i; i++) {
        delete newGameContribution[casinoGamesData.rows[i]?.casinoGameId]
      }
      setGameContribution(newGameContribution)
    }
  }

  const checkSelected = (parentArray, subsetArray) => {
    const set = new Set(subsetArray)
    return (subsetArray?.length && parentArray?.length)
      ? parentArray.every(x => set.has(String(x?.casinoGameId)))
      : false
  }

  return (
       <div className='wagering-table'>
           {casinoGamesData?.count !== 0 && (
        <PaginationComponent
          page={casinoGamesData?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}
      <Table  striped responsive hover size='sm' className='text-center scrollable'>
        
        <thead className='thead-light'>
          <tr>
            {[
              'Name',
              'RTP',
              'Default',
              'Custom Value'
            ].map((h) => (
              h === 'Name'
                ? (
                  <th key={h}>
                    {casinoGamesData && casinoGamesData.rows?.length > 0 &&
                      <input
                        className='wageering-template-checkbox' type='checkbox'
                        onChange={(e) => selectAllHandler(e.target.checked)}
                        checked={checkSelected(casinoGamesData?.rows, Object.keys(gameContribution))}
                      />}{h}
                  </th>)
                : <th key={h}>{h}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {casinoGamesData && casinoGamesData.count > 0
            ? casinoGamesData.rows.map(({ casinoGameId, name, returnToPlayer, wageringContribution }, idx) => {
              return (
                <tr key={`wagering-template ${casinoGameId}`}>
                  <td>
                    <input
                      className='wageering-template-checkbox'
                      type='checkbox'
                      checked={casinoGameId in gameContribution} name={casinoGameId}
                      onChange={(e) => selectHandler(e, casinoGameId)}
                    />
                    {name}
                  </td>

                  <td>{returnToPlayer} %</td>
                  <td>{wageringContribution} %</td>
                  <td>{gameContribution[casinoGameId] || defaultGames[casinoGameId] || 100} %</td>

                </tr>
              )
            })
            : (
              <tr>
                <td colSpan={7} className='text-danger text-center'>
                  No data found
                </td>
              </tr>
              )}

        </tbody>
      </Table>
    
    </div>
   
  )
}

export default WageringListEdit
