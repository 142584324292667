import React from 'react'
import {
  Form,
  Row,
  Col,
  Button,
  //   Table,
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons'
// import useCheckPermission from '../../../utils/checkPermission'
import useMatchesListing from './useMatchesListing'
import SportsMatchesTable from './SportsMatchesTable'
import { SIZE_CONSTANT } from './constants'
import Trigger from '../../../components/OverlayTrigger'
const SporotsList = () => {
  const {
    sportsMatchesInfo,
    pageNo,
    setPageNo,
    totalPages,
    limit,
    setLimit,
    isGetMatchesLoading,
    searchByName,
    setSearchByName,
    searchByStatus,
    setSearchByStatus,
    isLive,
    setIsLive,
    isFeatured,
    setIsFeatured,
    sportsListInfo,
    setSearchBySports,
    searchBySports
  } = useMatchesListing()
  //   const { isHidden } = useCheckPermission();
  return (
    <>
      <div className='section-header-wrap mt-n3 mx-n3'>
        <Row>
          <Col>
            <h2 className='title-text'>Matches</h2>
          </Col>
        </Row>
      </div>
      <div className='FormsBg'>
        <Row>
          <Col xs='12' sm='2'>
            <Form.Label style={{ marginBottom: '0', marginRight: '15px' }}>
              Sports
            </Form.Label>
            <Form.Select
              onChange={(e) => {
                setSearchBySports(e.target.value)
              }}
              value={searchBySports}
            >
              <option value=''>All</option>
              {sportsListInfo?.sportsList?.rows.map(({ providerSportId, sportName }) => (
                <option value={providerSportId}>{sportName[0]?.name}</option>
              ))}
            </Form.Select>
          </Col>
          <Col xs='12' sm='2'>
            <Form.Label style={{ marginBottom: '0', marginRight: '15px' }}>
              Bet Status
            </Form.Label>
            <Form.Select
              onChange={(e) => { setSearchByStatus(e.target.value) }}
              value={searchByStatus}
            >
              <option value=''>All</option>
              <option value='4'>Cancelled</option>
              <option value='5'>Finished</option>
              <option value='1'>In Progress</option>
              <option value='2'>Not Started</option>
            </Form.Select>
          </Col>
          <Col xs='12' sm='2'>
            <Form.Label style={{ marginBottom: '0', marginRight: '15px' }}>
              Is Live
            </Form.Label>
            <Form.Select
              onChange={(e) => { setIsLive(e.target.value) }}
              value={isLive}
            >
              <option value=''>All</option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </Form.Select>
          </Col>
          <Col xs='12' sm='2'>
            <Form.Label style={{ marginBottom: '0', marginRight: '15px' }}>
              Is featured
            </Form.Label>
            <Form.Select
              onChange={(e) => { setIsFeatured(e.target.value) }}
              value={isFeatured}
            >
              <option value=''>All</option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </Form.Select>
          </Col>
          <Col xs='12' sm='2'>
            <Form.Label style={{ marginBottom: '0', marginRight: '15px' }}>
              Size
            </Form.Label>
            <Form.Select
              onChange={(e) => {
                setLimit(e.target.value);
                setPageNo(1);
              }}
              value={limit}
            >
              <option value=''>All</option>
              {
                SIZE_CONSTANT.map((item, index) => {
                  return (
                    <option value={item}>{item}</option>
                  )
                })
              }
            </Form.Select>
          </Col>
          <Col xs={2} className='d-flex justify-content-end align-items-center'>
            <Trigger message='Reset Filters'>
              <Button
                size='sm'
                onClick={() => {
                  setPageNo(1);
                  setLimit(10);
                  setIsFeatured('');
                  setIsLive('')
                  setSearchByStatus('')
                  setSearchByName('')
                  setSearchBySports('')
                }}
              >
                <FontAwesomeIcon icon={faRedoAlt} />
              </Button>
            </Trigger>
          </Col>
        </Row>
      </div>
      <SportsMatchesTable
        sportsMatchesInfo={sportsMatchesInfo}
        pageNo={pageNo}
        setPageNo={setPageNo}
        totalPages={totalPages}
        limit={limit}
        setLimit={setLimit}
        isGetMatchesLoading={isGetMatchesLoading}
      />

    </>
  )
}

export default SporotsList;
