import styled from 'styled-components'
export const StateBoxContainer = styled.div`
    width: 100%;
    height: 175px;
    background: ${props => props.bgColorVal ? props.bgColorVal : 'white'};
    border-radius: 10px;
    overflow: hidden;
    font-size: 14px;
    font-weight: bold;
    position: relative;
    margin-bottom: 24px;
    .icon-box {
        width: 60px;
        height: 60px;
        background: $primaryColor;
        border-radius: 10px;
    }
    .dashboard-stats-common {
        // align-items: center;
        padding: 20px 20px 20px 20px;
       
    }
    .state-text-wrap {
        text-align: center;
        padding-top: 20px;
    }
    .stats-icon {
        background: #fff;
        width: 40px;
        height: 40px;
        border-radius: 10px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        .material-icons {
            width: 35px;
            height: 35px;
        }
    }
    .state-text-wrap {
        .state-text-head {
            color: white;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 600;
        }
        .state-text-subhead {
            color: white;
            font-weight: 700;
            font-size: 25px;
        }
    }
    .stats-footer-text {
        position: absolute;
        bottom: 6px;
        right: 20px;
        font-weight: 400;
        font-size: 17px;
        line-height: 1.57;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #fff;
    }

`

export const DashboardHeadTitle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .dashboard-spec-text {
        font-weight: 700;
        font-size: 25px;
        color: rgb(34, 51, 84);
        margin-bottom: 0px;
        
    }
`

export const DashboardLiveContainer = styled.div`
    margin: 30px 0;
    padding: 20px;
    background-color: rgb(255, 255, 255);
    color: rgb(34, 51, 84);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 10px;
    box-shadow: rgba(159, 162, 191, 0.18) 0px 9px 16px, rgba(159, 162, 191, 0.32) 0px 2px 2px;
    overflow: hidden;
    position: relative;

    .chart-container {
        height: 300px;
        .chart-container-text {
            background: white;
            border-radius: 10px 10px 0px 0px;
            color: #00133c;
            font-size: 24px;
            padding-top: 12px;
            padding-left: 20px;
            border-bottom: 2px solid #eee;
            padding-bottom: 12px;
        }
    }
`
export const DemoGraphicContainer = styled.div`
    padding: 0;
    .demo-graph-titleleft {
        display: flex;
        align-items: center;
    }
    .demo-graph-titlelefttext {
        color: #00133c;
        font-size: 24px;
        margin-bottom: 0;
    }
    .card-header {        
        background-color: transparent;
    }
`

export const ReportListContainer = styled.div`
    padding-top: 25px;
    padding-left: 25px;
    .nav-light {
        background: transparent;
        border: unset;
        box-shadow: unset !important;
    }
    .custom-container {
        border: 0.0625rem solid #a3a4a7 !important;
        box-shadow: none !important;
        border-radius: 0.3rem !important;
        height: 35px;
        line-height: 1 !important;
        font-size: 0.9rem !important;
        margin-right: 10px;
    }
    .export-summary-btn {
        margin-right: 5px;
    }
`
