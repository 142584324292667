import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  createCurrenciesStart,
  editCurrencyStart
} from '../../../../store/redux-slices/currencies'
import { createSocialLinkStart, editSocialLinkStart } from '../../../../store/redux-slices/socialLinks'

const useCreateSocialLink = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loading } = useSelector((state) => state.currencies)

  const editSocialLink = (data) =>
    dispatch(
      editSocialLinkStart(data)
    )

  const createSocialLink = (data) =>
    dispatch(
      createSocialLinkStart(data)
    )

  return {
    navigate,
    editSocialLink,
    createSocialLink,
    loading
  }
}

export default useCreateSocialLink
