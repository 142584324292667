import React, { useEffect, useState } from 'react'
import {
  Button,
  Form as BForm,
  Row,
  Col,
  Modal,
  Spinner
} from '@themesberg/react-bootstrap'
import { Formik, Form, ErrorMessage } from 'formik'
import { 
    // editCategorySchema, 
    // editproviderSchema, 
    languageSchema, 
    // providerSchema 
} from '../schema'
import Preloader from '../../../../components/Preloader'
// import Trigger from '../../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { InputGroup } from 'react-bootstrap'
const CreateCasinoCategory = ({
  handleClose,
  data,
  show,
  type,
  // aggregators,
  loading,
  createProvider,
  updateProvider,
  language,
  setLangauge,
  languages
}) => {
  const [createName, setCreateName] = useState({ EN: '' })

  console.log('1111', data)

  useEffect(() => {
    if (type === 'Edit' && data) {
      setCreateName(data?.jname)
    } else {
      setCreateName({ EN: '' })
    }
  }, [type, data])
  return (
    <>
      {loading
        ? <Preloader />
        : (
          <Formik
          enableReinitialize
            initialValues={{
              name: createName || '',
              isActive: data?.isActive || false,
            }
             }
            validationSchema={languageSchema(createName)}
            onSubmit={(formValues) => {
              data?.gameCategoryId
                ? updateProvider(formValues, { gameCategoryId: data?.gameCategoryId })
                : createProvider(formValues)

              handleClose()
            }}
            
          >
            {({ values, handleChange, handleSubmit, handleBlur, setFieldValue }) => {
              console.log(JSON.stringify(values))
              return (
              <Form>
                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop='static'
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>{type} Category</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>

                  <Row className='mt-3'>
                        <Col>
                            <BForm.Label>
                              Select Language<span className='text-danger'> *</span>
                            </BForm.Label>
                            <BForm.Select
                              name='language'
                              value={language}
                              onChange={(e) => {
                                setLangauge(e.target.value)
                                const label = Object.assign({ }, values?.name)
                                if (label[e.target.value] === undefined) {
                                  label[e.target.value] = ''
                                }
                                setCreateName(label)
                              }}
                            >
                              <option value=''>Select Language</option>
                              {languages?.count && languages?.rows?.map(({ languageName, code }) => {
                                return !Object.keys(createName)?.includes(code) && (
                                  <option key={code} value={code}>{languageName}</option>
                                )
                              })}
                            </BForm.Select>
                        </Col>
                      </Row>
                      <Row className='mt-3'>
                        <Col>
                          <BForm.Label>
                            Category Name<span className='text-danger'> *</span>
                          </BForm.Label>

                          {Object.keys(values?.name).map((language) => {
                            return (
                              <div className='d-flex align-items-center mt-1' key={language}>
                                <Col>
                                  <Col className='d-flex'>
                                    <InputGroup>
                                      <InputGroup.Text>{language}</InputGroup.Text>
                                      <BForm.Control
                                        type='text'
                                        name={`name[${language}]`}
                                        placeholder='Enter  Name'
                                        value={values.name[language]}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </InputGroup>

                                    <Button
                                      className='btn-danger'
                                      style={{Height: '35px', lineHeight: '14px'}}
                                      disabled={language === 'EN' || data?.name?.[language] !== undefined}
                                      onClick={() => {
                                        const label = Object.assign({ }, values?.name)
                                        delete label[language]
                                        setCreateName(label)
                                        setFieldValue('name', label)
                                        setLangauge('EN')
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                  </Col>

                                  <ErrorMessage
                                    component='div'
                                    name={`name[${language}]`}
                                    className='text-danger'
                                  />
                                </Col>
                              </div>
                            )
                          })}
                        </Col>
                      </Row>

                    {/* <Row className='mt-3'>
                      <Col>
                        <BForm.Label>
                        Category Name<span className='text-danger'> *</span>
                        </BForm.Label>

                        <BForm.Control
                          type='text'
                          name='name'
                          placeholder='Enter Category Name'
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <ErrorMessage
                          component='div'
                          name='name'
                          className='text-danger'
                        />
                      </Col>
                    </Row> */}

                    {/* <Row className='mt-3'>
                      <Col>
                        <BForm.Label>
                          Aggregator<span className='text-danger'> *</span>
                        </BForm.Label>

                        <BForm.Select
                          type='text'
                          name='gameAggregatorId'
                          value={values.gameAggregatorId}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={data?.gameCategoryId}
                        >
                          <option value='' disabled>---Select Aggregator---</option>
                          {aggregators &&
                        aggregators?.rows.map(({ gameAggregatorId, name }) => {
                          return (
                            <option value={gameAggregatorId} key={gameAggregatorId}>
                              {name}
                            </option>
                          )
                        })}
                        </BForm.Select>

                        <ErrorMessage
                          component='div'
                          name='gameAggregatorId'
                          className='text-danger'
                        />
                      </Col>
                    </Row> */}

                    <Row className='mt-3'>
                      <Col sm='2'>
                        <BForm.Label>
                          Status<span className='text-danger'> *</span>
                        </BForm.Label>
                    </Col>
                    <Col sm='3'>
                        <BForm.Check
                          className='mx-auto'
                          type='checkbox'
                          name='isActive'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.isActive}
                          defaultChecked={values.isActive}
                        />

                      </Col>
                    </Row>

                    {/* <Row className='mt-3'>
                      <Col className='d-flex'>
                        <BForm.Label>
                          Demo Mode<span className='text-danger'> *</span>
                        </BForm.Label>

                        <BForm.Check
                          className='mx-auto'
                          type='checkbox'
                          name='demo'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.demo}
                          defaultChecked={values.demo}
                        />
                      </Col>
                    </Row> */}
{/* 
                    <Row className='mt-3'>
                      <Col className='d-grid'>
                        <BForm.Label>Thumbnail</BForm.Label>

                        <BForm.Text>
                          <Trigger message='Only PNG, JPG, JPEG allowed with size < 1MB'>
                            <input
                              id='file'
                              title=' '
                              name='thumbnail'
                              type='file'
                              onChange={(event) => {
                                setFieldValue(
                                  'thumbnail',
                                  event.currentTarget.files[0]
                                )
                              }}
                            />
                          </Trigger>
                          {values?.thumbnail && (
                            <img
                              alt='not found'
                              width='60px'
                              src={URL.createObjectURL(values.thumbnail)}
                            />
                          )}
                          {!values?.thumbnail && data?.thumbnailUrl && (
                            <img
                              alt='not found'
                              width='60px'
                              src={data?.thumbnailUrl}
                            />
                          )}
                        </BForm.Text>

                        <ErrorMessage
                          component='div'
                          name='thumbnail'
                          className='text-danger'
                        />
                      </Col>
                    </Row> */}

                  </Modal.Body>

                  <div className='mt-4 '>
                    <Modal.Footer className='d-flex justify-content-between align-items-center'>
                      <Button className='btn-danger' onClick={() => handleClose()}>
                        Cancel
                      </Button>
                      <Button
                        className='btn-primary'
                        onClick={handleSubmit}
                      >
                        Submit
                        {loading && (
                          <Spinner
                            as='span'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        )}
                      </Button>
                    </Modal.Footer>
                  </div>
                </Modal>
              </Form>
            )}}
          </Formik>)}
    </>
  )
}

export default CreateCasinoCategory
