import { Button, Col, Row, Form } from '@themesberg/react-bootstrap'
import React from 'react'
import { statusType, transactionType } from './constants'
import useTransactionBList from './hooks/useTransactionBList'
import DateRangePicker from '../../../components/DateRangePicker'
import { getDateDaysAgo } from '../../../utils/dateFormatter'
import TransactionsList from '../../../components/TransactionsList'
import ClientFilter from '../../../components/ClientFilter'
import PortalFilter from '../../../components/PortalFilter'
import Trigger from '../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt, faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { toast } from '../../../components/Toast'
import { downloadFile } from '../../../utils/fileDownloader'

export default () => {
  const {
    allCurrencies,
    setSelectedCurrency,
    setSearch,
    setLimit,
    setPage,
    totalPages,
    limit,
    page,
    setSelectedAction,
    selectedCurrency,
    selectedAction,
    search,
    state,
    setState,
    transactions,
    loading,
    selectedClient,
    setSelectedClient,
    selectedPortal,
    setSelectedPortal,
    setSelectedPaymentProvider,
    selectedPaymentProvider,
    getCsvDownloadUrl,
    status,
    setStatus
  } = useTransactionBList({ isUserDetail: false })

  return (
    <>
      <div className='section-header-wrap mt-n3 mx-n3'>
        <Row>
          <Col>
            <h2 className='title-text'>Transações Bancárias</h2>
          </Col>
        </Row>
      </div>
      <div className='FormsBg'>
        <Row>
          <Col xs={"auto"} md={3}>
            {/* <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            Currency
          </Form.Label> */}

            <Form.Select
              size='sm'
              style={{ maxWidth: '230px' }}
              onChange={(e) => setSelectedCurrency(e.target.value)}
              value={selectedCurrency}
            >
              <option value=''>Selecione uma moeda</option>
              {allCurrencies && allCurrencies?.rows?.map(
                ({ name: currName, currencyId, code }) => (
                  <option key={currencyId} value={code}>
                    {currName}
                  </option>
                )
              )}
            </Form.Select>
          </Col>

          <Col xs={"auto"} md={3}>
            <Form.Select
              size='sm'
              style={{ maxWidth: '230px' }}
              onChange={(e) => setSelectedAction(e.target.value)}
              value={selectedAction}
            >
              <option value=''> Escolha o tipo</option>
              {transactionType && transactionType?.map(
                ({ label, value }) => (
                  <option key={label} value={value}>
                    {label}
                  </option>
                )
              )}
            </Form.Select>
          </Col>

          <Col xs={"auto"} md={3}>
            <Form.Select
              size='sm'
              style={{ maxWidth: '230px' }}
              value={status}
              onChange={e =>
                setStatus(e.target.value.replace(/[~`!$%^&*#=)()><?]+/g, ''))}
            >
              {statusType.map(({ label, value }) => {
                return <option key={label} value={value}>{label}</option>
              })}
            </Form.Select>
          </Col>

          <Col xs={"auto"} md={3}>
            <div className='d-flex justify-content-end '>
              <Trigger message='Reset Filters'>
                <Button
                  className=''
                  size='sm'
                  onClick={() => {
                    setSearch('')
                    setSelectedAction('')
                    setSelectedCurrency('')
                    setLimit(10)
                    setPage(1)
                    setSelectedPaymentProvider('')
                    setSelectedClient('')
                    setStatus('')
                    setState([
                      {
                        startDate: getDateDaysAgo(10),
                        endDate: new Date(),
                        key: 'selection'
                      }
                    ])
                  }}
                >
                  <FontAwesomeIcon icon={faRedoAlt} />
                </Button>
              </Trigger>
              <Trigger message='Download as CSV'>
                <Button
                  className='btn-primary'
                  size='sm'
                  disabled={transactions?.count === 0}
                  style={{ marginLeft: '10px' }}
                  onClick={() => {
                    downloadFile(getCsvDownloadUrl())
                  }}
                >
                  <FontAwesomeIcon icon={faFileDownload} />
                </Button>
              </Trigger>
            </div>
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col xs={"auto"} md={3} >
            <DateRangePicker
              state={state} setState={setState}
              size='sm'
            />
          </Col>
        </Row>
      </div>
      <TransactionsList
        page={page}
        setLimit={setLimit}
        limit={limit}
        setPage={setPage}
        totalPages={totalPages}
        transactions={transactions}
        isTenant={false}
        loading={loading}
      />
    </>
  )
}
