export const transformGameSubCategoryData = (responseData) => {
  const { data: { casinoSubCategory } } = responseData
  const transformedData = {
    data: {
      casinoSubCategory: {
        count: casinoSubCategory.count,
        rows: []
      }
    }
  }

  for (let index = 0; index < casinoSubCategory.rows.length; index++) {
    const category = casinoSubCategory.rows[index]
    let jname
    try {
      jname = JSON.parse(category.name)
    } catch (error) {
      jname = { EN: category.name }
    }
    transformedData.data.casinoSubCategory.rows.push({ ...category, jname })
  }

  return transformedData
}