import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormatNumber from "../../../../utils/formatNumber";
import { DashboardLiveContainer } from "../../../Affiliates/Dashboard/style";
import Trigger from "../../../../components/OverlayTrigger";
import {
  CardInfo,
  InfoLabel,
  MainCard,
  StyledCard,
  InfoValue,
} from "../../../Affiliates/Dashboard/components/AffiliateStats/style";
import { MONTHS } from '../../../../utils/constant';
import { AffiliateDashboard } from '../../Dashboard/hooks/useDashboard';

const ggrClassName = (ggr) => ggr < 0 ? "text-danger" : ggr == 0 ? "" : "text-success"

/**
 * @type {(props: { reportType: 'casino' | 'sports' | 'lives' | 'subAffiliateComissions' | 'subAffiliateLiveComissions' , getSubCategory: () => void}) => void}
 */
const TotalReports = ({ getSubCategory, reportType }) => {
  const dispatch = useDispatch()
  /**@type { AffiliateDashboard } */
  const affiliateDashoboard = useSelector((state) => state.affiliate.affiliateDashboard);
  const [liveCommission, setLiveCommission] = useState({});

  const gameSubCategories = useSelector((state) => state.superAdminCasino?.gameSubCategory?.rows) || []
  const [selectedSubCategory, setSelectedSubCategory] = useState()

  const reportValue = (affiliateDashoboard, liveCommission) => ({
    casino: [
      {
        info: [
          { label: 'Apostas', value: FormatNumber.currency(affiliateDashoboard?.totalreports?.gameReport?.betAmount || 0), tooltip: 'Apostas dos usuários cadastrado com seu link de afiliado' },
          { label: 'Ganhos', value: FormatNumber.currency(affiliateDashoboard?.totalreports?.gameReport?.winAmount || 0), tooltip: 'Ganhos dos usuários cadastrado com seu link de afiliado' },
        ]
      },
      {
        info: [
          { label: 'GGR', value: FormatNumber.currency(affiliateDashoboard?.totalreports?.gameReport?.ggr || 0), className: ggrClassName(affiliateDashoboard?.totalreports?.gameReport?.ggr || 0) },
          { label: 'NRG', value: FormatNumber.currency(affiliateDashoboard?.totalreports?.gameReport?.ngr || 0), className: ggrClassName(affiliateDashoboard?.totalreports?.gameReport?.ngr || 0) },
          { label: 'Comissão', value: FormatNumber.currency(affiliateDashoboard?.totalreports?.gameReport?.commission || 0), className: ggrClassName(affiliateDashoboard?.totalreports?.gameReport?.commission || 0) }
        ]
      },
    ],
    sports: [
      {
        info: [
          { label: 'Apostas', value: FormatNumber.currency(affiliateDashoboard?.sportsReport?.gameReport?.betAmount || 0), tooltip: 'Apostas dos usuários cadastrado com seu link de afiliado' },
          { label: 'Ganhos', value: FormatNumber.currency(affiliateDashoboard?.sportsReport?.gameReport?.winAmount || 0), tooltip: 'Ganhos dos usuários cadastrado com seu link de afiliado' },
        ]
      },
      {
        info: [
          { label: 'GGR', value: FormatNumber.currency(affiliateDashoboard?.sportsReport?.gameReport?.ggr || 0), className: ggrClassName(affiliateDashoboard?.sportsReport?.gameReport?.ggr || 0) },
          { label: 'NGR', value: FormatNumber.currency(affiliateDashoboard?.sportsReport?.gameReport?.ngr || 0), className: ggrClassName(affiliateDashoboard?.sportsReport?.gameReport?.ngr || 0) },
          { label: 'Comissão', value: FormatNumber.currency(affiliateDashoboard?.sportsReport?.gameReport?.commission || 0), className: ggrClassName(affiliateDashoboard?.sportsReport?.gameReport?.commission || 0) }
        ]
      }
    ],
    lives: [
      {
        info: [
          { label: 'Apostas', value: FormatNumber.currency(liveCommission?.betAmount || 0), tooltip: 'Apostas dos usuários que jogaram na sua live' },
          { label: 'Ganhos', value: FormatNumber.currency(liveCommission?.winAmount || 0), tooltip: 'Ganhos dos usuários que jogaram na sua live' },
        ]
      },
      {
        info: [
          { label: 'GGR', value: FormatNumber.currency(liveCommission?.ggr || 0), className: ggrClassName(liveCommission.ggr || 0) },
          { label: 'NGR', value: FormatNumber.currency(liveCommission?.ngr || 0), className: ggrClassName(liveCommission.ngr || 0) },
          { label: 'Comissão', value: FormatNumber.currency(liveCommission?.commission || 0), className: ggrClassName(liveCommission.commission || 0) }
        ]
      }
    ],
    subAffiliateComissions: [
      {
        info: [
          { label: 'Apostas', value: FormatNumber.currency(affiliateDashoboard?.subAffiliateCommission?.gameReport?.betAmount || 0), tooltip: 'Apostas dos usuários cadastrado no link dos seus sub-afiliados' },
          { label: 'Ganhos', value: FormatNumber.currency(affiliateDashoboard?.subAffiliateCommission?.gameReport?.winAmount || 0), tooltip: 'Ganhos dos usuários cadastrado no link dos seus sub-afiliados' },
        ]
      },
      {
        info: [
          { label: 'GGR', value: FormatNumber.currency(affiliateDashoboard?.subAffiliateCommission?.gameReport?.ggr || 0), className: ggrClassName(affiliateDashoboard?.subAffiliateCommission?.gameReport?.ggr || 0) },
          { label: 'NRG', value: FormatNumber.currency(affiliateDashoboard?.subAffiliateCommission?.gameReport?.ngr || 0), className: ggrClassName(affiliateDashoboard?.subAffiliateCommission?.gameReport?.ngr || 0) },
          { label: 'Comissão', value: FormatNumber.currency(affiliateDashoboard?.subAffiliateCommission?.gameReport?.commission || 0), className: ggrClassName(affiliateDashoboard?.subAffiliateCommission?.gameReport?.commission || 0) }
        ]
      },
    ],
    subAffiliateLiveComissions: [
      {
        info: [
          { label: 'Apostas', value: FormatNumber.currency(affiliateDashoboard?.subAffiliateLiveCommission?.gameReport?.betAmount || 0), tooltip: 'Apostas dos usuários que jogaram na live dos seus sub-afiliados' },
          { label: 'Ganhos', value: FormatNumber.currency(affiliateDashoboard?.subAffiliateLiveCommission?.gameReport?.winAmount || 0), tooltip: 'Ganhos dos usuários que jogaram na live dos seus sub-afiliados' },
        ]
      },
      {
        info: [
          { label: 'GGR', value: FormatNumber.currency(affiliateDashoboard?.subAffiliateLiveCommission?.gameReport?.ggr || 0), className: ggrClassName(affiliateDashoboard?.subAffiliateLiveCommission?.gameReport?.ggr || 0) },
          { label: 'NRG', value: FormatNumber.currency(affiliateDashoboard?.subAffiliateLiveCommission?.gameReport?.ngr || 0), className: ggrClassName(affiliateDashoboard?.subAffiliateLiveCommission?.gameReport?.ngr || 0) },
          { label: 'Comissão', value: FormatNumber.currency(affiliateDashoboard?.subAffiliateLiveCommission?.gameReport?.commission || 0), className: ggrClassName(affiliateDashoboard?.subAffiliateLiveCommission?.gameReport?.commission || 0) }
        ]
      },
    ]
  });

  const reportLabel = {
    casino: 'Comissões RevShare',
    sports: 'Total de Esportes',
    lives: 'Comissão de Lives',
    subAffiliateComissions: 'Comissão de Sub-afiliados',
    subAffiliateLiveComissions: 'Comissão de Lives de Sub-afiliados',
  }

  const getLiveCommissionByMonth = (value) => {
    let data;
    if (value == "last-month") {
      data = (affiliateDashoboard?.liveComission?.gameReportsByMonthLive[0]?.gameReport || {});
    } else {
      data = affiliateDashoboard?.liveComission?.gameReportsByMonthLive.find(e => e.month == value)?.gameReport
    }
    setLiveCommission(data)
  }

  const [dataToBeMap, setDataToBeMap] = useState([])

  useEffect(() => {
    getLiveCommissionByMonth("last-month")
  }, [affiliateDashoboard])

  useEffect(() => {
    setDataToBeMap(reportValue(affiliateDashoboard, liveCommission)[reportType])
  }, [affiliateDashoboard, liveCommission])

  return (
    <DashboardLiveContainer className="p-0">
      <div className="p-3 pb-0 d-flex align-items-center justify-content-between">
        <h5 className='m-0 text-muted fw-bold dashboard-spec-text'>{reportLabel[reportType]}</h5>
        {reportType === 'casino' &&
          <div className='custom-select-wrap'>
            <select className="form-select" aria-label="Default select example" onChange={(e) => { getSubCategory(e.target.value) }}>
              <option selected value={'all'}>Todos</option>
              {gameSubCategories.map((subCategory, index) => <option key={index} value={subCategory.gameSubCategoryId}>{subCategory?.jname?.EN || 'N/A'}</option>)}
            </select>
          </div>
        }
        {reportType === 'lives' &&
          <div className='custom-select-wrap'>
            <select className="form-select" aria-label="Default select example" onChange={(e) => { getLiveCommissionByMonth(e.target.value) }}>
              <option selected value={'last-month'}>Último mês</option>
              {(affiliateDashoboard?.liveComission?.gameReportsByMonthLive ?? []).map(({ month }, index) => <option key={index} value={month}>{MONTHS[month] || 'N/A'}</option>)}
            </select>
          </div>
        }
      </div>
      <MainCard>
        {dataToBeMap.map((card, index) => (
          <StyledCard key={index} last={index === dataToBeMap.length - 1}>
            {card.info.map((item, idx) => (
              (item.label) ? <CardInfo key={idx}>
                <Trigger message={item.tooltip} placement='top'><InfoLabel>{item.label}</InfoLabel></Trigger>
                <InfoValue className={item.className}>{item.value}</InfoValue>
              </CardInfo> : null
            ))}
          </StyledCard>
        ))}
      </MainCard>
    </DashboardLiveContainer>
  )
}

export default TotalReports

