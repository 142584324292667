import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { createLoyaltyRuleStart, editLoyaltyRuleStart, getLoyaltyRulesState } from '../../../../store/redux-slices/loyaltyRules'

const useCreateLoyaltyRule = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loading } = useSelector(getLoyaltyRulesState)

  const editLoyaltyRule = (data) => {
    const { loyaltyPoint, ...rest } = data
    const payload = { ...rest }
    if (loyaltyPoint) payload.loyaltyPoint = loyaltyPoint

    dispatch(editLoyaltyRuleStart(payload))
  }
  const createLoyaltyRule = (data) => {
    const { loyaltyPoint, ...rest } = data
    const payload = { ...rest }
    if (loyaltyPoint) payload.loyaltyPoint = loyaltyPoint
    dispatch(
      createLoyaltyRuleStart(payload)
    )
  }


  return {
    navigate,
    editLoyaltyRule,
    createLoyaltyRule,
    loading
  }
}

export default useCreateLoyaltyRule
