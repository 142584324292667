import React, { useState } from 'react'
import { Row, Col, Button, Tabs, Tab, Form } from '@themesberg/react-bootstrap'
import { SpinnerLoader } from '../../../../../components/Preloader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedo } from '@fortawesome/free-solid-svg-icons'
import DateRangePicker, { DateRangePickerWithoutInput } from '../../../../../components/DateRangePicker'
import useKPISummary from '../../hooks/useKPISummary'
import { downloadFile } from '../../../../../utils/fileDownloader'
import { KPISummaryTable } from '../../../../../components/KPISummaryTable'
import { ReportListContainer } from '../../style'

export default ({ selectedPortal, selectedClient }) => {
  const {
    loadKPISummary,
    loading,
    setState,
    state,
    getCsvDownloadUrl,
    KPISummary,
    summaryOption,
    setSummaryOption,
    selectedTab,
    setSelectedTab
  } = useKPISummary({ selectedPortal, selectedClient })

  const kpiSummeryOptions = [
    { name: 'Relatório do dia', value: 'todayReport' },
    { name: 'Relatório do dia anterior', value: 'yesterdayReport' },
    { name: 'Relatório dos últimos 30 dias', value: 'lastMonthToTodayReport' },
    { name: 'Relatório do mês anterior', value: 'lastMonthReport' },
    { name: 'Relatório Customizado', value: 'customDateReport' }
  ]

  return (
    <ReportListContainer>
      <Row>
        <Col lg={6} xs={12}>
          <h3 className='d-flex'>Números quantitativos {loading ? <SpinnerLoader /> : <FontAwesomeIcon onClick={() => loadKPISummary()} style={{ fontSize: '15px', color: 'gray', cursor: 'pointer', marginTop: '5px', marginLeft: '10px' }} icon={faRedo} />}</h3>
        </Col>
        <Col lg={6} xs={12}>
          <div className='d-flex justify-content-end align-items-center'>
            <Form.Select
              onChange={(e) => {
                setSummaryOption(e.target.value)
              }}
              value={summaryOption}
              size='xs'
            >
              {kpiSummeryOptions.map((item) =>
              (
                <option key={item.value} value={item.value} style={{ textTransform: 'capitalize' }}>
                  {item.name}
                </option>
              ))}
            </Form.Select>
            <DateRangePicker
              disabled={summaryOption !== 'customDateReport'}
              state={state} setState={setState}
            />

            <Button
              variant='outline-success'
              size='sm'
              className='export-summary-btn mw-fit'
              style={{ width: '150px' }}
              disabled={KPISummary?.length < 1}
              onClick={() => { downloadFile(getCsvDownloadUrl()) }}
            >
              Exportar relatório
            </Button>
          </div>
        </Col>
      </Row>

      <Tabs
        className='nav-light dashboard'
        activeKey={selectedTab}
        onSelect={(tab) => setSelectedTab(tab)}
      >
        {/* <Tab eventKey='Banking' title='Banking'>
          <div className='mt-2'>
            <Row className='mt-3 d-flex flex-row-reverse text-right'>
              <KPISummaryTable KPISummary={KPISummary?.banking?.[summaryOption] || []} type='banking' />
            </Row>
          </div>
        </Tab>

        <Tab eventKey='SPORTS' title='Sports'>
          <div className='mt-2'>
            <Row className='mt-3 d-flex flex-row-reverse text-right'>
              <KPISummaryTable KPISummary={KPISummary?.sports?.[summaryOption] || []} type='sports' />
            </Row>
          </div>
        </Tab> */}

        <Tab eventKey='CASINO' title='Casino'>
          <div className='mt-2'>
            <Row className='mt-3 d-flex flex-row-reverse text-right'>
              <KPISummaryTable KPISummary={KPISummary?.casino?.[summaryOption] || []} type='casino' />
            </Row>
          </div>
        </Tab>
      </Tabs>

      {/* <KPISummaryTable KPISummary={KPISummary} /> */}
    </ReportListContainer>

  )
}
