import React from 'react';
import TocRoundedIcon from '@mui/icons-material/TocRounded';
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded';
import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import AccessibilityTwoToneIcon from '@mui/icons-material/AccessibilityTwoTone';
import PaletteTwoToneIcon from '@mui/icons-material/PaletteTwoTone';
import ReviewsTwoToneIcon from '@mui/icons-material/ReviewsTwoTone';
import FactCheckTwoToneIcon from '@mui/icons-material/FactCheckTwoTone';
import ReceiptLongTwoToneIcon from '@mui/icons-material/ReceiptLongTwoTone';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';
import SportsSoccerTwoToneIcon from '@mui/icons-material/SportsSoccerTwoTone';
import CommentTwoToneIcon from '@mui/icons-material/CommentTwoTone';
import CollectionsTwoToneIcon from '@mui/icons-material/CollectionsTwoTone';
import AccountBalanceWalletTwoToneIcon from '@mui/icons-material/AccountBalanceWalletTwoTone';
import ContactMailTwoToneIcon from '@mui/icons-material/ContactMailTwoTone';
import GTranslateTwoToneIcon from '@mui/icons-material/GTranslateTwoTone';
import ManageAccountsTwoToneIcon from '@mui/icons-material/ManageAccountsTwoTone';
import PlaceTwoToneIcon from '@mui/icons-material/PlaceTwoTone';
import ViewCarouselTwoToneIcon from '@mui/icons-material/ViewCarouselTwoTone';
import CasinoTwoToneIcon from '@mui/icons-material/CasinoTwoTone';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import LiveTvTwoToneIcon from '@mui/icons-material/LiveTvTwoTone';
import SynagogueTwoToneIcon from '@mui/icons-material/SynagogueTwoTone';
import LocalAtmTwoToneIcon from '@mui/icons-material/LocalAtmTwoTone';
import CardMembershipTwoToneIcon from '@mui/icons-material/CardMembershipTwoTone';
import SpeakerNotesTwoToneIcon from '@mui/icons-material/SpeakerNotesTwoTone';
import LocationOffTwoToneIcon from '@mui/icons-material/LocationOffTwoTone';
import QrCode2TwoToneIcon from '@mui/icons-material/QrCode2TwoTone';
import PsychologyTwoToneIcon from '@mui/icons-material/PsychologyTwoTone';
import ManageHistoryTwoToneIcon from '@mui/icons-material/ManageHistoryTwoTone';

export const profileConstants = [
  { key: 'First Name', value: 'firstName', edit: true },
  { key: 'Last Name', value: 'lastName', edit: true },
  { key: 'Email', value: 'email' },
  { key: 'User Name', value: 'adminUsername' },
  { key: 'Role', value: 'AdminRole', subValue: 'name' },
  { key: 'Group', value: 'group' }
]

export const siteConfigConstants = [
  { key: 'Site  Name', value: 'name', edit: true },
  { key: 'Site  Url', value: 'url', edit: true },
  { key: 'Support Email Address', value: 'supportEmail', edit: true },
  { key: 'Send grid Email', value: 'sendgridEmail', edit: true },
  { key: 'Send grid Api Key', value: 'sendgridKey', edit: true },

  // { key: 'Minimum Reedemable Coins', value: 'languages',edit: true  },
  // { key: 'Site Logo', value: 'logo',edit: true  }
]

export const profileConstantsTA = [
  { key: 'First Name', value: 'firstName', edit: true },
  { key: 'Last Name', value: 'lastName', edit: true },
  { key: 'Email', value: 'email' },
  { key: 'Phone', value: 'phone', edit: true },
  { key: 'Role', value: 'AdminRole', subValue: 'name' },
  { key: 'Username', value: 'agentName' },
  { key: 'Group', value: 'group' }
]

export const permissionLabel = (label) => {
  switch (label) {
    case 'C':
      return 'Create'
    case 'R':
      return 'Read'
    case 'U':
      return 'Update'
    case 'D':
      return 'Delete'
    case 'T':
      return 'Toggle Status'
    case 'A':
      return 'Apply'
    case 'CC':
      return 'Create Custom'
    case 'AB':
      return 'Manage Money'
    case 'SR':
      return 'Limit'
    case 'TE':
      return 'Test Email'
    case 'EV':
      return 'Verify Email'
    case 'UP':
      return 'Reset Password'
    default:
      return label
  }
}
export const permissionIcons = () => {
  return (
    {
      CMS: <TocRoundedIcon />,
      Bonus: <CurrencyExchangeRoundedIcon />,
      Users: <PeopleOutlineRoundedIcon />,
      Admins: <AdminPanelSettingsRoundedIcon />,
      Tenant: <AccessibilityTwoToneIcon />,
      Themes: <PaletteTwoToneIcon />,
      Reviews: <ReviewsTwoToneIcon />,
      KycLabel: <FactCheckTwoToneIcon />,
      KpiReport: <ReceiptLongTwoToneIcon />,
      Currencies: <MonetizationOnTwoToneIcon />,
      GameReport: <SportsSoccerTwoToneIcon />,
      UserComment: <CommentTwoToneIcon />,
      ImageGallery: <CollectionsTwoToneIcon />,
      Transactions: <AccountBalanceWalletTwoToneIcon />,
      EmailTemplate: <ContactMailTwoToneIcon />,
      MultiLanguage: <GTranslateTwoToneIcon />,
      TenantSettings: <ManageAccountsTwoToneIcon />,
      DemographReport: <PlaceTwoToneIcon />,
      BannerManagement: <ViewCarouselTwoToneIcon />,
      CasinoManagement: <CasinoTwoToneIcon />,
      KpiSummaryReport: <AssessmentTwoToneIcon />,
      LivePlayerReport: <LiveTvTwoToneIcon />,
      WageringTemplate: <SynagogueTwoToneIcon />,
      CashierManagement: <LocalAtmTwoToneIcon />,
      LoyaltyManagement: <CardMembershipTwoToneIcon />,
      RegistrationField: <SpeakerNotesTwoToneIcon />,
      RestrictedCountry: <LocationOffTwoToneIcon />,
      TenantCredentials: <QrCode2TwoToneIcon />,
      TenantConfigurations: <PsychologyTwoToneIcon />,
      PlayerLiabilityReport: <AssessmentTwoToneIcon />,
      PlayerManagementReport: <ManageHistoryTwoToneIcon />,      
    }
  )
}