import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getCMSLanguagesStart, getLanguageSupportKeysStart, uploadLanguageCSVStart } from '../../../store/redux-slices/languages'
import { getItem, getLoginToken } from '../../../utils/storageUtils'
import { useDidMountEffect } from '../../../utils/useDidMountEffect'
import { keysNotToBeShown } from './constant'

const useLanguageMgmt = ({ isTenant }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const tenantId = getItem('tenant-id')
  const { languageKeys, tenantLanguages } = useSelector(state => state.languages)
  const { tenantDetails } = useSelector(state => state.login)
  const [myKeyData, setMyKeyData] = useState({})
  const [selectedLang, setSelectedLang] = useState('')
  const [uploadModal, setUploadModal] = useState(false)
  const [search, setSearch] = useState('')
  const [keySearch, setKeySearch] = useState('')
  const [availableLanguages, setAvailableLanguages] = useState([])
  const [myLanguages, setMyLanguages] = useState([])
  const isInitialRender = useDidMountEffect()
  const [myKeys, setMyKeys] = useState({})

  useEffect(() => {
    !isTenant && dispatch(getCMSLanguagesStart({ adminId: '', tenantId: '' }))
    dispatch(getLanguageSupportKeysStart({ isTenant, tenantId: isTenant ? tenantId : '', language: '' }))
  }, [])

  useEffect(() => {
    if (languageKeys && languageKeys?.length) {
      const set = new Set()
      const data = {}
      const data2 = {}
      for (const key in languageKeys[0]) {
        if (!keysNotToBeShown.includes(key)) data2[key] = ''
        const keyData = {}
        for (const i in languageKeys) {
          keyData[languageKeys[i].language] = languageKeys[i][key]
          set.add(languageKeys[i].language)
        }
        data[key] = keyData
      }
      setMyKeyData(data)
      setMyKeys(data2)
      setMyLanguages(set)
    }
  }, [languageKeys])

  const updateKeysForSearch = () => {
    if (languageKeys && languageKeys?.length) {
      const set = new Set()
      const data = {}
      for (const key in languageKeys[0]) {
        const keyData = {}
        if (key.includes(keySearch)) {
          for (const i in languageKeys) {
            if (String(languageKeys[i][key]).toLowerCase()?.includes(search.toLowerCase())) {
              keyData[languageKeys[i].language] = languageKeys[i][key]
              set.add(languageKeys[i].language)
            }
          }
        }
        let count = 0
        for (const i in keyData) {
          if (keyData[i] !== '') {
            count++
          }
        }
        if (count) {
          data[key] = keyData
        }
      }
      for (const key in data) {
        const currData = data[key]
        data[key] = {}
        for (const lang of set) {
          data[key][lang] = currData[lang] || ''
        }
      }
      setMyKeyData(data)
      setMyLanguages(set)
    }
  }

  const setData = () => {
    const lang = []
    if (isTenant && tenantDetails?.tenantConfig?.allowedLanguages) {
      tenantDetails.tenantConfig.allowedLanguages.forEach(code => {
        if (!languageKeys?.find(({ language }) => code === language)) lang.push(code)
      })
    } else if (tenantLanguages?.length) {
      tenantLanguages.forEach(code => {
        if (!languageKeys?.find(({ language }) => code === language)) lang.push(code)
      })
    }
    setAvailableLanguages(lang)
  }

  useEffect(() => setData(), [tenantDetails, tenantLanguages, languageKeys])

  const keyOptions = [{ label: 'All', value: '' }]
  myKeys &&
    Object.keys(myKeys).map(element =>
      !keysNotToBeShown.includes(element) && keyOptions.push({ label: element, value: element })
    )

  useEffect(() => {
    if (!isInitialRender) {
      const delayDebounceFn = setTimeout(() => {
        updateKeysForSearch()
      }, 1000)

      return () => clearTimeout(delayDebounceFn)
    }
  }, [search, keySearch])

  const handleDownload = () =>
    `${process.env.REACT_APP_API_URL}/api/admin/language/support-keys?csvDownload=true&token=${getLoginToken()}`

  const handleUpload = (data) =>
    dispatch(uploadLanguageCSVStart({ isTenant, data, language: selectedLang, tenantId: isTenant ? tenantId : '' }))

  return {
    languageKeys,
    myKeyData,
    navigate,
    selectedLang,
    setSelectedLang,
    handleDownload,
    handleUpload,
    uploadModal,
    setUploadModal,
    availableLanguages,
    tenantLanguages: isTenant ? tenantDetails?.tenantConfig?.allowedLanguages : tenantLanguages,
    search,
    setSearch,
    keyOptions,
    myKeys,
    setKeySearch,
    myLanguages
  }
}

export default useLanguageMgmt
