import { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAllCasinoGamesStart, getSuperAdminGameCategoryStart, getSuperAdminGameSubCategoryStart, updateReorderGamesStart } from '../../../../store/redux-slices/superAdminCasinoManagement'
import { getAllCasinoCategoriesStart, getAllGamesStart, getAllSubCategoriesStart } from '../../../../store/redux-slices/tenantCasino'
import { getItem } from '../../../../utils/storageUtils'

const useGameReorder = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { loading, casinoGamesData:games, gameSubCategory:subCategories,gameCategory:casinoCategories, casinoProvidersData } = useSelector(
    (state) => state.superAdminCasino
  )
  // const {  games, casinoCategories, subCategories } = useSelector((state) => state.tenantCasino)
  const [reOrderedGame, setReorderedGame] = useState({ rows: [], count: 0 })
  const [casinoGames, setCasinoGames] = useState({ rows: [], count: 0 })
  const [categoryFilter, setCategoryFilter] = useState('')
  const [casinoCategoryId, setCasinoCategoryId] = useState(null)
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const totalPages = Math.ceil(games?.count / limit)

  useEffect(() => {
    if (games && games.length) {
      if (reOrderedGame?.rows) {
        const filteredArray = games?.filter((item) => {
          const exist = reOrderedGame?.rows.find(common => common.categoryGameId === item.categoryGameId)
          if (exist) {
            return false
          } else {
            return true
          }
        })
        setCasinoGames({ rows: filteredArray, count: games?.length })
      } else {
        setCasinoGames({ rows: games, count: games?.length })
      }
    }
  }, [games])

  useEffect(() => {
    dispatch(getSuperAdminGameCategoryStart({ limit, pageNo: page, search, tenantId: '' }))
  }, [])

  const reorder = (reorderItem, startIndex, endIndex) => {
    const result = Array.from(reorderItem)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const rows = reorder(
      reOrderedGame.rows,
      result.source.index,
      result.destination.index
    )
    setReorderedGame({ rows, count: rows.length })
  }

  const handleAddGame = (item) => {
    setReorderedGame((oldItem) => {
      const newArray = [...oldItem.rows, item]
      return { rows: newArray, count: newArray.length }
    })
    setCasinoGames((oldItem) => {
      const newArray = oldItem?.rows.filter((gameItem) => gameItem.casinoGameId !== item.casinoGameId)
      return { rows: newArray, count: newArray.length }
    })
  }

  const handRemoveGame = (item) => {
    setCasinoGames((oldItem) => {
      const newArray = [...oldItem.rows, item]
      return { rows: newArray, count: newArray.length }
    })
    setReorderedGame((oldItem) => {
      const newArray = oldItem?.rows.filter((gameItem) => gameItem.casinoGameId !== item.casinoGameId)
      return { rows: newArray, count: newArray.length }
    })
  }

  const handleSave = async () => {
    const orderedGames = []
    const unOrderedGames = []
    reOrderedGame && reOrderedGame.rows.map((list) => orderedGames.push(list.casinoGameId))
    casinoGames && casinoGames.rows.map((list) => unOrderedGames.push(list.casinoGameId))

    const data = {
      order: [...orderedGames, ...unOrderedGames],
      gameSubCategoryId: Number(casinoCategoryId),
    }
    await dispatch(updateReorderGamesStart({ data, casinoCategoryId }))
    setReorderedGame({ rows: [], count: 0 })
    dispatch(
      getAllCasinoGamesStart({
           limit,
            pageNo: '',
            casinoCategoryId,
            search:"",
            isActive: '',
            tenantId: '',
            selectedProvider:"",
            reorder:'true'
      })
    )
  }

  useEffect(() => {
    if (casinoCategoryId) {
      dispatch(
        getAllCasinoGamesStart({
             limit,
              pageNo: '',
              casinoCategoryId,
              search:"",
              isActive: '',
              tenantId: '',
              selectedProvider:"",
              reorder:'true'
        })
      )
    }
  }, [casinoCategoryId])

  useEffect(() => {
    if (categoryFilter) {
      dispatch(getSuperAdminGameSubCategoryStart({
        limit: '',
        pageNo: '',
        gameCategoryId: categoryFilter,
        search: '',
        isActive: '',
        orderBy: '',
        sort: '',
        tenantId: ''
      }))
    }
  }, [categoryFilter])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (categoryFilter) {
        dispatch(
          getAllCasinoGamesStart({
            limit: '',
            pageNo: '',
            casinoCategoryId,
            isActive: '',
            orderBy: '',
            sort: '',
            search,
            tenantId: '',
            selectedProvider:"",
            reorder:'true'
          })
        )
        setPage(1)
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  return {
    loading,
    reOrderedGame,
    onDragEnd,
    handleSave,
    navigate,
    casinoGames,
    handRemoveGame,
    handleAddGame,
    casinoCategories,
    categoryFilter,
    setCategoryFilter,
    totalPages,
    setLimit,
    setPage,
    limit,
    page,
    subCategories,
    casinoCategoryId,
    setCasinoCategoryId,
    setReorderedGame,
    search,
    setSearch
  }
}

export default useGameReorder
