import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Form as BForm,
  Button,
  InputGroup
} from '@themesberg/react-bootstrap'
import { ErrorMessage } from 'formik'
import WageringTemplateDetails from '../../../../../components/WageringTemplateDetails'
import { getWageringTemplateDetailStart } from '../../../../../store/redux-slices/wageringTemplate'
import useDidMountEffect from '../../../../../utils/useDidMountEffect'
import { useSelector } from 'react-redux'
import { InlineLoader } from '../../../../../components/Preloader'

const WageringContributionForm = ({
  touched,
  errors,
  values,
  handleChange,
  handleSubmit,
  handleBlur,
  setFieldValue,
  setSelectedTab,
  dispatch,
  wageringTemplateList,
  wageringTemplateDetail,
  isTenant,
  limit,
  setLimit,
  pageNo,
  setPageNo,
  totalPages
}) => {
  const { loading } = useSelector(state => state.wageringTemplate)
  const [search, setSearch] = useState('')
  const isInitialRender = useDidMountEffect()
  const fetchData = () => {
    dispatch(
      getWageringTemplateDetailStart({
        wageringTemplateId: values?.wageringTemplateId,
        isTenant,
        limit,
        pageNo,
        providerId: '',
        search
      }))
  }
  useEffect(() => {
    !values?.wageringTemplateId && setFieldValue('wageringTemplateId', wageringTemplateList?.[0]?.wageringTemplateId)
    if (values?.wageringTemplateId !== '' && values?.wageringTemplateId) {
      setPageNo(1)
      fetchData()
    }
  }, [values?.wageringTemplateId, limit])

  useEffect(() => {
    !isInitialRender && fetchData()
  }, [pageNo])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (pageNo === 1) {
          fetchData()
        } else {
          setPageNo(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])
  return (
    <>
      <Row className='mt-3 mb-3'>
        <Col sm='auto'>
          <label
            htmlFor='wageringTemplateId'
            className={
              touched.wageringTemplateId && errors.wageringTemplateId
                ? 'text-danger'
                : ''
            }
          >
            Wagering Template<span className='text-danger'> *</span>
          </label>
          <InputGroup
            className={
              touched.wageringTemplateId && errors.wageringTemplateId
                ? 'border border-danger'
                : ''
            }
          >
            <BForm.Select
              name='wageringTemplateId'
              size='sm'
              style={{ minWidth: '300px' }}
              value={values.wageringTemplateId || ''}
              onInput={handleChange}
              onBlur={handleBlur}
              onChange={(e) => {
                handleChange(e)
              }}
            >
              {wageringTemplateList?.map((list) => {
                return (
                  <option
                    value={list.wageringTemplateId}
                    key={list.wageringTemplateId}
                  >
                    {list.name}
                  </option>
                )
              })}
            </BForm.Select>
          </InputGroup>
          <ErrorMessage
            component='div'
            name='wageringTemplateId'
            className='text-danger'
          />
        </Col>
        <Col sm='auto'>
          <label>Search</label>
          <BForm.Control
            type='search'
            name='search'
            size='sm'
            value={search}
            style={{ minWidth: '230px' }}
            placeholder='Search Game Name'
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
      </Row>

      {loading
        ? <InlineLoader />
        : (
          <Row>
            {wageringTemplateDetail && values?.wageringTemplateId && (
              <>
                <WageringTemplateDetails
                  wageringTemplateDetail={wageringTemplateDetail}
                  limit={limit}
                  pageNo={pageNo}
                  setLimit={setLimit}
                  setPageNo={setPageNo}
                  totalPages={totalPages}
                  bonus
                />
              </>
            )}
          </Row>)}
      <div className='mt-4 d-flex justify-content-between align-items-center'>
        <Button
          variant='outline-warning'
          onClick={() => {
            (values?.bonusType === 'freespins' || values?.bonusType === 'cashfreespins')
              ? setSelectedTab('currency')
              : ((values?.bonusType === 'depositCashback' || values?.bonusType === 'wagering')
                  ? setSelectedTab('loyalty')
                  : setSelectedTab('currency'))
          }}
          className='ml-2'
        >
          Previous
        </Button>
        <Button
          variant='outline-success'
          onClick={handleSubmit}
          className='ml-2'
        >
          Next
        </Button>
      </div>
    </>
  )
}
export default WageringContributionForm
