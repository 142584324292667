import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCurrenciesStart } from '../../../../store/redux-slices/currencies'
import { getAllSocialLinksStart } from '../../../../store/redux-slices/socialLinks'

const useCurrenciesListing = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const { socialLinks, loading } = useSelector((state) => {
    console.log('state :>> ', state);
    return state.socialLinks
  })

  useEffect(() => {
    dispatch(getAllSocialLinksStart())
  }, [])

  return {
    navigate,
    limit,
    setLimit,
    page,
    setPage,
    socialLinks,
    loading
  }
}

export default useCurrenciesListing
