import React, { useEffect } from 'react'
import CreateLoyaltyRule from './CreateLoyaltyRule'
import { useSelector } from 'react-redux'
import { getLoyaltyRulesState } from '../../../../store/redux-slices/loyaltyRules'
import { AdminsRoutes } from '../../../../routes'
import { useNavigate } from 'react-router-dom';

const UpdateLoyaltyRule = () => {
  const { loyaltyRule, } = useSelector(getLoyaltyRulesState)
  const navigate = useNavigate()
  useEffect(()=>{
    if(!loyaltyRule){
      navigate(AdminsRoutes.Loyalty)
    }
  },[])
  return (
    <>
      {loyaltyRule && (
        <CreateLoyaltyRule
          id={loyaltyRule.id}
          level={loyaltyRule.level}
          loyaltyPoint={loyaltyRule.loyaltyPoint}
          loyaltyCoins={loyaltyRule.loyaltyCoins}

        />
      )}
    </>
  )
}

export default UpdateLoyaltyRule
