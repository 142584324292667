import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createCasinoStart, deleteCasinoSubCategoriesStart, getAllCasinoProvidersStart, getSuperAdminGameCategoryStart, getSuperAdminGameSubCategoryStart, updateCasinoProviderStart, updateCasinoStart, updateCasinoStatusStart } from '../../../store/redux-slices/superAdminCasinoManagement'
import useDidMountEffect from '../../../utils/useDidMountEffect'
import { useNavigate } from 'react-router-dom'
import { resetRestrictedCountriesStart } from '../../../store/redux-slices/fetchData'
import { getLanguagesStart } from '../../../store/redux-slices/languages'

const useCategoryListing = () => {
  const dispatch = useDispatch()
  const isInitialRender = useDidMountEffect()
  const navigate = useNavigate()

  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [active, setActive] = useState('')
  const [categoryId, setCategoryId] = useState('')
  const [show, setShow] = useState(false)
  const [providerId, setProviderId] = useState()
  const [status, setStatus] = useState()
  const [statusShow, setStatusShow] = useState(false)
  const [name, setName] = useState()
  const [data, setData] = useState()
  const [type, setType] = useState('')
  const [language, setLangauge] = useState('EN')
  const { languages } = useSelector(state => state.languages)
  const { loading, gameCategory, gameSubCategory } = useSelector((state) => state.superAdminCasino)
  const totalPages = Math.ceil(gameSubCategory?.count / limit)


  useEffect(() => {
    dispatch(getLanguagesStart({ limit: '', pageNo: '', name: '' }))
  }, [])
  const handleClose = () => setShow(false)
  const handleShow = (type, data) => {
    setType(type)
    setData(data)
    setShow(true)
  }

  const handleStatusShow = (id, status, myName) => {
    setProviderId(id)
    setStatus(!status)
    setStatusShow(true)
    setName(myName?.EN)
  }


  const handleDeleteItems = (gameSubCategoryId) => {
    dispatch(deleteCasinoSubCategoriesStart({
      data: {
        code: 'CASINO_SUB_CATEGORY',
      },
      gameSubCategoryId: gameSubCategoryId,
      limit,
      pageNo: page,
      search
    }))
  }



  const handleYes = () => {
    dispatch(
      updateCasinoStatusStart({
        data: {
          code: 'CASINO_SUB_CATEGORY',
          gameSubCategoryId: providerId,
          status
        },
        limit,
        pageNo: page,
        search
      })
    )
    setTimeout(() => {
      setStatusShow(false)
    }, 500)
  }

  useEffect(() => {
    dispatch(getAllCasinoProvidersStart({ limit, pageNo: page, search }))
    dispatch(resetRestrictedCountriesStart())
  }, [limit, page])

  const updateCategory = (data, { casinoSubCategoryId }) =>
    dispatch(
      updateCasinoStart({
        data: {
          ...data,
          casinoSubCategoryId
        },
        code: 'CASINO_SUB_CATEGORY',
        limit,
        pageNo: page,
        search
      }))

  const createCategory = (data) =>
    dispatch(
      createCasinoStart({
        data,
        code: 'CASINO_SUB_CATEGORY',
        limit,
        pageNo: page,
        search
      })
    )

  const getGameName = (id) => {
    const selected = gameCategory?.rows?.find((obj) => obj.gameCategoryId === id)
    let casinoCategory
    try {
      casinoCategory = JSON.parse(selected.name).EN
    } catch (error) {
      casinoCategory = selected?.name
    }
    return casinoCategory
  }

  useEffect(() => {
    dispatch(getSuperAdminGameCategoryStart({ limit: limit, pageNo: page, search: '', tenantId: '' }))
  }, [])

  useEffect(() => {
    dispatch(getSuperAdminGameSubCategoryStart({ limit, pageNo: page, search, isActive: active, gameCategoryId: categoryId, tenantId: '' }))
  }, [limit, page, active, categoryId])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          dispatch(getSuperAdminGameSubCategoryStart({ limit, pageNo: page, search, isActive: active, gameCategoryId: categoryId, tenantId: '' }))
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  return {
    limit,
    setLimit,
    page,
    setPage,
    loading,
    totalPages,
    gameSubCategory,
    getGameName,
    search,
    setSearch,
    active,
    setActive,
    gameCategory,
    categoryId,
    setCategoryId,
    handleClose,
    handleShow,
    handleStatusShow,
    handleYes,
    updateCategory,
    createCategory,
    status,
    navigate,
    name,
    show,
    statusShow,
    setStatusShow,
    handleDeleteItems,
    data,
    type,
    languages,
    language,
    setLangauge,


  }
}

export default useCategoryListing
