import React from 'react'
import { Row, Col, Tabs, Tab, Form, Button } from '@themesberg/react-bootstrap'
import { SpinnerLoader } from '../../../../../components/Preloader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedo } from '@fortawesome/free-solid-svg-icons'
import DateRangePicker, { DateRangePickerWithoutInput } from '../../../../../components/DateRangePicker'
import useGameReport from '../../hooks/useGameReport'
import DateOptionsFilter from '../../../../../components/DateOptionsFilter'
import GameReportTable from '../../../../../components/GameReportTable'
import { downloadFile } from '../../../../../utils/fileDownloader'
import { ReportListContainer } from '../../style'
import ProviderReport from '../../../../../components/ProviderReportTable'
import UserReport from '../../../../../components/UserReport'
import PaginationComponent from '../../../../../components/Pagination'

export default ({ selectedPortal, selectedClient }) => {
  const {
    fetchDetails,
    setState, state, loading, selectedTab, setSelectedTab, gameReport,
    setDateOptions,
    dateOptions,
    getCsvDownloadUrl,
    page, setPage
  } = useGameReport({ selectedPortal, selectedClient })
  return (
    <ReportListContainer>
      <Row>
        <Col lg={6} xs={12}>
          <h3 className='d-flex'>Relatórios por jogos  {loading ? <SpinnerLoader /> : <FontAwesomeIcon onClick={() => fetchDetails()} style={{ fontSize: '15px', color: 'gray', cursor: 'pointer', marginTop: '5px', marginLeft: '10px' }} icon={faRedo} />}</h3>
        </Col>

        <Col lg={6} xs={12}>
          <div className='d-flex justify-content-end align-items-center'>

            <DateRangePicker
              state={state} setState={setState}
            />

            <Button
              className='mw-fit'
              variant='outline-success'
              size='sm'
              style={{ width: '150px' }}
              disabled={false}
              onClick={() => { downloadFile(getCsvDownloadUrl()) }}
            >
              Exportar relatório
            </Button>
          </div>
        </Col>

      </Row>

      <Tabs
        activeKey={selectedTab}
        onSelect={(tab) => { setSelectedTab(tab); setPage(1) }}
        className='nav-light dashboard'
      >
        <Tab eventKey='game' title='GAME'>
          <div className='mt-2'>
            <Row className='mt-3 d-flex flex-row-reverse text-right'>
              <GameReportTable setPage={setPage} tableData={gameReport?.game?.rows || []} />
            </Row>
          </div>
        </Tab>

        <Tab eventKey='provider' title='PROVIDER'>
          <div className='mt-2'>
            <Row className='mt-3 d-flex flex-row-reverse text-right'>
              <ProviderReport setPage={setPage} tableData={gameReport?.provider?.rows || []} />
            </Row>
          </div>
        </Tab>
        <Tab eventKey='user' title='User'>
          <div className='mt-2'>
            <Row className='mt-3 d-flex flex-row-reverse text-right'>
              <UserReport setPage={setPage} tableData={gameReport?.user?.rows || []} />
            </Row>
          </div>
        </Tab>
      </Tabs>
      <PaginationComponent limit={5} page={page} setPage={setPage} totalPages={Math.ceil(gameReport?.[selectedTab]?.count / 5) || 0}></PaginationComponent>

    </ReportListContainer>

  )
}
