import { Modal, Button } from '@themesberg/react-bootstrap'
import { Formik } from 'formik'
import React from 'react'
import { tagSchema } from './schema'
import CreatableSelect from 'react-select/creatable'
import { toast } from '../../../../components/Toast'

const TagModal = ({
  show,
  handleClose,
  updateTags,
  tagOptions,
  setTagOptions,
  selectedTag,
  setSelectedTag,
  name
}) => {
  return (
    <>
      <Formik
        initialValues={{
          tags: []
        }}
        validationSchema={tagSchema}
        onSubmit={(formValues, { resetForm }) => {
          updateTags(formValues)
          resetForm({ formValues: '' })
          handleClose(false)
        }}
      >
        {({ values, touched, errors, handleSubmit, setFieldValue }) => (
          <Modal
            show={show}
            onHide={() => handleClose(false)}
            backdrop='static'
            keyboard={false}
          >
            <Modal.Header closeButton>
              <h4>Manage Tags {name ? ` for ${name}` : ''}</h4>
            </Modal.Header>
            <Modal.Body>
              <div className='form-group'>
                <label
                  htmlFor='reason'
                  className={touched.reason && errors.reason ? 'text-danger mb-2' : 'mb-2'}
                >
                  Tags <small className='text-danger'>(Only Alphabets and Numbers Allowed)</small>
                </label>
                <CreatableSelect
                  isClearable={false}
                  isMulti
                  name='tags'
                  onCreateOption={(option) => {
                    if (option && option.match(/^[A-Za-z0-9_-]*$/)) {
                      if (tagOptions) {
                        setFieldValue('tags', [...selectedTag, { label: option, value: option, isNew: true }])
                        setSelectedTag([...selectedTag, { label: option, value: option }])
                        setTagOptions([...tagOptions, { label: option, value: option, isNew: true }])
                      } else {
                        setFieldValue('tags', [{ label: option, value: option, isNew: true }])
                        setSelectedTag([{ label: option, value: option }])
                        setTagOptions([{ label: option, value: option, isNew: true }])
                      }
                    } else {
                      toast('Only Alphabets and Numbers Allowed', 'error')
                    }
                  }}
                  options={tagOptions}
                  value={selectedTag}
                  classNamePrefix='select'
                  onChange={(option, e) => {
                    if (e.removedValue?.value !== 'Internal') {
                      if (e?.removedValue?.value) {
                        const tags = selectedTag.filter(tag => tag?.value !== e?.removedValue?.value)
                        setSelectedTag(tags)
                      } else {
                        setSelectedTag(option)
                      }
                      setFieldValue('tags', option)
                    }
                  }}
                />
              </div>
            </Modal.Body>
            <div className='mt-4 '>
              <Modal.Footer className='d-flex justify-content-between align-items-center'>
                <Button
                  variant='outline-warning'
                  onClick={() => handleClose(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant='outline-success'
                  onClick={handleSubmit}
                  className='ml-2'
                >
                  Submit
                </Button>
              </Modal.Footer>
            </div>
          </Modal>
        )}

      </Formik>
    </>
  )
}

export default TagModal
