import React from 'react';
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded'
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Button,
} from '@themesberg/react-bootstrap'
import { useDispatch } from 'react-redux';
import { deleteSocialLinkStart, setSocialLink } from '../../../store/redux-slices/socialLinks';
import useCheckPermission from '../../../utils/checkPermission';
const ActionMenu = (props) => {
    const { navigate, currencyId, hide } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl)
    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const dispatch = useDispatch()
    const {isHidden} = useCheckPermission()
    return (
        <>
            <Button
                variant='btn text-btn'
                id='basic-button'
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <MoreVertRoundedIcon />
            </Button>
            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}


            >
                <MenuItem onClick={handleClose}>
                    <Button
                        variant='btn text-btn'
                        onClick={() => {
                            dispatch(setSocialLink(props.socialLink))
                            navigate(`/admin/social-link/edit/${props.socialLink.iconId}`)
                        }

                        }
                        hidden={isHidden({module:{key:'SocialLinks',value:'U'}})}
                    >
                        <BorderColorRoundedIcon />
                        <span className='text'>Editar</span>
                    </Button>
                </MenuItem>
                <MenuItem onClick={handleClick}>
                <Button
                        variant='btn text-btn'
                        onClick={() => {
                            dispatch(deleteSocialLinkStart({id:props.socialLink.iconId}));
                            // navigate(`/admin/social-link/edit/${props.socialLink.iconId}`)
                        }

                        }
                        hidden={isHidden({module:{key:'SocialLinks',value:'D'}})}

                    >
                        <DeleteIcon />
                        <span className='text'>Deletar</span>
                    </Button>
                </MenuItem>

            </Menu>
        </>
    )
};

export default ActionMenu;