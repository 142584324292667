import React from 'react'
import {
  Button,
  Form as BForm,
  InputGroup,
  Row,
  Col,
  Spinner
} from '@themesberg/react-bootstrap'
import { Formik, Form, ErrorMessage } from 'formik'

import { AdminsRoutes } from '../../../../routes'
import { createSocialLinkSchema } from '../schema'
import useCreateSocialLink from '../hooks/useCreateSocialLink'

export default ({
  name: editName,
  label: editLabel,
  exchangeRate: editExchangeRate,
  link: editLink,
  iconId: iconId,
  isPrimary: editIsPrimary,
}) => {
  const { navigate, editSocialLink, createSocialLink, loading } =
  useCreateSocialLink()
  console.log('name :>> ', editLink);
  return (
    <>
      <div className='section-header-wrap mt-n3 mx-n3'>
        <h2 className='title-text'>
          {iconId ? 'Edit Social Link' : 'Create Social Link'}
        </h2>
      </div>

      <div className='FormsBg'>
        <Formik
          initialValues={{
            name: editName || '',
            label: editLabel || '',
            link: editLink || '',
          }}
          validationSchema={createSocialLinkSchema(editName)}
          onSubmit={({ name, label, link}) => {
            iconId
              ? editSocialLink({
                iconId,
                name,
                label,
                link,
                navigate
              })
              : createSocialLink({
                name,
                label,
                link,
                navigate
              })
          }}
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleSubmit,
            handleBlur
          }) => (
            <div className='m-4'>
              <Form>
                {/* Div for name */}
                <Row>
                  <Col xs='12' sm='6'>
                    <div className='form-group mb-2'>
                      <label
                        htmlFor='name'
                        className={touched.name && errors.name ? 'text-danger' : ''}
                      >
                        <b>Name</b>
                        <span className='text-danger'> *</span>
                      </label>

                      <InputGroup
                        className={
                          touched.name && errors.name ? 'border border-danger' : ''
                        }
                      >
                        <BForm.Control
                          name='name'
                          required
                          type='text'
                          placeholder='Enter name'
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </InputGroup>

                      <ErrorMessage
                        component='div'
                        name='name'
                        className='text-danger'
                      />
                    </div>
                  </Col>

                  {/* Div for code */}
                  <Col xs='12' sm='6'>
                    <div className='form-group pt-2 mb-2'>
                      <label
                        htmlFor='label'
                        className={touched.label && errors.label ? 'text-danger' : ''}
                      >
                        <b>Label</b>
                        <span className='text-danger'> *</span>
                      </label>

                      <InputGroup
                        className={
                          touched.label && errors.label ? 'border border-danger' : ''
                        }
                      >
                        <BForm.Control
                          name='label'
                          required
                          type='text'
                          placeholder='Enter Label'
                          value={values.label}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </InputGroup>

                      <ErrorMessage
                        component='div'
                        name='label'
                        className='text-danger'
                      />
                    </div>
                  </Col>

                  {/* Div for Symbol */}
                  <Col xs='12' sm='6'>
                    <div className='form-group pt-2 mb-2'>
                      <label
                        htmlFor='link'
                        className={
                          touched.link && errors.link ? 'text-danger' : ''
                        }
                      >
                        <b>Link</b>
                        <span className='text-danger'> * </span>
                      </label>

                      <InputGroup
                        className={
                          touched.link && errors.link
                            ? 'border border-danger'
                            : ''
                        }
                      >
                        <BForm.Control
                          name='link'
                          required
                          type='text'
                          placeholder='Enter link'
                          value={values.link}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </InputGroup>

                      <ErrorMessage
                        component='div'
                        name='link'
                        className='text-danger'
                      />
                    </div>
                  </Col>
                </Row>
                <div className='mt-4 d-flex justify-content-between align-items-center'>
                  <Button
                    className='btn-danger ml-2'
                    onClick={() => navigate(AdminsRoutes.SocialLinks)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className='btn-primary'
                    onClick={handleSubmit}
                  >
                    Submit
                    {loading && (
                      <Spinner
                        as='span'
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    )}
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </>
  )
}
