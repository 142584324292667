import React from 'react'
import {
  Button,
  Row,
  Col,
  Table,
  Form,
  Card
} from '@themesberg/react-bootstrap'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import './GameReorder.scss'
import useGameReorder from './hooks/useGameReorder'
import { UpdateOutlined } from '@mui/icons-material'
import { motion } from 'framer-motion/dist/framer-motion'

export default () => {
  const {
    loading,
    reOrderedGame,
    onDragEnd,
    handleSave,
    casinoGames,
    handRemoveGame,
    handleAddGame,
    casinoCategories,
    categoryFilter,
    setCategoryFilter,
    setCasinoCategoryId,
    subCategories,
    casinoCategoryId,
    setReorderedGame,
    search,
    setSearch
  } = useGameReorder()
  return (
    <>
         <div className='section-header-wrap mt-n3 mx-n3'>
      <Row>
      <Col lg={8} xs={7}>
          <h2 className='title-text'>Game Reorder</h2>
        </Col>
        <Col lg={4} xs={5}>
          <div className='d-flex justify-content-end'>
          <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
            <Button
              variant='btn btn-primary w-100'
              style={{ maxWidth: '140px' }}
              onClick={() => handleSave()}
              disabled={reOrderedGame?.count === 0}
            >
               <UpdateOutlined className='me-2' />
                  <span>Update</span>
            </Button>
            </motion.div>
          </div>
        </Col>
      </Row>
      </div>
      <div className='game-reordering-container'>
        <Card className='p-2 game-reordering-subcontainer'>
        <div className='FormsBg'>
          <Row>
          <Col xs={3}>             
               {/* <Form.Label style={{ marginBottom: '0', marginRight: '15px' }}>
                  Category
                </Form.Label> */}

                <Form.Select
                  value={categoryFilter}
                  placeholder='Category'
                  onChange={(e) => {
                    setReorderedGame({ rows: [], count: 0 })
                    setCategoryFilter(e.target.value)
                  }}
                  size='sm'
                  style={{ maxWidth: '230px' }}
                >
                  <option value=''>Select Category</option>
                  {casinoCategories && casinoCategories?.rows?.map((c) => (
                    <option key={c?.gameCategoryId} value={c?.gameCategoryId}>{c?.jname?.EN}</option>
                  ))}
                </Form.Select>
                </Col>

                <Col xs={3}>   

                {categoryFilter &&
                  <>
                    {/* <Form.Label style={{ marginBottom: '0', marginRight: '15px', marginLeft: '15px' }}>
                      Sub Category
                    </Form.Label> */}
                    <Form.Select
                      style={{ marginBottom: '0', marginRight: '15px', maxWidth: '230px' }}
                      placeholder='Sub Category'
                     onChange={(e) => {
                        setCasinoCategoryId(e.target.value)
                        if (reOrderedGame.rows) {
                          setReorderedGame({ rows: [], count: 0 })
                        }
                      }}
                    >
                      <option value=''>All</option>

                      {subCategories && subCategories?.rows?.map((c) => (
                        <option key={c?.gameSubCategoryId} value={c?.gameSubCategoryId}>{c?.jname?.EN}</option>
                      ))}
                    </Form.Select>
                  </>}

            </Col>
          {/* </Row> */}
          {categoryFilter && casinoCategoryId &&
            // <Row>
              <Col xs={3}> 
            {/* <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
                  Search
                </Form.Label> */}

                <Form.Control
                  type='search'
                  value={search}
                  placeholder='Search Games'
                  size='sm'
                  style={{ maxWidth: '230px' }}
                  onChange={(event) => setSearch(event.target.value)}
                />
              </Col>
            }
            </Row>
            </div>
          <div style={{ overflow: 'auto' }}>
            {casinoCategoryId
              ? (
                <>
                  <Table  striped hover size='sm' className='text-center mt-4'>
                    <thead className='thead-light'>
                      <tr>
                        <th>Order ID</th>
                        <th>Game Name (ID)</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody id={loading ? 'table-spinner' : ''}>
                      {!loading && casinoGames?.rows?.map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>{item.name} ({item.casinoGameId})</td>
                            <td>
                              <Button
                                className='m-1'
                                size='sm'
                                variant='success'
                                onClick={() => handleAddGame(item)}
                              >+
                              </Button>
                            </td>
                          </tr>
                        )
                      })}

                      {casinoGames?.rows?.length === 0 && !loading && (
                        <tr><td className='text-danger' colSpan={10}>Registros não encontrados.</td></tr>
                      )}
                    </tbody>

                  </Table>
                </>
                )
              : <p className='text-danger text-center mt-7'> Selecione a categoria e subcategoria </p>}
          </div>
        </Card>
        <Card className='p-2 game-reordering-subcontainer'>
          {reOrderedGame?.count !== 0
            ? (
              <div className='game-reorder'>
                <div className='game-reorder-heading'>
                  {[
                    'ID',
                    'Game Name',
                    'Action'
                  ].map((h, idx) => (
                    <p className={`game-heading-${idx}`} key={h}>{h}</p>
                  ))}
                </div>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId='list'>
                    {provided => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {/* <QuoteList quotes={state.quotes} /> */}
                        {reOrderedGame?.rows?.map(
                          (item, idx) => (
                            <Draggable draggableId={`id-${idx}`} key={idx} index={idx}>
                              {provided => (
                                <div
                                  className='game-reorder-content'
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <p className='game-id'>{idx + 1}</p>
                                  <p className='game-name'>{item.name}</p>
                                  <Button
                                    className='m-1 game-button'
                                    size='sm'
                                    variant='danger'
                                    onClick={() => handRemoveGame(item)}
                                  >X
                                  </Button>
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
              )
            : <p className='text-danger text-center mt-7'>Jogo não selecionado</p>}
        </Card>

      </div>

    </>
  )
}
