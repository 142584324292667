import { Col, Form, Row, Table } from '@themesberg/react-bootstrap'
import React from 'react'
import PaginationComponent from '../../../components/Pagination'
import useLanguages from './hooks/useLanguages'

const Languages = () => {
  const {
    loading,
    languages,
    limit,
    pageNo,
    totalPages,
    setPageNo,
    setLimit,
    name,
    setName
  } = useLanguages()

  return (
    <>
          <div className='section-header-wrap mt-n3 mx-n3'>
      <Row>
        <Col>
          <h2 className='title-text'>Languages</h2>
        </Col>
      
      </Row>
      </div>

      <div className='FormsBg'>
        <Row>
        <Col xs='auto'>
          <div className='d-flex justify-content-end align-items-center w-100'>
         

            <Form.Control
              type='search'
              placeholder='Search Language'
              value={name}
              onChange={(event) =>
                setName(event.target.value.replace(/[^\w\s]/gi, ''))}
            />
          </div>
        </Col>
        </Row>
      </div>
      <div className='TableBg mt-4 pt-1'>
      
      <Table  striped responsive hover size='sm' className='text-center WalletTable mt-3'>
        <thead className='thead-light'>
          <tr>
            <th>Id</th>
            <th>Language Code</th>
            <th>Language Name</th>
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && languages?.rows?.map((item, i) => (
            <tr key={`language-listing ${i}`}>
              <td>
                {item?.languageId}
              </td>
              <td>
                {item?.code}
              </td>
              <td>
                {item?.languageName}
              </td>
            </tr>
          ))}
        </tbody>

        {languages?.count === 0 && !loading &&
          <tr>
            <td
              colSpan={4}
              className='text-danger text-center'
            >
              No data found
            </td>
          </tr>}
      </Table>
      {languages?.count !== 0 && !loading &&
              (
                <PaginationComponent
                  page={languages?.count < pageNo ? setPageNo(1) : pageNo}
                  totalPages={totalPages}
                  setPage={setPageNo}
                  limit={limit}
                  setLimit={setLimit}
                />
              )}
      </div>
     

    </>

  )
}

export default Languages
