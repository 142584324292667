import React, { useEffect, useState } from 'react';
import { Row, InputGroup, Col } from '@themesberg/react-bootstrap';
import { InlineLoader, Loading } from '../../../../components/Preloader';
import { ErrorMessage, Form, Formik } from 'formik';
import { globalSettingsFields, globalSettingsFormInitialValues, globalSettingsFormSchema } from './config.schema';
import CurrencyInput from '../../../../components/CurrencyInput';
import FormatNumber from '../../../../utils/formatNumber';
import Select from 'react-select';
import Trigger from '../../../../components/OverlayTrigger';
import { resolveErrors } from '../../../../utils/errorTypes';
import { toast } from '../../../../components/Toast';
import { getGlobalSettingsService, updateGlobalSettingsService } from '../../../../utils/apiCalls';
import PercentInput from '../../../../components/PercentInput';

import { get } from "object-path";
import { Form as BForm } from 'react-bootstrap';
import Button from '../../../../components/Button';
import { GLOBAL_SETTINGS_KEY } from '../../../../utils/constant';
import { useDispatch } from 'react-redux';


const GeneralSettingsPage = () => {
  const dispatch = useDispatch();

  const [initialLoading, setInitialLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState(globalSettingsFormInitialValues());

  const loadGlobalSettings = async () => {
    setInitialLoading(true);
    try {
      const { data: { data } } = await getGlobalSettingsService({ keys: [GLOBAL_SETTINGS_KEY.ADMIN_INFORMATION, GLOBAL_SETTINGS_KEY.SITE_INFORMATION, GLOBAL_SETTINGS_KEY.SENDGRID] });
      setInitialFormValues(globalSettingsFormInitialValues(data.settings));
    } catch (err) {
      const message = resolveErrors(err.response.data.errors, "Erro ao obter configurações de transações de pagamento");
      toast(message, "error");
    } finally {
      setInitialLoading(false);
    }
  }

  const onSubmit = async (formValue) => {
    setLoading(true);
    try {
      await updateGlobalSettingsService({ ...formValue });
      // dispatch(getAdminPanelSettingsStart());

      toast("Alterações salvas com sucesso!", "success");
    } catch (err) {
      const message = resolveErrors(err.response.data.errors, "Erro ao salvar as alterações");
      toast(message, "error");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadGlobalSettings();
  }, [])

  return (
    <>
      <div className='section-header-wrap mt-n3 mx-n3'>
        <h2 className='title-text'>Configurações Gerais</h2>
      </div>

      <div className='FormsBg position-relative'>
        <Formik
          enableReinitialize
          initialValues={initialFormValues}
          validationSchema={globalSettingsFormSchema}
          onSubmit={onSubmit}
        >
          {({ values, errors, handleChange: onChange, handleSubmit, handleBlur: onBlur, isValid, setFieldValue }, key) => {
            // console.log(values, errors)
            return (
              <Form autoComplete='off' key={key}>
                <Row>
                  {!initialLoading && globalSettingsFields.map(({ label, name, type = "text", options = [], isMulti, className = "col-sm-3 col-12", separator, disabled, message, step, title, subtitle, feedback, showIfValue, inputClassName, ...props }, key) => {
                    return (!showIfValue || (showIfValue && Boolean(get(values, showIfValue)))) ? <>
                      {(!separator && !title && !subtitle) ? <Trigger {...{ message }} placement='top' key={key}>
                        <Col className={`mb-3 d-flex flex-column ${className}`} key={key}>
                          {!["checkbox", "switch"].includes(type) ? <BForm.Label>{label}</BForm.Label> : null}
                          <InputGroup>
                            {type == "currency" ?
                              <CurrencyInput
                                {...{ type, name, onChange, onBlur, disabled }}
                                placeholder={FormatNumber.currency(0)}
                                defaultValue={FormatNumber.currency(get(initialFormValues, name))}

                                onNumberFormat={(event) => {
                                  setFieldValue(name, event.detail.number)
                                }}
                              /> : null}
                            {type == "percent" ?
                              <PercentInput
                                {...{ type, name, onChange, onBlur, disabled }}
                                placeholder={FormatNumber.percent(0)}
                                defaultValue={FormatNumber.percent(get(initialFormValues, name))}

                                onNumberFormat={(event) => {
                                  setFieldValue(name, event.detail.number)
                                }}
                              /> : null}
                            {type == "select" & !isMulti ?
                              <BForm.Select
                                {...{ type, name, onBlur, value: get(values, name), disabled }}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  if (Number(value)) value = Number(value);
                                  if (["true", "false", "null"].includes(value)) value = JSON.parse(value);
                                  setFieldValue(name, value)
                                }}
                              >
                                {options.map(({ label, value }, key) => <option {...{ value, key }}>{label}</option>)}
                              </BForm.Select>
                              : null}
                            {type == "select" & isMulti ?
                              <Select
                                {...{ type, name, onBlur, options, disabled }}
                                isMulti
                                isClearable={false}
                                className='basic-multi-select w-100'
                                classNamePrefix='select'
                                value={get(values, name)}
                                onChange={(values, e) => {
                                  setFieldValue(name, values)
                                }}
                              /> : null}
                            {["checkbox", "switch"].includes(type) ?
                              <BForm.Check
                                reverse
                                {...{ type, name, onBlur, label, feedback, checked: get(values, name), disabled, id: name }}
                                onChange={({ target }) => {
                                  setFieldValue(name, target.checked);
                                }}
                              /> : null}
                            {/* Default input */}
                            {(!type || ["text", "number"].includes(type)) ? <BForm.Control {...{ type, name, onChange, onBlur, value: get(values, name), disabled, step }} className={inputClassName} {...props} /> : null}

                          </InputGroup>
                          {(!["checkbox", "switch"].includes(type) && feedback) ? <BForm.Control.Feedback className='d-block text-feedback'>{feedback}</BForm.Control.Feedback> : null}
                          <ErrorMessage {...{ name }} component='small' className='text-danger mt-1' />
                        </Col>
                      </Trigger> : null}
                      {separator ? <hr className='mt-3 mb-4' key={key} /> : null}
                      {title ? <h4 className='mt-3 mb-3 d-flex gap-2 color-blue-800' key={key}><span className='mw-fit'>{title}</span><hr className='w-100' /></h4> : null}
                      {subtitle ? <h5 className='mt-3 mb-2 d-flex gap-2 color-blue-800' key={key}><span className='mw-fit'>{subtitle}</span></h5> : null}
                    </> : null
                  })}
                </Row>
                <Row className='mt-4'>
                  <Col className='ms-auto col-sm-2 col-6'>
                    <Button
                      className='w-100'
                      disabled={!isValid}
                      onClick={handleSubmit}
                      loading={loading}
                    >
                      Salvar
                    </Button>
                  </Col>
                </Row>
              </Form>
            )
          }}
        </Formik>
        {initialLoading ? <Loading className={"position-absolute bg-white top-0 start-0 rounded-4 w-100 h-100"} /> : null}
      </div>

    </>
  )
}

export default GeneralSettingsPage;
