import React from 'react';
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
// import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded'
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
// import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded'
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded'

import {
    Button,
  } from '@themesberg/react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { CopyAllRounded, PlusOneRounded } from '@mui/icons-material';
const ActionMenu = (props) => {
    const { 
    isHidden, 
    handleCreateEdit,
    handleDelete,
    thumbnail,
    bannerType} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl)
    const navigate = useNavigate()

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    return (
        <>
           {!isHidden({
                module: {
                key: 'Bonus',
                value: 'R',
                },
            }) ||
            !isHidden({
                module: {
                key: 'Bonus',
                value: 'U',
                },
            }) ? (
                <>
            <Button
                variant='btn text-btn'
                id='basic-button'
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
                }}
            >
                <MoreVertRoundedIcon />
            </Button>
            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button'
                }}
            >
               {/* <MenuItem onClick={handleClose}>
                    <Button
                       variant='btn text-btn'
                       hidden={isHidden({ module: { key: 'Bonus', value: 'R' } })}
                            onClick={() =>
                              navigate(`/admin/bonus/details/${bonusId}`)}
                    >
                        <RemoveRedEyeRoundedIcon/>
                        <span className='text'>View</span>
                    </Button>
                </MenuItem> */}
                {/* <MenuItem onClick={handleClose}>
                    <Button
                        variant='btn text-btn'
                        onClick={() => {
                          handleCreateEdit('Update',
                            {
                              thumbnail,
                              bannerType
                            })}}
                        >
                        <BorderColorRoundedIcon />
                        <span className='text'>Edit</span>
                    </Button>
                </MenuItem> */}

                {/* <MenuItem onClick={handleClose}>
                    <Button
                       variant='btn text-btn'
                       hidden={isHidden({ module: { key: 'Bonus', value: 'C' } })}
                       onClick={() =>
                         navigate(`/admin/bonus/clone/${bonusId}`)}
                    >
                        <CopyAllRounded />
                        <span className='text'>Clone</span>
                    </Button>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    
                    <Button
                        variant='btn text-btn'
                        onClick={() => handleShow(bonusId, isActive, `${promotionTitle?.EN}(${tenant?.name})`)}
                        hidden={isHidden({ module: { key: 'Bonus', value: 'T' } })}
                        
                    >
                    {
                            !isActive ? 
                                <>
                                    <CheckCircleRoundedIcon />
                                    <span className='text'>Set Status Active</span>
                                </>
                                :
                                <>
                                    <CancelRoundedIcon />
                                    <span className='text'>Set Status In-Active</span>
                                </>
                            }
                       
                        </Button>
                </MenuItem> */}
                <MenuItem onClick={handleClose}>
                    <Button
                       variant='btn text-btn'
                      //  disabled={claimedCount}
                       hidden={isHidden({ module: { key: 'BannerManagement', value: 'D' } })}
                      onClick={() => handleDelete(bannerType)}
                    >
                        <DeleteForeverOutlinedIcon />
                        <span className='text'>Delete</span>
                    </Button>
                </MenuItem>

                {/* {!isGlobal&&<MenuItem onClick={handleClose}>
                    <Button
                       variant='btn text-btn'
                        disabled={isGlobal}
                        onClick={() => navigate(`/admin/casino-sub-category/addGames/${gameSubCategoryId}`,{ state: { subCategoryName: name } })}
                        hidden={isHidden({
                        module: {
                            key: 'CasinoManagement',
                            value: 'T',
                        },
                        })}
                    >
                        <PlusOneRounded />
                        <span className='text'>Add Games</span>
                    </Button> */}
                {/* </MenuItem>} */}

            </Menu></>) : 'NA' }
        </>
    )
};

export default ActionMenu;