import { Col, Row, Tab, Tabs } from '@themesberg/react-bootstrap'
import React from 'react'
import Preloader from '../../../../components/Preloader'
import useEditProvider from '../hooks/useEditProvider'
import Country from './Country'
import General from './General'

const EditProvider = () => {
  const {
    providerDetails,
    loading,
    selectedKeyTab,
    setSelectedKeyTab,
    updateData,
    navigate,
    countries,
    data,
    setData,
    search,
    setSearch,
    myCountries,
    logoCheck,
    setLogoCheck,
    providerId,
    tenantLanguages,
    details,
    amtOptions,
    kycCountries,
    setKycCountries
  } = useEditProvider()

  return (
    <>
      {loading
        ? <Preloader />
        : (
          <>
            <Row>
              <Col>
                <h3>Edit Provider : {providerDetails?.name}</h3>
              </Col>
            </Row>

            <Tabs
              activeKey={selectedKeyTab}
              onSelect={(tab) => setSelectedKeyTab(tab)}
              className='nav-light'
            >
              <Tab
                eventKey='general'
                title='General'
              >
                <div className='mt-3'>
                  {parseInt(providerDetails?.paymentProviderId) === parseInt(providerId) &&
                    <General
                      providerDetails={providerDetails}
                      updateData={updateData}
                      navigate={navigate}
                      logoCheck={logoCheck}
                      setLogoCheck={setLogoCheck}
                      tenantLanguages={tenantLanguages}
                      details={details}
                      amtOptions={amtOptions}
                    />}
                </div>
              </Tab>
              <Tab
                eventKey='country'
                title='Countries'
              >
                <div className='mt-4'>
                  <Country
                    providerDetails={providerDetails}
                    updateData={updateData}
                    navigate={navigate}
                    countries={countries}
                    data={data}
                    setData={setData}
                    search={search}
                    setSearch={setSearch}
                    myCountries={myCountries}
                    details={details}
                    kycCountries={kycCountries}
                    setKycCountries={setKycCountries}
                    cashier
                  />
                </div>
              </Tab>
            </Tabs>
          </>
          )}
    </>
  )
}

export default EditProvider
