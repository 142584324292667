import React from 'react';
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded'
import {
    Button,
  } from '@themesberg/react-bootstrap'
const ActionMenu = (props) => {
    const { item, setEditData, toggleAddModal } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl)
    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    return (
        <>
            <Button
                variant='btn text-btn'
                id='basic-button'
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
                }}
            >
                <MoreVertRoundedIcon />
            </Button>
            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
                >
                <MenuItem onClick={handleClose}>
                    <Button
                        // size='sm'
                        variant='btn text-btn'
                        onClick={()=> {
                            setEditData(item);
                            toggleAddModal();
                        }}
                    >
                        <BorderColorRoundedIcon />
                        <span className="text">Edit</span>
                    </Button>
                </MenuItem>
            </Menu> 
        </>
    )
};

export default ActionMenu;