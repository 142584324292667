import React from 'react'
import {
  Row,
  Col,
  Table,
  Form
} from '@themesberg/react-bootstrap'
import PaginationComponent from '../../../components/Pagination'
import Trigger from '../../../components/OverlayTrigger'
import useCategoryListing from './useCategoryListing'
import { formatDate } from '../../../utils/dateFormatter'
import useCheckPermission from '../../../utils/checkPermission'
import { Button } from 'react-bootstrap'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faBan, faCheckSquare, faEdit, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import ConfirmationModal from '../../../components/ConfirmationModal'
import CreateCasinoCategory from './components/CreateCasinoCategory'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import { motion } from 'framer-motion/dist/framer-motion'
import ActionMenu from './ActionMenu'
import { AdminsRoutes } from '../../../routes'
import { Reorder } from '@mui/icons-material'
export default () => {
  const {
    status,
    name,
    show,
    statusShow,
    setStatusShow,
    limit,
    setLimit,
    page,
    setPage,
    loading,
    gameCategory,
    handleShow,
    totalPages,
    search,
    handleStatusShow,
    handleYes,
    handleClose,
    navigate,
    setSearch,
    data,
    type,
    language,
    setLangauge,
    languages,
    createCategory,
    updateCategory
  } = useCategoryListing()
  const { isHidden } = useCheckPermission()

  return (
    <>
      <div className='section-header-wrap mt-n3 mx-n3'>
        <Row>
          <Col lg={8} xs={7}>
            <h2 className='title-text'>Casino Categorias</h2>
          </Col>
          <Col lg={4} xs={5}>
            <div className='d-flex justify-content-end'>
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Button
                  className='m-1'
                  variant='btn btn-primary '
                  style={{ maxWidth: '140px' }}
                  onClick={() => handleShow('Create', null)}
                  hidden={isHidden({ module: { key: 'CasinoManagement', value: 'C' } })}
                >
                  <AddCircleOutlineRoundedIcon className='me-2' />
                  <span>Criar</span>
                </Button>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Button
                  className='m-1'
                  variant='m-1 btn btn-primary '
                  style={{ maxWidth: '140px' }}
                  onClick={() => navigate(AdminsRoutes.ReorderCategory)}
                  hidden={isHidden({ module: { key: 'CasinoManagement', value: 'C' } })}
                >
                  <Reorder className='me-2' />
                  <span>Reordenar</span>
                </Button>
              </motion.div>
            </div>
          </Col>
        </Row>
      </div>
      <div className='FormsBg'>
        <Row>
          <Col xs={3}>


            <Form.Control
              type='search'
              value={search}
              placeholder='Pesquisar Nome'
              size='sm'
              onChange={(event) => setSearch(event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, ''))}
            />
          </Col>
        </Row>
      </div>
      <div className='TableBg mt-4 pt-1'>

        <Table striped responsive hover size='sm' className='text-center mt-3'>
          <thead className='thead-light'>
            <tr>
              {[
                'Id',
                'Nome',
                'Data de criação',
                'Data de atualização',
                'Status',
                'Actions'
              ].map((header, idx) => (
                <th key={idx}>{header}</th>
              ))}
            </tr>
          </thead>

          <tbody id={loading ? 'cover-spin' : ''}>
            {!loading && gameCategory &&
              gameCategory?.rows?.map(
                (
                  { gameCategoryId, name, createdAt, updatedAt, isActive }, index
                ) => {
                  let casinoCategory
                  try {
                    casinoCategory = JSON.parse(name)?.EN
                  } catch (error) {
                    casinoCategory = name
                  }
                  return (
                    <tr key={gameCategoryId}>
                      <td>{gameCategoryId}</td>
                      <td>
                        <Trigger message={name}>
                          <span
                            style={{
                              width: '100px',
                              cursor: 'pointer',
                            }}
                            className="d-inline-block text-truncate"
                          >
                            {casinoCategory}
                          </span>
                        </Trigger>
                      </td>
                      <td>{formatDate(createdAt)}</td>
                      <td>{formatDate(updatedAt)}</td>
                      <td>
                        {isActive ? (
                          <span className="text-success">Ativos</span>
                        ) : (
                          <span className="text-danger">Inativos</span>
                        )}
                      </td>
                      <td>
                        <ActionMenu
                          isHidden={isHidden}
                          handleShow={handleShow}
                          isActive={isActive}
                          name={name}
                          index={index}
                          handleStatusShow={handleStatusShow}
                          gameCategoryId={gameCategoryId}
                          gameCategory={gameCategory}
                        />
                        {/* {!isHidden({
                                  module: {
                                    key: 'CasinoManagement',
                                    value: 'U',
                                  },
                                }) ||
                                !isHidden({
                                  module: {
                                    key: 'CasinoManagement',
                                    value: 'T',
                                  },
                                }) ? (
                                  <ButtonGroup>
                                    <Trigger message="Edit">
                                      <Button
                                        className="m-1"
                                        size="sm"
                                        variant="warning"
                                        onClick={() =>
                                          handleShow(
                                            'Edit',
                                            gameCategory.rows[index],
                                          )
                                        }
                                        hidden={isHidden({
                                          module: {
                                            key: 'CasinoManagement',
                                            value: 'U',
                                          },
                                        })}
                                      >
                                        <FontAwesomeIcon icon={faEdit} />
                                      </Button>
                                    </Trigger>

                                    {!isActive ? (
                                      <Trigger message="Set Status Active">
                                        <Button
                                          className="m-1"
                                          size="sm"
                                          variant="success"
                                          onClick={() =>
                                            handleStatusShow(
                                              gameCategoryId,
                                              isActive,
                                              name,
                                            )
                                          }
                                          hidden={isHidden({
                                            module: {
                                              key: 'CasinoManagement',
                                              value: 'T',
                                            },
                                          })}
                                        >
                                          <FontAwesomeIcon
                                            icon={faCheckSquare}
                                          />
                                        </Button>
                                      </Trigger>
                                    ) : (
                                      <Trigger message="Set Status In-Active">
                                        <Button
                                          className="m-1"
                                          size="sm"
                                          variant="danger"
                                          onClick={() =>
                                            handleStatusShow(
                                              gameCategoryId,
                                              isActive,
                                              name,
                                            )
                                          }
                                          hidden={isHidden({
                                            module: {
                                              key: 'CasinoManagement',
                                              value: 'T',
                                            },
                                          })}
                                        >
                                          <FontAwesomeIcon
                                            icon={faWindowClose}
                                          />
                                        </Button>
                                      </Trigger>
                                    )}

                                    <Trigger message="Delete">
                                      <Button
                                        className="m-1"
                                        size="sm"
                                        variant="secondary"
                                        hidden={isHidden({
                                          module: {
                                            key: 'CasinoManagement',
                                            value: 'U',
                                          },
                                        })}
                                        // onClick={() => navigate(`/admin/casino-games/restrict-countries/${gameCategoryId}`)}
                                      >
                                        <FontAwesomeIcon icon={faTrash} />
                                      </Button>
                                    </Trigger>
                                  </ButtonGroup>
                                ) : (
                                  'NA'
                                )} */}
                      </td>
                    </tr>
                  )
                }
              )}

            {gameCategory?.count === 0 && !loading &&
              (
                <tr>
                  <td
                    colSpan={4}
                    className='text-danger text-center'
                  >
                    No data found
                  </td>
                </tr>
              )}
          </tbody>
        </Table>
        {gameCategory?.count !== 0 && !loading &&
          (
            <PaginationComponent
              page={gameCategory?.count < page ? setPage(1) : page}
              totalPages={totalPages}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
            />
          )}
      </div>
      <ConfirmationModal
        setShow={setStatusShow}
        show={statusShow}
        handleYes={handleYes}
        active={status}
        name={name}
      />
      <CreateCasinoCategory
        handleClose={handleClose}
        data={data}
        type={type}
        show={show}
        language={language}
        setLangauge={setLangauge}
        languages={languages}
        loading={loading}
        createProvider={createCategory}
        updateProvider={updateCategory}
      />
    </>
  )
}
