import { Table } from '@themesberg/react-bootstrap'
import React from 'react'
// import PaginationComponent from '../Pagination'
// import { statusType, tableHeaders } from '../../pages/Super-Admin/CasinoTransactions/constants'
import { getDateTime } from '../../../utils/dateFormatter'
import Trigger from '../../../components/OverlayTrigger'
import PaginationComponent from '../../../components/Pagination'
import { findTypeStatus } from './constants'
import FormatNumber from '../../../utils/formatNumber'
// import Trigger from '../../OverlayTrigger'

const CasinoTransactionsList = ({
  setPage,
  page,
  totalPages,
  limit,
  setLimit,
  transactions,
  loading = false
}) => {
  const headerData = [
    'ID',
    'Email',
    'Quantia',
    // 'Non Cash Amount',
    'Moeda',
    'Tipos de ação',
    'Status',
    'Criado em'
  ]
  return (
    <>
      <div className='TableBg mt-4 pt-1'>
        {/* Table with Casino Transactions Info */}

        <Table striped responsive hover size='sm' className='text-center mt-3'>
          <thead className='thead-light'>
            <tr>
              {headerData.map((c) => (
                <th key={c} className={`${['Amount', 'Transaction Type'].includes(c) && transactions?.count > 0 ? 'text-right' : ''}`}>{c}</th>
              ))}
            </tr>
          </thead>

          <tbody id={loading ? 'cover-spin' : ''}>
            {transactions && !loading &&
              transactions?.rows?.map(
                ({
                  transactionId,
                  user,
                  //   actioneeType,
                  createdAt,
                  // nonCashAmount,
                  amount,
                  extraData,
                  currency,
                  actionType
                }) => {
                  return (
                    <tr key={`casino-transaction-list ${transactionId}`}>
                      <td>{transactionId}</td>

                      <td>
                        <Trigger message={user?.email}>
                          <span
                            style={{
                              width: '130px',
                              cursor: 'pointer'
                            }}
                            className='d-inline-block text-truncate'
                          >
                            {user?.email}
                          </span>
                        </Trigger>
                      </td>
                      <td className={`${amount > 0 ? 'text-success' : amount < 0 && 'text-danger'}`}>
                        {amount ? FormatNumber.currency(amount, currency?.code) : "-"}
                      </td>
                      <td>
                        {currency?.code || '-'}
                      </td>
                      <td>{actionType}</td>
                      <td>{extraData?.selections?.[0]?.status || "Pending"} </td>

                      <td>{getDateTime(createdAt)}</td>

                    </tr>
                  )
                })}
            {transactions?.count === 0 && !loading && (
              <tr>
                <td colSpan={9} className='text-danger text-center'>
                  Registros não encontrados
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {transactions?.count > 0 && !loading && (
          <PaginationComponent
            page={transactions?.count < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
          />
        )}
      </div>
    </>
  )
}

export default CasinoTransactionsList
