import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { useNavigate } from 'react-router-dom'

import { getCountriesListStart, getSportsListStart, getTournamentsListStart } from '../../../store/redux-slices/sportsBook'

const useCountriesListing = () => {
  const dispatch = useDispatch()

  const [limit, setLimit] = useState(10)
  const [pageNo, setPageNo] = useState(1);
  const [searchByName, setSearchByName] = useState("");
  const [searchByCountry, setSearchByCountry] = useState("");
  const [searchBySports, setSearchBySports] = useState("");

  const { sportsTournamentInfo, isGetTournamentLoading, sportsListInfo, sportsCountriesInfo } = useSelector(state => state.sportsBook)
  const totalPages = Math.ceil(sportsTournamentInfo?.totalPage / limit)


  useEffect(() => {
    dispatch(getSportsListStart({
      limit: 15,
      pageNo: 1,
      isAllListing: true
    }))
    dispatch(getCountriesListStart({
      limit: 15,
      pageNo: 1,
      isAllListing: true
    }))
  }, [])

  useEffect(() => {
    dispatch(getTournamentsListStart({
      limit: limit,
      pageNo: pageNo,
      providerCountryId: searchByCountry,
      providerSportId: searchBySports,
      search: searchByName,
    })
    )
  }, [limit, pageNo, searchByName, searchBySports, searchByCountry])

  return {
    limit,
    setLimit,
    pageNo,
    setPageNo,
    sportsTournamentInfo,
    totalPages,
    isGetTournamentLoading,
    searchByName,
    setSearchByName,
    searchByCountry,
    setSearchByCountry,
    searchBySports,
    setSearchBySports,
    sportsListInfo,
    sportsCountriesInfo
  }
}

export default useCountriesListing
