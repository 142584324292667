import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { getAdminAffiliateUsersStart } from "../../../../store/redux-slices/dashboard";
import { getAffiliateProductsReportStart } from "../../../../store/redux-slices/affiliates";
export const useProductsReport = ({ filterState }) => {
    const [orderBy, setOrderBy] = useState('userId')
    const [sort, setSort] = useState('DESC')
    const [over, setOver] = useState(false)
    const [state, setState] = useState([
        {
            startDate: new Date('1923'),
            endDate: new Date(),
            key: 'selection'
        }
    ])
    const [page,setPage] = useState(1)
    const [limit, setLimit] = useState(15)

    const selected = (h) =>
        orderBy === h.value &&
        (h.value === 'category' || h.value === 'activePlayers' || h.value === 'winLoss')
    const navigate = useNavigate()

   
    const dispatch = useDispatch()
    const affiliate = useSelector((state) => state.affiliate)
    useEffect(() => {
       
        dispatch(getAffiliateProductsReportStart())
    }, [])

    useEffect(() => {
       
        dispatch(getAffiliateProductsReportStart({}))
    }, [page])



    // STATIC COUNT  
    const totalPages = Math.ceil(affiliate?.affiliateProductsReport?.length * 3 / limit)
    return {
        state,
        setState,
        orderBy,
        setOrderBy,
        selected,
        sort,
        setSort,
        over,
        setOver,
        loading: false,
        navigate,
        productsReport:{ 
            rows: affiliate?.affiliateProductsReport, // STATIC COUNT /
            count: affiliate?.affiliateProductsReport?.length * 3 // STATIC COUNT /
        },
        page,
        setPage,
        limit,
        setLimit,
        totalPages


    }
}

