import React from "react";

import Button from 'react-bootstrap/Button';
import { Modal as BootStrapModal } from 'react-bootstrap';

export default function Modal(props) {
  const { show, content } = props;

  function getContent() {
    if (typeof content === "function") {
      return content();
    }
    return content;
  }

  if (!show) {
    return null;
  }

  const modalContent = getContent();

  return (
    <BootStrapModal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <BootStrapModal.Header closeButton>
        {/* <BootStrapModal.Title id="contained-modal-title-vcenter">
          BootStrapModal heading
        </BootStrapModal.Title> */}
      </BootStrapModal.Header>
      <BootStrapModal.Body>
        {modalContent}
      </BootStrapModal.Body>
    </BootStrapModal>
  );
}
