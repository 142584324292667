import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { approveWithdrawRequestStart, cancelWithdrawRequestStart, getAllWithdrawRequestStart } from '../../../store/redux-slices/tenantUsers'
import { getDateDaysAgo, formatDateYMD } from '../../../utils/dateFormatter'
import useDidMountEffect from '../../../utils/useDidMountEffect'

import { approveWithdrawRequestAdmin, cancelWithdrawRequestAdmin } from '../../../utils/apiCalls';
import { toast } from '../../../components/Toast'
import { reject } from 'lodash'

const useWithdrawRequestList = ({ type }) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [name, setName] = useState('')
  const [status, setStatus] = useState('')
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedPortal, setSelectedPortal] = useState('')
  const [state, setState] = useState([
    {
      startDate: getDateDaysAgo(10),
      endDate: new Date(),
      key: 'selection'
    }
  ])
  const [paymentProvider, setPaymentProvider] = useState('')
  const { withdrawRequests, loading, cancelWithdrawRequestLoader } = useSelector((state) => state.tenantUsers)
  const isInitialRender = useDidMountEffect()
  const totalPages = Math.ceil(withdrawRequests?.count / limit)
  const isSuperAdmin = location.pathname.includes('super-admin')

  const handleApprove = async ({ userId, withdrawRequestId }) => {
    //dispatch(approveWithdrawRequestStart({ userId, withdrawRequestId }))
    return new Promise (async (resolve, reject) => {
      try {
        const res = await approveWithdrawRequestAdmin({ userId, withdrawRequestId })
        
        dispatch(getAllWithdrawRequestStart({
          type,
          limit,
          page,
          name: name.replace('+', '%2B'),
          status,
          startDate: formatDateYMD(state.map(a => a.startDate)),
          endDate: formatDateYMD(state.map(a => a.endDate)),
          paymentProvider,
          tenantId: selectedPortal,
          adminId: selectedClient
        }))
        resolve(res)
        toast(res.data.data.message, 'success')
      } catch(e) {
        reject(e)
        toast(e?.response?.data?.errors[0]?.description, 'error')
      }
    })

  }

  const handleCancel = async ({ userId, withdrawRequestId, comments }) => {
    //dispatch(cancelWithdrawRequestStart({ userId, withdrawRequestId }))
    return new Promise (async (resolve, reject) => {
      try {
        const res = await cancelWithdrawRequestAdmin({ userId, withdrawRequestId, comments })
        
        dispatch(getAllWithdrawRequestStart({
          type,
          limit,
          page,
          name: name.replace('+', '%2B'),
          status,
          startDate: formatDateYMD(state.map(a => a.startDate)),
          endDate: formatDateYMD(state.map(a => a.endDate)),
          paymentProvider,
          tenantId: selectedPortal,
          adminId: selectedClient
        }))
        resolve(res)
        toast(res.data.data.message, 'success')
      } catch(e) {
        reject(e)
        toast(e?.response?.data?.errors[0]?.description, 'error')
      }
    })
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          dispatch(getAllWithdrawRequestStart({
            type,
            limit,
            page,
            name: name.replace('+', '%2B'),
            status,
            startDate: formatDateYMD(state.map(a => a.startDate)),
            endDate: formatDateYMD(state.map(a => a.endDate)),
            paymentProvider,
            tenantId: selectedPortal,
            adminId: selectedClient
          }))
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [name, paymentProvider])

  useEffect(() => {
    setPage(1)
    dispatch(getAllWithdrawRequestStart({
      type,
      limit,
      page,
      name: name.replace('+', '%2B'),
      status,
      startDate: formatDateYMD(state.map(a => a.startDate)),
      endDate: formatDateYMD(state.map(a => a.endDate)),
      paymentProvider,
      tenantId: selectedPortal,
      adminId: selectedClient
    }))
  }, [limit, status, state, selectedClient, selectedPortal])

  useEffect(() => {
    !isInitialRender && dispatch(getAllWithdrawRequestStart({
      type,
      limit,
      page,
      name: name.replace('+', '%2B'),
      status,
      startDate: formatDateYMD(state.map(a => a.startDate)),
      endDate: formatDateYMD(state.map(a => a.endDate)),
      paymentProvider,
      tenantId: selectedPortal,
      adminId: selectedClient
    }))
  }, [page])

  const resetFilter = () => {
    setLimit(15)
    setPage(1)
    setName('')
    setStatus('')
    setPaymentProvider('')
    setSelectedPortal('')
    setSelectedClient('')
    setState([
      {
        startDate: getDateDaysAgo(10),
        endDate: new Date(),
        key: 'selection'
      }
    ])
  }

  const getStatus = (status) => {
    switch (status) {
      case 0:
        return 'PENDING'
      case 1:
        return 'SUCCESS'
      case 2:
        return 'CANCELLED'
      case 3:
        return 'FAILED'
      case 4:
        return 'ROLLBACK'
      case 5:
        return 'APPROVED'
      case 6:
        return 'REJECTED'
      case 7:
        return 'REQUESTED'
    }
  }

  return {
    limit,
    page,
    name,
    status,
    state,
    paymentProvider,
    withdrawRequests,
    setLimit,
    setPage,
    setName,
    setStatus,
    setState,
    setPaymentProvider,
    loading,
    totalPages,
    selectedPortal,
    setSelectedPortal,
    setSelectedClient,
    selectedClient,
    resetFilter,
    getStatus,
    isSuperAdmin,
    handleApprove,
    handleCancel,
    cancelWithdrawRequestLoader
  }
}

export default useWithdrawRequestList
