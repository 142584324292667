import React, { useState } from "react";
import { Modal } from "@themesberg/react-bootstrap";
import { TRANSACTION_STATUS } from "../../../../SportsTransactions/constants";
import { STATUS_LABEL, STATUS_VALUE } from "../../../../../../utils/constant";
import Button from "../../../../../../components/Button";
import { approveAffiliateWithdrawRequest } from "../../../../../../utils/apiCalls";
import { resolveErrors } from "../../../../../../utils/errorTypes";
import { toast } from "../../../../../../components/Toast";

const ConfirmAffiliateWithdraRequestApprovalModal = ({ status, affiliateWithdrawRequestId, withdrawRequestAffiliate, onSuccess = () => null }) => {
  const [loading, setLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const toggleConfirmModal = () => setShowConfirmModal(!showConfirmModal);

  const handleApproveRequest = async () => {
    setLoading(true);
    try {
      await approveAffiliateWithdrawRequest({ affiliateId: withdrawRequestAffiliate.affiliateId, affiliateWithdrawRequestId });
      setShowConfirmModal(false);
      onSuccess();
      toast("Solicitação de saque aprovada com sucesso!", "success");
    } catch (err) {
      toast(resolveErrors(err?.response?.data?.errors, "Erro ao aprovar solicitação de saque"), "error");
    }
    finally {
      setLoading(false);
    }
  };


  return (<>
    <Button
      variant="m-1 btn w-100 btn-primary"
      disabled={status == TRANSACTION_STATUS.PENDING ? false : true}
      onClick={toggleConfirmModal}
      loading={loading}
    >
      {status == TRANSACTION_STATUS.PENDING ? "Aprovar" : STATUS_LABEL[STATUS_VALUE[status]]}
    </Button>
    <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmar aprovação de saque</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Você tem certeza que deseja confirmar a solicitação de saque de {withdrawRequestAffiliate?.username}?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
          Cancelar
        </Button>
        <Button
          variant="primary"
          onClick={handleApproveRequest}
          loading={loading}
        >
          Aprovar
        </Button>
      </Modal.Footer>
    </Modal>
  </>
  );
};

export default ConfirmAffiliateWithdraRequestApprovalModal;
