import React, { useState } from "react";
import { Form, Row, Col, Table } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import useWithdrawRequestList from "./hooks/useWithdrawRequestList";
import PaginationComponent from "../Pagination";
import DateRangePicker from "../DateRangePicker";
import ClientFilter from "../ClientFilter";
import PortalFilter from "../PortalFilter";
import Trigger from "../OverlayTrigger";

import { InlineLoader } from "../Preloader";
import CancelWithdralRequestModal from "./components/CancelWithdralRequestModal";
import ConfirmApprovalModal from "./components/ConfirmApprovalModal";
import FormatNumber from "../../utils/formatNumber";
import FormatDate from "../../utils/formatDate";
import { STATUS_VALUE, STATUS_LABEL } from "../../utils/constant";
import Button from "../Button";

const ApproveWithdrawRequestButton = ({
  status,
  getStatus,
  userId,
  withdrawRequestId,
  handleApprove,
  User,
}) => {
  const [loading, setLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const toggleConfirmModal = () => setShowConfirmModal(!showConfirmModal);
  const handleApproveRequest = async () => {
    setLoading(true);
    try {
      await handleApprove({ userId, withdrawRequestId });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        variant="m-1 btn w-100 btn-primary"
        disabled={getStatus(status) === "PENDING" ? false : true}
        onClick={toggleConfirmModal}
        loading={loading}
      >
        {getStatus(status) === "PENDING" ? "Aprovar" : getStatus(status)}
      </Button>

      <ConfirmApprovalModal
        show={showConfirmModal}
        setShow={setShowConfirmModal}
        handleApproveRequest={handleApproveRequest}
        User={User}
      />
    </>
  );
};

const CancelWithdrawRequestButton = ({
  status,
  getStatus,
  userId,
  withdrawRequestId,
  handleCancel,
  cancelWithdrawRequestLoader,
}) => {
  const [show, setShow] = useState(false);

  const toggleCancelModal = () => {
    setShow(!show);
  };

  return (
    <>
      <Button
        variant="m-1 btn btn-danger"
        disabled={getStatus(status) === "PENDING" ? false : true}
        onClick={() => toggleCancelModal({ userId, withdrawRequestId })}
      >
        <span>Cancelar</span>
      </Button>
      <CancelWithdralRequestModal
        {...{
          show,
          setShow,
          userId,
          withdrawRequestId,
          handleCancel,
          cancelWithdrawRequestLoader,
        }}
      />
    </>
  );
};

export default ({ type }) => {
  const {
    limit,
    page,
    name,
    status,
    state,
    setState,
    paymentProvider,
    withdrawRequests,
    setLimit,
    setPage,
    setName,
    setStatus,
    setPaymentProvider,
    loading,
    totalPages,
    selectedPortal,
    setSelectedPortal,
    setSelectedClient,
    selectedClient,
    resetFilter,
    getStatus,
    isSuperAdmin,
    handleApprove,
    handleCancel,
    cancelWithdrawRequestLoader,
  } = useWithdrawRequestList({ type });

  return (
    <>
      <div className="section-header-wrap mt-n3 mx-n3">
        <Row>
          <Col>
            <h2 className="title-text">Solicitações de Saque</h2>
          </Col>
        </Row>
      </div>
      <div className="FormsBg">
        <Row>
          <Col xs={3}>
            <Form.Control
              size="sm"
              type="search"
              value={name}
              placeholder="Pesquisar Name,Email"
              onChange={(e) =>
                setName(e.target.value.replace(/[~`!$%^&*#=)()><?]+/g, ""))
              }
            />
          </Col>
          <Col xs={3}>
            <Form.Control
              size="sm"
              type="search"
              value={paymentProvider}
              placeholder="Pesquisar provedores pagamento"
              onChange={(e) =>
                setPaymentProvider(
                  e.target.value.replace(/[~`!$%^&*#=)()><?]+/g, "")
                )
              }
            />
          </Col>

          <Col xs={2}>
            <Form.Select
              size="sm"
              // style={{ maxWidth: '230px' }}
              onChange={(e) => setStatus(e.target.value)}
              value={status}
            >
              <option value="">Todos</option>
              {Object.keys(STATUS_VALUE).map((value) => {
                const label = STATUS_LABEL[STATUS_VALUE[value]]
                return (<option key={label} value={value}>{label}</option>)
              })}
            </Form.Select>
          </Col>
          {isSuperAdmin && (
            <>
              <Col xs={2}>
                <ClientFilter
                  setSelectedClient={setSelectedClient}
                  setSelectedPortal={setSelectedPortal}
                  selectedClient={selectedClient}
                />
              </Col>

              {selectedClient && (
                <Col xs={2}>
                  <PortalFilter
                    setSelectedPortal={setSelectedPortal}
                    selectedPortal={selectedPortal}
                    selectedClient={selectedClient}
                  />
                </Col>
              )}
            </>
          )}

          <Col xs={3}>
            <DateRangePicker state={state} setState={setState} size="sm" />
          </Col>
          <Col xs={1}>
            <div className="d-flex justify-content-end align-item-center">
              <Trigger message="Reset Filters">
                <Button
                  className=""
                  size="sm"
                  onClick={() => {
                    resetFilter();
                  }}
                >
                  <FontAwesomeIcon icon={faRedoAlt} />
                </Button>
              </Trigger>
            </div>
          </Col>
        </Row>
      </div>
      <div className="TableBg mt-4 pt-1">
        <Table striped responsive hover size="sm" className="text-center mt-3">
          <thead className="thead-light">
            <tr>
              {[
                "Usuário",
                "ID da transação",
                /* "E-mail", */
                "Quantidade",
                "Chave PIX",
                "Tipo de chave PIX",
                "Status",
                "Criado em",
                "Atualizado em",
                "Ações",
              ].map((c) => (
                <th
                  key={c}
                  className={`${["Amount"].includes(c) && withdrawRequests?.count > 0
                    ? "text-right"
                    : ""
                    }`}
                >
                  {c}
                </th>
              ))}
            </tr>
          </thead>

          <tbody id={loading ? "cover-spin" : ""}>
            {Boolean(withdrawRequests) &&
              !loading &&
              withdrawRequests?.rows?.map(
                ({
                  withdrawRequestId,
                  userId,
                  status,
                  amount,
                  currency,
                  transactionBankingUUID,
                  actionableType,
                  paymentProvider,
                  createdAt,
                  updatedAt,
                  email,
                  User,
                  transactionBanking,
                }) => {
                  return (
                    <tr key={withdrawRequestId}>
                      <td className="text-left">
                        <a className="text-primary cursor-pointer" href={`/admin/users/details/${User.userId}`} target="_blank" >
                          {User?.username}
                        </a>
                      </td>
                      <td>{transactionBankingUUID}</td>
                      {/* <td>{email}</td> */}
                      <td className="text-right">
                        {FormatNumber.currency(amount, currency)}
                      </td>
                      <td className="">
                        {transactionBanking?.metaData?.pix_key}
                      </td>
                      <td className="">
                        {transactionBanking?.metaData?.pix_key_type}
                      </td>
                      <td><span className='w-100' data-badge={String(STATUS_VALUE[status]).toLowerCase()}>{STATUS_LABEL[STATUS_VALUE[status]]}</span></td>
                      <td>{FormatDate.default(createdAt)}</td>
                      <td>{FormatDate.toDateHour(updatedAt)}</td>
                      <td>
                        <div className="d-flex gap-2">
                          <ApproveWithdrawRequestButton
                            {...{
                              status,
                              getStatus,
                              userId,
                              withdrawRequestId,
                              handleApprove,
                              User,
                            }}
                          />

                          {getStatus(status) === "PENDING" ? (
                            <CancelWithdrawRequestButton
                              {...{
                                status,
                                getStatus,
                                userId,
                                withdrawRequestId,
                                cancelWithdrawRequestLoader,
                                handleCancel,
                              }}
                            />
                          ) : null}
                        </div>
                      </td>
                    </tr>
                  );
                }
              )}

            {withdrawRequests?.count === 0 && !loading && (
              <tr>
                <td colSpan={9} className="text-danger text-center">
                  Nenhuma solicitação de saque
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <div className='row px-3 mt-3'>
          <div className='col-sm-6 col-12 mb-3 mb-sm-0 justify-content-end justify-content-sm-start  d-flex align-items-center'>Total de registros ({withdrawRequests?.count || 0})</div>
          <div className='col-sm-6 col-12 d-flex justify-content-end'>
            {withdrawRequests?.count !== 0 && !loading && (
              <PaginationComponent
                className="m-0"
                page={withdrawRequests?.count < page ? setPage(1) : page}
                totalPages={totalPages}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
