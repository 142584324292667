export const transformGameCategoryData = (responseData) => {
  const { data: { casinoCategories } } = responseData
  const transformedData = {
    data: {
      casinoCategories: {
        count: casinoCategories.count,
        rows: []
      }
    }
  }

  for (let index = 0; index < casinoCategories.rows.length; index++) {
    const category = casinoCategories.rows[index]
    let jname
    try {
      jname = JSON.parse(category.name)
    } catch (error) {
      jname = { EN: category.name }
    }
    transformedData.data.casinoCategories.rows.push({ ...category, jname })
  }


  return transformedData
}