import { Col, InputGroup, Row, Form as BForm, Button, Spinner } from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import { adminProfileSchema } from './schema'
import { BoxContainer, NoDataContainer } from './style'
import { InlineLoader } from '../Preloader'

const Overview = ({
  details,
  isTenant,
  setEditable,
  editable,
  updateData,
  constant,
  loading,
  isGetLoading
}) => {
  return (
    <>
      <BoxContainer className='FormsBg'>
        {
          isGetLoading ?
            <NoDataContainer>
              <InlineLoader />
            </NoDataContainer>
            :
            <>
				{ details &&
					<Formik
						enableReinitialize
						initialValues={{
						firstName: details?.firstName,
						lastName: details?.lastName,
						email: details?.email,
						adminUsername: details?.adminUsername || '',
						phone: isTenant ? details?.phone : '',
						role: details?.SuperadminRole?.name,
						agentName: details?.agentName || '',
						group: details?.group || ''
						}}
						validationSchema={adminProfileSchema}
						onSubmit={(
						formValues
						) => {
						updateData({
							...formValues,
							id: details?.adminUserId
							// superAdminId: isTenant ? '' : details?.superAdminUserId,
							// adminUserId: isTenant ? details?.adminUserId : ''
						})
						}}
					>
						{({ values, handleChange, handleSubmit, handleBlur }) => {
						return (
							<Form>
							<Row>
								{details && constant.map(({ key, value, subValue, edit }, index) => {
								return (
									<Col md={6} sm={12} className='mb-3' key={index} hidden={(details?.adminRoleId === 1 || details?.superRoleId === 1) ? key === 'Group' : false}>
									<div>
										<label className='form-label'>{key || 'N/A'}</label>
										<span className='mb-0'>
										{key === 'Status'
											? (details[value] ? 'Active' : 'In-Active')
											: subValue
											? <p className='DefaultRole'>{details?.[value]?.[subValue] === 'Super Admin' ? 'Admin' : details?.[value]?.[subValue]}</p>
											: (
												<>
												<InputGroup>
													<BForm.Control
													type='text'
													name={value}
													disabled={!edit || !editable}
													value={values?.[value]}
													onChange={handleChange}
													onBlur={handleBlur}
													/>
												</InputGroup>

												<ErrorMessage
													component='div'
													name={value}
													className='text-danger'
												/>
												</>
												)}
										</span>
									</div>
									</Col>
								)
								})}
							</Row>
							<div className='mt-4 mb-3'>
								<Button
								variant='m-1 btn btn-primary'
								onClick={handleSubmit}
								className='ml-2'
								hidden={!editable}
								>
								Salvar
								{loading && (
									<Spinner
									as='span'
									animation='border'
									size='sm'
									role='status'
									aria-hidden='true'
									/>
								)}
								</Button>
							</div>
							</Form>
						)
						}}
						</Formik>
				}
				<div className='text-right'>
					<Button
						variant='m-1 btn btn-primary'
            hidden={editable}
						onClick={() => {
						setEditable(true)
						}}
					>Editar
					</Button>
				</div>
            </>
        }
        
      </BoxContainer>
    </>
  )
}

export default Overview
