import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { addReviewStart, getAllReviewStart, getReviewByIdStart, updateReviewStart } from '../../../store/redux-slices/fetchData'
import useDidMountEffect from '../../../utils/useDidMountEffect'

const useReviewManagement = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isInitialRender = useDidMountEffect()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [show, setShow] = useState(false)
  const [data, setData] = useState()
  const [type, setType] = useState('')
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedPortal, setSelectedPortal] = useState('')
  const [selectedClientL, setSelectedClientL] = useState('')
  const [selectedPortalL, setSelectedPortalL] = useState('')

  const [search, setSearch] = useState('')
  const [active, setActive] = useState('')

  const { reviewList, reviewDetail, loading, formLoaded } = useSelector((state) => state.fetch)
  const totalPages = Math.ceil(reviewList?.count / limit)

  const handleClose = () => setShow(false)

  const handleShow = (type, data) => {
    setType(type)
    setData(data)
    setShow(true)
  }

  useEffect(() => {
    dispatch(getAllReviewStart({ limit, pageNo: page, search, status: active, tenantId: selectedPortalL }))
  }, [limit, page, selectedPortalL, active])

  useEffect(() => {
    if (!formLoaded && !isInitialRender) dispatch(getAllReviewStart({ limit, pageNo: page, search, status: active, tenantId: selectedPortalL }))
  }, [formLoaded])

  useEffect(() => {
    if (!isInitialRender) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(getAllReviewStart({ limit, pageNo: page, search, status: active, tenantId: selectedPortalL }))
      }, 1000)

      return () => clearTimeout(delayDebounceFn)
    }
  }, [search])

  const updateReviewHandler = (data, reviewId) => {
    dispatch(updateReviewStart({ data: { ...data, reviewId, rating: Number(data.rating) } }))
  }

  const addReviewHandler = (res) => {
    const data = { ...res, tenantIds: [parseInt(selectedPortal)], rating: Number(res.rating) }
    dispatch(addReviewStart({ data }))
  }

  return {
    limit,
    setLimit,
    page,
    setPage,
    show,
    data,
    type,
    reviewList,
    totalPages,
    handleClose,
    handleShow,
    loading,
    addReviewHandler,
    updateReviewHandler,
    selectedClient,
    selectedPortal,
    setSelectedClient,
    setSelectedPortal,
    selectedClientL,
    selectedPortalL,
    setSelectedClientL,
    setSelectedPortalL,
    search,
    setSearch,
    active,
    setActive
  }
}

export default useReviewManagement
