import React, { Suspense, useState } from "react";
import SimpleBar from "simplebar-react";
import { CSSTransition } from "react-transition-group";
import { Nav, Button, Navbar } from "@themesberg/react-bootstrap";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";


import { getItem } from "../../utils/storageUtils";
import FormatNumber from "../../utils/formatNumber";
import useRole from "../../hooks/useRole";
import { avatarPlaceholder, getTemporaryAffiliateAvatar } from "../../utils/getAvatarImage";
const SuperAdminNavItems = React.lazy(() => import("./components/SuperAdminNavItems"));
// const TenantNavItems = React.lazy(() => import('./components/TenantNavItems'))
const AffiliateNavItems = React.lazy(() => import('./components/AffiliateNavItems'))

const UserAvatar = ({ adminDetails }) => {
  return <img
    // src="/avatar-img.png"
    src={adminDetails.affiliate ? getTemporaryAffiliateAvatar(adminDetails.affiliate) : "/avatar-img.png"}
    onError={(e) => {
      e.target.src = avatarPlaceholder(adminDetails.firstName || adminDetails.affiliate.firstName, adminDetails.lastName || adminDetails.affiliate.lastName)
    }}
    className="img-fluid"
    alt={adminDetails.affiliate?.username || adminDetails.firstName}
  />
}

export default (props = {}) => {
  const [show, setShow] = useState(false);
  const { SuperAdmin, Admin, Affiliate } = useRole();
  const showClass = show ? "show" : "";

  let { role, adminRole, tenantAdminRole } = useSelector(
    (state) => state.login
  );
  const { adminRoleId, superAdminUser, adminDetails } = useSelector(
    (state) => state.admins
  );

  if (!role) {
    role = getItem("role");
  }

  const roleData = adminRole
    ? adminRole?.find(
      (val) =>
        val.superRoleId !== 1 && val.superRoleId === parseInt(adminRoleId)
    )?.name
    : tenantAdminRole?.find(
      (val) =>
        val.adminRoleId !== 1 &&
        val.adminRoleId === parseInt(superAdminUser?.adminRoleId)
    )?.name;

  const onCollapse = () => setShow(!show);

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-primary px-4 d-md-none"
      >
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block text-white pb-4`}
        >
          <span className="position-absolute end-0 top-0 p-3 z-2 d-md-none d-block" onClick={onCollapse}>X</span>
          <div className="sidebar-inner px-4 pt-3 pb-2">
            <Nav className="flex-column pt-3 pt-md-0">
              <div className="w-100 d-flex flex-column align-items-center">
                <div className="avatar-wrap">
                  <div className="avatar-box">
                    <UserAvatar {...{ adminDetails }} />
                  </div>
                </div>
                <h5 className="d-flex align-items-center m-auto mb-2 text-ellipsis">
                  {`${(adminDetails.firstName || adminDetails.affiliate.firstName)} ${(adminDetails.lastName || adminDetails.affiliate.lastName)}` || role}&nbsp;
                </h5>
                {(SuperAdmin || Admin) && (superAdminUser?.email || adminDetails?.email) && (
                  <span style={{ fontSize: "15px", textAlign: "center" }} className="text-ellipsis">
                    {superAdminUser?.email || adminDetails?.email}
                  </span>
                )}
                {(role === "Affiliate" && adminDetails.affiliate) && (
                  <div style={{ fontSize: "15px", textAlign: "center" }} className="text-ellipsis">
                    <span>Saldo</span> <span>{FormatNumber.currency(adminDetails.affiliate?.affiliateWallet?.amount || 0)}</span>
                  </div>
                )}
              </div>

              <hr />

              {(SuperAdmin || Admin) && (
                <Suspense fallback={null}>
                  <SuperAdminNavItems setShow={setShow} />
                </Suspense>
              )}

              {Affiliate && (
                <Suspense fallback={null}>
                  <AffiliateNavItems setShow={setShow} />
                </Suspense>
              )}

              {/* {role === 'Admin' &&
                <Suspense fallback={null}>
                  <TenantNavItems setShow={setShow} />
                </Suspense>} */}
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
