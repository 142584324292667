import React from 'react'
import { Tabs, Tab, Row, Col } from '@themesberg/react-bootstrap'
import Overview from './components/Overview'
import useUserDetails from './useUserDetails'
import Preloader from '../../../components/Preloader'
import useCheckPermission from '../../../utils/checkPermission'
import CasinoTransactionsList from '../../../components/CasinoTransactionList'
import CasinoTransactions from './components/CasinoTransactions';
import TransactionBanking from './components/TransactionBanking';
import classNames from 'classnames'

const PlayerDetails = (props) => {
  const {
    selectedTab,
    associatedPlayerDetails,
    loading,
    basicInfo,
    kycInfo,
    contactInfo,
    setSelectedTab,
  } = useUserDetails()
  // const { isHidden } = useCheckPermission()

  return (
    <>
      {loading
        ? (
          <Preloader />
        )
        : (
          <>
            <div className='section-header-wrap mt-n3 mx-n3'>
              <Row>
                <Col lg={8} xs={12}>
                  <div className='d-flex align-items-center'>
                    <h2 className='title-text mb-1'>Jogador:&nbsp;</h2>
                    <h5 className='mb-0'>
                      {classNames(associatedPlayerDetails?.firstName, associatedPlayerDetails?.lastName)}
                    </h5>
                  </div>
                </Col>
              </Row>
            </div>
            <Tabs
              activeKey={selectedTab}
              onSelect={(tab) => setSelectedTab(tab)}
              className='nav-light'
              mountOnEnter
              unmountOnExit
            >
              <Tab eventKey='overview' title='Overview'>
                <div className='mt-3'>
                  <Overview
                    basicInfo={basicInfo}
                    kycInfo={kycInfo}
                    contactInfo={contactInfo}
                    userLimits={associatedPlayerDetails?.userLimit}
                    user={associatedPlayerDetails}
                  />
                </div>
              </Tab>

              {
                <Tab eventKey='casino-transactions' title='Histórico de apostas'>
                  <div className='mt-3'>
                    <CasinoTransactions />

                  </div>
                </Tab>}

              {
                <Tab eventKey='transactions-banking' title='Transações'>
                  <div className='mt-3'>
                    <TransactionBanking />
                  </div>
                </Tab>}
            </Tabs>
          </>)}
    </>
  )
}

export default PlayerDetails
