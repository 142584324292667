import React from 'react'
import { Row, Card, ListGroup, Col } from '@themesberg/react-bootstrap'
import { permissionIcons } from './constants'

const Permission = ({
  details,
  permissionLabel
}) => {
  return (
    <>
      <div className='FormsBg'>
        <Row className='my-n2 pt-3'>
          {details &&
          Object.keys(details?.userPermission?.permission).map((key, index) => {
            // const Icons = permissionIcons().CMS;
            return (
              details?.userPermission?.permission[key]?.length > 0 &&
                <Col lg={3} md={4} sm={6} className='mb-4' key={index}>
                  <Card className='permissions-card'>
                    <Card.Header className='fw-bold'>
                      <span className='icon'>
                        {permissionIcons()?.[key]}
                      </span>
                      <span className='text'>{key}</span>
                    </Card.Header>
                    <ListGroup variant='flush'>
                      {details?.userPermission?.permission[key].map((permissionKey, indx) =>
                        <ListGroup.Item key={indx} className='d-flex justify-content-between align-items-center py-1 px-3'>
                          <small>{permissionLabel(permissionKey)}</small>
                          <img src='/images/check.svg' className='check-img' alt='Check' />
                        </ListGroup.Item>)}
                    </ListGroup>
                  </Card>
                </Col>
            )
          })}
        </Row>
      </div>
    </>
  )
}

export default Permission
