import * as Yup from 'yup'
import FormatNumber from '../../../utils/formatNumber'

export const withdrawalValuesLimit = {
  CPF: 11,
  PHONE: 14,
}

export const withdrawalValuesPlacehgolder = {
  CPF: '95330756006',
  PHONE: '+5511999887766',
  EMAIL: 'email@mail.com',
  EVP: 'f5dbd56e-77e0-4a83-8050-56e25efc85f8',
}

export const withdrawalInitialValues = {
  value: null,
  pix_key_type: null,
  pix_key: null,
  paymentProviderId: null,
}


export const withdrawalSchema = (settings = {}, affiliateWalletAmount, pix_key_type = null) => {
  const maxValue = settings.affiliateMaxWithdrawAmount && (settings.affiliateMaxWithdrawAmount <= affiliateWalletAmount) ? settings.affiliateMaxWithdrawAmount : affiliateWalletAmount;
  const minValue = settings.affiliateMinWithdrawAmount;

  const minMaxMessage = `Por favor adicione no mínimo ${FormatNumber.currency(minValue)} e no máximo ${FormatNumber.currency(maxValue)}`
  // console.log(pix_key_type)
  return Yup.object().shape({
    value: Yup.number("Informe o valor").min(minValue, minMaxMessage).max(maxValue, minMaxMessage).required('O valor é obrigatório'),
    pix_key_type: Yup.string().required('Chave pix é obrigatório'),
    ...(pix_key_type === "CPF" ? {
      pix_key: Yup.string()
        .min(withdrawalValuesLimit['CPF'], `Informe um CPF válido`)
        .max(withdrawalValuesLimit['CPF'], `Informe um CPF válido`)
        .matches(/^(?!(\d)\1{2}\1{3}\1{3}\1{2}$)\d{3}\d{3}\d{3}\d{2}$/, 'Informe um CPF válido')
        .required('Chave pix é obrigatório')
    } : {}
    ),
    ...(pix_key_type === "EMAIL" ? {
      pix_key: Yup.string()
        .email('Informe um email válido')
        .required('Chave pix é obrigatório')
    } : {}
    ),
    ...(pix_key_type === "PHONE" ? {
      pix_key: Yup.string()
        .matches(/([+55]{3})([1-9]{2})([1-9]{1})([0-9]{4})([0-9]{4})/, "Informe um número de telefone válido")
        .required('Chave pix é obrigatório')
    } : {}
    ),
    ...(pix_key_type === "EVP" ? {
      pix_key: Yup.string()
        .matches(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/, "Chave pix randômica inválida!")
        .required('Chave pix é obrigatório')
    } : {}
    ),
    paymentProviderId: Yup.number().required('O id do provedor de pagamento é obrigatório'),
  })
}
