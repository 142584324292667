export const tableHeaders = [
  'ID',
  'E-mail do usuário',
  'Nome do game',
  'Tipo de ação',
  'Quantia',
  'Dinheiro bônus',
  'Status',
  'Data de criação'
]

export const transactionType = [
  { label: 'Select Type', value: 'all' },
  { label: 'Bet', value: 'bet' },
  { label: 'Win', value: 'win' },
  { label: 'RollBack', value: 'rollback' },
  { label: 'Pre RollBack', value: 'rollbackbeforebetwin' },
  { label: 'Free Spin', value: 'freespins' }
]

export const statusType = [
  { label: 'All', value: 'all' },
  { label: 'Pending', value: 'pending' },
  { label: 'Complete', value: 'completed' },
  { label: 'Failed', value: 'failed' },
  { label: 'RollBack', value: 'rollback' }
]
