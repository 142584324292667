import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { formatDateYMD, getDateDaysAgo } from '../../../../utils/dateFormatter'
import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { getAllCurrenciesStart } from '../../../../store/redux-slices/currencies'
import { getSuperAdminCasinoTransactionsStart, getSuperAdminSportsTransactionsStart } from '../../../../store/redux-slices/superAdminTransactions'
import { getLoginToken } from '../../../../utils/storageUtils'

const useCasinoTransactionsList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isInitialRender = useDidMountEffect()

  const [selectedCurrency, setSelectedCurrency] = useState('')
  const [selectedActionType, setSelectedActionType] = useState('all')
  const [selectedAction, setSelectedAction] = useState('all')
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [status, setStatus] = useState('all')
  const [search, setSearch] = useState('')
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedPortal, setSelectedPortal] = useState('')
  const { allCurrencies } = useSelector((state) => state.currencies)
  const { loading,sportTransactions } = useSelector((state) => state.superAdminTransactions)
  const totalPages = Math.ceil(sportTransactions?.count / limit)
  const userData=window.location?.pathname?.split("/")
  let  userId=userData &&  userData[userData.length-1]
  if(window.location.pathname==="/admin/sports-transactions"){
    userId='';
  }
  const [state, setState] = useState([
    {
      startDate: getDateDaysAgo(10),
      endDate: new Date(),
      key: 'selection'
    }
  ])
  useEffect(() => {
    dispatch(getAllCurrenciesStart({ limit: '', pageNo: '' }))
  }, [])

  useEffect(() => {
  }, [window.location.pathname])

  useEffect(() => {
    !isInitialRender && dispatch(getSuperAdminSportsTransactionsStart({
      limit,
      pageNo: page,
      email: search.replace('+', '%2B'),
      startDate: formatDateYMD(state.map(a => a.startDate)),
      endDate: formatDateYMD(state.map(a => a.endDate)),
      currencyCode: selectedCurrency,
      transactionType: selectedAction,
      adminId: selectedClient,
      status,
      userId: userId?userId:'',
      actionType:selectedActionType

    }))
  }, [page])

  useEffect(() => {
    setPage(1)
    dispatch(getSuperAdminSportsTransactionsStart({
      limit,
      pageNo: 1,
      email: search.replace('+', '%2B'),
      startDate: formatDateYMD(state.map(a => a.startDate)),
      endDate: formatDateYMD(state.map(a => a.endDate)),
      currencyCode: selectedCurrency,
      transactionType: selectedAction,
      adminId: selectedClient,
      status,
      userId: userId?userId:'',
      actionType:selectedActionType
    }))
  }, [limit, selectedPortal, selectedCurrency, selectedAction, state, status, selectedClient,search,selectedActionType])


  const getCsvDownloadUrl = () =>
  `${process.env.REACT_APP_API_URL}/api/admin/casino/sports-transactions?csvDownload=truelimit=${limit}&pageNo=${page}&startDate=${formatDateYMD(state.map(a => a.startDate))}&endDate=${formatDateYMD(state.map(a => a.endDate))}&currencyCode=${selectedCurrency}&transactionType=${selectedAction}&status=${status}&email=${search}&adminId=${selectedClient}&token=${getLoginToken()}`
    
  return {
    allCurrencies,
    setSelectedCurrency,
    setSearch,
    setLimit,
    setPage,
    totalPages,
    limit,
    page,
    setSelectedAction,
    navigate,
    selectedCurrency,
    selectedAction,
    search,
    state,
    setState,
    sportTransactions,
    loading,
    status,
    setStatus,
    selectedClient,
    selectedPortal,
    setSelectedClient,
    setSelectedPortal,
    getCsvDownloadUrl,
    selectedActionType,
     setSelectedActionType
  }
}

export default useCasinoTransactionsList
