export const bannerType = [
  // { label: 'Home Background', value: 'homeBackground' },
  { label: 'Home Banner', value: 'homeBanner' },
  // { label: 'Casino Background', value: 'casinoBackground' },
  { label: 'Casino Banner', value: 'casinoBanner' },
  { label: 'Favourite Banner', value: 'favouriteBanner' },
  // { label: 'Promotion Background', value: 'promotionsBackground' },
  // { label: 'Promotion Banner', value: 'promotionsBanner' },
  // { label: 'Loyalty Background', value: 'loyaltyBackground' },
  // { label: 'Loyalty Banner', value: 'loyaltyBanner' },
  // { label: 'Cashback Background', value: 'cashbackBackground' },
  // { label: 'Cashback Banner', value: 'cashbackBanner' }
]
