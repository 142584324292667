import * as Yup from "yup";
import FormatNumber from "../../../../utils/formatNumber";
import { ROLLOVER_TYPE, ROLLOVER_TYPE_VALUE } from "../../../../utils/constant";

const selectOptionDefault = [{ label: "Selecione", value: 0 }];

const booleanSelectOptions = (yes = "Sim", no = "Não") => [
  { label: "Selecione", value: null },
  { label: yes, value: true },
  { label: no, value: false }
]

const picKeysSelectOptions = [
  {
    label: "CPF",
    value: "CPF"
  },
  {
    label: "E-mail",
    value: "EMAIL"
  },
  {
    label: "Telefone",
    value: "PHONE"
  },
  {
    label: "Chave Randômica",
    value: "EVP"
  }
]

const rolloverOptions = [...selectOptionDefault, ...Object.values(ROLLOVER_TYPE).map(value => ({ value, label: ROLLOVER_TYPE_VALUE[value] }))];

export const siteConfigTransactionsFields = [

  { title: "Usuário" },

  { label: "Valor minímo de depósito", name: "minDepositAmount", type: "currency" },
  { label: "Valor máximo de depósito", name: "maxDepositAmount", type: "currency" },
  //{ label: "Valor máximo do (primeiro) depósito", name: "maxFirstDepositAmount", type: "currency" },
  { label: "Valor minímo de saque", name: "minWithdrawAmount", type: "currency" },
  { label: "Valor máximo de saque", name: "maxWithdrawAmount", type: "currency" },
  { label: "Valor máximo para saque automático", name: "maxAmountAutoWithdraw", type: "currency" },

  { label: "Saque automático", name: "enabledAutoWithdraw", type: "select", options: booleanSelectOptions("Habilitado", "Desabilitado") },
  { label: "Saques por dia", name: "withdrawAllowedPerDay", type: "number", disabled: true, message: "A liberação de saques por dia depende dos provedores de pagamento!" },
  { label: "Chaves pix permitidas", name: "pixKeysAllowed", type: "select", options: picKeysSelectOptions, isMulti: true },
  { label: "Rollover", name: "rolloverType", type: "select", options: rolloverOptions },
  { label: "Multiplicador do Rollover", name: "rolloverMultiplier", type: "number", step: 0.5 },

  { title: "Afiliado" },

  { label: "Valor minímo de saque", name: "affiliateMinWithdrawAmount", type: "currency" },
  { label: "Valor máximo de saque", name: "affiliateMaxWithdrawAmount", type: "currency" },
  { label: "Valor máximo para saque automático", name: "affiliateMaxAmountAutoWithdraw", type: "currency" },
  { label: "Saque automático", name: "affiliateEnabledAutoWithdraw", type: "select", options: booleanSelectOptions("Habilitado", "Desabilitado") },
  { label: "Chaves pix permitidas", name: "affiliatePixKeysAllowed", type: "select", options: picKeysSelectOptions, isMulti: true },

  { title: "Plataforma" },

  { label: "Taxas administrativas (em %)", name: "administrativeCommission", type: "number", step: 0.5, message: "Total das taxas administrativas, Ex: Provedor de Pagamento e outras despesas." },
]

export const siteConfigTransactionsFormInitialValues = (values = {}) => {
  return ({
    /* USER */
    /** Valor minímo de depósito */
    minDepositAmount: values.minDepositAmount,
    /** Valor máximo de depósito */
    maxDepositAmount: values.maxDepositAmount,
    /** Valor máximo do primeiro depósito */
    maxFirstDepositAmount: values.maxFirstDepositAmount,
    /** Valor minímo de saque */
    minWithdrawAmount: values.minWithdrawAmount,
    /** Valor máximo de saque */
    maxWithdrawAmount: values.maxWithdrawAmount,

    /** Permitir rolover apenas no primeiro depósito */
    rolloverType: values.rolloverType,
    /** Porcentagem do rollover no depósito */
    rolloverMultiplier: values.rolloverMultiplier,

    /** Quantidade de saques permitidos por dia */
    withdrawAllowedPerDay: 1,
    /** Habilitar ou desabilitar saque automático */
    enabledAutoWithdraw: values.enabledAutoWithdraw,
    /** Valor máximo para saque automático */
    maxAmountAutoWithdraw: values.maxAmountAutoWithdraw,
    /** Chaves pix permitidas */
    pixKeysAllowed: values.pixKeysAllowed,

    /* AFFILIATE */
    affiliateMaxWithdrawAmount: values.affiliateMaxWithdrawAmount,
    affiliateMinWithdrawAmount: values.affiliateMinWithdrawAmount,
    affiliatePixKeysAllowed: values.affiliatePixKeysAllowed,
    affiliateMaxAmountAutoWithdraw: values.affiliateMaxAmountAutoWithdraw,
    affiliateEnabledAutoWithdraw: values.affiliateEnabledAutoWithdraw,

    /* PLATFORM */
    /** Taxa administrativa */
    administrativeCommission: values.administrativeCommission
  })
}

export const siteConfigTransactionsFormSchema = Yup.object().shape({
  /* USER */
  minDepositAmount: Yup.number().required('Valor minímo de depósito é obrigatório').min(1, `Valor minímo de ${FormatNumber.currency(1)}`),
  maxDepositAmount: Yup.number().required('Valor máximo de depósito é obrigatório').min(1, `Valor minímo de ${FormatNumber.currency(1)}`),
  //maxFirstDepositAmount: Yup.number().required('Valor máximo do primeiro depósito é obrigatório'),
  minWithdrawAmount: Yup.number().required('Valor minímo de saque é obrigatório').min(1, `Valor minímo de ${FormatNumber.currency(1)}`),
  maxWithdrawAmount: Yup.number().required('Valor máximo de saque é obrigatório').min(1, `Valor minímo de ${FormatNumber.currency(1)}`),
  rolloverType: Yup.number().required("O tipo de rollover é obrigatório").min(1, "Selecione uma opção"),
  rolloverMultiplier: Yup.number().min(0, "O valor minímo é 0").required("O valor de multiplicador do rollover é obrigatório"),
  withdrawAllowedPerDay: Yup.number(),
  enabledAutoWithdraw: Yup.boolean().required('Selecione o estado do saque automático'),
  maxAmountAutoWithdraw: Yup.number().required('Valor máximo para saques automáticos é obrigatório').min(1, `Valor minímo de ${FormatNumber.currency(1)}`),
  pixKeysAllowed: Yup.array().of(Yup.object().shape({
    label: Yup.string(),
    value: Yup.string(),
  })).min(1, 'Escolha no minímo 1 tipo de chave pix').required('Escolha no minímo 1 tipo de chave pix'),

  /* AFFILIATE */
  affiliateMinWithdrawAmount: Yup.number().required('Valor minímo de saque é obrigatório').min(1, `Valor minímo de ${FormatNumber.currency(1)}`),
  affiliateMaxWithdrawAmount: Yup.number().required('Valor máximo de saque é obrigatório').min(1, `Valor minímo de ${FormatNumber.currency(1)}`),
  affiliateMaxAmountAutoWithdraw: Yup.number().required('Valor máximo para saques automáticos é obrigatório').min(1, `Valor minímo de ${FormatNumber.currency(1)}`),
  affiliateEnabledAutoWithdraw: Yup.boolean().required('Selecione o estado do saque automático'),
  affiliatePixKeysAllowed: Yup.array().of(Yup.object().shape({
    label: Yup.string(),
    value: Yup.string(),
  })).min(1, 'Escolha no minímo 1 tipo de chave pix').required('Escolha no minímo 1 tipo de chave pix'),

  /* PLATFORM */
  administrativeCommission: Yup.number(),
})