import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getReferralManagementStart, updateReferralManagementStart } from '../../../../store/redux-slices/referralManagement'

const useReferralManagement = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { referralManagement, loading } = useSelector((state) => state.referralManagement)
  const handelSetClick = (formValues) => {
    const FormData = require('form-data');
    let data = new FormData();
    data.append('promotionTitle', '{"EN":"Referral"}');
    data.append('bonusType', 'referral');
    // data.append('validFrom', '2024-04-22');
    // data.append('validTo', '2024-04-23');
    // data.append('quantity', '0');
    data.append('daysToClear', formValues.daysToClear);
    data.append('wageringRequirementType', 'bonus');
    data.append('depositBonusPercent', '0');
    data.append('isActive',formValues.isActive);
    data.append('visibleInPromotions', 'false');
    data.append('isSticky', 'false');
    data.append('wageringMultiplier', formValues.wageringMultiplier || '0');
    data.append('timePeriod', '1');
    data.append('betLevel', '1');
    data.append('description', '{"EN":""}');
    data.append('termCondition', '{"EN":""}');
    data.append('minimumDeposite', formValues.minimumDeposite);
    data.append('referralAmount', formValues.referralAmount);
    dispatch(updateReferralManagementStart(data))
  }
  useEffect(() => {
    dispatch(getReferralManagementStart())
  }, [])

  return {
    navigate,
    loading,
    referralManagement,
    handelSetClick
  }
}

export default useReferralManagement
