import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { getAdminAffiliateUsersStart } from "../../../../store/redux-slices/dashboard";
const useActivePlayers = ({ playerType,filterState }) => {
    const [orderBy, setOrderBy] = useState('userId')
    const [sort, setSort] = useState('DESC')
    const [over, setOver] = useState(false)
    const [state, setState] = useState([
        {
            startDate: new Date('1923'),
            endDate: new Date(),
            key: 'selection'
        }
    ])
  

    const selected = (h) =>
        orderBy === h.value &&
        (h.value === 'email' || h.value === 'userId' || h.value === 'username')
    const navigate = useNavigate()

    //NOTE: HARDCODE STATIC DATA
    const data = {
        "rows": [
            {
                "userId": 1,
                "email": "user1@example.com",
                "isActive": true,
                "username": "user1",
                "phone": "1234567890",
                "kycStatus": "VERIFIED",
                "isInternalUser": false,
                "affiliate": "affiliate1",
                "affiliateStatus": "active",
                "sumsubKycStatus": null,
                "kycMethod": 2,
                "dateOfBirth": "1990-01-01",
                "fullName": "Test"
            },
            {
                "userId": 2,
                "email": "user2@example.com",
                "isActive": false,
                "username": 'qwertyu',
                "phone": "0987654321",
                "kycStatus": "PENDING",
                "isInternalUser": true,
                "affiliate": "affiliate2",
                "affiliateStatus": "inactive",
                "sumsubKycStatus": null,
                "kycMethod": 2,
                "dateOfBirth": "1985-05-15",
                "fullName": "Test"

            }
        ]
    }
    const dispatch = useDispatch()
    // useEffect(() => {
    //     dispatch(getAdminAffiliateUsersStart({
    //         orderBy,
    //         sort,
    //         playerType,
    //         search:filterState.emailNameUserName
    //     }))
    // }, [playerType , filterState])
 
    
    const { adminAffiliateList } = useSelector((state) => state.dashboard)
    return {
        state,
        setState,
        orderBy,
        setOrderBy,
        selected,
        sort,
        setSort,
        over,
        setOver,
        loading: false,
        navigate,
        userData: adminAffiliateList || {
            rows: [],
        },
        
        
    }
}

export default useActivePlayers