import React, { useEffect, useState } from "react";
import {
  Table,
  Form
} from '@themesberg/react-bootstrap'
import PaginationComponent from '../../../components/Pagination'
import { NoDataContainer } from "./style";
import DivLoader from "../../../components/Loader/divLoader";
import { useDispatch, useSelector } from 'react-redux'
import { updateStatusStart, uploadImageReset } from '../../../store/redux-slices/sportsBook'
import { Button, Dropdown } from "react-bootstrap";
import { ActionContainer } from "../SportsList/style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import ImageUploader from "../ImageUploader";
import Modal from "../../Modal/Modal";
import useCheckPermission from '../../../utils/checkPermission'
import ActionMenu from "./ActionMenu";

const SportsListTable = (props) => {
  const {
    sportsCountriesInfo,
    pageNo,
    setPageNo,
    totalPages,
    limit,
    setLimit,
    isGetCountriesLoading,
    searchByName
  } = props;

  const dispatch = useDispatch()
	const { isHidden } = useCheckPermission()
  const [show, setShow] = useState(false);
  const [countryId, setCountryId] = useState(null);

  const { uploadImgSuccess } = useSelector((state) => state.sportsBook)

  useEffect(() => {
    if (uploadImgSuccess) {
      setShow(false)
      dispatch(uploadImageReset());
    }
  }, [uploadImgSuccess])

  const handleStatusChange = (sportCountryId, status) => {
    dispatch(updateStatusStart({ sportCountryId, code: 'SPORTCONTRY', status: !status, search: searchByName, limit, pageNo }))
  }

  return (
    <div className='TableBg mt-4 pt-1'>
      <Table striped responsive hover size='sm' className='text-center mt-3'>
        <thead className='thead-light'>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Icon</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            !isGetCountriesLoading && sportsCountriesInfo?.countryList?.rows?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.countryId}</td>
                  <td>{item.countryName[0].name}</td>
									<td>-</td>
                  <td className={item.isActive ? 'text-success' : 'text-danger'}>{item.isActive ? 'Active' : 'In-Active'}</td>
                 
                  <td>
                    <ActionMenu
											isHidden={isHidden}
											handleStatusChange={handleStatusChange}
											item={item}
											setShow={setShow}
											setCountryId={setCountryId}         
                    />
                  </td>
                  {/* <td>
                    <Dropdown>
                      <ActionContainer>
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                          <span>&#8285;</span>
                        </Dropdown.Toggle>
                      </ActionContainer>

                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <span>
                            Status
                            <Form.Check
                              name='status'
                              type='switch'
                              className="d-inline-block ms-4"
                              checked={item.isActive}
                              value={item.isActive}
                              onChange={() => handleStatusChange(item.countryId, item.isActive)}
                            />
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          Upload Icon <Button onClick={() => {
                            setShow(true)
                            setCountryId(item.countryId)
                          }}>
                            <FontAwesomeIcon icon={faUpload} beat />
                          </Button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td> */}
                </tr>
              )
            })
          }
          {isGetCountriesLoading &&
            <tr>
              <td colSpan="5">
                <NoDataContainer>
                  <DivLoader />
                </NoDataContainer>
              </td>
            </tr>
          }
        </tbody>
      </Table>
      {sportsCountriesInfo?.countryList?.count !== 0 && !isGetCountriesLoading &&
        (<PaginationComponent
          page={sportsCountriesInfo?.countryList?.count < pageNo ? setPageNo(1) : pageNo}
          totalPages={totalPages}
          setPage={setPageNo}
          limit={limit}
          setLimit={setLimit}
        />)
      }
      <Modal
        show={show}
        content={
          <ImageUploader countryId={countryId} code={"COUNTRY"} />
        }
        onHide={() => setShow(false)}
      />
    </div>
  )
};

export default SportsListTable;